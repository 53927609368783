import {TimelineStepIndexModel} from './TimelineStepIndexModel';

export class HSPPoolSearchModel implements IHSPPoolSearchModel {
  hspPoolId?: string | undefined;
  dateCreated?: string | undefined;
  dateStudentAdded?: string | undefined;
  title?: string | undefined;
  companyId?: string | undefined;
  poolStatus?: string | undefined;
  state?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  poolStudentStatus?: string | undefined;

  constructor(data?: IHSPPoolSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.hspPoolId = Data["hspPoolId"];
      this.dateCreated = Data["dateCreated"];
      this.dateStudentAdded = Data["dateStudentAdded"];
      this.title = Data["title"];
      this.companyId = Data["companyId"];
      this.poolStatus = Data["poolStatus"];
      this.state = Data["state"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      this.poolStudentStatus = Data["poolStudentStatus"];
    }
  }

  static fromJS(data: any): HSPPoolSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPPoolSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["hspPoolId"] = this.hspPoolId;
    data["dateCreated"] = this.dateCreated;
    data["dateStudentAdded"] = this.dateStudentAdded;
    data["title"] = this.title;
    data["companyId"] = this.companyId;
    data["poolStatus"] = this.poolStatus;
    data["state"] = this.state;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    data["poolStudentStatus"] = this.poolStudentStatus;
    return data;
  }
}

export interface IHSPPoolSearchModel {
  hspPoolId?: string | undefined;
  dateCreated?: string | undefined;
  dateStudentAdded?: string | undefined;
  title?: string | undefined;
  companyId?: string | undefined;
  poolStatus?: string | undefined;
  state?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  poolStudentStatus?: string | undefined;
}
