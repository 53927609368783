import React from 'react';
import ReactMarkdown from 'react-markdown';
import {linkableIdsWithBreaks} from '../../../@template/helpers/regexPatterns';
import {ReactMarkdownOptions} from 'react-markdown/lib/react-markdown';
import {uuidv4} from '../../../@template/helpers/uuid';
import PersonLink from '../../people/PersonLink';
import CompanyLink from '../../companies/CompanyLink';

const MultiMarkdownParser = (props: ReactMarkdownOptions) => {
  const sourceData = props.children;
  let processedString = sourceData.split(linkableIdsWithBreaks);
  return (
    <React.Fragment>
      {processedString.map((piece: string, index: number) => {
        if (piece) {
          const mdKey = uuidv4();
          if (piece.startsWith('[P:')) {
            const personId = piece.replace('[P:', '').replace(']', '');
            return <>
              &nbsp;<PersonLink personId={personId} linkProfile={false}/>
            </>;
          }
          if (piece.startsWith('[C:')) {
            const companyId = piece.replace('[C:', '').replace(']', '');
            return <>
              &nbsp;<CompanyLink companyId={companyId} linkProfile={false}/>
            </>;
          }
          if (piece.startsWith('\n')) {
            return <br key={`mdBlock-br-${mdKey}-${index}`}/>;
          }
          return <ReactMarkdown key={`mdBlock-${mdKey}`} disallowedElements={['p']} unwrapDisallowed={true} children={piece} />;
        }
      })}
    </React.Fragment>
  );
};

export default MultiMarkdownParser;