import {GraduatingCapabilitiesSelection} from './GraduatingCapabilitiesSelection';

export class GraduatingCapabilitiesModel implements IGraduatingCapabilitiesModel {
  summaryGraduatingCapability?: string | undefined;
  description?: string | undefined;
  colour?: string | undefined;
  lighterColour?: string | undefined;
  section?: string | undefined;
  content?: GraduatingCapabilitiesSelection[] | undefined;

  constructor(data?: IGraduatingCapabilitiesModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.summaryGraduatingCapability = Data["summaryGraduatingCapability"];
      this.description = Data["description"];
      this.colour = Data["colour"];
      this.lighterColour = Data["lighterColour"];
      this.section = Data["section"];
      if (Array.isArray(Data["content"])) {
        this.content = [] as any;
        for (let item of Data["content"])
          this.content!.push(GraduatingCapabilitiesSelection.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GraduatingCapabilitiesModel {
    data = typeof data === 'object' ? data : {};
    let result = new GraduatingCapabilitiesModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["summaryGraduatingCapability"] = this.summaryGraduatingCapability;
    data["description"] = this.description;
    data["colour"] = this.colour;
    data["lighterColour"] = this.lighterColour;
    data["section"] = this.section;
    if (Array.isArray(this.content)) {
      data["content"] = [];
      for (let item of this.content)
        data["content"].push(item.toJSON());
    }
    return data;
  }
}

export interface IGraduatingCapabilitiesModel {
  summaryGraduatingCapability?: string | undefined;
  description?: string | undefined;
  colour?: string | undefined;
  lighterColour?: string | undefined;
  section?: string | undefined;
  content?: GraduatingCapabilitiesSelection[] | undefined;
}