import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface ContactPaths {
  ContactEdit: string,
  ContactPromote: string,
  ContactCreate: string,
  ContactProfile: string,
  ContactSearch: string,
}

export const contactPaths: ContactPaths = {
  ContactEdit: '/advisor/contacts/edit/:id',
  ContactPromote: '/advisor/contacts/promote/:id',
  ContactCreate: '/advisor/contacts/add',
  ContactProfile: '/advisor/contacts/profile/:id',
  ContactSearch: '/advisor/contacts/search',
};

export interface IContactProfileTabs {
  activity: RouteTabParameter;
  profile: RouteTabParameter;
  opportunities: RouteTabParameter;
  files: RouteTabParameter;
  changeRequests: RouteTabParameter;
}

export const contactProfileTabs: IContactProfileTabs = {
  activity: new RouteTabParameter(0, 'Activity', 'activity'),
  profile: new RouteTabParameter(1, 'Profile', 'profile'),
  opportunities: new RouteTabParameter(2, 'Opportunities', 'opp'),
  files: new RouteTabParameter(4, 'Files', 'files'),
  changeRequests: new RouteTabParameter(6, 'Change Requests', 'change'),
}

const profileTabs = [
  contactProfileTabs.activity,
  contactProfileTabs.profile,
  contactProfileTabs.opportunities,
  contactProfileTabs.files,
  contactProfileTabs.changeRequests,
];

export const getContactProfileTab = (query: string | undefined | null) => profileTabs.find(x => x.query === query);
