export class MailingListRecipientModel implements IMailingListRecipientModel {
  mailingListRecipientId?: string | undefined;
  mailingListId?: string | undefined;
  dateCreated?: Date | undefined;
  createdById?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  isContact?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  personId?: string | undefined;
  dateLastEmailed?: Date | undefined;

  constructor(data?: IMailingListRecipientModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.mailingListRecipientId = Data["mailingListRecipientId"];
      this.mailingListId = Data["mailingListId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.createdById = Data["createdById"];
      this.firstName = Data["firstName"];
      this.lastName = Data["lastName"];
      this.email = Data["email"];
      this.isContact = Data["isContact"];
      this.isStudent = Data["isStudent"];
      this.isVolunteer = Data["isVolunteer"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.isAlumni = Data["isAlumni"];
      this.personId = Data["personId"];
      this.dateLastEmailed = Data["dateLastEmailed"] ? new Date(Data["dateLastEmailed"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): MailingListRecipientModel {
    data = typeof data === 'object' ? data : {};
    let result = new MailingListRecipientModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["mailingListRecipientId"] = this.mailingListRecipientId;
    data["mailingListId"] = this.mailingListId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["createdById"] = this.createdById;
    data["firstName"] = this.firstName;
    data["lastName"] = this.lastName;
    data["email"] = this.email;
    data["isContact"] = this.isContact;
    data["isStudent"] = this.isStudent;
    data["isVolunteer"] = this.isVolunteer;
    data["isWorkShadow"] = this.isWorkShadow;
    data["isAlumni"] = this.isAlumni;
    data["personId"] = this.personId;
    data["dateLastEmailed"] = this.dateLastEmailed ? this.dateLastEmailed.toISOString() : <any>undefined;
    return data;
  }
}

export interface IMailingListRecipientModel {
  mailingListRecipientId?: string | undefined;
  mailingListId?: string | undefined;
  dateCreated?: Date | undefined;
  createdById?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  isContact?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  personId?: string | undefined;
  dateLastEmailed?: Date | undefined;
}
