import {apiConfig} from '../../config';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {all, call, put, select, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  CONTACT_CHANGE_REQUEST,
  STUDENT_CHANGE_REQUEST,
  studentChangeRequestLoadError,
  studentChangeRequestLoadSuccess,
  contactChangeRequestLoadError,
  contactChangeRequestLoadSuccess,
} from './Actions';
import {StudentUpdatesSearchPaged} from '../../types/views/StudentUpdatesSearchPaged';
import {ContactUpdatesSearchPaged} from '../../types/views/ContactUpdatesSearchPaged';

export function* LoadStudentChangeRequests() {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreStudentPath}/search/updates`,
    });
    const studentChangeRequests = StudentUpdatesSearchPaged.fromJS(res.data);
    yield put(studentChangeRequestLoadSuccess(studentChangeRequests));
  } catch (err) {
    console.log('Cache load error', err);
    yield put(studentChangeRequestLoadError(err));
  }
}

export function* LoadContactChangeRequests() {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreContactPath}/search/updates`,
    });
    const contactChangeRequests = ContactUpdatesSearchPaged.fromJS(res.data);
    yield put(contactChangeRequestLoadSuccess(contactChangeRequests));
  } catch (err) {
    console.log('Cache load error', err);
    yield put(contactChangeRequestLoadError(err));
  }
}

export function* watchLoadStudentChangeRequest(){
  yield takeLatest(STUDENT_CHANGE_REQUEST.LOAD, ({ payload }: PayloadAction) => LoadStudentChangeRequests());
}

export function* watchLoadContactChangeRequest(){
  yield takeLatest(CONTACT_CHANGE_REQUEST.LOAD, ({ payload }: PayloadAction) => LoadContactChangeRequests());
}

export function* watchRefreshStudentChangeRequest(){
  yield takeLatest(STUDENT_CHANGE_REQUEST.REFRESH, ({ payload }: PayloadAction) => LoadStudentChangeRequests());
}

export function* watchRefreshContactChangeRequest(){
  yield takeLatest(CONTACT_CHANGE_REQUEST.REFRESH, ({ payload }: PayloadAction) => LoadContactChangeRequests());
}

export default function* changeRequestSaga() {
  yield all([
    call(watchLoadStudentChangeRequest),
    call(watchLoadContactChangeRequest),
    call(watchRefreshStudentChangeRequest),
    call(watchRefreshContactChangeRequest),
  ])
}