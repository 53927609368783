import {formatDate} from '../../@template/helpers/datetimes';

const defaultStudentStatusFilter = ['Potential', 'Pipeline', 'Intern'];

export const defaultStudentSearchParamsModel = () => {
    return StudentSearchParamsModel.fromJS({
        searchText: '',
        statusFlags: defaultStudentStatusFilter,
        advisorUsers: [],
        primaryOnly: true,
        advisorSelected: false,
        excludeAdvisorSelected: false,
        noPrimaryAppointed: false,
        noAdvisorAppointed: false,
        byTeam: false,
        byAdvisorTeam: false,
        byPrimaryAdvisorTeam: true,
        companyIds: [],
        studentTags: [],
        degreePoolId: [],
        majorSearchText: '',
        university: [],
        campus: [],
        state: [],
        countryCode: 'AU',
        contactSearchText: '',
        includeInactive: false,
        icsStatus: [],
        atRiskOnly: false,
        isUniversityProgram: false,
        isPreUniversity: false,
        isOnlyUniversity: false,
        isOnlyPreUniversity: false,
        isUniversityAndPreUniversity: false,
        isNotUniversity: false,
        isNotPreUniversity: false,
        wasWorkShadow: false,
        usingBoth: false,
        usingEither: false,
        usingApp: false,
        usingPortal: false,
        signedNotPermission: false,
        permissionNotSigned: false,
        notSignedNotPermission: false,
        notArchived: true,
        includeArchived: false,
        onlyArchived: false,
        isNoContact: false,
        hasChangeRequest: undefined,
        degreeAndMajorSearchText: '',
        beforeMatchedIsTravelling: false,
        beforeMatchedTravelLocations: [],
        proximityTravelLocations: [],
        archivedReason: [],
        highSchoolSearchText: '',
        isWorkShadowBuddy: false,
    });
}

export class StudentSearchParamsModel implements IStudentSearchParamsModel {
    /** Searches name, quick notes, profile */
    searchText?: string | undefined;
    statusFlags?: string[] | undefined;
    joinedFrom?: Date | undefined;
    joinedTo?: Date | undefined;
    advisorUsers?: string[] | undefined;
    primaryOnly?: boolean | undefined;
    advisorSelected?: boolean | undefined;
    excludePrimarySelected?: boolean | undefined;
    noPrimaryAppointed?: boolean | undefined;
    noAdvisorAppointed?: boolean | undefined;
    byTeam?: boolean | undefined;
    advisorTeamId?: string | undefined;
    advisorSubTeamId?: string | undefined;
    byAdvisorTeam?: boolean | undefined;
    byPrimaryAdvisorTeam?: boolean | undefined;
    yearStartFrom?: number | undefined;
    seasonStartFrom?: number | undefined;
    yearStartTo?: number | undefined;
    seasonStartTo?: number | undefined;
    yearEndFrom?: number | undefined;
    seasonEndFrom?: number | undefined;
    yearEndTo?: number | undefined;
    seasonEndTo?: number | undefined;
    studentTags?: string[] | undefined;
    degreePoolId?: string[] | undefined;
    majorSearchText?: string | undefined;
    companyIds?: string[] | undefined;
    university?: string[] | undefined;
    campus?: string[] | undefined;
    state?: string[] | undefined;
    countryCode?: string | undefined;
    contactSearchText?: string | undefined;
    atRiskOnly?: boolean | undefined;
    isNoContact?: boolean | undefined;
    icsStatus?: string[] | undefined;
    placedFromYear?: number | undefined;
    placedFromSeason?: number | undefined;
    placedToYear?: number | undefined;
    placedToSeason?: number | undefined;
    travelFromYear?: number | undefined;
    travelFromSemester?: number | undefined;
    travelToYear?: number | undefined;
    travelToSemester?: number | undefined;
    isUniversityProgram?: boolean | undefined;
    isPreUniversity?: boolean | undefined;
    isOnlyUniversity?: boolean | undefined;
    isOnlyPreUniversity?: boolean | undefined;
    isUniversityAndPreUniversity?: boolean | undefined;
    isNotUniversity?: boolean | undefined;
    isNotPreUniversity?: boolean | undefined;
    wasWorkShadow?: boolean | undefined;
    usingBoth?: boolean | undefined;
    usingEither?: boolean | undefined;
    usingApp?: boolean | undefined;
    usingPortal?: boolean | undefined;
    signedNotPermission?: boolean | undefined;
    permissionNotSigned?: boolean | undefined;
    notSignedNotPermission?: boolean | undefined;
    notArchived?: boolean | undefined;
    includeArchived?: boolean | undefined;
    onlyArchived?: boolean | undefined;
    degreeAndMajorSearchText?: string | undefined;
    beforeMatchedIsTravelling?: boolean | undefined;
    beforeMatchedIsNotTravelling?: boolean | undefined;
    beforeMatchedTravelLocations?: string[] | undefined;
    proximityTravelLocations?: string[] | undefined;
    archivedReason?: string[] | undefined;
    highSchoolSearchText?: string | undefined;
    highSchoolFromYear?: number | undefined;
    highSchoolToYear?: number | undefined;
    isWorkShadowBuddy?: boolean | undefined;

    constructor(data?: IStudentSearchParamsModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.searchText = Data["searchText"];
            if (Array.isArray(Data["statusFlags"])) {
                this.statusFlags = [] as any;
                for (let item of Data["statusFlags"])
                    this.statusFlags!.push(item);
            }
            this.joinedFrom = Data["joinedFrom"] ? new Date(Data["joinedFrom"].toString()) : <any>undefined;
            this.joinedTo = Data["joinedTo"] ? new Date(Data["joinedTo"].toString()) : <any>undefined;
            if (Array.isArray(Data["advisorUsers"])) {
                this.advisorUsers = [] as any;
                for (let item of Data["advisorUsers"])
                    this.advisorUsers!.push(item);
            }
            this.primaryOnly = Data["primaryOnly"];
            this.advisorSelected = Data["advisorSelected"];
            this.excludePrimarySelected = Data["excludePrimarySelected"];
            this.noPrimaryAppointed = Data["noPrimaryAppointed"];
            this.noAdvisorAppointed = Data["noAdvisorAppointed"];
            this.byTeam = Data["byTeam"];
            this.advisorTeamId = Data["advisorTeamId"];
            this.advisorSubTeamId = Data["advisorSubTeamId"];
            this.byAdvisorTeam = Data["byAdvisorTeam"];
            this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
            this.yearStartFrom = Data["yearStartFrom"];
            this.seasonStartFrom = Data["seasonStartFrom"];
            this.yearStartTo = Data["yearStartTo"];
            this.seasonStartTo = Data["seasonStartTo"];
            this.yearEndFrom = Data["yearEndFrom"];
            this.seasonEndFrom = Data["seasonEndFrom"];
            this.yearEndTo = Data["yearEndTo"];
            this.seasonEndTo = Data["seasonEndTo"];
            if (Array.isArray(Data["studentTags"])) {
                this.studentTags = [] as any;
                for (let item of Data["studentTags"])
                    this.studentTags!.push(item);
            }
            if (Array.isArray(Data["degreePoolId"])) {
                this.degreePoolId = [] as any;
                for (let item of Data["degreePoolId"])
                    this.degreePoolId!.push(item);
            }
            this.majorSearchText = Data["majorSearchText"];
            if (Array.isArray(Data["companyIds"])) {
                this.companyIds = [] as any;
                for (let item of Data["companyIds"])
                    this.companyIds!.push(item);
            }
            if (Array.isArray(Data["university"])) {
                this.university = [] as any;
                for (let item of Data["university"])
                    this.university!.push(item);
            }
            if (Array.isArray(Data["campus"])) {
                this.campus = [] as any;
                for (let item of Data["campus"])
                    this.campus!.push(item);
            }
            if (Array.isArray(Data["state"])) {
                this.state = [] as any;
                for (let item of Data["state"])
                    this.state!.push(item);
            }
            this.countryCode = Data["countryCode"];
            this.contactSearchText = Data["contactSearchText"];
            this.atRiskOnly = Data["atRiskOnly"];
            this.isNoContact = Data["isNoContact"];
            if (Array.isArray(Data["icsStatus"])) {
                this.icsStatus = [] as any;
                for (let item of Data["icsStatus"])
                    this.icsStatus!.push(item);
            }
            this.placedFromYear = Data["placedFromYear"];
            this.placedFromSeason = Data["placedFromSeason"];
            this.placedToYear = Data["placedToYear"];
            this.placedToSeason = Data["placedToSeason"];
            this.travelFromYear = Data["travelFromYear"];
            this.travelFromSemester = Data["travelFromSemester"];
            this.travelToYear = Data["travelToYear"];
            this.travelToSemester = Data["travelToSemester"];
            this.isUniversityProgram = Data["isUniversityProgram"];
            this.isPreUniversity = Data["isPreUniversity"];
            this.isOnlyUniversity = Data["isOnlyUniversity"];
            this.isOnlyPreUniversity = Data["isOnlyPreUniversity"];
            this.isUniversityAndPreUniversity = Data["isUniversityAndPreUniversity"];
            this.isNotUniversity = Data["isNotUniversity"];
            this.isNotPreUniversity = Data["isNotPreUniversity"];
            this.wasWorkShadow = Data["wasWorkShadow"];
            this.usingBoth = Data["usingBoth"];
            this.usingEither = Data["usingEither"];
            this.usingApp = Data["usingApp"];
            this.usingPortal = Data["usingPortal"];
            this.signedNotPermission = Data["signedNotPermission"];
            this.permissionNotSigned = Data["permissionNotSigned"];
            this.notSignedNotPermission = Data["notSignedNotPermission"];
            this.notArchived = Data["notArchived"];
            this.includeArchived = Data["includeArchived"];
            this.onlyArchived = Data["onlyArchived"];
            this.degreeAndMajorSearchText = Data["degreeAndMajorSearchText"];
            this.beforeMatchedIsTravelling = Data["beforeMatchedIsTravelling"];
            this.beforeMatchedIsNotTravelling = Data["beforeMatchedIsNotTravelling"];
            if (Array.isArray(Data["beforeMatchedTravelLocations"])) {
                this.beforeMatchedTravelLocations = [] as any;
                for (let item of Data["beforeMatchedTravelLocations"])
                    this.beforeMatchedTravelLocations!.push(item);
            }
            if (Array.isArray(Data["proximityTravelLocations"])) {
                this.proximityTravelLocations = [] as any;
                for (let item of Data["proximityTravelLocations"])
                    this.proximityTravelLocations!.push(item);
            }
            if (Array.isArray(Data["archivedReason"])) {
                this.archivedReason = [] as any;
                for (let item of Data["archivedReason"])
                    this.archivedReason!.push(item);
            }
            this.highSchoolSearchText = Data["highSchoolSearchText"];
            this.highSchoolFromYear = Data["highSchoolFromYear"];
            this.highSchoolToYear = Data["highSchoolToYear"];
            this.isWorkShadowBuddy = Data["isWorkShadowBuddy"];
        }
    }

    static fromJS(data: any): StudentSearchParamsModel {
        data = typeof data === 'object' ? data : {};
        let result = new StudentSearchParamsModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["searchText"] = this.searchText;
        if (Array.isArray(this.statusFlags)) {
            data["statusFlags"] = [];
            for (let item of this.statusFlags)
                data["statusFlags"].push(item);
        }
        data["joinedFrom"] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
        data["joinedTo"] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
        if (Array.isArray(this.advisorUsers)) {
            data["advisorUsers"] = [];
            for (let item of this.advisorUsers)
                data["advisorUsers"].push(item);
        }
        data["primaryOnly"] = this.primaryOnly;
        data["advisorSelected"] = this.advisorSelected;
        data["excludePrimarySelected"] = this.excludePrimarySelected;
        data["noPrimaryAppointed"] = this.noPrimaryAppointed;
        data["noAdvisorAppointed"] = this.noAdvisorAppointed;
        data["byTeam"] = this.byTeam;
        data["advisorTeamId"] = this.advisorTeamId;
        data["advisorSubTeamId"] = this.advisorSubTeamId;
        data["byAdvisorTeam"] = this.byAdvisorTeam;
        data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
        data["yearStartFrom"] = this.yearStartFrom;
        data["seasonStartFrom"] = this.seasonStartFrom;
        data["yearStartTo"] = this.yearStartTo;
        data["seasonStartTo"] = this.seasonStartTo;
        data["yearEndFrom"] = this.yearEndFrom;
        data["seasonEndFrom"] = this.seasonEndFrom;
        data["yearEndTo"] = this.yearEndTo;
        data["seasonEndTo"] = this.seasonEndTo;
        if (Array.isArray(this.studentTags)) {
            data["studentTags"] = [];
            for (let item of this.studentTags)
                data["studentTags"].push(item);
        }
        if (Array.isArray(this.degreePoolId)) {
            data["degreePoolId"] = [];
            for (let item of this.degreePoolId)
                data["degreePoolId"].push(item);
        }
        data["majorSearchText"] = this.majorSearchText;
        if (Array.isArray(this.companyIds)) {
            data["companyIds"] = [];
            for (let item of this.companyIds)
                data["companyIds"].push(item);
        }
        if (Array.isArray(this.university)) {
            data["university"] = [];
            for (let item of this.university)
                data["university"].push(item);
        }
        if (Array.isArray(this.campus)) {
            data["campus"] = [];
            for (let item of this.campus)
                data["campus"].push(item);
        }
        if (Array.isArray(this.state)) {
            data["state"] = [];
            for (let item of this.state)
                data["state"].push(item);
        }
        data["countryCode"] = this.countryCode;
        data["contactSearchText"] = this.contactSearchText;
        data["atRiskOnly"] = this.atRiskOnly;
        data["isNoContact"] = this.isNoContact;
        if (Array.isArray(this.icsStatus)) {
            data["icsStatus"] = [];
            for (let item of this.icsStatus)
                data["icsStatus"].push(item);
        }
        data["placedFromYear"] = this.placedFromYear;
        data["placedFromSeason"] = this.placedFromSeason;
        data["placedToYear"] = this.placedToYear;
        data["placedToSeason"] = this.placedToSeason;
        data["travelFromYear"] = this.travelFromYear;
        data["travelFromSemester"] = this.travelFromSemester;
        data["travelToYear"] = this.travelToYear;
        data["travelToSemester"] = this.travelToSemester;
        data["isUniversityProgram"] = this.isUniversityProgram;
        data["isPreUniversity"] = this.isPreUniversity;
        data["isOnlyUniversity"] = this.isOnlyUniversity;
        data["isOnlyPreUniversity"] = this.isOnlyPreUniversity;
        data["isUniversityAndPreUniversity"] = this.isUniversityAndPreUniversity;
        data["isNotUniversity"] = this.isNotUniversity;
        data["isNotPreUniversity"] = this.isNotPreUniversity;
        data["wasWorkShadow"] = this.wasWorkShadow;
        data["usingBoth"] = this.usingBoth;
        data["usingEither"] = this.usingEither;
        data["usingApp"] = this.usingApp;
        data["usingPortal"] = this.usingPortal;
        data["signedNotPermission"] = this.signedNotPermission;
        data["permissionNotSigned"] = this.permissionNotSigned;
        data["notSignedNotPermission"] = this.notSignedNotPermission;
        data["notArchived"] = this.notArchived;
        data["includeArchived"] = this.includeArchived;
        data["onlyArchived"] = this.onlyArchived;
        data["degreeAndMajorSearchText"] = this.degreeAndMajorSearchText;
        data["beforeMatchedIsTravelling"] = this.beforeMatchedIsTravelling;
        data["beforeMatchedIsNotTravelling"] = this.beforeMatchedIsNotTravelling;
        if (Array.isArray(this.beforeMatchedTravelLocations)) {
            data["beforeMatchedTravelLocations"] = [];
            for (let item of this.beforeMatchedTravelLocations)
                data["beforeMatchedTravelLocations"].push(item);
        }
        if (Array.isArray(this.proximityTravelLocations)) {
            data["proximityTravelLocations"] = [];
            for (let item of this.proximityTravelLocations)
                data["proximityTravelLocations"].push(item);
        }
        if (Array.isArray(this.archivedReason)) {
            data["archivedReason"] = [];
            for (let item of this.archivedReason)
                data["archivedReason"].push(item);
        }
        data["highSchoolSearchText"] = this.highSchoolSearchText;
        data["highSchoolFromYear"] = this.highSchoolFromYear;
        data["highSchoolToYear"] = this.highSchoolToYear;
        data["isWorkShadowBuddy"] = this.isWorkShadowBuddy;
        return data;
    }
}

export interface IStudentSearchParamsModel {
    /** Searches name, quick notes, profile */
    searchText?: string | undefined;
    statusFlags?: string[] | undefined;
    joinedFrom?: Date | undefined;
    joinedTo?: Date | undefined;
    advisorUsers?: string[] | undefined;
    primaryOnly?: boolean | undefined;
    advisorSelected?: boolean | undefined;
    excludePrimarySelected?: boolean | undefined;
    noPrimaryAppointed?: boolean | undefined;
    noAdvisorAppointed?: boolean | undefined;
    byTeam?: boolean | undefined;
    advisorTeamId?: string | undefined;
    advisorSubTeamId?: string | undefined;
    byAdvisorTeam?: boolean | undefined;
    byPrimaryAdvisorTeam?: boolean | undefined;
    yearStartFrom?: number | undefined;
    seasonStartFrom?: number | undefined;
    yearStartTo?: number | undefined;
    seasonStartTo?: number | undefined;
    yearEndFrom?: number | undefined;
    seasonEndFrom?: number | undefined;
    yearEndTo?: number | undefined;
    seasonEndTo?: number | undefined;
    studentTags?: string[] | undefined;
    degreePoolId?: string[] | undefined;
    majorSearchText?: string | undefined;
    companyIds?: string[] | undefined;
    university?: string[] | undefined;
    campus?: string[] | undefined;
    state?: string[] | undefined;
    countryCode?: string | undefined;
    contactSearchText?: string | undefined;
    atRiskOnly?: boolean | undefined;
    isNoContact?: boolean | undefined;
    icsStatus?: string[] | undefined;
    placedFromYear?: number | undefined;
    placedFromSeason?: number | undefined;
    placedToYear?: number | undefined;
    placedToSeason?: number | undefined;
    travelFromYear?: number | undefined;
    travelFromSemester?: number | undefined;
    travelToYear?: number | undefined;
    travelToSemester?: number | undefined;
    isUniversityProgram?: boolean | undefined;
    isPreUniversity?: boolean | undefined;
    isOnlyUniversity?: boolean | undefined;
    isOnlyPreUniversity?: boolean | undefined;
    isUniversityAndPreUniversity?: boolean | undefined;
    isNotUniversity?: boolean | undefined;
    isNotPreUniversity?: boolean | undefined;
    wasWorkShadow?: boolean | undefined;
    usingBoth?: boolean | undefined;
    usingEither?: boolean | undefined;
    usingApp?: boolean | undefined;
    usingPortal?: boolean | undefined;
    signedNotPermission?: boolean | undefined;
    permissionNotSigned?: boolean | undefined;
    notSignedNotPermission?: boolean | undefined;
    notArchived?: boolean | undefined;
    includeArchived?: boolean | undefined;
    onlyArchived?: boolean | undefined;
    degreeAndMajorSearchText?: string | undefined;
    beforeMatchedIsTravelling?: boolean | undefined;
    beforeMatchedIsNotTravelling?: boolean | undefined;
    beforeMatchedTravelLocations?: string[] | undefined;
    proximityTravelLocations?: string[] | undefined;
    archivedReason?: string[] | undefined;
    highSchoolSearchText?: string | undefined;
    highSchoolFromYear?: number | undefined;
    highSchoolToYear?: number | undefined;
    isWorkShadowBuddy?: boolean | undefined;
}