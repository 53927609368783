export class MailingListListItem implements IMailingListListItem {
  id?: string | undefined;
  title?: string | undefined;
  mailingListDescription?: string | undefined;

  constructor(data?: IMailingListListItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.title = Data["title"];
      this.mailingListDescription = Data["mailingListDescription"];
    }
  }

  static fromJS(data: any): MailingListListItem {
    data = typeof data === 'object' ? data : {};
    let result = new MailingListListItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["title"] = this.title;
    data["mailingListDescription"] = this.mailingListDescription;
    return data;
  }
}

export interface IMailingListListItem {
  id?: string | undefined;
  title?: string | undefined;
  mailingListDescription?: string | undefined;
}
