import {ThreadDetailModel} from './ThreadDetailModel';
import {EventItemViewModel} from './EventItemViewModel';

export class ThreadTaskList implements IThreadTaskList {
  thread?: ThreadDetailModel | undefined;
  taskList?: EventItemViewModel[] | undefined;

  constructor(data?: IThreadTaskList) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.thread = Data["thread"] ? ThreadDetailModel.fromJS(Data["thread"]) : <any>undefined;
      if (Array.isArray(Data["taskList"])) {
        this.taskList = [] as any;
        for (let item of Data["taskList"])
          this.taskList!.push(EventItemViewModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ThreadTaskList {
    data = typeof data === 'object' ? data : {};
    let result = new ThreadTaskList();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["thread"] = this.thread ? this.thread.toJSON() : <any>undefined;
    if (Array.isArray(this.taskList)) {
      data["taskList"] = [];
      for (let item of this.taskList)
        data["taskList"].push(item.toJSON());
    }
    return data;
  }
}

export interface IThreadTaskList {
  thread?: ThreadDetailModel | undefined;
  taskList?: EventItemViewModel[] | undefined;
}