import {
  Fonts,
  FooterType,
  HeaderType,
  LayoutType,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from '../../../shared/constants/AppEnums';

export const cardRadius = ThemeStyleRadius.MODERN;


const defaultConfig: any = {
  theme: {
    typography: {
      fontSize: 12,
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      h1: {
        fontSize: '2rem',
        fontWeight: Fonts.MEDIUM,
      },
      h2: {
        fontSize: '1.75rem',
        fontWeight: Fonts.MEDIUM,
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: Fonts.MEDIUM,
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 500
      },
      h5: {
        fontSize: '1.1rem',
        fontWeight: 500
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.06)',
            fontWeight: Fonts.BOLD,
            textTransform: 'none',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
          },
        }
      },
      MuiFilledInput: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiCardLg: {
        styleOverrides: {
          root: {
            borderRadius: ThemeStyleRadius.MODERN + 20,
          },
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.05)',
            '& .MuiCardContent-root:last-child': {
              paddingBottom: 16,
            },
          },
        }
      },
      MuiDialog: {
        defaultProps: {
          scroll: "paper"
        }
      },
      MuiDialogContent: {
        defaultProps: {
          dividers: true
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '12px 20px'
          },
        }
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: '#A8A8A8',
            borderColor: '#A8A8A8'
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          elevation: 2,
        },
        styleOverrides: {
          root: {
            boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.05)',
          },
        }
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        }
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
        styleOverrides: {
          root: {
            fontSize: 14,
            background: '#fcfdff',
          },
        }
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense',
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
        styleOverrides: {
          root: {
            background: '#fcfdff',
          },
          notchedOutline: {
            borderColor: '#a9afba',
          },
        }
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        }
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        }
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        },
      },
      MuiTooltip: {
        defaultProps: {
          fontSize: '2rem'
        },
        styleOverrides: {
          tooltip: {
            fontSize: '0.8rem'
          },
        }
      },
      MuiSpeedDial: {
        defaultProps: {

        }
      }
    },
    spacing: 4,
    direction: 'ltr', //ltr, rtl
    palette: {
      mode: ThemeMode.LIGHT,
      background: {
        paper: '#FFFFFF',
        default: '#F8F8F8',
      },
      primary: {
        main: '#EA5409',
        contrastText: '#fff',
      },
      secondary: {
        main: '#4473AB',
      },
      info: {
        main: '#8a8f97',
      },
      sidebar: {
        bgColor: '#313541',
        textColor: '#808183',
      },
      grey: {
        50: '#fafafa',
        100: '#f7fafc',
        200: '#edf2f7',
        300: '#E0E0E0',
        400: '#c5c6cb',
        500: '#A8A8A8',
        600: '#666666',
        700: '#4a5568',
        800: '#201e21',
        900: '#1a202c',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
      text: {
        primary: '#495057',
        secondary: '#74788d',
        disabled: '#909098',
        hint: '#aeafb8',
        white: '#efefef',
      },
    },
    status: {
      danger: 'orange',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  themeStyle: ThemeStyle.MODERN,
  themeMode: ThemeMode.LIGHT,
  navStyle: NavStyle.MINI_SIDEBAR_TOGGLE,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FIXED,
  headerType: HeaderType.LIGHT,
  rtAnim: RouteTransition.NONE,
  footer: true,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  rtlLocale: ['au'],
};
export default defaultConfig;
