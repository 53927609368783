import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {POOL_CACHE} from './Actions';
import {PoolSuggestModel} from '../../types/views/PoolSuggestModel';

export interface IPoolsCacheState {
  poolCacheUpdating: boolean | false;
  poolCacheError: string | undefined;
  poolPendingIdList: string[];
  poolCache: PoolSuggestModel[];
}

const initialState: IPoolsCacheState = {
  poolCacheUpdating: false,
  poolCacheError: undefined,
  poolPendingIdList: [],
  poolCache: [],
}

const poolsCacheReducer = createReducer(initialState, {
  [POOL_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.poolPendingIdList = [...new Set([...state.poolPendingIdList, ...action.payload])];
    state.poolCacheError = undefined;
  },
  [POOL_CACHE.UPDATE]: (state) => {
    state.poolPendingIdList = [];
    state.poolCacheUpdating = true;
    state.poolCacheError = undefined;
  },
  [POOL_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<PoolSuggestModel[]>) => {
    let currentPoolsCache = state.poolCache;
    action.payload.map(item => {
      if (!currentPoolsCache.find(x => x.id === item.id)) {
        currentPoolsCache.push(item);
      }
    });
    state.poolCache = currentPoolsCache;
    state.poolCacheUpdating = false;
    state.poolCacheError = undefined;
  },
  [POOL_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.poolCache = state.poolCache.filter(x => x.id !== action.payload);
    state.poolCacheUpdating = false;
    state.poolCacheError = undefined;
  },
});

export default poolsCacheReducer;