import {MAILING_LIST_CACHE, mailingListCacheError, mailingListCacheMerge, mailingListCacheUpdate} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {AppState} from '../store';
import {hasItems} from '../../@template/helpers/arrays';
import {apiConfig} from '../../config';
import {MailingListListItem} from '../../types/views/MailingListListItem';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IMailingListsCacheState} from './Reducer';
import {PayloadAction} from '@reduxjs/toolkit';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const mailingCacheState = (state: AppState) => state.mailingCache;

export function* LoadMailingListCacheItems(idList: string[]) {
  console.log('SAGA - mailingCacheSaga - LoadMailingListCacheItems', idList);
  try {
    if (hasItems(idList)) {
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.coreMailingPath}/list`,
        data: { id_list: idList }
      });
      const mailingLists: MailingListListItem[] = res.data.map((x: any) => MailingListListItem.fromJS(x));
      yield put(mailingListCacheMerge(mailingLists));
    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(mailingListCacheError(err));
  }
}

export function* AddMailingListCacheItems() {
  console.log('SAGA - mailingCacheSaga - AddMailingListCacheItems');
  yield call(delay, 200);
  const currentMailingListCache: IMailingListsCacheState = yield select(mailingCacheState);
  const currentPendingList = currentMailingListCache.mailingListPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    console.log('SAGA - mailingCacheSaga - AddMailingListCacheItems Load', currentPendingList);
    yield put(mailingListCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadMailingListCacheItems(){
  yield takeEvery(MAILING_LIST_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadMailingListCacheItems(payload));
}

export function* watchAddMailingListCacheItems(){
  yield takeEvery(MAILING_LIST_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddMailingListCacheItems());
}

export default function* mailingCacheSaga() {
  yield all([
    call(watchLoadMailingListCacheItems),
    call(watchAddMailingListCacheItems),
  ])
}