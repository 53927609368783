export class TaskFieldModel implements ITaskFieldModel {
  label?: string | undefined;
  variable?: string | undefined;

  constructor(data?: ITaskFieldModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.label = Data["label"];
      this.variable = Data["variable"];
    }
  }

  static fromJS(data: any): TaskFieldModel {
    data = typeof data === 'object' ? data : {};
    let result = new TaskFieldModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["label"] = this.label;
    data["variable"] = this.variable;
    return data;
  }
}

export interface ITaskFieldModel {
  label?: string | undefined;
  variable?: string | undefined;
}