import {ICommonCacheActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {FileMetaModel} from '../../types/models/FileMetaModel';

export const FILE_CACHE: ICommonCacheActionType = {
  ADD: 'fileCache/add',
  UPDATE: 'fileCache/updated',
  MERGE_ITEMS: 'fileCache/merge',
  ERROR: 'fileCache/error',
  REMOVE: 'fileCache/remove'
};

export const fileCacheAdd = createAction<string[]>(FILE_CACHE.ADD);
export const fileCacheUpdate = createAction<string[]>(FILE_CACHE.UPDATE);
export const fileCacheMerge = createAction<FileMetaModel[]>(FILE_CACHE.MERGE_ITEMS);
export const fileCacheError = createAction<string>(FILE_CACHE.ERROR);
export const fileCacheRemove = createAction<string>(FILE_CACHE.REMOVE);