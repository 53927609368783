import {apiConfig} from '../../config';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {all, call, put, select, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {HistorySubmitModel} from '../../types/requests/HistorySubmitModel';
import {HistoryRetrievedModel} from '../../types/models/HistoryRetrievedModel';
import {HISTORY, historyLoadError, historyLoadSuccess, historySaveError, historySaveSuccess} from './Actions';

export function* LoadHistory() {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreSettingsPath}/history`,
    });
    const history = res.data.map((x: any) => HistoryRetrievedModel.fromJS(x));
    yield put(historyLoadSuccess(history));
  } catch (err) {
    console.log('Cache load error', err);
    yield put(historyLoadError(err));
  }
}

export function* SaveHistory(payload: HistorySubmitModel) {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: `${apiConfig.coreSettingsPath}/history`,
      data: payload
    });
    const history = res.data.map((x: any) => HistoryRetrievedModel.fromJS(x));
    yield put(historySaveSuccess(history));
  } catch (error) {
    yield put(historySaveError(error));
  }
}

export function* watchLoadHistory(){
  yield takeLatest(HISTORY.LOAD, ({ payload }: PayloadAction) => LoadHistory());
}


export function* watchSaveHistory(){
  yield takeLatest(HISTORY.SAVE, ({ payload }: PayloadAction<HistorySubmitModel>) => SaveHistory(payload));
}

export default function* historySaga() {
  yield all([
    call(watchLoadHistory),
    call(watchSaveHistory),
  ])
}