import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {FILES} from './Actions';
import {FilesPagedModel} from '../../types/views/FilesPagedModel';

export interface IFilesState {
  filesResultLoading: boolean;
  filesResultRefreshing: boolean;
  filesResultError: string | undefined;
  filesResult: FilesPagedModel | undefined;
}


const initialState: IFilesState = {
  filesResultLoading: false,
  filesResultRefreshing: false,
  filesResultError: undefined,
  filesResult: undefined
};

const filesReducer = createReducer(initialState, {
  [FILES.LOAD]: (state) => {
    state.filesResultLoading = true;
    state.filesResultRefreshing = false;
    state.filesResultError = undefined;
    state.filesResult = undefined;
  },
  [FILES.REFRESH]: (state) => {
    state.filesResultLoading = false;
    state.filesResultRefreshing = true;
    state.filesResultError = undefined;
  },
  [FILES.LOADED]: (state, action: PayloadAction<FilesPagedModel>) => {
    state.filesResultLoading = false;
    state.filesResultRefreshing = false;
    state.filesResultError = undefined;
    state.filesResult = action.payload;
  },
  [FILES.ERROR]: (state, action: PayloadAction<string>) => {
    state.filesResultLoading = true;
    state.filesResultRefreshing = false;
    state.filesResultError = action.payload;
  },
});

export default filesReducer;