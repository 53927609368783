import {toLocalTime} from '../../@template/helpers/datetimes';

export class EventModel implements IEventModel {
  title?: string | undefined;
  start?: Date | undefined;
  end?: Date | undefined;

  constructor(data?: IEventModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.title = Data["title"];
      this.start = Data["start"] ? toLocalTime(Data["start"].toString()) : <any>undefined;
      this.end = Data["end"] ? toLocalTime(Data["end"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): EventModel {
    data = typeof data === 'object' ? data : {};
    let result = new EventModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["title"] = this.title;
    data["start"] = this.start ? this.start.toISOString() : <any>undefined;
    data["end"] = this.end ? this.end.toISOString() : <any>undefined;
    return data;
  }
}

export interface IEventModel {
  title?: string | undefined;
  start?: Date | undefined;
  end?: Date | undefined;
}