export class SeasonPlatformSettingsModel implements ISeasonPlatformSettingsModel {
  seasonYear?: number | undefined;
  seasonNumber?: number | undefined;
  seasonEndDate?: Date | undefined;
  seasonDueDate?: Date | undefined;

  constructor(data?: ISeasonPlatformSettingsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.seasonYear = Data["seasonYear"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonEndDate = Data["seasonEndDate"] ? new Date(Data["seasonEndDate"].toString()) : <any>undefined;
      this.seasonDueDate = Data["seasonDueDate"] ? new Date(Data["seasonDueDate"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): SeasonPlatformSettingsModel {
    data = typeof data === 'object' ? data : {};
    let result = new SeasonPlatformSettingsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["seasonYear"] = this.seasonYear;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonEndDate"] = this.seasonEndDate ? this.seasonEndDate.toISOString() : <any>undefined;
    data["seasonDueDate"] = this.seasonDueDate ? this.seasonDueDate.toISOString() : <any>undefined;
    return data;
  }
}

export interface ISeasonPlatformSettingsModel {
  seasonYear?: number | undefined;
  seasonNumber?: number | undefined;
  seasonEndDate?: Date | undefined;
  seasonDueDate?: Date | undefined;
}