import {HSPPoolDetailModel} from '../../types/models/HSPPoolDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {
  HSP_COMPANY_POOL_LIST,
  HSP_COMPANY_POOL_SEASONS,
} from './Actions';
import {HSPCompanyPoolSeasonModel} from '../../types/models/HSPCompanyPoolSeasonModel';


export interface IHSPPoolState {
  hspPoolCompanySeasons: HSPCompanyPoolSeasonModel[] | undefined;
  hspPoolCompanySeasonsLoading: boolean;
  hspPoolCompanySeasonsRefreshing: boolean;
  hspPoolCompanySeasonsError: string | undefined;
  hspPoolCompanyList: HSPPoolDetailModel[] | undefined;
  hspPoolCompanyListRefreshing: boolean;
  hspPoolCompanyListLoading: boolean;
  hspPoolCompanyListError: string | undefined;
}

const initialState: IHSPPoolState = {
  hspPoolCompanySeasons: undefined,
  hspPoolCompanySeasonsLoading: false,
  hspPoolCompanySeasonsRefreshing: false,
  hspPoolCompanySeasonsError: undefined,
  hspPoolCompanyList: undefined,
  hspPoolCompanyListRefreshing: false,
  hspPoolCompanyListLoading: false,
  hspPoolCompanyListError: undefined,
};

const hspPoolsReducer = createReducer(initialState, {
  [HSP_COMPANY_POOL_SEASONS.LOAD]: (state) => {
    state.hspPoolCompanySeasons = undefined;
    state.hspPoolCompanySeasonsRefreshing = false;
    state.hspPoolCompanySeasonsLoading = true;
    state.hspPoolCompanySeasonsError = undefined;
  },
  [HSP_COMPANY_POOL_SEASONS.REFRESH]: (state) => {
    state.hspPoolCompanySeasons = undefined;
    state.hspPoolCompanySeasonsRefreshing = true;
    state.hspPoolCompanySeasonsLoading = false;
    state.hspPoolCompanySeasonsError = undefined;
  },
  [HSP_COMPANY_POOL_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPoolCompanySeasonsRefreshing = false;
    state.hspPoolCompanySeasonsLoading = false;
    state.hspPoolCompanySeasonsError = action.payload;
  },
  [HSP_COMPANY_POOL_SEASONS.LOADED]: (state, action: PayloadAction<HSPCompanyPoolSeasonModel[]>) => {
    state.hspPoolCompanySeasons = action.payload;
    state.hspPoolCompanySeasonsRefreshing = false;
    state.hspPoolCompanySeasonsLoading = false;
    state.hspPoolCompanySeasonsError = undefined;
  },

  [HSP_COMPANY_POOL_LIST.LOAD]: (state) => {
    state.hspPoolCompanyList = undefined;
    state.hspPoolCompanyListRefreshing = false;
    state.hspPoolCompanyListLoading = true;
    state.hspPoolCompanyListError = undefined;
  },
  [HSP_COMPANY_POOL_LIST.REFRESH]: (state) => {
    state.hspPoolCompanyList = undefined;
    state.hspPoolCompanyListRefreshing = true;
    state.hspPoolCompanyListLoading = false;
    state.hspPoolCompanyListError = undefined;
  },
  [HSP_COMPANY_POOL_LIST.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPoolCompanyListRefreshing = false;
    state.hspPoolCompanyListLoading = false;
    state.hspPoolCompanyListError = action.payload;
  },
  [HSP_COMPANY_POOL_LIST.LOADED]: (state, action: PayloadAction<HSPPoolDetailModel[]>) => {
    state.hspPoolCompanyList = action.payload;
    state.hspPoolCompanyListRefreshing = false;
    state.hspPoolCompanyListLoading = false;
    state.hspPoolCompanyListError = undefined;
  },
});

export default hspPoolsReducer;

