import {HighSchoolProgramParticipantStepModel} from './HighSchoolProgramParticipantStepModel';

export class HighSchoolProgramParticipantModel implements IHighSchoolProgramParticipantModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title!: string;
  /** Description of the template */
  description?: string | undefined;
  /** If we want this to be the default for new workShadows */
  isDefault?: boolean | undefined;
  /** Steps for this template */
  participantSteps!: HighSchoolProgramParticipantStepModel[];
  /** Disabled */
  isDisabled?: boolean | undefined;

  constructor(data?: IHighSchoolProgramParticipantModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.participantSteps = [];
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.createdByUserId = Data["createdByUserId"];
      this.title = Data["title"];
      this.description = Data["description"];
      this.isDefault = Data["isDefault"];
      if (Array.isArray(Data["participantSteps"])) {
        this.participantSteps = [] as any;
        for (let item of Data["participantSteps"])
          this.participantSteps!.push(HighSchoolProgramParticipantStepModel.fromJS(item));
      }
      this.isDisabled = Data["isDisabled"];
    }
  }

  static fromJS(data: any): HighSchoolProgramParticipantModel {
    data = typeof data === 'object' ? data : {};
    let result = new HighSchoolProgramParticipantModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createdByUserId"] = this.createdByUserId;
    data["title"] = this.title;
    data["description"] = this.description;
    data["isDefault"] = this.isDefault;
    if (Array.isArray(this.participantSteps)) {
      data["participantSteps"] = [];
      for (let item of this.participantSteps)
        data["participantSteps"].push(item.toJSON());
    }
    data["isDisabled"] = this.isDisabled;
    return data;
  }
}

export interface IHighSchoolProgramParticipantModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title: string;
  /** Description of the template */
  description?: string | undefined;
  /** If we want this to be the default for new workShadows */
  isDefault?: boolean | undefined;
  /** Steps for this template */
  participantSteps: HighSchoolProgramParticipantStepModel[];
  /** Disabled */
  isDisabled?: boolean | undefined;
}
