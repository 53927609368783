import makeStyles from '@mui/styles/makeStyles';
import {Fonts, ThemeMode} from '../../../../shared/constants/AppEnums';
import {AppTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: AppTheme) => {
  return {
    navItem: {
      height: 40,
      marginTop: 2,
      marginBottom: 2,
      width: 'calc(100% - 16px)',
      borderRadius: '0 30px 30px 0',
      paddingLeft: (props: {level: number; themeMode: ThemeMode}) =>  24 + 40 * props.level,
      paddingRight: 24,

      '& .nav-item-text': {
        fontWeight: Fonts.REGULAR,
        // fontSize: 16,
        color: theme.palette.sidebar.textColor,

        [theme.breakpoints.up('lg')]: {
          marginTop: 1,
          marginBottom: 1,
        },
      },

      '& .nav-item-icon': {
        color: theme.palette.sidebar.textColor,
      },

      '& .nav-item-icon-arrow': {
        color: theme.palette.sidebar.textColor,
      },

      '& .MuiIconButton-root': {
        marginRight: -16,
      },

      '&.open, &:hover, &:focus': {
        '& .nav-item-text': {
          fontWeight: Fonts.MEDIUM,
          color: '#313541',
        },

        '& .nav-item-icon': {
          color: '#313541',
        },

        '& .nav-item-icon-arrow': {
          color:'#313541',
        },
      },
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    listIcon: {
      fontSize: 18,
      [theme.breakpoints.up('lg')]: {
        fontSize: 22,
      },
    },
  };
});
export default useStyles;
