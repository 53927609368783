import {ICommonCacheActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {HSPPoolSuggestModel} from '../../types/views/HSPPoolSuggestModel';

export const HSP_POOL_CACHE: ICommonCacheActionType = {
  ADD: 'hspPoolCache/add',
  UPDATE: 'hspPoolCache/updated',
  MERGE_ITEMS: 'hspPoolCache/merge',
  ERROR: 'hspPoolCache/error',
  REMOVE: 'hspPoolCache/remove'
};

export const hspPoolCacheAdd = createAction<string[]>(HSP_POOL_CACHE.ADD);
export const hspPoolCacheUpdate = createAction<string[]>(HSP_POOL_CACHE.UPDATE);
export const hspPoolCacheMerge = createAction<HSPPoolSuggestModel[]>(HSP_POOL_CACHE.MERGE_ITEMS);
export const hspPoolCacheError = createAction<string>(HSP_POOL_CACHE.ERROR);
export const hspPoolCacheRemove = createAction<string>(HSP_POOL_CACHE.REMOVE);