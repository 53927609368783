import {MAILING_LIST_CACHE} from './Actions';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {MailingListListItem} from '../../types/views/MailingListListItem';

export interface IMailingListsCacheState {
  mailingListCacheUpdating: boolean | false;
  mailingListCacheError: string | undefined;
  mailingListPendingIdList: string[];
  mailingListCache: MailingListListItem[];
}

const initialState: IMailingListsCacheState = {
  mailingListCacheUpdating: false,
  mailingListCacheError: undefined,
  mailingListPendingIdList: [],
  mailingListCache: [],
}

const mailingListsCacheReducer = createReducer(initialState, {
  [MAILING_LIST_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.mailingListPendingIdList = [...new Set([...state.mailingListPendingIdList, ...action.payload])];
    state.mailingListCacheError = undefined;
  },
  [MAILING_LIST_CACHE.UPDATE]: (state) => {
    state.mailingListPendingIdList = [];
    state.mailingListCacheUpdating = true;
    state.mailingListCacheError = undefined;
  },
  [MAILING_LIST_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<MailingListListItem[]>) => {
    let currentMailingListsCache = state.mailingListCache;
    action.payload.map(item => {
      if (!currentMailingListsCache.find(x => x.id === item.id)) {
        currentMailingListsCache.push(item);
      }
    });
    state.mailingListCache = currentMailingListsCache;
    state.mailingListCacheUpdating = false;
    state.mailingListCacheError = undefined;
  },
  [MAILING_LIST_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.mailingListCache = state.mailingListCache.filter(x => x.id !== action.payload);
    state.mailingListCacheUpdating = false;
    state.mailingListCacheError = undefined;
  },
});

export default mailingListsCacheReducer;