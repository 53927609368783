import { AlumniStatusEnum } from './Enums';
import {AlumniTagModel} from './AlumniTagModel';
import {AddressViewModel} from './AddressViewModel';
import { AlumniEarlyProfessionalModel } from './AlumniEarlyProfessionalModel';
import {AlumniEarlyProfessionalHistoryModel} from './AlumniEarlyProfessionalHistoryModel';

export class AlumniDetailModel implements IAlumniDetailModel {
  earlyProfessionalAlumniSettingId?: string | undefined;
  alumniEarlyProfessionalSetting?: AlumniEarlyProfessionalModel | undefined;
  alumniEarlyProfessionalHistory?: AlumniEarlyProfessionalHistoryModel[] | undefined;
  earlyProfessionalStepsComplete?: number | undefined;
  earlyProfessionalPercent?: number | undefined;
  dateInactivated?: Date | undefined;
  inactivatedPersonId?: string | undefined;
  inactivatedNotes?: string | undefined;
  inactivatedReason?: string | undefined;
  alumniStatus?: AlumniStatusEnum | undefined;
  alumniTags?: AlumniTagModel[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  interestsSkills?: string | undefined;
  postGradStudy?: string | undefined;
  dateAlumniSet?: Date | undefined;
  alumniSetByPersonId?: string | undefined;
  /** Company worked at */
  alumniCompany?: string | undefined;
  alumniCompanyId?: string | undefined;
  /** Current job title */
  alumniJobTitle?: string | undefined;
  /** City now working */
  alumniSuburb?: string | undefined;
  /** State now working */
  alumniState?: string | undefined;
  /** Country (code only) now working */
  alumniCountryCode?: string | undefined;
  alumniNotes?: string | undefined;
  quickNotes?: string | undefined;
  graduatedYear?: number | undefined;
  graduatedSemester?: number | undefined;
  degreePoolId?: string[] | undefined;
  postGradUniversity?: string | undefined;
  postGradCampus?: string | undefined;
  postGradDegree?: string | undefined;
  postGradMajor?: string | undefined;
  postGradDegreeStartYear?: number | undefined;
  postGradDegreeStartNumber?: number | undefined;
  postGradDegreeEndYear?: number | undefined;
  postGradDegreeEndNumber?: number | undefined;

  constructor(data?: IAlumniDetailModel) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.earlyProfessionalAlumniSettingId = Data['earlyProfessionalAlumniSettingId'];
      this.alumniEarlyProfessionalSetting = Data['alumniEarlyProfessionalSetting'] ? AlumniEarlyProfessionalModel.fromJS(Data['alumniEarlyProfessionalSetting']) : <any>undefined;
      if (Array.isArray(Data['alumniEarlyProfessionalHistory'])) {
        this.alumniEarlyProfessionalHistory = [] as any;
        for (const item of Data['alumniEarlyProfessionalHistory'])
          this.alumniEarlyProfessionalHistory!.push(AlumniEarlyProfessionalHistoryModel.fromJS(item));
      }
      this.earlyProfessionalStepsComplete = Data['earlyProfessionalStepsComplete'];
      this.earlyProfessionalPercent = Data['earlyProfessionalPercent'];
      this.dateInactivated = Data['dateInactivated'] ? new Date(Data['dateInactivated'].toString()) : <any>undefined;
      this.inactivatedPersonId = Data['inactivatedPersonId'];
      this.inactivatedNotes = Data['inactivatedNotes'];
      this.inactivatedReason = Data['inactivatedReason'];
      this.alumniStatus = Data['alumniStatus'];
      if (Array.isArray(Data['alumniTags'])) {
        this.alumniTags = [] as any;
        for (const item of Data['alumniTags'])
          this.alumniTags!.push(AlumniTagModel.fromJS(item));
      }
      this.locationAddress = Data['locationAddress'] ? AddressViewModel.fromJS(Data['locationAddress']) : <any>undefined;
      this.interestsSkills = Data['interestsSkills'];
      this.postGradStudy = Data['postGradStudy'];
      this.dateAlumniSet = Data['dateAlumniSet'] ? new Date(Data['dateAlumniSet'].toString()) : <any>undefined;
      this.alumniSetByPersonId = Data['alumniSetByPersonId'];
      this.alumniCompany = Data['alumniCompany'];
      this.alumniCompanyId = Data['alumniCompanyId'];
      this.alumniJobTitle = Data['alumniJobTitle'];
      this.alumniSuburb = Data['alumniSuburb'];
      this.alumniState = Data['alumniState'];
      this.alumniCountryCode = Data['alumniCountryCode'];
      this.alumniNotes = Data['alumniNotes'];
      this.quickNotes = Data['quickNotes'];
      this.graduatedYear = Data['graduatedYear'];
      this.graduatedSemester = Data['graduatedSemester'];
      if (Array.isArray(Data['degreePoolId'])) {
        this.degreePoolId = [] as any;
        for (const item of Data['degreePoolId'])
          this.degreePoolId!.push(item);
      }
      this.postGradUniversity = Data['postGradUniversity'];
      this.postGradCampus = Data['postGradCampus'];
      this.postGradDegree = Data['postGradDegree'];
      this.postGradMajor = Data['postGradMajor'];
      this.postGradDegreeStartYear = Data['postGradDegreeStartYear'];
      this.postGradDegreeStartNumber = Data['postGradDegreeStartNumber'];
      this.postGradDegreeEndYear = Data['postGradDegreeEndYear'];
      this.postGradDegreeEndNumber = Data['postGradDegreeEndNumber'];
    }
  }

  static fromJS(data: any): AlumniDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new AlumniDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['earlyProfessionalAlumniSettingId'] = this.earlyProfessionalAlumniSettingId;
    data['alumniEarlyProfessionalSetting'] = this.alumniEarlyProfessionalSetting ? this.alumniEarlyProfessionalSetting.toJSON() : <any>undefined;
    if (Array.isArray(this.alumniEarlyProfessionalHistory)) {
      data['alumniEarlyProfessionalHistory'] = [];
      for (const item of this.alumniEarlyProfessionalHistory)
        data['alumniEarlyProfessionalHistory'].push(item.toJSON());
    }
    data['earlyProfessionalStepsComplete'] = this.earlyProfessionalStepsComplete;
    data['earlyProfessionalPercent'] = this.earlyProfessionalPercent;
    data['dateInactivated'] = this.dateInactivated ? this.dateInactivated.toISOString() : <any>undefined;
    data['inactivatedPersonId'] = this.inactivatedPersonId;
    data['inactivatedNotes'] = this.inactivatedNotes;
    data['inactivatedReason'] = this.inactivatedReason;
    data['alumniStatus'] = this.alumniStatus;
    if (Array.isArray(this.alumniTags)) {
      data['alumniTags'] = [];
      for (const item of this.alumniTags)
        data['alumniTags'].push(item.toJSON());
    }
    data['locationAddress'] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data['interestsSkills'] = this.interestsSkills;
    data['postGradStudy'] = this.postGradStudy;
    data['dateAlumniSet'] = this.dateAlumniSet ? this.dateAlumniSet.toISOString() : <any>undefined;
    data['alumniSetByPersonId'] = this.alumniSetByPersonId;
    data['alumniCompany'] = this.alumniCompany;
    data['alumniCompanyId'] = this.alumniCompanyId;
    data['alumniJobTitle'] = this.alumniJobTitle;
    data['alumniSuburb'] = this.alumniSuburb;
    data['alumniState'] = this.alumniState;
    data['alumniCountryCode'] = this.alumniCountryCode;
    data['alumniNotes'] = this.alumniNotes;
    data['quickNotes'] = this.quickNotes;
    data['graduatedYear'] = this.graduatedYear;
    data['graduatedSemester'] = this.graduatedSemester;
    if (Array.isArray(this.degreePoolId)) {
      data['degreePoolId'] = [];
      for (const item of this.degreePoolId)
        data['degreePoolId'].push(item);
    }
    data['postGradUniversity'] = this.postGradUniversity;
    data['postGradCampus'] = this.postGradCampus;
    data['postGradDegree'] = this.postGradDegree;
    data['postGradMajor'] = this.postGradMajor;
    data['postGradDegreeStartYear'] = this.postGradDegreeStartYear;
    data['postGradDegreeStartNumber'] = this.postGradDegreeStartNumber;
    data['postGradDegreeEndYear'] = this.postGradDegreeEndYear;
    data['postGradDegreeEndNumber'] = this.postGradDegreeEndNumber;
    return data;
  }
}

export interface IAlumniDetailModel {
  earlyProfessionalAlumniSettingId?: string | undefined;
  alumniEarlyProfessionalSetting?: AlumniEarlyProfessionalModel | undefined;
  alumniEarlyProfessionalHistory?: AlumniEarlyProfessionalHistoryModel[] | undefined;
  earlyProfessionalStepsComplete?: number | undefined;
  earlyProfessionalPercent?: number | undefined;
  dateInactivated?: Date | undefined;
  inactivatedPersonId?: string | undefined;
  inactivatedNotes?: string | undefined;
  inactivatedReason?: string | undefined;
  alumniStatus?: AlumniStatusEnum | undefined;
  alumniTags?: AlumniTagModel[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  interestsSkills?: string | undefined;
  postGradStudy?: string | undefined;
  dateAlumniSet?: Date | undefined;
  alumniSetByPersonId?: string | undefined;
  /** Company worked at */
  alumniCompany?: string | undefined;
  alumniCompanyId?: string | undefined;
  /** Current job title */
  alumniJobTitle?: string | undefined;
  /** City now working */
  alumniSuburb?: string | undefined;
  /** State now working */
  alumniState?: string | undefined;
  /** Country (code only) now working */
  alumniCountryCode?: string | undefined;
  alumniNotes?: string | undefined;
  quickNotes?: string | undefined;
  graduatedYear?: number | undefined;
  graduatedSemester?: number | undefined;
  degreePoolId?: string[] | undefined;
  postGradUniversity?: string | undefined;
  postGradCampus?: string | undefined;
  postGradDegree?: string | undefined;
  postGradMajor?: string | undefined;
  postGradDegreeStartYear?: number | undefined;
  postGradDegreeStartNumber?: number | undefined;
  postGradDegreeEndYear?: number | undefined;
  postGradDegreeEndNumber?: number | undefined;
}