export class ThreadMetadataModel implements IThreadMetadataModel {
  title?: string | undefined;
  id?: string | undefined;
  displayContext?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  university?: string | undefined;
  campus?: string | undefined;
  brandName?: string | undefined;
  roleTitle?: string | undefined;
  startSeasonDate?: string | undefined;
  endSeasonDate?: string | undefined;
  addressLineOne?: string | undefined;
  addressLineTwo?: string | undefined;
  addressSuburb?: string | undefined;
  addressState?: string | undefined;
  addressPostcode?: string | undefined;
  contactTitle?: string | undefined;
  degreeStart?: string | undefined;
  degreeEnd?: string | undefined;
  icsStart?: string | undefined;
  icsEnd?: string | undefined;
  cvUrl?: string | undefined;
  placementStartDate?: string | undefined;
  placementEndDate?: string | undefined;
  placementAddressLineOne?: string | undefined;
  placementAddressLineTwo?: string | undefined;
  placementAddressSuburb?: string | undefined;
  placementAddressState?: string | undefined;
  placementAddressPostcode?: string | undefined;
  placementCompanyName?: string | undefined;
  managerFirstName?: string | undefined;
  managerLastName?: string | undefined;
  managerEmail?: string | undefined;
  managerPhoneNumber?: string | undefined;
  interviewToolkitUrl?: string | undefined;

  constructor(data?: IThreadMetadataModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.title = Data["title"];
      this.id = Data["id"];
      this.displayContext = Data["displayContext"];
      this.firstName = Data["firstName"];
      this.lastName = Data["lastName"];
      this.university = Data["university"];
      this.campus = Data["campus"];
      this.brandName = Data["brandName"];
      this.roleTitle = Data["roleTitle"];
      this.startSeasonDate = Data["startSeasonDate"];
      this.endSeasonDate = Data["endSeasonDate"];
      this.addressLineOne = Data["addressLineOne"];
      this.addressLineTwo = Data["addressLineTwo"];
      this.addressSuburb = Data["addressSuburb"];
      this.addressState = Data["addressState"];
      this.addressPostcode = Data["addressPostcode"];
      this.contactTitle = Data["contactTitle"];
      this.degreeStart = Data["degreeStart"];
      this.degreeEnd = Data["degreeEnd"];
      this.icsStart = Data["icsStart"];
      this.icsEnd = Data["icsEnd"];
      this.cvUrl = Data["cvUrl"];
      this.placementStartDate = Data["placementStartDate"];
      this.placementEndDate = Data["placementEndDate"];
      this.placementAddressLineOne = Data["placementAddressLineOne"];
      this.placementAddressLineTwo = Data["placementAddressLineTwo"];
      this.placementAddressSuburb = Data["placementAddressSuburb"];
      this.placementAddressState = Data["placementAddressState"];
      this.placementAddressPostcode = Data["placementAddressPostcode"];
      this.placementCompanyName = Data["placementCompanyName"];
      this.managerFirstName = Data["managerFirstName"];
      this.managerLastName = Data["managerLastName"];
      this.managerEmail = Data["managerEmail"];
      this.managerPhoneNumber = Data["managerPhoneNumber"];
      this.interviewToolkitUrl = Data["interviewToolkitUrl"];
    }
  }

  static fromJS(data: any): ThreadMetadataModel {
    data = typeof data === 'object' ? data : {};
    let result = new ThreadMetadataModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["title"] = this.title;
    data["id"] = this.id;
    data["displayContext"] = this.displayContext;
    data["firstName"] = this.firstName;
    data["lastName"] = this.lastName;
    data["university"] = this.university;
    data["campus"] = this.campus;
    data["brandName"] = this.brandName;
    data["roleTitle"] = this.roleTitle;
    data["startSeasonDate"] = this.startSeasonDate;
    data["endSeasonDate"] = this.endSeasonDate;
    data["addressLineOne"] = this.addressLineOne;
    data["addressLineTwo"] = this.addressLineTwo;
    data["addressSuburb"] = this.addressSuburb;
    data["addressState"] = this.addressState;
    data["addressPostcode"] = this.addressPostcode;
    data["contactTitle"] = this.contactTitle;
    data["degreeStart"] = this.degreeStart;
    data["degreeEnd"] = this.degreeEnd;
    data["icsStart"] = this.icsStart;
    data["icsEnd"] = this.icsEnd;
    data["cvUrl"] = this.cvUrl;
    data["placementStartDate"] = this.placementStartDate;
    data["placementEndDate"] = this.placementEndDate;
    data["placementAddressLineOne"] = this.placementAddressLineOne;
    data["placementAddressLineTwo"] = this.placementAddressLineTwo;
    data["placementAddressSuburb"] = this.placementAddressSuburb;
    data["placementAddressState"] = this.placementAddressState;
    data["placementAddressPostcode"] = this.placementAddressPostcode;
    data["placementCompanyName"] = this.placementCompanyName;
    data["managerFirstName"] = this.managerFirstName;
    data["managerLastName"] = this.managerLastName;
    data["managerEmail"] = this.managerEmail;
    data["managerPhoneNumber"] = this.managerPhoneNumber;
    data["interviewToolkitUrl"] = this.interviewToolkitUrl;
    return data;
  }
}

export interface IThreadMetadataModel {
  title?: string | undefined;
  id?: string | undefined;
  displayContext?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  university?: string | undefined;
  campus?: string | undefined;
  brandName?: string | undefined;
  roleTitle?: string | undefined;
  startSeasonDate?: string | undefined;
  endSeasonDate?: string | undefined;
  addressLineOne?: string | undefined;
  addressLineTwo?: string | undefined;
  addressSuburb?: string | undefined;
  addressState?: string | undefined;
  addressPostcode?: string | undefined;
  contactTitle?: string | undefined;
  degreeStart?: string | undefined;
  degreeEnd?: string | undefined;
  icsStart?: string | undefined;
  icsEnd?: string | undefined;
  cvUrl?: string | undefined;
  placementStartDate?: string | undefined;
  placementEndDate?: string | undefined;
  placementAddressLineOne?: string | undefined;
  placementAddressLineTwo?: string | undefined;
  placementAddressSuburb?: string | undefined;
  placementAddressState?: string | undefined;
  placementAddressPostcode?: string | undefined;
  placementCompanyName?: string | undefined;
  managerFirstName?: string | undefined;
  managerLastName?: string | undefined;
  managerEmail?: string | undefined;
  managerPhoneNumber?: string | undefined;
  interviewToolkitUrl?: string | undefined;
}