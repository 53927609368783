export class StudentUpdatesSearchModel implements IStudentUpdatesSearchModel {
  personId?: string | undefined;
  fullName?: string | undefined;
  email?: string | undefined;
  updatePipelineCount?: number | undefined;
  updatePipelineLastUpdated?: Date | undefined;

  constructor(data?: IStudentUpdatesSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.fullName = Data["fullName"];
      this.email = Data["email"];
      this.updatePipelineCount = Data["updatePipelineCount"];
      this.updatePipelineLastUpdated = Data["updatePipelineLastUpdated"] ? new Date(Data["updatePipelineLastUpdated"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): StudentUpdatesSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentUpdatesSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["fullName"] = this.fullName;
    data["email"] = this.email;
    data["updatePipelineCount"] = this.updatePipelineCount;
    data["updatePipelineLastUpdated"] = this.updatePipelineLastUpdated ? this.updatePipelineLastUpdated.toISOString() : <any>undefined;
    return data;
  }
}

export interface IStudentUpdatesSearchModel {
  personId?: string | undefined;
  fullName?: string | undefined;
  email?: string | undefined;
  updatePipelineCount?: number | undefined;
  updatePipelineLastUpdated?: Date | undefined;
}