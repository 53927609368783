import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface VolunteerPaths {
  VolunteerEdit: string,
  VolunteerPromote: string,
  VolunteerCreate: string,
  VolunteerProfile: string,
  VolunteerSearch: string,
}

export const volunteerPaths: VolunteerPaths = {
  VolunteerEdit: '/advisor/volunteers/edit/:id',
  VolunteerPromote: '/advisor/volunteers/promote/:id',
  VolunteerCreate: '/advisor/volunteers/add',
  VolunteerProfile: '/advisor/volunteers/profile/:id',
  VolunteerSearch: '/advisor/volunteers/search',
};

export interface IVolunteerProfileTabs {
  activity: RouteTabParameter;
  profile: RouteTabParameter;
  files: RouteTabParameter;
  changeRequests: RouteTabParameter;
}

export const volunteerProfileTabs: IVolunteerProfileTabs = {
  activity: new RouteTabParameter(0, 'Activity', 'activity'),
  profile: new RouteTabParameter(1, 'Profile', 'profile'),
  files: new RouteTabParameter(4, 'Files', 'files'),
  changeRequests: new RouteTabParameter(6, 'Change Requests', 'change'),
}

const profileTabs = [
  volunteerProfileTabs.activity,
  volunteerProfileTabs.profile,
  volunteerProfileTabs.files,
  volunteerProfileTabs.changeRequests
];

export const getVolunteerProfileTab = (query: string | undefined | null) => profileTabs.find(x => x.query === query);
