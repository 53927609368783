import React, {useContext, useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import Menu from '@mui/material/Menu';
import {toggleNavCollapsed} from '../../../../redux/ui/Actions';
import Box from '@mui/material/Box';
import SearchBar from '../../SearchBar';
import useStyles from './AppHeader.style';
import Notifications from '../../Notifications';
import clsx from 'clsx';
import {useAppDispatch} from '../../../utility/AppHooks';
import {matchRoutes} from 'react-router-config';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import AppContext from '../../../utility/AppContext';
import {useHistory, useLocation, Link, NavLink} from 'react-router-dom';
import {dashboardPaths} from '../../../../modules/dashboard/paths';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../../../types/AppRoutesPropType';
import ChangeRequest from '../../../../modules/topbar/ChangeRequest';
import GlobalSearch from '../../../../modules/topbar/GlobalSearch';
import HelpDropdown from '../../../../modules/topbar/Help';
import History from '../../../../modules/topbar/History';
import {historyLoad} from '../../../../redux/history/Actions';
import {studentChangeRequestLoad, contactChangeRequestLoad} from '../../../../redux/changeRequest/Actions';

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    routes,
  } = useContext<AppContextPropsType>(AppContext);
  const {pathname} = useLocation();
  const currentRoute = matchRoutes(routes, pathname)[0].route as any;
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!init)
    {
      setInit(true);
      dispatch(historyLoad());
      dispatch(studentChangeRequestLoad());
      dispatch(contactChangeRequestLoad());
    }
  }, [dispatch]);

  const handleHomeClicked = () => {
    history.push(dashboardPaths.LandingPage);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem className={clsx(classes.menuItemRoot, classes.sectionMobile)}>
        <Notifications />
        <GlobalSearch />
        <ChangeRequest />
        <History />
        <HelpDropdown />
      </MenuItem>
    </Menu>
  );

  return <>
    <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
      <Toolbar className={classes.appToolbar}>
        <IconButton
          edge='start'
          className={classes.menuButton}
          color='inherit'
          aria-label='open drawer'
          onClick={() => dispatch(toggleNavCollapsed())}
          >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <Box>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={() => handleHomeClicked()}
            >
            <HomeIcon className={classes.breadcrumbHomeIcon} />
          </IconButton>
        </Box>
        {currentRoute.breadcrumbs?.map((el: BreadcrumbItemProps, idx: number) => {
         if (el.path) {
          return <Box component="div" className={classes.breadcrumbItem} key={`appHeader-bc-${idx}`}>
           <ArrowIcon className={classes.breadcrumbIcon} /> <NavLink to={el.path}>{el.name}</NavLink>
         </Box>}
         else {
           return <Box component="div" className={classes.breadcrumbItem} key={`appHeader-bc-${idx}`}>
             <ArrowIcon className={classes.breadcrumbIcon} /> {el.name}
           </Box>
         }
        })}
        <Box className={classes.grow} />
        <Box className={classes.sectionDesktop}>
          <GlobalSearch />
          <ChangeRequest />
          <History />
          <Notifications />
          <HelpDropdown />
        </Box>
        <Box className={classes.sectionMobile}>
          <IconButton
            aria-label='show more'
            aria-controls={mobileMenuId}
            aria-haspopup='true'
            onClick={handleMobileMenuOpen}
            color='inherit'
            >
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
    {renderMobileMenu}
  </>;
};
export default AppHeader;
