import {EventModel} from './EventModel';

export class StudentScheduleModel implements IStudentScheduleModel {
  updatedByUserId?: string | undefined;
  dateUpdated?: Date | undefined;
  timeSlots?: EventModel[] | undefined;

  constructor(data?: IStudentScheduleModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.updatedByUserId = Data["updatedByUserId"];
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      if (Array.isArray(Data["timeSlots"])) {
        this.timeSlots = [] as any;
        for (let item of Data["timeSlots"])
          this.timeSlots!.push(EventModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): StudentScheduleModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentScheduleModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["updatedByUserId"] = this.updatedByUserId;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    if (Array.isArray(this.timeSlots)) {
      data["timeSlots"] = [];
      for (let item of this.timeSlots)
        data["timeSlots"].push(item.toJSON());
    }
    return data;
  }
}

export interface IStudentScheduleModel {
  updatedByUserId?: string | undefined;
  dateUpdated?: Date | undefined;
  timeSlots?: EventModel[] | undefined;
}