export class CompletionSourceConfigModel implements ICompletionSourceConfigModel {
  systemAutomation?: string | undefined;
  advisorPlatform?: string | undefined;
  studentApp?: string | undefined;
  employerEngagement?: string | undefined;
  studentPortal?: string | undefined;

  constructor(data?: ICompletionSourceConfigModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.systemAutomation = Data["systemAutomation"];
      this.advisorPlatform = Data["advisorPlatform"];
      this.studentApp = Data["studentApp"];
      this.employerEngagement = Data["employerEngagement"];
      this.studentPortal = Data["studentPortal"];
    }
  }

  static fromJS(data: any): CompletionSourceConfigModel {
    data = typeof data === 'object' ? data : {};
    let result = new CompletionSourceConfigModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["systemAutomation"] = this.systemAutomation;
    data["advisorPlatform"] = this.advisorPlatform;
    data["studentApp"] = this.studentApp;
    data["employerEngagement"] = this.employerEngagement;
    data["studentPortal"] = this.studentPortal;
    return data;
  }
}

export interface ICompletionSourceConfigModel {
  systemAutomation?: string | undefined;
  advisorPlatform?: string | undefined;
  studentApp?: string | undefined;
  employerEngagement?: string | undefined;
  studentPortal?: string | undefined;
}