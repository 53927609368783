import {AddressViewModel} from '../models/AddressViewModel';

export class ContactSearchModel implements IContactSearchModel {
  companyId?: string | undefined;
  locationAddress?: AddressViewModel | undefined;
  contactTags?: string[] | undefined;
  jobTitle?: string | undefined;
  quickNotes?: string | undefined;
  isPrimary?: boolean | undefined;
  isBilling?: boolean | undefined;
  isStudentContact?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;

  constructor(data?: IContactSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.companyId = Data["companyId"];
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      if (Array.isArray(Data["contactTags"])) {
        this.contactTags = [] as any;
        for (let item of Data["contactTags"])
          this.contactTags!.push(item);
      }
      this.jobTitle = Data["jobTitle"];
      this.quickNotes = Data["quickNotes"];
      this.isPrimary = Data["isPrimary"];
      this.isBilling = Data["isBilling"];
      this.isStudentContact = Data["isStudentContact"];
      this.isExecutiveSponsor = Data["isExecutiveSponsor"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
      this.primaryAdvisorFullName = Data["primaryAdvisorFullName"];
    }
  }

  static fromJS(data: any): ContactSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new ContactSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["companyId"] = this.companyId;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    if (Array.isArray(this.contactTags)) {
      data["contactTags"] = [];
      for (let item of this.contactTags)
        data["contactTags"].push(item);
    }
    data["jobTitle"] = this.jobTitle;
    data["quickNotes"] = this.quickNotes;
    data["isPrimary"] = this.isPrimary;
    data["isBilling"] = this.isBilling;
    data["isStudentContact"] = this.isStudentContact;
    data["isExecutiveSponsor"] = this.isExecutiveSponsor;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
    data["primaryAdvisorFullName"] = this.primaryAdvisorFullName;
    return data;
  }
}

export interface IContactSearchModel {
  companyId?: string | undefined;
  locationAddress?: AddressViewModel | undefined;
  contactTags?: string[] | undefined;
  jobTitle?: string | undefined;
  quickNotes?: string | undefined;
  isPrimary?: boolean | undefined;
  isBilling?: boolean | undefined;
  isStudentContact?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;
}