import React, {useState} from 'react';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {ListItem, Theme, IconButton} from '@mui/material';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {GlobalIndexModel} from '../../../types/views/GlobalIndexModel';
import PersonAvatar from '../../people/PersonAvatar';
import CompanyAvatar from '../../companies/CompanyAvatar';
import PersonLink from '../../people/PersonLink';
import CompanyLink from '../../companies/CompanyLink';
import GridContainer from '../../../@template/core/GridContainer';
import {Grid} from '@mui/material';
import {
  threeQuarterCol, optionsContentCol, optionsMenuCol, quarterCol,
} from '../../../@template/utility/FormLayout/layoutConfig';
import MultiMarkdownParser from '../../common/MultiMarkdownParser';
import {generatePath} from 'react-router';
import {NavLink} from 'react-router-dom';
import {studentPaths} from '../../students/paths';
import {contactPaths} from '../../contacts/paths';
import {volunteerPaths} from '../../volunteers/paths';
import {workShadowPaths} from '../../workShadow/paths';
import {companyPaths} from '../../companies/paths';
import ShowDescIcon from '@mui/icons-material/ArrowDropUp';
import NotShowDescIcon from '@mui/icons-material/ArrowDropDown';
import ProgramTags from '../../people/Common/ProgramTags';
import Chip from '@mui/material/Chip';
import CompanyIcon from '@mui/icons-material/Business';

const useStyles = makeStyles((theme: Theme) => ({
  textBase: {
    fontSize: 14,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    padding: '8px 10px',
  },
  titleLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.text.primary
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
  },
  chip: {
    backgroundColor: '#fde4d8',
    color: '#cb7f5c',
    borderRadius: '6px',
    fontWeight: Fonts.MEDIUM,
    marginRight: 2,
    marginBottom: 2,
    height: 'auto',
    '& .MuiSvgIcon-root': {
      color: '#cb7f5c',
    },
    '& .MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }
  },
  companyColour: {
    backgroundColor: '#f9f0cf',
    color: '#ADA483',
    '& .MuiSvgIcon-root': {
      color: '#ADA483',
    },
  },
}));

interface GlobalSearchItemProps {
  item: GlobalIndexModel;
  onClose(): void;
}

const GlobalSearchItem: React.FC<GlobalSearchItemProps> = ({item, onClose}) => {
  const classes = useStyles();

  const [openDesc, setOpenDesc] = useState<boolean>(false);

  const isStudent = item.entity?.includes('student');
  const isContact = item.entity?.includes('contact');
  const isVolunteer = item.entity?.includes('volunteer');
  const isWorkShadow = item.entity?.includes('work_shadow');
  const isAlumni = item.entity?.includes('alumni')
  const isAdvisor = item.entity?.includes('advisor');
  const isPerson = isStudent || isContact || isVolunteer || isWorkShadow || isAlumni;
  const isCompany = item.entity?.includes('company');

  const toggleDescription = () => {
    setOpenDesc(!openDesc);
  }

  const getLink = () => {
    if(isCompany)
    {
      return generatePath(companyPaths.CompanyProfile, { id: item.entityId });
    }
    if(isStudent || isWorkShadow || isAlumni)
    {
      return generatePath(studentPaths.StudentProfile, { id: item.entityId });
    }
    if(isContact)
    {
      return generatePath(contactPaths.ContactProfile, { id: item.entityId });
    }
    if(isVolunteer)
    {
      return generatePath(volunteerPaths.VolunteerProfile, { id: item.entityId });
    }
    return '';
  }

  return (
    <ListItem className={clsx(classes.listItemRoot, 'item-hover')}>
      <Box mr={4}>
        <ListItemAvatar className={classes.minWidth0}>
          {isPerson && <PersonAvatar personId={item.entityId ?? ''} size={32} />}
          {isCompany && <CompanyAvatar companyId={item.entityId??''} size={32} />}
        </ListItemAvatar>
      </Box>
      <GridContainer spacing={2}>
        <Grid item {...threeQuarterCol}>
          <GridContainer spacing={0}>
            <Grid item {...optionsContentCol}>
              <Box component="h4" onClick={onClose}>
                <NavLink to={getLink()} className={classes.titleLink}>
                  {isPerson && <PersonLink personId={item.entityId?? ''} />}
                  {isCompany && <CompanyLink companyId={item.entityId?? ''} />}
                  {!item?.entity && <span>{item?.title}</span>}
                </NavLink>
              </Box>
              <Box
                mr={2}
                component='p'
              >
                {!openDesc && <MultiMarkdownParser>{item.miniDescription?? ''}</MultiMarkdownParser>}
                {openDesc && <MultiMarkdownParser>{item.description?? ''}</MultiMarkdownParser>}
              </Box>
            </Grid>
            <Grid item {...optionsMenuCol} fontSize={16}>
              {item.description && item.description !== item.miniDescription && <IconButton
                style={{height: 30, width: 30}}
                onClick={() => toggleDescription()}
              >
                {!openDesc && <NotShowDescIcon />}
                {openDesc && <ShowDescIcon />}
              </IconButton>}
            </Grid>
          </GridContainer>
        </Grid>
        <Grid item {...quarterCol}>
          <ProgramTags onClick={onClose} id={item.entityId} isStudent={isStudent} isClient={isContact} isAdvisor={isAdvisor} isVolunteer={isVolunteer} isWorkShadow={isWorkShadow} isAlumni={isAlumni}/>
          {isCompany && <Chip className={clsx(classes.chip, classes.companyColour)} sx={{ fontSize: 12 }} icon={<CompanyIcon sx={{ fontSize: 14 }} />} label="Company" />}
        </Grid>
      </GridContainer>
    </ListItem>
  );
};

export default GlobalSearchItem;
