import {PhoneModel} from '../models/PhoneModel';
import {VolunteerDetailModel} from '../models/VolunteerDetailModel';

export class VolunteerPersonModel implements IVolunteerPersonModel {
  id?: string | undefined;
  personNumber?: number | undefined;
  createdByUserId?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  gender?: string | undefined;
  genderPronouns?: string | undefined;
  /** Phone numbers for this volunteer */
  phoneNumbers?: PhoneModel[] | undefined;
  /** URL of facebook profile */
  facebookUrl?: string | undefined;
  /** URL of LinkedIn profile */
  linkedinUrl?: string | undefined;
  isDeleted?: boolean | undefined;
  isNoContact?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  /** Volunteer specific information */
  volunteerDetail?: VolunteerDetailModel | undefined;
  restoreDeleted?: boolean | undefined;
  isVolunteerPortalUser?: boolean | undefined;
  dateVolunteerPortalCreated?: Date | undefined;
  dateVolunteerPortalUpdated?: Date | undefined;

  constructor(data?: IVolunteerPersonModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.personNumber = Data["personNumber"];
      this.createdByUserId = Data["createdByUserId"];
      this.firstName = Data["firstName"];
      this.middleName = Data["middleName"];
      this.lastName = Data["lastName"];
      this.birthName = Data["birthName"];
      this.email = Data["email"];
      this.altEmail = Data["altEmail"];
      this.gender = Data["gender"];
      this.genderPronouns = Data["genderPronouns"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.facebookUrl = Data["facebookUrl"];
      this.linkedinUrl = Data["linkedinUrl"];
      this.isDeleted = Data["isDeleted"];
      this.isNoContact = Data["isNoContact"];
      this.isClient = Data["isClient"];
      this.isStudent = Data["isStudent"];
      this.volunteerDetail = Data["volunteerDetail"] ? VolunteerDetailModel.fromJS(Data["volunteerDetail"]) : <any>undefined;
      this.restoreDeleted = Data["restoreDeleted"];
      this.isVolunteerPortalUser = Data["isVolunteerPortalUser"];
      this.dateVolunteerPortalCreated = Data["dateVolunteerPortalCreated"] ? new Date(Data["dateVolunteerPortalCreated"].toString()) : <any>undefined;
      this.dateVolunteerPortalUpdated = Data["dateVolunteerPortalUpdated"] ? new Date(Data["dateVolunteerPortalUpdated"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): VolunteerPersonModel {
    data = typeof data === 'object' ? data : {};
    let result = new VolunteerPersonModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["personNumber"] = this.personNumber;
    data["createdByUserId"] = this.createdByUserId;
    data["firstName"] = this.firstName;
    data["middleName"] = this.middleName;
    data["lastName"] = this.lastName;
    data["birthName"] = this.birthName;
    data["email"] = this.email;
    data["altEmail"] = this.altEmail;
    data["gender"] = this.gender;
    data["genderPronouns"] = this.genderPronouns;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item.toJSON());
    }
    data["facebookUrl"] = this.facebookUrl;
    data["linkedinUrl"] = this.linkedinUrl;
    data["isDeleted"] = this.isDeleted;
    data["isNoContact"] = this.isNoContact;
    data["isClient"] = this.isClient;
    data["isStudent"] = this.isStudent;
    data["volunteerDetail"] = this.volunteerDetail ? this.volunteerDetail.toJSON() : <any>undefined;
    data["restoreDeleted"] = this.restoreDeleted;
    data["isVolunteerPortalUser"] = this.isVolunteerPortalUser;
    data["dateVolunteerPortalCreated"] = this.dateVolunteerPortalCreated ? this.dateVolunteerPortalCreated.toISOString() : <any>undefined;
    data["dateVolunteerPortalUpdated"] = this.dateVolunteerPortalUpdated ? this.dateVolunteerPortalUpdated.toISOString() : <any>undefined;
    return data;
  }
}

export interface IVolunteerPersonModel {
  id?: string | undefined;
  personNumber?: number | undefined;
  createdByUserId?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  gender?: string | undefined;
  genderPronouns?: string | undefined;
  /** Phone numbers for this volunteer */
  phoneNumbers?: PhoneModel[] | undefined;
  /** URL of facebook profile */
  facebookUrl?: string | undefined;
  /** URL of LinkedIn profile */
  linkedinUrl?: string | undefined;
  isDeleted?: boolean | undefined;
  isNoContact?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  /** Volunteer specific information */
  volunteerDetail?: VolunteerDetailModel | undefined;
  restoreDeleted?: boolean | undefined;
  isVolunteerPortalUser?: boolean | undefined;
  dateVolunteerPortalCreated?: Date | undefined;
  dateVolunteerPortalUpdated?: Date | undefined;
}