import {formatDate} from '../../@template/helpers/datetimes';
import {PoolSearchParamsModel} from "./PoolSearchParamsModel";

export const defaultForOpportunitySearchParamsModel = () => {
  return PoolSearchParamsModel.fromJS({
    searchText: '',
    advisorUsers: [],
    primaryOnly: true,
    noPrimaryAppointed: false,
    noAdvisorAppointed: false,
    byTeam: false,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    companyIds: [],
    contactPersonIds: [],
    degreePoolIds: [],
    state: [],
    countryCode: 'AU',
    statusFlags: ['Potential', 'Active'],
    capacityFilter: 'Available',
    partnerTags: [],
    partnerStatusFlags: ['Potential', 'Active'],
    clientTags: [],
    clientStatusFlags: ['Potential', 'Pipeline', 'Active'],
    educationTags: [],
    educationStatusFlags: ['Potential', 'Pipeline', 'Active'],
    isNoneType: false,
    notArchived: true,
    includeArchived: false,
    onlyArchived: false,
    seasonFromYear: undefined,
    seasonFromSeason: undefined,
    opportunitySortProperty: undefined,
    opportunitySortOrder: undefined,
    remoteFlexible: false
  });
}

export const defaultForLocalOpportunitySearchParamsModel = () => {
  return OpportunitySearchParamsModel.fromJS({
    distance: 20,
    geoPoint: '-33.8688, 151.2093',
    statusFlags: ['Potential', 'Active'],
    companyIds: [],
    degreePoolIds: []
  });
}

export class OpportunitySearchParamsModel implements IOpportunitySearchParamsModel {
  /** Searches name, description */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  createdFrom?: Date | undefined;
  createdTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  companyIds?: string[] | undefined;
  contactPersonIds?: string[] | undefined;
  degreePoolIds?: string[] | undefined;
  studentPersonId?: string | undefined;
  progressFrom?: number | undefined;
  progressTo?: number | undefined;
  countryCode?: string | undefined;
  state?: string[] | undefined;
  seasonFromSeason?: number | undefined;
  seasonFromYear?: number | undefined;
  seasonToYear?: number | undefined;
  seasonToSeason?: number | undefined;
  billingStatus?: string | undefined;
  isNoSeason?: boolean | undefined;
  studentStatusFlags?: string[] | undefined;
  capacityFilter?: string | undefined;
  distance?: number | undefined;
  isPartner?: boolean | undefined;
  partnerTags?: string[] | undefined;
  partnerStatusFlags?: string[] | undefined;
  partnerType?: string | undefined;
  isClient?: boolean | undefined;
  clientTags?: string[] | undefined;
  clientStatusFlags?: string[] | undefined;
  isEducation?: boolean | undefined;
  educationTags?: string[] | undefined;
  educationStatusFlags?: string[] | undefined;
  institutionType?: string | undefined;
  isNoneType?: boolean | undefined;
  companyType?: string | undefined;
  geoPoint?: string | undefined;
  remoteFlexible?: boolean | undefined;
  opportunitySortProperty?: string | undefined;
  opportunitySortOrder?: string | undefined;

  constructor(data?: IOpportunitySearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      if (Array.isArray(Data["statusFlags"])) {
        this.statusFlags = [] as any;
        for (let item of Data["statusFlags"])
          this.statusFlags!.push(item);
      }
      this.createdFrom = Data["createdFrom"] ? new Date(Data["createdFrom"].toString()) : <any>undefined;
      this.createdTo = Data["createdTo"] ? new Date(Data["createdTo"].toString()) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data["primaryOnly"];
      this.advisorSelected = Data["advisorSelected"];
      this.excludePrimarySelected = Data["excludePrimarySelected"];
      this.noPrimaryAppointed = Data["noPrimaryAppointed"];
      this.noAdvisorAppointed = Data["noAdvisorAppointed"];
      this.byTeam = Data["byTeam"];
      this.advisorTeamId = Data["advisorTeamId"];
      this.advisorSubTeamId = Data["advisorSubTeamId"];
      this.byAdvisorTeam = Data["byAdvisorTeam"];
      this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
      if (Array.isArray(Data["companyIds"])) {
        this.companyIds = [] as any;
        for (let item of Data["companyIds"])
          this.companyIds!.push(item);
      }
      if (Array.isArray(Data["contactPersonIds"])) {
        this.contactPersonIds = [] as any;
        for (let item of Data["contactPersonIds"])
          this.contactPersonIds!.push(item);
      }
      if (Array.isArray(Data["degreePoolIds"])) {
        this.degreePoolIds = [] as any;
        for (let item of Data["degreePoolIds"])
          this.degreePoolIds!.push(item);
      }
      this.studentPersonId = Data["studentPersonId"];
      this.progressFrom = Data["progressFrom"];
      this.progressTo = Data["progressTo"];
      this.countryCode = Data["countryCode"];
      if (Array.isArray(Data["state"])) {
        this.state = [] as any;
        for (let item of Data["state"])
          this.state!.push(item);
      }
      this.seasonFromSeason = Data["seasonFromSeason"];
      this.seasonFromYear = Data["seasonFromYear"];
      this.seasonToYear = Data["seasonToYear"];
      this.seasonToSeason = Data["seasonToSeason"];
      this.billingStatus = Data["billingStatus"];
      this.isNoSeason = Data["isNoSeason"];
      if (Array.isArray(Data["studentStatusFlags"])) {
        this.studentStatusFlags = [] as any;
        for (let item of Data["studentStatusFlags"])
          this.studentStatusFlags!.push(item);
      }
      this.capacityFilter = Data["capacityFilter"];
      this.distance = Data["distance"];
      this.isPartner = Data["isPartner"];
      if (Array.isArray(Data["partnerTags"])) {
        this.partnerTags = [] as any;
        for (let item of Data["partnerTags"])
          this.partnerTags!.push(item);
      }
      if (Array.isArray(Data["partnerStatusFlags"])) {
        this.partnerStatusFlags = [] as any;
        for (let item of Data["partnerStatusFlags"])
          this.partnerStatusFlags!.push(item);
      }
      this.partnerType = Data["partnerType"];
      this.isClient = Data["isClient"];
      if (Array.isArray(Data["clientTags"])) {
        this.clientTags = [] as any;
        for (let item of Data["clientTags"])
          this.clientTags!.push(item);
      }
      if (Array.isArray(Data["clientStatusFlags"])) {
        this.clientStatusFlags = [] as any;
        for (let item of Data["clientStatusFlags"])
          this.clientStatusFlags!.push(item);
      }
      this.isEducation = Data["isEducation"];
      if (Array.isArray(Data["educationTags"])) {
        this.educationTags = [] as any;
        for (let item of Data["educationTags"])
          this.educationTags!.push(item);
      }
      if (Array.isArray(Data["educationStatusFlags"])) {
        this.educationStatusFlags = [] as any;
        for (let item of Data["educationStatusFlags"])
          this.educationStatusFlags!.push(item);
      }
      this.institutionType = Data["institutionType"];
      this.isNoneType = Data["isNoneType"];
      this.companyType = Data["companyType"];
      this.geoPoint = Data["geoPoint"];
      this.remoteFlexible = Data["remoteFlexible"];
      this.opportunitySortProperty = Data["opportunitySortProperty"];
      this.opportunitySortOrder = Data["opportunitySortOrder"];
    }
  }

  static fromJS(data: any): OpportunitySearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new OpportunitySearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    if (Array.isArray(this.statusFlags)) {
      data["statusFlags"] = [];
      for (let item of this.statusFlags)
        data["statusFlags"].push(item);
    }
    data["createdFrom"] = this.createdFrom ? formatDate(this.createdFrom) : <any>undefined;
    data["createdTo"] = this.createdTo ? formatDate(this.createdTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryOnly"] = this.primaryOnly;
    data["advisorSelected"] = this.advisorSelected;
    data["excludePrimarySelected"] = this.excludePrimarySelected;
    data["noPrimaryAppointed"] = this.noPrimaryAppointed;
    data["noAdvisorAppointed"] = this.noAdvisorAppointed;
    data["byTeam"] = this.byTeam;
    data["advisorTeamId"] = this.advisorTeamId;
    data["advisorSubTeamId"] = this.advisorSubTeamId;
    data["byAdvisorTeam"] = this.byAdvisorTeam;
    data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
    if (Array.isArray(this.companyIds)) {
      data["companyIds"] = [];
      for (let item of this.companyIds)
        data["companyIds"].push(item);
    }
    if (Array.isArray(this.contactPersonIds)) {
      data["contactPersonIds"] = [];
      for (let item of this.contactPersonIds)
        data["contactPersonIds"].push(item);
    }
    if (Array.isArray(this.degreePoolIds)) {
      data["degreePoolIds"] = [];
      for (let item of this.degreePoolIds)
        data["degreePoolIds"].push(item);
    }
    data["studentPersonId"] = this.studentPersonId;
    data["progressFrom"] = this.progressFrom;
    data["progressTo"] = this.progressTo;
    data["countryCode"] = this.countryCode;
    if (Array.isArray(this.state)) {
      data["state"] = [];
      for (let item of this.state)
        data["state"].push(item);
    }
    data["seasonFromSeason"] = this.seasonFromSeason;
    data["seasonFromYear"] = this.seasonFromYear;
    data["seasonToYear"] = this.seasonToYear;
    data["seasonToSeason"] = this.seasonToSeason;
    data["billingStatus"] = this.billingStatus;
    data["isNoSeason"] = this.isNoSeason;
    if (Array.isArray(this.studentStatusFlags)) {
      data["studentStatusFlags"] = [];
      for (let item of this.studentStatusFlags)
        data["studentStatusFlags"].push(item);
    }
    data["capacityFilter"] = this.capacityFilter;
    data["distance"] = this.distance;
    data["isPartner"] = this.isPartner;
    if (Array.isArray(this.partnerTags)) {
      data["partnerTags"] = [];
      for (let item of this.partnerTags)
        data["partnerTags"].push(item);
    }
    if (Array.isArray(this.partnerStatusFlags)) {
      data["partnerStatusFlags"] = [];
      for (let item of this.partnerStatusFlags)
        data["partnerStatusFlags"].push(item);
    }
    data["partnerType"] = this.partnerType;
    data["isClient"] = this.isClient;
    if (Array.isArray(this.clientTags)) {
      data["clientTags"] = [];
      for (let item of this.clientTags)
        data["clientTags"].push(item);
    }
    if (Array.isArray(this.clientStatusFlags)) {
      data["clientStatusFlags"] = [];
      for (let item of this.clientStatusFlags)
        data["clientStatusFlags"].push(item);
    }
    data["isEducation"] = this.isEducation;
    if (Array.isArray(this.educationTags)) {
      data["educationTags"] = [];
      for (let item of this.educationTags)
        data["educationTags"].push(item);
    }
    if (Array.isArray(this.educationStatusFlags)) {
      data["educationStatusFlags"] = [];
      for (let item of this.educationStatusFlags)
        data["educationStatusFlags"].push(item);
    }
    data["institutionType"] = this.institutionType;
    data["isNoneType"] = this.isNoneType;
    data["companyType"] = this.companyType;
    data["geoPoint"] = this.geoPoint;
    data["remoteFlexible"] = this.remoteFlexible;
    data["opportunitySortProperty"] = this.opportunitySortProperty;
    data["opportunitySortOrder"] = this.opportunitySortOrder;
    return data;
  }
}

export interface IOpportunitySearchParamsModel {
  /** Searches name, description */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  createdFrom?: Date | undefined;
  createdTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  companyIds?: string[] | undefined;
  contactPersonIds?: string[] | undefined;
  degreePoolIds?: string[] | undefined;
  studentPersonId?: string | undefined;
  progressFrom?: number | undefined;
  progressTo?: number | undefined;
  countryCode?: string | undefined;
  state?: string[] | undefined;
  seasonFromSeason?: number | undefined;
  seasonFromYear?: number | undefined;
  seasonToYear?: number | undefined;
  seasonToSeason?: number | undefined;
  billingStatus?: string | undefined;
  isNoSeason?: boolean | undefined;
  studentStatusFlags?: string[] | undefined;
  capacityFilter?: string | undefined;
  distance?: number | undefined;
  isPartner?: boolean | undefined;
  partnerTags?: string[] | undefined;
  partnerStatusFlags?: string[] | undefined;
  partnerType?: string | undefined;
  isClient?: boolean | undefined;
  clientTags?: string[] | undefined;
  clientStatusFlags?: string[] | undefined;
  isEducation?: boolean | undefined;
  educationTags?: string[] | undefined;
  educationStatusFlags?: string[] | undefined;
  institutionType?: string | undefined;
  isNoneType?: boolean | undefined;
  companyType?: string | undefined;
  geoPoint?: string | undefined;
  remoteFlexible?: boolean | undefined;
  opportunitySortProperty?: string | undefined;
  opportunitySortOrder?: string | undefined;
}