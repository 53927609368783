import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@template/core/AppLayout';
import AuthRoutes from '@template/utility/AuthRoutes';
import AppThemeProvider from '@template/utility/AppThemeProvider';
import AppStyleProvider from '@template/utility/AppStyleProvider';
import ContextProvider from '@template/utility/ContextProvider';
import {LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import store, {history} from './redux/store';
import CssBaseline from '@mui/material/CssBaseline';
import CacheReloadDialog from './modules/cacheReloadDialog';
import CacheBuster, {ICacheBusterState} from './cacheBuster';

const App = () => (
<CacheBuster>
    {({ isLatestVersion, refreshCacheAndReload }: ICacheBusterState) => {
      return (<ContextProvider>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppThemeProvider>
              <AppStyleProvider>
                <ConnectedRouter history={history}>
                  <AuthRoutes>
                    <CssBaseline />
                    <CacheReloadDialog isLatestVersion={isLatestVersion} refreshCacheAndReload={refreshCacheAndReload} />
                    <AppLayout />
                  </AuthRoutes>
                </ConnectedRouter>
              </AppStyleProvider>
            </AppThemeProvider>
          </LocalizationProvider>
        </Provider>
      </ContextProvider>);
    }}
</CacheBuster>
);

export default App;
