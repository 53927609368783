import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {
  BREAK_SETTINGS,
  BREAK_SETTINGS_LIST
} from './Actions';
import {BreakSettingsModel} from '../../types/requests/BreakSettingsModel';

export interface ISettingsState {
  breakSettingsListResultLoading: boolean;
  breakSettingsListResultError: string | undefined;
  breakSettingsListResult: BreakSettingsModel[] | undefined;
  breakSettingsLoading: boolean;
  breakSettingsRefreshing: boolean;
  breakSettingsSaving: boolean;
  breakSettingsSaveError: string | undefined;
  breakSettingsError: string | undefined;
  breakSettingsEditDetail: BreakSettingsModel | undefined;
  breakSettingsDetail: BreakSettingsModel | undefined;
}

const initialState: ISettingsState = {
  breakSettingsListResultLoading: false,
  breakSettingsListResultError: undefined,
  breakSettingsListResult: undefined,
  breakSettingsLoading: false,
  breakSettingsRefreshing: false,
  breakSettingsSaving: false,
  breakSettingsSaveError: undefined,
  breakSettingsError: undefined,
  breakSettingsEditDetail: undefined,
  breakSettingsDetail: undefined,
};

const settingsReducer = createReducer(initialState, {
  [BREAK_SETTINGS_LIST.LOAD]: (state) => {
    state.breakSettingsListResultError = undefined;
    state.breakSettingsListResultLoading = true;
  },
  [BREAK_SETTINGS_LIST.REFRESH]: (state) => {
    state.breakSettingsListResultError = undefined;
  },
  [BREAK_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.breakSettingsListResultError = action.payload;
    state.breakSettingsListResultLoading = false;
  },
  [BREAK_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<BreakSettingsModel[]>) => {
    state.breakSettingsListResult = action.payload;
    state.breakSettingsListResultLoading = false;
    state.breakSettingsListResultError = undefined;
  },
  [BREAK_SETTINGS.REFRESH]: (state) => {
    state.breakSettingsLoading = false;
    state.breakSettingsRefreshing = true;
    state.breakSettingsError = undefined;
    state.breakSettingsSaveError = undefined;
  },
  [BREAK_SETTINGS.SAVE]: (state) => {
    state.breakSettingsSaving = true;
    state.breakSettingsError = undefined;
    state.breakSettingsSaveError = undefined;
  },
  [BREAK_SETTINGS.SAVED]: (state, action: PayloadAction<BreakSettingsModel>) => {
    state.breakSettingsSaving = false;
    state.breakSettingsError = undefined;
    state.breakSettingsSaveError = undefined;
    state.breakSettingsDetail = action.payload;
  },
  [BREAK_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.breakSettingsSaving = false;
    state.breakSettingsError = undefined;
    state.breakSettingsSaveError = action.payload;
  },
});

export default settingsReducer;
