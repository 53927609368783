import React, {useEffect, useState} from 'react';
import packageJson from '../package.json';

interface ICacheBusterProps {
  children: any
}

export interface ICacheBusterState {
  isLatestVersion: boolean;
  refreshCacheAndReload(): void;
}

// @ts-ignore
global.appVersion = packageJson.version;

export const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

let timeout: NodeJS.Timeout | null;

const CacheBuster: React.FC<ICacheBusterProps> = ({children}) => {

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLatestVersion, setIsLatestVersion] = useState(true);

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  useEffect(() => {
    if (!init) {
      checkVersion();
      setInit(true);
    }
  }, [init]);

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }, [timeout]);

  const checkVersion = () => {
    setLoading(true);
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        // @ts-ignore
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          setLoading(false);
          setIsLatestVersion(false);
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          setLoading(false);
          setIsLatestVersion(true);
          timeout = setTimeout(checkVersion, 300000);
        }
      });
  };

  return <>{children({loading, isLatestVersion, refreshCacheAndReload})}</>;
}

export default CacheBuster;
