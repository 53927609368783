import { AdvisorUserModel } from "./AdvisorUserModel";
import {MailingListRecipientModel} from './MailingListRecipientModel';

export class MailingListModel implements IMailingListModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  title?: string | undefined;
  mailingListDescription?: string | undefined;
  dateLastEmailed?: Date | undefined;
  userId?: string | undefined;
  teamId?: string | undefined;
  isContact?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isEngagementInfo?: boolean | undefined;
  isArchived?: boolean | undefined;
  dateArchived?: Date | undefined;
  archivedByUserId?: string | undefined;
  totalCount?: number | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  recipients?: MailingListRecipientModel[] | undefined;

  constructor(data?: IMailingListModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.title = Data["title"];
      this.mailingListDescription = Data["mailingListDescription"];
      this.dateLastEmailed = Data["dateLastEmailed"] ? new Date(Data["dateLastEmailed"].toString()) : <any>undefined;
      this.userId = Data["userId"];
      this.teamId = Data["teamId"];
      this.isContact = Data["isContact"];
      this.isStudent = Data["isStudent"];
      this.isVolunteer = Data["isVolunteer"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.isAlumni = Data["isAlumni"];
      this.isEngagementInfo = Data["isEngagementInfo"];
      this.isArchived = Data["isArchived"];
      this.dateArchived = Data["dateArchived"] ? new Date(Data["dateArchived"].toString()) : <any>undefined;
      this.archivedByUserId = Data["archivedByUserId"];
      this.totalCount = Data["totalCount"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(AdvisorUserModel.fromJS(item));
      }
      if (Array.isArray(Data["recipients"])) {
        this.recipients = [] as any;
        for (let item of Data["recipients"])
          this.recipients!.push(MailingListRecipientModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): MailingListModel {
    data = typeof data === 'object' ? data : {};
    let result = new MailingListModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["title"] = this.title;
    data["mailingListDescription"] = this.mailingListDescription;
    data["dateLastEmailed"] = this.dateLastEmailed ? this.dateLastEmailed.toISOString() : <any>undefined;
    data["userId"] = this.userId;
    data["teamId"] = this.teamId;
    data["isContact"] = this.isContact;
    data["isStudent"] = this.isStudent;
    data["isVolunteer"] = this.isVolunteer;
    data["isWorkShadow"] = this.isWorkShadow;
    data["isAlumni"] = this.isAlumni;
    data["isEngagementInfo"] = this.isEngagementInfo;
    data["isArchived"] = this.isArchived;
    data["dateArchived"] = this.dateArchived ? this.dateArchived.toISOString() : <any>undefined;
    data["archivedByUserId"] = this.archivedByUserId;
    data["totalCount"] = this.totalCount;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item.toJSON());
    }
    if (Array.isArray(this.recipients)) {
      data["recipients"] = [];
      for (let item of this.recipients)
        data["recipients"].push(item.toJSON());
    }
    return data;
  }
}

export interface IMailingListModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  title?: string | undefined;
  mailingListDescription?: string | undefined;
  dateLastEmailed?: Date | undefined;
  userId?: string | undefined;
  teamId?: string | undefined;
  isContact?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isEngagementInfo?: boolean | undefined;
  isArchived?: boolean | undefined;
  dateArchived?: Date | undefined;
  archivedByUserId?: string | undefined;
  totalCount?: number | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  recipients?: MailingListRecipientModel[] | undefined;
}