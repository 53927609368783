import {PlacementDetailModel} from './PlacementDetailModel';
import {HSPOpportunityStudentContactPersonView} from './HSPOpportunityStudentContactPersonView';
import {StudentPoolSummary} from '../views/StudentPoolSummary';
import {EmployerExperienceModel, HonourAndAwardsModel} from '../requests/StudentProfileEditModel';
import {HSPOpportunityStudentViewOpportunityStudentStatus} from './Enums';

export class HSPOpportunityStudentView implements IHSPOpportunityStudentView {
  id?: string | undefined;
  HSPOpportunityId?: string | undefined;
  personId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  additionalInfo?: string | undefined;
  hspOpportunityStudentStatus?: HSPOpportunityStudentViewOpportunityStudentStatus | undefined;
  pools?: StudentPoolSummary[] | undefined;
  placement?: PlacementDetailModel[] | undefined;
  studentIntroduction?: string | undefined;
  sentByUserId?: string | undefined;
  dateFirstSent?: Date | undefined;
  dateLastSent?: Date | undefined;
  contactPeople?: HSPOpportunityStudentContactPersonView[] | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  publicProfile?: string | undefined;
  internshipDates?: string | undefined;
  profileLastUpdated?: Date | undefined;
  profileUpdatedByUserId?: string | undefined;

  constructor(data?: IHSPOpportunityStudentView) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.HSPOpportunityId = Data["HSPOportunityId"];
      this.personId = Data["personId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.additionalInfo = Data["additionalInfo"];
      this.hspOpportunityStudentStatus = Data["hspOpportunityStudentStatus"];
      if (Array.isArray(Data["pools"])) {
        this.pools = [] as any;
        for (let item of Data["pools"])
          this.pools!.push(StudentPoolSummary.fromJS(item));
      }
      if (Array.isArray(Data["placement"])) {
        this.placement = [] as any;
        for (let item of Data["placement"])
          this.placement!.push(PlacementDetailModel.fromJS(item));
      }
      this.studentIntroduction = Data["studentIntroduction"];
      this.sentByUserId = Data["sentByUserId"];
      this.dateFirstSent = Data["dateFirstSent"] ? new Date(Data["dateFirstSent"].toString()) : <any>undefined;
      this.dateLastSent = Data["dateLastSent"] ? new Date(Data["dateLastSent"].toString()) : <any>undefined;
      if (Array.isArray(Data["contactPeople"])) {
        this.contactPeople = [] as any;
        for (let item of Data["contactPeople"])
          this.contactPeople!.push(HSPOpportunityStudentContactPersonView.fromJS(item));
      }
      if (Array.isArray(Data["employerExperiences"])) {
        this.employerExperiences = [] as any;
        for (let item of Data["employerExperiences"])
          this.employerExperiences!.push(EmployerExperienceModel.fromJS(item));
      }
      if (Array.isArray(Data["employerHonourAwards"])) {
        this.employerHonourAwards = [] as any;
        for (let item of Data["employerHonourAwards"])
          this.employerHonourAwards!.push(HonourAndAwardsModel.fromJS(item));
      }
      this.publicProfile = Data["publicProfile"];
      this.internshipDates = Data["internshipDates"];
      this.profileLastUpdated = Data["profileLastUpdated"] ? new Date(Data["profileLastUpdated"].toString()) : <any>undefined;
      this.profileUpdatedByUserId = Data["profileUpdatedByUserId"];
    }
  }

  static fromJS(data: any): HSPOpportunityStudentView {
    data = typeof data === 'object' ? data : {};
    let result = new HSPOpportunityStudentView();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["HSPOpportunityId"] = this.HSPOpportunityId;
    data["personId"] = this.personId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["additionalInfo"] = this.additionalInfo;
    data["hspOpportunityStudentStatus"] = this.hspOpportunityStudentStatus;
    if (Array.isArray(this.pools)) {
      data["pools"] = [];
      for (let item of this.pools)
        data["pools"].push(item.toJSON());
    }
    if (Array.isArray(this.placement)) {
      data["placement"] = [];
      for (let item of this.placement)
        data["placement"].push(item.toJSON());
    }
    data["studentIntroduction"] = this.studentIntroduction;
    data["sentByUserId"] = this.sentByUserId;
    data["dateFirstSent"] = this.dateFirstSent ? this.dateFirstSent.toISOString() : <any>undefined;
    data["dateLastSent"] = this.dateLastSent ? this.dateLastSent.toISOString() : <any>undefined;
    if (Array.isArray(this.contactPeople)) {
      data["contactPeople"] = [];
      for (let item of this.contactPeople)
        data["contactPeople"].push(item.toJSON());
    }
    if (Array.isArray(this.employerExperiences)) {
      data["employerExperiences"] = [];
      for (let item of this.employerExperiences)
        data["employerExperiences"].push(item.toJSON());
    }
    if (Array.isArray(this.employerHonourAwards)) {
      data["employerHonourAwards"] = [];
      for (let item of this.employerHonourAwards)
        data["employerHonourAwards"].push(item.toJSON());
    }
    data["publicProfile"] = this.publicProfile;
    data["internshipDates"] = this.internshipDates;
    data["profileLastUpdated"] = this.profileLastUpdated ? this.profileLastUpdated.toISOString() : <any>undefined;
    data["profileUpdatedByUserId"] = this.profileUpdatedByUserId;
    return data;
  }
}

export interface IHSPOpportunityStudentView {
  id?: string | undefined;
  HSPOpportunityId?: string | undefined;
  personId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  additionalInfo?: string | undefined;
  hspOpportunityStudentStatus?: HSPOpportunityStudentViewOpportunityStudentStatus | undefined;
  pools?: StudentPoolSummary[] | undefined;
  placement?: PlacementDetailModel[] | undefined;
  studentIntroduction?: string | undefined;
  sentByUserId?: string | undefined;
  dateFirstSent?: Date | undefined;
  dateLastSent?: Date | undefined;
  contactPeople?: HSPOpportunityStudentContactPersonView[] | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  publicProfile?: string | undefined;
  internshipDates?: string | undefined;
  profileLastUpdated?: Date | undefined;
  profileUpdatedByUserId?: string | undefined;
}

