import {TagListModel} from './TagListModel';
import {FileMetaModel} from './FileMetaModel';

export class FileSettingsModel implements IFileSettingsModel {
  fileMetaModel?: FileMetaModel[] | undefined;
  tagListModel?: TagListModel | undefined;

  constructor(data?: IFileSettingsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["fileMetaModel"])) {
        this.fileMetaModel = [] as any;
        for (let item of Data["fileMetaModel"])
          this.fileMetaModel!.push(FileMetaModel.fromJS(item));
      }
      this.tagListModel = Data["tagListModel"] ? TagListModel.fromJS(Data["tagListModel"]) : <any>undefined;
    }
  }

  static fromJS(data: any): FileSettingsModel {
    data = typeof data === 'object' ? data : {};
    let result = new FileSettingsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.fileMetaModel)) {
      data["fileMetaModel"] = [];
      for (let item of this.fileMetaModel)
        data["fileMetaModel"].push(item.toJSON());
    }
    data["tagListModel"] = this.tagListModel ? this.tagListModel.toJSON() : <any>undefined;
    return data;
  }
}

export interface IFileSettingsModel {
  fileMetaModel?: FileMetaModel[] | undefined;
  tagListModel?: TagListModel | undefined;
}