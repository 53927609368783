export class SubTeamDetailModel implements ISubTeamDetailModel {
  id?: string | undefined;
  teamId?: string | undefined;
  createdByUserId?: string | undefined;
  /** Descriptive title */
  title?: string | undefined;
  /** A short name for this team (eg NSW) */
  shortTitle?: string | undefined;
  isDisabled?: boolean | undefined;
  dateDisabled?: Date | undefined;
  disabledByUserId?: string | undefined;
  dateCreated?: Date | undefined;

  constructor(data?: ISubTeamDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.teamId = Data["teamId"];
      this.createdByUserId = Data["createdByUserId"];
      this.title = Data["title"];
      this.shortTitle = Data["shortTitle"];
      this.isDisabled = Data["isDisabled"];
      this.dateDisabled = Data["dateDisabled"] ? new Date(Data["dateDisabled"].toString()) : <any>undefined;
      this.disabledByUserId = Data["disabledByUserId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): SubTeamDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new SubTeamDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["teamId"] = this.teamId;
    data["createdByUserId"] = this.createdByUserId;
    data["title"] = this.title;
    data["shortTitle"] = this.shortTitle;
    data["isDisabled"] = this.isDisabled;
    data["dateDisabled"] = this.dateDisabled ? this.dateDisabled.toISOString() : <any>undefined;
    data["disabledByUserId"] = this.disabledByUserId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    return data;
  }
}

export interface ISubTeamDetailModel {
  id?: string | undefined;
  teamId?: string | undefined;
  createdByUserId?: string | undefined;
  /** Descriptive title */
  title?: string | undefined;
  /** A short name for this team (eg NSW) */
  shortTitle?: string | undefined;
  isDisabled?: boolean | undefined;
  dateDisabled?: Date | undefined;
  disabledByUserId?: string | undefined;
  dateCreated?: Date | undefined;
}