import {PermissionLevel} from '../types/common/Permissions';
import {studentPaths} from './students/paths';
import {workShadowPaths} from './workShadow/paths';
import {teamPaths} from './team/paths';
import {adminPaths} from './admin/paths';
import {scorecardPaths} from './scorecards/paths';
import {companyPaths} from './companies/paths';
import {contactPaths} from './contacts/paths';
import {volunteerPaths} from './volunteers/paths';
import {peoplePaths} from './people/paths';
import {mailingListPaths} from './mailingList/paths';
import {settingsPaths} from './settings/paths';
import {reportingPaths} from './reporting/paths';
import {emailTemplatePaths} from './emailTemplateSettings/paths';
import {breakSettingsPath} from './breakSettings/paths';
import {alumniPaths} from './alumni/paths';


export interface NavItemProps {
  id: string;
  label: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: number;
  children?: NavItemProps[] | NavItemProps;
}


const routesConfig: NavItemProps[] = [
  {
    id: 'home',
    title: 'Dashboard',
    label: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard',
  },
  {
    id: 'reporting',
    title: 'Reporting',
    label: 'Reporting',
    type: 'collapse',
    icon: 'bar_chart',
    children: [
      {
        id: 'reporting-recruitment-scorecard',
        title: 'Recruitment Scorecard',
        label: 'Recruitment Scorecard',
        type: 'item',
        url: scorecardPaths.RecruitmentScorecard,
      },
      {
        id: 'reporting-engagement-scorecard',
        title: 'Engagement Scorecard',
        label: 'Engagement Scorecard',
        type: 'item',
        url: scorecardPaths.EngagementScorecard,
      },
      {
        id: 'reporting-hsp-engagement-scorecard',
        title: 'HSP Engagement Scorecard',
        label: 'HSP Engagement Scorecard',
        type: 'item',
        url: scorecardPaths.HSPEngagementScorecard,
      },
      {
        id: 'reporting-internship-scorecard',
        title: 'Internship Scorecard',
        label: 'Internship Scorecard',
        type: 'item',
        url: scorecardPaths.InternshipScorecard,
      },
      {
        id: 'reporting-company-internship-scorecard',
        title: 'Company Internship Scorecard',
        label: 'Company Internship Scorecard',
        type: 'item',
        url: scorecardPaths.CompanyInternshipScorecard,
      },
      {
        id: 'reporting-student-activity',
        title: 'Student Activity',
        label: 'Student Activity',
        type: 'item',
        url: reportingPaths.StudentActivity,
      },
    ]
  },
  {
    id: 'student',
    title: 'Students',
    label: 'Students',
    type: 'collapse',
    icon: 'group',
    children: [
      {
        id: 'search-header',
        title: 'Search',
        label: 'Search',
        type: 'header',
      },
      {
        id: 'engagement-search',
        title: 'Search All Students',
        label: 'Search All Students',
        type: 'item',
        url: studentPaths.EngagementSearch,
      },
      {
        id: 'work-shadow-search',
        title: 'Search High School Program',
        label: 'Search High School Program',
        type: 'item',
        url: workShadowPaths.WorkShadowSearch,
      },
      {
        id: 'student-search',
        title: 'Search University',
        label: 'Search University',
        type: 'item',
        url: studentPaths.StudentSearch,
      },
      {
        id: 'alumni-search',
        title: 'Search Alumni',
        label: 'Search Alumni',
        type: 'item',
        url: alumniPaths.AlumniSearch,
      },
      {
        id: 'add-header',
        title: 'Add',
        label: 'Add',
        type: 'header',
      },
      {
        id: 'work-shadow-create',
        title: 'Add High School Program',
        label: 'Add High School Program',
        type: 'item',
        url: workShadowPaths.WorkShadowCreate,
      },
      {
        id: 'student-create',
        title: 'Add University',
        label: 'Add University',
        type: 'item',
        url: studentPaths.StudentCreate,
      },
      // {
      //   id: 'alumni-create',
      //   title: 'Add Alumni',
      //   label: 'Add Alumni',
      //   type: 'item',
      //   url: alumniPaths.AlumniCreate,
      // },
      {
        id: 'placements-header',
        title: 'Placements',
        label: 'Placements',
        type: 'header',
      },
      {
        id: 'placement-search',
        title: 'Search Placements',
        label: 'Search Placements',
        type: 'item',
        url: studentPaths.PlacementSearch,
      },
    ],
  },
  {
    id: 'companies',
    title: 'Companies',
    label: 'Companies',
    type: 'collapse',
    icon: 'business',
    children: [
      {
        id: 'companies-search',
        title: 'Search Company',
        label: 'Search Company',
        type: 'item',
        url: companyPaths.CompanySearch,
      },
      {
        id: 'companies-create',
        title: 'Add Company',
        label: 'Add Company',
        type: 'item',
        url: companyPaths.CompanyCreate,
      },
      {
        id: 'pool-search',
        title: 'Search Opportunities',
        label: 'Search Opportunities',
        type: 'item',
        url: companyPaths.OpportunitiesSearch,
      }
    ],
  },
  {
    id: 'contacts',
    title: 'Contacts',
    label: 'Contacts',
    type: 'collapse',
    icon: 'supervised_user_circle',
    children: [
      {
        id: 'contacts-search',
        title: 'Search Contacts',
        label: 'Search Contacts',
        type: 'item',
        url: contactPaths.ContactSearch,
      },
      {
        id: 'contacts-create',
        title: 'Add Contact',
        label: 'Add Contact',
        type: 'item',
        url: contactPaths.ContactCreate,
      }
    ],
  },
  {
    id: 'volunteers',
    title: 'Volunteers',
    label: 'Volunteers',
    type: 'collapse',
    icon: 'volunteer_activism',
    children: [
      {
        id: 'volunteers-search',
        title: 'Search Volunteers',
        label: 'Search Volunteers',
        type: 'item',
        url: volunteerPaths.VolunteerSearch,
      },
      {
        id: 'volunteers-create',
        title: 'Add Volunteer',
        label: 'Add Volunteer',
        type: 'item',
        url: volunteerPaths.VolunteerCreate,
      }
    ],
  },
  {
    id: 'people',
    title: 'People',
    label: 'People',
    type: 'collapse',
    icon: 'contacts',
    children: [
      {
        id: 'people-search',
        title: 'Search People',
        label: 'Search People',
        type: 'item',
        url: peoplePaths.PeopleSearch,
      },
    ],
  },
  {

    id: 'mailing-list',
    title: 'Mailing List',
    label: 'Mailing List',
    type: 'collapse',
    icon: 'mail',
    children: [
      {
        id: 'mailing-list-search',
        title: 'Search Mailing List',
        label: 'Search Mailing List',
        type: 'item',
        url: mailingListPaths.MailingListSearch,
      },
    ],
  },
  {
    auth: PermissionLevel.ADMINISTRATOR,
    id: 'teams',
    title: 'Teams',
    label: 'Teams',
    type: 'collapse',
    icon: 'person_pin_circle',
    children: [
      {
        id: 'team-manage',
        title: 'Manage Teams',
        label: 'Manage Teams',
        type: 'item',
        url: teamPaths.TeamManage,
      },
      {
        id: 'user-search',
        title: 'Manage Advisors',
        label: 'Manage Advisors',
        type: 'item',
        url: teamPaths.UserSearch,
      },
      {
        id: 'user-create',
        title: 'Add Advisor User',
        label: 'Add Advisor User',
        type: 'item',
        url: teamPaths.UserCreate,
      }

    ],
  },
  {
    auth: PermissionLevel.ADMINISTRATOR,
    id: 'settings',
    title: 'Settings',
    label: 'Settings',
    type: 'collapse',
    icon: 'settings',
    children: [
      {
        id: 'student-settings',
        title: 'Student Settings',
        label: 'Student Settings',
        type: 'item',
        url: settingsPaths.StudentSettings,
      },
      {
        id: 'employer-settings',
        title: 'Employer Settings',
        label: 'Employer Settings',
        type: 'item',
        url: settingsPaths.EmployerSettings,
      },
      {
        id: 'alumni-settings',
        title: 'Alumni Settings',
        label: 'Alumni Settings',
        type: 'item',
        url: settingsPaths.AlumniSettings,
      },
      {
        id: 'work-shadow-settings',
        title: 'Work Shadow Settings',
        label: 'Work Shadow Settings',
        type: 'item',
        url: settingsPaths.WorkShadowSettings,
      },
      {
        id: 'education-settings',
        title: 'Education Settings',
        label: 'Education Settings',
        type: 'item',
        url: settingsPaths.EducationSettings,
      },
      {
        id: 'tag-definition-settings',
        title: 'Tag Definitions',
        label: 'Tag Definitions',
        type: 'item',
        url: settingsPaths.TagDefinitionSettings,
      },
      {
        id: 'email-template-settings',
        title: 'Email Templates Settings',
        label: 'Email Templates Settings',
        type: 'item',
        url: emailTemplatePaths.EmailTemplateSettings,
      },
      {
        id: 'break-settings',
        title: 'Break Settings',
        label: 'Break Settings',
        type: 'item',
        url: breakSettingsPath.BreakSettingsManage,
      },
    ],
  },

  {
    auth: PermissionLevel.ADMINISTRATOR,
    id: 'administrators',
    title: 'Administrators',
    label: 'Administrators',
    type: 'collapse',
    icon: 'admin_panel_settings',
    children: [
      {
        id: 'admin-dev-reindex',
        title: 'Dev Reindex Operations',
        label: 'Dev Reindex Operations',
        type: 'item',
        url: adminPaths.DevReindexPage,
      },
      {
        id: 'admin-manage-unsubscribe',
        title: 'Manage email unsubscribe',
        label: 'Manage email unsubscribe',
        type: 'item',
        url: adminPaths.ManageUnsubscribe,
      },
      {
        id: 'admin-reporting-work-shadow-activity',
        title: 'Work Shadow Activity',
        label: 'Work Shadow Activity',
        type: 'item',
        url: reportingPaths.WorkShadowActivity,
      },
      {
        id: 'admin-reporting-hidden-student-activity',
        title: 'Student Activity(Hidden)',
        label: 'Student Activity(Hidden)',
        type: 'item',
        url: reportingPaths.HiddenStudentActivity,
      },
      {
        id: 'bulk-advisor-update',
        title: 'Bulk Advisor Update',
        label: 'Bulk Advisor Update',
        type: 'item',
        url: adminPaths.BulkAdvisorUpdatePage,
      },
    ],
  }
];
export default routesConfig;
