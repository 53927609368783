import {ActionTypeReferenceModel} from './ActionTypeReferenceModel';

export class ActionCategoriesModel implements IActionCategoriesModel {
  student?: ActionTypeReferenceModel[] | undefined;
  contact?: ActionTypeReferenceModel[] | undefined;
  advisor?: ActionTypeReferenceModel[] | undefined;
  pool?: ActionTypeReferenceModel[] | undefined;
  volunteer?: ActionTypeReferenceModel[] | undefined;
  company?: ActionTypeReferenceModel[] | undefined;
  workShadow?: ActionTypeReferenceModel[] | undefined;

  constructor(data?: IActionCategoriesModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["student"])) {
        this.student = [] as any;
        for (let item of Data["student"])
          this.student!.push(ActionTypeReferenceModel.fromJS(item));
      }
      if (Array.isArray(Data["contact"])) {
        this.contact = [] as any;
        for (let item of Data["contact"])
          this.contact!.push(ActionTypeReferenceModel.fromJS(item));
      }
      if (Array.isArray(Data["advisor"])) {
        this.advisor = [] as any;
        for (let item of Data["advisor"])
          this.advisor!.push(ActionTypeReferenceModel.fromJS(item));
      }
      if (Array.isArray(Data["pool"])) {
        this.pool = [] as any;
        for (let item of Data["pool"])
          this.pool!.push(ActionTypeReferenceModel.fromJS(item));
      }
      if (Array.isArray(Data["volunteer"])) {
        this.volunteer = [] as any;
        for (let item of Data["volunteer"])
          this.volunteer!.push(ActionTypeReferenceModel.fromJS(item));
      }
      if (Array.isArray(Data["company"])) {
        this.company = [] as any;
        for (let item of Data["company"])
          this.company!.push(ActionTypeReferenceModel.fromJS(item));
      }
      if (Array.isArray(Data["workShadow"])) {
        this.workShadow = [] as any;
        for (let item of Data["workShadow"])
          this.workShadow!.push(ActionTypeReferenceModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ActionCategoriesModel {
    data = typeof data === 'object' ? data : {};
    let result = new ActionCategoriesModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.student)) {
      data["student"] = [];
      for (let item of this.student)
        data["student"].push(item.toJSON());
    }
    if (Array.isArray(this.contact)) {
      data["contact"] = [];
      for (let item of this.contact)
        data["contact"].push(item.toJSON());
    }
    if (Array.isArray(this.advisor)) {
      data["advisor"] = [];
      for (let item of this.advisor)
        data["advisor"].push(item.toJSON());
    }
    if (Array.isArray(this.pool)) {
      data["pool"] = [];
      for (let item of this.pool)
        data["pool"].push(item.toJSON());
    }
    if (Array.isArray(this.volunteer)) {
      data["volunteer"] = [];
      for (let item of this.volunteer)
        data["volunteer"].push(item.toJSON());
    }
    if (Array.isArray(this.company)) {
      data["company"] = [];
      for (let item of this.company)
        data["company"].push(item.toJSON());
    }
    if (Array.isArray(this.workShadow)) {
      data["workShadow"] = [];
      for (let item of this.workShadow)
        data["workShadow"].push(item.toJSON());
    }
    return data;
  }
}

export interface IActionCategoriesModel {
  student?: ActionTypeReferenceModel[] | undefined;
  contact?: ActionTypeReferenceModel[] | undefined;
  advisor?: ActionTypeReferenceModel[] | undefined;
  pool?: ActionTypeReferenceModel[] | undefined;
  volunteer?: ActionTypeReferenceModel[] | undefined;
  company?: ActionTypeReferenceModel[] | undefined;
  workShadow?: ActionTypeReferenceModel[] | undefined;
}