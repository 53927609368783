import {AppState} from '../store';
import {CompanyListItem} from '../../types/views/CompanyListItem';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {
  COMPANY_CACHE,
  companyCacheError,
  companyCacheMerge,
  companyCacheUpdate,
  companyLogoCacheMerge,
} from './Actions';
import {hasItems} from '../../@template/helpers/arrays';
import {ImageMetadataModel} from '../../types/models/ImageMetadataModel';
import {ICompaniesCacheState} from './Reducer';
import {PayloadAction} from '@reduxjs/toolkit';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const companyCacheState = (state: AppState) => state.companiesCache;

export function* LoadCompanyCacheItems(idList: string[]) {
  // console.log('SAGA - companyCacheSaga - LoadCompanyCacheItems');
  try {

    if (hasItems(idList)) {
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.coreCompaniesPath}/list`,
        data: { id_list: idList }
      });
      const companies: CompanyListItem[] = res.data.map((x: any) => CompanyListItem.fromJS(x));
      let newImages: ImageMetadataModel[] = [];
      if (hasItems(companies)) {
        const newImageIds = companies.filter(x => !!x.logoImageId).map(x => x.logoImageId);
        if (hasItems(newImageIds)) {
          const imageResult = yield authApi.request({
            baseURL: apiConfig.filesUrl,
            method: 'PUT',
            url: `${apiConfig.imagesPath}/list`,
            data: { id_list: newImageIds }
          });
          newImages = imageResult.data.map((x: any) => ImageMetadataModel.fromJS(x));
        }
      }
      yield put(companyCacheMerge(companies));
      if (hasItems(newImages)) {
        yield put(companyLogoCacheMerge(newImages));
      }

    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(companyCacheError(err));
  }
}

export function* AddCompanyCacheItems() {
  // console.log('SAGA - companyCacheSaga - AddCompanyCacheItems');
  yield call(delay, 200);
  const currentCompanyCache: ICompaniesCacheState = yield select(companyCacheState);
  const currentPendingList = currentCompanyCache.companyPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    yield put(companyCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadCompanyCacheItems(){
  yield takeEvery(COMPANY_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadCompanyCacheItems(payload));
}

export function* watchAddCompanyCacheItems(){
  yield takeEvery(COMPANY_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddCompanyCacheItems());
}

export default function* companyCacheSaga() {
  yield all([
    call(watchLoadCompanyCacheItems),
    call(watchAddCompanyCacheItems),
  ])
}
