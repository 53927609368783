import {
  defaultForLocalOpportunitySearchParamsModel,
  OpportunitySearchParamsModel,
} from '../../types/requests/OpportunitySearchParamsModel';
import {OpportunityDetailModel} from '../../types/models/OpportunityDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {
  COMPANY_OPPORTUNITY_SEARCH,
  COMPANY_OPPORTUNITY_SEASONS, CONTACT_OPPORTUNITY_SEASONS, CURRENT_OPPORTUNITY,
  OPPORTUNITY, OPPORTUNITY_LOCALITY_SEARCH,
  OPPORTUNITY_SEARCH,
  OPPORTUNITY_STUDENT_PROFILES, STUDENT_OPPORTUNITY_SEASONS,
} from './Actions';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {PersonModel} from '../../types/models/PersonModel';
import {OpportunitySearchPaged} from '../../types/models/OpportunitySearchPaged';
import {OpportunitySummaryModel} from '../../types/models/OpportunitySummaryModel';
import {OpportunityStudentListView} from '../../types/views/OpportunityStudentListView';
import {LocalOpportunitySearchItem} from '../../types/views/LocalOpportunitySearchItem';


export interface IOpportunityState {
  opportunitySearchParams: OpportunitySearchParamsModel | undefined;
  opportunitySearchView: string | undefined;
  opportunitySearchSort: string | undefined;
  opportunityResultLoading: boolean;
  opportunityResultError: string | undefined;
  opportunityResult: OpportunitySearchPaged | undefined;
  opportunityCompanyResultLoading: boolean;
  opportunityCompanyResultRefreshing: boolean;
  opportunityCompanyResultError: string | undefined;
  opportunityCompanyResult: OpportunityDetailModel[] | undefined;
  opportunitiesSelectLoading: boolean;
  opportunitiesSelectError: string | undefined;
  opportunitiesSelectResult: OpportunityDetailModel[] | undefined;
  opportunityCompanySeasons: OpportunitySummaryModel[] | undefined;
  opportunityCompanySeasonsLoading: boolean;
  opportunityCompanySeasonsRefreshing: boolean;
  opportunityCompanySeasonsError: string | undefined;
  opportunityLoading: boolean;
  opportunityRefreshing: boolean;
  opportunitySaving: boolean;
  opportunityError: string | undefined;
  opportunityDetail: OpportunityStudentListView | undefined;
  opportunityStudentProfiles: PersonModel[] | undefined;
  opportunityStudentProfilesLoading: boolean;
  opportunityStudentProfilesError: string | undefined;
  currentOpportunityState: string| undefined;
  opportunityStudentSeasons: OpportunityDetailModel[] | undefined;
  opportunityStudentSeasonsRefreshing: boolean;
  opportunityStudentSeasonsLoading: boolean;
  opportunityStudentSeasonsError: string | undefined;
  opportunityContactSeasons: OpportunitySummaryModel[] | undefined;
  opportunityContactSeasonsLoading: boolean;
  opportunityContactSeasonsRefreshing: boolean;
  opportunityContactSeasonsError: string | undefined;
  opportunityLocalSearchParams: OpportunitySearchParamsModel;
  opportunityLocalSearchView: string | undefined;
  opportunityLocalSearchSort: string | undefined;
  opportunityLocalResultLoading: boolean;
  opportunityLocalResultError: string | undefined;
  opportunityLocalResult: LocalOpportunitySearchItem[] | undefined;
  currentOpportunityDetail: OpportunityStudentListView | undefined;
  currentOpportunityLoading: boolean;
  currentOpportunityRefreshing: boolean;
  currentOpportunityError: string | undefined;
}

const initialState: IOpportunityState = {
  opportunitySearchParams: undefined,
  opportunitySearchView: 'default',
  opportunitySearchSort: 'Relevance',
  opportunityResultLoading: false,
  opportunityResultError: undefined,
  opportunityResult: undefined,
  opportunityCompanyResultLoading: false,
  opportunityCompanyResultRefreshing: true,
  opportunityCompanyResultError: undefined,
  opportunityCompanyResult: undefined,
  opportunitiesSelectLoading: false,
  opportunitiesSelectError: undefined,
  opportunitiesSelectResult: undefined,
  opportunityCompanySeasons: undefined,
  opportunityCompanySeasonsLoading: false,
  opportunityCompanySeasonsRefreshing: false,
  opportunityCompanySeasonsError: undefined,
  opportunityLoading: false,
  opportunityRefreshing: false,
  opportunitySaving: false,
  opportunityError: undefined,
  opportunityDetail: undefined,
  opportunityStudentProfiles: undefined,
  opportunityStudentProfilesError: undefined,
  opportunityStudentProfilesLoading: false,
  currentOpportunityState: undefined,
  opportunityStudentSeasons: undefined,
  opportunityStudentSeasonsRefreshing: false,
  opportunityStudentSeasonsLoading: false,
  opportunityStudentSeasonsError: undefined,
  opportunityContactSeasons: undefined,
  opportunityContactSeasonsLoading: false,
  opportunityContactSeasonsRefreshing: false,
  opportunityContactSeasonsError: undefined,
  opportunityLocalSearchParams: defaultForLocalOpportunitySearchParamsModel(),
  opportunityLocalSearchView: 'default',
  opportunityLocalSearchSort: 'Relevance',
  opportunityLocalResultLoading: false,
  opportunityLocalResultError: undefined,
  opportunityLocalResult: undefined,
  currentOpportunityDetail: undefined,
  currentOpportunityLoading: false,
  currentOpportunityRefreshing: false,
  currentOpportunityError: undefined,
};

const opportunitiesReducer = createReducer(initialState, {
  [OPPORTUNITY.LOAD]: (state) => {
    state.opportunityLoading = true;
    state.opportunityRefreshing = false;
    state.opportunityError = undefined;
    state.opportunityDetail = undefined;
  },
  [OPPORTUNITY.LOAD_BY_SEASON]: (state) => {
    state.opportunityLoading = true;
    state.opportunityRefreshing = false;
    state.opportunityError = undefined;
    state.opportunityDetail = undefined;
  },
  [OPPORTUNITY.REFRESH]: (state) => {
    state.opportunityLoading = false;
    state.opportunityRefreshing = true;
    state.opportunityError = undefined;
  },
  [OPPORTUNITY.REFRESH_BY_SEASON]: (state) => {
    state.opportunityLoading = false;
    state.opportunityRefreshing = true;
    state.opportunityError = undefined;
  },
  [OPPORTUNITY.LOADED]: (state, action: PayloadAction<OpportunityStudentListView>) => {
    state.opportunityLoading = false;
    state.opportunityError = undefined;
    state.opportunityDetail = action.payload;
  },
  [OPPORTUNITY.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityLoading = false;
    state.opportunityError = action.payload;
  },
  [OPPORTUNITY_STUDENT_PROFILES.LOAD]: (state) => {
    state.opportunityStudentProfilesLoading = true;
    state.opportunityStudentProfilesError = undefined;
    state.opportunityStudentProfiles = undefined;
  },
  [OPPORTUNITY_STUDENT_PROFILES.LOADED]: (state, action: PayloadAction<PersonModel[]>) => {
    state.opportunityStudentProfilesLoading = false;
    state.opportunityStudentProfilesError = undefined;
    state.opportunityStudentProfiles = action.payload;
  },
  [OPPORTUNITY_STUDENT_PROFILES.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityStudentProfilesLoading = false;
    state.opportunityStudentProfilesError = action.payload;
  },
  [OPPORTUNITY.SAVE]: (state) => {
    state.opportunityError = undefined;
    state.opportunitySaving = true;
  },
  [OPPORTUNITY.SAVED]: (state, action: PayloadAction<OpportunityDetailModel>) => {
    state.opportunitySaving = false;
    state.opportunityError = undefined;
    state.opportunityDetail = action.payload;
  },
  [OPPORTUNITY.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunitySaving = false;
    state.opportunityError = action.payload;
  },
  [OPPORTUNITY_SEARCH.LOAD]: (state, action: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => {
    state.opportunitySearchParams = action.payload.searchParams;
    state.opportunitySearchSort = action.payload.sort;
    state.opportunityResultLoading = true;
    state.opportunityResultError = undefined;
  },
  [OPPORTUNITY_SEARCH.LOADED]: (state, action: PayloadAction<OpportunitySearchPaged>) => {
    state.opportunityResultLoading = false;
    state.opportunityResultError = undefined;
    state.opportunityResult = action.payload;
  },
  [OPPORTUNITY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityResultLoading = false;
    state.opportunityResultError = action.payload;
  },
  [COMPANY_OPPORTUNITY_SEARCH.LOAD]: (state) => {
    state.opportunityCompanyResult = undefined;
    state.opportunityCompanyResultLoading = true;
    state.opportunityCompanyResultRefreshing = false;
    state.opportunityCompanyResultError = undefined;
  },
  [COMPANY_OPPORTUNITY_SEARCH.REFRESH]: (state) => {
    state.opportunityCompanyResultLoading = false;
    state.opportunityCompanyResultRefreshing = true;
    state.opportunityCompanyResultError = undefined;
  },
  [COMPANY_OPPORTUNITY_SEARCH.LOADED]: (state, action: PayloadAction<OpportunityDetailModel[]>) => {
    state.opportunityCompanyResult = action.payload;
    state.opportunityCompanyResultLoading = false;
    state.opportunityCompanyResultRefreshing = false;
    state.opportunityCompanyResultError = undefined;
  },
  [COMPANY_OPPORTUNITY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityCompanyResultLoading = false;
    state.opportunityCompanyResultRefreshing = false;
    state.opportunityCompanyResultError = action.payload;
  },
  [COMPANY_OPPORTUNITY_SEASONS.LOAD]: (state) => {
    state.opportunityCompanySeasons = undefined;
    state.opportunityCompanySeasonsRefreshing = false;
    state.opportunityCompanySeasonsLoading = true;
    state.opportunityCompanySeasonsError = undefined;
  },
  [COMPANY_OPPORTUNITY_SEASONS.REFRESH]: (state) => {
    state.opportunityCompanySeasons = undefined;
    state.opportunityCompanySeasonsRefreshing = true;
    state.opportunityCompanySeasonsLoading = false;
    state.opportunityCompanySeasonsError = undefined;
  },
  [COMPANY_OPPORTUNITY_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityCompanySeasonsRefreshing = false;
    state.opportunityCompanySeasonsLoading = false;
    state.opportunityCompanySeasonsError = action.payload;
  },
  [COMPANY_OPPORTUNITY_SEASONS.LOADED]: (state, action: PayloadAction<OpportunitySummaryModel[]>) => {
    state.opportunityCompanySeasons = action.payload;
    state.opportunityCompanySeasonsRefreshing = false;
    state.opportunityCompanySeasonsLoading = false;
    state.opportunityCompanySeasonsError = undefined;
  },
  [STUDENT_OPPORTUNITY_SEASONS.LOAD]: (state) => {
    state.opportunityStudentSeasons = undefined;
    state.opportunityStudentSeasonsRefreshing = false;
    state.opportunityStudentSeasonsLoading = true;
    state.opportunityStudentSeasonsError = undefined;
  },
  [STUDENT_OPPORTUNITY_SEASONS.REFRESH]: (state) => {
    state.opportunityStudentSeasons = undefined;
    state.opportunityStudentSeasonsRefreshing = true;
    state.opportunityStudentSeasonsLoading = false;
    state.opportunityStudentSeasonsError = undefined;
  },
  [STUDENT_OPPORTUNITY_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityStudentSeasonsRefreshing = false;
    state.opportunityStudentSeasonsLoading = false;
    state.opportunityStudentSeasonsError = action.payload;
  },
  [STUDENT_OPPORTUNITY_SEASONS.LOADED]: (state, action: PayloadAction<OpportunityDetailModel[]>) => {
    state.opportunityStudentSeasons = action.payload;
    state.opportunityStudentSeasonsRefreshing = false;
    state.opportunityStudentSeasonsLoading = false;
    state.opportunityStudentSeasonsError = undefined;
  },

  [CONTACT_OPPORTUNITY_SEASONS.LOAD]: (state) => {
    state.opportunityContactSeasons = undefined;
    state.opportunityContactSeasonsRefreshing = false;
    state.opportunityContactSeasonsLoading = true;
    state.opportunityContactSeasonsError = undefined;
  },
  [CONTACT_OPPORTUNITY_SEASONS.REFRESH]: (state) => {
    state.opportunityContactSeasons = undefined;
    state.opportunityContactSeasonsRefreshing = true;
    state.opportunityContactSeasonsLoading = false;
    state.opportunityContactSeasonsError = undefined;
  },
  [CONTACT_OPPORTUNITY_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityContactSeasonsRefreshing = false;
    state.opportunityContactSeasonsLoading = false;
    state.opportunityContactSeasonsError = action.payload;
  },
  [CONTACT_OPPORTUNITY_SEASONS.LOADED]: (state, action: PayloadAction<OpportunitySummaryModel[]>) => {
    state.opportunityContactSeasons = action.payload;
    state.opportunityContactSeasonsRefreshing = false;
    state.opportunityContactSeasonsLoading = false;
    state.opportunityContactSeasonsError = undefined;
  },
  [OPPORTUNITY_LOCALITY_SEARCH.LOAD]: (state, action: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => {
    state.opportunityLocalSearchParams = action.payload.searchParams;
    state.opportunityLocalSearchSort = action.payload.sort;
    state.opportunityLocalResultLoading = true;
    state.opportunityLocalResultError = undefined;
  },
  [OPPORTUNITY_LOCALITY_SEARCH.LOADED]: (state, action: PayloadAction<LocalOpportunitySearchItem[]>) => {
    state.opportunityLocalResultLoading = false;
    state.opportunityLocalResultError = undefined;
    state.opportunityLocalResult = action.payload;
  },
  [OPPORTUNITY_LOCALITY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityLocalResultLoading = true;
    state.opportunityLocalResultError = action.payload;
  },
  [CURRENT_OPPORTUNITY.LOAD]: (state) => {
    state.currentOpportunityLoading = true;
    state.currentOpportunityRefreshing = false;
    state.currentOpportunityError = undefined;
    state.currentOpportunityDetail = undefined;
  },
  [CURRENT_OPPORTUNITY.REFRESH]: (state) => {
    state.currentOpportunityLoading = false;
    state.currentOpportunityRefreshing = true;
    state.currentOpportunityError = undefined;
  },
  [CURRENT_OPPORTUNITY.LOADED]: (state, action: PayloadAction<OpportunityStudentListView>) => {
    state.currentOpportunityLoading = false;
    state.currentOpportunityError = undefined;
    state.currentOpportunityDetail = action.payload;
  },
  [CURRENT_OPPORTUNITY.ERROR]: (state, action: PayloadAction<string>) => {
    state.currentOpportunityLoading = false;
    state.currentOpportunityError = action.payload;
  },
});

export default opportunitiesReducer;

