import {PhoneModel} from '../models/PhoneModel';
import {ContactDetailModel} from '../models/ContactDetailModel';
import {BuddyWorkShadowDetailModel} from '../models/BuddyWorkShadowDetailModel';

export class ContactPersonModel implements IContactPersonModel {
  id?: string | undefined;
  personNumber?: number | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  gender?: string | undefined;
  genderPronouns?: string | undefined;
  /** Phone numbers for this contact */
  phoneNumbers?: PhoneModel[] | undefined;
  /** URL of facebook profile */
  facebookUrl?: string | undefined;
  /** URL of LinkedIn profile */
  linkedinUrl?: string | undefined;
  isDeleted?: boolean | undefined;
  isNoContact?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  isBuddyWorkShadow?: boolean | undefined;
  /** Company specific contact information */
  contactDetail?: ContactDetailModel | undefined;
  restoreDeleted?: boolean | undefined;
  buddyWorkShadowDetail?: BuddyWorkShadowDetailModel | undefined;

  constructor(data?: IContactPersonModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.personNumber = Data["personNumber"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.firstName = Data["firstName"];
      this.middleName = Data["middleName"];
      this.lastName = Data["lastName"];
      this.birthName = Data["birthName"];
      this.email = Data["email"];
      this.altEmail = Data["altEmail"];
      this.gender = Data["gender"];
      this.genderPronouns = Data["genderPronouns"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.facebookUrl = Data["facebookUrl"];
      this.linkedinUrl = Data["linkedinUrl"];
      this.isDeleted = Data["isDeleted"];
      this.isNoContact = Data["isNoContact"];
      this.isClient = Data["isClient"];
      this.isStudent = Data["isStudent"];
      this.isBuddyWorkShadow = Data["isBuddyWorkShadow"];
      this.contactDetail = Data["contactDetail"] ? ContactDetailModel.fromJS(Data["contactDetail"]) : <any>undefined;
      this.restoreDeleted = Data["restoreDeleted"];
      this.buddyWorkShadowDetail = Data["buddyWorkShadowDetail"] ? BuddyWorkShadowDetailModel.fromJS(Data["buddyWorkShadowDetail"]) : <any>undefined;
    }
  }

static fromJS(data: any): ContactPersonModel {
    data = typeof data === 'object' ? data : {};
    let result = new ContactPersonModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["personNumber"] = this.personNumber;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["firstName"] = this.firstName;
    data["middleName"] = this.middleName;
    data["lastName"] = this.lastName;
    data["birthName"] = this.birthName;
    data["email"] = this.email;
    data["altEmail"] = this.altEmail;
    data["gender"] = this.gender;
    data["genderPronouns"] = this.genderPronouns;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item.toJSON());
    }
    data["facebookUrl"] = this.facebookUrl;
    data["linkedinUrl"] = this.linkedinUrl;
    data["isDeleted"] = this.isDeleted;
    data["isNoContact"] = this.isNoContact;
    data["isClient"] = this.isClient;
    data["isStudent"] = this.isStudent;
    data["isBuddyWorkShadow"] = this.isBuddyWorkShadow;
    data["contactDetail"] = this.contactDetail ? this.contactDetail.toJSON() : <any>undefined;
    data["restoreDeleted"] = this.restoreDeleted;
    data["buddyWorkShadowDetail"] = this.buddyWorkShadowDetail ? this.buddyWorkShadowDetail.toJSON() : <any>undefined;
    return data;
  }
}

export interface IContactPersonModel {
  id?: string | undefined;
  personNumber?: number | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  gender?: string | undefined;
  genderPronouns?: string | undefined;
  /** Phone numbers for this contact */
  phoneNumbers?: PhoneModel[] | undefined;
  /** URL of facebook profile */
  facebookUrl?: string | undefined;
  /** URL of LinkedIn profile */
  linkedinUrl?: string | undefined;
  isDeleted?: boolean | undefined;
  isNoContact?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  isBuddyWorkShadow?: boolean | undefined;
  /** Company specific contact information */
  contactDetail?: ContactDetailModel | undefined;
  restoreDeleted?: boolean | undefined;
  buddyWorkShadowDetail?: BuddyWorkShadowDetailModel | undefined;
}