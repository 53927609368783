export class RecipientPerson implements IRecipientPerson {
  personId!: string;
  companyId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  fullName?: string | undefined;
  companyName?: string | undefined;
  isInternal?: boolean | undefined;

  constructor(data?: IRecipientPerson) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.companyId = Data["companyId"];
      this.firstName = Data["firstName"];
      this.lastName = Data["lastName"];
      this.fullName = Data["fullName"];
      this.companyName = Data["companyName"];
      this.isInternal = Data["isInternal"];
    }
  }

  static fromJS(data: any): RecipientPerson {
    data = typeof data === 'object' ? data : {};
    let result = new RecipientPerson();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["companyId"] = this.companyId;
    data["firstName"] = this.firstName;
    data["lastName"] = this.lastName;
    data["fullName"] = this.fullName;
    data["companyName"] = this.companyName;
    data["isInternal"] = this.isInternal;
    return data;
  }
}

export interface IRecipientPerson {
  personId: string;
  companyId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  fullName?: string | undefined;
  companyName?: string | undefined;
  isInternal?: boolean | undefined;
}