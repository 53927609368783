import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ALUMNI} from './Actions';
import {PersonModel} from '../../types/models/PersonModel';
import {
  defaultAlumniSearchParamsModel,
  AlumniSearchParamsModel,
} from '../../types/requests/AlumniSearchParamsModel';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {SearchView} from '../../types/common/SearchView';
import {ALUMNI_SEARCH} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';

export interface IAlumniState {
  alumniSearchParams: AlumniSearchParamsModel;
  alumniSearchView: string | undefined;
  alumniSearchSort: string | undefined;
  alumniResultLoading: boolean;
  alumniResultError: string | undefined;
  alumniResult: PersonSearchResultPaged | undefined;
  alumniLoading: boolean;
  alumniRefreshing: boolean;
  alumniSaving: boolean;
  alumniSaveError: string | undefined;
  alumniError: string | undefined;
  alumniDetail: PersonModel | undefined;
}

const initialState: IAlumniState = {
  alumniSearchParams: defaultAlumniSearchParamsModel(),
  alumniSearchView: SearchView.Default,
  alumniSearchSort: 'Relevance',
  alumniResultLoading: false,
  alumniResultError: undefined,
  alumniResult: undefined,
  alumniLoading: false,
  alumniRefreshing: false,
  alumniSaving: false,
  alumniSaveError: undefined,
  alumniError: undefined,
  alumniDetail: undefined
}

const alumniReducer = createReducer(initialState, {
  [ALUMNI_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.alumniSearchView = action.payload;
  },
  [ALUMNI_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<AlumniSearchParamsModel>>) => {
    state.alumniSearchParams = action.payload.searchParams;
    state.alumniResultError = undefined;
    state.alumniResultLoading = true;
  },
  [ALUMNI_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<AlumniSearchParamsModel>>) => {
    state.alumniSearchParams = action.payload.searchParams;
    state.alumniResultError = undefined;
  },
  [ALUMNI_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.alumniResultError = action.payload;
    state.alumniResultLoading = false;
  },
  [ALUMNI_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.alumniResult = action.payload;
    state.alumniResultLoading = false;
    state.alumniResultError = undefined;
  },
  [ALUMNI.LOAD]: (state) => {
    state.alumniLoading = true;
    state.alumniRefreshing = false;
    state.alumniError = undefined;
    state.alumniSaveError = undefined;
    state.alumniDetail = undefined;
  },
  [ALUMNI.REFRESH]: (state) => {
    state.alumniLoading = false;
    state.alumniRefreshing = true;
    state.alumniError = undefined;
    state.alumniSaveError = undefined;
  },
  [ALUMNI.LOADED]: (state, action: PayloadAction<PersonModel>) => {
    state.alumniLoading = false;
    state.alumniRefreshing = false;
    state.alumniError = undefined;
    state.alumniSaveError = undefined;
    state.alumniDetail = action.payload;
  },
  [ALUMNI.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.alumniLoading = false;
    state.alumniRefreshing = false;
    state.alumniError = action.payload;
    state.alumniSaveError = undefined;
  },
  [ALUMNI.SAVE]: (state) => {
    state.alumniSaving = true;
    state.alumniError = undefined;
    state.alumniSaveError = undefined;
  },
  [ALUMNI.SAVED]: (state, action: PayloadAction<PersonModel>) => {
    state.alumniSaving = false;
    state.alumniError = undefined;
    state.alumniSaveError = undefined;
    state.alumniDetail = action.payload;
  },
  [ALUMNI.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.alumniSaving = false;
    state.alumniError = undefined;
    state.alumniSaveError = action.payload;
  },
});

export default alumniReducer;
