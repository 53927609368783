import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {PersonModel, IPersonModel} from '../../types/models/PersonModel';
import { IMergePersonModel } from 'types/requests/MergePersonModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {defaultPeopleSearchParamsModel, PeopleSearchParamsModel} from '../../types/requests/PeopleSearchParamsModel';
import {IPeopleUpdateRequestPayload} from '../../types/requests/PeopleUpdateRequestPayload';
import {IPeopleMergeRequestPayload} from '../../types/requests/PeopleMergeRequestPayload';
import {PeopleSearchPaged} from '../../types/views/PeopleSearchPaged';
import {PEOPLE, PEOPLE_SEARCH, PEOPLE_UPDATE_REQUEST_LIST, PEOPLE_MERGE, PERSON_UPDATE_REQUEST} from './Actions';

import { PersonSearchResultPaged } from 'types/views/PersonSearchResultPaged';
import {SearchRequest} from '../../types/common/SearchRequest';
import {UpdatePipelineResult} from '../../types/views/UpdatePipelineResult';
import {IChangeRequestFindRequest, IChangeRequestLoadRequest} from '../../types/common/ChangeRequestProps';


export interface IPeopleState {
  peopleSearchParams: PeopleSearchParamsModel;
  peopleSearchView: string | undefined;
  peopleSearchSort: string | undefined;
  peopleResultLoading: boolean;
  peopleResultError: string | undefined;
  peopleResult: PersonSearchResultPaged | undefined;
  person: PersonModel | undefined;
  personLoading: boolean;
  peopleRefreshing: boolean;
  personSaving: boolean;
  personSaveError: string | undefined;
  personError: string | undefined;
  personUpdateRequestListResult: UpdatePipelineResult[] | [];
  personUpdateRequestListLoading: boolean;
  personUpdateRequestListError: string | undefined;
  personUpdateRequestListSaving: boolean;

  personUpdateRequest: UpdatePipelineResult | undefined;
  personUpdateRequestSaving: boolean;
  personUpdateRequestSaveError: string | undefined;
  personUpdateRequestLoading: boolean;
  personUpdateRequestError: string | undefined;

  mergeSourcePersonId: string | undefined;
  mergeDestinationPerson: PersonModel | undefined;
  mergePersonLoading: boolean;
  mergePersonSaving: boolean;
  mergePersonError: string | undefined;
}


const initialState: IPeopleState = {
  peopleSearchParams: defaultPeopleSearchParamsModel(),
  peopleSearchView: 'default',
  peopleSearchSort: 'Relevance',
  peopleResultLoading: false,
  peopleResultError: undefined,
  peopleResult: undefined,
  person: undefined,
  personLoading: false,
  peopleRefreshing: false,
  personSaving: false,
  personSaveError: undefined,
  personError: undefined,
  personUpdateRequestListResult: [],
  personUpdateRequestListLoading: false,
  personUpdateRequestListError: undefined,
  personUpdateRequestListSaving: false,
  personUpdateRequest: undefined,
  personUpdateRequestSaving: false,
  personUpdateRequestSaveError: undefined,
  personUpdateRequestLoading: false,
  personUpdateRequestError: undefined,
  mergeSourcePersonId: undefined,
  mergeDestinationPerson: undefined,
  mergePersonLoading: false,
  mergePersonSaving: false,
  mergePersonError: undefined
};

const peopleReducer = createReducer(initialState, {
  [PEOPLE.LOAD]: (state) => {
    state.personLoading = true;
    state.peopleRefreshing = false;
    state.personError = undefined;
    state.personSaveError = undefined;
    state.person = undefined;
  },
  [PEOPLE.REFRESH]: (state) => {
    state.personLoading = false;
    state.peopleRefreshing = true;
    state.personError = undefined;
    state.personSaveError = undefined;
    state.person = undefined;
  },
  [PEOPLE.LOADED]: (state, action: PayloadAction<PersonModel>) => {
    state.personLoading = false;
    state.peopleRefreshing = false;
    state.personError = undefined;
    state.personSaveError = undefined;
    state.person = action.payload;
  },
  [PEOPLE.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.personLoading = false;
    state.peopleRefreshing = false;
    state.personError = action.payload;
    state.personSaveError = undefined;
  },
  [PEOPLE.SAVE]: (state) => {
    state.personSaving = true;
    state.personError = undefined;
    state.personSaveError = undefined;
  },
  [PEOPLE.SAVED]: (state, action: PayloadAction<PersonModel>) => {
    state.personSaving = false;
    state.personError = undefined;
    state.personSaveError = undefined;
    state.person = action.payload;
  },
  [PEOPLE.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.personSaving = false;
    state.personError = undefined;
    state.personSaveError = action.payload;
  },
  [PEOPLE_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.peopleSearchView = action.payload;
  },
  [PEOPLE_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<PeopleSearchParamsModel>>) => {
    state.peopleSearchParams = action.payload.searchParams;
    state.peopleResultError = undefined;
    state.peopleResultLoading = true;
  },
  [PEOPLE_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<PeopleSearchPaged>>) => {
    state.peopleSearchParams = action.payload.searchParams;
    state.peopleResultError = undefined;
  },
  [PEOPLE_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.peopleResultError = action.payload;
    state.peopleResultLoading = false;
  },
  [PEOPLE_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.peopleResult = action.payload;
    state.peopleResultLoading = false;
    state.peopleResultError = undefined;
  },
  [PEOPLE_UPDATE_REQUEST_LIST.LOAD]: (state) => {
    state.personUpdateRequestListLoading = true;
    state.personUpdateRequestListError = undefined;
    state.personUpdateRequestListResult = [];
  },
  [PEOPLE_UPDATE_REQUEST_LIST.LOADED]: (state, action: PayloadAction<UpdatePipelineResult[]>) => {
    state.personUpdateRequestListLoading = false;
    state.personUpdateRequestListError = undefined;
    state.personUpdateRequestListResult = action.payload;
  },
  [PEOPLE_UPDATE_REQUEST_LIST.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.personUpdateRequestListLoading = false;
    state.personUpdateRequestListSaving = false;
    state.personUpdateRequestListError = action.payload;
    state.personUpdateRequestListResult = [];
  },
  [PEOPLE_UPDATE_REQUEST_LIST.SAVE]: (state) => {
    state.personUpdateRequestListSaving = true;
    state.personUpdateRequestListError = undefined;
    state.personUpdateRequestListResult = [];
  },
  [PEOPLE_UPDATE_REQUEST_LIST.SAVED]: (state, action: PayloadAction<UpdatePipelineResult[]>) => {
    state.personUpdateRequestListSaving = false;
    state.personUpdateRequestListError = undefined;
    state.personUpdateRequestListResult = action.payload;
  },
  [PERSON_UPDATE_REQUEST.LOAD]: (state, action: PayloadAction<IChangeRequestLoadRequest>) => {
    if (action.payload.updateId) {
      state.personUpdateRequestLoading = true;
      state.personUpdateRequestError = undefined;
    } else {
      state.personUpdateRequest = undefined;
      state.personUpdateRequestLoading = false;
      state.personUpdateRequestError = undefined;
    }
  },
  [PERSON_UPDATE_REQUEST.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.personUpdateRequestLoading = false;
    state.personUpdateRequestError = action.payload;
  },
  [PERSON_UPDATE_REQUEST.LOADED]: (state, action: PayloadAction<UpdatePipelineResult>) => {
    state.personUpdateRequest = action.payload;
    state.personUpdateRequestLoading = false;
    state.personUpdateRequestError = undefined;
  },
  [PERSON_UPDATE_REQUEST.FIND]: (state, action: PayloadAction<IChangeRequestFindRequest>) => {
    state.personUpdateRequest = undefined;
    state.personUpdateRequestLoading = true;
    state.personUpdateRequestError = undefined;
  },
  [PERSON_UPDATE_REQUEST.REFRESH]: (state, action: PayloadAction<UpdatePipelineResult>) => {
    state.personUpdateRequest = action.payload;
  },
  [PERSON_UPDATE_REQUEST.SAVE]: (state) => {
    state.personUpdateRequestSaving = true;
    state.personUpdateRequestSaveError = undefined;
  },
  [PERSON_UPDATE_REQUEST.SAVED]: (state) => {
    state.personUpdateRequestSaving = false;
  },
  [PERSON_UPDATE_REQUEST.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.personUpdateRequestSaving = false;
    state.personUpdateRequestSaveError = action.payload;
  },
  [PEOPLE_MERGE.LOAD]: (state, action: PayloadAction<string>) => {
    state.mergePersonLoading = true;
    state.mergePersonSaving = false;
    state.mergePersonError = undefined;
    state.mergeSourcePersonId = action.payload;
    state.mergeDestinationPerson = undefined;
  },
  [PEOPLE_MERGE.LOADED]: (state, action: PayloadAction<PersonModel>) => {
    state.mergePersonLoading = false;
    state.mergePersonSaving = false;
    state.mergePersonError = undefined;
    state.mergeSourcePersonId = undefined;
    state.mergeDestinationPerson = action.payload;
  },
  [PEOPLE_MERGE.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.mergePersonLoading = false;
    state.mergePersonSaving = false;
    state.mergePersonError = action.payload;
    state.mergeSourcePersonId = undefined;
    state.mergeDestinationPerson = undefined;
  },
  [PEOPLE_MERGE.SAVE]: (state) => {
    state.mergePersonLoading = false;
    state.mergePersonSaving = true;
    state.mergePersonError = undefined;
  },
  [PEOPLE_MERGE.SAVED]: (state) => {
    state.mergePersonLoading = false;
    state.mergePersonSaving = false;
    state.mergePersonError = undefined;
  },
  [PEOPLE_MERGE.CLEAR]: (state) => {
    state.mergePersonLoading = false;
    state.mergePersonSaving = false;
    state.mergePersonError = undefined;
    state.mergeSourcePersonId = undefined;
    state.mergeDestinationPerson = undefined;
  },
});

export default peopleReducer;