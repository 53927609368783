import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {FILES, filesSearchError, filesSearchSuccess} from './Actions';
import {FileSearchParams} from '../../types/requests/FileSearchParams';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {FilesPagedModel} from '../../types/views/FilesPagedModel';

function* SearchFiles({ page, searchParams }: ISearchRequest<FileSearchParams>) {
  console.log('SAGA - filesSaga - SearchFiles');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.filesUrl,
      method: 'PUT',
      url: `${apiConfig.filesPath}/search?page=${page}`,
      data: searchParams
    });
    const results: FilesPagedModel = FilesPagedModel.fromJS(res.data);
    yield put(filesSearchSuccess(results));
  } catch (error) {
    yield put(filesSearchError(error));
  }
}


export function* watchFilesSearch() {
  yield takeLatest(FILES.LOAD, ({payload}: PayloadAction<ISearchRequest<FileSearchParams>>) => SearchFiles(payload))
}

export function* watchFilesSearchRefresh() {
  yield takeLatest(FILES.REFRESH, ({payload}: PayloadAction<ISearchRequest<FileSearchParams>>) => SearchFiles(payload))
}

export default function* fileSaga() {
  yield all ([
    call(watchFilesSearch),
    call(watchFilesSearchRefresh),
  ])
}