import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {RE_INIT} from './Actions';

export interface IDevOperationsState {
  reInitError: any | undefined;
  reInitResult: string | undefined;
  reInitLoading: boolean;
}

const initialState: IDevOperationsState = {
  reInitError: undefined,
  reInitLoading: false,
  reInitResult: undefined,
};

const devOperationsReducer = createReducer(initialState, {
  [RE_INIT.LOAD]: (state) => {
    state.reInitLoading = true;
    state.reInitError = undefined;
    state.reInitResult = undefined;
  },
  [RE_INIT.LOADED]: (state, action: PayloadAction<string>) => {
    state.reInitLoading = true;
    state.reInitError = undefined;
    state.reInitResult = action.payload;
  },
  [RE_INIT.ERROR]: (state, action: PayloadAction<any>) => {
    state.reInitLoading = false;
    state.reInitError = action.payload;
    state.reInitResult = undefined;
  }
});

export default devOperationsReducer;
