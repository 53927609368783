import {EventItemViewModel} from '../models/EventItemViewModel';
import {camelKeys} from '../../@template/helpers/json';
import {toCamel} from '../../@template/helpers/strings';


export const defaultForStudentProfileEditModel = () => {
  return StudentProfileEditModel.fromJS({
    employerExperiences: [],
    employerHonourAwards: [],
    publicProfile: undefined,
  })
}

interface IPropsObject {
  [key: string]: any
}

const convertPythonBool = (val: any) => {
  if (val === 'False') {
    return false;
  } else if (val === 'True') {
    return true;
  } else if (val === 'None') {
    return '';
  }
  return val;
}

export const employerProfileFromTaskModel = (event: EventItemViewModel) => {
  const sourceObj: IPropsObject = {};

  event.refFields?.forEach(f => {
    if (f.refName === 'employer_experiences') {
      if (f?.refValue) {
        sourceObj['employerExperiences'] = camelKeys(JSON.parse(f?.refValue));
      }
    } else if (f.refName === 'employer_honour_awards') {
      if (f?.refValue) {
        sourceObj['employerHonourAwards'] = camelKeys(JSON.parse(f?.refValue));
      }
    } else {
      sourceObj[toCamel(f.refName??'')] = convertPythonBool(f?.refValue);
    }
  });
  return StudentProfileEditModel.fromJS({...sourceObj});
}

export class StudentProfileEditModel implements IStudentProfileEditModel {
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  publicProfile?: string | undefined;

  constructor(data?: IStudentProfileEditModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["employerExperiences"])) {
        this.employerExperiences = [] as any;
        for (let item of Data["employerExperiences"])
          this.employerExperiences!.push(EmployerExperienceModel.fromJS(item));
      }
      if (Array.isArray(Data["employerHonourAwards"])) {
        this.employerHonourAwards = [] as any;
        for (let item of Data["employerHonourAwards"])
          this.employerHonourAwards!.push(HonourAndAwardsModel.fromJS(item));
      }
      this.publicProfile = Data["publicProfile"];
    }
  }

  static fromJS(data: any): StudentProfileEditModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentProfileEditModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.employerExperiences)) {
      data["employerExperiences"] = [];
      for (let item of this.employerExperiences)
        data["employerExperiences"].push(item.toJSON());
    }
    if (Array.isArray(this.employerHonourAwards)) {
      data["employerHonourAwards"] = [];
      for (let item of this.employerHonourAwards)
        data["employerHonourAwards"].push(item.toJSON());
    }
    data["publicProfile"] = this.publicProfile;
    return data;
  }
}

export interface IStudentProfileEditModel {
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  publicProfile?: string | undefined;
}

export class EmployerExperienceModel implements IEmployerExperienceModel {
  roleTitle!: string;
  company!: string;
  startYear?: number | undefined;
  endYear?: number | undefined;
  isPresentEndYear?: boolean | undefined;

  constructor(data?: IEmployerExperienceModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.roleTitle = Data["roleTitle"];
      this.company = Data["company"];
      this.startYear = Data["startYear"];
      this.endYear = Data["endYear"];
      this.isPresentEndYear = Data["isPresentEndYear"];
    }
  }

  static fromJS(data: any): EmployerExperienceModel {
    data = typeof data === 'object' ? data : {};
    let result = new EmployerExperienceModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["roleTitle"] = this.roleTitle;
    data["company"] = this.company;
    data["startYear"] = this.startYear;
    data["endYear"] = this.endYear;
    data["isPresentEndYear"] = this.isPresentEndYear;
    return data;
  }
}

export interface IEmployerExperienceModel {
  roleTitle: string;
  company: string;
  startYear?: number | undefined;
  endYear?: number | undefined;
  isPresentEndYear?: boolean | undefined;
}

export class HonourAndAwardsModel implements IHonourAndAwardsModel {
  awardIndex?: number | undefined;
  awardTitle?: string | undefined;
  organisationName?: string | undefined;
  awardYear?: string | undefined;

  constructor(data?: IHonourAndAwardsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.awardIndex = Data["awardIndex"];
      this.awardTitle = Data["awardTitle"];
      this.organisationName = Data["organisationName"];
      this.awardYear = Data["awardYear"];
    }
  }

  static fromJS(data: any): HonourAndAwardsModel {
    data = typeof data === 'object' ? data : {};
    let result = new HonourAndAwardsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["awardIndex"] = this.awardIndex;
    data["awardTitle"] = this.awardTitle;
    data["organisationName"] = this.organisationName;
    data["awardYear"] = this.awardYear;
    return data;
  }
}

export interface IHonourAndAwardsModel {
  awardIndex?: number | undefined;
  awardTitle?: string | undefined;
  organisationName?: string | undefined;
  awardYear?: string | undefined;
}