import {ICommonLoadActionType, ICommonSaveActionType, ICommonSavePayload} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {STUDENT} from '../students/Actions';
import {IUserDetailModel} from '../../types/models/UserDetailModel';
import {IUserEditModel} from '../../types/requests/UserEditModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {IElasticUserPersonModel} from '../../types/views/ElasticUserPersonModel';
import {IUserSearchParams} from '../../types/requests/UserSearchParams';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {ITeamEditModel} from '../../types/requests/TeamEditModel';


export interface IUserActionTypes extends ICommonSaveActionType {
  DISABLE: string;
  ENABLE: string;
}

export interface ITeamActionTypes extends ICommonSaveActionType {
  DISABLE: string;
  ENABLE: string;
}

export const USER: IUserActionTypes = {
  LOAD: 'user/load',
  LOADED: 'user/loaded',
  LOAD_ERROR: 'user/loadError',
  SAVE: 'user/save',
  SAVED: 'user/saveSuccess',
  SAVE_ERROR: 'user/saveError',
  DISABLE: 'user/disable',
  ENABLE: 'user/enable'
};

export const TEAM: IUserActionTypes = {
  LOAD: 'team/load',
  LOADED: 'team/loaded',
  LOAD_ERROR: 'team/loadError',

  SAVE: 'team/save',
  SAVED: 'team/saveSuccess',
  SAVE_ERROR: 'team/saveError',
  DISABLE: 'team/disable',
  ENABLE: 'team/enable'
};

export const USER_SEARCH: ICommonLoadActionType = {
  ERROR: 'userSearch/error',
  LOAD: 'userSearch/load',
  LOADED: 'userSearch/loaded'
}

export const userLoad = createAction<string>(USER.LOAD);
export const userLoadSuccess = createAction<IUserDetailModel>(USER.LOADED);
export const userLoadError = createAction<string>(USER.LOAD_ERROR);

export const userEnable = createAction<string>(USER.ENABLE);
export const userDisable = createAction<string>(USER.DISABLE);

export const userSave = createAction<ICommonSavePayload<IUserEditModel>>(USER.SAVE);
export const userSaveSuccess = createAction<IUserDetailModel>(USER.SAVED);
export const userSaveError = createAction<string>(USER.SAVE_ERROR);

export const userSearch = createAction<ISearchRequest<IUserSearchParams>>(USER_SEARCH.LOAD);
export const userSearchSuccess = createAction<IPagedResult<IElasticUserPersonModel>>(USER_SEARCH.LOADED);
export const userSearchError = createAction<string>(USER_SEARCH.ERROR);

export const teamSave = createAction<ICommonSavePayload<ITeamEditModel>>(TEAM.SAVE);
export const teamSaveSuccess = createAction<ITeamEditModel>(TEAM.SAVED);
export const teamSaveError = createAction<string>(TEAM.SAVE_ERROR);
