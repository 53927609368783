import {TimelineStepIndexModel} from './TimelineStepIndexModel';

export class StudentPlacementSearchModel implements IStudentPlacementSearchModel {
  placementId?: string | undefined;
  dateCreated?: Date | undefined;
  companyId?: string | undefined;
  title?: string | undefined;
  isTravelRequired?: boolean | undefined;
  isFlightsRequired?: boolean | undefined;
  departureLocation?: string | undefined;
  arrivalLocation?: string | undefined;
  managerPersonIds?: string[] | undefined;
  accommodationRequired?: boolean | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  isComplete?: boolean | undefined;
  dateComplete?: Date | undefined;
  isCancelled?: boolean | undefined;
  dateCancelled?: Date | undefined;
  isCancelledDuring?: boolean | undefined;
  placementSettingId?: string | undefined;
  placementStepsComplete?: number | undefined;
  placementPercentComplete?: number | undefined;
  placementStudentSteps?: TimelineStepIndexModel[] | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;

  constructor(data?: IStudentPlacementSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.placementId = Data["placementId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.companyId = Data["companyId"];
      this.title = Data["title"];
      this.isTravelRequired = Data["isTravelRequired"];
      this.isFlightsRequired = Data["isFlightsRequired"];
      this.departureLocation = Data["departureLocation"];
      this.arrivalLocation = Data["arrivalLocation"];
      this.managerPersonIds = Data["managerPersonIds"];
      this.accommodationRequired = Data["accommodationRequired"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      this.isComplete = Data["isComplete"];
      this.dateComplete = Data["dateComplete"] ? new Date(Data["dateComplete"].toString()) : <any>undefined;
      this.isCancelled = Data["isCancelled"];
      this.dateCancelled = Data["dateCancelled"] ? new Date(Data["dateCancelled"].toString()) : <any>undefined;
      this.isCancelledDuring = Data["isCancelledDuring"];
      this.placementSettingId = Data["placementSettingId"];
      this.placementStepsComplete = Data["placementStepsComplete"];
      this.placementPercentComplete = Data["placementPercentComplete"];
      if (Array.isArray(Data["placementStudentSteps"])) {
        this.placementStudentSteps = [] as any;
        for (let item of Data["placementStudentSteps"])
          this.placementStudentSteps!.push(TimelineStepIndexModel.fromJS(item));
      }
      this.startDate = Data["startDate"] ? new Date(Data["startDate"].toString()) : <any>undefined;
      this.endDate = Data["endDate"] ? new Date(Data["endDate"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): StudentPlacementSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentPlacementSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["placementId"] = this.placementId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["companyId"] = this.companyId;
    data["title"] = this.title;
    data["isTravelRequired"] = this.isTravelRequired;
    data["isFlightsRequired"] = this.isFlightsRequired;
    data["departureLocation"] = this.departureLocation;
    data["arrivalLocation"] = this.arrivalLocation;
    data["managerPersonIds"] = this.managerPersonIds;
    data["accommodationRequired"] = this.accommodationRequired;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    data["isComplete"] = this.isComplete;
    data["dateComplete"] = this.dateComplete ? this.dateComplete.toISOString() : <any>undefined;
    data["isCancelled"] = this.isCancelled;
    data["dateCancelled"] = this.dateCancelled ? this.dateCancelled.toISOString() : <any>undefined;
    data["isCancelledDuring"] = this.isCancelledDuring;
    data["placementSettingId"] = this.placementSettingId;
    data["placementStepsComplete"] = this.placementStepsComplete;
    data["placementPercentComplete"] = this.placementPercentComplete;
    if (Array.isArray(this.placementStudentSteps)) {
      data["placementStudentSteps"] = [];
      for (let item of this.placementStudentSteps)
        data["placementStudentSteps"].push(item.toJSON());
    }
    data["startDate"] = this.startDate ? this.startDate.toISOString() : <any>undefined;
    data["endDate"] = this.endDate ? this.endDate.toISOString() : <any>undefined;
    return data;
  }
}

export interface IStudentPlacementSearchModel {
  placementId?: string | undefined;
  dateCreated?: Date | undefined;
  companyId?: string | undefined;
  title?: string | undefined;
  isTravelRequired?: boolean | undefined;
  isFlightsRequired?: boolean | undefined;
  departureLocation?: string | undefined;
  arrivalLocation?: string | undefined;
  managerPersonIds?: string[] | undefined;
  accommodationRequired?: boolean | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  isComplete?: boolean | undefined;
  dateComplete?: Date | undefined;
  isCancelled?: boolean | undefined;
  dateCancelled?: Date | undefined;
  isCancelledDuring?: boolean | undefined;
  placementSettingId?: string | undefined;
  placementStepsComplete?: number | undefined;
  placementPercentComplete?: number | undefined;
  placementStudentSteps?: TimelineStepIndexModel[] | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}