import {createAction} from '@reduxjs/toolkit';
import {ICommonLoadActionType} from '../Common.actions';

export const RE_INIT: ICommonLoadActionType = {
  LOAD: 'reInit/load',
  ERROR: 'reInit/loadError',
  LOADED: 'reInit/loadSuccess',
};

export const reInit = createAction(RE_INIT.LOAD);
export const reInitSuccess = createAction<string>(RE_INIT.LOADED);
export const reInitError = createAction<any>(RE_INIT.ERROR);
