export class GraduatingCapabilitiesSelection implements IGraduatingCapabilitiesSelection {
  graduatingCapability?: string | undefined;
  description?: string | undefined;

  constructor(data?: IGraduatingCapabilitiesSelection) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.graduatingCapability = Data["graduatingCapability"];
      this.description = Data["description"];
    }
  }

  static fromJS(data: any): GraduatingCapabilitiesSelection {
    data = typeof data === 'object' ? data : {};
    let result = new GraduatingCapabilitiesSelection();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["graduatingCapability"] = this.graduatingCapability;
    data["description"] = this.description;
    return data;
  }
}

export interface IGraduatingCapabilitiesSelection {
  graduatingCapability?: string | undefined;
  description?: string | undefined;
}