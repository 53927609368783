import {
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonLoadActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {HSPPlacementModel} from '../../types/models/HSPPlacementModel';
import {HSPPlacementEditDetailModel} from '../../types/requests/HSPPlacementEditDetailModel';
import {HSPPlacementDetailModel} from '../../types/models/HSPPlacementDetailModel';
import {HSPPlacementListByModel} from '../../types/requests/HSPPlacementListByModel';
import {EventItemViewModel} from '../../types/models/EventItemViewModel';
import {SeasonalActionModel} from '../../types/models/SeasonalActionModel';


export interface IHSPPlacementActionTypes extends ICommonSaveActionType {
  REFRESH: string;
}

export interface IHSPPlacementListActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const HSP_PLACEMENT: IHSPPlacementActionTypes = {
  LOAD: 'hspPlacement/load',
  LOAD_ERROR: 'hspPlacement/loadError',
  LOADED: 'hspPlacement/loadSuccess',
  REFRESH: 'hspPlacement/refresh',
  SAVE: 'hspPlacement/save',
  SAVED: 'hspPlacement/saveSuccess',
  SAVE_ERROR: 'hspPlacement/saveError',
};

export const HSP_PLACEMENT_LIST: IHSPPlacementListActionTypes = {
  REFRESH: 'hspPlacementList/list/refresh',
  ERROR: 'hspPlacementList/list/loadError',
  LOAD: 'hspPlacementList/list/load',
  LOADED: 'hspPlacementList/list/loadSuccess'
};

export const HSP_PLACEMENT_EVALUATIONS: ICommonLoadActionType = {
  ERROR: 'hspPlacement/evaluations/error',
  LOAD: 'hspPlacement/evaluations/load',
  LOADED: 'hspPlacement/evaluations/loadSuccess',
}

export const hspPlacementLoad = createAction<string>(HSP_PLACEMENT.LOAD);
export const hspPlacementRefresh = createAction<string>(HSP_PLACEMENT.REFRESH);
export const hspPlacementLoadSuccess = createAction<HSPPlacementDetailModel>(HSP_PLACEMENT.LOADED);
export const hspPlacementLoadError = createAction<string>(HSP_PLACEMENT.LOAD_ERROR);

export const hspPlacementSave = createAction<ICommonSavePayload<HSPPlacementEditDetailModel>>(HSP_PLACEMENT.SAVE);
export const hspPlacementSaveSuccess = createAction<HSPPlacementModel>(HSP_PLACEMENT.SAVED);
export const hspPlacementSaveError = createAction<string>(HSP_PLACEMENT.SAVE_ERROR);

export const hspPlacementList = createAction<HSPPlacementListByModel>(HSP_PLACEMENT_LIST.LOAD);
export const hspPlacementListRefresh = createAction<HSPPlacementListByModel>(HSP_PLACEMENT_LIST.REFRESH);
export const hspPlacementListSuccess = createAction<HSPPlacementDetailModel[]>(HSP_PLACEMENT_LIST.LOADED);
export const hspPlacementListError = createAction<string>(HSP_PLACEMENT_LIST.ERROR);

export const hspPlacementEvaluationList = createAction<boolean>(HSP_PLACEMENT_EVALUATIONS.LOAD);
export const hspPlacementEvaluationListSuccess = createAction<HSPPlacementDetailModel[]>(HSP_PLACEMENT_EVALUATIONS.LOADED);
export const hspPlacementEvaluationListError = createAction<string>(HSP_PLACEMENT_EVALUATIONS.ERROR);