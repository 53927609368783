import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@template/utility/Utils';
import {dashboardPaths} from './dashboard/paths';
import authRouteConfig from './auth';
import errorPagesConfig from './errorPages';
import dashboardRoutesConfig from './dashboard';
import studentRoutesConfig from './students';
import workShadowRoutesConfig from './workShadow';
import teamPathsConfig from './team';
import adminRoutesConfig from './admin';
import emailTemplateRoutesConfig from './emailTemplateSettings';
import scorecardRoutesConfig from './scorecards';
import companyRoutesConfig from './companies';
import contactRoutesConfig from './contacts';
import volunteerRoutesConfig from './volunteers';
import peopleRoutesConfig from './people';
import inboxPathsConfig from './messaging';
import mailingListRoutesConfig from './mailingList';
import settingsRoutesConfig from './settings';
import reportingRoutesConfig from './reporting';
import breakSettingsRoutesConfig from './breakSettings';
import alumniRoutesConfig from './alumni';
import {PermissionLevel} from '../types/common/Permissions';

const routeConfigs = [
  ...authRouteConfig,
  ...errorPagesConfig,
  ...dashboardRoutesConfig,
  ...studentRoutesConfig,
  ...workShadowRoutesConfig,
  ...teamPathsConfig,
  ...adminRoutesConfig,
  ...emailTemplateRoutesConfig,
  ...scorecardRoutesConfig,
  ...companyRoutesConfig,
  ...contactRoutesConfig,
  ...volunteerRoutesConfig,
  ...peopleRoutesConfig,
  ...inboxPathsConfig,
  ...mailingListRoutesConfig,
  ...settingsRoutesConfig,
  ...reportingRoutesConfig,
  ...breakSettingsRoutesConfig,
  ...alumniRoutesConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    auth: PermissionLevel.ADVISOR,
    path: '/',
    exact: true,
    component: () => <Redirect to={dashboardPaths.LandingPage} />,
  },
  {
    component: () => <Redirect to={'/error-pages/error-404'} />,
  },
];

export default routes;
