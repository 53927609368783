export class CompanySuggestRequest implements ICompanySuggestRequest {
  isClient?: boolean | undefined;
  isPartner?: boolean | undefined;
  isEducation?: boolean | undefined;

  constructor(data?: ICompanySuggestRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.isClient = Data['isClient'];
      this.isPartner = Data['isPartner'];
      this.isEducation = Data['isEducation'];
    }
  }

  static fromJS(data: any): CompanySuggestRequest {
    data = typeof data === 'object' ? data : {};
    let result = new CompanySuggestRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isClient'] = this.isClient;
    data['isPartner'] = this.isPartner;
    data['isEducation'] = this.isEducation;
    return data;
  }
}

export interface ICompanySuggestRequest {
  isClient?: boolean | undefined;
  isPartner?: boolean | undefined;
  isEducation?: boolean | undefined;
}
