import {ICommonCacheActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {PlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {PlacementIdList} from '../../types/requests/PlacementIdList';
import {PlacementListItem} from '../../types/views/PlacementListItem';

export const PLACEMENT_CACHE: ICommonCacheActionType = {
  ADD: 'placementCache/add',
  UPDATE: 'placementCache/updated',
  MERGE_ITEMS: 'placementCache/merge',
  ERROR: 'placementCache/error',
  REMOVE: 'placementCache/remove'
};

export const placementCacheAdd = createAction<string[]>(PLACEMENT_CACHE.ADD);
export const placementCacheUpdate = createAction<string[]>(PLACEMENT_CACHE.UPDATE);
export const placementCacheMerge = createAction<PlacementListItem[]>(PLACEMENT_CACHE.MERGE_ITEMS);
export const placementCacheError = createAction<string>(PLACEMENT_CACHE.ERROR);
export const placementCacheRemove = createAction<string>(PLACEMENT_CACHE.REMOVE);
