import {CompanySearchParams, defaultForCompanySearchParams} from '../../types/requests/CompanySearchParams';
import {CompanyModel} from '../../types/models/CompanyModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {COMPANY, COMPANY_LOGO, COMPANY_SEARCH, MY_COMPANIES, OPPORTUNITY_SEARCH, RELATED_COMPANIES} from './Actions';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {CompanySearchPaged} from '../../types/views/CompanySearchPaged';
import {OpportunitiesSearchPaged} from '../../types/views/OpportunitiesSearchPaged';
import {
  defaultForOpportunitySearchParamsModel,
  OpportunitySearchParamsModel,
} from '../../types/requests/OpportunitySearchParamsModel';
import {CompanySearchModel} from '../../types/views/CompanySearchModel';

export interface ICompaniesState {
  companySearchParams: CompanySearchParams;
  companySearchView: string | undefined;
  companySearchSort: string | undefined;
  companyResultLoading: boolean;
  companyResultRefreshing: boolean;
  companyResultError: string | undefined;
  companyResult: IPagedResult<CompanySearchModel> | undefined;
  companyLoading: boolean;
  companySaving: boolean;
  companySaveError: string | undefined;
  companyError: string | undefined;
  companyDetail: CompanyModel | undefined;

  companyLogoSaving: boolean;
  companyLogoSaveError: string | undefined;

  opportunitySearchParams: OpportunitySearchParamsModel | undefined;
  opportunitySearchView: string | undefined;
  opportunitySearchSort: string | undefined;
  opportunityResultLoading: boolean;
  opportunityResultRefreshing: boolean;
  opportunityResultError: string | undefined;
  opportunityResult: OpportunitiesSearchPaged | undefined;
  myCompaniesResultLoading: boolean;
  myCompaniesResultError: string | undefined;
  myCompaniesResult: CompanySearchPaged | undefined;
  relatedCompaniesResult: Array<CompanyModel> | undefined;
  relatedCompaniesResultLoading: boolean;
  relatedCompaniesResultError: string | undefined;
}

const initialState: ICompaniesState = {
  companySearchParams: defaultForCompanySearchParams(),
  companySearchView: 'default',
  companySearchSort: 'Relevance',
  companyResultLoading: false,
  companyResultRefreshing: false,
  companyResultError: undefined,
  companyResult: undefined,
  companyLoading: false,
  companyError: undefined,
  companySaving: false,
  companySaveError: undefined,
  companyDetail: undefined,
  companyLogoSaving: false,
  companyLogoSaveError: undefined,
  opportunitySearchParams: defaultForOpportunitySearchParamsModel(),
  opportunitySearchView: 'default',
  opportunitySearchSort: 'Relevance',
  opportunityResultLoading: false,
  opportunityResultRefreshing: false,
  opportunityResultError: undefined,
  opportunityResult: undefined,
  myCompaniesResultLoading: false,
  myCompaniesResultError: undefined,
  myCompaniesResult: undefined,
  relatedCompaniesResult: undefined,
  relatedCompaniesResultLoading: false,
  relatedCompaniesResultError: undefined,
};

const companiesReducer = createReducer(initialState, {
  [COMPANY_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.companySearchView = action.payload;
  },
  [COMPANY_SEARCH.LOAD]: (state, action: PayloadAction<ISearchRequest<CompanySearchParams>>) => {
    state.companySearchParams = action.payload.searchParams;
    state.companySearchSort = action.payload.sort;
    state.companyResultLoading = true;
    state.companyResultRefreshing = false;
    state.companyResultError = undefined;
  },
  [COMPANY_SEARCH.REFRESH]: (state, action: PayloadAction<ISearchRequest<CompanySearchParams>>) => {
    state.companySearchParams = action.payload.searchParams;
    state.companySearchSort = action.payload.sort;
    state.companyResultLoading = false;
    state.companyResultRefreshing = true;
    state.companyResultError = undefined;
  },
  [COMPANY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.companyResultLoading = false;
    state.companyResultRefreshing = false;
    state.companyResultError = action.payload;
  },
  [COMPANY_SEARCH.LOADED]: (state, action: PayloadAction<CompanySearchPaged>) => {
    state.companyResultLoading = false;
    state.companyResultRefreshing = false;
    state.companyResultError = undefined;
    state.companyResult = action.payload;
  },
  [COMPANY.LOAD]: (state) => {
    state.companyDetail = undefined;
    state.companyLoading = true;
    state.companyError = undefined;
  },
  [COMPANY.LOADED]: (state, action: PayloadAction<CompanyModel>) => {
    state.companyDetail = action.payload;
    state.companyLoading = false;
    state.companyError = undefined;
  },
  [COMPANY.REFRESH]: (state, action: PayloadAction<string>) => {
    state.companyLoading = false;
    state.companyError = undefined;
  },
  [COMPANY.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.companyLoading = false;
    state.companyError = action.payload;
  },
  [COMPANY.SAVE]: (state) => {
    state.companySaving = true;
    state.companySaveError = undefined;
  },
  [COMPANY.SAVED]: (state, action: PayloadAction<CompanyModel>) => {
    state.companySaving = false;
    state.companySaveError = undefined;
    state.companyDetail = action.payload;
  },
  [COMPANY.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.companySaving = false;
    state.companySaveError = action.payload;
  },

  [COMPANY_LOGO.SAVE]: (state) => {
    state.companyLogoSaving = true;
    state.companyLogoSaveError = undefined;
  },
  [COMPANY_LOGO.SAVED]: (state) => {
    state.companyLogoSaving = false;
    state.companyLogoSaveError = undefined;
  },
  [COMPANY_LOGO.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.companyLogoSaving = false;
    state.companyLogoSaveError = action.payload;
  },

  [OPPORTUNITY_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.opportunitySearchView = action.payload;
  },
  [OPPORTUNITY_SEARCH.LOAD]: (state, action: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => {
    state.opportunitySearchParams = action.payload.searchParams;
    state.opportunitySearchSort = action.payload.sort;
    state.opportunityResultLoading = true;
    state.opportunityResultRefreshing = false;
    state.opportunityResultError = undefined;
  },
  [OPPORTUNITY_SEARCH.REFRESH]: (state, action: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => {
    state.opportunitySearchParams = action.payload.searchParams;
    state.opportunitySearchSort = action.payload.sort;
    state.opportunityResultLoading = false;
    state.opportunityResultRefreshing = true;
    state.opportunityResultError = undefined;
  },
  [OPPORTUNITY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.opportunityResultLoading = false;
    state.opportunityResultRefreshing = false;
    state.opportunityResultError = action.payload;
  },
  [OPPORTUNITY_SEARCH.LOADED]: (state, action: PayloadAction<OpportunitiesSearchPaged>) => {
    state.opportunityResultLoading = false;
    state.opportunityResultRefreshing = false;
    state.opportunityResultError = undefined;
    state.opportunityResult = action.payload;
  },
  [MY_COMPANIES.LOAD]: (state) => {
    state.myCompaniesResultLoading = true;
    state.myCompaniesResultError = undefined;
  },
  [MY_COMPANIES.ERROR]: (state, action: PayloadAction<string>) => {
    state.myCompaniesResultLoading = false;
    state.myCompaniesResultError = action.payload;
  },
  [MY_COMPANIES.LOADED]: (state, action: PayloadAction<CompanySearchPaged>) => {
    state.myCompaniesResultLoading = false;
    state.myCompaniesResultError = undefined;
    state.myCompaniesResult = action.payload;
  },
  [RELATED_COMPANIES.LOAD]: (state) => {
    state.relatedCompaniesResultLoading = true;
    state.relatedCompaniesResultError = undefined;
  },
  [RELATED_COMPANIES.ERROR]: (state, action: PayloadAction<string>) => {
    state.relatedCompaniesResultLoading = false;
    state.relatedCompaniesResultError = action.payload;
  },
  [RELATED_COMPANIES.LOADED]: (state, action: PayloadAction<Array<CompanyModel>>) => {
    state.relatedCompaniesResultLoading = false;
    state.relatedCompaniesResultError = undefined;
    state.relatedCompaniesResult = action.payload;
  },
});

export default companiesReducer;
