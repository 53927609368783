import {
  defaultWorkShadowSearchParamsModel,
  WorkShadowSearchParamsModel,
} from '../../types/requests/WorkShadowSearchParamsModel';
import {WorkShadowEditDetailModel} from '../../types/requests/WorkShadowEditDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {WORKSHADOW, WORKSHADOW_SEARCH, HSP_PLACEMENTS, HSP_STUDENT_POOLS, HSP_OPPORTUNITIES, MY_HSP_STUDENTS} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {WorkShadowDetailModel} from '../../types/models/WorkShadowDetailModel';
import {PersonModel} from '../../types/models/PersonModel';
import {HSPPlacementDetailModel} from '../../types/models/HSPPlacementDetailModel';
import {HSPPoolItem} from '../../types/views/HSPPoolItem';
import {StudentPoolItem} from '../../types/views/StudentPoolItem';
import {HSPStudentOpportunityListView} from '../../types/views/HSPStudentOpportunityListView';

export interface IWorkShadowState {
  workShadowSearchParams: WorkShadowSearchParamsModel;
  workShadowSearchView: string | undefined;
  workShadowSearchSort: string | undefined;
  workShadowResultLoading: boolean;
  workShadowResultError: string | undefined;
  workShadowResult: PersonSearchResultPaged | undefined;
  workShadowLoading: boolean;
  workShadowRefreshing: boolean;
  workShadowSaving: boolean;
  workShadowSaveError: string | undefined;
  workShadowError: string | undefined;
  workShadowDetail: PersonModel | undefined;
  workShadowEditDetail: WorkShadowEditDetailModel | undefined;
  hspPlacements: HSPPlacementDetailModel[] | undefined;
  hspPlacementsLoading: boolean;
  hspPlacementsRefreshing: boolean;
  hspPlacementsError: string | undefined;
  hspPoolsResult: HSPPoolItem[] | undefined;
  hspPoolsLoading: boolean;
  hspPoolsRefreshing: boolean;
  hspPoolsError: string | undefined;
  hspOpportunitiesResult: HSPStudentOpportunityListView[] | undefined;
  hspOpportunitiesLoading: boolean;
  hspOpportunitiesError: string | undefined;
  hspOpportunitiesRefreshing: boolean;
  myHSPStudentsResultLoading: boolean;
  myHSPStudentsResultError: string | undefined;
  myHSPStudentsResult: PersonSearchResultPaged | undefined;
}

const initialState: IWorkShadowState = {
  workShadowSearchParams: defaultWorkShadowSearchParamsModel(),
  workShadowSearchView: 'default',
  workShadowSearchSort: 'Relevance',
  workShadowResultLoading: false,
  workShadowResultError: undefined,
  workShadowResult: undefined,
  workShadowLoading: false,
  workShadowRefreshing: false,
  workShadowSaving: false,
  workShadowSaveError: undefined,
  workShadowError: undefined,
  workShadowDetail: undefined,
  workShadowEditDetail: undefined,
  hspPlacements: undefined,
  hspPlacementsError: undefined,
  hspPlacementsLoading: false,
  hspPlacementsRefreshing: false,
  hspPoolsResult: undefined,
  hspPoolsLoading: false,
  hspPoolsRefreshing: false,
  hspPoolsError: undefined,
  hspOpportunitiesResult: undefined,
  hspOpportunitiesLoading: false,
  hspOpportunitiesRefreshing: false,
  hspOpportunitiesError: undefined,
  myHSPStudentsResultLoading: false,
  myHSPStudentsResultError: undefined,
  myHSPStudentsResult: undefined,
}

const workShadowReducer = createReducer(initialState, {
  [WORKSHADOW_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.workShadowSearchView = action.payload;
  },
  [WORKSHADOW_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<WorkShadowSearchParamsModel>>) => {
    state.workShadowSearchParams = action.payload.searchParams;
    state.workShadowResultLoading = true;
    state.workShadowResultError = undefined;
  },
  [WORKSHADOW_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<WorkShadowSearchParamsModel>>) => {
    state.workShadowSearchParams = action.payload.searchParams;
    state.workShadowResultError = undefined;
  },
  [WORKSHADOW_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowResultLoading = false;
    state.workShadowResultError = action.payload;
  },
  [WORKSHADOW_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.workShadowResultLoading = false;
    state.workShadowResultError = undefined;
    state.workShadowResult =  action.payload;
  },
  [WORKSHADOW.LOAD]: (state) => {
    state.workShadowLoading = true;
    state.workShadowRefreshing = false;
    state.workShadowError = undefined;
    state.workShadowSaveError = undefined;
    state.workShadowDetail = undefined;
  },
  [WORKSHADOW.REFRESH]: (state) => {
    state.workShadowLoading = false;
    state.workShadowRefreshing = true;
    state.workShadowError = undefined;
    state.workShadowSaveError = undefined;
  },
  [WORKSHADOW.LOADED]: (state, action: PayloadAction<WorkShadowDetailModel>) => {
    state.workShadowLoading = false;
    state.workShadowRefreshing = false;
    state.workShadowError = undefined;
    state.workShadowSaveError = undefined;
    state.workShadowDetail = action.payload;
  },
  [WORKSHADOW.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowLoading = false;
    state.workShadowRefreshing = false;
    state.workShadowError = action.payload;
    state.workShadowSaveError = undefined;
  },
  [WORKSHADOW.SAVE]: (state) => {
    state.workShadowSaving = true;
    state.workShadowError = undefined;
    state.workShadowSaveError = undefined;
  },
  [WORKSHADOW.ROLLBACK]: (state) => {
    state.workShadowSaving = true;
    state.workShadowError = undefined;
    state.workShadowSaveError = undefined;
  },
  [WORKSHADOW.SAVED]: (state, action: PayloadAction<WorkShadowDetailModel>) => {
    state.workShadowSaving = false;
    state.workShadowError = undefined;
    state.workShadowSaveError = undefined;
    state.workShadowDetail = action.payload;
  },
  [WORKSHADOW.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowSaving = false;
    state.workShadowError = undefined;
    state.workShadowSaveError = action.payload;
  },
  [HSP_PLACEMENTS.LOAD]: (state) => {
    state.hspPlacementsLoading = true;
    state.hspPlacementsRefreshing = false;
    state.hspPlacementsError = undefined;
    state.hspPlacements = undefined;
  },
  [HSP_PLACEMENTS.REFRESH]: (state) => {
    state.hspPlacementsLoading = false;
    state.hspPlacementsRefreshing = true;
    state.hspPlacementsError = undefined;
  },
  [HSP_PLACEMENTS.LOADED]: (state, action: PayloadAction<HSPPlacementDetailModel[]>) => {
    state.hspPlacementsLoading = false;
    state.hspPlacementsRefreshing = false;
    state.hspPlacementsError = undefined;
    state.hspPlacements = action.payload;
  },
  [HSP_PLACEMENTS.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPlacementsLoading = false;
    state.hspPlacementsRefreshing = false;
    state.hspPlacementsError = action.payload;
  },
  [HSP_STUDENT_POOLS.LOAD]: (state) => {
    state.hspPoolsLoading = true;
    state.hspPoolsRefreshing = false;
    state.hspPoolsError = undefined;
    state.hspPoolsResult = undefined;
  },
  [HSP_STUDENT_POOLS.REFRESH]: (state) => {
    state.hspPoolsLoading = false;
    state.hspPoolsRefreshing = true;
    state.hspPoolsError = undefined;
  },
  [HSP_STUDENT_POOLS.LOADED]: (state, action: PayloadAction<HSPPoolItem[]>) => {
    state.hspPoolsLoading = false;
    state.hspPoolsRefreshing = false;
    state.hspPoolsResult = action.payload;
    state.hspPoolsError = undefined;
  },
  [HSP_STUDENT_POOLS.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPoolsLoading = false;
    state.hspPoolsRefreshing = false;
    state.hspPoolsError = action.payload;
  },
  [HSP_OPPORTUNITIES.LOAD]: (state) => {
    state.hspOpportunitiesLoading = true;
    state.hspOpportunitiesRefreshing = false;
    state.hspOpportunitiesError = undefined;
    state.hspOpportunitiesResult = undefined;
  },
  [HSP_OPPORTUNITIES.REFRESH]: (state) => {
    state.hspOpportunitiesLoading = false;
    state.hspOpportunitiesRefreshing = true;
    state.hspOpportunitiesError = undefined;
  },
  [HSP_OPPORTUNITIES.LOADED]: (state, action: PayloadAction<HSPStudentOpportunityListView[]>) => {
    state.hspOpportunitiesLoading = false;
    state.hspOpportunitiesRefreshing = false;
    state.hspOpportunitiesResult = action.payload;
    state.hspOpportunitiesError = undefined;
  },
  [HSP_OPPORTUNITIES.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunitiesLoading = false;
    state.hspOpportunitiesRefreshing = false;
    state.hspOpportunitiesError = action.payload;
  },
  [MY_HSP_STUDENTS.LOAD]: (state) => {
    state.myHSPStudentsResultLoading = true;
    state.myHSPStudentsResultError = undefined;
  },
  [MY_HSP_STUDENTS.ERROR]: (state, action: PayloadAction<string>) => {
    state.myHSPStudentsResultLoading = false;
    state.myHSPStudentsResultError = action.payload;
  },
  [MY_HSP_STUDENTS.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.myHSPStudentsResultLoading = false;
    state.myHSPStudentsResultError = undefined;
    state.myHSPStudentsResult = action.payload;
  }
});

export default workShadowReducer;
