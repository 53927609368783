import {FILE_CACHE} from './Actions';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {FileMetaModel} from '../../types/models/FileMetaModel';

export interface IFilesCacheState {
  fileCacheUpdating: boolean | false;
  fileCacheError: string | undefined;
  filePendingIdList: string[];
  fileCache: FileMetaModel[];
}

const initialState: IFilesCacheState = {
  fileCacheUpdating: false,
  fileCacheError: undefined,
  filePendingIdList: [],
  fileCache: [],
}

const filesCacheReducer = createReducer(initialState, {
  [FILE_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.filePendingIdList = [...new Set([...state.filePendingIdList, ...action.payload])];
    state.fileCacheError = undefined;
  },
  [FILE_CACHE.UPDATE]: (state) => {
    state.filePendingIdList = [];
    state.fileCacheUpdating = true;
    state.fileCacheError = undefined;
  },
  [FILE_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<FileMetaModel[]>) => {
    let currentFilesCache = state.fileCache;
    action.payload.map(item => {
      if (!currentFilesCache.find(x => x.fileId === item.fileId)) {
        currentFilesCache.push(item);
      }
    });
    state.fileCache = currentFilesCache;
    state.fileCacheUpdating = false;
    state.fileCacheError = undefined;
  },
  [FILE_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.fileCache = state.fileCache.filter(x => x.fileId !== action.payload);
    state.fileCacheUpdating = false;
    state.fileCacheError = undefined;
  },
});

export default filesCacheReducer;