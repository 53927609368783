export class AlumniTagModel implements IAlumniTagModel {
  tag?: string | undefined;
  dateAdded?: string | undefined;

  constructor(data?: IAlumniTagModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.tag = Data["tag"];
      this.dateAdded = Data["dateAdded"];
    }
  }

  static fromJS(data: any): AlumniTagModel {
    data = typeof data === 'object' ? data : {};
    let result = new AlumniTagModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["tag"] = this.tag;
    data["dateAdded"] = this.dateAdded;
    return data;
  }
}

export interface IAlumniTagModel {
  tag?: string | undefined;
  dateAdded?: string | undefined;
}