import {formatDate} from '../../@template/helpers/datetimes';

export class EngagementInfoSearchModel implements IEngagementInfoSearchModel {
  isActive?: boolean | undefined;
  isAppEnabled?: boolean | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;
  languageGroup?: string | undefined;
  heritage?: string | undefined;
  dateOfBirth?: Date | undefined;

  constructor(data?: IEngagementInfoSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.isActive = Data["isActive"];
      this.isAppEnabled = Data["isAppEnabled"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
      this.primaryAdvisorFullName = Data["primaryAdvisorFullName"];
      this.languageGroup = Data["languageGroup"];
      this.heritage = Data["heritage"];
      this.dateOfBirth = Data["dateOfBirth"] ? new Date(Data["dateOfBirth"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): EngagementInfoSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new EngagementInfoSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isActive"] = this.isActive;
    data["isAppEnabled"] = this.isAppEnabled;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
    data["primaryAdvisorFullName"] = this.primaryAdvisorFullName;
    data["languageGroup"] = this.languageGroup;
    data["heritage"] = this.heritage;
    data["dateOfBirth"] = this.dateOfBirth ? formatDate(this.dateOfBirth) : <any>undefined;
    return data;
  }
}

export interface IEngagementInfoSearchModel {
  isActive?: boolean | undefined;
  isAppEnabled?: boolean | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;
  languageGroup?: string | undefined;
  heritage?: string | undefined;
  dateOfBirth?: Date | undefined;
}
