export class EducationSearchModel implements IEducationSearchModel {
  educationSummary?: string | undefined;
  educationTags?: string[] | undefined;
  educationStatus?: string | undefined;
  isActive?: boolean | undefined;
  onboardingEducationSettingId?: string | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  institutionType?: string | undefined;

  constructor(data?: IEducationSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.educationSummary = Data["educationSummary"];
      if (Array.isArray(Data["educationTags"])) {
        this.educationTags = [] as any;
        for (let item of Data["educationTags"])
          this.educationTags!.push(item);
      }
      this.educationStatus = Data["educationStatus"];
      this.isActive = Data["isActive"];
      this.onboardingEducationSettingId = Data["onboardingEducationSettingId"];
      this.onboardingStepsComplete = Data["onboardingStepsComplete"];
      this.onboardingPercent = Data["onboardingPercent"];
      this.institutionType = Data["institutionType"];
    }
  }

  static fromJS(data: any): EducationSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new EducationSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["educationSummary"] = this.educationSummary;
    if (Array.isArray(this.educationTags)) {
      data["educationTags"] = [];
      for (let item of this.educationTags)
        data["educationTags"].push(item);
    }
    data["educationStatus"] = this.educationStatus;
    data["isActive"] = this.isActive;
    data["onboardingEducationSettingId"] = this.onboardingEducationSettingId;
    data["onboardingStepsComplete"] = this.onboardingStepsComplete;
    data["onboardingPercent"] = this.onboardingPercent;
    data["institutionType"] = this.institutionType;
    return data;
  }
}

export interface IEducationSearchModel {
  educationSummary?: string | undefined;
  educationTags?: string[] | undefined;
  educationStatus?: string | undefined;
  isActive?: boolean | undefined;
  onboardingEducationSettingId?: string | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  institutionType?: string | undefined;
}