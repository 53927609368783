import {formatDate} from '../../@template/helpers/datetimes';

export const defaultForCompanySearchParams = () => {
  return CompanySearchParams.fromJS({
    searchText: '',
    advisorUsers: [],
    primaryOnly: true,
    advisorSelected: false,
    excludePrimarySelected: false,
    noPrimaryAppointed: false,
    noAdvisorAppointed: false,
    byTeam: false,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    partnerTags: [],
    partnerStatusFlags: ['Potential', 'Active'],
    clientTags: [],
    clientStatusFlags: ['Potential', 'Pipeline', 'Active'],
    educationTags: [],
    educationStatusFlags: ['Potential', 'Pipeline', 'Active'],
    onboardingPercentTo: 100,
    state: [],
    countryCode: 'AU',
    companyType: '',
    isNoneType: false,
    typeFilter: 'All',
    notArchived: true,
    includeArchived: false,
    onlyArchived: false,
  });
};


export class CompanySearchParams implements ICompanySearchParams {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  isPartner?: boolean | undefined;
  partnerTags?: string[] | undefined;
  partnerStatusFlags?: string[] | undefined;
  partnerType?: string | undefined;
  isClient?: boolean | undefined;
  clientTags?: string[] | undefined;
  clientStatusFlags?: string[] | undefined;
  isEducation?: boolean | undefined;
  educationTags?: string[] | undefined;
  educationStatusFlags?: string[] | undefined;
  institutionType?: string | undefined;
  onboardingPercentFrom?: number | undefined;
  onboardingPercentTo?: number | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  companyType?: string | undefined;
  contractYearExpiresFrom?: number | undefined;
  contractYearExpiresTo?: number | undefined;
  isNoneType?: boolean | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;

  constructor(data?: ICompanySearchParams) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      this.joinedFrom = Data["joinedFrom"] ? new Date(Data["joinedFrom"].toString()) : <any>undefined;
      this.joinedTo = Data["joinedTo"] ? new Date(Data["joinedTo"].toString()) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data["primaryOnly"];
      this.advisorSelected = Data["advisorSelected"];
      this.excludePrimarySelected = Data["excludePrimarySelected"];
      this.noPrimaryAppointed = Data["noPrimaryAppointed"];
      this.noAdvisorAppointed = Data["noAdvisorAppointed"];
      this.byTeam = Data["byTeam"];
      this.advisorTeamId = Data["advisorTeamId"];
      this.advisorSubTeamId = Data["advisorSubTeamId"];
      this.byAdvisorTeam = Data["byAdvisorTeam"];
      this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
      this.isPartner = Data["isPartner"];
      if (Array.isArray(Data["partnerTags"])) {
        this.partnerTags = [] as any;
        for (let item of Data["partnerTags"])
          this.partnerTags!.push(item);
      }
      if (Array.isArray(Data["partnerStatusFlags"])) {
        this.partnerStatusFlags = [] as any;
        for (let item of Data["partnerStatusFlags"])
          this.partnerStatusFlags!.push(item);
      }
      this.partnerType = Data["partnerType"];
      this.isClient = Data["isClient"];
      if (Array.isArray(Data["clientTags"])) {
        this.clientTags = [] as any;
        for (let item of Data["clientTags"])
          this.clientTags!.push(item);
      }
      if (Array.isArray(Data["clientStatusFlags"])) {
        this.clientStatusFlags = [] as any;
        for (let item of Data["clientStatusFlags"])
          this.clientStatusFlags!.push(item);
      }
      this.isEducation = Data["isEducation"];
      if (Array.isArray(Data["educationTags"])) {
        this.educationTags = [] as any;
        for (let item of Data["educationTags"])
          this.educationTags!.push(item);
      }
      if (Array.isArray(Data["educationStatusFlags"])) {
        this.educationStatusFlags = [] as any;
        for (let item of Data["educationStatusFlags"])
          this.educationStatusFlags!.push(item);
      }
      this.institutionType = Data["institutionType"];
      this.onboardingPercentFrom = Data["onboardingPercentFrom"];
      this.onboardingPercentTo = Data["onboardingPercentTo"];
      if (Array.isArray(Data["state"])) {
        this.state = [] as any;
        for (let item of Data["state"])
          this.state!.push(item);
      }
      this.countryCode = Data["countryCode"];
      this.companyType = Data["companyType"];
      this.contractYearExpiresFrom = Data["contractYearExpiresFrom"];
      this.contractYearExpiresTo = Data["contractYearExpiresTo"];
      this.isNoneType = Data["isNoneType"];
      this.notArchived = Data["notArchived"];
      this.includeArchived = Data["includeArchived"];
      this.onlyArchived = Data["onlyArchived"];
    }
  }

  static fromJS(data: any): CompanySearchParams {
    data = typeof data === 'object' ? data : {};
    let result = new CompanySearchParams();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    data["joinedFrom"] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
    data["joinedTo"] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryOnly"] = this.primaryOnly;
    data["advisorSelected"] = this.advisorSelected;
    data["excludePrimarySelected"] = this.excludePrimarySelected;
    data["noPrimaryAppointed"] = this.noPrimaryAppointed;
    data["noAdvisorAppointed"] = this.noAdvisorAppointed;
    data["byTeam"] = this.byTeam;
    data["advisorTeamId"] = this.advisorTeamId;
    data["advisorSubTeamId"] = this.advisorSubTeamId;
    data["byAdvisorTeam"] = this.byAdvisorTeam;
    data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
    data["isPartner"] = this.isPartner;
    if (Array.isArray(this.partnerTags)) {
      data["partnerTags"] = [];
      for (let item of this.partnerTags)
        data["partnerTags"].push(item);
    }
    if (Array.isArray(this.partnerStatusFlags)) {
      data["partnerStatusFlags"] = [];
      for (let item of this.partnerStatusFlags)
        data["partnerStatusFlags"].push(item);
    }
    data["partnerType"] = this.partnerType;
    data["isClient"] = this.isClient;
    if (Array.isArray(this.clientTags)) {
      data["clientTags"] = [];
      for (let item of this.clientTags)
        data["clientTags"].push(item);
    }
    if (Array.isArray(this.clientStatusFlags)) {
      data["clientStatusFlags"] = [];
      for (let item of this.clientStatusFlags)
        data["clientStatusFlags"].push(item);
    }
    data["isEducation"] = this.isEducation;
    if (Array.isArray(this.educationTags)) {
      data["educationTags"] = [];
      for (let item of this.educationTags)
        data["educationTags"].push(item);
    }
    if (Array.isArray(this.educationStatusFlags)) {
      data["educationStatusFlags"] = [];
      for (let item of this.educationStatusFlags)
        data["educationStatusFlags"].push(item);
    }
    data["institutionType"] = this.institutionType;
    data["onboardingPercentFrom"] = this.onboardingPercentFrom;
    data["onboardingPercentTo"] = this.onboardingPercentTo;
    if (Array.isArray(this.state)) {
      data["state"] = [];
      for (let item of this.state)
        data["state"].push(item);
    }
    data["countryCode"] = this.countryCode;
    data["companyType"] = this.companyType;
    data["contractYearExpiresFrom"] = this.contractYearExpiresFrom;
    data["contractYearExpiresTo"] = this.contractYearExpiresTo;
    data["isNoneType"] = this.isNoneType;
    data["notArchived"] = this.notArchived;
    data["includeArchived"] = this.includeArchived;
    data["onlyArchived"] = this.onlyArchived;
    return data;
  }
}

export interface ICompanySearchParams {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  isPartner?: boolean | undefined;
  partnerTags?: string[] | undefined;
  partnerStatusFlags?: string[] | undefined;
  partnerType?: string | undefined;
  isClient?: boolean | undefined;
  clientTags?: string[] | undefined;
  clientStatusFlags?: string[] | undefined;
  isEducation?: boolean | undefined;
  educationTags?: string[] | undefined;
  educationStatusFlags?: string[] | undefined;
  institutionType?: string | undefined;
  onboardingPercentFrom?: number | undefined;
  onboardingPercentTo?: number | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  companyType?: string | undefined;
  contractYearExpiresFrom?: number | undefined;
  contractYearExpiresTo?: number | undefined;
  isNoneType?: boolean | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
}