export interface SettingsPaths {
  StudentSettings: string,
  StudentOnboardingSettingsCreate: string,
  StudentOnboardingSettingsEdit: string,
  StudentPoolSettingsCreate: string,
  StudentPoolSettingsEdit: string,
  StudentPlacementSettingsCreate: string,
  StudentPlacementSettingsEdit: string,
  StudentGraduatingSettingsCreate: string,
  StudentGraduatingSettingsEdit: string,
  EmployerSettings: string,
  EmployerOnboardingSettingsCreate: string,
  EmployerOnboardingSettingsEdit: string,
  EducationSettings: string,
  EducationOnboardingSettingsCreate: string,
  EducationOnboardingSettingsEdit: string,
  WorkShadowSettings: string,
  WorkShadowOnboardingSettingsCreate: string,
  WorkShadowOnboardingSettingsEdit: string,
  WorkShadowPoolSettingsCreate: string,
  WorkShadowPoolSettingsEdit: string,
  WorkShadowPlacementSettingsCreate: string,
  WorkShadowPlacementSettingsEdit: string,
  HighSchoolProgramParticipantSettingsCreate: string,
  HighSchoolProgramParticipantSettingsEdit: string,
  TagDefinitionSettings: string,
  AlumniSettings: string,
  AlumniEarlyProfSettingsCreate: string,
  AlumniEarlyProfSettingsEdit: string,
}

export const settingsPaths: SettingsPaths = {
  StudentSettings: '/advisor/settings/student/list',
  StudentOnboardingSettingsCreate: '/advisor/settings/student/onboarding/add',
  StudentOnboardingSettingsEdit: '/advisor/settings/student/onboarding/edit/:id',
  StudentPoolSettingsCreate: '/advisor/settings/student/pool/add',
  StudentPoolSettingsEdit: '/advisor/settings/student/pool/edit/:id',
  StudentPlacementSettingsCreate: '/advisor/settings/student/placement/add',
  StudentPlacementSettingsEdit: '/advisor/settings/student/placement/edit/:id',
  StudentGraduatingSettingsCreate: '/advisor/settings/student/graduating/add',
  StudentGraduatingSettingsEdit: '/advisor/settings/student/graduating/edit/:id',
  EmployerSettings: '/advisor/settings/employer/list',
  EmployerOnboardingSettingsCreate: '/advisor/settings/employer/onboarding/add',
  EmployerOnboardingSettingsEdit: '/advisor/settings/employer/onboarding/edit/:id',
  EducationSettings: '/advisor/settings/education/list',
  EducationOnboardingSettingsCreate: '/advisor/settings/education/onboarding/add',
  EducationOnboardingSettingsEdit: '/advisor/settings/education/onboarding/edit/:id',
  WorkShadowSettings: '/advisor/settings/hsp/list',
  WorkShadowOnboardingSettingsCreate: '/advisor/settings/hsp/onboarding/add',
  WorkShadowOnboardingSettingsEdit: '/advisor/settings/hsp/onboarding/edit/:id',
  WorkShadowPoolSettingsCreate: '/advisor/settings/hsp/pool/add',
  WorkShadowPoolSettingsEdit: '/advisor/settings/hsp/pool/edit/:id',
  WorkShadowPlacementSettingsCreate: '/advisor/settings/hsp/placement/add',
  WorkShadowPlacementSettingsEdit: '/advisor/settings/hsp/placement/edit/:id',
  HighSchoolProgramParticipantSettingsCreate: '/advisor/settings/highschoolprogram/participant/add',
  HighSchoolProgramParticipantSettingsEdit: '/advisor/settings/highschoolprogram/participant/edit/:id',
  TagDefinitionSettings: '/advisor/settings/tags',
  AlumniSettings: '/advisor/settings/alumni/list',
  AlumniEarlyProfSettingsCreate: '/advisor/settings/alumni/early/add',
  AlumniEarlyProfSettingsEdit: '/advisor/settings/alumni/early/edit/:id',
};
