export class VariableModel implements IVariableModel {
  fieldName?: string | undefined;
  fieldLabel?: string | undefined;
  availability?: string[] | undefined;
  sample?: string | undefined;

  constructor(data?: IVariableModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.fieldName = Data["fieldName"];
      this.fieldLabel = Data["fieldLabel"];
      if (Array.isArray(Data["availability"])) {
        this.availability = [] as any;
        for (let item of Data["availability"])
          this.availability!.push(item);
      }
      this.sample = Data["sample"];
    }
  }

  static fromJS(data: any): VariableModel {
    data = typeof data === 'object' ? data : {};
    let result = new VariableModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["fieldName"] = this.fieldName;
    data["fieldLabel"] = this.fieldLabel;
    if (Array.isArray(this.availability)) {
      data["availability"] = [];
      for (let item of this.availability)
        data["availability"].push(item);
    }
    data["sample"] = this.sample;
    return data;
  }
}

export interface IVariableModel {
  fieldName?: string | undefined;
  fieldLabel?: string | undefined;
  availability?: string[] | undefined;
  sample?: string | undefined;
}