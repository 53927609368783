import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {HSP_PLACEMENT_CACHE} from './Actions';
import {HSPPlacementDetailModel} from '../../types/models/HSPPlacementDetailModel';
import {HSPPlacementListItem} from '../../types/views/HSPPlacementListItem';
import {HSPPlacementIdList} from '../../types/requests/HSPPlacementIdList';

export interface IHSPPlacementsCacheState {
  hspPlacementCacheUpdating: boolean | false;
  hspPlacementCacheError: string | undefined;
  hspPlacementPendingIdList: string[];
  hspPlacementCache: HSPPlacementListItem[];
}

const initialState: IHSPPlacementsCacheState = {
  hspPlacementCacheUpdating: false,
  hspPlacementCacheError: undefined,
  hspPlacementPendingIdList: [],
  hspPlacementCache: [],
}

const hspPlacementsCacheReducer = createReducer(initialState, {
  [HSP_PLACEMENT_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.hspPlacementPendingIdList = [...new Set([...state.hspPlacementPendingIdList, ...action.payload])];
    state.hspPlacementCacheError = undefined;
  },
  [HSP_PLACEMENT_CACHE.UPDATE]: (state) => {
    state.hspPlacementPendingIdList = [];
    state.hspPlacementCacheUpdating = true;
    state.hspPlacementCacheError = undefined;
  },
  [HSP_PLACEMENT_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<HSPPlacementListItem[]>) => {
    let currentPlacementsCache = state.hspPlacementCache;
    action.payload.map(item => {
      if (!currentPlacementsCache.find(x => x.id === item.id)) {
        currentPlacementsCache.push(item);
      }
    });
    state.hspPlacementCache = currentPlacementsCache;
    state.hspPlacementCacheUpdating = false;
    state.hspPlacementCacheError = undefined;
  },
  [HSP_PLACEMENT_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.hspPlacementCache = state.hspPlacementCache.filter(x => x.id !== action.payload);
    state.hspPlacementCacheUpdating = false;
    state.hspPlacementCacheError = undefined;
  },
});

export default hspPlacementsCacheReducer;
