import {HSPOpportunityPoolDetailModel} from './HSPOpportunityPoolDetailModel';
import {HSPOpportunityStudentView} from '../models/HSPOpportunityStudentView';

export class HSPLocalOpportunitySearchItem implements IHSPLocalOpportunitySearchItem {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  startingPositions?: number | undefined;
  calculatedRemainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  tenByTenPositions?: number | undefined;
  remainingTenByTenPositions?: number | undefined;
  billablePositions?: number | undefined;
  remainingBillablePositions?: number | undefined;
  nonBillablePositions?: number | undefined;
  remainingNonBillablePositions?: number | undefined;
  contactPersonIds?: string[] | undefined;
  opportunityStudents?: HSPOpportunityStudentView[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  hspPools?: HSPOpportunityPoolDetailModel[] | undefined;

  constructor(data?: IHSPLocalOpportunitySearchItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyId = Data["companyId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.startingPositions = Data["startingPositions"];
      this.calculatedRemainingPositions = Data["calculatedRemainingPositions"];
      this.shortlistedStudentsCount = Data["shortlistedStudentsCount"];
      this.studentsMatched = Data["studentsMatched"];
      this.tenByTenPositions = Data["tenByTenPositions"];
      this.remainingTenByTenPositions = Data["remainingTenByTenPositions"];
      this.billablePositions = Data["billablePositions"];
      this.remainingBillablePositions = Data["remainingBillablePositions"];
      this.nonBillablePositions = Data["nonBillablePositions"];
      this.remainingNonBillablePositions = Data["remainingNonBillablePositions"];
      if (Array.isArray(Data["contactPersonIds"])) {
        this.contactPersonIds = [] as any;
        for (let item of Data["contactPersonIds"])
          this.contactPersonIds!.push(item);
      }
      if (Array.isArray(Data["opportunityStudents"])) {
        this.opportunityStudents = [] as any;
        for (let item of Data["opportunityStudents"])
          this.opportunityStudents!.push(HSPOpportunityStudentView.fromJS(item));
      }
      this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      if (Array.isArray(Data["hspPools"])) {
        this.hspPools = [] as any;
        for (let item of Data["hspPools"])
          this.hspPools!.push(HSPOpportunityPoolDetailModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): HSPLocalOpportunitySearchItem {
    data = typeof data === 'object' ? data : {};
    let result = new HSPLocalOpportunitySearchItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyId"] = this.companyId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["startingPositions"] = this.startingPositions;
    data["calculatedRemainingPositions"] = this.calculatedRemainingPositions;
    data["shortlistedStudentsCount"] = this.shortlistedStudentsCount;
    data["studentsMatched"] = this.studentsMatched;
    data["tenByTenPositions"] = this.tenByTenPositions;
    data["remainingTenByTenPositions"] = this.remainingTenByTenPositions;
    data["billablePositions"] = this.billablePositions;
    data["remainingBillablePositions"] = this.remainingBillablePositions;
    data["nonBillablePositions"] = this.nonBillablePositions;
    data["remainingNonBillablePositions"] = this.remainingNonBillablePositions;
    if (Array.isArray(this.contactPersonIds)) {
      data["contactPersonIds"] = [];
      for (let item of this.contactPersonIds)
        data["contactPersonIds"].push(item);
    }
    if (Array.isArray(this.opportunityStudents)) {
      data["opportunityStudents"] = [];
      for (let item of this.opportunityStudents)
        data["opportunityStudents"].push(item.toJSON());
    }
    data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    if (Array.isArray(this.hspPools)) {
      data["hspPools"] = [];
      for (let item of this.hspPools)
        data["hspPools"].push(item.toJSON());
    }
    return data;
  }
}

export interface IHSPLocalOpportunitySearchItem {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  startingPositions?: number | undefined;
  calculatedRemainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  tenByTenPositions?: number | undefined;
  remainingTenByTenPositions?: number | undefined;
  billablePositions?: number | undefined;
  remainingBillablePositions?: number | undefined;
  nonBillablePositions?: number | undefined;
  remainingNonBillablePositions?: number | undefined;
  contactPersonIds?: string[] | undefined;
  opportunityStudents?: HSPOpportunityStudentView[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  hspPools?: HSPOpportunityPoolDetailModel[] | undefined;
}