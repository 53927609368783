import {PhoneModel} from '../models/PhoneModel';
import {ElasticUserDetailModel} from './ElasticUserDetailModel';

export class ElasticUserPersonModel implements IElasticUserPersonModel {
  id?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  userDetail?: ElasticUserDetailModel | undefined;
  picture?: string | undefined;
  email?: string | undefined;
  /** Phone numbers for this student */
  phoneNumbers?: PhoneModel[] | undefined;
  jobTitle?: string | undefined;

  constructor(data?: IElasticUserPersonModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.firstName = Data["firstName"];
      this.lastName = Data["lastName"];
      this.userDetail = Data["userDetail"] ? ElasticUserDetailModel.fromJS(Data["userDetail"]) : <any>undefined;
      this.picture = Data["picture"];
      this.email = Data["email"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.jobTitle = Data["jobTitle"];
    }
  }

  static fromJS(data: any): ElasticUserPersonModel {
    data = typeof data === 'object' ? data : {};
    let result = new ElasticUserPersonModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["firstName"] = this.firstName;
    data["lastName"] = this.lastName;
    data["userDetail"] = this.userDetail ? this.userDetail.toJSON() : <any>undefined;
    data["picture"] = this.picture;
    data["email"] = this.email;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item.toJSON());
    }
    data["jobTitle"] = this.jobTitle;
    return data;
  }
}

export interface IElasticUserPersonModel {
  id?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  userDetail?: ElasticUserDetailModel | undefined;
  picture?: string | undefined;
  email?: string | undefined;
  /** Phone numbers for this student */
  phoneNumbers?: PhoneModel[] | undefined;
  jobTitle?: string | undefined;
}