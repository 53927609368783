export interface EmailTemplatePaths {
  EmailTemplateEdit: string,
  EmailTemplateCreate: string,
  EmailTemplateSettings: string,
}

export const emailTemplatePaths: EmailTemplatePaths = {
  EmailTemplateEdit: '/settings/email-template/edit/:id',
  EmailTemplateCreate: '/settings/email-template/add',
  EmailTemplateSettings: '/settings/email-template/manage',
};
