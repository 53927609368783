import {
  ICommonLoadActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {HSPPoolDetailModel} from '../../types/models/HSPPoolDetailModel';
import {HSPCompanyPoolSeasonModel} from '../../types/models/HSPCompanyPoolSeasonModel';

export interface IHSPPoolCompanySeasonsType extends ICommonLoadActionType {
  REFRESH: string;
}

export const HSP_COMPANY_POOL_SEASONS: IHSPPoolCompanySeasonsType = {
  ERROR: 'hspPoolCompanySeasonsSearch/error',
  LOAD: 'hspPoolCompanySeasonsSearch/search',
  LOADED: 'hspPoolCompanySeasonsSearch/searchSuccess',
  REFRESH: 'hspPoolCompanySeasonsSearch/refresh'
};

export const HSP_COMPANY_POOL_LIST = {
  LOAD: 'hspPoolCompanyList/load',
  LOADED: 'hspPoolCompanyList/loadSuccess',
  LOAD_ERROR: 'hspPoolCompanyList/loadError',
  REFRESH: 'hspPoolCompanyList/refresh',
};

export const hspPoolCompanySeasonsLoad = createAction<string>(HSP_COMPANY_POOL_SEASONS.LOAD);
export const hspPoolCompanySeasonsRefresh = createAction<string>(HSP_COMPANY_POOL_SEASONS.REFRESH);
export const hspPoolCompanySeasonsLoadSuccess = createAction<HSPCompanyPoolSeasonModel[]>(HSP_COMPANY_POOL_SEASONS.LOADED);
export const hspPoolCompanySeasonsLoadError = createAction<string>(HSP_COMPANY_POOL_SEASONS.ERROR);

export const hspPoolCompanyListLoad = createAction<string[]>(HSP_COMPANY_POOL_LIST.LOAD);
export const hspPoolCompanyListRefresh = createAction<string[]>(HSP_COMPANY_POOL_LIST.REFRESH);
export const hspPoolCompanyListLoadSuccess = createAction<HSPPoolDetailModel[]>(HSP_COMPANY_POOL_LIST.LOADED);
export const hspPoolCompanyListLoadError = createAction<string>(HSP_COMPANY_POOL_LIST.LOAD_ERROR);
