import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  BREAK_SETTINGS, BREAK_SETTINGS_LIST, breakSettingsListSearch,
  breakSettingsListSearchError,
  breakSettingsListSearchSuccess, breakSettingsLoadError, breakSettingsLoadSuccess,
  breakSettingsSaveError,
  breakSettingsSaveSuccess,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {ICommonSavePayload} from '../Common.actions';
import {BreakSettingsModel} from '../../types/requests/BreakSettingsModel';


function* LoadBreakSetting(id: string) {
  console.log('SAGA - settingsSaga - LoadBreakSetting');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/break/${id}`);
    const breakSetting = BreakSettingsModel.fromJS(res.data);
    yield put(breakSettingsLoadSuccess(breakSetting));
  } catch (error) {
    yield put(breakSettingsLoadError(error));
  }
}


function* SaveBreakSetting({ id, submission }: ICommonSavePayload<BreakSettingsModel>) {
  console.log('SAGA - settingsSaga - SaveBreakSetting');
  const breakSettingsPath = id ? `${apiConfig.coreSettingsPath}/break/${id}` : `${apiConfig.coreSettingsPath}/break`
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: breakSettingsPath,
      data: submission
    });
    const breakSetting = BreakSettingsModel.fromJS(res.data);
    yield put(breakSettingsSaveSuccess(breakSetting));
  } catch (error) {
    yield put(breakSettingsSaveError(error));
  }
}

function* DeleteBreakSetting(id: string) {
  console.log('SAGA - settingsSaga - DeleteBreakSetting');
  const breakSettingsPath = `${apiConfig.coreSettingsPath}/break/${id}`
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: breakSettingsPath,
    });
    yield put(breakSettingsListSearch());
  } catch (error) {
    yield put(breakSettingsSaveError(error));
  }
}



function* SearchBreakSettings(includeDisabled: boolean) {
  console.log('SAGA - settingsSaga - SearchBreakSettings');
  try {
    const inclusion = includeDisabled ? '?include_all=true' : '';
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreSettingsPath}/break${inclusion}`
    });
    const results: BreakSettingsModel[] = res.data.map((setting: BreakSettingsModel) => BreakSettingsModel.fromJS(setting));
    yield put(breakSettingsListSearchSuccess(results));
  } catch (error) {
    yield put(breakSettingsListSearchError(error));
  }
}

export function* watchBreakSettingsListRefresh() {
  yield takeLatest(BREAK_SETTINGS_LIST.REFRESH, ({ payload }: PayloadAction<boolean>) => SearchBreakSettings(payload));
}

export function* watchBreakSettingsListSearch() {
  yield takeLatest(BREAK_SETTINGS_LIST.LOAD, ({ payload }: PayloadAction<boolean>) => SearchBreakSettings(payload));
}

export function* watchBreakSettingSave() {
  yield takeLatest(BREAK_SETTINGS.SAVE, ({ payload }: PayloadAction<ICommonSavePayload<BreakSettingsModel>>) => SaveBreakSetting(payload));
}

export function* watchBreakSettingLoad() {
  yield takeLatest(BREAK_SETTINGS.LOAD, ({ payload }: PayloadAction<string>) => LoadBreakSetting(payload));
}

export function* watchBreakSettingDelete() {
  yield takeLatest(BREAK_SETTINGS.DELETE, ({ payload }: PayloadAction<string>) => DeleteBreakSetting(payload));
}



export default function* settingsSaga() {
  yield all ([
    call(watchBreakSettingsListRefresh),
    call(watchBreakSettingsListSearch),
    call(watchBreakSettingSave),
    call(watchBreakSettingLoad),
    call(watchBreakSettingDelete),
  ])
}
