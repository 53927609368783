import {PlacementSearchResultModel} from '../../types/views/PlacementSearchResultModel';
import {StudentPlacementModel} from '../../types/models/StudentPlacementModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {PLACEMENT, PLACEMENT_EVALUATIONS, PLACEMENT_LIST} from './Actions';
import {PlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {SeasonalActionModel} from '../../types/models/SeasonalActionModel';

export interface IPlacementState {
  placement: PlacementDetailModel | undefined;
  placementLoading: boolean;
  placementLoadError: string | undefined;
  placementSaving: boolean;
  placementSaveError: string | undefined;
  placementRefreshing: boolean;
  placementListLoading: boolean;
  placementList: PlacementDetailModel[] | [];
  placementListError: string | undefined;
  placementEvaluationsList: SeasonalActionModel[] | [];
  placementEvaluationsLoading: boolean;
  placementEvaluationsError: string | undefined;
}

const initialState: IPlacementState = {
  placement: undefined,
  placementLoading: false,
  placementLoadError: undefined,
  placementSaving: false,
  placementSaveError: undefined,
  placementRefreshing: false,
  placementListLoading: false,
  placementList: [],
  placementListError: undefined,
  placementEvaluationsList: [],
  placementEvaluationsLoading: false,
  placementEvaluationsError: undefined
}

const placementReducer = createReducer(initialState, {
  [PLACEMENT.LOAD]: (state) => {
    state.placementLoading = true;
    state.placementLoadError = undefined;
    state.placementSaveError = undefined;
    state.placementRefreshing = false;
    state.placement = undefined;
  },
  [PLACEMENT.LOADED]: (state, action: PayloadAction<StudentPlacementModel>) => {
    state.placementLoading = false;
    state.placementRefreshing = false;
    state.placementLoadError = undefined;
    state.placementSaveError = undefined;
    state.placement = action.payload;
  },
  [PLACEMENT.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.placementLoading = false;
    state.placementRefreshing = false;
    state.placementLoadError = action.payload;
    state.placementSaveError = undefined;
  },
  [PLACEMENT.REFRESH]: (state) => {
    state.placementLoading = true;
    state.placementRefreshing = true;
    state.placementSaveError = undefined;
    state.placementLoadError = undefined;
  },
  [PLACEMENT.SAVE]: (state) => {
    state.placementSaving = true;
    state.placementSaveError = undefined;
  },
  [PLACEMENT.SAVED]: (state, action: PayloadAction<StudentPlacementModel>) => {
    state.placementSaving = false;
    state.placementSaveError = undefined;
    state.placement = action.payload;
  },
  [PLACEMENT.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.placementSaving = false;
    state.placementSaveError = action.payload;
  },
  [PLACEMENT_LIST.LOAD]: (state) => {
    state.placementListLoading = true;
    state.placementListError = undefined;
    state.placementList = [];
  },
  [PLACEMENT_LIST.LOADED]: (state, action: PayloadAction<PlacementDetailModel[]>) => {
    state.placementListLoading = false;
    state.placementListError = undefined;
    state.placementList = action.payload;
  },
  [PLACEMENT_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.placementListLoading = false;
    state.placementListError = action.payload;
  },
  [PLACEMENT_EVALUATIONS.LOAD]: (state) => {
    state.placementEvaluationsLoading = true;
    state.placementEvaluationsError = undefined;
    state.placementEvaluationsList = [];
  },
  [PLACEMENT_EVALUATIONS.LOADED]: (state, action: PayloadAction<PlacementDetailModel[]>) => {
    state.placementEvaluationsLoading = false;
    state.placementEvaluationsError = undefined;
    state.placementEvaluationsList = action.payload;
  },
  [PLACEMENT_EVALUATIONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.placementEvaluationsLoading = false;
    state.placementEvaluationsError = action.payload;
  },
});


export default placementReducer;