import {all, call, put, takeLatest} from 'redux-saga/effects';
import {
  COMPANY_POOL_LIST,
  COMPANY_POOL_SEASONS,
  poolCompanyListLoadError, poolCompanyListLoadSuccess,
  poolCompanySeasonsLoadError, poolCompanySeasonsLoadSuccess,
} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PoolDetailModel} from '../../types/models/PoolDetailModel';
import {SeasonModel} from '../../types/models/SeasonModel';
import {CompanyPoolSeasonModel} from '../../types/models/CompanyPoolSeasonModel';

function* CompanyPoolSeasonsLoad(companyId: string) {
  console.log('SAGA - poolSaga - CompanyPoolSeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreCompaniesPath}/${companyId}/pool-seasons`,
    });
    const result: SeasonModel[] = res.data.map((x: any) => CompanyPoolSeasonModel.fromJS(x));
    yield put(poolCompanySeasonsLoadSuccess(result));
  } catch (err) {
    yield put(poolCompanySeasonsLoadError(err));
  }
}

function* CompanyPoolsList(companyIds: string[]) {
  console.log('SAGA - poolSaga - CompanyPoolListLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url:`${apiConfig.corePoolsPath}/list-by-company`,
      data: { id_list: companyIds }
    });
    const result: PoolDetailModel[] = res.data.map((x: any) => PoolDetailModel.fromJS(x));
    yield put(poolCompanyListLoadSuccess(result));
  } catch (err) {
    yield put(poolCompanyListLoadError(err));
  }
}

export function* watchPoolCompanySeasonsLoad() {
  yield takeLatest(COMPANY_POOL_SEASONS.LOAD, ({payload}: PayloadAction<string>) => CompanyPoolSeasonsLoad(payload))
}

export function* watchPoolCompanySeasonsRefresh() {
  yield takeLatest(COMPANY_POOL_SEASONS.REFRESH, ({payload}: PayloadAction<string>) => CompanyPoolSeasonsLoad(payload))
}

export function* watchCompanyPoolListLoad() {
  yield takeLatest(COMPANY_POOL_LIST.LOAD, ({payload}: PayloadAction<string[]>) => CompanyPoolsList(payload))
}

export function* watchCompanyPoolListRefresh() {
  yield takeLatest(COMPANY_POOL_LIST.REFRESH, ({payload}: PayloadAction<string[]>) => CompanyPoolsList(payload))
}

export default function* poolSaga() {
  yield all ([
    call(watchPoolCompanySeasonsLoad),
    call(watchPoolCompanySeasonsRefresh),
    call(watchCompanyPoolListLoad),
    call(watchCompanyPoolListRefresh),
  ])
}
