export class HSPPlacementListItem implements IHSPPlacementListItem {
  id?: string | undefined;
  personId?: string | undefined;
  companyId?: string | undefined;
  poolId?: string | undefined;
  seasonNumber?: string | undefined;
  seasonYear?: string | undefined;
  title?: string | undefined;
  personFirstName?: string | undefined;
  personLastName?: string | undefined;

  constructor(data?: IHSPPlacementListItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.personId = Data["personId"];
      this.companyId = Data["companyId"];
      this.poolId = Data["poolId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.title = Data["title"];
      this.personFirstName = Data["personFirstName"];
      this.personLastName = Data["personLastName"];
    }
  }

  static fromJS(data: any): HSPPlacementListItem {
    data = typeof data === 'object' ? data : {};
    let result = new HSPPlacementListItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["personId"] = this.personId;
    data["companyId"] = this.companyId;
    data["poolId"] = this.poolId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["title"] = this.title;
    data["personFirstName"] = this.personFirstName;
    data["personLastName"] = this.personLastName;
    return data;
  }
}

export interface IHSPPlacementListItem {
  id?: string | undefined;
  personId?: string | undefined;
  companyId?: string | undefined;
  poolId?: string | undefined;
  seasonNumber?: string | undefined;
  seasonYear?: string | undefined;
  title?: string | undefined;
  personFirstName?: string | undefined;
  personLastName?: string | undefined;
}