import {AppState} from '../store';
import {hasItems} from '../../@template/helpers/arrays';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PoolSuggestModel} from '../../types/views/PoolSuggestModel';
import {POOL_CACHE, poolCacheError, poolCacheMerge, poolCacheUpdate} from './Actions';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IPoolsCacheState} from './Reducer';
import {PayloadAction} from '@reduxjs/toolkit';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const poolCacheState = (state: AppState) => state.poolsCache;

export function* LoadPoolCacheItems(idList: string[]) {
  // console.log('SAGA - poolCacheSaga - LoadPoolCacheItems', idList);
  try {
    if (hasItems(idList)) {
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.corePoolsPath}/list`,
        data: { id_list: idList }
      });
      const people: PoolSuggestModel[] = res.data.map((x: any) => PoolSuggestModel.fromJS(x));
      yield put(poolCacheMerge(people));
    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(poolCacheError(err));
  }
}

export function* AddPoolCacheItems() {
  // console.log('SAGA - peopleCacheSaga - AddPoolCacheItems');
  yield call(delay, 200);
  const currentPoolCache: IPoolsCacheState = yield select(poolCacheState);
  const currentPendingList = currentPoolCache.poolPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    // console.log('SAGA - peopleCacheSaga - AddPoolCacheItems Load', currentPendingList);
    yield put(poolCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadPoolCacheItems(){
  yield takeEvery(POOL_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadPoolCacheItems(payload));
}

export function* watchAddPoolCacheItems(){
  yield takeEvery(POOL_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddPoolCacheItems());
}

export default function* poolCacheSaga() {
  yield all([
    call(watchLoadPoolCacheItems),
    call(watchAddPoolCacheItems),
  ])
}