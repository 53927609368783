import {AddressSearch} from './AddressSearch';
import {TimelineStepIndexModel} from './TimelineStepIndexModel';

export class AlumniSearchModel implements IAlumniSearchModel {
  earlyProfessionalAlumniSettingId?: string | undefined;
  earlyProfessionalStepsComplete?: number | undefined;
  earlyProfessionalPercent?: number | undefined;
  earlyProfessionalSteps?: TimelineStepIndexModel[] | undefined;
  earlyProfessionalStepIds?: string[] | undefined;
  alumniStatus?: string | undefined;
  alumniTags?: string[] | undefined;
  locationAddress?: AddressSearch | undefined;
  quickNotes?: string | undefined;
  interestsSkills?: string | undefined;
  postGradStudy?: string | undefined;
  graduatedYear?: number | undefined;
  graduatedSemester?: number | undefined;
  degreePoolId?: string[] | undefined;
  degreePoolFirst?: string | undefined;

  constructor(data?: IAlumniSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.earlyProfessionalAlumniSettingId = Data["earlyProfessionalAlumniSettingId"];
      this.earlyProfessionalStepsComplete = Data["earlyProfessionalStepsComplete"];
      this.earlyProfessionalPercent = Data["earlyProfessionalPercent"];
      if (Array.isArray(Data["earlyProfessionalSteps"])) {
        this.earlyProfessionalSteps = [] as any;
        for (let item of Data["earlyProfessionalSteps"])
          this.earlyProfessionalSteps!.push(TimelineStepIndexModel.fromJS(item));
      }
      if (Array.isArray(Data["earlyProfessionalStepIds"])) {
        this.earlyProfessionalStepIds = [] as any;
        for (let item of Data["earlyProfessionalStepIds"])
          this.earlyProfessionalStepIds!.push(item);
      }
      this.alumniStatus = Data["alumniStatus"];
      if (Array.isArray(Data["alumniTags"])) {
        this.alumniTags = [] as any;
        for (let item of Data["alumniTags"])
          this.alumniTags!.push(item);
      }
      this.locationAddress = Data["locationAddress"] ? AddressSearch.fromJS(Data["locationAddress"]) : <any>undefined;
      this.quickNotes = Data["quickNotes"];
      this.interestsSkills = Data["interestsSkills"];
      this.postGradStudy = Data["postGradStudy"];
      this.graduatedYear = Data["graduatedYear"];
      this.graduatedSemester = Data["graduatedSemester"];
      if (Array.isArray(Data["degreePoolId"])) {
        this.degreePoolId = [] as any;
        for (let item of Data["degreePoolId"])
          this.degreePoolId!.push(item);
      }
      this.degreePoolFirst = Data["degreePoolFirst"];
    }
  }

  static fromJS(data: any): AlumniSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new AlumniSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["earlyProfessionalAlumniSettingId"] = this.earlyProfessionalAlumniSettingId;
    data["earlyProfessionalStepsComplete"] = this.earlyProfessionalStepsComplete;
    data["earlyProfessionalPercent"] = this.earlyProfessionalPercent;
    if (Array.isArray(this.earlyProfessionalSteps)) {
      data["earlyProfessionalSteps"] = [];
      for (let item of this.earlyProfessionalSteps)
        data["earlyProfessionalSteps"].push(item.toJSON());
    }
    if (Array.isArray(this.earlyProfessionalStepIds)) {
      data["earlyProfessionalStepIds"] = [];
      for (let item of this.earlyProfessionalStepIds)
        data["earlyProfessionalStepIds"].push(item);
    }
    data["alumniStatus"] = this.alumniStatus;
    if (Array.isArray(this.alumniTags)) {
      data["alumniTags"] = [];
      for (let item of this.alumniTags)
        data["alumniTags"].push(item);
    }
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["quickNotes"] = this.quickNotes;
    data["interestsSkills"] = this.interestsSkills;
    data["postGradStudy"] = this.postGradStudy;
    data["graduatedYear"] = this.graduatedYear;
    data["graduatedSemester"] = this.graduatedSemester;
    if (Array.isArray(this.degreePoolId)) {
      data["degreePoolId"] = [];
      for (let item of this.degreePoolId)
        data["degreePoolId"].push(item);
    }
    data["degreePoolFirst"] = this.degreePoolFirst;
    return data;
  }
}

export interface IAlumniSearchModel {
  earlyProfessionalAlumniSettingId?: string | undefined;
  earlyProfessionalStepsComplete?: number | undefined;
  earlyProfessionalPercent?: number | undefined;
  earlyProfessionalSteps?: TimelineStepIndexModel[] | undefined;
  earlyProfessionalStepIds?: string[] | undefined;
  alumniStatus?: string | undefined;
  alumniTags?: string[] | undefined;
  locationAddress?: AddressSearch | undefined;
  quickNotes?: string | undefined;
  interestsSkills?: string | undefined;
  postGradStudy?: string | undefined;
  graduatedYear?: number | undefined;
  graduatedSemester?: number | undefined;
  degreePoolId?: string[] | undefined;
  degreePoolFirst?: string | undefined;
}