export class HSPOpportunitySearchModel implements IHSPOpportunitySearchModel {
  companyId?: string | undefined;
  dateCreated?: string | undefined;
  hspOpportunityStudentStatus?: string | undefined;
  hspOpportunityStudentStatusIndex?: number | undefined;
  hspOpportunityId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;

  constructor(data?: IHSPOpportunitySearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.companyId = Data["companyId"];
      this.dateCreated = Data["dateCreated"];
      this.hspOpportunityStudentStatus = Data["hspOpportunityStudentStatus"];
      this.hspOpportunityStudentStatusIndex = Data["hspOpportunityStudentStatusIndex"];
      this.hspOpportunityId = Data["hspOpportunityId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
    }
  }

  static fromJS(data: any): HSPOpportunitySearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPOpportunitySearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["companyId"] = this.companyId;
    data["dateCreated"] = this.dateCreated;
    data["hspOpportunityStudentStatus"] = this.hspOpportunityStudentStatus;
    data["hspOpportunityStudentStatusIndex"] = this.hspOpportunityStudentStatusIndex;
    data["hspOpportunityId"] = this.hspOpportunityId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    return data;
  }
}

export interface IHSPOpportunitySearchModel {
  companyId?: string | undefined;
  dateCreated?: string | undefined;
  hspOpportunityStudentStatus?: string | undefined;
  hspOpportunityStudentStatusIndex?: number | undefined;
  hspOpportunityId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
}