import {useFormContext, Controller, FieldValues, get} from 'react-hook-form';
import React, {ChangeEvent, SyntheticEvent, useCallback, useEffect, useState} from 'react';
import {MenuItem, TextField, Autocomplete } from '@mui/material';
import {isFieldRequired, useValidationContext} from '../../validation/ValidationContext';
import {isFieldInObject} from '../../helpers/json';
import {changeFieldOverride} from '../../utility/FormLayout/layoutConfig';
import {InactiveOptions} from '../../helpers/careerTrackers';


const FormAutoComplete = <T extends FieldValues>(props: T) => {
  const { schema, changeRequest } = useValidationContext();
  const { control, setValue, formState: { errors } } = useFormContext();
  const { name, label, data = [], labelField = 'title', menuLabelField = undefined, valueField = 'id', defaultSelectedItem = undefined, required = false, fullWidth = true, groupBy = undefined, onBlur = undefined } = props;

  const isRequired = required ? true : schema ? isFieldRequired(schema, name, required) : false;

  let isError = false;
  let errorMessage = '';

  if (errors) {
    errorMessage = get(errors, name)?.message;
    if (errorMessage)
      isError = true;
  }

  const styleForChangeRequest = () => {
    if (changeRequest && isFieldInObject(changeRequest, name)) {
      return changeFieldOverride;
    }
    return undefined;
  }

  const handleChanged = useCallback(
    (e: SyntheticEvent<Element, Event>, selectedVal:  string | undefined) => {
      if (!selectedVal) {
        setValue(name, null);
      }
      else if (selectedVal.hasOwnProperty(valueField)) {
        setValue(name, selectedVal[valueField]);
      } else {
        setValue(name, selectedVal);
      }
      if(onBlur) onBlur();
    },
    [setValue, data]
  );

  const resolveValue = (value: any | null | undefined) => {
    if (!value) return null;
    if (typeof value === 'string') {
      if (data) {
        let matching = data.find((x: any) => x.hasOwnProperty(valueField) && x[valueField] === value);
        if (matching) return matching;
      }
      if (defaultSelectedItem && defaultSelectedItem[valueField] === value) {
        return defaultSelectedItem;
      }
      let loadingOptions: any = {};
      loadingOptions[valueField] = value;
      loadingOptions[labelField] = data ? '' : 'Loading...';
      return loadingOptions;
    }
    return value;
  }

  const isOptionEqualToValue = (option: any, value: any) => {
    return option && value
      && option.hasOwnProperty(valueField)
      && value.hasOwnProperty(valueField)
      && option[valueField] === value[valueField];
  }


  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...rest } }) => (
        <Autocomplete
          {...rest}
          onChange={handleChanged}
          options={data}
          autoComplete
          getOptionLabel={(option: any) => option ? option[labelField] : ''}
          renderOption={(props, option: any) => (<MenuItem {...props}>{option[menuLabelField?menuLabelField:labelField]}</MenuItem>)}
          isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
          value={resolveValue(value)}
          groupBy={groupBy}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={styleForChangeRequest()}
              fullWidth={fullWidth}
              label={label}
              variant="outlined"
              required={isRequired}
              error={isError}
              helperText={errorMessage}
            />
          )}
        />
      )}
    />
  )
}

export default FormAutoComplete;