import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';
import {ContentView} from '../../index';
import useStyles from '../../../shared/jss/common/common.style';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {selectCurrentUser} from '../../../redux/auth/Selectors';
import {matchRoutes} from 'react-router-config';
import {useHistory, useLocation} from 'react-router-dom';
import {useAppDispatch} from '../../utility/AppHooks';

const useStyle = makeStyles(() => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

interface CareerTrackersLayoutProps {}

const CareerTrackersLayout: React.FC<CareerTrackersLayoutProps> = () => {
  useStyles();
  const {navStyle} = useContext<AppContextPropsType>(AppContext);
  const user = useSelector(selectCurrentUser);
  const AppLayout = Layouts[navStyle];
  const {pathname} = useLocation();
  const {
    routes,
  } = useContext<AppContextPropsType>(AppContext);
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  const classes = useStyle();
  return (
    <>
      {!!currentRoute.auth ? (
        <AppLayout />
      ) : (
        <Box className={classes.appAuth}>
          <ContentView />
        </Box>
      )}
    </>
  );
};

export default React.memo(CareerTrackersLayout);
