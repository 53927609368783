import {IPagedResult} from '../common/PagedResult';
import {Paging} from '../common/Paging';
import {OpportunitiesDetailModel} from './OpportunitiesDetailModel';

export interface IOpportunitiesSearchPaged extends IPagedResult<OpportunitiesDetailModel> {
  totalOpportunities?: number | undefined;
}

export class OpportunitiesSearchPaged implements IOpportunitiesSearchPaged {
  totalOpportunities?: number | undefined;
  paging?: Paging | undefined;
  result?: OpportunitiesDetailModel[] | undefined;

  constructor(data?: IOpportunitiesSearchPaged) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.totalOpportunities = Data["totalOpportunities"];
      this.paging = Data["paging"] ? Paging.fromJS(Data["paging"]) : <any>undefined;
      if (Array.isArray(Data["result"])) {
        this.result = [] as any;
        for (let item of Data["result"])
          this.result!.push(OpportunitiesDetailModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): OpportunitiesSearchPaged {
    data = typeof data === 'object' ? data : {};
    let result = new OpportunitiesSearchPaged();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["totalOpportunities"] = this.totalOpportunities;
    data["paging"] = this.paging ? this.paging.toJSON() : <any>undefined;
    if (Array.isArray(this.result)) {
      data["result"] = [];
      for (let item of this.result)
        data["result"].push(item.toJSON());
    }
    return data;
  }
}

export interface IOpportunitiesSearchPaged {
  totalOpportunities?: number | undefined;
  paging?: Paging | undefined;
  result?: OpportunitiesDetailModel[] | undefined;
}