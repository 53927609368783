import React from 'react';
import {create} from 'jss';
import {jssPreset, StylesProvider} from '@mui/styles';
import initialiseYup from '../../validation';
// Configure JSS
const jss = create({plugins: [...jssPreset().plugins]});

const CareerTrackersStyleProvider: React.FC<React.ReactNode> = props => {
  initialiseYup();
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
};
export default CareerTrackersStyleProvider;
