import {createAction} from '@reduxjs/toolkit';

interface IJobProgressActionType {
  SAVE: string;
  SAVE_FILE: string;
  CLEAR: string;
  ERROR: string;
  SUCCESS: string;
}


export const JOB_PROGRESS: IJobProgressActionType = {
  CLEAR: 'jobProgress/clear',
  ERROR: 'jobProgress/error',
  SAVE: 'jobProgress/save',
  SAVE_FILE: 'jobProgress/saveFile',
  SUCCESS: 'jobProgress/success',
};

export const jobProgressSave = createAction<string>(JOB_PROGRESS.SAVE);
export const jobProgressFileSave = createAction<string>(JOB_PROGRESS.SAVE_FILE);
export const jobProgressClear = createAction(JOB_PROGRESS.CLEAR);
export const jobProgressSuccess = createAction<string[]>(JOB_PROGRESS.SUCCESS);
export const jobProgressError = createAction<string>(JOB_PROGRESS.ERROR);
