export class HistoryRetrievedModel implements IHistoryRetrievedModel {
  historyId?: string | undefined;
  pageType?: string | undefined;
  entityId?: string | undefined;
  dateLastVisited?: string | undefined;
  dateFirstVisited?: string | undefined;
  title?: string | undefined;
  subTitle?: string | undefined;
  count?: string | undefined;

  constructor(data?: IHistoryRetrievedModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.historyId = Data["historyId"];
      this.pageType = Data["pageType"];
      this.entityId = Data["entityId"];
      this.dateLastVisited = Data["dateLastVisited"];
      this.dateFirstVisited = Data["dateFirstVisited"];
      this.title = Data["title"];
      this.subTitle = Data["subTitle"];
      this.count = Data["count"];
    }
  }

  static fromJS(data: any): HistoryRetrievedModel {
    data = typeof data === 'object' ? data : {};
    let result = new HistoryRetrievedModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["historyId"] = this.historyId;
    data["pageType"] = this.pageType;
    data["entityId"] = this.entityId;
    data["dateLastVisited"] = this.dateLastVisited;
    data["dateFirstVisited"] = this.dateFirstVisited;
    data["title"] = this.title;
    data["subTitle"] = this.subTitle;
    data["count"] = this.count;
    return data;
  }
}

export interface IHistoryRetrievedModel {
  historyId?: string | undefined;
  pageType?: string | undefined;
  entityId?: string | undefined;
  dateLastVisited?: string | undefined;
  dateFirstVisited?: string | undefined;
  title?: string | undefined;
  subTitle?: string | undefined;
  count?: string | undefined;
}