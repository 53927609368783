import {camelCase, camelCaseTransformMerge, snakeCase} from 'change-case';
import {snakeKeysRE} from './regexPatterns';
import map from 'map-obj';
import {isNumber} from 'chart.js/helpers';

export const camelKeys: any = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key, { transform: camelCaseTransformMerge })]: camelKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

// TODO: Convert to TS safe approach
// @ts-ignore
export const snakeCaseKeys = (obj) => {
  const options = { deep: true, exclude: [] };

  // @ts-ignore
  return map(obj, function (key, val) {
    // @ts-ignore
    return [matches(options.exclude, key) ? key : snakeCase(key, {splitRegexp: snakeKeysRE}), val]
  }, options)
}

// @ts-ignore
function matches (patterns, value) {
  // @ts-ignore
  return patterns.some(function (pattern) {
    return typeof pattern === 'string'
      ? pattern === value
      : pattern.test(value)
  })
}

export const stripObjNull = (obj: any) => {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] && typeof obj[key] === 'object') stripObjNull(obj[key]);
    else if (obj[key] === null || obj[key] === undefined) delete obj[key];
  });
  return obj;
};

export const objHasKey = (obj: any, key: string) => {
  if (!obj) return false;
  if (Array.isArray(obj)) {
    if (!isNumber(key)) return false;
    return obj.length > parseInt(key);
  }
  if (typeof obj === "object") {
    return obj.hasOwnProperty(key);
  }
  return false;
}

export const isFieldInObject = (obj: any, key: string): boolean => {
  if (!key === undefined) return false;
  const keyArr = key.split('.');
  let hasKey = false;
  let testObj = obj;
  keyArr.forEach(k => {
    if (objHasKey(testObj, k)) {
      if (isNumber(k)) {
        testObj = testObj[parseInt(k)];
        hasKey = true;
      } else {
        testObj = testObj[k];
        hasKey = true;
      }
    } else {
      hasKey = false;
    }
  });
  return hasKey;
};

