import {defaultVolunteerSearchParamsModel, VolunteerSearchParamsModel} from '../../types/requests/VolunteerSearchParamsModel';
import {VolunteerPersonModel} from '../../types/views/VolunteerPersonModel';
import {VolunteerEditDetailModel} from '../../types/requests/VolunteerEditDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {VOLUNTEER, VOLUNTEER_SEARCH} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {SearchView} from '../../types/common/SearchView';
import {PersonModel} from '../../types/models/PersonModel';

export interface IVolunteerState {
  volunteerSearchParams: VolunteerSearchParamsModel;
  volunteerSearchView: string | undefined;
  volunteerSearchSort: string | undefined;
  volunteerResultLoading: boolean;
  volunteerResultError: string | undefined;
  volunteerResult: PersonSearchResultPaged | undefined;
  volunteerLoading: boolean;
  volunteerRefreshing: boolean;
  volunteerSaving: boolean;
  volunteerSaveError: string | undefined;
  volunteerError: string | undefined;
  volunteerEditDetail: VolunteerEditDetailModel | undefined;
  volunteerDetail: PersonModel | undefined;
}

const initialState: IVolunteerState = {
  volunteerSearchParams: defaultVolunteerSearchParamsModel(),
  volunteerSearchView: SearchView.Default,
  volunteerSearchSort: 'Relevance',
  volunteerResultLoading: false,
  volunteerResultError: undefined,
  volunteerResult: undefined,
  volunteerLoading: false,
  volunteerRefreshing: false,
  volunteerSaving: false,
  volunteerSaveError: undefined,
  volunteerError: undefined,
  volunteerEditDetail: undefined,
  volunteerDetail: undefined,
};

const volunteerReducer = createReducer(initialState, {
  [VOLUNTEER_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.volunteerSearchView = action.payload;
  },
  [VOLUNTEER_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<VolunteerSearchParamsModel>>) => {
    state.volunteerSearchParams = action.payload.searchParams;
    state.volunteerResultError = undefined;
    state.volunteerResultLoading = true;
  },
  [VOLUNTEER_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<VolunteerSearchParamsModel>>) => {
    state.volunteerSearchParams = action.payload.searchParams;
    state.volunteerResultError = undefined;
  },
  [VOLUNTEER_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.volunteerResultError = action.payload;
    state.volunteerResultLoading = false;
  },
  [VOLUNTEER_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.volunteerResult = action.payload;
    state.volunteerResultLoading = false;
    state.volunteerResultError = undefined;
  },
  [VOLUNTEER.LOAD]: (state) => {
    state.volunteerLoading = true;
    state.volunteerRefreshing = false;
    state.volunteerError = undefined;
    state.volunteerSaveError = undefined;
    state.volunteerDetail = undefined;
  },
  [VOLUNTEER.REFRESH]: (state) => {
    state.volunteerLoading = false;
    state.volunteerRefreshing = true;
    state.volunteerError = undefined;
    state.volunteerSaveError = undefined;
  },
  [VOLUNTEER.LOADED]: (state, action: PayloadAction<PersonModel>) => {
    state.volunteerLoading = false;
    state.volunteerRefreshing = false;
    state.volunteerError = undefined;
    state.volunteerSaveError = undefined;
    state.volunteerDetail = action.payload;
  },
  [VOLUNTEER.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.volunteerLoading = false;
    state.volunteerRefreshing = false;
    state.volunteerError = action.payload;
    state.volunteerSaveError = undefined;
  },
  [VOLUNTEER.SAVE]: (state) => {
    state.volunteerSaving = true;
    state.volunteerError = undefined;
    state.volunteerSaveError = undefined;
  },
  [VOLUNTEER.SAVED]: (state, action: PayloadAction<VolunteerPersonModel>) => {
    state.volunteerSaving = false;
    state.volunteerError = undefined;
    state.volunteerSaveError = undefined;
    state.volunteerDetail = action.payload;
  },
  [VOLUNTEER.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.volunteerSaving = false;
    state.volunteerError = undefined;
    state.volunteerSaveError = action.payload;
  },
});

export default volunteerReducer;