export interface TeamPaths {
  UserEdit: string,
  UserCreate: string,
  UserSearch: string,
  TeamManage: string,
}

export const teamPaths: TeamPaths = {
  UserEdit: '/advisor/team/users/edit/:id',
  UserCreate: '/advisor/team/users/add',
  UserSearch: '/advisor/team/users/search',
  TeamManage: '/advisor/team/manage',
};
