import {
  defaultEngagementInfoSearchParamsModel,
  EngagementInfoSearchParamsModel,
} from '../../types/requests/EngagementInfoSearchParamsModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {BULK_ADVISOR_OPERATION_SEARCH, ENGAGEMENT_SEARCH} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';

export interface IEngagementState {
  engagementSearchParams: EngagementInfoSearchParamsModel;
  engagementSearchView: string | undefined;
  engagementSearchSort: string | undefined;
  engagementResultLoading: boolean;
  engagementResultError: string | undefined;
  engagementResult: PersonSearchResultPaged | undefined;

  bulkUpdateSearchParams: EngagementInfoSearchParamsModel;
  bulkUpdateSearchView: string | undefined;
  bulkUpdateSearchSort: string | undefined;
  bulkUpdateResultLoading: boolean;
  bulkUpdateResultError: string | undefined;
  bulkUpdateResult: PersonSearchResultPaged | undefined;

}

const initialState: IEngagementState = {
  engagementSearchParams: defaultEngagementInfoSearchParamsModel(),
  engagementSearchView: 'default',
  engagementSearchSort: 'Relevance',
  engagementResultLoading: false,
  engagementResultError: undefined,
  engagementResult: undefined,

  bulkUpdateSearchParams: defaultEngagementInfoSearchParamsModel(),
  bulkUpdateSearchView: 'table',
  bulkUpdateSearchSort: 'Relevance',
  bulkUpdateResultLoading: false,
  bulkUpdateResultError: undefined,
  bulkUpdateResult: undefined,
}

const engagementReducer = createReducer(initialState, {
  [ENGAGEMENT_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.engagementSearchView = action.payload;
  },
  [ENGAGEMENT_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<EngagementInfoSearchParamsModel>>) => {
    state.engagementSearchParams = action.payload.searchParams;
    state.engagementResultLoading = true;
    state.engagementResultError = undefined;
  },
  [ENGAGEMENT_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<EngagementInfoSearchParamsModel>>) => {
    state.engagementSearchParams = action.payload.searchParams;
    state.engagementResultError = undefined;
  },
  [ENGAGEMENT_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.engagementResultLoading = false;
    state.engagementResultError = action.payload;
  },
  [ENGAGEMENT_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    console.log('action.payload', action.payload);
    state.engagementResultLoading = false;
    state.engagementResultError = undefined;
    state.engagementResult =  action.payload;
  },

  [BULK_ADVISOR_OPERATION_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.bulkUpdateSearchView = action.payload;
  },
  [BULK_ADVISOR_OPERATION_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<EngagementInfoSearchParamsModel>>) => {
    state.bulkUpdateSearchParams = action.payload.searchParams;
    state.bulkUpdateResultLoading = true;
    state.bulkUpdateResultError = undefined;
  },
  [BULK_ADVISOR_OPERATION_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<EngagementInfoSearchParamsModel>>) => {
    state.bulkUpdateSearchParams = action.payload.searchParams;
    state.bulkUpdateResultError = undefined;
  },
  [BULK_ADVISOR_OPERATION_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.bulkUpdateResultLoading = false;
    state.bulkUpdateResultError = action.payload;
  },
  [BULK_ADVISOR_OPERATION_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.bulkUpdateResultLoading = false;
    state.bulkUpdateResultError = undefined;
    state.bulkUpdateResult =  action.payload;
  }
});

export default engagementReducer;
