import {
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonSearchActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IPagedResult} from '../../types/common/PagedResult';
import {IScorecardParamsModel} from '../../types/requests/ScorecardParamsModel';
import {ISaveScorecardPresetsModel} from '../../types/requests/SaveScorecardPresetsModel';
import {IScorecardPresetModel} from '../../types/models/ScorecardPresetModel';
import {IPersonSearchResultModel} from '../../types/views/PersonSearchResultModel';
import {IRetrieveScorecardPresetsModel} from '../../types/requests/RetrieveScorecardPresetsModel';
import {STUDENT} from '../students/Actions';


export interface IScorecardPresetTypes extends ICommonSaveActionType {
  DELETE: string,
  REFRESH: string,
}

export interface IRecruitmentScorecardTypes extends ICommonSearchActionType {

}

export interface IEngagementScorecardTypes extends ICommonSearchActionType {

}

export interface IInternshipScorecardTypes extends ICommonSearchActionType {

}

export interface ICompanyInternshipScorecardTypes extends ICommonSearchActionType {

}

export const SCORECARD_PRESET: IScorecardPresetTypes = {
  LOAD: 'scorecardPreset/load',
  LOADED: 'scorecardPreset/loaded',
  LOAD_ERROR: 'scorecardPreset/loadError',
  SAVE: 'scorecardPreset/save',
  SAVED: 'scorecardPreset/saved',
  SAVE_ERROR: 'scorecardPreset/saveError',
  DELETE: 'scorecardPreset/delete',
  REFRESH: 'scorecardPreset/refresh',
}

export const RECRUITMENT_SCORECARD: IRecruitmentScorecardTypes = {
  LOAD: 'recruitmentScorecard/load',
  LOADED: 'recruitmentScorecard/loaded',
  ERROR: 'recruitmentScorecard/search',
  CHANGE_VIEW: 'recruitmentScorecard/changeView',
  REFRESH: 'recruitmentScorecard/refresh',
};

export const ENGAGEMENT_SCORECARD: IEngagementScorecardTypes = {
  LOAD: 'engagementScorecard/load',
  LOADED: 'engagementScorecard/success',
  ERROR: 'engagementScorecard/search',
  CHANGE_VIEW: 'engagementScorecard/changeView',
  REFRESH: 'engagementScorecard/refresh',
};

export const INTERNSHIP_SCORECARD: IInternshipScorecardTypes = {
  LOAD: 'internshipScorecard/load',
  LOADED: 'internshipScorecard/success',
  ERROR: 'internshipScorecard/search',
  CHANGE_VIEW: 'internshipScorecard/changeView',
  REFRESH: 'internshipScorecard/refresh',
};

export const COMPANY_INTERNSHIP_SCORECARD: ICompanyInternshipScorecardTypes = {
  LOAD: 'companyInternshipScorecard/load',
  LOADED: 'companyInternshipScorecard/success',
  ERROR: 'companyInternshipScorecard/search',
  CHANGE_VIEW: 'companyInternshipScorecard/changeView',
  REFRESH: 'companyInternshipScorecard/refresh',
};

export const HSP_ENGAGEMENT_SCORECARD: IEngagementScorecardTypes = {
  LOAD: 'hspEngagementScorecard/load',
  LOADED: 'hspEngagementScorecard/success',
  ERROR: 'hspEngagementScorecard/search',
  CHANGE_VIEW: 'hspEngagementScorecard/changeView',
  REFRESH: 'hspEngagementScorecard/refresh',
};

export const scorecardPresetSave = createAction<ISaveScorecardPresetsModel>(SCORECARD_PRESET.SAVE);
export const scorecardPresetSaveSuccess = createAction(SCORECARD_PRESET.SAVED);
export const scorecardPresetSaveError = createAction<string>(SCORECARD_PRESET.SAVE_ERROR);
export const scorecardPresetLoad = createAction<IRetrieveScorecardPresetsModel>(SCORECARD_PRESET.LOAD);
export const scorecardPresetLoadSuccess = createAction<IScorecardPresetModel[]>(SCORECARD_PRESET.LOADED);
export const scorecardPresetLoadError = createAction<string>(SCORECARD_PRESET.LOAD_ERROR);
export const scorecardPresetDelete = createAction<ISaveScorecardPresetsModel>(SCORECARD_PRESET.DELETE);
export const scorecardPresetRefresh = createAction(SCORECARD_PRESET.REFRESH);

export const recruitmentScorecardSearch = createAction<ISearchRequest<IScorecardParamsModel>>(RECRUITMENT_SCORECARD.LOAD);
export const recruitmentScorecardSearchView = createAction<string>(RECRUITMENT_SCORECARD.CHANGE_VIEW);
export const recruitmentScorecardSearchRefresh = createAction<ISearchRequest<IScorecardParamsModel>>(RECRUITMENT_SCORECARD.REFRESH);
export const recruitmentScorecardSearchError = createAction<string>(RECRUITMENT_SCORECARD.ERROR);
export const recruitmentScorecardSearchSuccess = createAction<IPagedResult<IPersonSearchResultModel>>(RECRUITMENT_SCORECARD.LOADED);

export const engagementScorecardSearch = createAction<ISearchRequest<IScorecardParamsModel>>(ENGAGEMENT_SCORECARD.LOAD);
export const engagementScorecardSearchView = createAction<string>(ENGAGEMENT_SCORECARD.CHANGE_VIEW);
export const engagementScorecardSearchRefresh = createAction<ISearchRequest<IScorecardParamsModel>>(ENGAGEMENT_SCORECARD.REFRESH);
export const engagementScorecardSearchError = createAction<string>(ENGAGEMENT_SCORECARD.ERROR);
export const engagementScorecardSearchSuccess = createAction<IPagedResult<IPersonSearchResultModel>>(ENGAGEMENT_SCORECARD.LOADED);

export const hspEngagementScorecardSearch = createAction<ISearchRequest<IScorecardParamsModel>>(HSP_ENGAGEMENT_SCORECARD.LOAD);
export const hspEngagementScorecardSearchView = createAction<string>(HSP_ENGAGEMENT_SCORECARD.CHANGE_VIEW);
export const hspEngagementScorecardSearchRefresh = createAction<ISearchRequest<IScorecardParamsModel>>(HSP_ENGAGEMENT_SCORECARD.REFRESH);
export const hspEngagementScorecardSearchError = createAction<string>(HSP_ENGAGEMENT_SCORECARD.ERROR);
export const hspEngagementScorecardSearchSuccess = createAction<IPagedResult<IPersonSearchResultModel>>(HSP_ENGAGEMENT_SCORECARD.LOADED);

export const internshipScorecardSearch = createAction<ISearchRequest<IScorecardParamsModel>>(INTERNSHIP_SCORECARD.LOAD);
export const internshipScorecardSearchView = createAction<string>(INTERNSHIP_SCORECARD.CHANGE_VIEW);
export const internshipScorecardSearchRefresh = createAction<ISearchRequest<IScorecardParamsModel>>(INTERNSHIP_SCORECARD.REFRESH);
export const internshipScorecardSearchError = createAction<string>(INTERNSHIP_SCORECARD.ERROR);
export const internshipScorecardSearchSuccess = createAction<IPagedResult<IPersonSearchResultModel>>(INTERNSHIP_SCORECARD.LOADED);

export const companyInternshipScorecardSearch = createAction<ISearchRequest<IScorecardParamsModel>>(COMPANY_INTERNSHIP_SCORECARD.LOAD);
export const companyInternshipScorecardSearchView = createAction<string>(COMPANY_INTERNSHIP_SCORECARD.CHANGE_VIEW);
export const companyInternshipScorecardSearchRefresh = createAction<ISearchRequest<IScorecardParamsModel>>(COMPANY_INTERNSHIP_SCORECARD.REFRESH);
export const companyInternshipScorecardSearchError = createAction<string>(COMPANY_INTERNSHIP_SCORECARD.ERROR);
export const companyInternshipScorecardSearchSuccess = createAction<IPagedResult<IPersonSearchResultModel>>(COMPANY_INTERNSHIP_SCORECARD.LOADED);
