export class MessageDraftCompletion implements IMessageDraftCompletion {
  isCompleted?: boolean | undefined;
  isCancelled?: boolean | undefined;

  constructor(data?: IMessageDraftCompletion) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.isCompleted = Data["isCompleted"];
      this.isCancelled = Data["isCancelled"];
    }
  }

  static fromJS(data: any): MessageDraftCompletion {
    data = typeof data === 'object' ? data : {};
    let result = new MessageDraftCompletion();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isCompleted"] = this.isCompleted;
    data["isCancelled"] = this.isCancelled;
    return data;
  }
}

export interface IMessageDraftCompletion {
  isCompleted?: boolean | undefined;
  isCancelled?: boolean | undefined;
}