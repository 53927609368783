import { createSelector } from '@reduxjs/toolkit';
import {IUIState} from './Reducer';

export const selectUI = (state: { ui: IUIState }) => state.ui;

export const selectUILoading = createSelector(
  selectUI,
  (state) => state.loading
);

export const selectUIError = createSelector(
  selectUI,
  (state) => state.error
);

export const selectChatCollapsed = createSelector(
  selectUI,
  (state) => state.chatCollapsed
);

export const selectIsAppDrawerOpen = createSelector(
  selectUI,
  (state) => state.isAppDrawerOpen
);

export const selectNavCollapsed = createSelector(
  selectUI,
  (state) => state.navCollapsed
);