import React from 'react';
import { generatePath } from 'react-router';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {ListItem} from '@mui/material';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {StudentUpdatesSearchModel} from '../../../types/views/StudentUpdatesSearchModel';
import PersonAvatar from '../../people/PersonAvatar';
import {studentPaths, studentProfileTabs} from '../../students/paths';
import {AppNavLink} from '../../../@template';

const useStyles = makeStyles(() => ({
  textBase: {
    fontSize: 14,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    padding: '8px 10px',
  },
  titleLink: {
    textDecoration: 'none',
  },
}));

interface StudentChangeRequestItemProps {
  item: StudentUpdatesSearchModel;
}

const StudentChangeRequestItem: React.FC<StudentChangeRequestItemProps> = ({item}) => {
  const classes = useStyles();

  const getStudentLink = () => {
    return `${generatePath(studentPaths.StudentProfile, { id: item.personId })}?tab=${studentProfileTabs.changeRequests.query}`;
  }

  return (
    <ListItem
      className={clsx(classes.listItemRoot, 'item-hover')}
      component={AppNavLink}
      to={getStudentLink()}
    >
      <Box mr={4}>
        <PersonAvatar personId={item.personId ?? ''} size={36}/>
      </Box>
      <Box>
        <Box
          mr={2}
          component='span'
          display='inline-block'
          color='text.primary'
          fontWeight={Fonts.MEDIUM}>
          <strong>{item.fullName} ({item.updatePipelineCount} Pending Requests)</strong>
        </Box>
        <Box component='p' className={classes.textBase} color='text.secondary'>
          {item.updatePipelineLastUpdated?.toDateString()}
        </Box>
      </Box>
    </ListItem>
  );
};

export default StudentChangeRequestItem;