import React from 'react';
import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/RestorePageOutlined';
import {Popover, Theme} from '@mui/material';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Scrollbar from '../../../@template/core/Scrollbar';
import clsx from 'clsx';
import HistoryItem from './HistoryItem';
import {Fonts} from '../../../shared/constants/AppEnums';
import makeStyles from '@mui/styles/makeStyles';
import {useSelector} from 'react-redux';
import {selectHistoryDetail, selectHistoryLoading} from '../../../redux/history/Selectors';
import {HistoryRetrievedModel} from '../../../types/models/HistoryRetrievedModel';
import {hasItems} from '../../../@template/helpers/arrays';

const useStyles = makeStyles((theme: Theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('xs')]: {
        width: 300,
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 200,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 380,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('xs')]: {
      height: 70,
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      width: 'auto',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
}));

interface HistoryProps {}

const History: React.FC<HistoryProps> = () => {
  const [
    anchorHistory,
    setAnchorHistory,
  ] = React.useState<HTMLButtonElement | null>(null);

  const historyDetail = useSelector(selectHistoryDetail);
  const historyLoading = useSelector(selectHistoryLoading);

  const onClickHistoryButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorHistory(event.currentTarget);
  };

  const classes = useStyles();

  return <>
    <IconButton
      className={clsx(classes.notiBtn, 'notiBtn')}
      aria-label='show 20 last profiles'
      color='inherit'
      onClick={onClickHistoryButton}
    >
      <HistoryIcon
        className={clsx(classes.notiIcon, 'notiIcon')}
      />
      <Box
        sx={{ display: {  xs: 'block', sm: 'none' } }}
        ml={4}
        fontSize={16}
        fontWeight={Fonts.REGULAR}
        component='span'>
        Recent Profiles
      </Box>
    </IconButton>

    <Popover
      anchorEl={anchorHistory}
      id='history'
      className={classes.crPopover}
      keepMounted
      open={Boolean(anchorHistory)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => setAnchorHistory(null)}>
      <Box>
        <Box px={5} py={3}>
          <Box component='h5' fontSize={16}>
            Recent Profiles
          </Box>
        </Box>
        <Scrollbar className='scroll-submenu'>
          <List
            className={classes.list}
            onClick={() => {
              setAnchorHistory(null);
            }}>
            {hasItems(historyDetail) && historyDetail.map((item: HistoryRetrievedModel, index) => (
              <HistoryItem key={item.historyId} item={item} />
            ))}
          </List>
        </Scrollbar>
      </Box>
    </Popover>
  </>;
};

export default History;