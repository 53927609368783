import {all, call, put, takeLatest} from 'redux-saga/effects';
import {
  HSP_COMPANY_POOL_LIST,
  HSP_COMPANY_POOL_SEASONS,
  hspPoolCompanyListLoadError, hspPoolCompanyListLoadSuccess,
  hspPoolCompanySeasonsLoadError, hspPoolCompanySeasonsLoadSuccess,
} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {HSPPoolDetailModel} from '../../types/models/HSPPoolDetailModel';
import {SeasonModel} from '../../types/models/SeasonModel';
import {HSPCompanyPoolSeasonModel} from '../../types/models/HSPCompanyPoolSeasonModel';

function* HSPCompanyPoolSeasonsLoad(companyId: string) {
  console.log('SAGA - hspPoolSaga - CompanyPoolSeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreCompaniesPath}/${companyId}/hsp-pool-seasons`,
    });
    const result: SeasonModel[] = res.data.map((x: any) => HSPCompanyPoolSeasonModel.fromJS(x));
    yield put(hspPoolCompanySeasonsLoadSuccess(result));
  } catch (err) {
    yield put(hspPoolCompanySeasonsLoadError(err));
  }
}

function* HSPCompanyPoolsList(companyIds: string[]) {
  console.log('SAGA - hspPoolSaga - CompanyPoolListLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url:`${apiConfig.coreHSPPoolsPath}/list-by-company`,
      data: { id_list: companyIds }
    });
    const result: HSPPoolDetailModel[] = res.data.map((x: any) => HSPPoolDetailModel.fromJS(x));
    yield put(hspPoolCompanyListLoadSuccess(result));
  } catch (err) {
    yield put(hspPoolCompanyListLoadError(err));
  }
}

export function* watchHSPPoolCompanySeasonsLoad() {
  yield takeLatest(HSP_COMPANY_POOL_SEASONS.LOAD, ({payload}: PayloadAction<string>) => HSPCompanyPoolSeasonsLoad(payload))
}

export function* watchHSPPoolCompanySeasonsRefresh() {
  yield takeLatest(HSP_COMPANY_POOL_SEASONS.REFRESH, ({payload}: PayloadAction<string>) => HSPCompanyPoolSeasonsLoad(payload))
}

export function* watchHSPCompanyPoolListLoad() {
  yield takeLatest(HSP_COMPANY_POOL_LIST.LOAD, ({payload}: PayloadAction<string[]>) => HSPCompanyPoolsList(payload))
}

export function* watchHSPCompanyPoolListRefresh() {
  yield takeLatest(HSP_COMPANY_POOL_LIST.REFRESH, ({payload}: PayloadAction<string[]>) => HSPCompanyPoolsList(payload))
}

export default function* hspPoolSaga() {
  yield all ([
    call(watchHSPPoolCompanySeasonsLoad),
    call(watchHSPPoolCompanySeasonsRefresh),
    call(watchHSPCompanyPoolListLoad),
    call(watchHSPCompanyPoolListRefresh),
  ])
}
