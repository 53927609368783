export class PartnerSearchModel implements IPartnerSearchModel {
  isActive?: boolean | undefined;
  partnerStatus?: string | undefined;
  partnerTags?: string[] | undefined;
  partnerSummary?: string | undefined;

  constructor(data?: IPartnerSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.isActive = Data["isActive"];
      this.partnerStatus = Data["partnerStatus"];
      if (Array.isArray(Data["partnerTags"])) {
        this.partnerTags = [] as any;
        for (let item of Data["partnerTags"])
          this.partnerTags!.push(item);
      }
      this.partnerSummary = Data["partnerSummary"];
    }
  }

  static fromJS(data: any): PartnerSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new PartnerSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isActive"] = this.isActive;
    data["partnerStatus"] = this.partnerStatus;
    if (Array.isArray(this.partnerTags)) {
      data["partnerTags"] = [];
      for (let item of this.partnerTags)
        data["partnerTags"].push(item);
    }
    data["partnerSummary"] = this.partnerSummary;
    return data;
  }
}

export interface IPartnerSearchModel {
  isActive?: boolean | undefined;
  partnerStatus?: string | undefined;
  partnerTags?: string[] | undefined;
  partnerSummary?: string | undefined;
}
