import {AppState} from '../store';
import {PERSON_CACHE, personCacheError, personCacheMerge, personCacheUpdate} from './Actions';
import {apiConfig} from '../../config';
import {hasItems} from '../../@template/helpers/arrays';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {PersonListItem} from '../../types/views/PersonListItem';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IPeopleCacheState} from './Reducer';
import {PayloadAction} from '@reduxjs/toolkit';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const peopleCacheState = (state: AppState) => state.peopleCache;

export function* LoadPersonCacheItems(idList: string[]) {
  // console.log('SAGA - peopleCacheSaga - LoadPersonCacheItems');
  try {
    if (hasItems(idList)) {
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.corePeoplePath}/list`,
        data: { id_list: idList }
      });
      const people: PersonListItem[] = res.data.map((x: any) => PersonListItem.fromJS(x));
      yield put(personCacheMerge(people));
    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(personCacheError(err));
  }
}

export function* AddPersonCacheItems() {
  // console.log('SAGA - peopleCacheSaga - AddPersonCacheItems');
  yield call(delay, 200);
  const currentPersonCache: IPeopleCacheState = yield select(peopleCacheState);
  const currentPendingList = currentPersonCache.personPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    yield put(personCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadPersonCacheItems(){
  yield takeEvery(PERSON_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadPersonCacheItems(payload));
}

export function* watchAddPersonCacheItems(){
  yield takeEvery(PERSON_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddPersonCacheItems());
}

export default function* peopleCacheSaga() {
  yield all([
    call(watchLoadPersonCacheItems),
    call(watchAddPersonCacheItems),
  ])
}