import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {
  COMPANY,
  COMPANY_LOGO,
  COMPANY_SEARCH,
  companyLoadError,
  companyLoadSuccess,
  companyLogoSaveError,
  companyLogoSaveSuccess,
  companySaveError,
  companySaveSuccess,
  companySearchError,
  companySearchSuccess, MY_COMPANIES, myCompaniesError, myCompaniesSuccess,
  OPPORTUNITY_SEARCH,
  opportunitySearchError,
  opportunitySearchSuccess, RELATED_COMPANIES, relatedCompaniesError, relatedCompaniesSuccess,
} from './Actions';
import {ICommonSavePayload} from '../Common.actions';
import {CompanyEditModel} from '../../types/requests/CompanyEditModel';
import {CompanySearchPaged} from '../../types/views/CompanySearchPaged';
import {CompanySearchParams} from '../../types/requests/CompanySearchParams';
import {CompanyModel} from '../../types/models/CompanyModel';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {ICompanyLogoRequest} from '../../types/requests/CompanyLogoRequest';
import {OpportunitiesSearchPaged} from '../../types/views/OpportunitiesSearchPaged';
import {OpportunitySearchParamsModel} from '../../types/requests/OpportunitySearchParamsModel';


export function* LoadCompany(id: string) {
  console.log('SAGA - companySaga - LoadCompany');
  try {
    const res = yield authApi.get(`${apiConfig.coreCompaniesPath}/${id}`);
    const company: CompanyModel = CompanyModel.fromJS(res.data);
    yield put(companyLoadSuccess(company));
  } catch (err) {
    yield put(companyLoadError(err));
  }
}

export function* SaveCompany({ id, submission }: ICommonSavePayload<CompanyEditModel>) {
  console.log('SAGA - companySaga - SaveCompany');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: id ? `${apiConfig.coreCompaniesPath}/${id}` : apiConfig.coreCompaniesPath,
      data: submission
    });
    const company: CompanyModel = CompanyModel.fromJS(res.data);
    yield put(companySaveSuccess(company));
  } catch (err) {
    yield put(companySaveError(err));
  }
}

export function* SearchCompanies({ page, searchParams, sort, pageSize }: ISearchRequest<CompanySearchParams>) {
  console.log('SAGA - companySaga - SearchCompanies');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreCompaniesPath}/search?page=${page}&page_size=${pageSize}&sort=${sort}`,
      data: searchParams
    });
    const results: CompanySearchPaged = CompanySearchPaged.fromJS(res.data);
    yield put(companySearchSuccess(results));
  } catch (err) {
    yield put(companySearchError(err));
  }
}

export function* MyCompanies({ page, searchParams, sort, pageSize }: ISearchRequest<CompanySearchParams>) {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreCompaniesPath}/search?page=${page}&page_size=${pageSize}`,
      data: searchParams
    });
    const results: CompanySearchPaged = CompanySearchPaged.fromJS(res.data);
    yield put(myCompaniesSuccess(results));
  } catch (err) {
    yield put(myCompaniesError(err));
  }
}

export function* SaveCompanyLogo({companyId, website}: ICompanyLogoRequest) {
  try {
    yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreCompaniesPath}/favicon/save/${companyId}`,
      data: {website}
    });
    yield put(companyLogoSaveSuccess());
  } catch (error) {
    yield put(companyLogoSaveError(error));
  }
}

export function* RelatedCompaniesLoad(id: string) {
  console.log('SAGA - companySaga - LoadCompany');
  try {
    const res = yield authApi.get(`${apiConfig.coreCompaniesPath}/related-companies/${id}`);
    const company: Array<CompanyModel> = res.data.map((x:any) => CompanyModel.fromJS(x));
    yield put(relatedCompaniesSuccess(company));
  } catch (err) {
    yield put(relatedCompaniesError(err));
  }
}

export function* SearchOpportunities({ page, searchParams, sort, pageSize }: ISearchRequest<OpportunitySearchParamsModel>) {
  console.log('SAGA - companySaga - SearchOpportunities');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreOpportunitiesPath}/search?page=${page}&page_size=${pageSize}&sort=${sort}`,
      data: searchParams
    });
    const results: OpportunitiesSearchPaged = OpportunitiesSearchPaged.fromJS(res.data);
    yield put(opportunitySearchSuccess(results));
  } catch (err) {
    yield put(opportunitySearchError(err));
  }
}

export function* watchCompanyLoad() {
  yield takeLatest(COMPANY.LOAD, ({payload}: PayloadAction<string>) => LoadCompany(payload))
}

export function* watchCompanyRefresh() {
  yield takeLatest(COMPANY.REFRESH, ({payload}: PayloadAction<string>) => LoadCompany(payload))
}

export function* watchCompanySave() {
  yield takeLatest(COMPANY.SAVE, ({payload}: PayloadAction<ICommonSavePayload<CompanyEditModel>>) => SaveCompany(payload))
}

export function* watchCompanyLogoSave() {
  yield takeLatest(COMPANY_LOGO.SAVE, ({payload}: PayloadAction<ICompanyLogoRequest>) => SaveCompanyLogo(payload))
}

export function* watchCompanySearch() {
  yield takeLatest(COMPANY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<CompanySearchParams>>) => SearchCompanies(payload))
}

export function* watchCompanySearchRefresh() {
  yield takeLatest(COMPANY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<CompanySearchParams>>) => SearchCompanies(payload))
}

export function* watchOpportunitySearch() {
  yield takeLatest(OPPORTUNITY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => SearchOpportunities(payload))
}

export function* watchOpportunitySearchRefresh() {
  yield takeLatest(OPPORTUNITY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => SearchOpportunities(payload))
}

export function* watchMyCompanies(){
  yield takeLatest(MY_COMPANIES.LOAD, ({payload}: PayloadAction<ISearchRequest<CompanySearchParams>>) => MyCompanies(payload))
}

export function* watchRelatedCompanies(){
  yield takeLatest(RELATED_COMPANIES.LOAD, ({payload}: PayloadAction<string>) => RelatedCompaniesLoad(payload))
}


export default function* companySaga() {
  yield all ([
    call(watchCompanyLoad),
    call(watchCompanySave),
    call(watchCompanyRefresh),
    call(watchCompanySearch),
    call(watchCompanyLogoSave),
    call(watchOpportunitySearch),
    call(watchOpportunitySearchRefresh),
    call(watchMyCompanies),
    call(watchRelatedCompanies),
  ])
}
