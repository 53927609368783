export class BreakSettingsModel implements IBreakSettingsModel {
  id?: string | undefined;
  studentId?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  startBreakDate?: Date | undefined;
  endBreakDate?: Date | undefined;
  isGlobal?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isDisabled?: boolean | undefined;

  constructor(data?: IBreakSettingsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.studentId = Data["studentId"];
      this.title = Data["title"];
      this.description = Data["description"];
      this.startBreakDate = Data["startBreakDate"] ? new Date(Data["startBreakDate"].toString()) : <any>undefined;
      this.endBreakDate = Data["endBreakDate"] ? new Date(Data["endBreakDate"].toString()) : <any>undefined;
      this.isGlobal = Data["isGlobal"];
      this.isDeleted = Data["isDeleted"];
      this.isDisabled = Data["isDisabled"];
    }
  }

  static fromJS(data: any): BreakSettingsModel {
    data = typeof data === 'object' ? data : {};
    let result = new BreakSettingsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["studentId"] = this.studentId;
    data["title"] = this.title;
    data["description"] = this.description;
    data["startBreakDate"] = this.startBreakDate ? this.startBreakDate.toISOString() : <any>undefined;
    data["endBreakDate"] = this.endBreakDate ? this.endBreakDate.toISOString() : <any>undefined;
    data["isGlobal"] = this.isGlobal;
    data["isDeleted"] = this.isDeleted;
    data["isDisabled"] = this.isDisabled;
    return data;
  }
}

export interface IBreakSettingsModel {
  id?: string | undefined;
  studentId?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  startBreakDate?: Date | undefined;
  endBreakDate?: Date | undefined;
  isGlobal?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isDisabled?: boolean | undefined;
}
