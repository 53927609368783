import {formatDate} from '../../@template/helpers/datetimes';

const defaultWorkShadowStatusFilter = ['Potential', 'Pipeline', 'Confirmed'];

export const defaultWorkShadowSearchParamsModel = () => {
  return WorkShadowSearchParamsModel.fromJS({
    searchText: '',
    statusFlags: defaultWorkShadowStatusFilter,
    joinedFrom: null,
    joinedTo: null,
    advisorUsers: [],
    primaryOnly: true,
    noPrimaryAppointed: false,
    advisorSelected: false,
    noAdvisorAppointed: false,
    excludePrimarySelected: false,
    byTeam: false,
    advisorTeamId: null,
    advisorSubTeamId: null,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    degreePoolId: [],
    workShadowTags: [],
    state: [],
    countryCode: 'AU',
    contactSearchText: '',
    highSchoolComplete: null,
    notArchived: true,
    includeArchived: false,
    onlyArchived: false,
    isStudent: false,
    isAlumni: false,
    isHighSchoolProgramOrWorkShadow: true,
    onlyHighSchoolProgram: false,
    onlyWorkShadow: false,
    companyIds: [],
  });
}

export class WorkShadowSearchParamsModel implements IWorkShadowSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  degreePoolId?: string[] | undefined;
  workShadowTags?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  highSchoolComplete?: number | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  isStudent?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isHighSchoolProgramOrWorkShadow?: boolean | undefined;
  onlyHighSchoolProgram?: boolean | undefined;
  onlyWorkShadow?: boolean | undefined;
  companyIds?: string[] | undefined;

  constructor(data?: IWorkShadowSearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      if (Array.isArray(Data["statusFlags"])) {
        this.statusFlags = [] as any;
        for (let item of Data["statusFlags"])
          this.statusFlags!.push(item);
      }
      this.joinedFrom = Data["joinedFrom"] ? new Date(Data["joinedFrom"].toString()) : <any>undefined;
      this.joinedTo = Data["joinedTo"] ? new Date(Data["joinedTo"].toString()) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data["primaryOnly"];
      this.advisorSelected = Data["advisorSelected"];
      this.noPrimaryAppointed = Data["noPrimaryAppointed"];
      this.noAdvisorAppointed = Data["noAdvisorAppointed"];
      this.excludePrimarySelected = Data["excludePrimarySelected"];
      this.byTeam = Data["byTeam"];
      this.advisorTeamId = Data["advisorTeamId"];
      this.advisorSubTeamId = Data["advisorSubTeamId"];
      this.byAdvisorTeam = Data["byAdvisorTeam"];
      this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
      if (Array.isArray(Data["degreePoolId"])) {
        this.degreePoolId = [] as any;
        for (let item of Data["degreePoolId"])
          this.degreePoolId!.push(item);
      }
      if (Array.isArray(Data["workShadowTags"])) {
        this.workShadowTags = [] as any;
        for (let item of Data["workShadowTags"])
          this.workShadowTags!.push(item);
      }
      if (Array.isArray(Data["state"])) {
        this.state = [] as any;
        for (let item of Data["state"])
          this.state!.push(item);
      }
      this.countryCode = Data["countryCode"];
      this.contactSearchText = Data["contactSearchText"];
      this.highSchoolComplete = Data["highSchoolComplete"];
      this.notArchived = Data["notArchived"];
      this.includeArchived = Data["includeArchived"];
      this.onlyArchived = Data["onlyArchived"];
      this.isStudent = Data["isStudent"];
      this.isAlumni = Data["isAlumni"];
      this.isHighSchoolProgramOrWorkShadow = Data["isHighSchoolProgramOrWorkShadow"];
      this.onlyHighSchoolProgram = Data["onlyHighSchoolProgram"];
      this.onlyWorkShadow = Data["onlyWorkShadow"];
      if (Array.isArray(Data["companyIds"])) {
        this.companyIds = [] as any;
        for (let item of Data["companyIds"])
          this.companyIds!.push(item);
      }
    }
  }

  static fromJS(data: any): WorkShadowSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new WorkShadowSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    if (Array.isArray(this.statusFlags)) {
      data["statusFlags"] = [];
      for (let item of this.statusFlags)
        data["statusFlags"].push(item);
    }
    data["joinedFrom"] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
    data["joinedTo"] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryOnly"] = this.primaryOnly;
    data["advisorSelected"] = this.advisorSelected;
    data["noPrimaryAppointed"] = this.noPrimaryAppointed;
    data["noAdvisorAppointed"] = this.noAdvisorAppointed;
    data["excludePrimarySelected"] = this.excludePrimarySelected;
    data["byTeam"] = this.byTeam;
    data["advisorTeamId"] = this.advisorTeamId;
    data["advisorSubTeamId"] = this.advisorSubTeamId;
    data["byAdvisorTeam"] = this.byAdvisorTeam;
    data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
    if (Array.isArray(this.degreePoolId)) {
      data["degreePoolId"] = [];
      for (let item of this.degreePoolId)
        data["degreePoolId"].push(item);
    }
    if (Array.isArray(this.workShadowTags)) {
      data["workShadowTags"] = [];
      for (let item of this.workShadowTags)
        data["workShadowTags"].push(item);
    }
    if (Array.isArray(this.state)) {
      data["state"] = [];
      for (let item of this.state)
        data["state"].push(item);
    }
    data["countryCode"] = this.countryCode;
    data["contactSearchText"] = this.contactSearchText;
    data["highSchoolComplete"] = this.highSchoolComplete;
    data["notArchived"] = this.notArchived;
    data["includeArchived"] = this.includeArchived;
    data["onlyArchived"] = this.onlyArchived;
    data["isStudent"] = this.isStudent;
    data["isAlumni"] = this.isAlumni;
    data["isHighSchoolProgramOrWorkShadow"] = this.isHighSchoolProgramOrWorkShadow;
    data["onlyHighSchoolProgram"] = this.onlyHighSchoolProgram;
    data["onlyWorkShadow"] = this.onlyWorkShadow;
    if (Array.isArray(this.companyIds)) {
      data["companyIds"] = [];
      for (let item of this.companyIds)
        data["companyIds"].push(item);
    }
    return data;
  }
}

export interface IWorkShadowSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  degreePoolId?: string[] | undefined;
  workShadowTags?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  highSchoolComplete?: number | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  isStudent?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isHighSchoolProgramOrWorkShadow?: boolean | undefined;
  onlyHighSchoolProgram?: boolean | undefined;
  onlyWorkShadow?: boolean | undefined;
  companyIds?: string[] | undefined;
}
