export interface AlumniPaths {
  AlumniEdit: string,
  AlumniPromote: string,
  AlumniCreate: string,
  AlumniSearch: string
}

export const alumniPaths: AlumniPaths = {
  AlumniEdit: '/advisor/alumni/edit/:id',
  AlumniPromote: '/advisor/alumni/promote/:id',
  AlumniCreate: '/advisor/alumni/add',
  AlumniSearch: '/advisor/alumni/search',
};