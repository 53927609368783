import { HSPOpportunityDetailModel } from "types/models/HSPOpportunityDetailModel";
import {HSPPoolItem} from './HSPPoolItem';

export class HSPOpportunityStudentListView implements IHSPOpportunityStudentListView {
  hspOpportunity?: HSPOpportunityDetailModel | undefined;
  poolStudents?: HSPPoolItem[] | undefined;

  constructor(data?: IHSPOpportunityStudentListView) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.hspOpportunity = Data["hspOpportunity"] ? HSPOpportunityDetailModel.fromJS(Data["hspOpportunity"]) : <any>undefined;
      if (Array.isArray(Data["poolStudents"])) {
        this.poolStudents = [] as any;
        for (let item of Data["poolStudents"])
          this.poolStudents!.push(HSPPoolItem.fromJS(item));
      }
    }
  }

  static fromJS(data: any): HSPOpportunityStudentListView {
    data = typeof data === 'object' ? data : {};
    let result = new HSPOpportunityStudentListView();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["hspOpportunity"] = this.hspOpportunity ? this.hspOpportunity.toJSON() : <any>undefined;
    if (Array.isArray(this.poolStudents)) {
      data["poolStudents"] = [];
      for (let item of this.poolStudents)
        data["poolStudents"].push(item.toJSON());
    }
    return data;
  }
}

export interface IHSPOpportunityStudentListView {
  hspOpportunity?: HSPOpportunityDetailModel | undefined;
  poolStudents?: HSPPoolItem[] | undefined;
}