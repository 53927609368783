import {ICommonSaveActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {HistoryRetrievedModel} from '../../types/models/HistoryRetrievedModel';
import {HistorySubmitModel} from '../../types/requests/HistorySubmitModel';

export interface IHistoryActionTypes extends ICommonSaveActionType {}

export const HISTORY: IHistoryActionTypes = {
  LOAD: 'history/load',
  LOADED: 'history/loaded',
  LOAD_ERROR: 'history/loadError',
  SAVE: 'history/save',
  SAVED: 'history/saveSuccess',
  SAVE_ERROR: 'history/saveError'
}

export const historyLoad = createAction(HISTORY.LOAD);
export const historyLoadSuccess = createAction<HistoryRetrievedModel[]>(HISTORY.LOADED);
export const historyLoadError = createAction<string>(HISTORY.LOAD_ERROR);
export const historySave = createAction<HistorySubmitModel>(HISTORY.SAVE);
export const historySaveSuccess = createAction<HistoryRetrievedModel[]>(HISTORY.SAVED);
export const historySaveError = createAction<string>(HISTORY.SAVE_ERROR);