export class UpdatePipelineResult implements IUpdatePipelineResult {
  updateId?: string | undefined;
  personId?: string | undefined;
  changeType?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  source?: string | undefined;
  fields?: any | undefined;
  isIgnored?: boolean | undefined;
  isApplied?: boolean | undefined;
  dateResolved?: Date | undefined;
  resolvedByUserId?: string | undefined;

  constructor(data?: IUpdatePipelineResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.updateId = Data["updateId"];
      this.personId = Data["personId"];
      this.changeType = Data["changeType"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.source = Data["source"];
      this.fields = Data["fields"];
      this.isIgnored = Data["isIgnored"];
      this.isApplied = Data["isApplied"];
      this.dateResolved = Data["dateResolved"] ? new Date(Data["dateResolved"].toString()) : <any>undefined;
      this.resolvedByUserId = Data["resolvedByUserId"];
    }
  }

  static fromJS(data: any): UpdatePipelineResult {
    data = typeof data === 'object' ? data : {};
    let result = new UpdatePipelineResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["updateId"] = this.updateId;
    data["personId"] = this.personId;
    data["changeType"] = this.changeType;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["source"] = this.source;
    data["fields"] = this.fields;
    data["isIgnored"] = this.isIgnored;
    data["isApplied"] = this.isApplied;
    data["dateResolved"] = this.dateResolved ? this.dateResolved.toISOString() : <any>undefined;
    data["resolvedByUserId"] = this.resolvedByUserId;
    return data;
  }
}

export interface IUpdatePipelineResult {
  updateId?: string | undefined;
  personId?: string | undefined;
  changeType?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  source?: string | undefined;
  fields?: any | undefined;
  isIgnored?: boolean | undefined;
  isApplied?: boolean | undefined;
  dateResolved?: Date | undefined;
  resolvedByUserId?: string | undefined;
}