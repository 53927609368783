import {defaultStudentSearchParamsModel, StudentSearchParamsModel} from '../../types/requests/StudentSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {PersonModel} from '../../types/models/PersonModel';
import {StudentEditDetailModel} from '../../types/requests/StudentEditDetailModel';
import {StudentPoolItem} from '../../types/views/StudentPoolItem';
import {StudentAchievementModel} from '../../types/models/StudentAchievementModel';
import {StudentUpdatesSearchModel} from '../../types/views/StudentSearchUpdatesModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {
  EMPLOYER_PUBLIC_PROFILE, MY_STUDENTS,
  PLACEMENT_SEARCH,
  STUDENT,
  STUDENT_ACHIEVEMENTS, STUDENT_LEARNING_CONTRACTS, STUDENT_OPPORTUNITIES,
  STUDENT_PLACEMENTS,
  STUDENT_SEARCH,
  STUDENT_STUDENT_POOLS,
} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';
import {SearchView} from '../../types/common/SearchView';
import {PlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {defaultInternshipSearchParams, InternshipSearchParams} from '../../types/requests/InternshipSearchParams';
import {StudentInternshipSearchPaged} from '../../types/views/StudentInternshipSearchPaged';
import {EventItemViewModel} from '../../types/models/EventItemViewModel';
import {StudentProfileEditModel} from '../../types/requests/StudentProfileEditModel';
import {StudentOpportunityListView} from '../../types/views/StudentOpportunityListView';
import { PersonSearchResultPaged } from 'types/views/PersonSearchResultPaged';

export interface IStudentState {
  studentSearchParams: StudentSearchParamsModel;
  studentSearchView: string | undefined;
  studentSearchSort: string | undefined;
  studentResultLoading: boolean;
  studentResultError: string | undefined;
  studentResult: PersonSearchResultPaged | undefined;
  studentLoading: boolean;
  studentRefreshing: boolean;
  studentSaving: boolean;
  studentSaveError: string | undefined;
  studentError: string | undefined;
  studentEditDetail: StudentEditDetailModel | undefined;
  studentDetail: PersonModel | undefined;
  studentPoolsResult: StudentPoolItem[] | undefined;
  studentPoolsLoading: boolean;
  studentPoolsRefreshing: boolean;
  studentOpportunitiesResult: StudentOpportunityListView[] | undefined;
  studentOpportunitiesLoading: boolean;
  studentOpportunitiesError: string | undefined;
  studentOpportunitiesRefreshing: boolean;
  studentPoolsError: string | undefined;
  studentPlacements:PlacementDetailModel[] | undefined;
  studentPlacementsLoading: boolean;
  studentPlacementsRefreshing: boolean;
  studentPlacementsError: string | undefined;
  achievementsLoading: boolean;
  achievementsError: string | undefined;
  achievementsResult: StudentAchievementModel[] | undefined;
  changeRequestStudentsResultLoading: boolean;
  changeRequestStudentsResultError: string | undefined;
  changeRequestStudentsResultCount: number;
  changeRequestStudentsResult: IPagedResult<StudentUpdatesSearchModel> | undefined;
  placementSearchView: string | undefined;
  placementSearchSort: string | undefined;
  placementSearchResult: StudentInternshipSearchPaged | undefined;
  placementSearchResultLoading: boolean;
  placementSearchResultError: string | undefined;
  placementSearchParams: InternshipSearchParams;
  learningContracts: EventItemViewModel[] | undefined;
  learningContractsError: string | undefined;
  learningContractsLoading: boolean;

  employerProfileLoading: boolean;
  employerProfileRefreshing: boolean;
  employerProfileSaving: boolean;
  employerProfileSaveError: string | undefined;
  employerProfileError: string | undefined;
  employerProfileEditDetail: StudentProfileEditModel | undefined;
  employerProfileViewStudent: PersonModel | undefined;
  employerProfileViewStudentLoading: boolean;
  employerProfileViewStudentError: string | undefined;
  myStudentsResultLoading: boolean;
  myStudentsResultError: string | undefined;
  myStudentsResult: PersonSearchResultPaged | undefined;
}

const initialState: IStudentState = {
  studentSearchParams: defaultStudentSearchParamsModel(),
  studentSearchView: SearchView.Default,
  studentSearchSort: 'Relevance',
  studentResultLoading: false,
  studentResultError: undefined,
  studentResult: undefined,
  studentLoading: false,
  studentRefreshing: false,
  studentSaving: false,
  studentSaveError: undefined,
  studentError: undefined,
  studentEditDetail: undefined,
  studentDetail: undefined,
  studentOpportunitiesResult: undefined,
  studentOpportunitiesRefreshing: false,
  studentOpportunitiesLoading: false,
  studentOpportunitiesError: undefined,
  studentPoolsResult: undefined,
  studentPoolsLoading: false,
  studentPoolsRefreshing: false,
  studentPoolsError: undefined,
  studentPlacements: undefined,
  studentPlacementsError: undefined,
  studentPlacementsLoading: false,
  studentPlacementsRefreshing: false,
  achievementsLoading: false,
  achievementsError: undefined,
  achievementsResult: undefined,
  changeRequestStudentsResultLoading: false,
  changeRequestStudentsResultError: undefined,
  changeRequestStudentsResultCount: 0,
  changeRequestStudentsResult: undefined,
  placementSearchParams: defaultInternshipSearchParams(),
  placementSearchView: SearchView.Default,
  placementSearchSort: 'Relevance',
  placementSearchResultLoading: false,
  placementSearchResultError: undefined,
  placementSearchResult: undefined,
  learningContracts: undefined,
  learningContractsLoading: false,
  learningContractsError: undefined,

  employerProfileLoading: false,
  employerProfileRefreshing: false,
  employerProfileSaving: false,
  employerProfileSaveError: undefined,
  employerProfileError: undefined,
  employerProfileEditDetail: undefined,
  employerProfileViewStudent: undefined,
  employerProfileViewStudentLoading: false,
  employerProfileViewStudentError: undefined,
  myStudentsResultLoading: false,
  myStudentsResultError: undefined,
  myStudentsResult: undefined,
};

const studentsReducer = createReducer(initialState, {
  [STUDENT_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.studentSearchView = action.payload;
  },
  [STUDENT_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<StudentSearchParamsModel>>) => {
    state.studentSearchParams = action.payload.searchParams;
    state.studentResultError = undefined;
    state.studentResultLoading = true;
  },
  [STUDENT_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<StudentSearchParamsModel>>) => {
    state.studentSearchParams = action.payload.searchParams;
    state.studentResultError = undefined;
  },
  [STUDENT_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.studentResultError = action.payload;
    state.studentResultLoading = false;
  },
  [STUDENT_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.studentResult = action.payload;
    state.studentResultLoading = false;
    state.studentResultError = undefined;
  },
  [STUDENT.LOAD]: (state) => {
    state.studentLoading = true;
    state.studentRefreshing = false;
    state.studentError = undefined;
    state.studentSaveError = undefined;
    state.studentDetail = undefined;
  },
  [STUDENT.REFRESH]: (state) => {
    state.studentLoading = false;
    state.studentRefreshing = true;
    state.studentError = undefined;
    state.studentSaveError = undefined;
  },
  [STUDENT.LOADED]: (state, action: PayloadAction<PersonModel>) => {
    state.studentLoading = false;
    state.studentRefreshing = false;
    state.studentError = undefined;
    state.studentSaveError = undefined;
    state.studentDetail = action.payload;
  },
  [STUDENT.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.studentLoading = false;
    state.studentRefreshing = false;
    state.studentError = action.payload;
    state.studentSaveError = undefined;
  },
  [STUDENT.SAVE]: (state) => {
    state.studentSaving = true;
    state.studentError = undefined;
    state.studentSaveError = undefined;
  },
  [STUDENT.ROLLBACK]: (state) => {
    state.studentSaving = true;
    state.studentError = undefined;
    state.studentSaveError = undefined;
  },
  [STUDENT.SAVED]: (state, action: PayloadAction<PersonModel>) => {
    state.studentSaving = false;
    state.studentError = undefined;
    state.studentSaveError = undefined;
    state.studentDetail = action.payload;
  },
  [STUDENT.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.studentSaving = false;
    state.studentError = undefined;
    state.studentSaveError = action.payload;
  },
  [STUDENT_STUDENT_POOLS.LOAD]: (state) => {
    state.studentPoolsLoading = true;
    state.studentPoolsRefreshing = false;
    state.studentPoolsError = undefined;
    state.studentPoolsResult = undefined;
  },
  [STUDENT_STUDENT_POOLS.REFRESH]: (state) => {
    state.studentPoolsLoading = false;
    state.studentPoolsRefreshing = true;
    state.studentPoolsError = undefined;
  },
  [STUDENT_STUDENT_POOLS.LOADED]: (state, action: PayloadAction<StudentPoolItem[]>) => {
    state.studentPoolsLoading = false;
    state.studentPoolsRefreshing = false;
    state.studentPoolsResult = action.payload;
    state.studentPoolsError = undefined;
  },
  [STUDENT_STUDENT_POOLS.ERROR]: (state, action: PayloadAction<string>) => {
    state.studentOpportunitiesLoading = false;
    state.studentOpportunitiesRefreshing = false;
    state.studentOpportunitiesError = action.payload;
  },
  [STUDENT_OPPORTUNITIES.LOAD]: (state) => {
    state.studentOpportunitiesLoading = true;
    state.studentOpportunitiesRefreshing = false;
    state.studentOpportunitiesError = undefined;
    state.studentOpportunitiesResult = undefined;
  },
  [STUDENT_OPPORTUNITIES.REFRESH]: (state) => {
    state.studentOpportunitiesLoading = false;
    state.studentOpportunitiesRefreshing = true;
    state.studentOpportunitiesError = undefined;
  },
  [STUDENT_OPPORTUNITIES.LOADED]: (state, action: PayloadAction<StudentPoolItem[]>) => {
    state.studentOpportunitiesLoading = false;
    state.studentOpportunitiesRefreshing = false;
    state.studentOpportunitiesResult = action.payload;
    state.studentOpportunitiesError = undefined;
  },
  [STUDENT_OPPORTUNITIES.ERROR]: (state, action: PayloadAction<string>) => {
    state.studentOpportunitiesLoading = false;
    state.studentOpportunitiesRefreshing = false;
    state.studentOpportunitiesError = action.payload;
  },
  [STUDENT_PLACEMENTS.LOAD]: (state) => {
    state.studentPlacementsLoading = true;
    state.studentPlacementsRefreshing = false;
    state.studentPlacementsError = undefined;
    state.studentPlacements = undefined;
  },
  [STUDENT_PLACEMENTS.REFRESH]: (state) => {
    state.studentPlacementsLoading = false;
    state.studentPlacementsRefreshing = true;
    state.studentPlacementsError = undefined;
  },
  [STUDENT_PLACEMENTS.LOADED]: (state, action: PayloadAction<PlacementDetailModel[]>) => {
    state.studentPlacementsLoading = false;
    state.studentPlacementsRefreshing = false;
    state.studentPlacementsError = undefined;
    state.studentPlacements = action.payload;
  },
  [STUDENT_PLACEMENTS.ERROR]: (state, action: PayloadAction<string>) => {
    state.studentPlacementsLoading = false;
    state.studentPlacementsRefreshing = false;
    state.studentPlacementsError = action.payload;
  },
  [STUDENT_ACHIEVEMENTS.LOAD]: (state) => {
    state.achievementsLoading = true;
    state.achievementsError = undefined;
    state.achievementsResult = undefined;
  },
  [STUDENT_ACHIEVEMENTS.ERROR]: (state, action: PayloadAction<string>) => {
    state.achievementsLoading = false;
    state.achievementsError = action.payload;
  },
  [STUDENT_ACHIEVEMENTS.LOADED]: (state, action: PayloadAction<StudentAchievementModel[]>) => {
    state.achievementsLoading = false;
    state.achievementsError = undefined;
    state.achievementsResult = action.payload;
  },
  [STUDENT_LEARNING_CONTRACTS.LOAD]: (state) => {
    state.learningContracts = undefined;
    state.learningContractsLoading = true;
    state.learningContractsError = undefined;
  },
  [STUDENT_LEARNING_CONTRACTS.REFRESH]: (state) => {
    state.learningContractsLoading = false;
    state.learningContractsError = undefined;
  },
  [STUDENT_LEARNING_CONTRACTS.ERROR]: (state, action: PayloadAction<string>) => {
    state.learningContractsLoading = false;
    state.learningContractsError = action.payload;
  },
  [STUDENT_LEARNING_CONTRACTS.LOADED]: (state, action: PayloadAction<EventItemViewModel[]>) => {
    state.learningContracts = action.payload;
    state.learningContractsLoading = false;
    state.learningContractsError = undefined;
  },
  [PLACEMENT_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.placementSearchView = action.payload;
  },
  [PLACEMENT_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<InternshipSearchParams>>) => {
    state.placementSearchParams = action.payload.searchParams;
    state.placementSearchResultError = undefined;
    state.placementSearchResultLoading = true;
  },
  [PLACEMENT_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<InternshipSearchParams>>) => {
    state.placementSearchParams = action.payload.searchParams;
    state.placementSearchResultError = undefined;
  },
  [PLACEMENT_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.placementSearchResultError = action.payload;
    state.placementSearchResultLoading = false;
  },
  [PLACEMENT_SEARCH.LOADED]: (state, action: PayloadAction<StudentInternshipSearchPaged>) => {
    state.placementSearchResult = action.payload;
    state.placementSearchResultLoading = false;
    state.placementSearchResultError = undefined;
  },
  [EMPLOYER_PUBLIC_PROFILE.SAVE]: (state) => {
    state.employerProfileSaving = true;
    state.employerProfileError = undefined;
    state.employerProfileSaveError = undefined;
  },
  [EMPLOYER_PUBLIC_PROFILE.SAVED]: (state, action: PayloadAction<PersonModel>) => {
    state.employerProfileSaving = false;
    state.employerProfileError = undefined;
    state.employerProfileSaveError = undefined;
    state.studentDetail = action.payload;
  },
  [EMPLOYER_PUBLIC_PROFILE.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.employerProfileSaving = false;
    state.employerProfileError = undefined;
    state.employerProfileSaveError = action.payload;
  },
  [EMPLOYER_PUBLIC_PROFILE.STUDENT_VIEW_LOAD]: (state) => {
    state.employerProfileViewStudentLoading = true;
    state.employerProfileViewStudentError = undefined;
    state.employerProfileViewStudent = undefined;
  },
  [EMPLOYER_PUBLIC_PROFILE.STUDENT_VIEW_LOADED]: (state, action: PayloadAction<PersonModel>) => {
    state.employerProfileViewStudentLoading = false;
    state.employerProfileViewStudentError = undefined;
    state.employerProfileViewStudent = action.payload;
  },
  [MY_STUDENTS.LOAD]: (state) => {
    state.myStudentsResultLoading = true;
    state.myStudentsResultError = undefined;
  },
  [MY_STUDENTS.ERROR]: (state, action: PayloadAction<string>) => {
    state.myStudentsResultLoading = false;
    state.myStudentsResultError = action.payload;
  },
  [MY_STUDENTS.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.myStudentsResultLoading = false;
    state.myStudentsResultError = undefined;
    state.myStudentsResult = action.payload;
  }
});

export default studentsReducer;
