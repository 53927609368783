export class StateModel implements IStateModel {
  title?: string | undefined;
  shortTitle?: string | undefined;

  constructor(data?: IStateModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.title = Data["title"];
      this.shortTitle = Data["shortTitle"];
    }
  }

  static fromJS(data: any): StateModel {
    data = typeof data === 'object' ? data : {};
    let result = new StateModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["title"] = this.title;
    data["shortTitle"] = this.shortTitle;
    return data;
  }
}

export interface IStateModel {
  title?: string | undefined;
  shortTitle?: string | undefined;
}