import {IMessagingState} from './Reducer';
import {createDraftSafeSelector, createSelector} from '@reduxjs/toolkit';
import {WritableDraft} from 'immer/dist/types/types-external';
import {differenceInMilliseconds} from 'date-fns';

export const selectMessagingState = (state: { messaging: IMessagingState }) => state.messaging;

// Groups notifications (right panel)
export const selectGroupNotificationList = createSelector(
  selectMessagingState,
  (subState) => subState.groupsNotificationList
);
export const selectGroupsNotificationError = createSelector(
  selectMessagingState,
  (subState) => subState.groupsNotificationError
);
export const selectGroupsNotificationLoading = createSelector(
  selectMessagingState,
  (subState) => subState.groupsNotificationLoading
);
export const selectGroupsNotificationRefreshing = createSelector(
  selectMessagingState,
  (subState) => subState.groupsNotificationRefreshing
);

// Groups
export const selectGroupList = createSelector(
  selectMessagingState,
  (subState) => subState.groupsList
);
export const selectGroupsError = createSelector(
  selectMessagingState,
  (subState) => subState.groupsError
);
export const selectGroupsLoading = createSelector(
  selectMessagingState,
  (subState) => subState.groupsLoading
);
export const selectGroupsRefreshing = createSelector(
  selectMessagingState,
  (subState) => subState.groupsRefreshing
);
export const selectGroupThreads = createSelector(
  selectMessagingState,
  (subState) => subState.groupsThreadsList
);
export const selectGroupThreadsError = createSelector(
  selectMessagingState,
  (subState) => subState.groupsThreadsError
);
export const selectGroupThreadsLoading = createSelector(
  selectMessagingState,
  (subState) => subState.groupsThreadsLoading
);
// Summary
export const selectNotificationsSummary = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsSummary
);
export const selectNotificationsSummaryLoading = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsLoading
);
export const selectNotificationsSummaryError = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsError
);
export const selectNotificationsSummaryRefreshing = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsRefreshing
);
// Feed
export const selectMessageSearchParams = createSelector(
  selectMessagingState,
  (subState) => subState.messageSearch
);
const selectMessageListState = createSelector(
  selectMessagingState,
  (subState) => subState.messageList
);
export const selectMessageList = createSelector(
  selectMessageListState,
  (subState) => {
    if (subState) {
      const defaultDate = new Date(1900, 1, 1);
      return [...subState].sort((a, b) => differenceInMilliseconds(a.dtCreated??defaultDate, b.dtCreated ??defaultDate))
    } else {
      return [];
    }
  }
);

export const selectMessageListIsFullHistory = createSelector(
  selectMessagingState,
  (subState) => subState.messageListIsFullHistory
);
export const selectMessageListLoading = createSelector(
  selectMessagingState,
  (subState) => subState.messageListLoading
);
export const selectMessageListRefreshing = createSelector(
  selectMessagingState,
  (subState) => subState.messageListRefreshing
);
export const selectMessageListError = createSelector(
  selectMessagingState,
  (subState) => subState.messageListError
);
export const selectMessageListHistoryLoading = createSelector(
  selectMessagingState,
  (subState) => subState.messageListHistoryLoading
);
// ActionItemMessage
export const selectMessageSaving = createSelector(
  selectMessagingState,
  (subState) => subState.messageSaving
);
export const selectMessageError = createSelector(
  selectMessagingState,
  (subState) => subState.messageError
);
// Thread Metadata
export const selectThreadMetadata = createSelector(
  selectMessagingState,
  (subState) => subState.threadMetadata
);
export const selectThreadMetadataLoading = createSelector(
  selectMessagingState,
  (subState) => subState.threadMetadataLoading
);
export const selectThreadMetadataError = createSelector(
  selectMessagingState,
  (subState) => subState.threadMetadataError
);
export const selectThread = createSelector(
  selectMessagingState,
  (subState) => subState.thread
);
export const selectThreadLoading = createSelector(
  selectMessagingState,
  (subState) => subState.threadLoading
);
export const selectThreadError = createSelector(
  selectMessagingState,
  (subState) => subState.threadError
);
// Drafts
export const selectMessageDraft = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraft
);
export const selectMessageDraftLoading = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraftLoading
);
export const selectMessageDraftError = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraftError
);
export const selectMessageDraftSaving = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraftSaving
);
export const selectMessageDraftSaveError = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraftSaveError
);
export const selectMessageDraftIds = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraftIds
);
export const selectMessageDraftIdsError = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraftIdsError
);
export const selectMessageDraftIdsLoading = createSelector(
  selectMessagingState,
  (subState) => subState.messageDraftIdsLoading
);

// region Threads for dashboard (task management)
export const  selectTaskThreads = createSelector(
  selectMessagingState,
  (subState) => subState.taskThreadsList
);
export const  selectTaskThreadsError = createSelector(
  selectMessagingState,
  (subState) => subState.taskThreadsError
);
export const  selectTaskThreadsLoading = createSelector(
  selectMessagingState,
  (subState) => subState.taskThreadsLoading
);
// endregion