import {AddressViewModel} from './AddressViewModel';
import {ClientDetailModel} from './ClientDetailModel';
import {PartnerDetailModel} from './PartnerDetailModel';
import {BrandModel} from './BrandModel';
import {CompanyContactModel} from './CompanyContactModel';
import {AdvisorUserModel} from './AdvisorUserModel';
import {EducationDetailModel} from './EducationDetailModel';

export class CompanyModel implements ICompanyModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  abn?: string | undefined;
  acn?: string | undefined;
  companyNumber?: number | undefined;
  locationAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  phoneNumbers?: string[] | undefined;
  isActive?: boolean | undefined;
  isPartner?: boolean | undefined;
  isClient?: boolean | undefined;
  isEducation?: boolean | undefined;
  clientDetail?: ClientDetailModel | undefined;
  partnerDetail?: PartnerDetailModel | undefined;
  educationDetail?: EducationDetailModel | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  brandId?: string | undefined;
  brand?: BrandModel | undefined;
  contacts?: CompanyContactModel[] | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByPersonId?: string | undefined;
  workShadowHost?: boolean | undefined;

  constructor(data?: ICompanyModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.abn = Data["abn"];
      this.acn = Data["acn"];
      this.companyNumber = Data["companyNumber"];
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.quickNotes = Data["quickNotes"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(item);
      }
      this.isActive = Data["isActive"];
      this.isPartner = Data["isPartner"];
      this.isClient = Data["isClient"];
      this.isEducation = Data["isEducation"];
      this.clientDetail = Data["clientDetail"] ? ClientDetailModel.fromJS(Data["clientDetail"]) : <any>undefined;
      this.partnerDetail = Data["partnerDetail"] ? PartnerDetailModel.fromJS(Data["partnerDetail"]) : <any>undefined;
      this.educationDetail = Data["educationDetail"] ? EducationDetailModel.fromJS(Data["educationDetail"]) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(AdvisorUserModel.fromJS(item));
      }
      this.brandId = Data["brandId"];
      this.brand = Data["brand"] ? BrandModel.fromJS(Data["brand"]) : <any>undefined;
      if (Array.isArray(Data["contacts"])) {
        this.contacts = [] as any;
        for (let item of Data["contacts"])
          this.contacts!.push(CompanyContactModel.fromJS(item));
      }
      this.isDeleted = Data["isDeleted"];
      this.dateDeleted = Data["dateDeleted"] ? new Date(Data["dateDeleted"].toString()) : <any>undefined;
      this.deletedByPersonId = Data["deletedByPersonId"];
      this.workShadowHost = Data["workShadowHost"];
    }
  }

  static fromJS(data: any): CompanyModel {
    data = typeof data === 'object' ? data : {};
    let result = new CompanyModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["abn"] = this.abn;
    data["acn"] = this.acn;
    data["companyNumber"] = this.companyNumber;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["quickNotes"] = this.quickNotes;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item);
    }
    data["isActive"] = this.isActive;
    data["isPartner"] = this.isPartner;
    data["isClient"] = this.isClient;
    data["isEducation"] = this.isEducation;
    data["clientDetail"] = this.clientDetail ? this.clientDetail.toJSON() : <any>undefined;
    data["partnerDetail"] = this.partnerDetail ? this.partnerDetail.toJSON() : <any>undefined;
    data["educationDetail"] = this.educationDetail ? this.educationDetail.toJSON() : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item.toJSON());
    }
    data["brandId"] = this.brandId;
    data["brand"] = this.brand ? this.brand.toJSON() : <any>undefined;
    if (Array.isArray(this.contacts)) {
      data["contacts"] = [];
      for (let item of this.contacts)
        data["contacts"].push(item.toJSON());
    }
    data["isDeleted"] = this.isDeleted;
    data["dateDeleted"] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
    data["deletedByPersonId"] = this.deletedByPersonId;
    data["workShadowHost"] = this.workShadowHost;
    return data;
  }
}

export interface ICompanyModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  abn?: string | undefined;
  acn?: string | undefined;
  companyNumber?: number | undefined;
  locationAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  phoneNumbers?: string[] | undefined;
  isActive?: boolean | undefined;
  isPartner?: boolean | undefined;
  isClient?: boolean | undefined;
  isEducation?: boolean | undefined;
  clientDetail?: ClientDetailModel | undefined;
  partnerDetail?: PartnerDetailModel | undefined;
  educationDetail?: EducationDetailModel | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  brandId?: string | undefined;
  brand?: BrandModel | undefined;
  contacts?: CompanyContactModel[] | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByPersonId?: string | undefined;
  workShadowHost?: boolean | undefined;
}