export class SubIndustryModel implements ISubIndustryModel {
  subIndustryId?: number | undefined;
  subIndustryName?: string | undefined;

  constructor(data?: ISubIndustryModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.subIndustryId = Data["subIndustryId"];
      this.subIndustryName = Data["subIndustryName"];
    }
  }

  static fromJS(data: any): SubIndustryModel {
    data = typeof data === 'object' ? data : {};
    let result = new SubIndustryModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["subIndustryId"] = this.subIndustryId;
    data["subIndustryName"] = this.subIndustryName;
    return data;
  }
}

export interface ISubIndustryModel {
  subIndustryId?: number | undefined;
  subIndustryName?: string | undefined;
}
