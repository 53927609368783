import React, {useContext} from 'react';
import AppContext from '../../../utility/AppContext';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import AppContextPropsType, {
  AppTheme,
} from '../../../../types/AppContextPropsType';
import {alpha} from '@mui/material/styles';
import {siteConfig} from '../../../../config';
import { grey } from '@mui/material/colors';
import GridContainer from "../../GridContainer";
import { Grid } from '@mui/material';
import {isBreakPointDown} from "../../../utility/Utils";

const useStyles = makeStyles((theme: AppTheme) => ({
  footer: {
    position: 'fixed',
    left: 'auto',
    bottom: 0,
    zIndex: 99,
    margin: '0',
    width: '100%',
    marginTop: 0,
    paddingTop: 0,
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    color: grey[500],
    fontSize: '0.8rem',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100vw - 4rem)',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100vw - 5rem)',
    },
    '& .footerContainer': {
      padding: '2px 20px',
      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 62,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 32,
        paddingRight: 62,
      },
      [theme.breakpoints.up('xl')]: {
        padding: '2px 22px',
      },
    },
  },
  btnRoot: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  rightSideBox: {
    display: 'block',
    [theme.breakpoints.only('xl')]: {
      marginRight: '90px',
    },
    [theme.breakpoints.only('lg')]: {
      marginRight: '100px',
    },
    [theme.breakpoints.only('md')]: {
      marginRight: '135px',
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: '75px',
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: '10px',
    },
  }
}));

interface AppFixedFooterProps {}

const AppFixedFooter: React.FC<AppFixedFooterProps> = () => {
  const {footer, footerType} = useContext<AppContextPropsType>(AppContext);

  const classes = useStyles();

  // @ts-ignore
  const currentVersion = global.appVersion;

  return (
    <>
      {footer && footerType === 'fixed' ? (
        <Box className={clsx(classes.footer, 'footer fixed-footer')}>
          <GridContainer direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid container direction="row" justifyContent="flex-end">
              {siteConfig.debugFooter && <>
                <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'block', sm: 'none' } }}>(size: xs)</Box>
                <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', sm: 'block', md: 'none' } }}>(size: sm)</Box>
                <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', md: 'block', lg: 'none' } }}>(size: md)</Box>
                <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', lg: 'block', xl: 'none' } }}>(size: lg)</Box>
                <Box sx={{ fontWeight: 'bold', marginRight: '10px', display: { xs: 'none', xl: 'block' } }}>(size: xl)</Box>
              </>}
              <Box sx={{marginRight: '10px', display: 'block'}}>{isBreakPointDown('sm') ? 'Ver:' : 'Current version:'} {currentVersion}</Box>
              <Box className={classes.rightSideBox}>Copyright CareerTrackers Pty Ltd 2022</Box>
            </Grid>
          </GridContainer>
        </Box>
      ) : null}
    </>
  );
};

export default AppFixedFooter;
