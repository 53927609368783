import {PhoneModel} from './PhoneModel';
import {AddressViewModel} from './AddressViewModel';
import {WorkShadowStatusEnum} from './Enums';
import {WorkShadowOnboardingModel} from './WorkShadowOnboardingModel';
import {WorkShadowOnboardingHistoryModel} from './WorkShadowOnboardingHistoryModel';
import {WorkShadowTagModel} from './WorkShadowTagModel';
import {formatDate} from '../../@template/helpers/datetimes';
import {StudentCareerQuestionnaireModel} from './StudentCareerQuestionnaireModel';
import {SeasonalStudentModel} from './SeasonalStudentModel';
import {EmployerExperienceModel, HonourAndAwardsModel} from '../requests/StudentProfileEditModel';



export class WorkShadowDetailModel implements IWorkShadowDetailModel {
  onboardingWorkShadowSettingId?: string | undefined;
  workShadowOnboardingSetting?: WorkShadowOnboardingModel | undefined;
  workShadowOnboardingHistory?: WorkShadowOnboardingHistoryModel[] | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  workShadowStatus?: WorkShadowStatusEnum | undefined;
  workShadowTags?: WorkShadowTagModel[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  emergencyFirstName?: string | undefined;
  emergencyLastName?: string | undefined;
  emergencyPhoneNumbers?: PhoneModel[] | undefined;
  emergencyAddress?: AddressViewModel | undefined;
  emergencyRelationship?: string | undefined;
  emergencyEmail?: string | undefined;
  parentFirstName?: string | undefined;
  parentLastName?: string | undefined;
  parentPhoneNumbers?: PhoneModel[] | undefined;
  parentAddress?: AddressViewModel | undefined;
  parentRelationship?: string | undefined;
  parentEmail?: string | undefined;
  isParentEmergency?: boolean | undefined;
  isAddressSame?: boolean | undefined;
  degreePoolId?: string[] | undefined;
  highSchool?: string | undefined;
  highSchoolComplete?: number | undefined;
  highSchoolAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  workShadowSource?: string | undefined;
  dateAlumniSet?: Date | undefined;
  alumniSetByPersonId?: string | undefined;
  alumniNotes?: string | undefined;
  cvUrl?: string | undefined;
  publicProfile?: string | undefined;
  isHighSchoolProgram?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  interviewToolkitUrl?: string | undefined;
  beforeMatchedIsTravelling?: boolean | undefined;
  beforeMatchedTravelLocations?: AddressViewModel[] | undefined;
  careerQuestionnaire?: StudentCareerQuestionnaireModel | undefined;
  seasonalStudent?: SeasonalStudentModel[] | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;

  constructor(data?: IWorkShadowDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.onboardingWorkShadowSettingId = Data["onboardingWorkShadowSettingId"];
      this.workShadowOnboardingSetting = Data["workShadowOnboardingSetting"] ? WorkShadowOnboardingModel.fromJS(Data["workShadowOnboardingSetting"]) : <any>undefined;
      if (Array.isArray(Data["workShadowOnboardingHistory"])) {
        this.workShadowOnboardingHistory = [] as any;
        for (let item of Data["workShadowOnboardingHistory"])
          this.workShadowOnboardingHistory!.push(WorkShadowOnboardingHistoryModel.fromJS(item));
      }
      this.onboardingStepsComplete = Data["onboardingStepsComplete"];
      this.onboardingPercent = Data["onboardingPercent"];
      this.workShadowStatus = Data["workShadowStatus"];
      if (Array.isArray(Data["workShadowTags"])) {
        this.workShadowTags = [] as any;
        for (let item of Data["workShadowTags"])
          this.workShadowTags!.push(WorkShadowTagModel.fromJS(item));
      }
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.emergencyFirstName = Data["emergencyFirstName"];
      this.emergencyLastName = Data["emergencyLastName"];
      if (Array.isArray(Data["emergencyPhoneNumbers"])) {
        this.emergencyPhoneNumbers = [] as any;
        for (let item of Data["emergencyPhoneNumbers"])
          this.emergencyPhoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.emergencyAddress = Data["emergencyAddress"] ? AddressViewModel.fromJS(Data["emergencyAddress"]) : <any>undefined;
      this.emergencyRelationship = Data["emergencyRelationship"];
      this.emergencyEmail = Data["emergencyEmail"];
      this.parentFirstName = Data["parentFirstName"];
      this.parentLastName = Data["parentLastName"];
      if (Array.isArray(Data["parentPhoneNumbers"])) {
        this.parentPhoneNumbers = [] as any;
        for (let item of Data["parentPhoneNumbers"])
          this.parentPhoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.parentAddress = Data["parentAddress"] ? AddressViewModel.fromJS(Data["parentAddress"]) : <any>undefined;
      this.parentRelationship = Data["parentRelationship"];
      this.parentEmail = Data["parentEmail"];
      this.isParentEmergency = Data["isParentEmergency"];
      this.isAddressSame = Data["isAddressSame"];
      if (Array.isArray(Data["degreePoolId"])) {
        this.degreePoolId = [] as any;
        for (let item of Data["degreePoolId"])
          this.degreePoolId!.push(item);
      }
      this.highSchool = Data["highSchool"];
      this.highSchoolComplete = Data["highSchoolComplete"];
      this.highSchoolAddress = Data["highSchoolAddress"] ? AddressViewModel.fromJS(Data["highSchoolAddress"]) : <any>undefined;
      this.quickNotes = Data["quickNotes"];
      this.workShadowSource = Data["workShadowSource"];
      this.dateAlumniSet = Data["dateAlumniSet"] ? new Date(Data["dateAlumniSet"].toString()) : <any>undefined;
      this.alumniSetByPersonId = Data["alumniSetByPersonId"];
      this.alumniNotes = Data["alumniNotes"];
      this.cvUrl = Data["cvUrl"];
      this.publicProfile = Data["publicProfile"];
      this.isHighSchoolProgram = Data["isHighSchoolProgram"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.interviewToolkitUrl = Data["interviewToolkitUrl"];
      this.beforeMatchedIsTravelling = Data["beforeMatchedIsTravelling"];
      if (Array.isArray(Data["beforeMatchedTravelLocations"])) {
        this.beforeMatchedTravelLocations = [] as any;
        for (let item of Data["beforeMatchedTravelLocations"])
          this.beforeMatchedTravelLocations!.push(AddressViewModel.fromJS(item));
      }
      this.careerQuestionnaire = Data["careerQuestionnaire"] ? StudentCareerQuestionnaireModel.fromJS(Data["careerQuestionnaire"]) : <any>undefined;
      if (Array.isArray(Data["seasonalStudent"])) {
        this.seasonalStudent = [] as any;
        for (let item of Data["seasonalStudent"])
          this.seasonalStudent!.push(SeasonalStudentModel.fromJS(item));
      }
      if (Array.isArray(Data["employerExperiences"])) {
        this.employerExperiences = [] as any;
        for (let item of Data["employerExperiences"])
          this.employerExperiences!.push(EmployerExperienceModel.fromJS(item));
      }
      if (Array.isArray(Data["employerHonourAwards"])) {
        this.employerHonourAwards = [] as any;
        for (let item of Data["employerHonourAwards"])
          this.employerHonourAwards!.push(HonourAndAwardsModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): WorkShadowDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new WorkShadowDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["onboardingWorkShadowSettingId"] = this.onboardingWorkShadowSettingId;
    data["workShadowOnboardingSetting"] = this.workShadowOnboardingSetting ? this.workShadowOnboardingSetting.toJSON() : <any>undefined;
    if (Array.isArray(this.workShadowOnboardingHistory)) {
      data["workShadowOnboardingHistory"] = [];
      for (let item of this.workShadowOnboardingHistory)
        data["workShadowOnboardingHistory"].push(item.toJSON());
    }
    data["onboardingStepsComplete"] = this.onboardingStepsComplete;
    data["onboardingPercent"] = this.onboardingPercent;
    data["workShadowStatus"] = this.workShadowStatus;
    if (Array.isArray(this.workShadowTags)) {
      data["workShadowTags"] = [];
      for (let item of this.workShadowTags)
        data["workShadowTags"].push(item.toJSON());
    }
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["emergencyFirstName"] = this.emergencyFirstName;
    data["emergencyLastName"] = this.emergencyLastName;
    if (Array.isArray(this.emergencyPhoneNumbers)) {
      data["emergencyPhoneNumbers"] = [];
      for (let item of this.emergencyPhoneNumbers)
        data["emergencyPhoneNumbers"].push(item.toJSON());
    }
    data["emergencyAddress"] = this.emergencyAddress ? this.emergencyAddress.toJSON() : <any>undefined;
    data["emergencyRelationship"] = this.emergencyRelationship;
    data["emergencyEmail"] = this.emergencyEmail;
    data["parentFirstName"] = this.parentFirstName;
    data["parentLastName"] = this.parentLastName;
    if (Array.isArray(this.parentPhoneNumbers)) {
      data["parentPhoneNumbers"] = [];
      for (let item of this.parentPhoneNumbers)
        data["parentPhoneNumbers"].push(item.toJSON());
    }
    data["parentAddress"] = this.parentAddress ? this.parentAddress.toJSON() : <any>undefined;
    data["parentRelationship"] = this.parentRelationship;
    data["parentEmail"] = this.parentEmail;
    data["isParentEmergency"] = this.isParentEmergency;
    data["isAddressSame"] = this.isAddressSame;
    if (Array.isArray(this.degreePoolId)) {
      data["degreePoolId"] = [];
      for (let item of this.degreePoolId)
        data["degreePoolId"].push(item);
    }
    data["highSchool"] = this.highSchool;
    data["highSchoolComplete"] = this.highSchoolComplete;
    data["highSchoolAddress"] = this.highSchoolAddress ? this.highSchoolAddress.toJSON() : <any>undefined;
    data["quickNotes"] = this.quickNotes;
    data["workShadowSource"] = this.workShadowSource;
    data["dateAlumniSet"] = this.dateAlumniSet ? this.dateAlumniSet.toISOString() : <any>undefined;
    data["alumniSetByPersonId"] = this.alumniSetByPersonId;
    data["alumniNotes"] = this.alumniNotes;
    data["cvUrl"] = this.cvUrl;
    data["publicProfile"] = this.publicProfile;
    data["isHighSchoolProgram"] = this.isHighSchoolProgram;
    data["isWorkShadow"] = this.isWorkShadow;
    data["interviewToolkitUrl"] = this.interviewToolkitUrl;
    data["beforeMatchedIsTravelling"] = this.beforeMatchedIsTravelling;
    if (Array.isArray(this.beforeMatchedTravelLocations)) {
      data["beforeMatchedTravelLocations"] = [];
      for (let item of this.beforeMatchedTravelLocations)
        data["beforeMatchedTravelLocations"].push(item.toJSON());
    }
    data["careerQuestionnaire"] = this.careerQuestionnaire ? this.careerQuestionnaire.toJSON() : <any>undefined;
    if (Array.isArray(this.seasonalStudent)) {
      data["seasonalStudent"] = [];
      for (let item of this.seasonalStudent)
        data["seasonalStudent"].push(item.toJSON());
    }
    if (Array.isArray(this.employerExperiences)) {
      data["employerExperiences"] = [];
      for (let item of this.employerExperiences)
        data["employerExperiences"].push(item.toJSON());
    }
    if (Array.isArray(this.employerHonourAwards)) {
      data["employerHonourAwards"] = [];
      for (let item of this.employerHonourAwards)
        data["employerHonourAwards"].push(item.toJSON());
    }
    return data;
  }
}

export interface IWorkShadowDetailModel {
  onboardingWorkShadowSettingId?: string | undefined;
  workShadowOnboardingSetting?: WorkShadowOnboardingModel | undefined;
  workShadowOnboardingHistory?: WorkShadowOnboardingHistoryModel[] | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  workShadowStatus?: WorkShadowStatusEnum | undefined;
  workShadowTags?: WorkShadowTagModel[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  emergencyFirstName?: string | undefined;
  emergencyLastName?: string | undefined;
  emergencyPhoneNumbers?: PhoneModel[] | undefined;
  emergencyAddress?: AddressViewModel | undefined;
  emergencyRelationship?: string | undefined;
  emergencyEmail?: string | undefined;
  parentFirstName?: string | undefined;
  parentLastName?: string | undefined;
  parentPhoneNumbers?: PhoneModel[] | undefined;
  parentAddress?: AddressViewModel | undefined;
  parentRelationship?: string | undefined;
  parentEmail?: string | undefined;
  isParentEmergency?: boolean | undefined;
  isAddressSame?: boolean | undefined;
  degreePoolId?: string[] | undefined;
  highSchool?: string | undefined;
  highSchoolComplete?: number | undefined;
  highSchoolAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  workShadowSource?: string | undefined;
  dateAlumniSet?: Date | undefined;
  alumniSetByPersonId?: string | undefined;
  alumniNotes?: string | undefined;
  cvUrl?: string | undefined;
  publicProfile?: string | undefined;
  isHighSchoolProgram?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  interviewToolkitUrl?: string | undefined;
  beforeMatchedIsTravelling?: boolean | undefined;
  beforeMatchedTravelLocations?: AddressViewModel[] | undefined;
  careerQuestionnaire?: StudentCareerQuestionnaireModel | undefined;
  seasonalStudent?: SeasonalStudentModel[] | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
}