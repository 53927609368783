export class PlacementStepView implements IPlacementStepView {
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  dateEvent?: Date | undefined;
  stepId?: string | undefined;
  stepTitle?: string | undefined;
  eventPersonId?: string | undefined;
  additionalInfo?: string | undefined;
  fileIds?: string[] | undefined;
  skipped?: boolean | undefined;

  constructor(data?: IPlacementStepView) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.dateEvent = Data["dateEvent"] ? new Date(Data["dateEvent"].toString()) : <any>undefined;
      this.stepId = Data["stepId"];
      this.stepTitle = Data["stepTitle"];
      this.eventPersonId = Data["eventPersonId"];
      this.additionalInfo = Data["additionalInfo"];
      if (Array.isArray(Data["fileIds"])) {
        this.fileIds = [] as any;
        for (let item of Data["fileIds"])
          this.fileIds!.push(item);
      }
      this.skipped = Data["skipped"];
    }
  }

  static fromJS(data: any): PlacementStepView {
    data = typeof data === 'object' ? data : {};
    let result = new PlacementStepView();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["dateEvent"] = this.dateEvent ? this.dateEvent.toISOString() : <any>undefined;
    data["stepId"] = this.stepId;
    data["stepTitle"] = this.stepTitle;
    data["eventPersonId"] = this.eventPersonId;
    data["additionalInfo"] = this.additionalInfo;
    if (Array.isArray(this.fileIds)) {
      data["fileIds"] = [];
      for (let item of this.fileIds)
        data["fileIds"].push(item);
    }
    data["skipped"] = this.skipped;
    return data;
  }
}

export interface IPlacementStepView {
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  dateEvent?: Date | undefined;
  stepId?: string | undefined;
  stepTitle?: string | undefined;
  eventPersonId?: string | undefined;
  additionalInfo?: string | undefined;
  fileIds?: string[] | undefined;
  skipped?: boolean | undefined;
}
