import {ICommonLoadActionType, ICommonSaveActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {StudentUpdatesSearchPaged} from '../../types/views/StudentUpdatesSearchPaged';

export interface IChangeRequestActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const STUDENT_CHANGE_REQUEST: IChangeRequestActionTypes = {
  LOAD: 'studentChangeRequest/load',
  LOADED: 'studentChangeRequest/loaded',
  ERROR: 'studentChangeRequest/error',
  REFRESH: 'studentChangeRequest/refresh',
}

export const CONTACT_CHANGE_REQUEST: IChangeRequestActionTypes = {
  LOAD: 'contactChangeRequest/load',
  LOADED: 'contactChangeRequest/loaded',
  ERROR: 'contactChangeRequest/error',
  REFRESH: 'contactChangeRequest/refresh',
}

export const studentChangeRequestLoad = createAction(STUDENT_CHANGE_REQUEST.LOAD);
export const studentChangeRequestLoadSuccess = createAction<StudentUpdatesSearchPaged>(STUDENT_CHANGE_REQUEST.LOADED);
export const studentChangeRequestLoadError = createAction<string>(STUDENT_CHANGE_REQUEST.ERROR);
export const studentChangeRequestRefresh = createAction(STUDENT_CHANGE_REQUEST.REFRESH);

export const contactChangeRequestLoad = createAction(CONTACT_CHANGE_REQUEST.LOAD);
export const contactChangeRequestLoadSuccess = createAction<StudentUpdatesSearchPaged>(CONTACT_CHANGE_REQUEST.LOADED);
export const contactChangeRequestLoadError = createAction<string>(CONTACT_CHANGE_REQUEST.ERROR);
export const contactChangeRequestRefresh = createAction(CONTACT_CHANGE_REQUEST.REFRESH);