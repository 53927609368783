import makeStyles from '@mui/styles/makeStyles';
import {ThemeMode} from '../../../../shared/constants/AppEnums';
import {AppTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: AppTheme) => {
  return {
    navItem: {
      height: 40,
      marginTop: 2,
      marginBottom: 2,
      paddingLeft:(props: {level: number; themeMode: ThemeMode}) => 24 + 40 * props.level,
      paddingRight: 12,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      fontSize: 14,
      cursor: 'pointer',
      textDecoration: 'none!important',

      [theme.breakpoints.up('lg')]: {
        // fontSize: 16,
        marginTop: 4,
        marginBottom: 4,
      },
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
    },
  };
});

export default useStyles;
