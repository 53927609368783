export class ThreadExcludingTaskListModel implements IThreadExcludingTaskListModel {
  threadList?: ThreadExcludingTaskModel[] | undefined;

  constructor(data?: IThreadExcludingTaskListModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["threadList"])) {
        this.threadList = [] as any;
        for (let item of Data["threadList"])
          this.threadList!.push(ThreadExcludingTaskModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ThreadExcludingTaskListModel {
    data = typeof data === 'object' ? data : {};
    let result = new ThreadExcludingTaskListModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.threadList)) {
      data["threadList"] = [];
      for (let item of this.threadList)
        data["threadList"].push(item.toJSON());
    }
    return data;
  }
}

export interface IThreadExcludingTaskListModel {
  threadList?: ThreadExcludingTaskModel[] | undefined;
}

export class ThreadExcludingTaskModel implements IThreadExcludingTaskModel {
  actionReference?: string | undefined;
  eventSubCategory?: string | undefined;

  constructor(data?: IThreadExcludingTaskModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.actionReference = Data["actionReference"];
      this.eventSubCategory = Data["eventSubCategory"];
    }
  }

  static fromJS(data: any): ThreadExcludingTaskModel {
    data = typeof data === 'object' ? data : {};
    let result = new ThreadExcludingTaskModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["actionReference"] = this.actionReference;
    data["eventSubCategory"] = this.eventSubCategory;
    return data;
  }
}

export interface IThreadExcludingTaskModel {
  actionReference?: string | undefined;
  eventSubCategory?: string | undefined;
}