export class RefFieldsModel implements IRefFieldsModel {
  refName?: string | undefined;
  refValue?: string | undefined;

  constructor(data?: IRefFieldsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.refName = Data["refName"];
      this.refValue = Data["refValue"];
    }
  }

  static fromJS(data: any): RefFieldsModel {
    data = typeof data === 'object' ? data : {};
    let result = new RefFieldsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["refName"] = this.refName;
    data["refValue"] = this.refValue;
    return data;
  }
}

export interface IRefFieldsModel {
  refName?: string | undefined;
  refValue?: string | undefined;
}
