import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  EMPLOYER_PUBLIC_PROFILE,
  employerProfileSaveError,
  employerProfileSaveSuccess,
  employerProfileViewStudentLoad,
  employerProfileViewStudentLoadError,
  employerProfileViewStudentLoadSuccess,
  MY_STUDENTS,
  myStudentsError,
  myStudentsSuccess,
  PLACEMENT_SEARCH,
  placementSearchError,
  placementSearchSuccess,
  STUDENT,
  STUDENT_ACHIEVEMENTS,
  STUDENT_LEARNING_CONTRACTS,
  STUDENT_OPPORTUNITIES,
  STUDENT_PLACEMENTS,
  STUDENT_SEARCH,
  STUDENT_STUDENT_POOLS,
  studentAchievementsLoadError,
  studentAchievementsLoadSuccess,
  studentLearningContractsListError,
  studentLearningContractsListSuccess,
  studentLoadError,
  studentLoadSuccess,
  studentOpportunitiesLoadError,
  studentOpportunitiesLoadSuccess,
  studentPlacementsLoadError,
  studentPlacementsLoadSuccess,
  studentPoolsLoadError,
  studentPoolsLoadSuccess,
  studentSaveError,
  studentSaveSuccess,
  studentsSearchError,
  studentsSearchSuccess,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {StudentEditModel} from '../../types/requests/StudentEditModel';
import {ICommonSavePayload} from '../Common.actions';
import {PersonModel} from '../../types/models/PersonModel';
import {StudentPoolItem} from '../../types/views/StudentPoolItem';
import {StudentAchievementModel} from '../../types/models/StudentAchievementModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {StudentSearchParamsModel} from '../../types/requests/StudentSearchParamsModel';
import {PlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {InternshipSearchParams} from '../../types/requests/InternshipSearchParams';
import {StudentInternshipSearchPaged} from '../../types/views/StudentInternshipSearchPaged';
import {EventItemViewModel} from '../../types/models/EventItemViewModel';
import {SubCategoryListModel} from '../../types/requests/SubCategoryListModel';
import {StudentProfileEditModel} from '../../types/requests/StudentProfileEditModel';
import {StudentOpportunityListView} from '../../types/views/StudentOpportunityListView';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {RollbackStudentEditModel} from '../../types/requests/RollbackStudentEditModel';

function* LoadStudent(id: string) {
  console.log('SAGA - studentSaga - LoadStudent');
  try {
    const res = yield authApi.get(`${apiConfig.coreStudentPath}/${id}`);
    const student = PersonModel.fromJS(res.data);
    yield put(studentLoadSuccess(student));
  } catch (error) {
    yield put(studentLoadError(error));
  }
}

function* SaveStudent({ id, submission }: ICommonSavePayload<StudentEditModel>) {
  console.log('SAGA - studentSaga - SaveStudent');
  const studentPath = id ? `${apiConfig.coreStudentPath}/${id}` : apiConfig.coreStudentPath;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: studentPath,
      data: submission
    });
    const student = PersonModel.fromJS(res.data);
    yield put(studentSaveSuccess(student));
  } catch (error) {
    yield put(studentSaveError(error));
  }
}

function* RollbackStudent({ id, submission }: ICommonSavePayload<RollbackStudentEditModel>) {
  console.log('SAGA - studentSaga - RollbackStudent');
  const studentPath = `${apiConfig.coreStudentPath}/${id}/rollback`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: studentPath,
      data: submission
    });
    const student = PersonModel.fromJS(res.data);
    yield put(studentSaveSuccess(student));
  } catch (error) {
    yield put(studentSaveError(error));
  }
}

function* LoadStudentOpportunities(personId: string) {
  console.log('SAGA - studentSaga - LoadStudentOpportunities');
  try {
    const url = `${apiConfig.coreStudentPath}/${personId}/opportunities`;
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: url,
    });
    const opportunities = res.data.map((x: any) => StudentOpportunityListView.fromJS(x));

    yield put(studentOpportunitiesLoadSuccess(opportunities));
  } catch (error) {
    yield put(studentOpportunitiesLoadError(error));
  }
}

function* LoadStudentPools(id: string) {
  console.log('SAGA - studentSaga - LoadStudentPools');
  try {
    const res = yield authApi.get(`${apiConfig.coreUrl}${apiConfig.coreStudentPath}/${id}/pools`);
    const pools = res.data.map((x: any) => StudentPoolItem.fromJS(x));
    yield put(studentPoolsLoadSuccess(pools));
  } catch (error) {
    yield put(studentPoolsLoadError(error));
  }
}

function* LoadStudentPlacements(id: string) {
  console.log('SAGA - studentSaga - LoadStudentPlacements');
  try {
    const res = yield authApi.get(`${apiConfig.coreUrl}${apiConfig.coreStudentPath}/${id}/placements`);
    const placements = res.data.map((x: any) => PlacementDetailModel.fromJS(x));
    yield put(studentPlacementsLoadSuccess(placements));
  } catch (error) {
    yield put(studentPlacementsLoadError(error));
  }
}

function* LoadStudentAchievements(id: string) {
  console.log('SAGA - studentSaga - LoadStudentAchievements');
  try {
    const res = yield authApi.get(`${apiConfig.coreUrl}${apiConfig.coreStudentPath}/${id}/achievements`);
    const student = res.data.map((x: any) => StudentAchievementModel.fromJS(x));
    yield put(studentAchievementsLoadSuccess(student));
  } catch (error) {
    yield put(studentAchievementsLoadError(error));
  }
}

function* LoadStudentLearningContracts(studentId: string) {
  console.log('SAGA - studentSaga - LoadStudentLearningContracts');
  try {
    const submission = SubCategoryListModel.fromJS({
      ownerId: 'internal',
      subCategories: ['SubmitLearningContract', 'SubmitPreUniversityLearningContract']
    })

    const url = `${apiConfig.messagingEventsPath}/thread/s_${studentId}/by-sub-category-type`;
    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'PUT',
      url: url,
      data: submission
    });
    const student = res.data.map((x: any) => EventItemViewModel.fromJS(x));
    yield put(studentLearningContractsListSuccess(student));
  } catch (error) {
    yield put(studentLearningContractsListError(error));
  }
}

function* SearchStudents({ page, sort, searchParams }: ISearchRequest<StudentSearchParamsModel>) {
  console.log('SAGA - studentSaga - SearchStudents');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreStudentPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(studentsSearchSuccess(results));
  } catch (error) {
    yield put(studentsSearchError(error));
  }
}

function* MyStudents({ searchParams, page, pageSize }: ISearchRequest<StudentSearchParamsModel>) {
  try {
    const searchPath = `${apiConfig.coreStudentPath}/search?page=${page}&page_size=${pageSize ? pageSize:50}`;
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: searchPath,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(myStudentsSuccess(results));
  } catch (error) {
    yield put(myStudentsError(error));
  }
}

function* SearchPlacements({ page, sort, searchParams }: ISearchRequest<InternshipSearchParams>) {
  console.log('SAGA - studentSaga - SearchPlacements');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreStudentPath}/internships/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: StudentInternshipSearchPaged = StudentInternshipSearchPaged.fromJS(res.data);
    yield put(placementSearchSuccess(results));
  } catch (error) {
    yield put(placementSearchError(error));
  }
}

function* SaveEmployerProfile({ id, submission }: ICommonSavePayload<StudentProfileEditModel>) {
  console.log('SAGA - studentSaga - SaveEmployerProfile');
  const profilePath = `${apiConfig.coreStudentPath}/${id}/profile`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: profilePath,
      data: submission
    });
    const student = PersonModel.fromJS(res.data);
    yield put(employerProfileSaveSuccess(student));
  } catch (error) {
    yield put(employerProfileSaveError(error));
  }
}

function* LoadEmployerProfileViewStudent(id: string) {
  console.log('SAGA - studentSaga - LoadEmployerProfileViewStudent');
  try {
    const res = yield authApi.get(`${apiConfig.coreStudentPath}/${id}`);
    const student = PersonModel.fromJS(res.data);
    yield put(employerProfileViewStudentLoadSuccess(student));
  } catch (error) {
    yield put(employerProfileViewStudentLoadError(error));
  }
}

export function* watchStudentLoad() {
  yield takeLatest(STUDENT.LOAD, ({ payload }: PayloadAction<string>) => LoadStudent(payload));
}

export function* watchStudentRefresh() {
  yield takeLatest(STUDENT.REFRESH, ({ payload }: PayloadAction<string>) => LoadStudent(payload));
}

export function* watchStudentSave() {
  yield takeLatest(STUDENT.SAVE, ({ payload }: PayloadAction<ICommonSavePayload<StudentEditModel>>) => SaveStudent(payload));
}

export function* watchStudentRollback() {
  yield takeLatest(STUDENT.ROLLBACK, ({ payload }: PayloadAction<ICommonSavePayload<RollbackStudentEditModel>>) => RollbackStudent(payload));
}

export function* watchStudentLoadOpportunities() {
  yield takeLatest(STUDENT_OPPORTUNITIES.LOAD, ({ payload }: PayloadAction<string>) => LoadStudentOpportunities(payload));
}
export function* watchStudentRefreshOpportunities() {
  yield takeLatest(STUDENT_OPPORTUNITIES.REFRESH, ({ payload }: PayloadAction<string>) => LoadStudentOpportunities(payload));
}

export function* watchStudentLoadPools() {
  yield takeLatest(STUDENT_STUDENT_POOLS.LOAD, ({ payload }: PayloadAction<string>) => LoadStudentPools(payload));
}
export function* watchStudentRefreshPools() {
  yield takeLatest(STUDENT_STUDENT_POOLS.REFRESH, ({ payload }: PayloadAction<string>) => LoadStudentPools(payload));
}

export function* watchStudentLoadPlacements() {
  yield takeLatest(STUDENT_PLACEMENTS.LOAD, ({ payload }: PayloadAction<string>) => LoadStudentPlacements(payload));
}
export function* watchStudentRefreshPlacements() {
  yield takeLatest(STUDENT_PLACEMENTS.REFRESH, ({ payload }: PayloadAction<string>) => LoadStudentPlacements(payload));
}

export function* watchStudentLoadLearningContracts() {
  yield takeLatest(STUDENT_LEARNING_CONTRACTS.LOAD, ({ payload }: PayloadAction<string>) => LoadStudentLearningContracts(payload));
}

export function* watchStudentRefreshLearningContracts() {
  yield takeLatest(STUDENT_LEARNING_CONTRACTS.REFRESH, ({ payload }: PayloadAction<string>) => LoadStudentLearningContracts(payload));
}

export function* watchStudentLoadAchievements() {
  yield takeLatest(STUDENT_ACHIEVEMENTS.LOAD, ({ payload }: PayloadAction<string>) => LoadStudentAchievements(payload));
}

export function* watchStudentSearch() {
  yield takeLatest(STUDENT_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<StudentSearchParamsModel>>) => SearchStudents(payload))
}

export function* watchStudentSearchRefresh() {
  yield takeLatest(STUDENT_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<StudentSearchParamsModel>>) => SearchStudents(payload))
}

export function* watchPlacementSearch() {
  yield takeLatest(PLACEMENT_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<InternshipSearchParams>>) => SearchPlacements(payload))
}

export function* watchPlacementSearchRefresh() {
  yield takeLatest(PLACEMENT_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<InternshipSearchParams>>) => SearchPlacements(payload))
}

export function* watchEmployerSave() {
  yield takeLatest(EMPLOYER_PUBLIC_PROFILE.SAVE, ({ payload }: PayloadAction<ICommonSavePayload<StudentProfileEditModel>>) => SaveEmployerProfile(payload));
}

export function* watchMyStudents(){
  yield takeLatest(MY_STUDENTS.LOAD, ({ payload }: PayloadAction<ISearchRequest<StudentSearchParamsModel>>) => MyStudents(payload))
}

export function* watchEmployerProfileViewStudentLoad(){
  yield takeLatest(EMPLOYER_PUBLIC_PROFILE.STUDENT_VIEW_LOAD, ({ payload }: PayloadAction<string>) => LoadEmployerProfileViewStudent(payload))
}

export default function* studentSaga() {
  yield all ([
    call(watchStudentLoad),
    call(watchStudentRefresh),
    call(watchStudentSave),
    call(watchStudentRollback),
    call(watchStudentLoadOpportunities),
    call(watchStudentRefreshOpportunities),
    call(watchStudentLoadPools),
    call(watchStudentRefreshPools),
    call(watchStudentLoadPlacements),
    call(watchStudentRefreshPlacements),
    call(watchStudentLoadAchievements),
    call(watchStudentLoadLearningContracts),
    call(watchStudentRefreshLearningContracts),
    call(watchStudentSearch),
    call(watchStudentSearchRefresh),
    call(watchPlacementSearch),
    call(watchPlacementSearchRefresh),
    call(watchEmployerSave),
    call(watchMyStudents),
    call(watchEmployerProfileViewStudentLoad),
  ])
}
