import {
  defaultForMailingListSearchParamsModel,
  MailingListSearchParamsModel,
} from '../../types/requests/MailingListSearchParamsModel';
import {
  defaultMailingListMessageSearchParamsModel,
  MailingListMessageSearchParamsModel,
} from '../../types/requests/MailingListMessageSearchParamsModel';
import {MailingListSearchPaged} from '../../types/views/MailingListSearchPaged';
import {MailingListModel} from '../../types/models/MailingListModel';
import {MailingListRecipientResultPaged} from '../../types/views/MailingListRecipientResultPaged';
import {MailingListMessageSearchPaged} from '../../types/views/MailingListMessageSearchPaged';
import {
  defaultRecipientSearchParamsModel,
  RecipientSearchParamsModel,
} from '../../types/requests/RecipientSearchparamsModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {MAILING_LIST, MAILING_LIST_RECIPIENT, MAILING_LIST_MESSAGE_SEARCH, MAILING_LIST_RECIPIENT_SEARCH, MAILING_LIST_SEARCH} from './Actions';
import {ISearchRequest, SearchRequest} from '../../types/common/SearchRequest';
import {SearchView} from '../../types/common/SearchView';

export interface IMailingListState {
  mailingListSearchParams: MailingListSearchParamsModel
  mailingListMessageSearchParams: MailingListMessageSearchParamsModel,
  mailingListSearchSort: string;
  mailingListSearchView: string | undefined;
  mailingListMessageSearchSort: string;
  mailingListResult: MailingListSearchPaged | undefined;
  mailingListResultError: string | undefined;
  mailingListResultLoading: boolean;
  mailingList: MailingListModel | undefined;
  mailingListLoading: boolean;
  mailingListLoadError: string | undefined;
  mailingListSaving: boolean;
  mailingListSaveError: string | undefined;
  mailingListEnabling: boolean;
  mailingListEnableError: string | undefined;
  mailingListDisabling: boolean;
  mailingListDisableError: string | undefined;
  mailingListRecipients: MailingListRecipientResultPaged | undefined;
  mailingListRecipientSearchParams: RecipientSearchParamsModel | undefined;
  mailingListRecipientsError: string | undefined;
  mailingListRecipientsLoading: boolean;
  mailingListRecipientsRefreshing: boolean;
  mailingListMessages: MailingListMessageSearchPaged | undefined;
  mailingListMessagesLoading: boolean;
  mailingListMessagesRefreshing: boolean;
  mailingListMessagesError: string | undefined;
}

const initialState: IMailingListState = {
  mailingListSearchParams: defaultForMailingListSearchParamsModel(),
  mailingListMessageSearchParams: defaultMailingListMessageSearchParamsModel(),
  mailingListSearchSort: 'Created',
  mailingListSearchView: SearchView.Default,
  mailingListResult: undefined,
  mailingListResultError: undefined,
  mailingListResultLoading: false,
  mailingListLoading: false,
  mailingListLoadError: undefined,
  mailingList: undefined,
  mailingListSaving: false,
  mailingListSaveError: undefined,
  mailingListEnabling: false,
  mailingListEnableError: undefined,
  mailingListDisabling: false,
  mailingListDisableError: undefined,
  mailingListRecipients: undefined,
  mailingListRecipientSearchParams: defaultRecipientSearchParamsModel(),
  mailingListRecipientsError: undefined,
  mailingListRecipientsLoading: false,
  mailingListRecipientsRefreshing: false,
  mailingListMessages: undefined,
  mailingListMessageSearchSort: 'Date Added',
  mailingListMessagesLoading: false,
  mailingListMessagesRefreshing: false,
  mailingListMessagesError: undefined,
};

const mailingListReducer = createReducer(initialState, {
  /* mailing list */
  [MAILING_LIST_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.mailingListSearchView = action.payload;
  },
  [MAILING_LIST.SAVE]: (state, action: PayloadAction<string>) => {
    state.mailingListSaving = true;
    state.mailingListSaveError = undefined;
  },
  [MAILING_LIST.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.mailingListSaving = false;
    state.mailingListSaveError = action.payload;
  },
  [MAILING_LIST.SAVED]: (state, action: PayloadAction<MailingListModel>) => {
    state.mailingListSaving = false;
    state.mailingListSaveError = undefined;
    state.mailingList = action.payload;
  },
  [MAILING_LIST.LOAD]: (state) => {
    state.mailingListLoading = true;
    state.mailingList = undefined;
    state.mailingListLoadError = undefined;
  },
  [MAILING_LIST.LOADED]: (state, action: PayloadAction<MailingListModel>) => {
    state.mailingListLoading = false;
    state.mailingList = action.payload;
    state.mailingListLoadError = undefined;
  },
  [MAILING_LIST.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.mailingListLoading = false;
    state.mailingListLoadError = action.payload;
  },
  [MAILING_LIST.ENABLE]: (state, action: PayloadAction<string>) => {
    state.mailingListEnabling = true;
    state.mailingListEnableError = undefined;
  },
  [MAILING_LIST.ENABLED]: (state) => {
    state.mailingListEnabling = false;
    state.mailingListEnableError = undefined;
  },
  [MAILING_LIST.ENABLE_ERROR]: (state, action: PayloadAction<string>) => {
    state.mailingListEnabling = false;
    state.mailingListEnableError = action.payload;
  },
  [MAILING_LIST.DISABLE]: (state) => {
    state.mailingListDisabling = true;
    state.mailingListDisableError = undefined;
  },
  [MAILING_LIST.DISABLED]: (state) => {
    state.mailingListDisabling = false;
    state.mailingListDisableError = undefined;
  },
  [MAILING_LIST.DISABLE_ERROR]: (state, action: PayloadAction<string>) => {
    state.mailingListDisabling = false;
    state.mailingListDisableError = action.payload;
  },
  /* search */
  [MAILING_LIST_SEARCH.LOAD]: (state, action: PayloadAction<ISearchRequest<MailingListSearchParamsModel>>) => {
    state.mailingListSearchParams = action.payload.searchParams;
    state.mailingListResultLoading = false;
    state.mailingListResultError = undefined;
  },
  [MAILING_LIST_SEARCH.QUICK_SEARCH]: (state) => {
    state.mailingListResultLoading = false;
    state.mailingListResultError = undefined;
  },
  [MAILING_LIST_SEARCH.LOADED]: (state, action: PayloadAction<MailingListSearchPaged>) => {
    state.mailingListResultLoading = false;
    state.mailingListResultError = undefined;
    state.mailingListResult = action.payload;
  },
  [MAILING_LIST_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.mailingListResultLoading = false;
    state.mailingListResultError = action.payload;
    state.mailingListResult = undefined;
  },
  /* recipients */
  [MAILING_LIST_RECIPIENT_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<RecipientSearchParamsModel>>) => {
    state.mailingListRecipientSearchParams = action.payload.searchParams;
    state.mailingListRecipientsError = undefined;
    state.mailingListRecipientsLoading = true;
  },
  [MAILING_LIST_RECIPIENT_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<RecipientSearchParamsModel>>) => {
    state.mailingListRecipientSearchParams = action.payload.searchParams;
    state.mailingListRecipientsError = undefined;
  },
  [MAILING_LIST_RECIPIENT_SEARCH.LOADED]: (state, action: PayloadAction<MailingListRecipientResultPaged>) => {
    state.mailingListRecipients = action.payload;
    state.mailingListRecipientsLoading = false;
    state.mailingListRecipientsError = undefined;
  },
  [MAILING_LIST_MESSAGE_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<RecipientSearchParamsModel>>) => {
    state.mailingListMessageSearchParams = action.payload.searchParams;
    state.mailingListMessagesError = undefined;
    state.mailingListMessagesLoading = true;
  },
  [MAILING_LIST_MESSAGE_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<RecipientSearchParamsModel>>) => {
    state.mailingListMessageSearchParams = action.payload.searchParams;
    state.mailingListMessagesError = undefined;
  },
  [MAILING_LIST_MESSAGE_SEARCH.LOADED]: (state, action: PayloadAction<MailingListRecipientResultPaged>) => {
    state.mailingListMessages = action.payload;
    state.mailingListMessagesLoading = false;
    state.mailingListMessagesError = undefined;
  },
  [MAILING_LIST_RECIPIENT.LOADED]: (state, action: PayloadAction<string>) => {
    state.mailingListDisabling = false;
    state.mailingListDisableError = action.payload;
  },
  [MAILING_LIST_RECIPIENT.LOAD]: (state, action: PayloadAction<string>) => {
    state.mailingListDisabling = false;
    state.mailingListDisableError = action.payload;
  },
  [MAILING_LIST_RECIPIENT.LOAD]: (state, action: PayloadAction<string>) => {
    state.mailingListDisabling = false;
    state.mailingListDisableError = action.payload;
  },
  [MAILING_LIST_RECIPIENT.LOAD]: (state, action: PayloadAction<string>) => {
    state.mailingListDisabling = false;
    state.mailingListDisableError = action.payload;
  },
  [MAILING_LIST_RECIPIENT.LOAD]: (state, action: PayloadAction<string>) => {
    state.mailingListDisabling = false;
    state.mailingListDisableError = action.payload;
  },

});

export default mailingListReducer;