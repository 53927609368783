import {ICommonCacheActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {HSPPlacementListItem} from '../../types/views/HSPPlacementListItem';

export const HSP_PLACEMENT_CACHE: ICommonCacheActionType = {
  ADD: 'hspPlacementCache/add',
  UPDATE: 'hspPlacementCache/updated',
  MERGE_ITEMS: 'hspPlacementCache/merge',
  ERROR: 'hspPlacementCache/error',
  REMOVE: 'hspPlacementCache/remove'
};

export const hspPlacementCacheAdd = createAction<string[]>(HSP_PLACEMENT_CACHE.ADD);
export const hspPlacementCacheUpdate = createAction<string[]>(HSP_PLACEMENT_CACHE.UPDATE);
export const hspPlacementCacheMerge = createAction<HSPPlacementListItem[]>(HSP_PLACEMENT_CACHE.MERGE_ITEMS);
export const hspPlacementCacheError = createAction<string>(HSP_PLACEMENT_CACHE.ERROR);
export const hspPlacementCacheRemove = createAction<string>(HSP_PLACEMENT_CACHE.REMOVE);
