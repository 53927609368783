import {
  ICommonLoadActionType,
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonSearchActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ICompanyEditModel} from '../../types/requests/CompanyEditModel';
import {CompanyModel, ICompanyModel} from '../../types/models/CompanyModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {ICompanySearchParams} from '../../types/requests/CompanySearchParams';
import {IPagedResult} from '../../types/common/PagedResult';
import {ICompanyLogoRequest} from '../../types/requests/CompanyLogoRequest';
import {IPoolSearchParamsModel} from '../../types/requests/PoolSearchParamsModel';
import {
  IOpportunitySearchParamsModel,
  OpportunitySearchParamsModel,
} from '../../types/requests/OpportunitySearchParamsModel';
import {IOpportunitiesDetailModel} from '../../types/views/OpportunitiesDetailModel';
import {CompanySearchModel, ICompanySearchModel} from '../../types/views/CompanySearchModel';

export interface ICompanyActionType extends ICommonSaveActionType {
  REFRESH: string;
}

export interface ICompanyLogoActionType {
  SAVE: string;
  SAVED: string;
  SAVE_ERROR: string;
}

export const COMPANY: ICompanyActionType = {
  LOAD: 'company/load',
  LOADED: 'company/loadSuccess',
  LOAD_ERROR: 'company/loadError',
  REFRESH: 'company/loadRefresh',
  SAVE: 'company/save',
  SAVED: 'company/saveSuccess',
  SAVE_ERROR: 'company/saveError'
};

export const COMPANY_SEARCH: ICommonSearchActionType = {
  ERROR: 'companies/searchError',
  LOAD: 'companies/search',
  LOADED: 'companies/searchSuccess',
  REFRESH: 'companies/searchRefresh',
  CHANGE_VIEW: 'companies/searchChangeView'
};

export const COMPANY_LOGO: ICompanyLogoActionType = {
  SAVE: 'companyLogo/save',
  SAVED: 'companyLogo/saveSuccess',
  SAVE_ERROR: 'companyLogo/saveError'
}

export const OPPORTUNITY_SEARCH: ICommonSearchActionType = {
  ERROR: 'opportunities/searchError',
  LOAD: 'opportunities/search',
  LOADED: 'opportunities/searchSuccess',
  REFRESH: 'opportunities/searchRefresh',
  CHANGE_VIEW: 'opportunities/searchChangeView'
};

export const MY_COMPANIES: ICommonLoadActionType = {
  LOAD: 'myCompanies/load',
  ERROR: 'myCompanies/error',
  LOADED: 'myCompanies/success',
}

export const RELATED_COMPANIES: ICommonLoadActionType = {
  LOAD: 'relatedCompanies/load',
  ERROR: 'relatedCompanies/error',
  LOADED: 'relatedCompanies/success',
}

export const companySave = createAction<ICommonSavePayload<ICompanyEditModel>>(COMPANY.SAVE);
export const companySaveSuccess = createAction<ICompanyModel>(COMPANY.SAVED);
export const companySaveError = createAction<string>(COMPANY.SAVE_ERROR);
export const companyLoad = createAction<string>(COMPANY.LOAD);
export const companyRefresh = createAction<string>(COMPANY.REFRESH);
export const companyLoadSuccess = createAction<ICompanyModel>(COMPANY.LOADED);
export const companyLoadError = createAction<string>(COMPANY.LOAD_ERROR);

export const companySearch = createAction<ISearchRequest<ICompanySearchParams>>(COMPANY_SEARCH.LOAD);
export const companySearchRefresh = createAction<ISearchRequest<ICompanySearchParams>>(COMPANY_SEARCH.REFRESH);
export const companySearchChangeView = createAction<string>(COMPANY_SEARCH.CHANGE_VIEW);
export const companySearchSuccess = createAction<IPagedResult<ICompanySearchModel>>(COMPANY_SEARCH.LOADED);
export const companySearchError = createAction<string>(COMPANY_SEARCH.ERROR);

export const companyLogoSave = createAction<ICompanyLogoRequest>(COMPANY_LOGO.SAVE);
export const companyLogoSaveSuccess = createAction(COMPANY_LOGO.SAVED);
export const companyLogoSaveError = createAction<string>(COMPANY_LOGO.SAVE_ERROR);

export const opportunitySearch = createAction<ISearchRequest<IOpportunitySearchParamsModel>>(OPPORTUNITY_SEARCH.LOAD);
export const opportunitySearchRefresh = createAction<ISearchRequest<IPoolSearchParamsModel>>(OPPORTUNITY_SEARCH.REFRESH);
export const opportunitySearchChangeView = createAction<string>(OPPORTUNITY_SEARCH.CHANGE_VIEW);
export const opportunitySearchSuccess = createAction<IPagedResult<IOpportunitiesDetailModel>>(OPPORTUNITY_SEARCH.LOADED);
export const opportunitySearchError = createAction<string>(OPPORTUNITY_SEARCH.ERROR);

export const myCompaniesRequest = createAction<ISearchRequest<ICompanySearchParams>>(MY_COMPANIES.LOAD);
export const myCompaniesError = createAction<string>(MY_COMPANIES.ERROR);
export const myCompaniesSuccess = createAction<IPagedResult<ICompanySearchModel>>(MY_COMPANIES.LOADED);

export const relatedCompaniesRequest = createAction<string>(RELATED_COMPANIES.LOAD);
export const relatedCompaniesError = createAction<string>(RELATED_COMPANIES.ERROR);
export const relatedCompaniesSuccess = createAction<Array<CompanyModel>>(RELATED_COMPANIES.LOADED);
