import {formatDate} from '../../@template/helpers/datetimes';

export const defaultContactSearchParamsModel = () => {
  return ContactSearchParamsModel.fromJS({
    searchText: '',
    statusFlags: [],
    joinedFrom: null,
    joinedTo: null,
    advisorUsers: [],
    primaryOnly: true,
    advisorSelected: false,
    excludePrimarySelected: false,
    noPrimaryAppointed: false,
    noAdvisorAppointed: false,
    byTeam: false,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    contactTags: [],
    state: [],
    countryCode: 'AU',
    contactSearchText: '',
    includeInactive: false,
    isExecutiveSponsor: false,
  });
}

export class ContactSearchParamsModel implements IContactSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  contactTags?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  isExecutiveSponsor?: boolean | undefined;


  constructor(data?: IContactSearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      if (Array.isArray(Data["statusFlags"])) {
        this.statusFlags = [] as any;
        for (let item of Data["statusFlags"])
          this.statusFlags!.push(item);
      }
      this.joinedFrom = Data["joinedFrom"] ? new Date(Data["joinedFrom"].toString()) : <any>undefined;
      this.joinedTo = Data["joinedTo"] ? new Date(Data["joinedTo"].toString()) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data["primaryOnly"];
      this.advisorSelected = Data["advisorSelected"];
      this.excludePrimarySelected = Data["excludePrimarySelected"];
      this.noPrimaryAppointed = Data["noPrimaryAppointed"];
      this.noAdvisorAppointed = Data["noAdvisorAppointed"];
      this.byTeam = Data["byTeam"];
      this.advisorTeamId = Data["advisorTeamId"];
      this.advisorSubTeamId = Data["advisorSubTeamId"];
      this.byAdvisorTeam = Data["byAdvisorTeam"];
      this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
      if (Array.isArray(Data["contactTags"])) {
        this.contactTags = [] as any;
        for (let item of Data["contactTags"])
          this.contactTags!.push(item);
      }
      if (Array.isArray(Data["state"])) {
        this.state = [] as any;
        for (let item of Data["state"])
          this.state!.push(item);
      }
      this.countryCode = Data["countryCode"];
      this.contactSearchText = Data["contactSearchText"];
      this.isExecutiveSponsor = Data["isExecutiveSponsor"];
    }
  }

  static fromJS(data: any): ContactSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new ContactSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    if (Array.isArray(this.statusFlags)) {
      data["statusFlags"] = [];
      for (let item of this.statusFlags)
        data["statusFlags"].push(item);
    }
    data["joinedFrom"] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
    data["joinedTo"] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryOnly"] = this.primaryOnly;
    data["advisorSelected"] = this.advisorSelected;
    data["excludePrimarySelected"] = this.excludePrimarySelected;
    data["noPrimaryAppointed"] = this.noPrimaryAppointed;
    data["noAdvisorAppointed"] = this.noAdvisorAppointed;
    data["byTeam"] = this.byTeam;
    data["advisorTeamId"] = this.advisorTeamId;
    data["advisorSubTeamId"] = this.advisorSubTeamId;
    data["byAdvisorTeam"] = this.byAdvisorTeam;
    data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
    if (Array.isArray(this.contactTags)) {
      data["contactTags"] = [];
      for (let item of this.contactTags)
        data["contactTags"].push(item);
    }
    if (Array.isArray(this.state)) {
      data["state"] = [];
      for (let item of this.state)
        data["state"].push(item);
    }
    data["countryCode"] = this.countryCode;
    data["contactSearchText"] = this.contactSearchText;
    data["isExecutiveSponsor"] = this.isExecutiveSponsor;
    return data;
  }
}

export interface IContactSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  contactTags?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  isExecutiveSponsor?: boolean | undefined;
}
