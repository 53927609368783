import {all, call, put, takeLatest} from 'redux-saga/effects';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {
  PEOPLE_SEARCH,
  PEOPLE_UPDATE_REQUEST_LIST,
  PEOPLE_MERGE,
  PERSON_UPDATE_REQUEST,
  peopleSearchError,
  peopleSearchSuccess,
  peopleUpdateRequestListLoadError,
  peopleUpdateRequestListLoadSuccess,
  peopleUpdateRequestListSaveError,
  peopleUpdateRequestListSuccess,
  peopleMergeRequestLoadError,
  peopleMergeRequestLoadSuccess,
  peopleMergeRequestSaveError,
  peopleMergeRequestSuccess,
  ISubmitUpdatePipelineResolvedModel,
  peopleUpdateRequestSuccess,
  peopleUpdateRequestSaveError,
  peopleUpdateRequestLoadSuccess,
  peopleUpdateRequestLoadError,
} from './Actions';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {PeopleSearchParamsModel} from '../../types/requests/PeopleSearchParamsModel';
import {PersonModel} from '../../types/models/PersonModel';
import {UpdatePipelineResult} from '../../types/views/UpdatePipelineResult';
import {PeopleSearchPaged} from '../../types/views/PeopleSearchPaged';
import {IPeopleUpdateRequestPayload} from '../../types/requests/PeopleUpdateRequestPayload';
import {IPeopleMergeRequestPayload} from '../../types/requests/PeopleMergeRequestPayload';
import {UpdatePipelineResolvedModel} from '../../types/views/UpdatePipelineResolvedModel';
import {IChangeRequestFindRequest, IChangeRequestLoadRequest} from '../../types/common/ChangeRequestProps';
import {MergePersonModel} from '../../types/requests/MergePersonModel';

function* SearchPeople({ page, sort, searchParams }: ISearchRequest<PeopleSearchParamsModel>) {
  console.log('SAGA - peopleSaga - SearchPeople');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.corePeoplePath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PeopleSearchPaged = PeopleSearchPaged.fromJS(res.data);
    yield put(peopleSearchSuccess(results));
  } catch (error) {
    yield put(peopleSearchError(error));
  }
}

function* LoadPersonUpdateRequestsList(id: string) {
  console.log('SAGA - personSage - LoadPersonUpdateRequestsList');
  // TODO this is using a different endpoint than the old interface to load the completed update pipeline info
  const url = `${apiConfig.coreUrl}${apiConfig.corePeoplePath}/${id}/update-pipeline/history`;
  try {
    const res = yield authApi.get(url);
    const peopleUpdateRequest = res.data.map((x: any) => UpdatePipelineResult.fromJS(x));
    yield put(peopleUpdateRequestListLoadSuccess(peopleUpdateRequest));
  } catch (error) {
    yield put(peopleUpdateRequestListLoadError(error));
  }
}

function* LoadPersonUpdateRequest({personId, updateId }: IChangeRequestLoadRequest) {
  console.log('SAGA - personSaga - LoadPersonUpdateRequest');
  if (updateId) {
    const personPath = `${apiConfig.corePeoplePath}/${personId}/update-pipeline/${updateId}`;
    try {
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'GET',
        url: personPath,
      });
      const peopleUpdateRequest = UpdatePipelineResult.fromJS(res.data);
      yield put(peopleUpdateRequestLoadSuccess(peopleUpdateRequest));
    } catch (error) {
      yield put(peopleUpdateRequestLoadError(error));
    }
  }
}

function* FindPersonUpdateRequest({personId, changeType}: IChangeRequestFindRequest) {
  const personPath = `${apiConfig.corePeoplePath}/${personId}/update-pipeline`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: personPath,
      data: {changeType}
    });
    if(res.data.updateId){
      const peopleUpdateRequest = UpdatePipelineResult.fromJS(res.data);
      yield put(peopleUpdateRequestLoadSuccess(peopleUpdateRequest));
    }else{
      yield put(peopleUpdateRequestLoadSuccess(undefined));
    }

  } catch (error) {
    yield put(peopleUpdateRequestLoadError(error));
  }
}

function* SavePersonUpdateRequestsList({ id, changeIds}: IPeopleUpdateRequestPayload) {
  console.log('SAGA - personSage - SavePersonUpdateRequestsList');
  const studentPath = `${apiConfig.corePeoplePath}/${id}/update-pipeline/list`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: studentPath,
      data: { update_id_list: changeIds }
    });
    const peopleUpdateRequest = res.data.map((x: any) => UpdatePipelineResult.fromJS(x));
    yield put(peopleUpdateRequestListSuccess(peopleUpdateRequest));
  } catch (error) {
    yield put(peopleUpdateRequestListSaveError(error));
  }
}

function* SavePeopleUpdateRequest({ personId, updateId, isIgnored, isApplied }: ISubmitUpdatePipelineResolvedModel){
  console.log('SAGA - personSaga - SavePeopleUpdateRequest');
  const path = `${apiConfig.corePeoplePath}/${personId}/update-pipeline/${updateId}`;
  try {

    const submission: UpdatePipelineResolvedModel = new UpdatePipelineResolvedModel();
    submission.isIgnored = isIgnored;
    submission.isApplied = isApplied;

    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: path,
      data: submission
    });
    const result = UpdatePipelineResult.fromJS(res.data);
    yield put(peopleUpdateRequestSuccess(result));
  } catch (error) {
    yield put(peopleUpdateRequestSaveError(error));
  }
}

function* MergePersonLoad(mergeRequestPayload: IPeopleMergeRequestPayload) {
  console.log('SAGA - personSage - MergePersonLoad');
  const url = `${apiConfig.coreUrl}${apiConfig.corePeoplePath}/${mergeRequestPayload.destinationPersonId}`;
  try {
    const res = yield authApi.get(url);
    const person = PersonModel.fromJS(res.data);
    yield put(peopleMergeRequestLoadSuccess(person));
  } catch (error) {
    yield put(peopleMergeRequestLoadError(error));
  }
}


function* MergePersonSave(mergeRequest: MergePersonModel) {
  console.log('SAGA - personSage - MergePersonSave');
  const personPath = `${apiConfig.corePeoplePath}/merge`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: personPath,
      data: mergeRequest
    });
    const person = PersonModel.fromJS(res.data);
    yield put(peopleMergeRequestSuccess(person));
  } catch (error) {
    yield put(peopleMergeRequestSaveError(error));
  }
}

export function* watchPeopleSearch() {
  yield takeLatest(PEOPLE_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<PeopleSearchParamsModel>>) => SearchPeople(payload))
}

export function* watchLoadPeopleUpdateRequestList() {
  yield takeLatest(PEOPLE_UPDATE_REQUEST_LIST.LOAD, ({ payload }: PayloadAction<string>) => LoadPersonUpdateRequestsList(payload));
}

export function* watchSavePeopleUpdateRequestList() {
  yield takeLatest(PEOPLE_UPDATE_REQUEST_LIST.SAVE, ({ payload }: PayloadAction<IPeopleUpdateRequestPayload>) => SavePersonUpdateRequestsList(payload));
}

export function* watchLoadPeopleUpdateRequest() {
  yield takeLatest(PERSON_UPDATE_REQUEST.LOAD, ({ payload }: PayloadAction<IChangeRequestLoadRequest>) => LoadPersonUpdateRequest(payload));
}

export function* watchSavePeopleUpdateRequest() {
  yield takeLatest(PERSON_UPDATE_REQUEST.SAVE, ({ payload }: PayloadAction<ISubmitUpdatePipelineResolvedModel>) => SavePeopleUpdateRequest(payload));
}

export function* watchFindPeopleUpdateRequest() {
  yield takeLatest(PERSON_UPDATE_REQUEST.FIND, ({ payload }: PayloadAction<IChangeRequestFindRequest>) => FindPersonUpdateRequest(payload));
}

export function* watchMergePersonLoad() {
  yield takeLatest(PEOPLE_MERGE.LOAD, ({ payload }: PayloadAction<IPeopleMergeRequestPayload>) => MergePersonLoad(payload));
}

export function* watchMergePersonSave() {
  yield takeLatest(PEOPLE_MERGE.SAVE, ({ payload }: PayloadAction<MergePersonModel>) => MergePersonSave(payload));
}

export default function* placementSaga() {
  yield all ([
    call(watchPeopleSearch),
    call(watchLoadPeopleUpdateRequestList),
    call(watchSavePeopleUpdateRequestList),
    call(watchLoadPeopleUpdateRequest),
    call(watchMergePersonLoad),
    call(watchMergePersonSave),
    call(watchSavePeopleUpdateRequest),
    call(watchFindPeopleUpdateRequest)
  ])
}

