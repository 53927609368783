import {ICommonLoadActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ICountryModel} from '../../types/models/CountryModel';
import {IPlatformSettingsModel} from '../../types/models/PlatformSettingsModel';
import {ITagListModel} from '../../types/models/TagListModel';
import {IFileMetaModel} from '../../types/models/FileMetaModel';

interface ITagsUpdateActionTypes {
  UPDATE_STUDENT: string;
  UPDATE_CLIENT: string;
  UPDATE_PARTNER: string;
  UPDATE_EDUCATION: string;
  UPDATE_CONTACT: string;
  UPDATE_VOLUNTEER: string;
  UPDATE_WORK_SHADOW: string;
  UPDATE_ALUMNI: string;
  UPDATE_FILE: string;
  UPDATE_SYSTEM_FILE: string;
}

interface IEmailTemplatesActionTypes {
  FILES_LISTED: string;
  SELECT_TEMPLATE: string;
}

export const SESSION: ICommonLoadActionType = {
  LOAD: 'session/load',
  ERROR: 'session/error',
  LOADED: 'session/loaded',
};

export const LOCALITIES: ICommonLoadActionType = {
  LOAD: 'localities/load',
  ERROR: 'localities/error',
  LOADED: 'localities/loaded',
};

export const TAGS: ITagsUpdateActionTypes = {
  UPDATE_STUDENT: 'tags/updateStudent',
  UPDATE_CLIENT: 'tags/updateClient',
  UPDATE_PARTNER:'tags/updatePartner',
  UPDATE_EDUCATION:'tags/updateEducation',
  UPDATE_CONTACT: 'tags/updateContact',
  UPDATE_VOLUNTEER: 'tags/updateVolunteer',
  UPDATE_WORK_SHADOW: 'tags/updateWorkShadow',
  UPDATE_ALUMNI: 'tags/alumni',
  UPDATE_FILE: 'tags/updateFileTags',
  UPDATE_SYSTEM_FILE: 'tags/updateSystemFileTags',
};

export const EMAIL_TEMPLATES: IEmailTemplatesActionTypes = {
  FILES_LISTED: 'EmailTemplateResults/list',
  SELECT_TEMPLATE: 'emailTemplate/selectTemplate',
};

export const sessionLoad = createAction(SESSION.LOAD);
export const sessionLoadError = createAction<string>(SESSION.ERROR);
export const sessionLoadSuccess = createAction<IPlatformSettingsModel>(SESSION.LOADED);
export const localitiesLoad = createAction(LOCALITIES.LOAD);
export const localitiesError = createAction<string>(LOCALITIES.ERROR);
export const localitiesLoadSuccess = createAction<ICountryModel[]>(LOCALITIES.LOADED);
export const tagsUpdateStudent = createAction<string[]>(TAGS.UPDATE_STUDENT);
export const tagsUpdateClient = createAction<string[]>(TAGS.UPDATE_CLIENT);
export const tagsUpdatePartner = createAction<string[]>(TAGS.UPDATE_PARTNER);
export const tagsUpdateEducation = createAction<string[]>(TAGS.UPDATE_EDUCATION);
export const tagsUpdateContact = createAction<string[]>(TAGS.UPDATE_CONTACT);
export const tagsUpdateVolunteer = createAction<string[]>(TAGS.UPDATE_VOLUNTEER);
export const tagsUpdateWorkShadow = createAction<string[]>(TAGS.UPDATE_WORK_SHADOW);
export const tagsUpdateAlumni = createAction<string[]>(TAGS.UPDATE_ALUMNI);
export const tagsUpdateFile = createAction<string[]>(TAGS.UPDATE_FILE);
export const tagsUpdateSystemFile = createAction<string[]>(TAGS.UPDATE_SYSTEM_FILE);
export const emailTemplateFileListSuccess = createAction<IFileMetaModel[]>(EMAIL_TEMPLATES.FILES_LISTED);
export const emailTemplateSelect = createAction<string>(EMAIL_TEMPLATES.SELECT_TEMPLATE);



