import {ISearchInRequest, ISearchRequest} from '../../types/common/SearchRequest';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {MailingListSearchPaged} from '../../types/views/MailingListSearchPaged';
import {MailingListMessageSearchPaged} from '../../types/views/MailingListMessageSearchPaged';
import {MailingListRecipientResultPaged} from '../../types/views/MailingListRecipientResultPaged';
import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  MAILING_LIST_SEARCH,
  MAILING_LIST,
  mailingListSearchError,
  mailingListSearchSuccess,
  mailingListLoadSuccess,
  mailingListLoadError,
  MAILING_LIST_RECIPIENT_SEARCH,
  MAILING_LIST_MESSAGE_SEARCH,
  mailingListRecipientLoadSuccess, mailingListRecipientLoadError, mailingListRecipientSearchLoadSuccess,
  mailingListMessageSearchSuccess, mailingListMessageSearchError,
} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {MailingListSearchParamsModel} from '../../types/requests/MailingListSearchParamsModel';
import {RecipientSearchParamsModel} from '../../types/requests/RecipientSearchparamsModel';
import {MailingListMessageSearchParamsModel} from '../../types/requests/MailingListMessageSearchParamsModel';
import {PersonModel} from '../../types/models/PersonModel';
import {MailingListModel} from '../../types/models/MailingListModel';
import {STUDENT, studentLoadError, studentLoadSuccess} from '../students/Actions';


function* SearchMailingList({ page, sort, searchParams }: ISearchRequest<MailingListSearchParamsModel>) {
  console.log('SAGA - mailingListSaga - SearchMailingList');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreMailingPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: MailingListSearchPaged = MailingListSearchPaged.fromJS(res.data);
    yield put(mailingListSearchSuccess(results));
  } catch (error) {
    yield put(mailingListSearchError(error));
  }
}

function* SearchMailingListRecipients({ id, page, sort, searchParams }: ISearchInRequest<RecipientSearchParamsModel>) {
  console.log('SAGA - mailingListSaga - SearchMailingListRecipients');

  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreMailingPath}/search-recipients/${id}?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: MailingListRecipientResultPaged = MailingListRecipientResultPaged.fromJS(res.data);
    yield put(mailingListRecipientSearchLoadSuccess(results));
  } catch (error) {
    yield put(mailingListMessageSearchError(error));
  }
}

function* SearchMailingListMessages({ id, page, sort, searchParams }: ISearchInRequest<MailingListMessageSearchParamsModel>) {
  console.log('SAGA - mailingListSaga - SearchMailingListMessages');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreMailingPath}/search-messages/${id}?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: MailingListMessageSearchPaged = MailingListMessageSearchPaged.fromJS(res.data);
    yield put(mailingListMessageSearchSuccess(results));
  } catch (error) {
    yield put(mailingListSearchError(error));
  }
}

function* LoadMailingList(id: string) {
  console.log('SAGA - mailingListSaga - LoadMailingList');
  try {
    const res = yield authApi.get(`${apiConfig.coreMailingPath}/${id}`);
    const mailingList = MailingListModel.fromJS(res.data);
    yield put(mailingListLoadSuccess(mailingList));
  } catch (error) {
    yield put(mailingListLoadError(error));
  }
}

export function* watchMailingListLoad() {
  yield takeLatest(MAILING_LIST.LOAD, ({ payload }: PayloadAction<string>) => LoadMailingList(payload));
}

export function* watchMailingListSearch() {
  yield takeLatest(MAILING_LIST_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<MailingListSearchParamsModel>>) => SearchMailingList(payload))
}

export function* watchMailingListRecipientSearch() {
  yield takeLatest(MAILING_LIST_RECIPIENT_SEARCH.LOAD, ({payload}: PayloadAction<ISearchInRequest<RecipientSearchParamsModel>>) => SearchMailingListRecipients(payload))
}

export function* watchMailingListMessageSearch() {
  yield takeLatest(MAILING_LIST_MESSAGE_SEARCH.LOAD, ({payload}: PayloadAction<ISearchInRequest<RecipientSearchParamsModel>>) => SearchMailingListMessages(payload))
}

export function* watchMailingListSearchRefresh() {
  yield takeLatest(MAILING_LIST_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<MailingListSearchParamsModel>>) => SearchMailingList(payload))
}

export default function* studentSaga() {
  yield all ([
    call(watchMailingListLoad),
    call(watchMailingListSearch),
    call(watchMailingListSearchRefresh),
    call(watchMailingListRecipientSearch),
    call(watchMailingListMessageSearch),
  ])
}