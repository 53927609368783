import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import {Alert, AlertTitle, Theme} from '@mui/material';

type Variant = 'error' | 'info' | 'success' | 'warning';

interface AppSnackbarProps {
  className?: string;
  message: string;
  title?: string | undefined,
  variant: Variant;
  handleClose?(): void;
  [x: string]: any;
}

const AppSnackbar: React.FC<AppSnackbarProps> = ({
  className,
  message,
  title,
  variant,
  handleClose,
  ...other
}) => {
  const [open, setOpen] = React.useState(true);
  const onClose = () => {
    setOpen(false);
    if (handleClose) handleClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      {...other}
    >
        <Alert variant="filled" id='client-snackbar' severity={variant}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
    </Snackbar>
  );
};
export default AppSnackbar;
