import {
  ICommonSaveActionType,
  ICommonSearchActionType,
  ICommonSavePayload,
  ICommonLoadActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {PersonModel, IPersonModel} from '../../types/models/PersonModel';
import { IMergePersonModel } from 'types/requests/MergePersonModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {IPeopleSearchParamsModel} from '../../types/requests/PeopleSearchParamsModel';
import {IPeopleUpdateRequestPayload} from '../../types/requests/PeopleUpdateRequestPayload';
import {IPeopleMergeRequestPayload} from '../../types/requests/PeopleMergeRequestPayload';
import {UpdatePipelineResult} from '../../types/views/UpdatePipelineResult';
import {UpdatePipelineResolvedModel} from '../../types/views/UpdatePipelineResolvedModel';
import {IChangeRequestFindRequest, IChangeRequestLoadRequest} from '../../types/common/ChangeRequestProps';

export interface IPeopleActionTypes extends ICommonSaveActionType {
  REFRESH: string;
}

export interface IPeopleMergeActionTypes extends ICommonSaveActionType {
  CLEAR: string;
}

export interface IPeopleUpdateRequestListActionTypes extends ICommonSaveActionType {
  REFRESH: string;
  FIND: string;
}

export const PEOPLE: IPeopleActionTypes = {
  LOAD: 'people/load',
  LOAD_ERROR: 'people/loadError',
  LOADED: 'people/loadSuccess',
  REFRESH: 'people/refresh',
  SAVE: 'people/save',
  SAVED: 'people/saveSuccess',
  SAVE_ERROR: 'people/saveError',
};

export const PEOPLE_SEARCH: ICommonSearchActionType = {
  ERROR: 'peopleSearch/error',
  LOAD: 'peopleSearch/load',
  LOADED: 'peopleSearch/loaded',
  REFRESH: 'peopleSearch/refresh',
  CHANGE_VIEW: 'peopleSearch/changeView',
};

export const PEOPLE_UPDATE_REQUEST_LIST: IPeopleActionTypes = {
  LOAD: 'peopleUpdateRequestList/load',
  LOAD_ERROR: 'peopleUpdateRequestList/loadError',
  LOADED: 'peopleUpdateRequestList/loadSuccess',
  REFRESH: 'peopleUpdateRequestList/refresh',
  SAVE: 'peopleUpdateRequestList/save',
  SAVED: 'peopleUpdateRequestList/saveSuccess',
  SAVE_ERROR: 'peopleUpdateRequestList/saveError',
};

export const PERSON_UPDATE_REQUEST: IPeopleUpdateRequestListActionTypes = {
  LOAD: 'peopleUpdateRequest/load',
  LOAD_ERROR: 'peopleUpdateRequest/loadError',
  LOADED:'peopleUpdateRequest/loaded',
  REFRESH: 'peopleUpdateRequestList/refresh',
  SAVE: 'peopleUpdateRequest/save',
  SAVED: 'peopleUpdateRequest/saveSuccess',
  SAVE_ERROR: 'peopleUpdateRequest/saveError',
  FIND: 'peopleUpdateRequest/find'
}

export const PEOPLE_MERGE: IPeopleMergeActionTypes = {
  LOAD: 'peopleMerge/load',
  LOAD_ERROR: 'peopleMerge/loadError',
  LOADED: 'peopleMerge/loadSuccess',
  SAVE: 'peopleMerge/save',
  SAVED: 'peopleMerge/saveSuccess',
  SAVE_ERROR: 'peopleMerge/saveError',
  CLEAR:  'peopleMerge/clear',
}

export interface ISubmitUpdatePipelineResolvedModel{
  updateId: string;
  personId: string;
  isIgnored: boolean;
  isApplied: boolean;
}

// export class SubmitUpdatePipelineResolvedModel implements ISubmitUpdatePipelineResolvedModel

export const peopleLoad = createAction<string>(PEOPLE.LOAD);
export const peopleRefresh = createAction<string>(PEOPLE.REFRESH);
export const peopleLoadSuccess = createAction<PersonModel>(PEOPLE.LOADED);
export const peopleLoadError = createAction<string>(PEOPLE.LOAD_ERROR);

export const peopleSearch = createAction<ISearchRequest<IPeopleSearchParamsModel>>(PEOPLE_SEARCH.LOAD);
export const peopleSearchView = createAction<string>(PEOPLE_SEARCH.CHANGE_VIEW);
export const peopleSearchRefresh = createAction<ISearchRequest<IPeopleSearchParamsModel>>(PEOPLE_SEARCH.REFRESH);
export const peopleSearchError = createAction<string>(PEOPLE_SEARCH.ERROR);
export const peopleSearchSuccess = createAction<IPagedResult<IPersonModel>>(PEOPLE_SEARCH.LOADED);

export const peopleUpdateRequestListLoad = createAction<string>(PEOPLE_UPDATE_REQUEST_LIST.LOAD);
export const peopleUpdateRequestListRefresh = createAction<string>(PEOPLE_UPDATE_REQUEST_LIST.REFRESH);
export const peopleUpdateRequestListLoadSuccess = createAction<UpdatePipelineResult[]>(PEOPLE_UPDATE_REQUEST_LIST.LOADED);
export const peopleUpdateRequestListLoadError = createAction<string>(PEOPLE_UPDATE_REQUEST_LIST.LOAD_ERROR);

export const peopleUpdateRequestListSave = createAction<IPeopleUpdateRequestPayload>(PEOPLE_UPDATE_REQUEST_LIST.SAVE);
export const peopleUpdateRequestListSuccess = createAction<UpdatePipelineResult[]>(PEOPLE_UPDATE_REQUEST_LIST.SAVED);
export const peopleUpdateRequestListSaveError = createAction<string>(PEOPLE_UPDATE_REQUEST_LIST.SAVE_ERROR);

export const peopleUpdateRequestLoad = createAction<IChangeRequestLoadRequest>(PERSON_UPDATE_REQUEST.LOAD);
export const peopleUpdateRequestRefresh = createAction<IChangeRequestLoadRequest>(PERSON_UPDATE_REQUEST.REFRESH);
export const peopleUpdateRequestLoadSuccess = createAction<UpdatePipelineResult | undefined>(PERSON_UPDATE_REQUEST.LOADED);
export const peopleUpdateRequestLoadError = createAction<string>(PERSON_UPDATE_REQUEST.LOAD_ERROR);
export const peopleUpdateRequestFind = createAction<IChangeRequestFindRequest>(PERSON_UPDATE_REQUEST.FIND);

export const peopleUpdateRequestSave = createAction<ISubmitUpdatePipelineResolvedModel>(PERSON_UPDATE_REQUEST.SAVE);
export const peopleUpdateRequestSuccess = createAction<UpdatePipelineResult>(PERSON_UPDATE_REQUEST.SAVED);
export const peopleUpdateRequestSaveError = createAction<string>(PERSON_UPDATE_REQUEST.SAVE_ERROR);

export const peopleMergeRequestLoad = createAction<IPeopleMergeRequestPayload>(PEOPLE_MERGE.LOAD);
export const peopleMergeRequestLoadSuccess = createAction<PersonModel>(PEOPLE_MERGE.LOADED);
export const peopleMergeRequestLoadError = createAction<string>(PEOPLE_MERGE.LOAD_ERROR);

export const peopleMergeRequestSave = createAction<IMergePersonModel>(PEOPLE_MERGE.SAVE);
export const peopleMergeRequestSuccess = createAction<PersonModel>(PEOPLE_MERGE.SAVED);
export const peopleMergeRequestSaveError = createAction<string>(PEOPLE_MERGE.SAVE_ERROR);

