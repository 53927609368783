import {useFormContext, Controller, FieldValues} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import GridContainer from '../../core/GridContainer';
import {halfCol} from '../../utility/FormLayout/layoutConfig';
import {Grid} from '@mui/material';
import FormTextField from '../FormTextField';
import {selectCountries} from '../../../redux/session/Selectors';
import FormAutoComplete from '../FormAutoComplete';
import {hasItems} from '../../helpers/arrays';
import {CountryModel} from '../../../types/models/CountryModel';
import {useSelector} from 'react-redux';

const loadStatesFromCountry = (countryCode: string, countries: CountryModel[]) => {
  if (!countryCode) return [];
  const countryObj = countries.find((x: CountryModel) => x.countryCode === countryCode);
  return countryObj ? countryObj.states : [];
}

const FormRegion = <T extends FieldValues>(props: T) => {
  const { prefixName = '', labelPrefix = '', disabled=false } = props;
  const { watch, setValue } = useFormContext();
  const formPrefix = prefixName ? `${prefixName}.` : '';
  const watchAddressCountry = watch(`${formPrefix}countryCode`);
  const watchAddressState = watch(`${formPrefix}state`);

  const countries = useSelector(selectCountries);
  // For performance. Full country list is sloooow
  const countriesOptions = hasItems(countries) ? countries.map((x: CountryModel) => {
    return {
      countryCode: x.countryCode,
      name: x.name
    };
  }) : [];

  const [ states, setStates ] = useState(loadStatesFromCountry(watchAddressCountry, countries));

  useEffect(() => {
    const statesList = loadStatesFromCountry(watchAddressCountry, countries);
    // Reset state if no longer valid
    if (!statesList || !hasItems(statesList) || !statesList.find(x => x.shortTitle === watchAddressState)) {
      setValue(`${formPrefix}state`, null);
    }
    setStates(statesList);
  }, [watchAddressCountry, countries, setValue, formPrefix])

  return (
    <GridContainer spacing={1} key={`ADDRESS_${prefixName}`}>
      <Grid item xs={12}>
        <FormAutoComplete name={`${formPrefix}countryCode`} label={`${labelPrefix} Country`} data={countriesOptions} labelField="name" valueField="countryCode" disabled={disabled}/>
      </Grid>
      <Grid item xs={12} p={0}>
        <FormTextField name={`${formPrefix}suburb`} label="Suburb" disabled={disabled}/>
      </Grid>
      <Grid item xs={12} p={0}>
        {hasItems(states) && <FormAutoComplete name={`${formPrefix}state`} label="State" data={states} labelField="shortTitle" valueField="shortTitle" disabled={disabled}/>}
        {!hasItems(states) && <FormTextField name={`${formPrefix}state`} label="State" disabled={disabled}/>}
      </Grid>
    </GridContainer>
  );
};

export default FormRegion;