import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  VOLUNTEER, VOLUNTEER_SEARCH,
  volunteerLoadError, volunteerLoadSuccess,
  volunteerSaveError, volunteerSaveSuccess,
  volunteersSearchError, volunteersSearchSuccess,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {VolunteerPersonEditModel} from '../../types/requests/VolunteerPersonEditModel';
import {ICommonSavePayload} from '../Common.actions';
import {VolunteerPersonModel} from '../../types/views/VolunteerPersonModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {VolunteerSearchParamsModel} from '../../types/requests/VolunteerSearchParamsModel';
import {VolunteerSearchPaged} from '../../types/views/VolunteerSearchPaged';
import {PersonModel} from '../../types/models/PersonModel';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';

function* LoadVolunteer(id: string) {
  console.log('SAGA - volunteerSaga - LoadVolunteer');
  try {
    const res = yield authApi.get(`${apiConfig.coreVolunteerPath}/${id}`);
    const student = PersonModel.fromJS(res.data);
    yield put(volunteerLoadSuccess(student));
  } catch (error) {
    yield put(volunteerLoadError(error));
  }
}

function* SaveVolunteer({ id, submission }: ICommonSavePayload<VolunteerPersonEditModel>) {
  console.log('SAGA - volunteerSaga - SaveVolunteer');
  const studentPath = id ? `${apiConfig.coreVolunteerPath}/${id}` : apiConfig.coreVolunteerPath;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: studentPath,
      data: submission
    });
    const student = VolunteerPersonModel.fromJS(res.data);
    yield put(volunteerSaveSuccess(student));
  } catch (error) {
    yield put(volunteerSaveError(error));
  }
}

function* SearchVolunteers({ page, sort, searchParams }: ISearchRequest<VolunteerSearchParamsModel>) {
  console.log('SAGA - volunteerSaga - SearchVolunteers');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreVolunteerPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(volunteersSearchSuccess(results));
  } catch (error) {
    yield put(volunteersSearchError(error));
  }
}

export function* watchVolunteerLoad() {
  yield takeLatest(VOLUNTEER.LOAD, ({ payload }: PayloadAction<string>) => LoadVolunteer(payload));
}

export function* watchVolunteerRefresh() {
  yield takeLatest(VOLUNTEER.REFRESH, ({ payload }: PayloadAction<string>) => LoadVolunteer(payload));
}

export function* watchVolunteerSave() {
  yield takeLatest(VOLUNTEER.SAVE, ({ payload }: PayloadAction<ICommonSavePayload<VolunteerPersonEditModel>>) => SaveVolunteer(payload));
}

export function* watchVolunteerSearch() {
  yield takeLatest(VOLUNTEER_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<VolunteerSearchParamsModel>>) => SearchVolunteers(payload))
}

export function* watchVolunteerSearchRefresh() {
  yield takeLatest(VOLUNTEER_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<VolunteerSearchParamsModel>>) => SearchVolunteers(payload))
}

export default function* studentSaga() {
  yield all ([
    call(watchVolunteerRefresh),
    call(watchVolunteerLoad),
    call(watchVolunteerSave),
    call(watchVolunteerSearch),
    call(watchVolunteerSearchRefresh),
  ])
}
