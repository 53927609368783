import {formatDate} from '../../@template/helpers/datetimes';

export const defaultPeopleSearchParamsModel = () => {
    return PeopleSearchParamsModel.fromJS({
        searchText: '',
        contactSearchText: '',
        isStudent: false,
        isClient: false,
        isAdvisor: false,
        isVolunteer: false,
        isWorkShadow: false,
        isAlumni: false,
        isBuddyWorkShadow: false,
        noType: false,
        joinedFrom: undefined,
        joinedTo: undefined,
    });
}

export class PeopleSearchParamsModel implements IPeopleSearchParamsModel {
    /** Searches name, quick notes, profile */
    searchText?: string | undefined;
    contactSearchText?: string | undefined;
    isStudent?: boolean | undefined;
    isClient?: boolean | undefined;
    isAdvisor?: boolean | undefined;
    isVolunteer?: boolean | undefined;
    isWorkShadow?: boolean | undefined;
    isAlumni?: boolean | undefined;
    isBuddyWorkShadow?: boolean | undefined;
    noType?: boolean | undefined;
    joinedFrom?: Date | undefined;
    joinedTo?: Date | undefined;

    constructor(data?: IPeopleSearchParamsModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.searchText = Data["searchText"];
            this.contactSearchText = Data["contactSearchText"];
            this.isStudent = Data["isStudent"];
            this.isClient = Data["isClient"];
            this.isAdvisor = Data["isAdvisor"];
            this.isVolunteer = Data["isVolunteer"];
            this.isWorkShadow = Data["isWorkShadow"];
            this.isAlumni = Data["isAlumni"];
            this.isBuddyWorkShadow = Data["isBuddyWorkShadow"];
            this.noType = Data["noType"];
            this.joinedFrom = Data["joinedFrom"] ? new Date(Data["joinedFrom"].toString()) : <any>undefined;
            this.joinedTo = Data["joinedTo"] ? new Date(Data["joinedTo"].toString()) : <any>undefined;
        }
    }

    static fromJS(data: any): PeopleSearchParamsModel {
        data = typeof data === 'object' ? data : {};
        let result = new PeopleSearchParamsModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["searchText"] = this.searchText;
        data["contactSearchText"] = this.contactSearchText;
        data["isStudent"] = this.isStudent;
        data["isClient"] = this.isClient;
        data["isAdvisor"] = this.isAdvisor;
        data["isVolunteer"] = this.isVolunteer;
        data["isWorkShadow"] = this.isWorkShadow;
        data["isAlumni"] = this.isAlumni;
        data["isBuddyWorkShadow"] = this.isBuddyWorkShadow;
        data["noType"] = this.noType;
        data["joinedFrom"] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
        data["joinedTo"] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
        return data;
    }
}

export interface IPeopleSearchParamsModel {
    /** Searches name, quick notes, profile */
    searchText?: string | undefined;
    contactSearchText?: string | undefined;
    isStudent?: boolean | undefined;
    isClient?: boolean | undefined;
    isAdvisor?: boolean | undefined;
    isVolunteer?: boolean | undefined;
    isWorkShadow?: boolean | undefined;
    isAlumni?: boolean | undefined;
    isBuddyWorkShadow?: boolean | undefined;
    noType?: boolean | undefined;
    joinedFrom?: Date | undefined;
    joinedTo?: Date | undefined;
}