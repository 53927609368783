export class PlacementIdList implements IPlacementIdList {
  idList?: string[] | undefined;

  constructor(data?: IPlacementIdList) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["idList"])) {
        this.idList = [] as any;
        for (let item of Data["idList"])
          this.idList!.push(item);
      }
    }
  }

  static fromJS(data: any): PlacementIdList {
    data = typeof data === 'object' ? data : {};
    let result = new PlacementIdList();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.idList)) {
      data["idList"] = [];
      for (let item of this.idList)
        data["idList"].push(item);
    }
    return data;
  }
}

export interface IPlacementIdList {
  idList?: string[] | undefined;
}
