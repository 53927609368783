import {IJobProgressState} from './Reducer';
import {createSelector} from '@reduxjs/toolkit';


export const selectJobProgressState = (state: { jobProgress: IJobProgressState }) => state.jobProgress;

export const selectJobErrorList = createSelector(
  selectJobProgressState,
  (subState) => subState.jobErrorList
);

export const selectJobId = createSelector(
  selectJobProgressState,
  (subState) => subState.jobId
);

export const selectJobError = createSelector(
  selectJobProgressState,
  (subState) => subState.jobError
);

export const selectJobType = createSelector(
  selectJobProgressState,
  (subState) => subState.jobType
);

