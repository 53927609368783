import {useTheme} from '@mui/material';
import {createTheme} from '@mui/material';
import {useMediaQuery} from '@mui/material';
import {Breakpoint} from '@mui/material';
import DateFnsAdapter from "@date-io/date-fns";
import {formatDistanceToNow} from 'date-fns/esm';
import {Theme} from '@mui/material';
import {PermissionLevel} from '../../types/common/Permissions';

const dateFns = new DateFnsAdapter();

type BreakpointOrNull = Breakpoint | null;

export const isBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const defaultTheme = createTheme();
  return defaultTheme.breakpoints.values[key] > window.innerWidth;
};

export const useDownBreakPointChecker = (
  key: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
) => {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down(key));
};

export const useBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

export const useWidth = () => {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export const createRoutes = (routeConfigs: any[]) => {
  let allRoutes: any[] = [];
  routeConfigs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config: any) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map(route => {
      let auth = route.auth
        ? route.auth
        : config.auth;
      return {...route, auth};
    });
  }

  return [...routes];
};
export const getBreakPointsValue = (valueSet: any, breakpoint: string) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};
export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const toUTC = (date: Date) => {
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1 ? dateFns.addMinutes(date, offset) : dateFns.addMinutes(date, Math.abs(offset)*-1);
}

export const timeFromNow = (date: Date) => {
  return formatDistanceToNow(date, { addSuffix: true });
};

export const checkPermission = (
  routeAuth: number | null | undefined,
  isManager: boolean | null | undefined,
  isAdmin: boolean | null | undefined,
) => {
  if (!routeAuth || routeAuth === PermissionLevel.ADVISOR) {
    return true;
  }

  if (routeAuth === PermissionLevel.MANAGER) {
    return isManager || isAdmin;
  }

  if (routeAuth === PermissionLevel.ADMINISTRATOR) {
    return isAdmin;
  }

  return false;
};
