export class CurrentUserModel implements ICurrentUserModel {
    personId?: string | undefined;
    name?: string | undefined;
    picture?: string | undefined;
    email?: string | undefined;
    isAdmin?: boolean | undefined;
    teamId?: string | undefined;
    subTeamId?: string | undefined;
    isManager?: boolean | undefined;
    isMailingListApprover?: boolean | undefined;

    constructor(data?: ICurrentUserModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.personId = Data["personId"];
            this.name = Data["name"];
            this.picture = Data["picture"];
            this.email = Data["email"];
            this.isAdmin = Data["isAdmin"];
            this.teamId = Data["teamId"];
            this.subTeamId = Data["subTeamId"];
            this.isManager = Data["isManager"];
            this.isMailingListApprover = Data["isMailingListApprover"];
        }
    }

    static fromJS(data: any): CurrentUserModel {
        data = typeof data === 'object' ? data : {};
        let result = new CurrentUserModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["personId"] = this.personId;
        data["name"] = this.name;
        data["picture"] = this.picture;
        data["email"] = this.email;
        data["isAdmin"] = this.isAdmin;
        data["teamId"] = this.teamId;
        data["subTeamId"] = this.subTeamId;
        data["isManager"] = this.isManager;
        data["isMailingListApprover"] = this.isMailingListApprover;
        return data;
    }
}

export interface ICurrentUserModel {
    personId?: string | undefined;
    name?: string | undefined;
    picture?: string | undefined;
    email?: string | undefined;
    isAdmin?: boolean | undefined;
    teamId?: string | undefined;
    subTeamId?: string | undefined;
    isManager?: boolean | undefined;
    isMailingListApprover?: boolean | undefined;
}