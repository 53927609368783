export class UniversityModel implements IUniversityModel {
  uniId?: string | undefined;
  title?: string | undefined;
  abbreviation?: string | undefined;
  campuses?: string[] | undefined;

  constructor(data?: IUniversityModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.uniId = Data["uniId"];
      this.title = Data["title"];
      this.abbreviation = Data["abbreviation"];
      if (Array.isArray(Data["campuses"])) {
        this.campuses = [] as any;
        for (let item of Data["campuses"])
          this.campuses!.push(item);
      }
    }
  }

  static fromJS(data: any): UniversityModel {
    data = typeof data === 'object' ? data : {};
    let result = new UniversityModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["uniId"] = this.uniId;
    data["title"] = this.title;
    data["abbreviation"] = this.abbreviation;
    if (Array.isArray(this.campuses)) {
      data["campuses"] = [];
      for (let item of this.campuses)
        data["campuses"].push(item);
    }
    return data;
  }
}

export interface IUniversityModel {
  uniId?: string | undefined;
  title?: string | undefined;
  abbreviation?: string | undefined;
  campuses?: string[] | undefined;
}