import React, {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {Popover, Theme} from '@mui/material';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Scrollbar from '../Scrollbar';
import clsx from 'clsx';
import NotificationItem from './NotificationItem';
import {Fonts} from '../../../shared/constants/AppEnums';
import makeStyles from '@mui/styles/makeStyles';
import {useSelector} from 'react-redux';
import {
  selectNotificationsSummary,
} from '../../../redux/messaging/Selectors';
import {ThreadDetailModel} from '../../../types/models/ThreadDetailModel';
import {useAppDispatch, useAuthUser} from '../../utility/AppHooks';
import {generatePath} from 'react-router';
import {inboxPaths} from '../../../modules/messaging/paths';
import AppNavLink from '../AppNavLink';
import {notificationsLoad} from '../../../redux/messaging/Actions';

const useStyles = makeStyles((theme: Theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('xs')]: {
        width: 300,
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 200,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 380,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('xs')]: {
      height: 70,
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      width: 'auto',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
}));

interface NotificationsProps {}

const Notifications: React.FC<NotificationsProps> = () => {
  const dispatch = useAppDispatch();

  const [init, setInit] = useState(false);

  const notificationSummary = useSelector(selectNotificationsSummary);

  const currentUser = useAuthUser();

  const [
    anchorNotification,
    setAnchorNotification,
  ] = React.useState<HTMLButtonElement | null>(null);

  const onClickNotificationButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorNotification(event.currentTarget);
  };

  const classes = useStyles();

  useEffect(() => {
    if (!init) {
      dispatch(notificationsLoad());
      setInit(true);
    }
  }, [init]);

  const totalUnread = () => {
    if (!notificationSummary || !notificationSummary.summaries) return 0;
    let totalUnRead = notificationSummary.summaries.reduce((a, b) => {
      return a + parseInt(b.unreadEvents ? b.unreadEvents:'0');
    }, 0);
    return totalUnRead < 100 ? totalUnRead : 99;
  }

  return <>
    <IconButton
      className={clsx(classes.notiBtn, 'notiBtn')}
      aria-label='show new notifications'
      color='inherit'
      onClick={onClickNotificationButton}
      >
      <Badge
        className={classes.badge}
        badgeContent={totalUnread()}
        color='secondary'>
        <NotificationsActiveIcon
          className={clsx(classes.notiIcon, 'notiIcon')}
        />
      </Badge>
      <Box
        sx={{ display: {  xs: 'block', sm: 'none' } }}
        ml={4}
        fontSize={16}
        fontWeight={Fonts.REGULAR}
        component='span'>
        Notifications
      </Box>
    </IconButton>

    <Popover
      anchorEl={anchorNotification}
      id='language-switcher'
      className={classes.crPopover}
      keepMounted
      open={Boolean(anchorNotification)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => setAnchorNotification(null)}>
      <Box>
        <Box px={5} py={3}>
          <Box component='h5' fontSize={16}>
             Notifications ({totalUnread()})
          </Box>
        </Box>
        <Scrollbar className='scroll-submenu'>
          <List
            className={classes.list}
            onClick={() => {
              setAnchorNotification(null);
            }}>
            {notificationSummary?.threadList?.map((item: ThreadDetailModel, index) => (
              <NotificationItem key={`tb-not-${index}-${item.id}`} threadItem={item} currentUser={currentUser} />
            ))}
            {!notificationSummary?.threadList?.length && <Box m={4} sx={{ textAlign: 'center' }}>No unread notifications</Box>}
          </List>
        </Scrollbar>
        <Box>
          <Button
            component={AppNavLink}
            className={classes.btnPopover}
            to={generatePath(inboxPaths.InboxView)}
            variant='contained'
            color='primary'>
            View inbox
          </Button>
        </Box>
      </Box>
    </Popover>
  </>;
};

export default Notifications;
