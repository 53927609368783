export interface AdminPaths {
  DevReindexPage: string;
  ManageUnsubscribe: string;
  BulkAdvisorUpdatePage: string;
}

export const adminPaths: AdminPaths = {
  DevReindexPage: '/admin/reindex',
  ManageUnsubscribe: '/admin/unsubscribe',
  BulkAdvisorUpdatePage: '/admin/advisor-update',
};
