import {RefFieldsModel} from './RefFieldsModel';

export class SeasonalActionModel implements ISeasonalActionModel {
  dateCreated?: Date | undefined;
  seasonSemester?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  actionReference?: string | undefined;
  actionName?: string | undefined;
  subCategory?: string | undefined;
  subCategoryRef?: string | undefined;
  dateAction?: string | undefined;
  dateDue?: string | undefined;
  dateActionComplete?: Date | undefined;
  activityId?: string | undefined;
  activityRowKey?: string | undefined;
  completedPersonId?: string | undefined;
  completeActivityId?: string | undefined;
  completeActivityRowKey?: string | undefined;
  isComplete?: boolean | undefined;
  completedStatus?: string | undefined;
  refFields?: RefFieldsModel[] | undefined;

  constructor(data?: ISeasonalActionModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.seasonSemester = Data["seasonSemester"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      this.actionReference = Data["actionReference"];
      this.actionName = Data["actionName"];
      this.subCategory = Data["subCategory"];
      this.subCategoryRef = Data["subCategoryRef"];
      this.dateAction = Data["dateAction"];
      this.dateDue = Data["dateDue"];
      this.dateActionComplete = Data["dateActionComplete"] ? new Date(Data["dateActionComplete"].toString()) : <any>undefined;
      this.activityId = Data["activityId"];
      this.activityRowKey = Data["activityRowKey"];
      this.completedPersonId = Data["completedPersonId"];
      this.completeActivityId = Data["completeActivityId"];
      this.completeActivityRowKey = Data["completeActivityRowKey"];
      this.isComplete = Data["isComplete"];
      this.completedStatus = Data["completedStatus"];
      if (Array.isArray(Data["refFields"])) {
        this.refFields = [] as any;
        for (let item of Data["refFields"])
          this.refFields!.push(RefFieldsModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): SeasonalActionModel {
    data = typeof data === 'object' ? data : {};
    let result = new SeasonalActionModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["seasonSemester"] = this.seasonSemester;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    data["actionReference"] = this.actionReference;
    data["actionName"] = this.actionName;
    data["subCategory"] = this.subCategory;
    data["subCategoryRef"] = this.subCategoryRef;
    data["dateAction"] = this.dateAction;
    data["dateDue"] = this.dateDue;
    data["dateActionComplete"] = this.dateActionComplete ? this.dateActionComplete.toISOString() : <any>undefined;
    data["activityId"] = this.activityId;
    data["activityRowKey"] = this.activityRowKey;
    data["completedPersonId"] = this.completedPersonId;
    data["completeActivityId"] = this.completeActivityId;
    data["completeActivityRowKey"] = this.completeActivityRowKey;
    data["isComplete"] = this.isComplete;
    data["completedStatus"] = this.completedStatus;
    if (Array.isArray(this.refFields)) {
      data["refFields"] = [];
      for (let item of this.refFields)
        data["refFields"].push(item.toJSON());
    }
    return data;
  }
}

export interface ISeasonalActionModel {
  dateCreated?: Date | undefined;
  seasonSemester?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  actionReference?: string | undefined;
  actionName?: string | undefined;
  subCategory?: string | undefined;
  subCategoryRef?: string | undefined;
  dateAction?: string | undefined;
  dateDue?: string | undefined;
  dateActionComplete?: Date | undefined;
  activityId?: string | undefined;
  activityRowKey?: string | undefined;
  completedPersonId?: string | undefined;
  completeActivityId?: string | undefined;
  completeActivityRowKey?: string | undefined;
  isComplete?: boolean | undefined;
  completedStatus?: string | undefined;
  refFields?: RefFieldsModel[] | undefined;
}