import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface StudentPaths {
  StudentEdit: string,
  StudentPromote: string,
  StudentRollback: string,
  StudentCreate: string,
  StudentProfile: string,
  StudentSearch: string,
  EngagementSearch:string,

  PlacementSearch: string,
  EmployerPublicProfileEdit: string,
}

export const studentPaths: StudentPaths = {
  StudentEdit: '/advisor/students/edit/:id',
  StudentPromote: '/advisor/students/promote/:id',
  StudentRollback: '/advisor/students/rollback/:id',
  StudentCreate: '/advisor/students/add',
  StudentProfile: '/advisor/students/profile/:id',
  StudentSearch: '/advisor/students/search',
  EngagementSearch: '/advisor/engagement/search',

  PlacementSearch: '/advisor/placements/search',

  EmployerPublicProfileEdit: '/advisor/students/employer-profile/edit/:id',
};

export interface IStudentProfileTabs {
  activity: RouteTabParameter;
  profile: RouteTabParameter;
  opportunities: RouteTabParameter;
  progression: RouteTabParameter;
  files: RouteTabParameter;
  achievements: RouteTabParameter;
  changeRequests: RouteTabParameter;
}

export const studentProfileTabs: IStudentProfileTabs = {
  activity: new RouteTabParameter(0, 'Activity', 'activity'),
  profile: new RouteTabParameter(1, 'Profile', 'profile'),
  opportunities: new RouteTabParameter(2, 'Opportunities', 'opp'),
  progression: new RouteTabParameter(3, 'Progress', 'progress'),
  files: new RouteTabParameter(4, 'Files', 'files'),
  achievements: new RouteTabParameter(5, 'Achievements', 'achieve'),
  changeRequests: new RouteTabParameter(6, 'Change Requests', 'change')
}

const profileTabs = [
  studentProfileTabs.activity,
  studentProfileTabs.profile,
  studentProfileTabs.progression,
  studentProfileTabs.opportunities,
  studentProfileTabs.files,
  studentProfileTabs.achievements,
  studentProfileTabs.changeRequests,
];


export const getStudentProfileTab = (query: string | undefined | null) => profileTabs.find(x => x.query === query);
