import {ClientOnboardingStepModelSetToStatus} from './Enums';
import {StudentOnboardingStepModel} from './StudentOnboardingStepModel';

export const defaultForClientOnboardingStepModel = () => {
  return ClientOnboardingStepModel.fromJS({
    setTags: [],
    title: '',
    shortTitle: '',
    isAttachmentRequired: false,
    isPrimaryContactMessage: false,
    isVisible: true,
    completedByTasks: [],
  });
}

export class ClientOnboardingStepModel implements IClientOnboardingStepModel {
  /** identity of step */
  stepId?: string | undefined;
  setTags?: string[] | undefined;
  setToStatus?: ClientOnboardingStepModelSetToStatus | undefined;
  /** Title of the step */
  title!: string;
  /** Short name of this step */
  shortTitle!: string;
  isAttachmentRequired?: boolean | undefined;
  skipped?: boolean | undefined;
  /** allows primary contact message on step */
  isPrimaryContactMessage?: boolean | undefined;

  constructor(data?: IClientOnboardingStepModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.stepId = Data["stepId"];
      if (Array.isArray(Data["setTags"])) {
        this.setTags = [] as any;
        for (let item of Data["setTags"])
          this.setTags!.push(item);
      }
      this.setToStatus = Data["setToStatus"];
      this.title = Data["title"];
      this.shortTitle = Data["shortTitle"];
      this.isAttachmentRequired = Data["isAttachmentRequired"];
      this.skipped = Data["skipped"];
      this.isPrimaryContactMessage = Data["isPrimaryContactMessage"];
    }
  }

  static fromJS(data: any): ClientOnboardingStepModel {
    data = typeof data === 'object' ? data : {};
    let result = new ClientOnboardingStepModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["stepId"] = this.stepId;
    if (Array.isArray(this.setTags)) {
      data["setTags"] = [];
      for (let item of this.setTags)
        data["setTags"].push(item);
    }
    data["setToStatus"] = this.setToStatus;
    data["title"] = this.title;
    data["shortTitle"] = this.shortTitle;
    data["isAttachmentRequired"] = this.isAttachmentRequired;
    data["skipped"] = this.skipped;
    data["isPrimaryContactMessage"] = this.isPrimaryContactMessage;
    return data;
  }
}

export interface IClientOnboardingStepModel {
  /** identity of step */
  stepId?: string | undefined;
  setTags?: string[] | undefined;
  setToStatus?: ClientOnboardingStepModelSetToStatus | undefined;
  /** Title of the step */
  title: string;
  /** Short name of this step */
  shortTitle: string;
  isAttachmentRequired?: boolean | undefined;
  skipped?: boolean | undefined;
  /** allows primary contact message on step */
  isPrimaryContactMessage?: boolean | undefined;
}