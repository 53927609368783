import {ContactTagModel} from './ContactTagModel';
import {AddressViewModel} from './AddressViewModel';
import {AdvisorUserModel} from './AdvisorUserModel';

export class ContactDetailModel implements IContactDetailModel {
  companyId?: string | undefined;
  contactTags?: ContactTagModel[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  jobTitle?: string | undefined;
  quickNotes?: string | undefined;
  isPrimary?: boolean | undefined;
  isBilling?: boolean | undefined;
  isStudentContact?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;

  constructor(data?: IContactDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.companyId = Data["companyId"];
      if (Array.isArray(Data["contactTags"])) {
        this.contactTags = [] as any;
        for (let item of Data["contactTags"])
          this.contactTags!.push(ContactTagModel.fromJS(item));
      }
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.jobTitle = Data["jobTitle"];
      this.quickNotes = Data["quickNotes"];
      this.isPrimary = Data["isPrimary"];
      this.isBilling = Data["isBilling"];
      this.isStudentContact = Data["isStudentContact"];
      this.isExecutiveSponsor = Data["isExecutiveSponsor"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(AdvisorUserModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ContactDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new ContactDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["companyId"] = this.companyId;
    if (Array.isArray(this.contactTags)) {
      data["contactTags"] = [];
      for (let item of this.contactTags)
        data["contactTags"].push(item.toJSON());
    }
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["jobTitle"] = this.jobTitle;
    data["quickNotes"] = this.quickNotes;
    data["isPrimary"] = this.isPrimary;
    data["isBilling"] = this.isBilling;
    data["isStudentContact"] = this.isStudentContact;
    data["isExecutiveSponsor"] = this.isExecutiveSponsor;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item.toJSON());
    }
    return data;
  }
}

export interface IContactDetailModel {
  companyId?: string | undefined;
  contactTags?: ContactTagModel[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  jobTitle?: string | undefined;
  quickNotes?: string | undefined;
  isPrimary?: boolean | undefined;
  isBilling?: boolean | undefined;
  isStudentContact?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
}
