export class ImageMetadataModel implements IImageMetadataModel {
  /** Universal file id */
  imageId?: string | undefined;
  /** Image type */
  imageType?: string | undefined;
  /** The id of the person who uploaded or sent this file */
  mimeType?: string | undefined;
  /** Mime type of the file */
  filename?: string | undefined;
  contentType?: string | undefined;

  constructor(data?: IImageMetadataModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.imageId = Data["imageId"];
      this.imageType = Data["imageType"];
      this.mimeType = Data["mimeType"];
      this.filename = Data["filename"];
      this.contentType = Data["contentType"];
    }
  }

  static fromJS(data: any): ImageMetadataModel {
    data = typeof data === 'object' ? data : {};
    let result = new ImageMetadataModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["imageId"] = this.imageId;
    data["imageType"] = this.imageType;
    data["mimeType"] = this.mimeType;
    data["filename"] = this.filename;
    data["contentType"] = this.contentType;
    return data;
  }
}

export interface IImageMetadataModel {
  /** Universal file id */
  imageId?: string | undefined;
  /** Image type */
  imageType?: string | undefined;
  /** The id of the person who uploaded or sent this file */
  mimeType?: string | undefined;
  /** Mime type of the file */
  filename?: string | undefined;
  contentType?: string | undefined;
}