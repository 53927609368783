import {PhoneModel} from '../models/PhoneModel';
import {ContactEditDetailModel} from './ContactEditDetailModel';
import {Gender} from '../models/Enums';

export class ContactPersonEditModel implements IContactPersonEditModel {
  firstName!: string;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  email!: string;
  altEmail?: string | undefined;
  /** Phone numbers for this student */
  phoneNumbers?: PhoneModel[] | undefined;
  /** URL of facebook profile */
  facebookUrl?: string | undefined;
  /** URL of LinkedIn profile */
  linkedinUrl?: string | undefined;
  gender?: Gender | undefined;
  genderPronouns?: string | undefined;
  /** Company contact specific information */
  contactDetail?: ContactEditDetailModel | undefined;

  constructor(data?: IContactPersonEditModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.firstName = Data["firstName"];
      this.middleName = Data["middleName"];
      this.lastName = Data["lastName"];
      this.birthName = Data["birthName"];
      this.email = Data["email"];
      this.altEmail = Data["altEmail"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.facebookUrl = Data["facebookUrl"];
      this.linkedinUrl = Data["linkedinUrl"];
      this.gender = Data["gender"];
      this.genderPronouns = Data["genderPronouns"];
      this.contactDetail = Data["contactDetail"] ? ContactEditDetailModel.fromJS(Data["contactDetail"]) : <any>undefined;
    }
  }

  static fromJS(data: any): ContactPersonEditModel {
    data = typeof data === 'object' ? data : {};
    let result = new ContactPersonEditModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["firstName"] = this.firstName;
    data["middleName"] = this.middleName;
    data["lastName"] = this.lastName;
    data["birthName"] = this.birthName;
    data["email"] = this.email;
    data["altEmail"] = this.altEmail;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item.toJSON());
    }
    data["facebookUrl"] = this.facebookUrl;
    data["linkedinUrl"] = this.linkedinUrl;
    data["gender"] = this.gender;
    data["genderPronouns"] = this.genderPronouns;
    data["contactDetail"] = this.contactDetail ? this.contactDetail.toJSON() : <any>undefined;
    return data;
  }
}

export interface IContactPersonEditModel {
  firstName: string;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  email: string;
  altEmail?: string | undefined;
  /** Phone numbers for this student */
  phoneNumbers?: PhoneModel[] | undefined;
  /** URL of facebook profile */
  facebookUrl?: string | undefined;
  /** URL of LinkedIn profile */
  linkedinUrl?: string | undefined;
  gender?: Gender | undefined;
  genderPronouns?: string | undefined;
  /** Company contact specific information */
  contactDetail?: ContactEditDetailModel | undefined;
}