import React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import HelpIcon from '@mui/icons-material/Help';
import {Popover, Theme, ListItem, Button} from '@mui/material';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Scrollbar from '../../../@template/core/Scrollbar';
import clsx from 'clsx';
import {Fonts} from '../../../shared/constants/AppEnums';
import makeStyles from '@mui/styles/makeStyles';
import EmptyResult from '../../../@template/core/EmptyResult';
import {authLogOut} from '../../../redux/auth/Actions';
import {useDispatch} from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme: Theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('xs')]: {
        width: 300,
      },
      [theme.breakpoints.up('lg')]: {
        width: 300,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 200,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 380,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('xs')]: {
      height: 70,
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      width: 'auto',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
  listItemRoot: {
    padding: '8px 10px',
  },
  userInfo: {
    width: 'calc(100% - 75px)',
  },
}));

interface ChangeRequestProps {}

const HelpDropdown: React.FC<ChangeRequestProps> = () => {
  const dispatch = useDispatch();

  const [
    anchorRequests,
    setAnchorRequests,
  ] = React.useState<HTMLButtonElement | null>(null);

  const onClickRequestsButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorRequests(event.currentTarget);
  };

  const classes = useStyles();

  const helpUrl = {
    help: 'https://app.slack.com/client/T0T9L9QGP/C13L92HFS/thread/G010HNV7GF6-1585281643.022500',
    guide: 'https://drive.google.com/drive/folders/1hm9Oj-dImrm77_KLgHcmwNUs6Hm8EAsd',
    feedback: 'https://forms.gle/v2m1JbBbJqfAm1278'
  }

  const onLogOutClick = () => {
    localStorage.removeItem('INITIAL_URL');
    dispatch(authLogOut());
  }

  return <>
    <IconButton
      className={clsx(classes.notiBtn, 'notiBtn')}
      aria-label='show help & guide pending'
      color='inherit'
      onClick={onClickRequestsButton}
    >
      <Badge
        className={classes.badge}
        color='secondary'>
        <HelpIcon
          className={clsx(classes.notiIcon, 'notiIcon')}
        />
      </Badge>
      <Box
        sx={{ display: {  xs: 'block', sm: 'none' } }}
        ml={4}
        fontSize={16}
        fontWeight={Fonts.REGULAR}
        component='span'>
        Help & Guides
      </Box>
    </IconButton>

    <Popover
      anchorEl={anchorRequests}
      id='change-request'
      className={classes.crPopover}
      keepMounted
      open={Boolean(anchorRequests)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => setAnchorRequests(null)}>
      <Box>
        <Box px={5} py={3}>
          <Box component='h5' fontSize={16}>
            Help & Guides
          </Box>
        </Box>
        <Scrollbar className='scroll-submenu'>
          <List
            className={classes.list}
            onClick={() => {
              setAnchorRequests(null);
            }}>
            <a href={helpUrl.help} target='_blank' style={{textDecoration: 'none'}}>
              <ListItem
                className={clsx(classes.listItemRoot, 'item-hover')}
              >
                <Box mr={4}>

                </Box>
                <Box>
                  <Box
                    mr={2}
                    component='p'
                    color='text.primary'
                    fontWeight={Fonts.MEDIUM}
                  >
                    <strong>Help</strong>
                  </Box>
                </Box>
              </ListItem>
            </a>
            <a href={helpUrl.guide} target='_blank' style={{textDecoration: 'none'}}>
              <ListItem
                className={clsx(classes.listItemRoot, 'item-hover')}
              >
                <Box mr={4}>

                </Box>
                <Box>
                  <Box
                    mr={2}
                    component='p'
                    color='text.primary'
                    fontWeight={Fonts.MEDIUM}
                  >
                    <strong>Guides & Resources</strong>
                  </Box>
                </Box>
              </ListItem>
            </a>
            <a href={helpUrl.feedback} target='_blank' style={{textDecoration: 'none'}}>
              <ListItem
                className={clsx(classes.listItemRoot, 'item-hover')}
              >
                <Box mr={4}>

                </Box>
                <Box>
                  <Box
                    mr={2}
                    component='p'
                    color='text.primary'
                    fontWeight={Fonts.MEDIUM}
                  >
                    <strong>Feedback</strong>
                  </Box>
                </Box>
              </ListItem>
            </a>
            <ListItem
              className={clsx(classes.listItemRoot, 'item-hover')}
              onClick={() => onLogOutClick()}
            >
              <Box mr={4}>

              </Box>
              <Box>
                <Box
                  mr={2}
                  component='p'
                  color='text.primary'
                  fontWeight={Fonts.MEDIUM}
                >
                  <strong>Logout</strong>
                </Box>
              </Box>
            </ListItem>

          </List>
        </Scrollbar>
      </Box>
    </Popover>
  </>;

};

export default HelpDropdown;