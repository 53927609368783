import {
    ICommonSaveActionType,
    ICommonSavePayload,
    ICommonLoadActionType,
  } from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {StudentPlacementModel} from '../../types/models/StudentPlacementModel';
import {PlacementEditDetailModel} from '../../types/requests/PlacementEditDetailModel';
import {PlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {PlacementListByModel} from '../../types/requests/PlacementListByModel';
import {EventItemViewModel} from '../../types/models/EventItemViewModel';
import {SeasonalActionModel} from '../../types/models/SeasonalActionModel';


export interface IPlacementActionTypes extends ICommonSaveActionType {
  REFRESH: string;
}

export interface IPlacementListActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const PLACEMENT: IPlacementActionTypes = {
  LOAD: 'placement/load',
  LOAD_ERROR: 'placement/loadError',
  LOADED: 'placement/loadSuccess',
  REFRESH: 'placement/refresh',
  SAVE: 'placement/save',
  SAVED: 'placement/saveSuccess',
  SAVE_ERROR: 'placement/saveError',
};

export const PLACEMENT_LIST: IPlacementListActionTypes = {
  REFRESH: 'placementList/list/refresh',
  ERROR: 'placementList/list/loadError',
  LOAD: 'placementList/list/load',
  LOADED: 'placementList/list/loadSuccess'
};

export const PLACEMENT_EVALUATIONS: ICommonLoadActionType = {
  ERROR: 'placement/evaluations/error',
  LOAD: 'placement/evaluations/load',
  LOADED: 'placement/evaluations/loadSuccess',
}

export const placementLoad = createAction<string>(PLACEMENT.LOAD);
export const placementRefresh = createAction<string>(PLACEMENT.REFRESH);
export const placementLoadSuccess = createAction<PlacementDetailModel>(PLACEMENT.LOADED);
export const placementLoadError = createAction<string>(PLACEMENT.LOAD_ERROR);

export const placementSave = createAction<ICommonSavePayload<PlacementEditDetailModel>>(PLACEMENT.SAVE);
export const placementSaveSuccess = createAction<StudentPlacementModel>(PLACEMENT.SAVED);
export const placementSaveError = createAction<string>(PLACEMENT.SAVE_ERROR);

export const placementList = createAction<PlacementListByModel>(PLACEMENT_LIST.LOAD);
export const placementListRefresh = createAction<PlacementListByModel>(PLACEMENT_LIST.REFRESH);
export const placementListSuccess = createAction<PlacementDetailModel[]>(PLACEMENT_LIST.LOADED);
export const placementListError = createAction<string>(PLACEMENT_LIST.ERROR);

export const placementEvaluationList = createAction<boolean>(PLACEMENT_EVALUATIONS.LOAD);
export const placementEvaluationListSuccess = createAction<PlacementDetailModel[]>(PLACEMENT_EVALUATIONS.LOADED);
export const placementEvaluationListError = createAction<string>(PLACEMENT_EVALUATIONS.ERROR);