import {apiConfig} from '../../config';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {all, call, put, select, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {GLOBAL_SEARCH, globalSearchLoadError, globalSearchLoadSuccess} from './Actions';
import {GlobalIndexModel} from '../../types/views/GlobalIndexModel';

export function* GlobalSearch(payload: string) {
  try {
    //TODO maybe use queryString.stringify?
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreSettingsPath}/suggest?code=utf-8&query=${payload}`,
    });
    const search = res.data.map((x: any) => GlobalIndexModel.fromJS(x));
    yield put(globalSearchLoadSuccess(search));
  } catch (err) {
    console.log('Global Search error', err);
    yield put(globalSearchLoadError(err));
  }
}

export function* watchGlobalSearch(){
  yield takeLatest(GLOBAL_SEARCH.LOAD, ({ payload }: PayloadAction<string>) => GlobalSearch(payload));
}

export default function* globalSearchSaga() {
  yield all([
    call(watchGlobalSearch),
  ])
}