import {AnyAction, combineReducers, Reducer} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {firebaseAnalytics} from '../@template/utility/AuthProvider/firebaseConfig';
import {logEvent} from 'firebase/analytics';
import rootSaga from './saga';
import authReducer from './auth/Reducer';
import uiReducer from './ui/Reducer';
import sessionReducer from './session/Reducer';
import settingsReducer from './settings/Reducer';
import studentsReducer from './students/Reducer';
import companiesCacheReducer from './companyCache/Reducer';
import filesCacheReducer from './filesCache/Reducer';
import mailingListsCacheReducer from './mailingCache/Reducer';
import opportunitiesReducer from './opportunities/Reducer';
import peopleCacheReducer from './peopleCache/Reducer';
import placementsCacheReducer from './placementCache/Reducer';
import poolsCacheReducer from './poolsCache/Reducer';
import teamReducer from './team/Reducer';
import timelinesReducer from './timelines/Reducer';
import workShadowReducer from './workShadow/Reducer';
import devOperationsReducer from './devOperations/Reducer';
import companiesReducer from './companies/Reducers';
import scorecardReducer from './scorecards/Reducer';
import jobProgressReducer from './jobProgress/Reducer';
import mailingListReducer from './mailingList/Reducer';
import contactsReducer from './contacts/Reducer';
import volunteerReducer from './volunteers/Reducer';
import placementReducer from './placements/Reducer';
import peopleReducer from './people/Reducer';
import messagingReducer from './messaging/Reducer';
import filesReducer from './files/Reducer';
import historyReducer from './history/Reducer';
import changeRequestsReducer from './changeRequest/Reducer';
import globalSearchReducer from './globalSearch/Reducer';
import poolsReducer from './pools/Reducer';
import alumniReducer from './alumni/Reducer';
import engagementReducer from './engagementInfo/Reducer';
import hspOpportunitiesReducer from './hspOpportunities/Reducer';
import hspPlacementReducer from './hspPlacements/Reducer';
import hspPoolsReducer from './hspPools/Reducer';
import hspPlacementsCacheReducer from './hspPlacementCache/Reducer';
import hspPoolsCacheReducer from './hspPoolCache/Reducer';

const createBrowserHistory = require('history').createBrowserHistory;

const history = createBrowserHistory();
history.listen((location: Location) => logEvent(firebaseAnalytics, 'mcleod_screen_view', { pathname: location.pathname }));
const sagaMiddleware = createSagaMiddleware();


export type AppState = ReturnType<typeof rootReducer>;

const combinedReducers: Reducer = combineReducers({
  router: connectRouter(history),
  authLocal: authReducer,
  ui: uiReducer,
  session: sessionReducer,
  settings: settingsReducer,
  students: studentsReducer,
  companies: companiesReducer,
  companiesCache: companiesCacheReducer,
  files: filesReducer,
  filesCache: filesCacheReducer,
  jobProgress: jobProgressReducer,
  mailingCache: mailingListsCacheReducer,
  mailingLists: mailingListReducer,
  messaging: messagingReducer,
  opportunities: opportunitiesReducer,
  peopleCache: peopleCacheReducer,
  placementsCache: placementsCacheReducer,
  poolsCache: poolsCacheReducer,
  pools: poolsReducer,
  teams: teamReducer,
  timelines: timelinesReducer,
  workShadow: workShadowReducer,
  devOperations: devOperationsReducer,
  scorecards: scorecardReducer,
  contacts: contactsReducer,
  volunteers: volunteerReducer,
  placements: placementReducer,
  people: peopleReducer,
  history: historyReducer,
  changeRequests: changeRequestsReducer,
  globalSearch: globalSearchReducer,
  alumni: alumniReducer,
  engagement: engagementReducer,
  hspOpportunity: hspOpportunitiesReducer,
  hspPlacement: hspPlacementReducer,
  hspPlacementCache: hspPlacementsCacheReducer,
  hspPools: hspPoolsReducer,
  hspPoolsCache: hspPoolsCacheReducer,
});

const rootReducer: Reducer = (state: AppState, action: AnyAction) => {
  if (action.type === "root/reset") {
    state = {} as AppState;
  }
  return combinedReducers(state, action);
};


const enhancers = [];
// const windowIfDefined =
//   typeof window === 'undefined' ? null : (window as any);
// if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
//   enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
// }
const middleware = [sagaMiddleware, routerMiddleware(history)];

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export { history }
export default store;


