import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {CurrentUserModel} from '../../types/views/CurrentUserModel';
import {authRefresh} from '../../redux/auth/Actions';
import {selectAuth, selectCurrentUser} from '../../redux/auth/Selectors';
import {AppDispatch, AppState} from '../../redux/store';
import app from './AuthProvider/firebaseConfig';
import {getAuth, onAuthStateChanged, onIdTokenChanged, User} from 'firebase/auth';
import {
  selectCountriesError,
  selectCountriesInit,
  selectCountriesLoading, selectSessionError,
  selectSessionInit,
  selectSessionLoading,
} from '../../redux/session/Selectors';
import {localitiesLoad, sessionLoad} from '../../redux/session/Actions';
import {AppRouteGroupProps} from '../../types/AppRoutesPropType';
import {setAuthToken} from '../services/auth/authApi/authenticatedApi';
import useRecursiveTimeout from './AppPolling';

export const auth = getAuth(app);

// const timeoutInterval = 1000 * 60 * 30;

// isAuthenticated, isAuthLoading, isInitialisingSession
export const useAuthState = (appRoute: AppRouteGroupProps): [boolean, boolean, boolean] => {
  const dispatch = useDispatch();

  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialising, setIsInitialising] = useState(false);

  const [currentToken, setCurrentToken] = useState<string | undefined>(undefined);
  const [tokenExpiry, setTokenExpiry] = useState<string | undefined>(undefined);

  const {loggingIn, refreshing, error, currentUser} = useSelector(selectAuth);

  const isInitialised  = useSelector(selectSessionInit);
  const sessionLoading = useSelector(selectSessionLoading);
  const sessionError = useSelector(selectSessionError);
  const countriesInitialised = useSelector(selectCountriesInit);
  const countriesLoading = useSelector(selectCountriesLoading);
  const countriesError = useSelector(selectCountriesError);

  const timeoutInterval = 1000 * 60 * 50;

  // We still need this because firebase doesn't refresh custom tokens reliably.
  useRecursiveTimeout(() =>
    new Promise(r => {
      console.log('AUTH HOOK - token keep alive');
      if (!isAuthLoading) {
        auth.currentUser?.getIdTokenResult(true);
      }
      r();
    }), timeoutInterval);

  const subscribeAuthStateChanged = (authUser: User | null) => {
    console.log('AUTH HOOK - subscribeAuthStateChanged');
    if (authUser) {
      authUser.getIdTokenResult(true).then((result) => {
        setIsAuthenticated(true);
      }).catch(err => {
        setIsAuthenticated(false);
        setIsAuthLoading(false);
      });
    } else {
      setIsAuthLoading(false);
      setIsAuthenticated(false);
      setIsInitialising(false);
    }
  }

  const subscribeIdTokenChanged = (authUser: User | null) => {
    console.log('AUTH HOOK - subscribeIdTokenChanged');
    if (authUser) {
      authUser.getIdTokenResult().then((result) => {
        setAuthToken(result.token, result.expirationTime);
        setTokenExpiry(result.expirationTime);
        setCurrentToken(result.token);
        setIsAuthenticated(true);
        setIsAuthLoading(false);
      }).catch(err => {
        setTokenExpiry(undefined);
        setCurrentToken(undefined);
        setIsAuthenticated(false);
        setIsAuthLoading(false);
      });
    } else {
      setTokenExpiry(undefined);
      setCurrentToken(undefined);
      setIsAuthLoading(false);
      setIsAuthenticated(false);
      setIsInitialising(false);
    }
  }

  useEffect(() => {
    console.log('AUTH HOOK - First load')
    // Set up listeners required for authentication.
    // This will happen on refresh and app loading

    // Subscribe AuthStateChanged
    onAuthStateChanged(auth, subscribeAuthStateChanged);

    // Subscribe IdTokenChanged
    onIdTokenChanged(auth, subscribeIdTokenChanged);
  }, []);

  useEffect(() => {
    // This will respond to change in the token and checks if the redux data has been initialised
    // console.log('APP HOOKS - Load user effect', isAuthenticated, currentToken, !currentUser);
    if (isAuthenticated && currentToken) {
      dispatch(authRefresh());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // When the current user has loaded for the first time, we may need to refresh the token
    // console.log('APP HOOKS - Load session data effect', currentUser, isInitialising);
    if (!loggingIn && !refreshing && currentUser) {
      if (error) {
        setIsAuthLoading(false);
        setIsAuthenticated(false)
        setIsInitialising(false);
      } else {
        auth.currentUser?.getIdTokenResult(true).then((result) => {
          setAuthToken(result.token, result.expirationTime);
          setTokenExpiry(result.expirationTime);
          setCurrentToken(result.token);
          setIsAuthenticated(true);
        }).catch(err => {
          setIsAuthenticated(false);
          setIsAuthLoading(false);
        });
        if (isAuthenticated) {
          if (!isInitialised && !sessionLoading) {
            setIsInitialising(true);
            dispatch(sessionLoad());
          }
          if (!countriesInitialised && !countriesLoading) {
            setIsInitialising(true);
            dispatch(localitiesLoad());
          }
          setIsAuthLoading(false);
        }
      }
    }
  }, [loggingIn, refreshing]);

  useEffect(() =>  {
    if (countriesError || sessionError) {
      setTokenExpiry(undefined);
      setCurrentToken(undefined);
      setIsAuthLoading(false);
      setIsAuthenticated(false);
    }
  }, [countriesError, sessionError]);

  useEffect(() => {
    console.log('AUTH HOOK - Other data initialise changes');
    if (isInitialised && countriesInitialised) {
      setIsInitialising(false);
    }
  }, [isInitialised, countriesInitialised])

  // useEffect(() => {
  //   console.log(isInitialised, 'isInitialised', countriesInitialised, 'countriesInitialised', loggingIn, 'loggingIn', refreshing, 'refreshing',
  //     currentToken, 'currentToken', isAuthLoading, 'isAuthLoading', isInitialising, 'isInitialising', isAuthenticated, 'isAuthenticated');
  // }, [isInitialised, countriesInitialised, loggingIn, refreshing, currentToken, isAuthLoading, isInitialising, currentUser])

  return [isAuthenticated, isAuthLoading, isInitialising];
};

export const useAuthUser = (): CurrentUserModel | undefined => {
  const currentUser = useSelector(selectCurrentUser);
  if (currentUser) {
    return currentUser;
  }
  return undefined;
};

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
