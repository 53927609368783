export class AlumniEarlyProfessionalHistoryModel implements IAlumniEarlyProfessionalHistoryModel {
  id?: string | undefined;
  personId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  dateEvent?: Date | undefined;
  eventId?: string | undefined;
  stepId?: string | undefined;
  stepTitle?: string | undefined;
  /** The person who did the event. Not always a user */
  eventPersonId?: string | undefined;
  /** Any additional notes */
  additionalInformation?: string | undefined;
  /** The files associated with this step. */
  fileIds?: string[] | undefined;
  skipped?: boolean | undefined;

  constructor(data?: IAlumniEarlyProfessionalHistoryModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.personId = Data["personId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.dateEvent = Data["dateEvent"] ? new Date(Data["dateEvent"].toString()) : <any>undefined;
      this.eventId = Data["eventId"];
      this.stepId = Data["stepId"];
      this.stepTitle = Data["stepTitle"];
      this.eventPersonId = Data["eventPersonId"];
      this.additionalInformation = Data["additionalInformation"];
      if (Array.isArray(Data["fileIds"])) {
        this.fileIds = [] as any;
        for (let item of Data["fileIds"])
          this.fileIds!.push(item);
      }
      this.skipped = Data["skipped"];
    }
  }

  static fromJS(data: any): AlumniEarlyProfessionalHistoryModel {
    data = typeof data === 'object' ? data : {};
    let result = new AlumniEarlyProfessionalHistoryModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["personId"] = this.personId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["dateEvent"] = this.dateEvent ? this.dateEvent.toISOString() : <any>undefined;
    data["eventId"] = this.eventId;
    data["stepId"] = this.stepId;
    data["stepTitle"] = this.stepTitle;
    data["eventPersonId"] = this.eventPersonId;
    data["additionalInformation"] = this.additionalInformation;
    if (Array.isArray(this.fileIds)) {
      data["fileIds"] = [];
      for (let item of this.fileIds)
        data["fileIds"].push(item);
    }
    data["skipped"] = this.skipped;
    return data;
  }
}

export interface IAlumniEarlyProfessionalHistoryModel {
  id?: string | undefined;
  personId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  dateEvent?: Date | undefined;
  eventId?: string | undefined;
  stepId?: string | undefined;
  stepTitle?: string | undefined;
  /** The person who did the event. Not always a user */
  eventPersonId?: string | undefined;
  /** Any additional notes */
  additionalInformation?: string | undefined;
  /** The files associated with this step. */
  fileIds?: string[] | undefined;
  skipped?: boolean | undefined;
}