export class UpdatePipelineResolvedModel implements IUpdatePipelineResolvedModel {
  isIgnored?: boolean | undefined;
  isApplied?: boolean | undefined;

  constructor(data?: IUpdatePipelineResolvedModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.isIgnored = Data["isIgnored"];
      this.isApplied = Data["isApplied"];
    }
  }

  static fromJS(data: any): UpdatePipelineResolvedModel {
    data = typeof data === 'object' ? data : {};
    let result = new UpdatePipelineResolvedModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isIgnored"] = this.isIgnored;
    data["isApplied"] = this.isApplied;
    return data;
  }
}

export interface IUpdatePipelineResolvedModel {
  isIgnored?: boolean | undefined;
  isApplied?: boolean | undefined;
}