import {
  ICommonLoadActionType,
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonSearchActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {PersonModel} from '../../types/models/PersonModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {HSPOpportunityDetailModel} from '../../types/models/HSPOpportunityDetailModel';
import {HSPOpportunityEditDetailModel} from '../../types/requests/HSPOpportunityEditDetailModel';
import {HSPCompanyOpportunitySeasonModel} from '../../types/models/HSPCompanyOpportunitySeasonModel';
import {HSPOpportunitySearchParamsModel} from '../../types/requests/HSPOpportunitySearchParamsModel';
import {HSPOpportunitySearchPaged} from '../../types/models/HSPOpportunitySearchPaged';
import {HSPOpportunityBySeasonRequest} from '../../types/requests/HSPOpportunityBySeasonRequest';
import {HSPOpportunityStudentListView} from '../../types/views/HSPOpportunityStudentListView';
import {HSPOpportunitySummaryModel} from '../../types/models/HSPOpportunitySummaryModel';
import {HSPPoolSearchParamsModel} from '../../types/requests/HSPPoolSearchParamsModel';
import {HSPPoolDetailModel} from '../../types/models/HSPPoolDetailModel';

export interface IHSPOpportunityActionTypes extends ICommonSaveActionType {
  REFRESH: string;
  LOAD_BY_SEASON: string;
  REFRESH_BY_SEASON: string;
}

export interface IHSPOpportunityCompanySeasonsType extends ICommonLoadActionType {
  REFRESH: string;
}

export interface IHSPOpportunityContactSeasonsType extends ICommonLoadActionType {
  REFRESH: string;
}

export interface IHSPCurrentOpportunityTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const HSP_OPPORTUNITY: IHSPOpportunityActionTypes = {
  LOAD: 'hspOpportunity/load',
  LOAD_BY_SEASON: 'hspOpportunity/loadBySeason',
  LOADED: 'hspOpportunity/loadSuccess',
  LOAD_ERROR: 'hspOpportunity/loadError',
  REFRESH: 'hspOpportunity/refresh',
  REFRESH_BY_SEASON: 'hspOpportunity/refreshBySeason',
  SAVE: 'hspOpportunity/save',
  SAVED: 'hspOpportunity/saveSuccess',
  SAVE_ERROR: 'hspOpportunity/saveError',
};

export const HSP_OPPORTUNITY_STUDENT_PROFILES: ICommonLoadActionType = {
  ERROR: 'hspOpportunityStudentProfiles/error',
  LOAD: 'hspOpportunityStudentProfiles/load',
  LOADED: 'hspOpportunityStudentProfiles/loadSuccess'
};

export const HSP_OPPORTUNITY_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'hspOpportunitySearch/view',
  ERROR: 'hspOpportunitySearch/error',
  LOAD: 'hspOpportunitySearch/search',
  LOADED: 'hspOpportunitySearch/searchSuccess',
  REFRESH: 'hspOpportunitySearch/refresh'
};

export const HSP_OPPORTUNITY_LOCALITY_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'hspOpportunityLocalitySearch/view',
  ERROR: 'hspOpportunityLocalitySearch/error',
  LOAD: 'hspOpportunityLocalitySearch/search',
  LOADED: 'hspOpportunityLocalitySearch/searchSuccess',
  REFRESH: 'hspOpportunityLocalitySearch/refresh'
};

export const HSP_COMPANY_OPPORTUNITY_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'hspOpportunityCompanySearch/view',
  ERROR: 'hspOpportunityCompanySearch/error',
  LOAD: 'hspOpportunityCompanySearch/search',
  LOADED: 'hspOpportunityCompanySearch/searchSuccess',
  REFRESH: 'hspOpportunityCompanySearch/refresh'
};

export const HSP_COMPANY_OPPORTUNITY_SEASONS: IHSPOpportunityCompanySeasonsType = {
  ERROR: 'hspOpportunityCompanySeasonsSearch/error',
  LOAD: 'hspOpportunityCompanySeasonsSearch/search',
  LOADED: 'hspOpportunityCompanySeasonsSearch/searchSuccess',
  REFRESH: 'hspOpportunityCompanySeasonsSearch/refresh'
};

export const HSP_OPPORTUNITY_SEASONS: IHSPOpportunityCompanySeasonsType = {
  ERROR: 'hspOpportunityStudentSeasonsSearch/error',
  LOAD: 'hspOpportunityStudentSeasonsSearch/search',
  LOADED: 'hspOpportunityStudentSeasonsSearch/searchSuccess',
  REFRESH: 'hspOpportunityStudentSeasonsSearch/refresh'
};

export const HSP_CONTACT_OPPORTUNITY_SEASONS: IHSPOpportunityContactSeasonsType = {
  ERROR: 'hspOpportunityContactSeasonsSearch/error',
  LOAD: 'hspOpportunityContactSeasonsSearch/search',
  LOADED: 'hspOpportunityContactSeasonsSearch/searchSuccess',
  REFRESH: 'hspOpportunityContactSeasonsSearch/refresh'
};

export const HSP_CURRENT_OPPORTUNITY: IHSPCurrentOpportunityTypes = {
  ERROR: 'hspCurrentCompanyOpportunities/error',
  LOAD: 'hspCurrentCompanyOpportunities/search',
  LOADED: 'hspCurrentCompanyOpportunities/searchSuccess',
  REFRESH: 'hspCurrentCompanyOpportunities/refresh'
}

export const hspOpportunityLoadBySeason = createAction<HSPOpportunityBySeasonRequest>(HSP_OPPORTUNITY.LOAD_BY_SEASON);
export const hspOpportunityLoad = createAction<string>(HSP_OPPORTUNITY.LOAD);
export const hspOpportunityRefreshBySeason = createAction<HSPOpportunityBySeasonRequest>(HSP_OPPORTUNITY.REFRESH_BY_SEASON);
export const hspOpportunityRefresh = createAction<string>(HSP_OPPORTUNITY.REFRESH);
export const hspOpportunityLoadSuccess = createAction<HSPOpportunityStudentListView | undefined>(HSP_OPPORTUNITY.LOADED);
export const hspOpportunityLoadError = createAction<string>(HSP_OPPORTUNITY.LOAD_ERROR);

export const hspOpportunityStudentProfilesLoad = createAction<string[]>(HSP_OPPORTUNITY_STUDENT_PROFILES.LOAD);
export const hspOpportunityStudentProfilesLoadSuccess = createAction<PersonModel[]>(HSP_OPPORTUNITY_STUDENT_PROFILES.LOADED);
export const hspOpportunityStudentProfilesLoadError = createAction<string>(HSP_OPPORTUNITY_STUDENT_PROFILES.ERROR);

export const hspOpportunitySave = createAction<ICommonSavePayload<HSPOpportunityEditDetailModel>>(HSP_OPPORTUNITY.SAVE);
export const hspOpportunitySaveSuccess = createAction<HSPOpportunityDetailModel>(HSP_OPPORTUNITY.SAVED);
export const hspOpportunitySaveError = createAction<string>(HSP_OPPORTUNITY.SAVE_ERROR);

export const hspOpportunitySearchLoad = createAction<ISearchRequest<HSPOpportunitySearchParamsModel>>(HSP_OPPORTUNITY_SEARCH.LOAD);
export const hspOpportunitySearchRefresh = createAction<ISearchRequest<HSPOpportunitySearchParamsModel>>(HSP_OPPORTUNITY_SEARCH.REFRESH);
export const hspOpportunitySearchLoadSuccess = createAction<HSPOpportunitySearchPaged>(HSP_OPPORTUNITY_SEARCH.LOADED);
export const hspOpportunitySearchLoadError = createAction<string>(HSP_OPPORTUNITY_SEARCH.ERROR);

export const hspOpportunityCompanySearchLoad = createAction<ISearchRequest<HSPOpportunitySearchParamsModel>>(HSP_COMPANY_OPPORTUNITY_SEARCH.LOAD);
export const hspOpportunityCompanySearchRefresh = createAction<ISearchRequest<HSPOpportunitySearchParamsModel>>(HSP_COMPANY_OPPORTUNITY_SEARCH.REFRESH);
export const hspOpportunityCompanySearchLoadSuccess = createAction<HSPOpportunityDetailModel[]>(HSP_COMPANY_OPPORTUNITY_SEARCH.LOADED);
export const hspOpportunityCompanySearchLoadError = createAction<string>(HSP_COMPANY_OPPORTUNITY_SEARCH.ERROR);

export const hspOpportunityCompanySeasonsLoad = createAction<string>(HSP_COMPANY_OPPORTUNITY_SEASONS.LOAD);
export const hspOpportunityCompanySeasonsRefresh = createAction<string>(HSP_COMPANY_OPPORTUNITY_SEASONS.REFRESH);
export const hspOpportunityCompanySeasonsLoadSuccess = createAction<HSPCompanyOpportunitySeasonModel[]>(HSP_COMPANY_OPPORTUNITY_SEASONS.LOADED);
export const hspOpportunityCompanySeasonsLoadError = createAction<string>(HSP_COMPANY_OPPORTUNITY_SEASONS.ERROR);

export const hspOpportunityStudentSeasonsLoad = createAction<string>(HSP_OPPORTUNITY_SEASONS.LOAD);
export const hspOpportunityStudentSeasonsRefresh = createAction<string>(HSP_OPPORTUNITY_SEASONS.REFRESH);
export const hspOpportunityStudentSeasonsLoadSuccess = createAction<HSPOpportunitySummaryModel[]>(HSP_OPPORTUNITY_SEASONS.LOADED);
export const hspOpportunityStudentSeasonsLoadError = createAction<string>(HSP_OPPORTUNITY_SEASONS.ERROR);

export const hspOpportunityContactSeasonsLoad = createAction<string>(HSP_CONTACT_OPPORTUNITY_SEASONS.LOAD);
export const hspOpportunityContactSeasonsRefresh = createAction<string>(HSP_CONTACT_OPPORTUNITY_SEASONS.REFRESH);
export const hspOpportunityContactSeasonsLoadSuccess = createAction<HSPOpportunitySummaryModel[]>(HSP_CONTACT_OPPORTUNITY_SEASONS.LOADED);
export const hspOpportunityContactSeasonsLoadError = createAction<string>(HSP_CONTACT_OPPORTUNITY_SEASONS.ERROR);

export const hspOpportunityLocalitySearchLoad = createAction<ISearchRequest<HSPOpportunitySearchParamsModel>>(HSP_OPPORTUNITY_LOCALITY_SEARCH.LOAD);
export const hspOpportunityLocalitySearchRefresh = createAction<ISearchRequest<HSPOpportunitySearchParamsModel>>(HSP_OPPORTUNITY_LOCALITY_SEARCH.REFRESH);
export const hspOpportunityLocalitySearchLoadSuccess = createAction<HSPPoolDetailModel[]>(HSP_OPPORTUNITY_LOCALITY_SEARCH.LOADED);
export const hspOpportunityLocalitySearchLoadError = createAction<string>(HSP_OPPORTUNITY_LOCALITY_SEARCH.ERROR);

export const hspCurrentOpportunityLoad = createAction<HSPOpportunityBySeasonRequest>(HSP_CURRENT_OPPORTUNITY.LOAD);
export const hspCurrentOpportunityRefresh = createAction<HSPOpportunityBySeasonRequest>(HSP_CURRENT_OPPORTUNITY.REFRESH);
export const hspCurrentOpportunityLoadSuccess = createAction<HSPOpportunityStudentListView | undefined>(HSP_CURRENT_OPPORTUNITY.LOADED);
export const hspCurrentOpportunityLoadError = createAction<string>(HSP_CURRENT_OPPORTUNITY.ERROR);