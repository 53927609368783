import {AppState} from '../store';
import {hasItems} from '../../@template/helpers/arrays';
import {apiConfig} from '../../config';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {FileMetaModel} from '../../types/models/FileMetaModel';
import {FILE_CACHE, fileCacheError, fileCacheMerge, fileCacheUpdate} from './Actions';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IFilesCacheState} from './Reducer';
import {PayloadAction} from '@reduxjs/toolkit';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const fileCacheState = (state: AppState) => state.filesCache;

export function* LoadFileCacheItems(idList: string[]) {
  try {
    if (hasItems(idList)) {
      const res = yield authApi.request({
        baseURL: apiConfig.filesUrl,
        method: 'PUT',
        url: `${apiConfig.filesPath}/list`,
        data: { id_list: idList }
      });
      const files: FileMetaModel[] = res.data.map((x: any) => FileMetaModel.fromJS(x));
      yield put(fileCacheMerge(files));
    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(fileCacheError(err));
  }
}

export function* AddFileCacheItems() {
  console.log('SAGA - fileCacheSaga - AddFileCacheItems');
  yield call(delay, 200);
  const currentFileCache: IFilesCacheState = yield select(fileCacheState);
  const currentPendingList = currentFileCache.filePendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    yield put(fileCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadFileCacheItems(){
  yield takeEvery(FILE_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadFileCacheItems(payload));
}

export function* watchAddFileCacheItems(){
  yield takeEvery(FILE_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddFileCacheItems());
}

export default function* fileCacheSaga() {
  yield all([
    call(watchLoadFileCacheItems),
    call(watchAddFileCacheItems),
  ])
}