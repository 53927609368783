import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface ReportingPaths {
  StudentActivity: string,
  WorkShadowActivity: string,
  HiddenStudentActivity: string,
}

export const reportingPaths: ReportingPaths = {
  StudentActivity: '/advisor/reporting/students-activity',
  WorkShadowActivity: '/advisor/reporting/work-shadow-activity',
  HiddenStudentActivity: '/advisor/reporting/hidden/students-activity',
}

export interface IStudentReportingProfileTabs {
  activityGraphs: RouteTabParameter;
  onBoardingSummary: RouteTabParameter;
  pipelineSummary: RouteTabParameter;
  opportunityStudentLocationSummary: RouteTabParameter;
  internSummary: RouteTabParameter;
  totalSummary: RouteTabParameter;
  atRiskSummary: RouteTabParameter;
  degreeRelevance: RouteTabParameter;
  internshipReviewForecast: RouteTabParameter;
  university: RouteTabParameter;
}

export const studentReportingProfileTabs: IStudentReportingProfileTabs = {
  activityGraphs: new RouteTabParameter(0, 'Activity Graphs', 'activity'),
  onBoardingSummary: new RouteTabParameter(1, 'Onboarding Summary', 'onboarding'),
  pipelineSummary: new RouteTabParameter(2, 'Pipeline Summary', 'pipeline'),
  opportunityStudentLocationSummary: new RouteTabParameter(3, 'Student Opportunity Status Summary', 'opportunity-student-location'),
  internSummary: new RouteTabParameter(4, 'Intern Summary', 'intern'),
  totalSummary: new RouteTabParameter(5, 'Total Summary', 'total'),
  atRiskSummary: new RouteTabParameter(6, 'At Risk Summary', 'risk'),
  degreeRelevance: new RouteTabParameter(7, 'Degree Relevance', 'degree-relevance'),
  internshipReviewForecast: new RouteTabParameter(5, 'Internship Review and Forecast', 'internship-review-forecast'),
  university: new RouteTabParameter(6, 'University Report', 'university'),
}

const studentProfileTabs = [
  studentReportingProfileTabs.activityGraphs,
  studentReportingProfileTabs.onBoardingSummary,
  studentReportingProfileTabs.pipelineSummary,
  studentReportingProfileTabs.opportunityStudentLocationSummary,
  studentReportingProfileTabs.internSummary,
  studentReportingProfileTabs.totalSummary,
  studentReportingProfileTabs.atRiskSummary,
  studentReportingProfileTabs.degreeRelevance,
  studentReportingProfileTabs.internshipReviewForecast,
  studentReportingProfileTabs.university,
];

export const getStudentReportingProfileTab = (query: string | undefined | null) => studentProfileTabs.find(x => x.query === query);


export interface IWorkShadowReportingProfileTabs {
  onBoardingSummary: RouteTabParameter;
}

export const workShadowReportingProfileTabs: IWorkShadowReportingProfileTabs = {
  onBoardingSummary: new RouteTabParameter(0, 'Onboarding Summary', 'onboarding'),
}

const workShadowProfileTabs = [
  studentReportingProfileTabs.onBoardingSummary,
];


export const getWorkShadowReportingProfileTab = (query: string | undefined | null) => workShadowProfileTabs.find(x => x.query === query);

export interface IHiddenStudentReportingProfileTabs {
  placementBySeason: RouteTabParameter;
  studentJourney: RouteTabParameter;
  taskExportDump: RouteTabParameter;
  taskExportDetailed: RouteTabParameter;
  averageTaskCompletionTime: RouteTabParameter;
  internshipReviewForecast: RouteTabParameter;
  university: RouteTabParameter;
}

export const hiddenReportingProfileTabs: IHiddenStudentReportingProfileTabs = {
  placementBySeason: new RouteTabParameter(0, 'Placement By Season', 'placement'),
  studentJourney: new RouteTabParameter(1, 'Student Journey [BETA]', 'journey'),
  taskExportDump: new RouteTabParameter(2, 'Task Export [TEMP]', 'task'),
  taskExportDetailed: new RouteTabParameter(3, 'Detailed Task Export [TEMP]', 'detailed-task'),
  averageTaskCompletionTime: new RouteTabParameter(4, 'Average Task Completion Time', 'complete-time'),
  internshipReviewForecast: new RouteTabParameter(5, 'Internship Review and Forecast', 'internship-review-forecast'),
  university: new RouteTabParameter(6, 'University Report', 'university'),
}

const hiddenStudentProfileTabs = [
  hiddenReportingProfileTabs.placementBySeason,
  hiddenReportingProfileTabs.studentJourney,
  hiddenReportingProfileTabs.taskExportDump,
  hiddenReportingProfileTabs.taskExportDetailed,
  hiddenReportingProfileTabs.averageTaskCompletionTime,
  hiddenReportingProfileTabs.internshipReviewForecast,
  hiddenReportingProfileTabs.university,
];

export const getHiddenStudentReportingProfileTab = (query: string | undefined | null) => hiddenStudentProfileTabs.find(x => x.query === query);
