import {all, call, takeEvery, put, select} from '@redux-saga/core/effects';
import {AUTH, authSuccess, authRefresh, authError} from './Actions';
import authApi, {setAuthToken} from '../../@template/services/auth/authApi/authenticatedApi';
import {CurrentUserModel} from '../../types/views/CurrentUserModel';
import {selectCurrentUser} from './Selectors';
import {authLogoutUser} from '../../@template/utility/AuthProvider';

export function* CheckAuthenticatedUser() {
  console.log('SAGA - authSaga - CheckAuthenticatedUser');
  const currentUser = yield select(selectCurrentUser);
  if (!currentUser) {
    yield put(authRefresh());
  } else {
    yield put(authSuccess(currentUser));
  }
}

export function* LoginAuthenticatedUser() {
  console.log('SAGA - authSaga - LoginAuthenticatedUser');
  try {
    const res = yield authApi.get('/auth');
    const authUser: CurrentUserModel = CurrentUserModel.fromJS(res.data);
    yield put(authSuccess(authUser));
  } catch (e) {
    yield put(authError(e.toString()))
  }
}

/*
This is redundant due to single sign on. Leaving in place
to simplify refactoring if required.
 */
export function* RefreshAuthenticatedUser() {
  console.log('SAGA - authSaga - RefreshAuthenticatedUser');
  try {
    const res = yield authApi.get('/auth');
    const authUser: CurrentUserModel = CurrentUserModel.fromJS(res.data);
    yield put(authSuccess(authUser));
  } catch (e) {
    yield put(authError(e.toString()))
  }
}

export function* Logout() {
  console.log('SAGA - authSaga - Logout');
  yield call(setAuthToken,null, null);
  yield authLogoutUser();
  yield put({ type: 'root/reset' });
}


export function* watchLoginAuthenticatedUser(){
  yield takeEvery(AUTH.LOGIN, LoginAuthenticatedUser);
}

export function* watchRefreshAuthenticatedUser(){
  yield takeEvery(AUTH.REFRESH, RefreshAuthenticatedUser);
}

export function* watchCheckAuthenticatedUser() {
  yield takeEvery(AUTH.CHECK, CheckAuthenticatedUser);
}

export function* watchLogout() {
  yield takeEvery(AUTH.LOGOUT, Logout)
}

export default function* authSaga() {
  yield all ([
    call(watchCheckAuthenticatedUser),
    call(watchLoginAuthenticatedUser),
    call(watchRefreshAuthenticatedUser),
    call(watchLogout),
  ])
}