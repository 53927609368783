import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {scorecardPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const sectionBreadcrumbs = [new BreadcrumbItemProps("Reporting", scorecardPaths.RecruitmentScorecard)];

const scorecardRoutes = [
  new AppRouteProps("Recruitment Scorecard", scorecardPaths.RecruitmentScorecard, React.lazy(() => import('./RecruitmentScorecard')), sectionBreadcrumbs),
  new AppRouteProps("Engagement Scorecard", scorecardPaths.EngagementScorecard, React.lazy(() => import('./EngagementScorecard')), sectionBreadcrumbs),
  new AppRouteProps("Internship Scorecard", scorecardPaths.InternshipScorecard, React.lazy(() => import('./InternshipScorecard')), sectionBreadcrumbs),
  new AppRouteProps("Company Internship Scorecard", scorecardPaths.CompanyInternshipScorecard, React.lazy(() => import('./CompanyInternshipScorecard')), sectionBreadcrumbs),
  new AppRouteProps("HSP Engagement Scorecard", scorecardPaths.HSPEngagementScorecard, React.lazy(() => import('./HSPEngagementScorecard')), sectionBreadcrumbs),
]

const scorecardRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, scorecardRoutes)
];

export default scorecardRoutesConfig;
