export interface BreakSettingsPaths {
  BreakSettingsEdit: string,
  BreakSettingsCreate: string,
  BreakSettingsManage: string,
}

export const breakSettingsPath: BreakSettingsPaths = {
  BreakSettingsEdit: '/settings/break-settings/edit/:id',
  BreakSettingsCreate: '/settings/break-settings/add',
  BreakSettingsManage: '/settings/break-settings/manage',
};
