import {all, call, takeLatest} from 'redux-saga/effects';
import {createDraftSafeSelector, PayloadAction} from '@reduxjs/toolkit';
import {
  ActivityGroupSearchModel,
  defaultForActivityGroupSearchModel,
} from '../../types/requests/ActivityGroupSearchModel';
import {IThreadMetadataRequest} from '../../types/requests/ThreadMetadataRequest';
import {ActivityEventSearchModel} from '../../types/requests/ActivityEventSearchModel';
import {
  DRAFT,
  DRAFT_LIST, GROUP_NOTIFICATIONS,
  GROUPS,
  groupsLoadError,
  groupsLoadSuccess, groupsNotificationLoadError, groupsNotificationLoadSuccess,
  groupsThreadsLoadError,
  groupsThreadsLoadSuccess,
  MESSAGE,
  MESSAGE_FEED,
  messageDraftListLoadError,
  messageDraftListLoadSuccess,
  messageDraftListRefresh,
  messageDraftLoadError,
  messageDraftLoadSuccess,
  messageDraftSaveError,
  messageError,
  messageFeedError, messageFeedHistoryLoadSuccess,
  messageFeedLoadSuccess,
  messageSaveSuccess,
  NOTIFICATIONS,
  notificationsLoadError,
  notificationsLoadSuccess, TASK_THREAD_LIST, taskThreadsError, taskThreadsLoaded,
  THREAD, threadLoadError, threadLoadSuccess,
  threadMetadataLoadError,
  threadMetadataLoadSuccess,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {put, select} from '@redux-saga/core/effects';
import {ActivityGroupModel} from '../../types/models/ActivityGroupModel';
import {ThreadDetailModel} from '../../types/models/ThreadDetailModel';
import {InboxSummary} from '../../types/views/InboxSummary';
import {ThreadMetadataModel} from '../../types/views/ThreadMetadataModel';
import {EventItemViewModel} from '../../types/models/EventItemViewModel';
import {IDraftIdentifier, IDraftSaveRequest, IMessageSaveRequest} from '../../types/requests/MessageSaveRequest';
import {MessagingInternalContext} from '../../types/models/Enums';
import {MessageDraftModel} from '../../types/requests/MessageDraftModel';
import {MessageDraftCompletion} from '../../types/requests/MessageDraftCompletion';
import {ThreadTaskList} from '../../types/models/ThreadTaskList';
import {ThreadExcludingTaskListModel, ThreadExcludingTaskModel} from '../../types/requests/ThreadExcludingTaskModel';
import {IMessagingState} from './Reducer';
import {hasItems} from "../../@template/helpers/arrays";

const messageState = (state: { messaging: IMessagingState }) => state.messaging;

function* InboxGroupsList(searchParams: ActivityGroupSearchModel) {
  console.log('SAGA - messageSaga - InboxGroupsList');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'PUT',
      url: `${apiConfig.messagingInboxPath}/groups`,
      data: searchParams
    });
    const groups = res.data.map((x: any) => ActivityGroupModel.fromJS(x));
    yield put(groupsLoadSuccess(groups));
  } catch (error) {
    yield put(groupsLoadError(error));
  }
}

function* InboxGroupsNotificationList() {
  console.log('SAGA - messageSaga - InboxGroupsNotificationList');
  const searchParams = defaultForActivityGroupSearchModel();
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'PUT',
      url: `${apiConfig.messagingInboxPath}/groups/unread-messages`,
      data: searchParams
    });
    const groups = res.data.map((x: any) => ActivityGroupModel.fromJS(x));
    yield put(groupsNotificationLoadSuccess(groups));
  } catch (error) {
    yield put(groupsNotificationLoadError(error));
  }
}

function* InboxGroupThreadsList(groupId: string) {
  console.log('SAGA - messageSaga - InboxGroupThreadsList');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'GET',
      url: `${apiConfig.messagingInboxPath}/groups/${groupId}/threads`,
    });
    const threads = res.data.map((x: any) => ThreadDetailModel.fromJS(x));
    yield put(groupsThreadsLoadSuccess(threads));
  } catch (error) {
    yield put(groupsThreadsLoadError(error));
  }
}

function* InboxSummaryLoad() {
  console.log('SAGA - messageSaga - InboxSummaryLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'GET',
      url: `${apiConfig.messagingInboxPath}/summary`,
    });
    const summary = InboxSummary.fromJS(res.data);
    yield put(notificationsLoadSuccess(summary));
  } catch (error) {
    yield put(notificationsLoadError(error));
  }
}

function* ThreadMetadataLoad({ contextId, threadContext }: IThreadMetadataRequest) {
  // This is to allow incorrectly labeled threads in messaging to not throw errors
  if (threadContext === 'InternalPool' && contextId.substr(0, 1) === 'o' ){
    contextId = contextId.substr(2);
    threadContext = 'InternalOpportunity';
  }
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.threadMetadataPath}/${threadContext}/${contextId}`,
    });
    const threadMetadata = ThreadMetadataModel.fromJS(res.data);
    yield put(threadMetadataLoadSuccess(threadMetadata));
  } catch (error) {
    yield put(threadMetadataLoadError(error));
  }
}

function* ThreadSearch(searchParams: ActivityEventSearchModel) {
  console.log('SAGA - messageSaga - ThreadSearch');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'PUT',
      url: `${apiConfig.messagingEventsPath}/search`,
      data: searchParams
    });
    const messageList = res.data.map((x: any) => EventItemViewModel.fromJS(x));
    yield put(messageFeedLoadSuccess(messageList));
  } catch (error) {
    yield put(messageFeedError(error));
  }
}

function* ThreadMarkRead(threadId: string) {
  console.log('SAGA - messageSaga - ThreadMarkRead');
  try {
    yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'GET',
      url: `${apiConfig.messagingInboxPath}/threads/${threadId}/read`,
    });
  } catch (error) {
    console.log(error);
  }
}

function* ThreadLoad(threadId: string) {
  console.log('SAGA - messageSaga - ThreadLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.threadMetadataPath}/threads/${threadId}`,
    });
    const thread = ThreadDetailModel.fromJS(res.data);
    yield put(threadLoadSuccess(thread));
  } catch (error) {
    yield put(threadLoadError(error));
  }
}

function* ThreadHistoryLoad(searchParams: ActivityEventSearchModel) {
  console.log('SAGA - messageSaga - ThreadHistoryLoad');
  try {
    const currentMessageState = yield select(messageState);
    searchParams.beforeDate = currentMessageState.messageListStart;
    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'PUT',
      url: `${apiConfig.messagingEventsPath}/search?h=1`,
      data: searchParams
    });
    const messageList = res.data.map((x: any) => EventItemViewModel.fromJS(x));
    yield put(messageFeedHistoryLoadSuccess(messageList));
  } catch (error) {
    yield put(messageFeedError(error));
  }
}

function* ThreadHistoryLoadAll(searchParams: ActivityEventSearchModel) {
  console.log('SAGA - messageSaga - ThreadHistoryLoadAll');
  try {
    let allMessageList: EventItemViewModel[] = [];
    let historyOldest = new Date();
    const currentMessageState = yield select(messageState);
    let startingDate = currentMessageState.messageListStart;
    while (startingDate.toDateString() !== historyOldest.toDateString()) {
      searchParams.beforeDate = currentMessageState.messageListStart;
      while (historyOldest.toDateString() !== searchParams.beforeDate.toDateString()) {
        const res = yield authApi.request({
          baseURL: apiConfig.messagingUrl,
          method: 'PUT',
          url: `${apiConfig.messagingEventsPath}/search?h=1`,
          data: searchParams
        });
        let messageList = res.data.map((x: any) => EventItemViewModel.fromJS(x));
        historyOldest = messageList[messageList.length - 1].dtCreated;
        allMessageList = [...allMessageList, ...messageList];
      }
    }

    yield put(messageFeedHistoryLoadSuccess(allMessageList));
    // let historyOldest = messageList[messageList.length - 1].dtCreated;
    // let messageListChanged = messageList;
    // while (hasItems(messageList)) {
    //   const historyOldest = messageList[messageList.length - 1].dtCreated;
    //   let currentHistoryList = currentMessageState.messageList;
    //   let newHistoryList: Map<string, EventItemViewModel> = new Map(currentHistoryList.map((x) => { return [x.id??'', x]}));
    //   messageList.forEach((item: EventItemViewModel) => {newHistoryList.set(item.id??'', item)});
    //   // This approach ensures all new values for existing messages overwrites old values
    //   messageListChanged = [...newHistoryList.values()];
    //
    //   searchParams.beforeDate = currentMessageState.messageListStart;
    //   const res = yield authApi.request({
    //     baseURL: apiConfig.messagingUrl,
    //     method: 'PUT',
    //     url: `${apiConfig.messagingEventsPath}/search?h=1`,
    //     data: searchParams
    //   });
    // }
  } catch (error) {
    yield put(messageFeedError(error));
  }
}

function* MessageSave({ messageContext, messageRequest, threadId }: IMessageSaveRequest) {
  console.log('SAGA - messageSaga - MessageSave');
  let path = '';
  let contextId = '';
  switch (messageContext) {
    case MessagingInternalContext.STUDENT:
      path = apiConfig.coreStudentPath;
      contextId = threadId.replace('s_', '');
      break;
    case MessagingInternalContext.COMPANY:
      path = apiConfig.coreCompaniesPath;
      contextId = threadId.replace('c_', '');
      break;
    case MessagingInternalContext.CONTACT:
      path = apiConfig.coreContactPath;
      contextId = threadId.replace('p_', '');
      break;
    case MessagingInternalContext.OPPORTUNITY:
      path = apiConfig.coreOpportunitiesPath;
      contextId = threadId.replace('o_', '');
      break;
    case MessagingInternalContext.INTERNSHIP:
      path = apiConfig.corePlacementsPath;
      contextId = threadId.replace('i_', '');
      break;
    case MessagingInternalContext.VOLUNTEER:
      path = apiConfig.coreVolunteerPath;
      contextId = threadId.replace('v_', '');
      break;
    case MessagingInternalContext.WORK_SHADOW:
      path = apiConfig.coreWorkShadowPath;
      contextId = threadId.replace('w_', '');
      break;
    case MessagingInternalContext.MAILING:
      path = apiConfig.coreMailingPath;
      contextId = threadId.replace('m_', '');
      break;
    default:
      yield put(messageError('Context not understood'));
      return;
  }
  try {
    yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: `${apiConfig.coreUrl}${path}/${contextId}/message`,
      data: messageRequest
    });
    yield put(messageSaveSuccess());
  } catch (error) {
    yield put(messageFeedError(error));
  }
}

function* MessageDraftSave({ threadId, draftContext, messageRequest }: IDraftSaveRequest) {
  console.log('SAGA - messageSaga - MessageDraftSave');
  try {
    yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: `${apiConfig.coreMessagingPath}/message-draft/${threadId}/${draftContext}`,
      data: messageRequest
    });
    yield put(messageDraftListRefresh());
  } catch (error) {
    yield put(messageDraftSaveError(error));
  }
}

function* MessageDraftLoad({threadId, draftContext}: IDraftIdentifier) {
  console.log('SAGA - messageSaga - MessageDraftLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreMessagingPath}/message-draft/${threadId}/${draftContext}`,
    });
    let draft: MessageDraftModel | undefined;
    if (res.data) draft = MessageDraftModel.fromJS(res.data);
    yield put(messageDraftLoadSuccess(draft));
  } catch (error) {
    yield put(messageDraftLoadError(error));
  }
}

function* MessageDraftDelete({threadId, draftContext}: IDraftIdentifier) {
  console.log('SAGA - messageSaga - MessageDraftDelete');
  try {
    const submission = MessageDraftCompletion.fromJS({
      isCompleted: false,
      isCancelled: true,
    });
    yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: `${apiConfig.coreMessagingPath}/message-draft/${threadId}/${draftContext}`,
      data: submission,
    });
    yield put(messageDraftListRefresh());
  } catch (error) {
    console.log(error);
  }
}

function* MessageDraftComplete({threadId, draftContext}: IDraftIdentifier) {
  console.log('SAGA - messageSaga - MessageDraftComplete');
  try {
    const submission = MessageDraftCompletion.fromJS({
      isCompleted: true,
      isCancelled: false,
    });
    yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: `${apiConfig.coreMessagingPath}/message-draft/${threadId}/${draftContext}`,
      data: submission,
    });
    yield put(messageDraftListRefresh());
  } catch (error) {
    console.log(error);
  }
}

function* MessageDraftIdListLoad() {
  console.log('SAGA - messageSaga - MessageDraftIdListLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreMessagingPath}/message-draft`,
    });

    yield put(messageDraftListLoadSuccess(res.data));
  } catch (error) {
    yield put(messageDraftListLoadError(error));
  }
}

function* MessageDraftIdListRefresh() {
  console.log('SAGA - messageSaga - MessageDraftIdListRefresh');
  /*
  * This differs from above by not checking the drafts have expired or not (significantly faster to run)
  * */
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: `${apiConfig.coreMessagingPath}/message-draft`,
    });
    yield put(messageDraftListLoadSuccess(res.data));
  } catch (error) {
    yield put(messageDraftListLoadError(error));
  }
}

function* LoadTaskList() {
  try {
    const submission: ThreadExcludingTaskListModel = new ThreadExcludingTaskListModel();
    submission.threadList = [
      ThreadExcludingTaskModel.fromJS({
        actionReference: 'InternshipSharedTaskAssigned',
        eventSubCategory: 'InternEvaluation'
      })
    ];

    const res = yield authApi.request({
      baseURL: apiConfig.messagingUrl,
      method: 'POST',
      url: `${apiConfig.messagingInboxPath}/tasks/internal`,
      data: submission,
    });

    const result: ThreadTaskList[] = res.data.map((x: any) => ThreadTaskList.fromJS(x));

    yield put(taskThreadsLoaded(result));
  } catch (error) {
    yield put(taskThreadsError(error));
  }
}

export function* watchInboxGroupsList() {
  yield takeLatest(GROUPS.LOAD, ({payload}: PayloadAction<ActivityGroupSearchModel>) => InboxGroupsList(payload));
}

export function* watchInboxGroupsRefreshList() {
  yield takeLatest(GROUPS.REFRESH, ({payload}: PayloadAction<ActivityGroupSearchModel>) => InboxGroupsList(payload));
}

export function* watchInboxGroupsNotificationList() {
  yield takeLatest(GROUP_NOTIFICATIONS.LOAD, () => InboxGroupsNotificationList());
}

export function* watchInboxGroupsNotificationRefreshList() {
  yield takeLatest(GROUP_NOTIFICATIONS.REFRESH, () => InboxGroupsNotificationList());
}

export function* watchInboxGroupThreadsList() {
  yield takeLatest(GROUPS.THREADS_LOAD, ({payload}: PayloadAction<string>) => InboxGroupThreadsList(payload));
}

export function* watchInboxSummaryLoad() {
  yield takeLatest(NOTIFICATIONS.LOAD, () => InboxSummaryLoad());
}

export function* watchInboxSummaryRefresh() {
  yield takeLatest(NOTIFICATIONS.REFRESH, () => InboxSummaryLoad());
}

export function* watchThreadMetadataLoad() {
  yield takeLatest(THREAD.METADATA_LOAD, ({payload}: PayloadAction<IThreadMetadataRequest>) => ThreadMetadataLoad(payload));
}

export function* watchThreadSearch() {
  yield takeLatest(MESSAGE_FEED.LOAD, ({payload}: PayloadAction<ActivityEventSearchModel>) => ThreadSearch(payload));
}

export function* watchThreadRefreshSearch() {
  yield takeLatest(MESSAGE_FEED.REFRESH, ({payload}: PayloadAction<ActivityEventSearchModel>) => ThreadSearch(payload));
}

export function* watchThreadHistoryLoad() {
  yield takeLatest(MESSAGE_FEED.HISTORY_LOAD, ({payload}: PayloadAction<ActivityEventSearchModel>) => ThreadHistoryLoad(payload));
}

export function* watchThreadHistoryLoadAll() {
  yield takeLatest(MESSAGE_FEED.HISTORY_LOAD_ALL, ({payload}: PayloadAction<ActivityEventSearchModel>) => ThreadHistoryLoadAll(payload));
}

export function* watchThreadMarkRead() {
  yield takeLatest(THREAD.READ, ({payload}: PayloadAction<string>) => ThreadMarkRead(payload));
}

export function* watchThreadLoad() {
  yield takeLatest(THREAD.LOAD, ({payload}: PayloadAction<string>) => ThreadLoad(payload));
}

export function* watchThreadRefresh() {
  yield takeLatest(THREAD.LOAD, ({payload}: PayloadAction<string>) => ThreadLoad(payload));
}

export function* watchMessageSave() {
  yield takeLatest(MESSAGE.SAVE, ({payload}: PayloadAction<IMessageSaveRequest>) => MessageSave(payload));
}

export function* watchMessageDraftSave() {
  yield takeLatest(DRAFT.SAVE, ({payload}: PayloadAction<IDraftSaveRequest>) => MessageDraftSave(payload));
}

export function* watchMessageDraftLoad() {
  yield takeLatest(DRAFT.LOAD, ({payload}: PayloadAction<IDraftIdentifier>) => MessageDraftLoad(payload));
}

export function* watchMessageDraftDelete() {
  yield takeLatest(DRAFT.DELETE, ({payload}: PayloadAction<IDraftIdentifier>) => MessageDraftDelete(payload));
}

export function* watchMessageDraftComplete() {
  yield takeLatest(DRAFT.COMPLETE, ({payload}: PayloadAction<IDraftIdentifier>) => MessageDraftComplete(payload));
}

export function* watchMessageDraftIdListLoad() {
  yield takeLatest(DRAFT_LIST.LOAD, () => MessageDraftIdListLoad());
}

export function* watchMessageDraftIdListRefresh() {
  yield takeLatest(DRAFT_LIST.REFRESH, () => MessageDraftIdListRefresh());
}

export function* watchLoadTaskList() {
  yield takeLatest(TASK_THREAD_LIST.LOAD, () => LoadTaskList());
}

export function* watchLoadTaskRefresh() {
  yield takeLatest(TASK_THREAD_LIST.REFRESH, () => LoadTaskList());
}

export default function* messageSaga() {
  yield all ([
    call(watchInboxGroupsList),
    call(watchInboxGroupsRefreshList),
    call(watchInboxGroupsNotificationList),
    call(watchInboxGroupsNotificationRefreshList),
    call(watchInboxGroupThreadsList),
    call(watchInboxSummaryLoad),
    call(watchInboxSummaryRefresh),
    call(watchThreadMetadataLoad),
    call(watchThreadSearch),
    call(watchThreadRefreshSearch),
    call(watchThreadMarkRead),
    call(watchThreadLoad),
    call(watchThreadRefresh),
    call(watchThreadHistoryLoad),
    call(watchMessageSave),
    call(watchMessageDraftSave),
    call(watchMessageDraftLoad),
    call(watchMessageDraftDelete),
    call(watchMessageDraftComplete),
    call(watchMessageDraftIdListLoad),
    call(watchMessageDraftIdListRefresh),
    call(watchLoadTaskList),
    call(watchLoadTaskRefresh),
  ])
}
