import React from 'react';
import { SchemaOf, reach } from 'yup';
import {isFieldInObject} from '../../helpers/json';

export interface IValidationContextValue {
  schema: SchemaOf<any> | undefined;
  changeRequest: any | undefined;
}

export const ValidationContext = React.createContext<IValidationContextValue>({ schema: undefined, changeRequest: undefined });

export const useValidationContext = () => React.useContext(ValidationContext);

export declare type ValidationContextProps = {
  children: React.ReactNode;
  schema: SchemaOf<any>;
  changeRequest?: any;
}

export const ValidationProvider = ({ schema, changeRequest, children }: ValidationContextProps) => {
  return (
    <ValidationContext.Provider
      value={{ schema, changeRequest }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

export const isFieldRequired = (schema: SchemaOf<any>, path: string, defaultVal = false) => {
  try {
    return reach(schema, path)
      .describe()
      .tests.some((test: any) => test.name === "required");
  } catch (err) {
    return defaultVal;
  }
}

export const isFieldInChangeRequest = (changeFields: any | undefined, path: string) => {
  return isFieldInObject(changeFields, path);
}

export const getFieldMaxLength = (schema: SchemaOf<any>, path: string) => {
  try {
    const maxRule = reach(schema, path)
      .describe()
      .tests.find((test: any) => test.name === "max");
    return maxRule ? maxRule.params.max : false;
  } catch (err) {
    return false;
  }
}