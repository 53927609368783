import {FieldValues, useFieldArray, useFormContext} from 'react-hook-form';
import React from 'react';
import Divider from '@mui/material/Divider';
import GridContainer from '../../core/GridContainer';
import {Button, Grid} from '@mui/material';
import {optionsContentCol, optionsMenuCol} from '../../utility/FormLayout/layoutConfig';
import FormTextField from '../FormTextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import LandlineIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import HelpOutline from '@mui/icons-material/HelpOutline';
import FormSelect from '../FormSelect';
import {GridLayout} from '../../../types/layout/Grid';
import makeStyles from '@mui/styles/makeStyles';
import defaultConfig from '../../utility/ContextProvider/defaultConfig';
import {PhoneModelPhoneType} from '../../../types/models/Enums';
import {PhoneModel} from '../../../types/models/PhoneModel';

export const typeCol = new GridLayout({xs: 2, sm: 2, md: 3, lg: 3, xl: 3 });
export const numberCol = new GridLayout({xs: 10, sm: 10, md: 9, lg: 9, xl: 9 });

const useStyles = makeStyles(() => ({
  divider: {
    marginHorizontal: 0,
    marginTop: 12,
    marginBottom: 12,
  },
  spacer: {
    marginHorizontal: 0,
    marginTop: 6,
    marginBottom: 6,
    backgroundColor: 'white',
    borderColor: 'white',
  },
  removeItem: {
    alignItems: 'center',
  },
  removeIcon: {
    marginTop: 8,
    color: defaultConfig.theme.palette.grey[500]
  }
}));

const FormPhoneNumbers = <T extends FieldValues>(props: T) => {
  const { name, errors, disabled = false, handleBlur } = props;
  const { control } = useFormContext();

  const classes = useStyles();

  const { fields: phoneNumbers, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const phoneTypeOptions = () => {
    return [{
        id: PhoneModelPhoneType.Mobile,
        icon: <PhoneIcon sx={{ width: 14, height: 14}} />
      },
      {
        id: PhoneModelPhoneType.Home,
        icon: <LandlineIcon sx={{ width: 14, height: 14}} />
      },
      {
        id: PhoneModelPhoneType.Work,
        icon: <WorkIcon sx={{ width: 14, height: 14}} />
      },
      {
        id: PhoneModelPhoneType.Other,
        icon: <HelpOutline sx={{ width: 14, height: 14}} />
      }
    ]
  }

  const onAddNumber = () => {
    const nextType = !phoneNumbers ? 'Mobile' : phoneNumbers.length > 2 ? PhoneModelPhoneType.Other : phoneTypeOptions()[phoneNumbers.length].id;
    const newPhoneNumber = PhoneModel.fromJS({
      phoneType: nextType,
      number: ''
    });
    append(newPhoneNumber);
  }

  const onRemoveNumber = (idx: number) => {
    remove(idx);
  }

  return (
    <React.Fragment>
      {phoneNumbers?.map((element, idx) => {
        return (
          <React.Fragment key={`${name}_${idx}`}>
            <GridContainer spacing={1} key={element.id}>
              <Grid item {...optionsContentCol}>
                <GridContainer spacing={1}>
                  <Grid item {...typeCol}>
                    <FormSelect name={`${name}.${idx}.phoneType`} label="" data={phoneTypeOptions()} labelField="icon" valueField="id" required={true} disabled={disabled} handleBlur={handleBlur}/>
                  </Grid>
                  <Grid item {...numberCol}>
                    <FormTextField name={`${name}.${idx}.number`} label="Number" required={true} disabled={disabled} onBlur={handleBlur}/>
                  </Grid>
                </GridContainer>
              </Grid>
              <Grid item {...optionsMenuCol} className={classes.removeItem}>
                <IconButton
                  onClick={() => onRemoveNumber(idx)}
                  className={classes.removeIcon}
                  ><DeleteIcon type="outlined" /></IconButton>
              </Grid>
            </GridContainer>
          </React.Fragment>
        );
      })}
      {phoneNumbers && phoneNumbers.length > 0 && <Divider className={classes.divider} />}
      {!phoneNumbers || phoneNumbers.length === 0 && <Divider className={classes.spacer} />}
      <Button variant="outlined" color="secondary" size="small" fullWidth onClick={() => onAddNumber()} disabled={disabled}><AddIcon type="outlined" sx={{ width: 14, height: 14 }} />Add phone number</Button>
    </React.Fragment>
  );
}

export default FormPhoneNumbers;