export class DegreePoolModel implements IDegreePoolModel {
  id?: string | undefined;
  title?: string | undefined;

  constructor(data?: IDegreePoolModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.title = Data["title"];
    }
  }

  static fromJS(data: any): DegreePoolModel {
    data = typeof data === 'object' ? data : {};
    let result = new DegreePoolModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["title"] = this.title;
    return data;
  }
}

export interface IDegreePoolModel {
  id?: string | undefined;
  title?: string | undefined;
}
