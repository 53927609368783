import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {alumniPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';
import {studentPaths} from '../students/paths';

const formPage = React.lazy(() => import('./AlumniEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Alumni Students", alumniPaths.AlumniSearch)];

const alumniRoutes = [
  new AppRouteProps("Add student", alumniPaths.AlumniCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit student", alumniPaths.AlumniEdit, formPage, sectionBreadcrumbs),
  new AppRouteProps("Promote", alumniPaths.AlumniPromote, React.lazy(() => import('./AlumniPromote')), sectionBreadcrumbs),
  new AppRouteProps("Search", alumniPaths.AlumniSearch, React.lazy(() => import('./AlumniSearch')), sectionBreadcrumbs),
]

const alumniRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, alumniRoutes)
];

export default alumniRoutesConfig;
