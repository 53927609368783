import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface CompanyPaths {
  CompanyEdit: string,
  CompanyCreate: string,
  CompanyProfile: string,
  CompanySearch: string,
  OpportunitiesSearch: string,
}

export const companyPaths: CompanyPaths = {
  CompanyEdit: '/advisor/companies/edit/:id',
  CompanyCreate: '/advisor/companies/add',
  CompanyProfile: '/advisor/companies/profile/:id',
  CompanySearch: '/advisor/companies/search',
  OpportunitiesSearch: '/advisor/opportunities/search',
};

export interface ICompanyProfileTabs {
  activity: RouteTabParameter;
  profile: RouteTabParameter;
  legacy: RouteTabParameter;
  opportunities: RouteTabParameter;
  hspOpportunities: RouteTabParameter;
  progression: RouteTabParameter;
  files: RouteTabParameter;
}

export const companyProfileTabs: ICompanyProfileTabs = {
  activity: new RouteTabParameter(0, 'Activity', 'activity'),
  profile: new RouteTabParameter(1, 'Profile', 'profile'),
  opportunities: new RouteTabParameter(2, 'Opportunities', 'opp'),
  hspOpportunities: new RouteTabParameter(3, 'HSP Opportunities', 'hspOpp'),
  progression: new RouteTabParameter(4, 'Progression', 'progress'),
  files: new RouteTabParameter(5, 'Files', 'files'),
  legacy: new RouteTabParameter(6, 'Legacy', 'legacy')
}

const profileTabs = [
  companyProfileTabs.activity,
  companyProfileTabs.profile,
  companyProfileTabs.legacy,
  companyProfileTabs.progression,
  companyProfileTabs.opportunities,
  companyProfileTabs.hspOpportunities,
  companyProfileTabs.files,
];

export const getCompanyProfileTab = (query: string | undefined | null) => profileTabs.find(x => x.query === query);
