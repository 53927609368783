
import {HSPPlacementModel} from '../../types/models/HSPPlacementModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {HSP_PLACEMENT, HSP_PLACEMENT_EVALUATIONS, HSP_PLACEMENT_LIST} from './Actions';
import {HSPPlacementDetailModel} from '../../types/models/HSPPlacementDetailModel';
import {SeasonalActionModel} from '../../types/models/SeasonalActionModel';

export interface IHSPPlacementState {
  hspPlacement: HSPPlacementDetailModel | undefined;
  hspPlacementLoading: boolean;
  hspPlacementLoadError: string | undefined;
  hspPlacementSaving: boolean;
  hspPlacementSaveError: string | undefined;
  hspPlacementRefreshing: boolean;
  hspPlacementListLoading: boolean;
  hspPlacementList: HSPPlacementDetailModel[] | [];
  hspPlacementListError: string | undefined;
  hspPlacementEvaluationsList: SeasonalActionModel[] | [];
  hspPlacementEvaluationsLoading: boolean;
  hspPlacementEvaluationsError: string | undefined;
}

const initialState: IHSPPlacementState = {
  hspPlacement: undefined,
  hspPlacementLoading: false,
  hspPlacementLoadError: undefined,
  hspPlacementSaving: false,
  hspPlacementSaveError: undefined,
  hspPlacementRefreshing: false,
  hspPlacementListLoading: false,
  hspPlacementList: [],
  hspPlacementListError: undefined,
  hspPlacementEvaluationsList: [],
  hspPlacementEvaluationsLoading: false,
  hspPlacementEvaluationsError: undefined
}

const hspPlacementReducer = createReducer(initialState, {
  [HSP_PLACEMENT.LOAD]: (state) => {
    state.hspPlacementLoading = true;
    state.hspPlacementLoadError = undefined;
    state.hspPlacementSaveError = undefined;
    state.hspPlacementRefreshing = false;
    state.hspPlacement = undefined;
  },
  [HSP_PLACEMENT.LOADED]: (state, action: PayloadAction<HSPPlacementModel>) => {
    state.hspPlacementLoading = false;
    state.hspPlacementRefreshing = false;
    state.hspPlacementLoadError = undefined;
    state.hspPlacementSaveError = undefined;
    state.hspPlacement = action.payload;
  },
  [HSP_PLACEMENT.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPlacementLoading = false;
    state.hspPlacementRefreshing = false;
    state.hspPlacementLoadError = action.payload;
    state.hspPlacementSaveError = undefined;
  },
  [HSP_PLACEMENT.REFRESH]: (state) => {
    state.hspPlacementLoading = true;
    state.hspPlacementRefreshing = true;
    state.hspPlacementSaveError = undefined;
    state.hspPlacementLoadError = undefined;
  },
  [HSP_PLACEMENT.SAVE]: (state) => {
    state.hspPlacementSaving = true;
    state.hspPlacementSaveError = undefined;
  },
  [HSP_PLACEMENT.SAVED]: (state, action: PayloadAction<HSPPlacementModel>) => {
    state.hspPlacementSaving = false;
    state.hspPlacementSaveError = undefined;
    state.hspPlacement = action.payload;
  },
  [HSP_PLACEMENT.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPlacementSaving = false;
    state.hspPlacementSaveError = action.payload;
  },
  [HSP_PLACEMENT_LIST.LOAD]: (state) => {
    state.hspPlacementListLoading = true;
    state.hspPlacementListError = undefined;
    state.hspPlacementList = [];
  },
  [HSP_PLACEMENT_LIST.LOADED]: (state, action: PayloadAction<HSPPlacementDetailModel[]>) => {
    state.hspPlacementListLoading = false;
    state.hspPlacementListError = undefined;
    state.hspPlacementList = action.payload;
  },
  [HSP_PLACEMENT_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPlacementListLoading = false;
    state.hspPlacementListError = action.payload;
  },
  [HSP_PLACEMENT_EVALUATIONS.LOAD]: (state) => {
    state.hspPlacementEvaluationsLoading = true;
    state.hspPlacementEvaluationsError = undefined;
    state.hspPlacementEvaluationsList = [];
  },
  [HSP_PLACEMENT_EVALUATIONS.LOADED]: (state, action: PayloadAction<HSPPlacementDetailModel[]>) => {
    state.hspPlacementEvaluationsLoading = false;
    state.hspPlacementEvaluationsError = undefined;
    state.hspPlacementEvaluationsList = action.payload;
  },
  [HSP_PLACEMENT_EVALUATIONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspPlacementEvaluationsLoading = false;
    state.hspPlacementEvaluationsError = action.payload;
  },
});


export default hspPlacementReducer;