export class GlobalIndexModel implements IGlobalIndexModel {
  title?: string | undefined;
  miniDescription?: string | undefined;
  description?: string | undefined;
  entity?: string[] | undefined;
  entityId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  personNumber?: number | undefined;

  constructor(data?: IGlobalIndexModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.title = Data["title"];
      this.miniDescription = Data["miniDescription"];
      this.description = Data["description"];
      if (Array.isArray(Data["entity"])) {
        this.entity = [] as any;
        for (let item of Data["entity"])
          this.entity!.push(item);
      }
      this.entityId = Data["entityId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.personNumber = Data["personNumber"];
    }
  }

  static fromJS(data: any): GlobalIndexModel {
    data = typeof data === 'object' ? data : {};
    let result = new GlobalIndexModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["title"] = this.title;
    data["miniDescription"] = this.miniDescription;
    data["description"] = this.description;
    if (Array.isArray(this.entity)) {
      data["entity"] = [];
      for (let item of this.entity)
        data["entity"].push(item);
    }
    data["entityId"] = this.entityId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["personNumber"] = this.personNumber;
    return data;
  }
}

export interface IGlobalIndexModel {
  title?: string | undefined;
  miniDescription?: string | undefined;
  description?: string | undefined;
  entity?: string[] | undefined;
  entityId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  personNumber?: number | undefined;
}