import {GLOBAL_SEARCH} from './Actions';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {GlobalIndexModel} from '../../types/views/GlobalIndexModel';

export interface IGlobalSearchState {
  globalSearchValue: string | undefined;
  globalSearchLoading: boolean | false;
  globalSearchError: string | undefined;
  globalSearchResult: GlobalIndexModel[];
}

const initialState: IGlobalSearchState = {
  globalSearchValue: undefined,
  globalSearchLoading: false,
  globalSearchError: undefined,
  globalSearchResult: []
}

const globalSearchReducer = createReducer(initialState, {
  [GLOBAL_SEARCH.LOAD]: (state, action: PayloadAction<string>) => {
    state.globalSearchValue = action.payload;
    state.globalSearchLoading = true;
    state.globalSearchError = undefined;
  },
  [GLOBAL_SEARCH.LOADED]: (state, action: PayloadAction<GlobalIndexModel[]>) => {
    state.globalSearchLoading = false;
    state.globalSearchError = undefined;
    state.globalSearchResult = action.payload;
  },
  [GLOBAL_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.globalSearchLoading = false;
    state.globalSearchError = action.payload;
  },
});

export default globalSearchReducer;