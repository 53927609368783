import {ScorecardParamsModel} from '../requests/ScorecardParamsModel';
import {
  companyInternshipScorecardDefaultColumns,
  engagementScorecardDefaultColumns, internshipScorecardDefaultColumns,
  recruitmentScorecardDefaultColumns, RetrieveSubmissionConfig,
} from '../../modules/scorecards/ScorecardConfig';

export const defaultPreset = (scorecardType: string) => {
  let visibleColumns = undefined;
  if (scorecardType === RetrieveSubmissionConfig.Recruitment) {
    visibleColumns = recruitmentScorecardDefaultColumns;
  }
  if (scorecardType === RetrieveSubmissionConfig.Engagement) {
    visibleColumns = engagementScorecardDefaultColumns;
  }
  if (scorecardType === RetrieveSubmissionConfig.Internship) {
    visibleColumns = internshipScorecardDefaultColumns;
  }
  if (scorecardType === RetrieveSubmissionConfig.CompanyInternship) {
    visibleColumns = companyInternshipScorecardDefaultColumns;
  }
  return ScorecardPresetModel.fromJS({
    name: 'Default',
    fields: visibleColumns,
    userId: undefined,
    dateCreated: undefined,
    scorecardType: undefined,
  });
}


// generated
export class ScorecardPresetModel implements IScorecardPresetModel {
  name?: string | undefined;
  fields?: string[] | undefined;
  userId?: string | undefined;
  dateCreated?: Date | undefined;
  scorecardType?: string | undefined;

  constructor(data?: IScorecardPresetModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.name = Data["name"];
      if (Array.isArray(Data["fields"])) {
        this.fields = [] as any;
        for (let item of Data["fields"])
          this.fields!.push(item);
      }
      this.userId = Data["userId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.scorecardType = Data["scorecardType"];
    }
  }

  static fromJS(data: any): ScorecardPresetModel {
    data = typeof data === 'object' ? data : {};
    let result = new ScorecardPresetModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["name"] = this.name;
    if (Array.isArray(this.fields)) {
      data["fields"] = [];
      for (let item of this.fields)
        data["fields"].push(item);
    }
    data["userId"] = this.userId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["scorecardType"] = this.scorecardType;
    return data;
  }
}

export interface IScorecardPresetModel {
  name?: string | undefined;
  fields?: string[] | undefined;
  userId?: string | undefined;
  dateCreated?: Date | undefined;
  scorecardType?: string | undefined;
}
