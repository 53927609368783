import {WorkShadowStatusEnum} from './Enums';

export const defaultForHighSchoolProgramParticipantStepModel = () => {
  return HighSchoolProgramParticipantStepModel.fromJS({
    setTags: [],
    title: '',
    shortTitle: '',
    isAttachmentRequired: false,
    isVisible: true,
    completedByTasks: [],
    generatesTasks: [],
    finaliseTasks:  [],
    generatesAchievements: [],
  });
}

export class HighSchoolProgramParticipantStepModel implements IHighSchoolProgramParticipantStepModel {
  /** identity of step */
  stepId?: string | undefined;
  setTags?: string[] | undefined;
  setToStatus?: WorkShadowStatusEnum | undefined;
  /** Title of the step */
  title!: string;
  /** Short name of this step */
  shortTitle!: string;
  /** A file will be required to complete this step */
  isAttachmentRequired?: boolean | undefined;
  /** WorkShadow App will see this step */
  isVisible?: boolean | undefined;
  /** Tip for student completion */
  tip?: string | undefined;
  completedByTasks?: string[] | undefined;
  generatesTasks?: string[] | undefined;
  generatesAchievements?: string[] | undefined;

  constructor(data?: IHighSchoolProgramParticipantStepModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.stepId = Data["stepId"];
      if (Array.isArray(Data["setTags"])) {
        this.setTags = [] as any;
        for (let item of Data["setTags"])
          this.setTags!.push(item);
      }
      this.setToStatus = Data["setToStatus"];
      this.title = Data["title"];
      this.shortTitle = Data["shortTitle"];
      this.isAttachmentRequired = Data["isAttachmentRequired"];
      this.isVisible = Data["isVisible"];
      this.tip = Data["tip"];
      if (Array.isArray(Data["completedByTasks"])) {
        this.completedByTasks = [] as any;
        for (let item of Data["completedByTasks"])
          this.completedByTasks!.push(item);
      }
      if (Array.isArray(Data["generatesTasks"])) {
        this.generatesTasks = [] as any;
        for (let item of Data["generatesTasks"])
          this.generatesTasks!.push(item);
      }
      if (Array.isArray(Data["generatesAchievements"])) {
        this.generatesAchievements = [] as any;
        for (let item of Data["generatesAchievements"])
          this.generatesAchievements!.push(item);
      }
    }
  }

  static fromJS(data: any): HighSchoolProgramParticipantStepModel {
    data = typeof data === 'object' ? data : {};
    let result = new HighSchoolProgramParticipantStepModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["stepId"] = this.stepId;
    if (Array.isArray(this.setTags)) {
      data["setTags"] = [];
      for (let item of this.setTags)
        data["setTags"].push(item);
    }
    data["setToStatus"] = this.setToStatus;
    data["title"] = this.title;
    data["shortTitle"] = this.shortTitle;
    data["isAttachmentRequired"] = this.isAttachmentRequired;
    data["isVisible"] = this.isVisible;
    data["tip"] = this.tip;
    if (Array.isArray(this.completedByTasks)) {
      data["completedByTasks"] = [];
      for (let item of this.completedByTasks)
        data["completedByTasks"].push(item);
    }
    if (Array.isArray(this.generatesTasks)) {
      data["generatesTasks"] = [];
      for (let item of this.generatesTasks)
        data["generatesTasks"].push(item);
    }
    if (Array.isArray(this.generatesAchievements)) {
      data["generatesAchievements"] = [];
      for (let item of this.generatesAchievements)
        data["generatesAchievements"].push(item);
    }
    return data;
  }
}

export interface IHighSchoolProgramParticipantStepModel {
  /** identity of step */
  stepId?: string | undefined;
  setTags?: string[] | undefined;
  setToStatus?: WorkShadowStatusEnum | undefined;
  /** Title of the step */
  title: string;
  /** Short name of this step */
  shortTitle: string;
  /** A file will be required to complete this step */
  isAttachmentRequired?: boolean | undefined;
  /** WorkShadow App will see this step */
  isVisible?: boolean | undefined;
  /** Tip for student completion */
  tip?: string | undefined;
  completedByTasks?: string[] | undefined;
  generatesTasks?: string[] | undefined;
  generatesAchievements?: string[] | undefined;
}