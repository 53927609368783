import React from 'react';
import {AppRouteGroupProps, AppRouteProps} from '../../types/AppRoutesPropType';
import {dashboardPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const dashboardRoutes = [
  new AppRouteProps("Home", dashboardPaths.LandingPage, React.lazy(() => import('./LandingPage')), [], PermissionLevel.ADVISOR),
]

const dashboardRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, dashboardRoutes)
];
export default dashboardRoutesConfig;