export class ActionTypeReferenceModel implements IActionTypeReferenceModel {
  actionType?: string | undefined;
  actionName?: string | undefined;
  isPrivate?: boolean | undefined;

  constructor(data?: IActionTypeReferenceModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.actionType = Data["actionType"];
      this.actionName = Data["actionName"];
      this.isPrivate = Data["isPrivate"];
    }
  }

  static fromJS(data: any): ActionTypeReferenceModel {
    data = typeof data === 'object' ? data : {};
    let result = new ActionTypeReferenceModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["actionType"] = this.actionType;
    data["actionName"] = this.actionName;
    data["isPrivate"] = this.isPrivate;
    return data;
  }
}

export interface IActionTypeReferenceModel {
  actionType?: string | undefined;
  actionName?: string | undefined;
  isPrivate?: boolean | undefined;
}