export class OpportunitySearchModel implements IOpportunitySearchModel {
  companyId?: string | undefined;
  dateCreated?: string | undefined;
  opportunityStudentStatus?: string | undefined;
  opportunityStudentStatusIndex?: number | undefined;
  opportunityId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;

  constructor(data?: IOpportunitySearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.companyId = Data["companyId"];
      this.dateCreated = Data["dateCreated"];
      this.opportunityStudentStatus = Data["opportunityStudentStatus"];
      this.opportunityStudentStatusIndex = Data["opportunityStudentStatusIndex"];
      this.opportunityId = Data["opportunityId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
    }
  }

  static fromJS(data: any): OpportunitySearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new OpportunitySearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["companyId"] = this.companyId;
    data["dateCreated"] = this.dateCreated;
    data["opportunityStudentStatus"] = this.opportunityStudentStatus;
    data["opportunityStudentStatusIndex"] = this.opportunityStudentStatusIndex;
    data["opportunityId"] = this.opportunityId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    return data;
  }
}

export interface IOpportunitySearchModel {
  companyId?: string | undefined;
  dateCreated?: string | undefined;
  opportunityStudentStatus?: string | undefined;
  opportunityStudentStatusIndex?: number | undefined;
  opportunityId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
}