import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  CLIENT_ON_BOARDING_SETTINGS,
  CLIENT_ON_BOARDING_SETTINGS_LIST,
  clientOnBoardingSettingsListError,
  clientOnBoardingSettingsListSuccess,
  clientOnBoardingSettingsLoadError,
  clientOnBoardingSettingsLoadSuccess,
  clientOnBoardingSettingsSaveError,
  clientOnBoardingSettingsSaveSuccess,
  EDUCATION_ON_BOARDING_SETTINGS,
  EDUCATION_ON_BOARDING_SETTINGS_LIST,
  educationOnBoardingSettingsListError,
  educationOnBoardingSettingsListSuccess,
  educationOnBoardingSettingsLoadError,
  educationOnBoardingSettingsLoadSuccess,
  educationOnBoardingSettingsSaveError,
  educationOnBoardingSettingsSaveSuccess,
  GRADUATING_SETTINGS,
  GRADUATING_SETTINGS_LIST,
  graduatingSettingsListError,
  graduatingSettingsListSuccess,
  graduatingSettingsLoadError,
  graduatingSettingsLoadSuccess,
  graduatingSettingsSaveError,
  graduatingSettingsSaveSuccess,
  STUDENT_ON_BOARDING_SETTINGS,
  STUDENT_ON_BOARDING_SETTINGS_LIST,
  STUDENT_PLACEMENT_SETTINGS,
  STUDENT_PLACEMENT_SETTINGS_LIST,
  studentOnBoardingSettingsListError,
  studentOnBoardingSettingsListSuccess,
  studentOnBoardingSettingsLoadError,
  studentOnBoardingSettingsLoadSuccess,
  studentOnBoardingSettingsSaveError,
  studentOnBoardingSettingsSaveSuccess,
  studentPlacementSettingsListError,
  studentPlacementSettingsListSuccess,
  studentPlacementSettingsLoadError,
  studentPlacementSettingsLoadSuccess,
  studentPlacementSettingsSaveError,
  studentPlacementSettingsSaveSuccess,
  WORK_SHADOW_ON_BOARDING_SETTINGS,
  WORK_SHADOW_ON_BOARDING_SETTINGS_LIST,
  WORK_SHADOW_PLACEMENT_SETTINGS,
  WORK_SHADOW_POOL_SETTINGS,
  WORK_SHADOW_POOL_SETTINGS_LIST,
  workShadowOnBoardingSettingsListError,
  workShadowOnBoardingSettingsListSuccess,
  workShadowOnBoardingSettingsLoadError,
  workShadowOnBoardingSettingsLoadSuccess,
  workShadowOnBoardingSettingsSaveError,
  workShadowOnBoardingSettingsSaveSuccess,
  workShadowPlacementSettingsListError,
  workShadowPlacementSettingsListSuccess,
  workShadowPlacementSettingsLoadError,
  workShadowPlacementSettingsLoadSuccess,
  workShadowPlacementSettingsSaveError,
  workShadowPlacementSettingsSaveSuccess,
  workShadowPoolSettingsListError,
  workShadowPoolSettingsListSuccess,
  workShadowPoolSettingsLoadError,
  workShadowPoolSettingsLoadSuccess,
  workShadowPoolSettingsSaveError,
  workShadowPoolSettingsSaveSuccess,
  ALUMNI_EARLY_PROFESSIONAL_SETTINGS,
  ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST,
  alumniEarlyProfessionalSettingsListError,
  alumniEarlyProfessionalSettingsListSuccess,
  alumniEarlyProfessionalSettingsLoadError,
  alumniEarlyProfessionalSettingsLoadSuccess,
  alumniEarlyProfessionalSettingsSaveError,
  alumniEarlyProfessionalSettingsSaveSuccess,
  alumniEarlyProfessionalSettingsLoad,
  highSchoolProgramParticipantSettingsLoadSuccess,
  highSchoolProgramParticipantSettingsSaveSuccess,
  highSchoolProgramParticipantSettingsListError,
  highSchoolProgramParticipantSettingsSaveError,
  highSchoolProgramParticipantSettingsLoadError,
  highSchoolProgramParticipantSettingsListSuccess,
  HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS,
  HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST,
  WORK_SHADOW_PLACEMENT_SETTINGS_LIST
} from './Actions';
import {StudentOnboardingModel} from '../../types/models/StudentOnboardingModel';
import {ICommonSavePayload} from '../Common.actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {StudentPlacementModel} from '../../types/models/StudentPlacementModel';
import {StudentGraduatingModel} from '../../types/models/StudentGraduatingModel';
import {ClientOnboardingModel} from '../../types/models/ClientOnboardingModel';
import {EducationOnboardingModel} from '../../types/models/EducationOnboardingModel';
import {WorkShadowOnboardingModel} from '../../types/models/WorkShadowOnboardingModel';
import {PoolWorkShadowModel} from '../../types/models/PoolWorkShadowModel';
import {PlacementWorkShadowModel} from '../../types/models/PlacementWorkShadowModel';
import { AlumniEarlyProfessionalModel } from 'types/models/AlumniEarlyProfessionalModel';
import {HighSchoolProgramParticipantModel} from '../../types/models/HighSchoolProgramParticipantModel';

/* ONBOARDING */
function* LoadStudentOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - LoadStudentOnBoarding');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/student/${id}`);
    const settings = StudentOnboardingModel.fromJS(res.data);
    yield put(studentOnBoardingSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(studentOnBoardingSettingsLoadError(error));
  }
}
function* SaveStudentOnBoarding({ id, submission }: ICommonSavePayload<StudentOnboardingModel>) {
  console.log('SAGA - timelineSaga - SaveStudentOnBoarding');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/student${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = StudentOnboardingModel.fromJS(res.data);
    yield put(studentOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(studentOnBoardingSettingsSaveError(error));
  }
}
function* DisableStudentOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - DisableStudentOnBoarding');
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/student/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = StudentOnboardingModel.fromJS(res.data);
    yield put(studentOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(studentOnBoardingSettingsSaveError(error));
  }
}
function* ListStudentOnBoarding(includeDisabled: boolean) {
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/student?include_all=true`);
    const settings = res.data.map((x: any) => StudentOnboardingModel.fromJS(x));
    yield put(studentOnBoardingSettingsListSuccess(settings));
  } catch (error) {
    yield put(studentOnBoardingSettingsListError(error));
  }
}

/* Placement */
function* LoadStudentPlacement(id: string) {
  console.log('SAGA - timelineSaga - LoadStudentPlacement');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/placements/${id}`);
    const settings = StudentPlacementModel.fromJS(res.data);
    yield put(studentPlacementSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(studentPlacementSettingsLoadError(error));
  }
}
function* SaveStudentPlacement({ id, submission }: ICommonSavePayload<StudentPlacementModel>) {
  console.log('SAGA - timelineSaga - SaveStudentPlacement');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/placements${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = StudentPlacementModel.fromJS(res.data);
    yield put(studentPlacementSettingsSaveSuccess(result));
  } catch (error) {
    yield put(studentPlacementSettingsSaveError(error));
  }
}
function* DisableStudentPlacement(id: string) {
  console.log('SAGA - timelineSaga - DisableStudentPlacement');
  const submitPath = `${apiConfig.coreSettingsPath}/placements/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = StudentPlacementModel.fromJS(res.data);
    yield put(studentPlacementSettingsSaveSuccess(result));
  } catch (error) {
    yield put(studentPlacementSettingsSaveError(error));
  }
}
function* ListStudentPlacement(includeDisabled: boolean) {
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/placements?include_all=true`);
    const settings = res.data.map((x: any) => StudentPlacementModel.fromJS(x));
    yield put(studentPlacementSettingsListSuccess(settings));
  } catch (error) {
    yield put(studentPlacementSettingsListError(error));
  }
}

function* ListHSPPlacement(includeDisabled: boolean) {
  console.log('SAGA - ListHSPPlacement')
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/placements/workshadow?include_all=true`);
    const settings = res.data.map((x: any) => PlacementWorkShadowModel.fromJS(x));
    yield put(workShadowPlacementSettingsListSuccess(settings));
  } catch (error) {
    yield put(workShadowPlacementSettingsListError(error));
  }
}

/* Graduating */

function* LoadGraduating(id: string) {
  console.log('SAGA - timelineSaga - LoadGraduating');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/graduating/student/${id}`);
    const settings = StudentGraduatingModel.fromJS(res.data);
    yield put(graduatingSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(graduatingSettingsLoadError(error));
  }
}
function* SaveGraduating({ id, submission }: ICommonSavePayload<StudentGraduatingModel>) {
  console.log('SAGA - timelineSaga - SaveGraduating');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/graduating/student${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = StudentGraduatingModel.fromJS(res.data);
    yield put(graduatingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(graduatingSettingsSaveError(error));
  }
}
function* DisableGraduating(id: string) {
  console.log('SAGA - timelineSaga - DisableGraduating');
  const submitPath = `${apiConfig.coreSettingsPath}/graduating/student/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = StudentGraduatingModel.fromJS(res.data);
    yield put(graduatingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(graduatingSettingsSaveError(error));
  }
}
function* ListGraduating(includeDisabled: boolean) {
  console.log('SAGA - timelineSaga - ListGraduating');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/graduating/student?include_all=true`);
    const settings = res.data.map((x: any) => StudentGraduatingModel.fromJS(x));
    yield put(graduatingSettingsListSuccess(settings));
  } catch (error) {
    yield put(graduatingSettingsListError(error));
  }
}

/* Client Onboarding */
function* LoadClientOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - LoadClientOnBoarding');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/client/${id}`);
    const settings = ClientOnboardingModel.fromJS(res.data);
    yield put(clientOnBoardingSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(clientOnBoardingSettingsLoadError(error));
  }
}
function* SaveClientOnBoarding({ id, submission }: ICommonSavePayload<ClientOnboardingModel>) {
  console.log('SAGA - timelineSaga - SaveClientOnBoarding');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/client${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = ClientOnboardingModel.fromJS(res.data);
    yield put(clientOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(clientOnBoardingSettingsSaveError(error));
  }
}
function* DisableClientOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - DisableClientOnBoarding');
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/client/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = ClientOnboardingModel.fromJS(res.data);
    yield put(clientOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(clientOnBoardingSettingsSaveError(error));
  }
}
function* ListClientOnBoarding(includeDisabled: boolean) {
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/client?include_all=true`);
    const settings = res.data.map((x: any) => ClientOnboardingModel.fromJS(x));
    yield put(clientOnBoardingSettingsListSuccess(settings));
  } catch (error) {
    yield put(clientOnBoardingSettingsListError(error));
  }
}

/* Education Onboarding */
function* LoadEducationOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - LoadEducationOnBoarding');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/education/${id}`);
    const settings = EducationOnboardingModel.fromJS(res.data);
    yield put(educationOnBoardingSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(educationOnBoardingSettingsLoadError(error));
  }
}
function* SaveEducationOnBoarding({ id, submission }: ICommonSavePayload<EducationOnboardingModel>) {
  console.log('SAGA - timelineSaga - SaveEducationOnBoarding');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/education${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = EducationOnboardingModel.fromJS(res.data);
    yield put(educationOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(educationOnBoardingSettingsSaveError(error));
  }
}
function* DisableEducationOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - DisableEducationOnBoarding');
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/education/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = EducationOnboardingModel.fromJS(res.data);
    yield put(educationOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(educationOnBoardingSettingsSaveError(error));
  }
}
function* ListEducationOnBoarding(includeDisabled: boolean) {
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/education?include_all=true`);
    const settings = res.data.map((x: any) => EducationOnboardingModel.fromJS(x));
    yield put(educationOnBoardingSettingsListSuccess(settings));
  } catch (error) {
    yield put(educationOnBoardingSettingsListError(error));
  }
}

/* WORKSHADOW */

function* LoadWorkShadowOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - LoadWorkShadowOnBoarding');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/workshadow/${id}`);
    const settings = WorkShadowOnboardingModel.fromJS(res.data);
    yield put(workShadowOnBoardingSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(workShadowOnBoardingSettingsLoadError(error));
  }
}
function* SaveWorkShadowOnBoarding({ id, submission }: ICommonSavePayload<WorkShadowOnboardingModel>) {
  console.log('SAGA - timelineSaga - SaveWorkShadowOnBoarding');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/workshadow${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = WorkShadowOnboardingModel.fromJS(res.data);
    yield put(workShadowOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(workShadowOnBoardingSettingsSaveError(error));
  }
}
function* DisableWorkShadowOnBoarding(id: string) {
  console.log('SAGA - timelineSaga - DisableWorkShadowOnBoarding');
  const submitPath = `${apiConfig.coreSettingsPath}/onboarding/workshadow/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = WorkShadowOnboardingModel.fromJS(res.data);
    yield put(workShadowOnBoardingSettingsSaveSuccess(result));
  } catch (error) {
    yield put(workShadowOnBoardingSettingsSaveError(error));
  }
}
function* ListWorkShadowOnBoarding(includeDisabled: boolean) {
  try {
    const inclusion = includeDisabled ? '?include_all=true':'';
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/onboarding/workshadow?include_all=true`);
    const settings = res.data.map((x: any) => WorkShadowOnboardingModel.fromJS(x));
    yield put(workShadowOnBoardingSettingsListSuccess(settings));
  } catch (error) {
    yield put(workShadowOnBoardingSettingsListError(error));
  }
}

/* WORKSHADOW POOLS */

function* LoadWorkShadowPool(id: string) {
  console.log('SAGA - timelineSaga - LoadWorkShadowPool');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/pools/workshadow/${id}`);
    const settings = PoolWorkShadowModel.fromJS(res.data);
    yield put(workShadowPoolSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(workShadowPoolSettingsLoadError(error));
  }
}
function* SaveWorkShadowPool({ id, submission }: ICommonSavePayload<PoolWorkShadowModel>) {
  console.log('SAGA - timelineSaga - SaveWorkShadowPool');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/pools/workshadow${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = PoolWorkShadowModel.fromJS(res.data);
    yield put(workShadowPoolSettingsSaveSuccess(result));
  } catch (error) {
    yield put(workShadowPoolSettingsSaveError(error));
  }
}
function* DisableWorkShadowPool(id: string) {
  console.log('SAGA - timelineSaga - DisableWorkShadowPool');
  const submitPath = `${apiConfig.coreSettingsPath}/pools/workshadow/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = PoolWorkShadowModel.fromJS(res.data);
    yield put(workShadowPoolSettingsSaveSuccess(result));
  } catch (error) {
    yield put(workShadowPoolSettingsSaveError(error));
  }
}
function* ListWorkShadowPool(includeDisabled: boolean) {
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/pools/workshadow?include_all=true`);
    const settings = res.data.map((x: any) => PoolWorkShadowModel.fromJS(x));
    yield put(workShadowPoolSettingsListSuccess(settings));
  } catch (error) {
    yield put(workShadowPoolSettingsListError(error));
  }
}


function* LoadWorkShadowPlacement(id: string) {
  console.log('SAGA - timelineSaga - LoadWorkShadowPlacement');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/placements/workshadow/${id}`);
    const settings = PlacementWorkShadowModel.fromJS(res.data);
    yield put(workShadowPlacementSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(workShadowPlacementSettingsLoadError(error));
  }
}

function* SaveWorkShadowPlacement({ id, submission }: ICommonSavePayload<PlacementWorkShadowModel>) {
  console.log('SAGA - timelineSaga - SaveWorkShadowPlacement');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/placements/workshadow${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = PlacementWorkShadowModel.fromJS(res.data);
    yield put(workShadowPlacementSettingsSaveSuccess(result));
  } catch (error) {
    yield put(workShadowPlacementSettingsSaveError(error));
  }
}

function* DisableWorkShadowPlacement(id: string) {
  console.log('SAGA - timelineSaga - DisableWorkShadowPlacement');
  const submitPath = `${apiConfig.coreSettingsPath}/placements/workshadow/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = PlacementWorkShadowModel.fromJS(res.data);
    yield put(workShadowPlacementSettingsSaveSuccess(result));
  } catch (error) {
    yield put(workShadowPlacementSettingsSaveError(error));
  }
}

function* ListWorkShadowPlacement(includeDisabled: boolean) {
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/placements/workshadow?include_all=true`);
    const settings = res.data.map((x: any) => PlacementWorkShadowModel.fromJS(x));
    yield put(workShadowPlacementSettingsListSuccess(settings));
  } catch (error) {
    yield put(workShadowPlacementSettingsListError(error));
  }
}
function* LoadAlumniEarlyProfessional(id: string) {
  console.log('SAGA - timelineSaga - LoadAlumniEarlyProfessional');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/early-professional/alumni/${id}`);
    const settings = AlumniEarlyProfessionalModel.fromJS(res.data);
    yield put(alumniEarlyProfessionalSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(alumniEarlyProfessionalSettingsLoadError(error));
  }
}
function* SaveAlumniEarlyProfessional({ id, submission }: ICommonSavePayload<AlumniEarlyProfessionalModel>) {
  console.log('SAGA - timelineSaga - SaveAlumniEarlyProfessional');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/early-professional/alumni${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = AlumniEarlyProfessionalModel.fromJS(res.data);
    yield put(alumniEarlyProfessionalSettingsSaveSuccess(result));
  } catch (error) {
    yield put(alumniEarlyProfessionalSettingsSaveError(error));
  }
}
function* DisableAlumniEarlyProfessional(id: string) {
  console.log('SAGA - timelineSaga - DisableAlumniEarlyProfessional');
  const submitPath = `${apiConfig.coreSettingsPath}/early-professional/alumni/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = AlumniEarlyProfessionalModel.fromJS(res.data);
    yield put(alumniEarlyProfessionalSettingsSaveSuccess(result));
  } catch (error) {
    yield put(alumniEarlyProfessionalSettingsSaveError(error));
  }
}
function* ListAlumniEarlyProfessional(includeDisabled: boolean) {
  try {
    const inclusion = includeDisabled ? '?include_all=true':'';
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/early-professional/alumni?include_all=true`);
    const settings = res.data.map((x: any) => AlumniEarlyProfessionalModel.fromJS(x));
    yield put(alumniEarlyProfessionalSettingsListSuccess(settings));
  } catch (error) {
    yield put(alumniEarlyProfessionalSettingsListError(error));
  }
}

/* HIGH SCHOOL PROGRAM PARTICIPANT TIMELINE */

function* LoadHighSchoolParticipant(id: string) {
  console.log('SAGA - timelineSaga - LoadHighSchoolParticipant');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/participant/hsp/${id}`);
    const settings = HighSchoolProgramParticipantModel.fromJS(res.data);
    yield put(highSchoolProgramParticipantSettingsLoadSuccess(settings));
  } catch (error) {
    yield put(highSchoolProgramParticipantSettingsLoadError(error));
  }
}
function* SaveHighSchoolParticipant({ id, submission }: ICommonSavePayload<HighSchoolProgramParticipantModel>) {
  console.log('SAGA - timelineSaga - SaveHighSchoolParticipant');
  const idPath = id ? `/${id}` : '';
  const submitPath = `${apiConfig.coreSettingsPath}/participant/hsp${idPath}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: submitPath,
      data: submission
    });
    const result = HighSchoolProgramParticipantModel.fromJS(res.data);
    yield put(highSchoolProgramParticipantSettingsSaveSuccess(result));
  } catch (error) {
    yield put(highSchoolProgramParticipantSettingsSaveError(error));
  }
}
function* DisableHighSchoolParticipant(id: string) {
  console.log('SAGA - timelineSaga - DisableHighSchoolParticipant');
  const submitPath = `${apiConfig.coreSettingsPath}/participant/hsp/${id}`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: submitPath
    });
    const result = HighSchoolProgramParticipantModel.fromJS(res.data);
    yield put(highSchoolProgramParticipantSettingsSaveSuccess(result));
  } catch (error) {
    yield put(highSchoolProgramParticipantSettingsSaveError(error));
  }
}
function* ListHighSchoolParticipant(includeDisabled: boolean) {
  try {
    const inclusion = includeDisabled ? '?include_all=true':'';
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/participant/hsp?include_all=true`);
    const settings = res.data.map((x: any) => HighSchoolProgramParticipantModel.fromJS(x));
    yield put(highSchoolProgramParticipantSettingsListSuccess(settings));
  } catch (error) {
    yield put(highSchoolProgramParticipantSettingsListError(error));
  }
}


export function* watchStudentOnBoardingLoad() {
  yield takeLatest(STUDENT_ON_BOARDING_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadStudentOnBoarding(payload))
}
export function* watchStudentOnBoardingSave() {
  yield takeLatest(STUDENT_ON_BOARDING_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<StudentOnboardingModel>>) => SaveStudentOnBoarding(payload))
}
export function* watchStudentOnBoardingDisable() {
  yield takeLatest(STUDENT_ON_BOARDING_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableStudentOnBoarding(payload))
}
export function* watchStudentOnBoardingList() {
  yield takeLatest(STUDENT_ON_BOARDING_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListStudentOnBoarding(payload))
}
export function* watchStudentPlacementLoad() {
  yield takeLatest(STUDENT_PLACEMENT_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadStudentPlacement(payload))
}
export function* watchStudentPlacementSave() {
  yield takeLatest(STUDENT_PLACEMENT_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<StudentPlacementModel>>) => SaveStudentPlacement(payload))
}
export function* watchStudentPlacementDisable() {
  yield takeLatest(STUDENT_PLACEMENT_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableStudentPlacement(payload))
}
export function* watchStudentPlacementList() {
  yield takeLatest(STUDENT_PLACEMENT_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListStudentPlacement(payload))
}

export function* watchGraduatingLoad() {
  yield takeLatest(GRADUATING_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadGraduating(payload))
}
export function* watchGraduatingSave() {
  yield takeLatest(GRADUATING_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<StudentGraduatingModel>>) => SaveGraduating(payload))
}
export function* watchGraduatingDisable() {
  yield takeLatest(GRADUATING_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableGraduating(payload))
}
export function* watchGraduatingList() {
  yield takeLatest(GRADUATING_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListGraduating(payload))
}

export function* watchClientOnBoardingLoad() {
  yield takeLatest(CLIENT_ON_BOARDING_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadClientOnBoarding(payload))
}
export function* watchClientOnBoardingSave() {
  yield takeLatest(CLIENT_ON_BOARDING_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<ClientOnboardingModel>>) => SaveClientOnBoarding(payload))
}
export function* watchClientOnBoardingDisable() {
  yield takeLatest(CLIENT_ON_BOARDING_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableClientOnBoarding(payload))
}
export function* watchClientOnBoardingList() {
  yield takeLatest(CLIENT_ON_BOARDING_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListClientOnBoarding(payload))
}

export function* watchEducationOnBoardingLoad() {
  yield takeLatest(EDUCATION_ON_BOARDING_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadEducationOnBoarding(payload))
}
export function* watchEducationOnBoardingSave() {
  yield takeLatest(EDUCATION_ON_BOARDING_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<EducationOnboardingModel>>) => SaveEducationOnBoarding(payload))
}
export function* watchEducationOnBoardingDisable() {
  yield takeLatest(EDUCATION_ON_BOARDING_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableEducationOnBoarding(payload))
}
export function* watchEducationOnBoardingList() {
  yield takeLatest(EDUCATION_ON_BOARDING_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListEducationOnBoarding(payload))
}


export function* watchWorkShadowOnBoardingLoad() {
  yield takeLatest(WORK_SHADOW_ON_BOARDING_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadWorkShadowOnBoarding(payload))
}
export function* watchWorkShadowOnBoardingSave() {
  yield takeLatest(WORK_SHADOW_ON_BOARDING_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<WorkShadowOnboardingModel>>) => SaveWorkShadowOnBoarding(payload))
}
export function* watchWorkShadowOnBoardingDisable() {
  yield takeLatest(WORK_SHADOW_ON_BOARDING_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableWorkShadowOnBoarding(payload))
}
export function* watchWorkShadowOnBoardingList() {
  yield takeLatest(WORK_SHADOW_ON_BOARDING_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListWorkShadowOnBoarding(payload))
}

export function* watchWorkShadowPoolLoad() {
  yield takeLatest(WORK_SHADOW_POOL_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadWorkShadowPool(payload))
}
export function* watchWorkShadowPoolSave() {
  yield takeLatest(WORK_SHADOW_POOL_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<PoolWorkShadowModel>>) => SaveWorkShadowPool(payload))
}
export function* watchWorkShadowPoolDisable() {
  yield takeLatest(WORK_SHADOW_POOL_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableWorkShadowPool(payload))
}
export function* watchWorkShadowPoolList() {
  yield takeLatest(WORK_SHADOW_POOL_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListWorkShadowPool(payload))
}

export function* watchWorkShadowPlacementLoad() {
  yield takeLatest(WORK_SHADOW_PLACEMENT_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadWorkShadowPlacement(payload))
}

export function* watchWorkShadowPlacementSave() {
  yield takeLatest(WORK_SHADOW_PLACEMENT_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<PlacementWorkShadowModel>>) => SaveWorkShadowPlacement(payload))
}

export function* watchWorkShadowPlacementList() {
  yield takeLatest(WORK_SHADOW_PLACEMENT_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableWorkShadowPlacement(payload))
}

export function* watchWorkShadowPlacementDisable() {
  yield takeLatest(WORK_SHADOW_POOL_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListWorkShadowPlacement(payload))
}

export function* watchHSPPlacementList() {
  yield takeLatest(WORK_SHADOW_PLACEMENT_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListHSPPlacement(payload))
}


export function* watchAlumniEarlyProfessionalLoad() {
  yield takeLatest(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadAlumniEarlyProfessional(payload))
}
export function* watchAlumniEarlyProfessionalSave() {
  yield takeLatest(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<AlumniEarlyProfessionalModel>>) => SaveAlumniEarlyProfessional(payload))
}
export function* watchAlumniEarlyProfessionalDisable() {
  yield takeLatest(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableAlumniEarlyProfessional(payload))
}
export function* watchAlumniEarlyProfessionalList() {
  yield takeLatest(ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListAlumniEarlyProfessional(payload))
}

export function* watchHighSchoolProgramParticipantLoad() {
  yield takeLatest(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.LOAD, ({payload}: PayloadAction<string>) => LoadHighSchoolParticipant(payload))
}
export function* watchHighSchoolProgramParticipantSave() {
  yield takeLatest(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.SAVE, ({payload}: PayloadAction<ICommonSavePayload<HighSchoolProgramParticipantModel>>) => SaveHighSchoolParticipant(payload))
}
export function* watchHighSchoolProgramParticipantDisable() {
  yield takeLatest(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.DISABLE, ({payload}: PayloadAction<string>) => DisableHighSchoolParticipant(payload))
}
export function* watchHighSchoolProgramParticipantList() {
  yield takeLatest(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST.LOAD, ({payload}: PayloadAction<boolean>) => ListHighSchoolParticipant(payload))
}


export default function* timelineSaga() {
  yield all ([
    call(watchStudentOnBoardingLoad),
    call(watchStudentOnBoardingSave),
    call(watchStudentOnBoardingList),
    call(watchStudentOnBoardingDisable),
    call(watchStudentPlacementLoad),
    call(watchStudentPlacementSave),
    call(watchStudentPlacementList),
    call(watchStudentPlacementDisable),
    call(watchGraduatingLoad),
    call(watchGraduatingSave),
    call(watchGraduatingList),
    call(watchGraduatingDisable),
    call(watchClientOnBoardingLoad),
    call(watchClientOnBoardingSave),
    call(watchClientOnBoardingList),
    call(watchClientOnBoardingDisable),
    call(watchEducationOnBoardingLoad),
    call(watchEducationOnBoardingSave),
    call(watchEducationOnBoardingList),
    call(watchEducationOnBoardingDisable),
    call(watchWorkShadowOnBoardingLoad),
    call(watchWorkShadowOnBoardingSave),
    call(watchWorkShadowOnBoardingList),
    call(watchWorkShadowOnBoardingDisable),
    call(watchWorkShadowPoolLoad),
    call(watchWorkShadowPoolSave),
    call(watchWorkShadowPoolList),
    call(watchWorkShadowPoolDisable),
    call(watchWorkShadowPlacementLoad),
    call(watchWorkShadowPlacementSave),
    call(watchWorkShadowPlacementList),
    call(watchWorkShadowPlacementDisable),
    call(watchAlumniEarlyProfessionalLoad),
    call(watchAlumniEarlyProfessionalSave),
    call(watchAlumniEarlyProfessionalDisable),
    call(watchAlumniEarlyProfessionalList),
    call(watchHighSchoolProgramParticipantLoad),
    call(watchHighSchoolProgramParticipantSave),
    call(watchHighSchoolProgramParticipantDisable),
    call(watchHighSchoolProgramParticipantList),
    call(watchHSPPlacementList),
  ])
}
