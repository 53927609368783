import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DialogCloseButton from '../../@template/core/DialogCloseButton';
import {useSelector} from 'react-redux';
import {selectJobError, selectJobErrorList, selectJobId, selectJobType} from '../../redux/jobProgress/Selectors';
import {jobProgressClear} from '../../redux/jobProgress/Actions';
import {JobProgressModel} from '../../types/models/JobProgressModel';
import {quarterCol, thirdCol, twoThirdCol} from '../../@template/utility/FormLayout/layoutConfig';
import GridContainer from '../../@template/core/GridContainer';
import SemiCircleProgress from '../../@template/core/SemiCircleProgress';
import useRecursiveTimeout from '../../@template/utility/AppPolling';
import {apiConfig, siteConfig} from '../../config';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {Loader, MessageView} from '../../@template';
import fileDownload from 'js-file-download';
import {format} from 'date-fns';
import {useAppDispatch} from '../../@template/utility/AppHooks';
import {JobErrorModel} from '../../types/models/JobErrorModel';
import {hasItems} from '../../@template/helpers/arrays';
import PersonLink from '../people/PersonLink';
import CompanyLink from '../companies/CompanyLink';

const timeoutInterval = 2000;

const ProgressLoaderDialog = () => {
  const [jobProgress, setJobProgress] = useState<JobProgressModel| undefined>(undefined);
  const [jobTimeout, setJobTimeout] = useState<boolean>(false);
  const [jobEndTime, setJobEndTime] = useState<Date | undefined>(undefined);
  const [fileDownloading, setFileDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string| undefined>(undefined);
  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<JobErrorModel[]>([]);

  const dispatch = useAppDispatch();

  const jobErrorList = useSelector(selectJobErrorList);
  const jobError = useSelector(selectJobError);
  const jobId = useSelector(selectJobId);
  const jobType = useSelector(selectJobType);

  let percent = 0;
  if(jobProgress && jobProgress?.itemsProcessed && jobProgress.itemsProcessed > 0 && jobProgress?.itemsTotal && jobProgress.itemsTotal > 0){
    percent = Math.round(100 * jobProgress.itemsProcessed / jobProgress.itemsTotal);
  }

  const jobRunning = !jobProgress?.itemsTotal || !jobProgress?.itemsProcessed || jobProgress?.jobStatus === 'JobStatus.InProgress';

  const loadJobUpdate = () => {
    const url = `${apiConfig.coreJobsPath}/${jobId}`;
    authenticatedApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: url
    }).then(response => {
      setJobProgress(JobProgressModel.fromJS(response.data));
    }).catch(err => {
      setError(err);
      console.warn(err);
    }).finally(() => {
      if(jobEndTime && jobEndTime === new Date()) {
        handleJobTimeout();
      }
    });
  }

  const handleJobTimeout = () => {
    setJobTimeout(true);
    const url = `${apiConfig.coreJobsPath}/${jobId}/timeout`;
    authenticatedApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: url
    }).then(response => {
      console.log(response);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleFileDownload = () => {
    setFileDownloading(true);
    const url = `${apiConfig.filesPath}/file-builder/${jobId}`;
    authenticatedApi.request({
      baseURL: apiConfig.filesUrl,
      method: 'POST',
      url: url
    }).then(response => {
      const currentDate = format(new Date(), siteConfig.dateFileFormat);
      const fileName = `CTFileExport_${currentDate}.csv`;
      fileDownload(response.data, fileName);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setFileDownloading(false);
    });
  }

  const retrieveErrors = () => {
    setErrorLoading(true);
    const url = `${apiConfig.coreJobsPath}/${jobId}/errors`;
    authenticatedApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: url
    }).then(response => {
      setErrorList(response.data.map((x: any) => JobErrorModel.fromJS(x)));
    }).catch(err => {
      setError(err);
      console.warn(err);
    }).finally(() => {
      setErrorLoading(false);
    });
  }

  useRecursiveTimeout(() =>
    new Promise(r => {
      if (jobRunning) loadJobUpdate();
      r();
    }), timeoutInterval);

  const showError = () => {
    return <MessageView variant="error" message="An error occurred initiating the job progress." />;
  };

  const finishJob = () => {
    setShowCloseDialog(false);
    setJobEndTime(undefined);
    setJobTimeout(false);
    dispatch(jobProgressClear());
  }

  const closeButton = () => {
    if(jobRunning){
      return(<Button
        variant="contained"
        color="secondary"
        onClick={() => setShowCloseDialog(true)}
      >
        Cancel
      </Button>);
    } else {
      return(<Button
        variant="contained"
        color="secondary"
        onClick={finishJob}
      >
        Close
      </Button>);
    }
  }

  const openErrorModal = () => {
    setShowErrorModal(true);
    if(!hasItems(errorList)) {
      retrieveErrors();
    }
  }

  const renderError = (error: JobErrorModel) => {
    if(error.failedEntityId)
    {
      if (error?.entityType === 'Person') {
        return (<React.Fragment key={error.failedEntityId}>
          <PersonLink personId={error.failedEntityId} linkProfile={false} />
          <p>{error.errorMessage}</p>
        </React.Fragment>);
      }
      if (error?.entityType === 'Company') {
        return (<React.Fragment key={error.failedEntityId}>
          <CompanyLink companyId={error.failedEntityId} linkProfile={false} />
          <p>{error.errorMessage}</p>
        </React.Fragment>);
      }
    }
    return '';
  }

  if(showErrorModal) {
    return(
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      >
        <DialogTitle>
          Errors From Job
          <DialogCloseButton onClick={finishJob} />
        </DialogTitle>
        <DialogContent>
          {errorLoading && <Loader />}
          {!errorLoading && hasItems(errorList) && errorList.map(renderError)}
          {!errorLoading && !hasItems(errorList) && <Box component='h4'>There was a problem retrieving the errors</Box>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowErrorModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (<>
    <Dialog open={showCloseDialog}>
      <DialogContent>
        {!(jobType === 'FILE') && "Closing this message will not stop execution of this job"}
        {jobType === 'FILE' && "Closing this will prevent your ability to download the file"}
      </DialogContent>
      <DialogActions>
        <Button sx={{ display: 'inline' }} variant="contained" color="secondary" size="small" onClick={() => setShowCloseDialog(false)}>No</Button>
        <Button sx={{ display: 'inline' }} variant="contained" color="primary" size="small" onClick={finishJob}>Yes</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={!!jobId}
      onClose={finishJob}
    >
      {error && showError()}
      <DialogTitle>
        Sending Job
        <DialogCloseButton onClick={finishJob} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <GridContainer spacing={1}>
          <Grid item {...thirdCol}>
            <SemiCircleProgress
              strokeWidth={5}
              percentage={percent}
              showPercentValue={true}
            />
          </Grid>
          <Grid item {...twoThirdCol}>
            {jobTimeout && <Box component="h4">This job has exceeded the time allocated to it, but it could still be running in the background.</Box>}
            {!jobTimeout && <>
              {jobRunning && !jobProgress?.jobDescription && <Box component="p">{jobProgress?.jobStatus === "JobStatus.InProgress" ? "Processing ":"Queuing "} Job</Box>}
              {jobRunning && !!jobProgress?.jobDescription && <Box component="p">{jobProgress?.jobDescription}</Box>}
              {!jobRunning && !jobProgress?.itemsFailed && jobProgress?.jobDescription && <p>{jobProgress?.itemsProcessed} of {jobProgress?.itemsTotal} succeeded in {jobProgress?.jobDescription}</p>}
              {!jobRunning && !jobProgress?.itemsFailed && jobProgress?.itemsTotal && jobProgress?.itemsTotal > 0 && !jobProgress?.jobDescription && <p>{jobProgress?.itemsProcessed} of {jobProgress?.itemsTotal} item/s processed</p>}
              {!jobRunning && !jobProgress?.itemsFailed && jobProgress?.itemsTotal == 0 && !jobProgress?.jobDescription && <p>No data found matching this search</p>}
              {!jobRunning && !jobProgress?.itemsFailed && jobType === 'FILE' && <LoadingButton
                color="primary"
                variant="contained"
                size="medium"
                loading={fileDownloading}
                onClick={handleFileDownload}>
                Download File
              </LoadingButton>}
              {!jobRunning && !!jobProgress?.itemsFailed && <React.Fragment>
                <p>{jobProgress?.itemsSuccess} of {jobProgress?.itemsProcessed} succeeded.</p>
                <p>{jobProgress?.itemsFailed} of {jobProgress?.itemsProcessed} failed. <a onClick={openErrorModal}>View Errors.</a></p>
                <p>Please contact support with any issues.</p>
              </React.Fragment>}
            </>}
          </Grid>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          loading={jobRunning}
          onClick={finishJob}
        >Job Completed</LoadingButton>
        {closeButton()}
      </DialogActions>
    </Dialog>
  </>);
}

export default ProgressLoaderDialog;
