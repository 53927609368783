import axios, {Method, AxiosError} from 'axios';
import {apiConfig} from '../../../../config';
import {camelKeys} from '../../../helpers/json';
import {snakeCaseKeys} from '../../../helpers/json';

const authRequest = axios.create({
  baseURL: apiConfig.coreUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const stripNull = (key: string, value: any) => {
  if (value === null) return undefined;
  return value;
};

authRequest.interceptors.request.use(
  (request) =>  {
    if (request.data) {
      request.data = JSON.stringify(snakeCaseKeys(request.data), stripNull);
    }
    return request;
  }
);
authRequest.interceptors.response.use(
  res => {
    if (res.data) {
      res.data = camelKeys(res.data);
    }
    return res;
  },
  (error: AxiosError) => {
    console.error('Request Failed:', error.config);
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
      if (error.response && error.response.status === 401) {
        setAuthToken(null, null);

      }
      return Promise.reject(error.response.data.message);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error ActionItemMessage:', error.message);
    }
    return Promise.reject(error.message);
  },
);

export const authCall = (baseUrl: string, method: Method, path: string, body: object | null) => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    method: method,
    url: path,
    data: body
  });
}

export const setAuthToken = (token: string | null, expiresOn: string | null) => {
  // console.log('authenticatedApi - SETTING TOKEN', token);
  if (token && expiresOn) {
    authRequest.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete authRequest.defaults.headers.common['Authorization'];
  }
};

export default authRequest;
