import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {studentPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const formPage = React.lazy(() => import('./StudentEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Students", studentPaths.StudentSearch)];
const universitySectionBreadcrumbs = [new BreadcrumbItemProps("University Students", studentPaths.StudentSearch)];

const engagementSectionBreadcrumbs = [new BreadcrumbItemProps("All Students", studentPaths.EngagementSearch)];

const studentRoutes = [
  new AppRouteProps("Add", studentPaths.StudentCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit", studentPaths.StudentEdit, formPage, sectionBreadcrumbs),
  new AppRouteProps("Promote", studentPaths.StudentPromote, React.lazy(() => import('./StudentPromote')), sectionBreadcrumbs),
  new AppRouteProps("Rollback", studentPaths.StudentRollback, React.lazy(() => import('./StudentRollback')), sectionBreadcrumbs),
  new AppRouteProps("Search", studentPaths.StudentSearch, React.lazy(() => import('./StudentSearch')), universitySectionBreadcrumbs),
  new AppRouteProps("Details", studentPaths.StudentProfile, React.lazy(() => import('./StudentDetails')), engagementSectionBreadcrumbs),
  new AppRouteProps("Search", studentPaths.EngagementSearch, React.lazy(() => import('./EngagementSearch')), engagementSectionBreadcrumbs),
  new AppRouteProps("Placement Search", studentPaths.PlacementSearch, React.lazy(() => import('./PlacementSearch')), []),
  new AppRouteProps("Employer Profile", studentPaths.EmployerPublicProfileEdit, React.lazy(() => import('./StudentDetails/EmployerProfile')), sectionBreadcrumbs),
]

const studentRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, studentRoutes)
];

export default studentRoutesConfig;
