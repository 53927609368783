import {ICommonCacheActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {CompanyListItem} from '../../types/views/CompanyListItem';
import {ImageMetadataModel} from '../../types/models/ImageMetadataModel';

export const COMPANY_CACHE: ICommonCacheActionType = {
  ADD: 'companyCache/add',
  UPDATE: 'companyCache/updated',
  MERGE_ITEMS: 'companyCache/merge',
  ERROR: 'companyCache/error',
  REMOVE: 'companyCache/remove'
};

export const COMPANY_LOGO_CACHE: ICommonCacheActionType = {
  ADD: 'companyLogoCache/add',
  UPDATE: 'companyLogoCache/updated',
  MERGE_ITEMS: 'companyLogoCache/merge',
  ERROR: 'companyLogoCache/error',
  REMOVE: 'companyLogoCache/remove'
};

export const companyCacheAdd = createAction<string[]>(COMPANY_CACHE.ADD);
export const companyCacheUpdate = createAction<string[]>(COMPANY_CACHE.UPDATE);
export const companyCacheMerge = createAction<CompanyListItem[]>(COMPANY_CACHE.MERGE_ITEMS);
export const companyCacheError = createAction<string>(COMPANY_CACHE.ERROR);
export const companyCacheRemove = createAction<string>(COMPANY_CACHE.REMOVE);
// Not sure the logo add and update is used here. It seems to go in a circle on the legacy platform
export const companyLogoCacheAdd = createAction<string[]>(COMPANY_LOGO_CACHE.ADD);
export const companyLogoCacheUpdate = createAction(COMPANY_LOGO_CACHE.UPDATE);
export const companyLogoCacheMerge = createAction<ImageMetadataModel[]>(COMPANY_LOGO_CACHE.MERGE_ITEMS);
export const companyLogoCacheError = createAction<string>(COMPANY_LOGO_CACHE.ERROR);
export const companyLogoCacheRemove = createAction<string>(COMPANY_LOGO_CACHE.REMOVE);
