import React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import {Dialog, Popover, Theme, InputBase, InputAdornment, DialogTitle} from '@mui/material';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Scrollbar from '../../../@template/core/Scrollbar';
import clsx from 'clsx';
import GlobalSearchItem from './GlobalSearchItem';
import {Fonts} from '../../../shared/constants/AppEnums';
import makeStyles from '@mui/styles/makeStyles';
import {useSelector} from 'react-redux';
import {selectGlobalSearchLoading, selectGlobalSearchValue, selectGlobalSearchResult} from '../../../redux/globalSearch/Selectors';
import {GlobalIndexModel} from '../../../types/views/GlobalIndexModel';
import {globalSearchLoad} from '../../../redux/globalSearch/Actions';
import {useAppDispatch} from '../../../@template/utility/AppHooks';

const useStyles = makeStyles((theme: Theme) => ({
  crPopover: {
    height: 'calc(100% - 16px)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100% - 128px)',
    },
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
      '& .MuiPaper-root': {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
          marginTop: '64px',
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: '128px',
        },
        '& .MuiBox-root': {
          '& .MuiInputBase-root': {
            padding: '12px 20px',
            fontSize: '18px',
            '& .MuiInputBase-input': {
              padding: 0,
              margin: 0
            }
          }
        }
      }
    }
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('xs')]: {
      height: 70,
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      width: 'auto',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
}));

interface GlobalSearchProps {}

const GlobalSearch: React.FC<GlobalSearchProps> = () => {
  const [
    searchOpen,
    setSearchOpen,
  ] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const globalSearchResult = useSelector(selectGlobalSearchResult);
  const globalSearchLoading = useSelector(selectGlobalSearchLoading);
  const globalSearchValue = useSelector(selectGlobalSearchValue);

  const onClickSearchButton = () => {
    setSearchOpen(true);
  };

  const classes = useStyles();

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(globalSearchLoad(e.target.value));
  }

  return <>
    <IconButton
      className={clsx(classes.notiBtn, 'notiBtn')}
      aria-label='show the global search'
      color='inherit'
      onClick={onClickSearchButton}
    >
      <SearchIcon
        className={clsx(classes.notiIcon, 'notiIcon')}
      />
      <Box
        sx={{ display: {  xs: 'block', sm: 'none' } }}
        ml={4}
        fontSize={16}
        fontWeight={Fonts.REGULAR}
        component='span'>
        Global Search
      </Box>
    </IconButton>

    <Dialog
      id='global-search'
      open={searchOpen}
      fullWidth
      maxWidth='lg'
      onClose={() => setSearchOpen(false)}
      className={classes.crPopover}
    >
      <DialogTitle>
        <InputBase
          id="input-with-icon-adornment"
          autoFocus={true}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          fullWidth={true}
          onChange={handleSearch}
        />
      </DialogTitle>
      <Scrollbar className='scroll-submenu'>
        <List
          className={classes.list}
        >
          {globalSearchResult.map((item: GlobalIndexModel, index) => (
            <GlobalSearchItem key={item.entityId} item={item} onClose={() => setSearchOpen(false)}/>
          ))}
        </List>
      </Scrollbar>
    </Dialog>
  </>;
};

export default GlobalSearch;