import { createSelector } from '@reduxjs/toolkit';
import {IAuthState} from './Reducer';

/*
* Note that selectors for value props no longer needed and with immer state,
* we can just expose the whole tree
* */
export const selectAuth = (state: { authLocal: IAuthState }) => state.authLocal;

export const selectCurrentUser = createSelector(
  selectAuth,
  (state) => state.currentUser
);

export const selectLoggingIn = createSelector(
  selectAuth,
  (state) => state.loggingIn
);

export const selectAuthError = createSelector(
  selectAuth,
  (state) => state.error
);
