import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {HSP_POOL_CACHE} from './Actions';
import {HSPPoolSuggestModel} from '../../types/views/HSPPoolSuggestModel';

export interface IHSPPoolsCacheState {
  hspPoolCacheUpdating: boolean | false;
  hspPoolCacheError: string | undefined;
  hspPoolPendingIdList: string[];
  hspPoolCache: HSPPoolSuggestModel[];
}

const initialState: IHSPPoolsCacheState = {
  hspPoolCacheUpdating: false,
  hspPoolCacheError: undefined,
  hspPoolPendingIdList: [],
  hspPoolCache: [],
}

const hspPoolsCacheReducer = createReducer(initialState, {
  [HSP_POOL_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.hspPoolPendingIdList = [...new Set([...state.hspPoolPendingIdList, ...action.payload])];
    state.hspPoolCacheError = undefined;
  },
  [HSP_POOL_CACHE.UPDATE]: (state) => {
    state.hspPoolPendingIdList = [];
    state.hspPoolCacheUpdating = true;
    state.hspPoolCacheError = undefined;
  },
  [HSP_POOL_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<HSPPoolSuggestModel[]>) => {
    let currentPoolsCache = state.hspPoolCache;
    action.payload.map(item => {
      if (!currentPoolsCache.find(x => x.id === item.id)) {
        currentPoolsCache.push(item);
      }
    });
    state.hspPoolCache = currentPoolsCache;
    state.hspPoolCacheUpdating = false;
    state.hspPoolCacheError = undefined;
  },
  [HSP_POOL_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.hspPoolCache = state.hspPoolCache.filter(x => x.id !== action.payload);
    state.hspPoolCacheUpdating = false;
    state.hspPoolCacheError = undefined;
  },
});

export default hspPoolsCacheReducer;