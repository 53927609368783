import {all} from '@redux-saga/core/effects';
import authSaga from './auth/Sagas';
import sessionSaga from './session/Sagas';
import settingsSaga from './settings/Sagas';
import studentSaga from './students/Sagas';
import companyCacheSaga from './companyCache/Sagas';
import fileCacheSaga from './filesCache/Sagas';
import mailingCacheSaga from './mailingCache/Sagas';
import opportunitySaga from './opportunities/Sagas';
import peopleCacheSaga from './peopleCache/Sagas';
import placementCacheSaga from './placementCache/Sagas';
import poolCacheSaga from './poolsCache/Saga';
import timelineSaga from './timelines/Sagas';
import workShadowSaga from './workShadow/Sagas';
import companySaga from './companies/Sagas';
import devOperationsSaga from './devOperations/Sagas';
import scorecardSaga from './scorecards/Sagas';
import contactsSaga from './contacts/Saga';
import volunteersSaga from './volunteers/Saga';
import placementSaga from './placements/Sagas';
import poolSaga from './pools/Sagas';
import peopleSaga from './people/Sagas';
import messageSaga from './messaging/Sagas';
import fileSaga from './files/Sagas';
import teamsSaga from './team/Sagas';
import mailingListSaga from './mailingList/Sagas';
import historySaga from './history/Sagas';
import changeRequestsSaga from './changeRequest/Sagas';
import globalSearchSaga from './globalSearch/Sagas';
import alumniSaga from './alumni/Sagas';
import engagementSaga from './engagementInfo/Sagas';
import hspPlacementSaga from './hspPlacements/Sagas';
import hspPoolSaga from './hspPools/Sagas';
import hspOpportunitySaga from './hspOpportunities/Sagas';
import hspPlacementCacheSaga from './hspPlacementCache/Sagas';
import hspPoolCacheSaga from './hspPoolCache/Sagas';


export default function* rootSaga() {
  yield all([
    authSaga(),
    companySaga(),
    companyCacheSaga(),
    fileSaga(),
    fileCacheSaga(),
    messageSaga(),
    mailingCacheSaga(),
    opportunitySaga(),
    peopleCacheSaga(),
    placementCacheSaga(),
    poolSaga(),
    poolCacheSaga(),
    sessionSaga(),
    settingsSaga(),
    studentSaga(),
    timelineSaga(),
    devOperationsSaga(),
    workShadowSaga(),
    scorecardSaga(),
    contactsSaga(),
    volunteersSaga(),
    placementSaga(),
    peopleSaga(),
    teamsSaga(),
    mailingListSaga(),
    historySaga(),
    changeRequestsSaga(),
    globalSearchSaga(),
    alumniSaga(),
    engagementSaga(),
    hspPlacementSaga(),
    hspPoolSaga(),
    hspOpportunitySaga(),
    hspPlacementCacheSaga(),
    hspPoolCacheSaga()
  ]);
}
