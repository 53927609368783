export class TagListModel implements ITagListModel {
  /** Tags with extra functionality */
  systemTags?: string[] | undefined;
  /** Tags that can be applied to files */
  fileTags?: string[] | undefined;

  constructor(data?: ITagListModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["systemTags"])) {
        this.systemTags = [] as any;
        for (let item of Data["systemTags"])
          this.systemTags!.push(item);
      }
      if (Array.isArray(Data["fileTags"])) {
        this.fileTags = [] as any;
        for (let item of Data["fileTags"])
          this.fileTags!.push(item);
      }
    }
  }

  static fromJS(data: any): TagListModel {
    data = typeof data === 'object' ? data : {};
    let result = new TagListModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.systemTags)) {
      data["systemTags"] = [];
      for (let item of this.systemTags)
        data["systemTags"].push(item);
    }
    if (Array.isArray(this.fileTags)) {
      data["fileTags"] = [];
      for (let item of this.fileTags)
        data["fileTags"].push(item);
    }
    return data;
  }
}

export interface ITagListModel {
  /** Tags with extra functionality */
  systemTags?: string[] | undefined;
  /** Tags that can be applied to files */
  fileTags?: string[] | undefined;
}