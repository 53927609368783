import {hasItems} from '../../@template/helpers/arrays';

export class InactiveReasonConfigModel implements IInactiveReasonConfigModel {
  title?: string | undefined;
  reasons?: string[] | undefined;

  constructor(data?: IInactiveReasonConfigModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.title = Data["title"];
      if (Array.isArray(Data["reasons"])) {
        this.reasons = [] as any;
        for (let item of Data["reasons"])
          this.reasons!.push(item);
      }
    }
  }

  static fromJS(data: any): InactiveReasonConfigModel {
    data = typeof data === 'object' ? data : {};
    let result = new InactiveReasonConfigModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["title"] = this.title;
    if (Array.isArray(this.reasons)) {
      data["reasons"] = [];
      for (let item of this.reasons)
        data["reasons"].push(item);
    }
    return data;
  }
}

export interface IInactiveReasonConfigModel {
  title?: string | undefined;
  reasons?: string[] | undefined;
}