import {AddressViewModel} from '../models/AddressViewModel';
import {HSPOpportunityPoolStudentViewModel} from "../models/HSPOpportunityPoolStudentViewModel";

export class HSPOpportunityPoolDetailModel implements IHSPOpportunityPoolDetailModel {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  poolStatus?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  isDeleted?: boolean | undefined;
  placements?: number | undefined;
  billingStatus?: string | undefined;
  closedCompanyContext?: string | undefined;
  locationAddress?: AddressViewModel | undefined;
  degreePoolIds?: string[] | undefined;
  remoteFlexible?: boolean | undefined;
  poolStudents?: HSPOpportunityPoolStudentViewModel[] | undefined;

  constructor(data?: IHSPOpportunityPoolDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyId = Data["companyId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.poolStatus = Data["poolStatus"];
      this.title = Data["title"];
      this.description = Data["description"];
      this.isDeleted = Data["isDeleted"];
      this.placements = Data["placements"];
      this.billingStatus = Data["billingStatus"];
      this.closedCompanyContext = Data["closedCompanyContext"];
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      if (Array.isArray(Data["degreePoolIds"])) {
        this.degreePoolIds = [] as any;
        for (let item of Data["degreePoolIds"])
          this.degreePoolIds!.push(item);
      }
      this.remoteFlexible = Data["remoteFlexible"];
      if (Array.isArray(Data["poolStudents"])) {
        this.poolStudents = [] as any;
        for (let item of Data["poolStudents"])
          this.poolStudents!.push(HSPOpportunityPoolStudentViewModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): HSPOpportunityPoolDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPOpportunityPoolDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyId"] = this.companyId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["poolStatus"] = this.poolStatus;
    data["title"] = this.title;
    data["description"] = this.description;
    data["isDeleted"] = this.isDeleted;
    data["placements"] = this.placements;
    data["billingStatus"] = this.billingStatus;
    data["closedCompanyContext"] = this.closedCompanyContext;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    if (Array.isArray(this.degreePoolIds)) {
      data["degreePoolIds"] = [];
      for (let item of this.degreePoolIds)
        data["degreePoolIds"].push(item);
    }
    data["remoteFlexible"] = this.remoteFlexible;
    if (Array.isArray(this.poolStudents)) {
      data["poolStudents"] = [];
      for (let item of this.poolStudents)
        data["poolStudents"].push(item.toJSON());
    }
    return data;
  }
}

export interface IHSPOpportunityPoolDetailModel {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  poolStatus?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  isDeleted?: boolean | undefined;
  placements?: number | undefined;
  billingStatus?: string | undefined;
  closedCompanyContext?: string | undefined;
  locationAddress?: AddressViewModel | undefined;
  degreePoolIds?: string[] | undefined;
  remoteFlexible?: boolean | undefined;
  poolStudents?: HSPOpportunityPoolStudentViewModel[] | undefined;
}
