import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  ENGAGEMENT_SEARCH,
  engagementsSearchSuccess,
  engagementsSearchError, BULK_ADVISOR_OPERATION_SEARCH, bulkUpdateAdvisorSearchSuccess, bulkUpdateAdvisorSearchError,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {EngagementInfoSearchParamsModel} from '../../types/requests/EngagementInfoSearchParamsModel';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';

function* SearchEngagements({ page, sort, searchParams }: ISearchRequest<EngagementInfoSearchParamsModel>) {
  console.log('SAGA - engagementSaga - SearchEngagement');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreEngagementPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    console.log('results', results);
    yield put(engagementsSearchSuccess(results));
    console.log("=======================")
  } catch (error) {
    console.log('error', error);
    yield put(engagementsSearchError(error));
  }
}

function* SearchBulkUpdateAdvisorEngagements({ page, sort, searchParams }: ISearchRequest<EngagementInfoSearchParamsModel>) {
  console.log('SAGA - engagementSaga - SearchBulkUpdateAdvisorEngagements');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreEngagementPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(bulkUpdateAdvisorSearchSuccess(results));
  } catch (error) {
    yield put(bulkUpdateAdvisorSearchError(error));
  }
}

export function* watchEngagementSearch() {
  yield takeLatest(ENGAGEMENT_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<EngagementInfoSearchParamsModel>>) => SearchEngagements(payload))
}

export function* watchEngagementSearchRefresh() {
  yield takeLatest(ENGAGEMENT_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<EngagementInfoSearchParamsModel>>) => SearchEngagements(payload))
}

export function* watchBulkUpdateAdvisorSearch() {
  yield takeLatest(BULK_ADVISOR_OPERATION_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<EngagementInfoSearchParamsModel>>) => SearchBulkUpdateAdvisorEngagements(payload))
}

export function* watchBulkUpdateAdvisorSearchRefresh() {
  yield takeLatest(BULK_ADVISOR_OPERATION_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<EngagementInfoSearchParamsModel>>) => SearchBulkUpdateAdvisorEngagements(payload))
}

export default function* engagementSaga() {
  yield all ([
    call(watchEngagementSearch),
    call(watchEngagementSearchRefresh),
    call(watchBulkUpdateAdvisorSearch),
    call(watchBulkUpdateAdvisorSearchRefresh),
  ])
}
