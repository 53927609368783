import {formatDate} from '../../@template/helpers/datetimes';

export const defaultForMailingListSearchParamsModel = () => {
  return MailingListSearchParamsModel.fromJS({
    searchText: '',
    recipientSearchText: '',
    isContact: false,
    isStudent: false,
    isVolunteer: false,
    isWorkShadow: false,
    isAlumni: false,
    isEngagementInfo: false,
    advisorUsers: [],
    createdByUserId: '',
    dateCreatedTo: '',
    dateCreatedFrom: '',
    userId: '',
    teamId: '',
    isArchived: false,
    teamPermissions: false,
    privatePermissions: false,
  });
}


export class MailingListSearchParamsModel implements IMailingListSearchParamsModel {
  /** Searches title */
  searchText?: string | undefined;
  /** Searches email */
  recipientSearchText?: string | undefined;
  isStudent?: boolean | undefined;
  isContact?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isEngagementInfo?: boolean | undefined;
  isArchived?: boolean | undefined;
  dateCreatedTo?: Date | undefined;
  dateCreatedFrom?: Date | undefined;
  createdByUserId?: string | undefined;
  advisorUsers?: string[] | undefined;
  userId?: string | undefined;
  teamId?: string | undefined;
  teamPermissions?: boolean | undefined;
  privatePermissions?: boolean | undefined;

  constructor(data?: IMailingListSearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      this.recipientSearchText = Data["recipientSearchText"];
      this.isStudent = Data["isStudent"];
      this.isContact = Data["isContact"];
      this.isVolunteer = Data["isVolunteer"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.isAlumni = Data["isAlumni"];
      this.isEngagementInfo = Data["isEngagementInfo"];
      this.isArchived = Data["isArchived"];
      this.dateCreatedTo = Data["dateCreatedTo"] ? new Date(Data["dateCreatedTo"].toString()) : <any>undefined;
      this.dateCreatedFrom = Data["dateCreatedFrom"] ? new Date(Data["dateCreatedFrom"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.userId = Data["userId"];
      this.teamId = Data["teamId"];
      this.teamPermissions = Data["teamPermissions"];
      this.privatePermissions = Data["privatePermissions"];
    }
  }

  static fromJS(data: any): MailingListSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new MailingListSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    data["recipientSearchText"] = this.recipientSearchText;
    data["isStudent"] = this.isStudent;
    data["isContact"] = this.isContact;
    data["isVolunteer"] = this.isVolunteer;
    data["isWorkShadow"] = this.isWorkShadow;
    data["isAlumni"] = this.isAlumni;
    data["isEngagementInfo"] = this.isEngagementInfo;
    data["isArchived"] = this.isArchived;
    data["dateCreatedTo"] = this.dateCreatedTo ? formatDate(this.dateCreatedTo) : <any>undefined;
    data["dateCreatedFrom"] = this.dateCreatedFrom ? formatDate(this.dateCreatedFrom) : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["userId"] = this.userId;
    data["teamId"] = this.teamId;
    data["teamPermissions"] = this.teamPermissions;
    data["privatePermissions"] = this.privatePermissions;
    return data;
  }
}

export interface IMailingListSearchParamsModel {
  /** Searches title */
  searchText?: string | undefined;
  /** Searches email */
  recipientSearchText?: string | undefined;
  isStudent?: boolean | undefined;
  isContact?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isEngagementInfo?: boolean | undefined;
  isArchived?: boolean | undefined;
  dateCreatedTo?: Date | undefined;
  dateCreatedFrom?: Date | undefined;
  createdByUserId?: string | undefined;
  advisorUsers?: string[] | undefined;
  userId?: string | undefined;
  teamId?: string | undefined;
  teamPermissions?: boolean | undefined;
  privatePermissions?: boolean | undefined;
}