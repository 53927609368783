import {toLocalTime} from '../../@template/helpers/datetimes';

/*
 * WARNING!
 * Messaging models need to have their TZ data adjusted.
 * If replacing this model, you need to apply the toLocaltime function for any date parsing
 */
export class EventItemTrackingDetail implements IEventItemTrackingDetail {
  isSeen?: boolean | undefined;
  dtCreated?: Date | undefined;
  dtLastSeen?: Date | undefined;
  dtFirstSeen?: Date | undefined;
  isBounced?: boolean | undefined;
  bouncedMessage?: string | undefined;
  dtFirstBounced?: Date | undefined;
  dtLastBounced?: Date | undefined;
  isEmailDelivered?: boolean | undefined;
  dtEmailDelivered?: Date | undefined;
  hasSourceData?: boolean | undefined;

  constructor(data?: IEventItemTrackingDetail) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.isSeen = Data["isSeen"];
      this.dtCreated = Data["dtCreated"] ? toLocalTime(Data["dtCreated"].toString()) : <any>undefined;
      this.dtLastSeen = Data["dtLastSeen"] ? toLocalTime(Data["dtLastSeen"].toString()) : <any>undefined;
      this.dtFirstSeen = Data["dtFirstSeen"] ? toLocalTime(Data["dtFirstSeen"].toString()) : <any>undefined;
      this.isBounced = Data["isBounced"];
      this.bouncedMessage = Data["bouncedMessage"];
      this.dtFirstBounced = Data["dtFirstBounced"] ? toLocalTime(Data["dtFirstBounced"].toString()) : <any>undefined;
      this.dtLastBounced = Data["dtLastBounced"] ? toLocalTime(Data["dtLastBounced"].toString()) : <any>undefined;
      this.isEmailDelivered = Data["isEmailDelivered"];
      this.dtEmailDelivered = Data["dtEmailDelivered"] ? toLocalTime(Data["dtEmailDelivered"].toString()) : <any>undefined;
      this.hasSourceData = Data["hasSourceData"];
    }
  }

  static fromJS(data: any): EventItemTrackingDetail {
    data = typeof data === 'object' ? data : {};
    let result = new EventItemTrackingDetail();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isSeen"] = this.isSeen;
    data["dtCreated"] = this.dtCreated ? this.dtCreated.toISOString() : <any>undefined;
    data["dtLastSeen"] = this.dtLastSeen ? this.dtLastSeen.toISOString() : <any>undefined;
    data["dtFirstSeen"] = this.dtFirstSeen ? this.dtFirstSeen.toISOString() : <any>undefined;
    data["isBounced"] = this.isBounced;
    data["bouncedMessage"] = this.bouncedMessage;
    data["dtFirstBounced"] = this.dtFirstBounced ? this.dtFirstBounced.toISOString() : <any>undefined;
    data["dtLastBounced"] = this.dtLastBounced ? this.dtLastBounced.toISOString() : <any>undefined;
    data["isEmailDelivered"] = this.isEmailDelivered;
    data["dtEmailDelivered"] = this.dtEmailDelivered ? this.dtEmailDelivered.toISOString() : <any>undefined;
    data["hasSourceData"] = this.hasSourceData;
    return data;
  }
}

export interface IEventItemTrackingDetail {
  isSeen?: boolean | undefined;
  dtCreated?: Date | undefined;
  dtLastSeen?: Date | undefined;
  dtFirstSeen?: Date | undefined;
  isBounced?: boolean | undefined;
  bouncedMessage?: string | undefined;
  dtFirstBounced?: Date | undefined;
  dtLastBounced?: Date | undefined;
  isEmailDelivered?: boolean | undefined;
  dtEmailDelivered?: Date | undefined;
  hasSourceData?: boolean | undefined;
}