import React from 'react';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import BusinessIcon from '@mui/icons-material/Business';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {selectCompanyColor} from '../../../@template/helpers/profileColour';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import defaultConfig from '../../../@template/utility/ContextProvider/defaultConfig';
import Avatar from '@mui/material/Avatar';
import {Tooltip} from '@mui/material';
import {AppState} from '../../../redux/store';
import {CompanyListItem} from '../../../types/views/CompanyListItem';
import {companyCacheAdd, companyLogoCacheAdd, companyLogoCacheUpdate} from '../../../redux/companyCache/Actions';
import {ImageMetadataModel} from '../../../types/models/ImageMetadataModel';
import {apiConfig, imageConfig} from '../../../config';


interface ICompanyIconProps {
  companyId?: string | undefined,
  size?: number | undefined,
  overrideColor?: string | undefined,
  onClick?(): void | undefined,
}

const getCacheItem = (id: string | undefined) => (state: AppState) => {
  if (id)
    return state.companiesCache.companyCache.find((x: CompanyListItem) => x.id === id);
  return undefined;
}

const getImageCacheItem = (id: string | undefined) => (state: AppState) => {
  if (id)
    return state.companiesCache.companyLogoCache.find((x: ImageMetadataModel) => x.imageId === id);
  return undefined;
}

const CompanyAvatar = ({ companyId, onClick, size = 20, overrideColor }: ICompanyIconProps) => {
  const dispatch = useAppDispatch();
  const [ avatarCompanyId ] = useState(companyId);

  const listItem = useSelector(getCacheItem(avatarCompanyId??''));
  const imageItem = useSelector(getImageCacheItem(listItem?.logoImageId));

  useEffect(() => {
    if (!listItem && avatarCompanyId) {
      dispatch(companyCacheAdd([avatarCompanyId]));
    }
  }, [ avatarCompanyId ]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const color = defaultConfig.theme.palette.secondary;

  if (listItem) {
    const initials = (listItem.companyName ?? '?').charAt(0).toUpperCase();
    if (listItem.logoImageId && imageItem) {
      const imageUrl = `${apiConfig.imagesUrl}${imageConfig.imageSize.imagesLarge}/${imageItem.filename}`;
      return (
        <Tooltip placement="bottom" title={listItem.companyName ?? '?'}>
          <>
            <Box onClick={(e) => handleClick(e)}>
              <Avatar src={imageUrl} sx={{ width: size, height: size}} variant="square">{initials}</Avatar>
            </Box>
          </>
        </Tooltip>
      );
    }

    const backgroundColorCode = !!overrideColor ? overrideColor : selectCompanyColor(initials);
    // It's not undefined, but meh
    const backgroundColor = alpha(backgroundColorCode??'#efefef', 0.1);
    const fontColor = overrideColor ? overrideColor : selectCompanyColor(initials);

    return (
      <Tooltip placement="bottom" title={listItem.companyName ?? '?'}>
        <>
          {listItem?.picture && <Box onClick={(e) => handleClick(e)}><Avatar src={listItem.picture} sx={{ width: size, height: size}} variant="square"/></Box>}
          {!listItem?.picture && <Box onClick={(e) => handleClick(e)}><Avatar sx={{ bgcolor: backgroundColor, color: fontColor, width: size, height: size }} variant="square">
            {initials}
          </Avatar></Box>}
        </>
      </Tooltip>
    );
  }
  return <Avatar onClick={(e) => handleClick(e)} sx={{ bgcolor: '#dfdfdf', width: size, height: size }}><BusinessIcon /></Avatar>;
};

export default CompanyAvatar;
