import {PoolDetailModel} from '../../types/models/PoolDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {
  COMPANY_POOL_LIST,
  COMPANY_POOL_SEASONS,
} from './Actions';
import {CompanyPoolSeasonModel} from '../../types/models/CompanyPoolSeasonModel';


export interface IPoolState {
  poolCompanySeasons: CompanyPoolSeasonModel[] | undefined;
  poolCompanySeasonsLoading: boolean;
  poolCompanySeasonsRefreshing: boolean;
  poolCompanySeasonsError: string | undefined;
  poolCompanyList: PoolDetailModel[] | undefined;
  poolCompanyListRefreshing: boolean;
  poolCompanyListLoading: boolean;
  poolCompanyListError: string | undefined;
}

const initialState: IPoolState = {
  poolCompanySeasons: undefined,
  poolCompanySeasonsLoading: false,
  poolCompanySeasonsRefreshing: false,
  poolCompanySeasonsError: undefined,
  poolCompanyList: undefined,
  poolCompanyListRefreshing: false,
  poolCompanyListLoading: false,
  poolCompanyListError: undefined,
};

const poolsReducer = createReducer(initialState, {
  [COMPANY_POOL_SEASONS.LOAD]: (state) => {
    state.poolCompanySeasons = undefined;
    state.poolCompanySeasonsRefreshing = false;
    state.poolCompanySeasonsLoading = true;
    state.poolCompanySeasonsError = undefined;
  },
  [COMPANY_POOL_SEASONS.REFRESH]: (state) => {
    state.poolCompanySeasons = undefined;
    state.poolCompanySeasonsRefreshing = true;
    state.poolCompanySeasonsLoading = false;
    state.poolCompanySeasonsError = undefined;
  },
  [COMPANY_POOL_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.poolCompanySeasonsRefreshing = false;
    state.poolCompanySeasonsLoading = false;
    state.poolCompanySeasonsError = action.payload;
  },
  [COMPANY_POOL_SEASONS.LOADED]: (state, action: PayloadAction<CompanyPoolSeasonModel[]>) => {
    state.poolCompanySeasons = action.payload;
    state.poolCompanySeasonsRefreshing = false;
    state.poolCompanySeasonsLoading = false;
    state.poolCompanySeasonsError = undefined;
  },

  [COMPANY_POOL_LIST.LOAD]: (state) => {
    state.poolCompanyList = undefined;
    state.poolCompanyListRefreshing = false;
    state.poolCompanyListLoading = true;
    state.poolCompanyListError = undefined;
  },
  [COMPANY_POOL_LIST.REFRESH]: (state) => {
    state.poolCompanyList = undefined;
    state.poolCompanyListRefreshing = true;
    state.poolCompanyListLoading = false;
    state.poolCompanyListError = undefined;
  },
  [COMPANY_POOL_LIST.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.poolCompanyListRefreshing = false;
    state.poolCompanyListLoading = false;
    state.poolCompanyListError = action.payload;
  },
  [COMPANY_POOL_LIST.LOADED]: (state, action: PayloadAction<PoolDetailModel[]>) => {
    state.poolCompanyList = action.payload;
    state.poolCompanyListRefreshing = false;
    state.poolCompanyListLoading = false;
    state.poolCompanyListError = undefined;
  },
});

export default poolsReducer;

