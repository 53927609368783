import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {contactPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const formPage = React.lazy(() => import('./ContactEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Contact", contactPaths.ContactSearch)];

const contactRoutes = [
  new AppRouteProps("Add", contactPaths.ContactCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit", contactPaths.ContactEdit, formPage, sectionBreadcrumbs),
  new AppRouteProps("Promote", contactPaths.ContactPromote, React.lazy(() => import('./ContactPromote')), sectionBreadcrumbs),
  new AppRouteProps("Search", contactPaths.ContactSearch, React.lazy(() => import('./ContactSearch')), sectionBreadcrumbs),
  new AppRouteProps("Details", contactPaths.ContactProfile, React.lazy(() => import('./ContactDetails')), sectionBreadcrumbs),
]

const contactRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, contactRoutes)
];

export default contactRoutesConfig;
