import {LazyExoticComponent} from 'react';
import {PermissionLevel} from './common/Permissions';

export interface IBreadcrumbItemPropType {
  name: string;
  path?: string | undefined;
}

export class BreadcrumbItemProps implements IBreadcrumbItemPropType {
  name: string;
  path?: string | undefined;

  constructor(name: string, path: string | undefined = undefined) {
    this.name = name;
    this.path = path;
  }
}

export interface IAppRoutePropType {
  auth: number | undefined;
  path: string;
  breadcrumbs: IBreadcrumbItemPropType[];
  component: LazyExoticComponent<any>;
}

export interface IAppRouteGroupPropsType {
  auth: number | undefined;
  routes: IAppRoutePropType[] | [];
}

export class AppRouteProps implements IAppRoutePropType {
  auth: number | undefined;
  path: string;
  breadcrumbs: BreadcrumbItemProps[];
  component: LazyExoticComponent<any>;

  constructor(title: string, path: string, component: LazyExoticComponent<any>, sectionBreadCrumbs: BreadcrumbItemProps[], auth: number | undefined = 1) {
    this.auth = auth;
    this.path = path;
    this.breadcrumbs = [...sectionBreadCrumbs, new BreadcrumbItemProps(title)];
    this.component = component;
  }
}

export class AppRouteGroupProps implements IAppRouteGroupPropsType {
  auth: number | undefined;
  routes: IAppRoutePropType[] | [];

  constructor(auth: number | undefined, routes: AppRouteProps[]) {
    this.auth = auth;
    this.routes = routes;
  }
}
