import {formatDate} from '../../@template/helpers/datetimes';
import { AlumniStatusEnum } from '../models/Enums';

const defaultAlumniStatusFilter = [
  AlumniStatusEnum.Early, AlumniStatusEnum.Mid,
  AlumniStatusEnum.Experienced
];
export const defaultAlumniSearchParamsModel = () => {
  return AlumniSearchParamsModel.fromJS({
    searchText: '',
    statusFlags: defaultAlumniStatusFilter,
    advisorUsers: [],
    primaryOnly: true,
    advisorSelected: false,
    excludeAdvisorSelected: false,
    noPrimaryAppointed: false,
    noAdvisorAppointed: false,
    byTeam: false,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    state: [],
    countryCode: 'AU',
    contactSearchText: '',
    includeInactive: false,
    usingBoth: false,
    usingEither: false,
    usingApp: false,
    usingPortal: false,
    signedNotPermission: false,
    permissionNotSigned: false,
    notSignedNotPermission: false,
    notArchived: true,
    includeArchived: false,
    onlyArchived: false,
    isNoContact: false,
    degreePoolId: [],
    wasWorkShadow: false,
    archivedReason: [],
    university: [],
    campus: [],
    degreeAndMajorSearchText: '',
    postGradUniversity: [],
    postGradCampus: [],
    postGradDegreeAndMajorSearchText: '',
  })
}

export class AlumniSearchParamsModel implements IAlumniSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  alumniTags?: string[] | undefined;
  degreePoolId?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  isNoContact?: boolean | undefined;
  usingBoth?: boolean | undefined;
  usingEither?: boolean | undefined;
  usingApp?: boolean | undefined;
  usingPortal?: boolean | undefined;
  signedNotPermission?: boolean | undefined;
  permissionNotSigned?: boolean | undefined;
  notSignedNotPermission?: boolean | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  graduatedYear?: string | undefined;
  graduatedSemester?: string | undefined;
  companyIds?: string[] | undefined;
  wasWorkShadow?: boolean | undefined;
  archivedReason?: string[] | undefined;
  yearStartFrom?: number | undefined;
  seasonStartFrom?: number | undefined;
  yearStartTo?: number | undefined;
  seasonStartTo?: number | undefined;
  yearEndFrom?: number | undefined;
  seasonEndFrom?: number | undefined;
  yearEndTo?: number | undefined;
  seasonEndTo?: number | undefined;
  postGradYearStartFrom?: number | undefined;
  postGradSeasonStartFrom?: number | undefined;
  postGradYearStartTo?: number | undefined;
  postGradSeasonStartTo?: number | undefined;
  postGradYearEndFrom?: number | undefined;
  postGradSeasonEndFrom?: number | undefined;
  postGradYearEndTo?: number | undefined;
  postGradSeasonEndTo?: number | undefined;
  university?: string[] | undefined;
  campus?: string[] | undefined;
  degreeAndMajorSearchText?: string | undefined;
  postGradUniversity?: string[] | undefined;
  postGradCampus?: string[] | undefined;
  postGradDegreeAndMajorSearchText?: string | undefined;


  constructor(data?: IAlumniSearchParamsModel) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data['searchText'];
      if (Array.isArray(Data['statusFlags'])) {
        this.statusFlags = [] as any;
        for (const item of Data['statusFlags'])
          this.statusFlags!.push(item);
      }
      this.joinedFrom = Data['joinedFrom'] ? new Date(Data['joinedFrom'].toString()) : <any>undefined;
      this.joinedTo = Data['joinedTo'] ? new Date(Data['joinedTo'].toString()) : <any>undefined;
      if (Array.isArray(Data['advisorUsers'])) {
        this.advisorUsers = [] as any;
        for (const item of Data['advisorUsers'])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data['primaryOnly'];
      this.advisorSelected = Data['advisorSelected'];
      this.excludePrimarySelected = Data['excludePrimarySelected'];
      this.noPrimaryAppointed = Data['noPrimaryAppointed'];
      this.noAdvisorAppointed = Data['noAdvisorAppointed'];
      this.byTeam = Data['byTeam'];
      this.advisorTeamId = Data['advisorTeamId'];
      this.advisorSubTeamId = Data['advisorSubTeamId'];
      this.byAdvisorTeam = Data['byAdvisorTeam'];
      this.byPrimaryAdvisorTeam = Data['byPrimaryAdvisorTeam'];
      if (Array.isArray(Data['alumniTags'])) {
        this.alumniTags = [] as any;
        for (const item of Data['alumniTags'])
          this.alumniTags!.push(item);
      }
      if (Array.isArray(Data['degreePoolId'])) {
        this.degreePoolId = [] as any;
        for (const item of Data['degreePoolId'])
          this.degreePoolId!.push(item);
      }
      if (Array.isArray(Data['state'])) {
        this.state = [] as any;
        for (const item of Data['state'])
          this.state!.push(item);
      }
      this.countryCode = Data['countryCode'];
      this.contactSearchText = Data['contactSearchText'];
      this.isNoContact = Data['isNoContact'];
      this.usingBoth = Data['usingBoth'];
      this.usingEither = Data['usingEither'];
      this.usingApp = Data['usingApp'];
      this.usingPortal = Data['usingPortal'];
      this.signedNotPermission = Data['signedNotPermission'];
      this.permissionNotSigned = Data['permissionNotSigned'];
      this.notSignedNotPermission = Data['notSignedNotPermission'];
      this.notArchived = Data['notArchived'];
      this.includeArchived = Data['includeArchived'];
      this.onlyArchived = Data['onlyArchived'];
      this.graduatedYear = Data['graduatedYear'];
      this.graduatedSemester = Data['graduatedSemester'];
      if (Array.isArray(Data['companyIds'])) {
        this.companyIds = [] as any;
        for (const item of Data['companyIds'])
          this.companyIds!.push(item);
      }
      this.wasWorkShadow = Data['wasWorkShadow'];
      if (Array.isArray(Data["archivedReason"])) {
        this.archivedReason = [] as any;
        for (let item of Data["archivedReason"])
          this.archivedReason!.push(item);
      }
      this.yearStartFrom = Data["yearStartFrom"];
      this.seasonStartFrom = Data["seasonStartFrom"];
      this.yearStartTo = Data["yearStartTo"];
      this.seasonStartTo = Data["seasonStartTo"];
      this.yearEndFrom = Data["yearEndFrom"];
      this.seasonEndFrom = Data["seasonEndFrom"];
      this.yearEndTo = Data["yearEndTo"];
      this.seasonEndTo = Data["seasonEndTo"];
      this.postGradYearStartFrom = Data["yearStartFrom"];
      this.postGradSeasonStartFrom = Data["seasonStartFrom"];
      this.postGradYearStartTo = Data["yearStartTo"];
      this.postGradSeasonStartTo = Data["seasonStartTo"];
      this.postGradYearEndFrom = Data["yearEndFrom"];
      this.postGradSeasonEndFrom = Data["seasonEndFrom"];
      this.postGradYearEndTo = Data["yearEndTo"];
      this.postGradSeasonEndTo = Data["seasonEndTo"];
      if (Array.isArray(Data["university"])) {
        this.university = [] as any;
        for (let item of Data["university"])
          this.university!.push(item);
      }
      if (Array.isArray(Data["campus"])) {
        this.campus = [] as any;
        for (let item of Data["campus"])
          this.campus!.push(item);
      }
      this.degreeAndMajorSearchText = Data["degreeAndMajorSearchText"];
      if (Array.isArray(Data["postGradUniversity"])) {
        this.postGradUniversity = [] as any;
        for (let item of Data["postGradUniversity"])
          this.postGradUniversity!.push(item);
      }
      if (Array.isArray(Data["postGradCampus"])) {
        this.postGradCampus = [] as any;
        for (let item of Data["postGradCampus"])
          this.postGradCampus!.push(item);
      }
      this.postGradDegreeAndMajorSearchText = Data["postGradDegreeAndMajorSearchText"];
    }
  }

  static fromJS(data: any): AlumniSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new AlumniSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['searchText'] = this.searchText;
    if (Array.isArray(this.statusFlags)) {
      data['statusFlags'] = [];
      for (const item of this.statusFlags)
        data['statusFlags'].push(item);
    }
    data['joinedFrom'] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
    data['joinedTo'] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data['advisorUsers'] = [];
      for (const item of this.advisorUsers)
        data['advisorUsers'].push(item);
    }
    data['primaryOnly'] = this.primaryOnly;
    data['advisorSelected'] = this.advisorSelected;
    data['excludePrimarySelected'] = this.excludePrimarySelected;
    data['noPrimaryAppointed'] = this.noPrimaryAppointed;
    data['noAdvisorAppointed'] = this.noAdvisorAppointed;
    data['byTeam'] = this.byTeam;
    data['advisorTeamId'] = this.advisorTeamId;
    data['advisorSubTeamId'] = this.advisorSubTeamId;
    data['byAdvisorTeam'] = this.byAdvisorTeam;
    data['byPrimaryAdvisorTeam'] = this.byPrimaryAdvisorTeam;
    if (Array.isArray(this.alumniTags)) {
      data['alumniTags'] = [];
      for (const item of this.alumniTags)
        data['alumniTags'].push(item);
    }
    if (Array.isArray(this.degreePoolId)) {
      data['degreePoolId'] = [];
      for (const item of this.degreePoolId)
        data['degreePoolId'].push(item);
    }
    if (Array.isArray(this.state)) {
      data['state'] = [];
      for (const item of this.state)
        data['state'].push(item);
    }
    data['countryCode'] = this.countryCode;
    data['contactSearchText'] = this.contactSearchText;
    data['isNoContact'] = this.isNoContact;
    data['usingBoth'] = this.usingBoth;
    data['usingEither'] = this.usingEither;
    data['usingApp'] = this.usingApp;
    data['usingPortal'] = this.usingPortal;
    data['signedNotPermission'] = this.signedNotPermission;
    data['permissionNotSigned'] = this.permissionNotSigned;
    data['notSignedNotPermission'] = this.notSignedNotPermission;
    data['notArchived'] = this.notArchived;
    data['includeArchived'] = this.includeArchived;
    data['onlyArchived'] = this.onlyArchived;
    data['graduatedYear'] = this.graduatedYear;
    data['graduatedSemester'] = this.graduatedSemester;
    if (Array.isArray(this.companyIds)) {
      data['companyIds'] = [];
      for (const item of this.companyIds)
        data['companyIds'].push(item);
    }
    data['wasWorkShadow'] = this.wasWorkShadow;
    if (Array.isArray(this.archivedReason)) {
      data["archivedReason"] = [];
      for (let item of this.archivedReason)
        data["archivedReason"].push(item);
    }
    data["yearStartFrom"] = this.yearStartFrom;
    data["seasonStartFrom"] = this.seasonStartFrom;
    data["yearStartTo"] = this.yearStartTo;
    data["seasonStartTo"] = this.seasonStartTo;
    data["yearEndFrom"] = this.yearEndFrom;
    data["seasonEndFrom"] = this.seasonEndFrom;
    data["yearEndTo"] = this.yearEndTo;
    data["seasonEndTo"] = this.seasonEndTo;
    data["postGradYearStartFrom"] = this.yearStartFrom;
    data["postGradSeasonStartFrom"] = this.seasonStartFrom;
    data["postGradYearStartTo"] = this.yearStartTo;
    data["postGradSeasonStartTo"] = this.seasonStartTo;
    data["postGradYearEndFrom"] = this.yearEndFrom;
    data["postGradSeasonEndFrom"] = this.seasonEndFrom;
    data["postGradYearEndTo"] = this.yearEndTo;
    data["postGradSeasonEndTo"] = this.seasonEndTo;
    if (Array.isArray(this.university)) {
      data["university"] = [];
      for (let item of this.university)
        data["university"].push(item);
    }
    if (Array.isArray(this.campus)) {
      data["campus"] = [];
      for (let item of this.campus)
        data["campus"].push(item);
    }
    data["degreeAndMajorSearchText"] = this.degreeAndMajorSearchText;
    if (Array.isArray(this.postGradUniversity)) {
      data["postGradUniversity"] = [];
      for (let item of this.postGradUniversity)
        data["postGradUniversity"].push(item);
    }
    if (Array.isArray(this.postGradCampus)) {
      data["postGradCampus"] = [];
      for (let item of this.postGradCampus)
        data["postGradCampus"].push(item);
    }
    data["postGradDegreeAndMajorSearchText"] = this.postGradDegreeAndMajorSearchText;
    return data;
  }
}

export interface IAlumniSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  alumniTags?: string[] | undefined;
  degreePoolId?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  isNoContact?: boolean | undefined;
  usingBoth?: boolean | undefined;
  usingEither?: boolean | undefined;
  usingApp?: boolean | undefined;
  usingPortal?: boolean | undefined;
  signedNotPermission?: boolean | undefined;
  permissionNotSigned?: boolean | undefined;
  notSignedNotPermission?: boolean | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  graduatedYear?: string | undefined;
  graduatedSemester?: string | undefined;
  companyIds?: string[] | undefined;
  wasWorkShadow?: boolean | undefined;
  archivedReason?: string[] | undefined;
  yearStartFrom?: number | undefined;
  seasonStartFrom?: number | undefined;
  yearStartTo?: number | undefined;
  seasonStartTo?: number | undefined;
  yearEndFrom?: number | undefined;
  seasonEndFrom?: number | undefined;
  yearEndTo?: number | undefined;
  seasonEndTo?: number | undefined;
  postGradYearStartFrom?: number | undefined;
  postGradSeasonStartFrom?: number | undefined;
  postGradYearStartTo?: number | undefined;
  postGradSeasonStartTo?: number | undefined;
  postGradYearEndFrom?: number | undefined;
  postGradSeasonEndFrom?: number | undefined;
  postGradYearEndTo?: number | undefined;
  postGradSeasonEndTo?: number | undefined;
  university?: string[] | undefined;
  campus?: string[] | undefined;
  degreeAndMajorSearchText?: string | undefined;
  postGradUniversity?: string[] | undefined;
  postGradCampus?: string[] | undefined;
  postGradDegreeAndMajorSearchText?: string | undefined;
}