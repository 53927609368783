import {ICommonSaveActionType, ICommonSavePayload, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {PersonModel} from '../../types/models/PersonModel';
import {AlumniEditModel} from '../../types/requests/AlumniEditModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IAlumniSearchParamsModel} from '../../types/requests/AlumniSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {IEngagementPersonModel} from '../../types/views/EngagementPersonModel';
import {PersonSearchResultModel} from '../../types/views/PersonSearchResultModel';

export interface IAlumniActionTypes extends ICommonSaveActionType {
  REFRESH: string;
}

export const ALUMNI: IAlumniActionTypes = {
  LOAD: 'alumni/load',
  LOAD_ERROR: 'alumni/loadError',
  LOADED: 'alumni/loadSuccess',
  REFRESH: 'alumni/refresh',
  SAVE: 'alumni/save',
  SAVED: 'alumni/saveSuccess',
  SAVE_ERROR: 'alumni/saveError',
};

export const ALUMNI_SEARCH: ICommonSearchActionType = {
  ERROR: 'alumniSearch/error',
  LOAD: 'alumniSearch/load',
  LOADED: 'alumniSearch/loaded',
  REFRESH: 'alumniSearch/refresh',
  CHANGE_VIEW: 'alumniSearch/changeView',
};

export const alumniLoad = createAction<string>(ALUMNI.LOAD);
export const alumniRefresh = createAction<string>(ALUMNI.REFRESH);
export const alumniLoadSuccess = createAction<PersonModel>(ALUMNI.LOADED);
export const alumniLoadError = createAction<string>(ALUMNI.LOAD_ERROR);

export const alumniSave = createAction<ICommonSavePayload<AlumniEditModel>>(ALUMNI.SAVE);
export const alumniSaveSuccess = createAction<PersonModel>(ALUMNI.SAVED);
export const alumniSaveError = createAction<string>(ALUMNI.SAVE_ERROR);

export const alumniSearch = createAction<ISearchRequest<IAlumniSearchParamsModel>>(ALUMNI_SEARCH.LOAD);
export const alumniSearchView = createAction<string>(ALUMNI_SEARCH.CHANGE_VIEW);
export const alumniSearchRefresh = createAction<ISearchRequest<IAlumniSearchParamsModel>>(ALUMNI_SEARCH.REFRESH);
export const alumniSearchError = createAction<string>(ALUMNI_SEARCH.ERROR);
export const alumniSearchSuccess = createAction<IPagedResult<PersonSearchResultModel>>(ALUMNI_SEARCH.LOADED);