import {alphaRE} from './regexPatterns';

const color1Letters = /[AFKPUZ]$/;
const color2Letters = /[BGLQV]$/;
const color3Letters = /[CHMRW]$/;
const color4Letters = /[DINSX]$/;
const color5Letters = /[EJOTY]$/;

const color1 = '#740070';
const color2 = '#740808';
const color3 = '#dc3951';
const color4 = '#bb3d00';
const color5 = '#af8300';

const compColor1 = '#0e2871';
const compColor2 = '#003c63';
const compColor3 = '#005020';
const compColor4 = '#356815';
const compColor5 = '#477100';

const selectCompanyColor = (initial: string) => {
  let firstLetter = 'A';
  if (initial && initial.match(alphaRE)) {
    firstLetter = initial.toUpperCase().substr(0, 1);
  }
  if (color1Letters.test(firstLetter)) return compColor1;
  if (color2Letters.test(firstLetter)) return compColor2;
  if (color3Letters.test(firstLetter)) return compColor3;
  if (color4Letters.test(firstLetter)) return compColor4;
  if (color5Letters.test(firstLetter)) return compColor5;
};

const selectPersonColor = (initial: string) => {
  let firstLetter = 'A';
  if (initial && initial.match(alphaRE)) {
    firstLetter = initial.toUpperCase().substr(0, 1);
  }
  if (color1Letters.test(firstLetter)) return color1;
  if (color2Letters.test(firstLetter)) return color2;
  if (color3Letters.test(firstLetter)) return color3;
  if (color4Letters.test(firstLetter)) return color4;
  if (color5Letters.test(firstLetter)) return color5;
};

export {
  selectCompanyColor,
  selectPersonColor
};