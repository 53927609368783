import {AppState} from '../store';
import {hasItems} from '../../@template/helpers/arrays';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IPlacementsCacheState} from './Reducer';
import {PLACEMENT_CACHE, placementCacheError, placementCacheMerge, placementCacheUpdate} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {PlacementListItem} from '../../types/views/PlacementListItem';
import {PlacementIdList} from '../../types/requests/PlacementIdList';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const placementsCacheState = (state: AppState) => state.placementsCache;

export function* LoadPlacementCacheItems(idList: string[]) {
  console.log('SAGA - placementCacheSaga - LoadPlacementCacheItems', idList);
  try {
    if (hasItems(idList)) {
      let submissionData = new PlacementIdList();
      submissionData.idList = idList;
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.corePlacementsPath}/list`,
        data: submissionData
      });
      const placements: PlacementListItem[] = res.data.map((x: any) => PlacementListItem.fromJS(x));
      yield put(placementCacheMerge(placements));
    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(placementCacheError(err));
  }
}

export function* AddPlacementCacheItems() {
  console.log('SAGA - placementCacheSaga - AddPlacementCacheItems');
  yield call(delay, 200);
  const currentPlacementCache: IPlacementsCacheState = yield select(placementsCacheState);
  const currentPendingList = currentPlacementCache.placementPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    console.log('SAGA - peopleCacheSaga - AddPlacementCacheItems Load', currentPendingList);
    yield put(placementCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadPlacementCacheItems(){
  yield takeEvery(PLACEMENT_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadPlacementCacheItems(payload));
}

export function* watchAddPlacementCacheItems(){
  yield takeEvery(PLACEMENT_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddPlacementCacheItems());
}

export default function* placementCacheSaga() {
  yield all([
    call(watchLoadPlacementCacheItems),
    call(watchAddPlacementCacheItems),
  ])
}
