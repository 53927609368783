import {StateModel} from './StateModel';

export class CountryModel implements ICountryModel {
  countryCode?: string | undefined;
  name?: string | undefined;
  states?: StateModel[] | undefined;

  constructor(data?: ICountryModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.countryCode = Data["countryCode"];
      this.name = Data["name"];
      if (Array.isArray(Data["states"])) {
        this.states = [] as any;
        for (let item of Data["states"])
          this.states!.push(StateModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): CountryModel {
    data = typeof data === 'object' ? data : {};
    let result = new CountryModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["countryCode"] = this.countryCode;
    data["name"] = this.name;
    if (Array.isArray(this.states)) {
      data["states"] = [];
      for (let item of this.states)
        data["states"].push(item.toJSON());
    }
    return data;
  }
}

export interface ICountryModel {
  countryCode?: string | undefined;
  name?: string | undefined;
  states?: StateModel[] | undefined;
}
