import React from 'react';
import {AppRouteGroupProps, AppRouteProps} from '../../types/AppRoutesPropType';
import {PermissionLevel} from '../../types/common/Permissions';

const errorRoutes = [
  new AppRouteProps('Error 505', '/error-pages/error-500', React.lazy(() => import('./Error500')), [], PermissionLevel.NONE),
  new AppRouteProps('Error 404', '/error-pages/error-404', React.lazy(() => import('./Error404')), [], PermissionLevel.NONE),
  new AppRouteProps('Maintenance', '/error-pages/maintenance', React.lazy(() => import('./Maintenance')), [], PermissionLevel.NONE),
]

const errorPagesConfig = [new AppRouteGroupProps(PermissionLevel.NONE, errorRoutes)];
export default errorPagesConfig;

