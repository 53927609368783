import { PartnerSearchModel } from "./PartnerSearchModel";
import {ClientSearchModel} from './ClientSearchModel';
import { AddressViewModel } from "types/models/AddressViewModel";
import {EducationSearchModel} from './EducationSearchModel';

export class CompanySearchModel implements ICompanySearchModel {
  id?: string | undefined;
  abn?: string | undefined;
  acn?: string | undefined;
  companyNumber?: number | undefined;
  locationAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  phoneNumbers?: string[] | undefined;
  isActive?: boolean | undefined;
  isPartner?: boolean | undefined;
  isClient?: boolean | undefined;
  isEducation?: boolean | undefined;
  clientDetail?: ClientSearchModel | undefined;
  educationDetail?: EducationSearchModel | undefined;
  partnerDetail?: PartnerSearchModel | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;
  brandId?: string | undefined;
  companyBrandName?: string | undefined;
  groupName?: string | undefined;
  website?: string | undefined;
  linkedinUrl?: string | undefined;
  shortSummary?: string | undefined;
  logoImageId?: string | undefined;

  constructor(data?: ICompanySearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.abn = Data["abn"];
      this.acn = Data["acn"];
      this.companyNumber = Data["companyNumber"];
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.quickNotes = Data["quickNotes"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(item);
      }
      this.isActive = Data["isActive"];
      this.isPartner = Data["isPartner"];
      this.isClient = Data["isClient"];
      this.isEducation = Data["isEducation"];
      this.clientDetail = Data["clientDetail"] ? ClientSearchModel.fromJS(Data["clientDetail"]) : <any>undefined;
      this.educationDetail = Data["educationDetail"] ? EducationSearchModel.fromJS(Data["educationDetail"]) : <any>undefined;
      this.partnerDetail = Data["partnerDetail"] ? PartnerSearchModel.fromJS(Data["partnerDetail"]) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
      this.primaryAdvisorFullName = Data["primaryAdvisorFullName"];
      this.brandId = Data["brandId"];
      this.companyBrandName = Data["companyBrandName"];
      this.groupName = Data["groupName"];
      this.website = Data["website"];
      this.linkedinUrl = Data["linkedinUrl"];
      this.shortSummary = Data["shortSummary"];
      this.logoImageId = Data["logoImageId"];
    }
  }

  static fromJS(data: any): CompanySearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new CompanySearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["abn"] = this.abn;
    data["acn"] = this.acn;
    data["companyNumber"] = this.companyNumber;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["quickNotes"] = this.quickNotes;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item);
    }
    data["isActive"] = this.isActive;
    data["isPartner"] = this.isPartner;
    data["isClient"] = this.isClient;
    data["isEducation"] = this.isEducation;
    data["clientDetail"] = this.clientDetail ? this.clientDetail.toJSON() : <any>undefined;
    data["educationDetail"] = this.educationDetail ? this.educationDetail.toJSON() : <any>undefined;
    data["partnerDetail"] = this.partnerDetail ? this.partnerDetail.toJSON() : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
    data["primaryAdvisorFullName"] = this.primaryAdvisorFullName;
    data["brandId"] = this.brandId;
    data["companyBrandName"] = this.companyBrandName;
    data["groupName"] = this.groupName;
    data["website"] = this.website;
    data["linkedinUrl"] = this.linkedinUrl;
    data["shortSummary"] = this.shortSummary;
    data["logoImageId"] = this.logoImageId;
    return data;
  }
}

export interface ICompanySearchModel {
  id?: string | undefined;
  abn?: string | undefined;
  acn?: string | undefined;
  companyNumber?: number | undefined;
  locationAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  phoneNumbers?: string[] | undefined;
  isActive?: boolean | undefined;
  isPartner?: boolean | undefined;
  isClient?: boolean | undefined;
  isEducation?: boolean | undefined;
  clientDetail?: ClientSearchModel | undefined;
  educationDetail?: EducationSearchModel | undefined;
  partnerDetail?: PartnerSearchModel | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;
  brandId?: string | undefined;
  companyBrandName?: string | undefined;
  groupName?: string | undefined;
  website?: string | undefined;
  linkedinUrl?: string | undefined;
  shortSummary?: string | undefined;
  logoImageId?: string | undefined;
}