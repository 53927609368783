import React from 'react';
import { generatePath } from 'react-router';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import {ListItem} from '@mui/material';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {HistoryRetrievedModel} from '../../../types/models/HistoryRetrievedModel';
import PersonAvatar from '../../people/PersonAvatar';
import CompanyAvatar from '../../companies/CompanyAvatar';
import {companyPaths} from '../../companies/paths';
import {studentPaths} from '../../students/paths';
import {contactPaths} from '../../contacts/paths';
import {workShadowPaths} from '../../workShadow/paths';
import {volunteerPaths} from '../../volunteers/paths';
import {AppNavLink} from '../../../@template';

const useStyles = makeStyles(() => ({
  textBase: {
    fontSize: 14,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    padding: '8px 10px',
  },
  titleLink: {
    textDecoration: 'none',
  },
}));

interface HistoryItemProps {
  item: HistoryRetrievedModel;
}

const HistoryItem: React.FC<HistoryItemProps> = ({item}) => {
  const classes = useStyles();

  // TODO Temporary to remove pools from this menu
  if(item.pageType == 'pool') return <></>;

  const getLink = () => {
    switch (item.pageType) {
      case ('student'):
        return generatePath(studentPaths.StudentProfile, { id: item.entityId });
      case ('volunteer'):
        return generatePath(volunteerPaths.VolunteerProfile, { id: item.entityId });
      case ('contact'):
        return generatePath(contactPaths.ContactProfile, { id: item.entityId });
      case ('company'):
        return generatePath(companyPaths.CompanyProfile, { id: item.entityId });
      default:
        return '';
    }
  }

  const renderHistoryIcon = () => {
    switch (item.pageType) {
      case ('student'):
      case ('volunteer'):
      case ('contact'):
      case ('workShadow'):
        return <PersonAvatar personId={item.entityId?? ''} size={36}/>;
      case ('company'):
        return <CompanyAvatar companyId={item.entityId?? ''} size={36}/>;
      default:
        return '';
    }
  }

  const renderType = () => {
    switch (item.pageType) {
      case ('student'):
        return 'Student';
      case ('volunteer'):
        return 'Volunteer';
      case ('contact'):
        return 'Contact';
      case ('workShadow'):
        return 'Work Shadow';
      case ('company'):
        return 'Company';
      default:
        return '';
    }
  }

  return (
    <ListItem
      className={clsx(classes.listItemRoot, 'item-hover')}
      component={AppNavLink}
      to={getLink()}
    >
      <Box mr={4}>
        {renderHistoryIcon()}
      </Box>
      <Box>
        <Box
          mr={2}
          component='p'
          color='text.primary'
          fontWeight={Fonts.MEDIUM}
        >
          <strong>{item.title} ({renderType()})</strong>
        </Box>
        <Box component='p' className={classes.textBase} color='text.secondary'>
          {item.subTitle}
        </Box>
      </Box>
    </ListItem>
  );
};

export default HistoryItem;