import {formatDate} from '../../@template/helpers/datetimes';
import {StudentOnboardingModel} from './StudentOnboardingModel';
import {StudentOnboardingHistoryModel} from './StudentOnboardingHistoryModel';
import {StudentGraduatingModel} from './StudentGraduatingModel';
import {StudentGraduatingHistoryModel} from './StudentGraduatingHistoryModel';
import {StudentStatusEnum} from './Enums';
import {StudentTagModel} from './StudentTagModel';
import {PhoneModel} from './PhoneModel';
import {AddressViewModel} from './AddressViewModel';
import {SeasonalActionModel} from './SeasonalActionModel';
import {EmployerExperienceModel, HonourAndAwardsModel} from '../requests/StudentProfileEditModel';
import {StudentCareerQuestionnaireModel} from './StudentCareerQuestionnaireModel';
import {SeasonalStudentModel} from './SeasonalStudentModel';

export class StudentDetailModel implements IStudentDetailModel {
  onboardingStudentSettingId?: string | undefined;
  studentOnboardingSetting?: StudentOnboardingModel | undefined;
  studentOnboardingHistory?: StudentOnboardingHistoryModel[] | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  graduatingStudentSettingId?: string | undefined;
  studentGraduatingSetting?: StudentGraduatingModel | undefined;
  studentGraduatingHistory?: StudentGraduatingHistoryModel[] | undefined;
  graduatingStepsComplete?: number | undefined;
  graduatingPercent?: number | undefined;
  dateInactivated?: Date | undefined;
  inactivatedPersonId?: string | undefined;
  inactivatedNotes?: string | undefined;
  inactivatedReason?: string | undefined;
  studentStatus?: StudentStatusEnum | undefined;
  dateIcsSet?: Date | undefined;
  icsByPersonId?: string | undefined;
  icsStatus?: string | undefined;
  icsId?: string | undefined;
  icsStart?: Date | undefined;
  icsEnd?: Date | undefined;
  icsNotes?: string | undefined;
  studentTags?: StudentTagModel[] | undefined;
  studentSource?: string | undefined;
  semesterNumberStart?: number | undefined;
  semesterYearStart?: number | undefined;
  semesterNumberEnd?: number | undefined;
  semesterYearEnd?: number | undefined;
  locationAddress?: AddressViewModel | undefined;
  emergencyFirstName?: string | undefined;
  emergencyLastName?: string | undefined;
  emergencyPhoneNumbers?: PhoneModel[] | undefined;
  emergencyAddress?: AddressViewModel | undefined;
  emergencyRelationship?: string | undefined;
  emergencyEmail?: string | undefined;
  university?: string | undefined;
  campus?: string | undefined;
  degreePoolId?: string[] | undefined;
  degree?: string | undefined;
  degreeMajor?: string | undefined;
  highSchool?: string | undefined;
  highSchoolComplete?: number | undefined;
  /** Travel arrangements will be required for this student */
  isTravelRequired?: boolean | undefined;
  dateAlumniSet?: Date | undefined;
  alumniSetByPersonId?: string | undefined;
  /** Company worked at */
  alumniCompany?: string | undefined;
  /** Current job title */
  alumniJobTitle?: string | undefined;
  /** City now working */
  alumniSuburb?: string | undefined;
  /** State now working */
  alumniState?: string | undefined;
  /** Country (code only) now working */
  alumniCountryCode?: string | undefined;
  alumniNotes?: string | undefined;
  isAtRisk?: boolean | undefined;
  dateAtRiskSet?: Date | undefined;
  atRiskByPersonId?: string | undefined;
  atRiskContext?: string | undefined;
  atRiskReasons?: string[] | undefined;
  atRiskSemester?: number | undefined;
  atRiskYear?: number | undefined;
  atRiskNotes?: string | undefined;
  quickNotes?: string | undefined;
  cvUrl?: string | undefined;
  publicSummary?: string | undefined;
  publicProfile?: string | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  profileLastUpdated?: Date | undefined;
  profileUpdatedByUserId?: string | undefined;
  isGraduating?: boolean | undefined;
  graduatingByPersonId?: string | undefined;
  graduatingNotes?: string | undefined;
  isPreUniversity?: boolean | undefined;
  datePreUniversity?: Date | undefined;
  preUniversityByPersonId?: string | undefined;
  isUniversityProgram?: boolean | undefined;
  dateUniversityProgram?: Date | undefined;
  universityProgramByPersonId?: string | undefined;
  interviewAvailability?: string | undefined;
  interviewToolkitUrl?: string | undefined;
  seasonalActions?: SeasonalActionModel[] | undefined;
  beforeMatchedIsTravelling?: boolean | undefined;
  beforeMatchedTravelLocations?: AddressViewModel[] | undefined;
  careerQuestionnaire?: StudentCareerQuestionnaireModel | undefined;
  seasonalStudent?: SeasonalStudentModel[] | undefined;

  constructor(data?: IStudentDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.onboardingStudentSettingId = Data["onboardingStudentSettingId"];
      this.studentOnboardingSetting = Data["studentOnboardingSetting"] ? StudentOnboardingModel.fromJS(Data["studentOnboardingSetting"]) : <any>undefined;
      if (Array.isArray(Data["studentOnboardingHistory"])) {
        this.studentOnboardingHistory = [] as any;
        for (let item of Data["studentOnboardingHistory"])
          this.studentOnboardingHistory!.push(StudentOnboardingHistoryModel.fromJS(item));
      }
      this.onboardingStepsComplete = Data["onboardingStepsComplete"];
      this.onboardingPercent = Data["onboardingPercent"];
      this.graduatingStudentSettingId = Data["graduatingStudentSettingId"];
      this.studentGraduatingSetting = Data["studentGraduatingSetting"] ? StudentGraduatingModel.fromJS(Data["studentGraduatingSetting"]) : <any>undefined;
      if (Array.isArray(Data["studentGraduatingHistory"])) {
        this.studentGraduatingHistory = [] as any;
        for (let item of Data["studentGraduatingHistory"])
          this.studentGraduatingHistory!.push(StudentGraduatingHistoryModel.fromJS(item));
      }
      this.graduatingStepsComplete = Data["graduatingStepsComplete"];
      this.graduatingPercent = Data["graduatingPercent"];
      this.dateInactivated = Data["dateInactivated"] ? new Date(Data["dateInactivated"].toString()) : <any>undefined;
      this.inactivatedPersonId = Data["inactivatedPersonId"];
      this.inactivatedNotes = Data["inactivatedNotes"];
      this.inactivatedReason = Data["inactivatedReason"];
      this.studentStatus = Data["studentStatus"];
      this.dateIcsSet = Data["dateIcsSet"] ? new Date(Data["dateIcsSet"].toString()) : <any>undefined;
      this.icsByPersonId = Data["icsByPersonId"];
      this.icsStatus = Data["icsStatus"];
      this.icsId = Data["icsId"];
      this.icsStart = Data["icsStart"] ? new Date(Data["icsStart"].toString()) : <any>undefined;
      this.icsEnd = Data["icsEnd"] ? new Date(Data["icsEnd"].toString()) : <any>undefined;
      this.icsNotes = Data["icsNotes"];
      if (Array.isArray(Data["studentTags"])) {
        this.studentTags = [] as any;
        for (let item of Data["studentTags"])
          this.studentTags!.push(StudentTagModel.fromJS(item));
      }
      this.studentSource = Data["studentSource"];
      this.semesterNumberStart = Data["semesterNumberStart"];
      this.semesterYearStart = Data["semesterYearStart"];
      this.semesterNumberEnd = Data["semesterNumberEnd"];
      this.semesterYearEnd = Data["semesterYearEnd"];
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.emergencyFirstName = Data["emergencyFirstName"];
      this.emergencyLastName = Data["emergencyLastName"];
      if (Array.isArray(Data["emergencyPhoneNumbers"])) {
        this.emergencyPhoneNumbers = [] as any;
        for (let item of Data["emergencyPhoneNumbers"])
          this.emergencyPhoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.emergencyAddress = Data["emergencyAddress"] ? AddressViewModel.fromJS(Data["emergencyAddress"]) : <any>undefined;
      this.emergencyRelationship = Data["emergencyRelationship"];
      this.emergencyEmail = Data["emergencyEmail"];
      this.university = Data["university"];
      this.campus = Data["campus"];
      if (Array.isArray(Data["degreePoolId"])) {
        this.degreePoolId = [] as any;
        for (let item of Data["degreePoolId"])
          this.degreePoolId!.push(item);
      }
      this.degree = Data["degree"];
      this.degreeMajor = Data["degreeMajor"];
      this.highSchool = Data["highSchool"];
      this.highSchoolComplete = Data["highSchoolComplete"];
      this.isTravelRequired = Data["isTravelRequired"];
      this.dateAlumniSet = Data["dateAlumniSet"] ? new Date(Data["dateAlumniSet"].toString()) : <any>undefined;
      this.alumniSetByPersonId = Data["alumniSetByPersonId"];
      this.alumniCompany = Data["alumniCompany"];
      this.alumniJobTitle = Data["alumniJobTitle"];
      this.alumniSuburb = Data["alumniSuburb"];
      this.alumniState = Data["alumniState"];
      this.alumniCountryCode = Data["alumniCountryCode"];
      this.alumniNotes = Data["alumniNotes"];
      this.isAtRisk = Data["isAtRisk"];
      this.dateAtRiskSet = Data["dateAtRiskSet"] ? new Date(Data["dateAtRiskSet"].toString()) : <any>undefined;
      this.atRiskByPersonId = Data["atRiskByPersonId"];
      this.atRiskContext = Data["atRiskContext"];
      if (Array.isArray(Data["atRiskReasons"])) {
        this.atRiskReasons = [] as any;
        for (let item of Data["atRiskReasons"])
          this.atRiskReasons!.push(item);
      }
      this.atRiskSemester = Data["atRiskSemester"];
      this.atRiskYear = Data["atRiskYear"];
      this.atRiskNotes = Data["atRiskNotes"];
      this.quickNotes = Data["quickNotes"];
      this.cvUrl = Data["cvUrl"];
      this.publicSummary = Data["publicSummary"];
      this.publicProfile = Data["publicProfile"];
      if (Array.isArray(Data["employerExperiences"])) {
        this.employerExperiences = [] as any;
        for (let item of Data["employerExperiences"])
          this.employerExperiences!.push(EmployerExperienceModel.fromJS(item));
      }
      if (Array.isArray(Data["employerHonourAwards"])) {
        this.employerHonourAwards = [] as any;
        for (let item of Data["employerHonourAwards"])
          this.employerHonourAwards!.push(HonourAndAwardsModel.fromJS(item));
      }
      this.profileLastUpdated = Data["profileLastUpdated"] ? new Date(Data["profileLastUpdated"].toString()) : <any>undefined;
      this.profileUpdatedByUserId = Data["profileUpdatedByUserId"];
      this.isGraduating = Data["isGraduating"];
      this.graduatingByPersonId = Data["graduatingByPersonId"];
      this.graduatingNotes = Data["graduatingNotes"];
      this.isPreUniversity = Data["isPreUniversity"];
      this.datePreUniversity = Data["datePreUniversity"] ? new Date(Data["datePreUniversity"].toString()) : <any>undefined;
      this.preUniversityByPersonId = Data["preUniversityByPersonId"];
      this.isUniversityProgram = Data["isUniversityProgram"];
      this.dateUniversityProgram = Data["dateUniversityProgram"] ? new Date(Data["dateUniversityProgram"].toString()) : <any>undefined;
      this.universityProgramByPersonId = Data["universityProgramByPersonId"];
      this.interviewAvailability = Data["interviewAvailability"];
      this.interviewToolkitUrl = Data["interviewToolkitUrl"];
      if (Array.isArray(Data["seasonalActions"])) {
        this.seasonalActions = [] as any;
        for (let item of Data["seasonalActions"])
          this.seasonalActions!.push(SeasonalActionModel.fromJS(item));
      }
      this.beforeMatchedIsTravelling = Data["beforeMatchedIsTravelling"];
      if (Array.isArray(Data["beforeMatchedTravelLocations"])) {
        this.beforeMatchedTravelLocations = [] as any;
        for (let item of Data["beforeMatchedTravelLocations"])
          this.beforeMatchedTravelLocations!.push(AddressViewModel.fromJS(item));
      }
      this.careerQuestionnaire = Data["careerQuestionnaire"] ? StudentCareerQuestionnaireModel.fromJS(Data["careerQuestionnaire"]) : <any>undefined;
      if (Array.isArray(Data["seasonalStudent"])) {
        this.seasonalStudent = [] as any;
        for (let item of Data["seasonalStudent"])
          this.seasonalStudent!.push(SeasonalStudentModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): StudentDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["onboardingStudentSettingId"] = this.onboardingStudentSettingId;
    data["studentOnboardingSetting"] = this.studentOnboardingSetting ? this.studentOnboardingSetting.toJSON() : <any>undefined;
    if (Array.isArray(this.studentOnboardingHistory)) {
      data["studentOnboardingHistory"] = [];
      for (let item of this.studentOnboardingHistory)
        data["studentOnboardingHistory"].push(item.toJSON());
    }
    data["onboardingStepsComplete"] = this.onboardingStepsComplete;
    data["onboardingPercent"] = this.onboardingPercent;
    data["graduatingStudentSettingId"] = this.graduatingStudentSettingId;
    data["studentGraduatingSetting"] = this.studentGraduatingSetting ? this.studentGraduatingSetting.toJSON() : <any>undefined;
    if (Array.isArray(this.studentGraduatingHistory)) {
      data["studentGraduatingHistory"] = [];
      for (let item of this.studentGraduatingHistory)
        data["studentGraduatingHistory"].push(item.toJSON());
    }
    data["graduatingStepsComplete"] = this.graduatingStepsComplete;
    data["graduatingPercent"] = this.graduatingPercent;
    data["dateInactivated"] = this.dateInactivated ? this.dateInactivated.toISOString() : <any>undefined;
    data["inactivatedPersonId"] = this.inactivatedPersonId;
    data["inactivatedNotes"] = this.inactivatedNotes;
    data["inactivatedReason"] = this.inactivatedReason;
    data["studentStatus"] = this.studentStatus;
    data["dateIcsSet"] = this.dateIcsSet ? this.dateIcsSet.toISOString() : <any>undefined;
    data["icsByPersonId"] = this.icsByPersonId;
    data["icsStatus"] = this.icsStatus;
    data["icsId"] = this.icsId;
    data["icsStart"] = this.icsStart ? formatDate(this.icsStart) : <any>undefined;
    data["icsEnd"] = this.icsEnd ? formatDate(this.icsEnd) : <any>undefined;
    data["icsNotes"] = this.icsNotes;
    if (Array.isArray(this.studentTags)) {
      data["studentTags"] = [];
      for (let item of this.studentTags)
        data["studentTags"].push(item.toJSON());
    }
    data["studentSource"] = this.studentSource;
    data["semesterNumberStart"] = this.semesterNumberStart;
    data["semesterYearStart"] = this.semesterYearStart;
    data["semesterNumberEnd"] = this.semesterNumberEnd;
    data["semesterYearEnd"] = this.semesterYearEnd;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["emergencyFirstName"] = this.emergencyFirstName;
    data["emergencyLastName"] = this.emergencyLastName;
    if (Array.isArray(this.emergencyPhoneNumbers)) {
      data["emergencyPhoneNumbers"] = [];
      for (let item of this.emergencyPhoneNumbers)
        data["emergencyPhoneNumbers"].push(item.toJSON());
    }
    data["emergencyAddress"] = this.emergencyAddress ? this.emergencyAddress.toJSON() : <any>undefined;
    data["emergencyRelationship"] = this.emergencyRelationship;
    data["emergencyEmail"] = this.emergencyEmail;
    data["university"] = this.university;
    data["campus"] = this.campus;
    if (Array.isArray(this.degreePoolId)) {
      data["degreePoolId"] = [];
      for (let item of this.degreePoolId)
        data["degreePoolId"].push(item);
    }
    data["degree"] = this.degree;
    data["degreeMajor"] = this.degreeMajor;
    data["highSchool"] = this.highSchool;
    data["highSchoolComplete"] = this.highSchoolComplete;
    data["isTravelRequired"] = this.isTravelRequired;
    data["dateAlumniSet"] = this.dateAlumniSet ? this.dateAlumniSet.toISOString() : <any>undefined;
    data["alumniSetByPersonId"] = this.alumniSetByPersonId;
    data["alumniCompany"] = this.alumniCompany;
    data["alumniJobTitle"] = this.alumniJobTitle;
    data["alumniSuburb"] = this.alumniSuburb;
    data["alumniState"] = this.alumniState;
    data["alumniCountryCode"] = this.alumniCountryCode;
    data["alumniNotes"] = this.alumniNotes;
    data["isAtRisk"] = this.isAtRisk;
    data["dateAtRiskSet"] = this.dateAtRiskSet ? this.dateAtRiskSet.toISOString() : <any>undefined;
    data["atRiskByPersonId"] = this.atRiskByPersonId;
    data["atRiskContext"] = this.atRiskContext;
    if (Array.isArray(this.atRiskReasons)) {
      data["atRiskReasons"] = [];
      for (let item of this.atRiskReasons)
        data["atRiskReasons"].push(item);
    }
    data["atRiskSemester"] = this.atRiskSemester;
    data["atRiskYear"] = this.atRiskYear;
    data["atRiskNotes"] = this.atRiskNotes;
    data["quickNotes"] = this.quickNotes;
    data["cvUrl"] = this.cvUrl;
    data["publicSummary"] = this.publicSummary;
    data["publicProfile"] = this.publicProfile;
    if (Array.isArray(this.employerExperiences)) {
      data["employerExperiences"] = [];
      for (let item of this.employerExperiences)
        data["employerExperiences"].push(item.toJSON());
    }
    if (Array.isArray(this.employerHonourAwards)) {
      data["employerHonourAwards"] = [];
      for (let item of this.employerHonourAwards)
        data["employerHonourAwards"].push(item.toJSON());
    }
    data["profileLastUpdated"] = this.profileLastUpdated ? this.profileLastUpdated.toISOString() : <any>undefined;
    data["profileUpdatedByUserId"] = this.profileUpdatedByUserId;
    data["isGraduating"] = this.isGraduating;
    data["graduatingByPersonId"] = this.graduatingByPersonId;
    data["graduatingNotes"] = this.graduatingNotes;
    data["isPreUniversity"] = this.isPreUniversity;
    data["datePreUniversity"] = this.datePreUniversity ? this.datePreUniversity.toISOString() : <any>undefined;
    data["preUniversityByPersonId"] = this.preUniversityByPersonId;
    data["isUniversityProgram"] = this.isUniversityProgram;
    data["dateUniversityProgram"] = this.dateUniversityProgram ? this.dateUniversityProgram.toISOString() : <any>undefined;
    data["universityProgramByPersonId"] = this.universityProgramByPersonId;
    data["interviewAvailability"] = this.interviewAvailability;
    data["interviewToolkitUrl"] = this.interviewToolkitUrl;
    if (Array.isArray(this.seasonalActions)) {
      data["seasonalActions"] = [];
      for (let item of this.seasonalActions)
        data["seasonalActions"].push(item.toJSON());
    }
    data["beforeMatchedIsTravelling"] = this.beforeMatchedIsTravelling;
    if (Array.isArray(this.beforeMatchedTravelLocations)) {
      data["beforeMatchedTravelLocations"] = [];
      for (let item of this.beforeMatchedTravelLocations)
        data["beforeMatchedTravelLocations"].push(item.toJSON());
    }
    data["careerQuestionnaire"] = this.careerQuestionnaire ? this.careerQuestionnaire.toJSON() : <any>undefined;
    if (Array.isArray(this.seasonalStudent)) {
      data["seasonalStudent"] = [];
      for (let item of this.seasonalStudent)
        data["seasonalStudent"].push(item.toJSON());
    }
    return data;
  }
}

export interface IStudentDetailModel {
  onboardingStudentSettingId?: string | undefined;
  studentOnboardingSetting?: StudentOnboardingModel | undefined;
  studentOnboardingHistory?: StudentOnboardingHistoryModel[] | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  graduatingStudentSettingId?: string | undefined;
  studentGraduatingSetting?: StudentGraduatingModel | undefined;
  studentGraduatingHistory?: StudentGraduatingHistoryModel[] | undefined;
  graduatingStepsComplete?: number | undefined;
  graduatingPercent?: number | undefined;
  dateInactivated?: Date | undefined;
  inactivatedPersonId?: string | undefined;
  inactivatedNotes?: string | undefined;
  inactivatedReason?: string | undefined;
  studentStatus?: StudentStatusEnum | undefined;
  dateIcsSet?: Date | undefined;
  icsByPersonId?: string | undefined;
  icsStatus?: string | undefined;
  icsId?: string | undefined;
  icsStart?: Date | undefined;
  icsEnd?: Date | undefined;
  icsNotes?: string | undefined;
  studentTags?: StudentTagModel[] | undefined;
  studentSource?: string | undefined;
  semesterNumberStart?: number | undefined;
  semesterYearStart?: number | undefined;
  semesterNumberEnd?: number | undefined;
  semesterYearEnd?: number | undefined;
  locationAddress?: AddressViewModel | undefined;
  emergencyFirstName?: string | undefined;
  emergencyLastName?: string | undefined;
  emergencyPhoneNumbers?: PhoneModel[] | undefined;
  emergencyAddress?: AddressViewModel | undefined;
  emergencyRelationship?: string | undefined;
  emergencyEmail?: string | undefined;
  university?: string | undefined;
  campus?: string | undefined;
  degreePoolId?: string[] | undefined;
  degree?: string | undefined;
  degreeMajor?: string | undefined;
  highSchool?: string | undefined;
  highSchoolComplete?: number | undefined;
  /** Travel arrangements will be required for this student */
  isTravelRequired?: boolean | undefined;
  dateAlumniSet?: Date | undefined;
  alumniSetByPersonId?: string | undefined;
  /** Company worked at */
  alumniCompany?: string | undefined;
  /** Current job title */
  alumniJobTitle?: string | undefined;
  /** City now working */
  alumniSuburb?: string | undefined;
  /** State now working */
  alumniState?: string | undefined;
  /** Country (code only) now working */
  alumniCountryCode?: string | undefined;
  alumniNotes?: string | undefined;
  isAtRisk?: boolean | undefined;
  dateAtRiskSet?: Date | undefined;
  atRiskByPersonId?: string | undefined;
  atRiskContext?: string | undefined;
  atRiskReasons?: string[] | undefined;
  atRiskSemester?: number | undefined;
  atRiskYear?: number | undefined;
  atRiskNotes?: string | undefined;
  quickNotes?: string | undefined;
  cvUrl?: string | undefined;
  publicSummary?: string | undefined;
  publicProfile?: string | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  profileLastUpdated?: Date | undefined;
  profileUpdatedByUserId?: string | undefined;
  isGraduating?: boolean | undefined;
  graduatingByPersonId?: string | undefined;
  graduatingNotes?: string | undefined;
  isPreUniversity?: boolean | undefined;
  datePreUniversity?: Date | undefined;
  preUniversityByPersonId?: string | undefined;
  isUniversityProgram?: boolean | undefined;
  dateUniversityProgram?: Date | undefined;
  universityProgramByPersonId?: string | undefined;
  interviewAvailability?: string | undefined;
  interviewToolkitUrl?: string | undefined;
  seasonalActions?: SeasonalActionModel[] | undefined;
  beforeMatchedIsTravelling?: boolean | undefined;
  beforeMatchedTravelLocations?: AddressViewModel[] | undefined;
  careerQuestionnaire?: StudentCareerQuestionnaireModel | undefined;
  seasonalStudent?: SeasonalStudentModel[] | undefined;
}