import {PoolStudentView} from '../models/PoolStudentView';
import {StudentPoolSummary} from './StudentPoolSummary';
import {PlacementDetailModel} from '../models/PlacementDetailModel';
import {SeasonalStudentModel} from '../models/SeasonalStudentModel';

export class StudentPoolItem implements IStudentPoolItem {
  poolStudent?: PoolStudentView | undefined;
  pool?: StudentPoolSummary | undefined;
  placement?: PlacementDetailModel[] | undefined;
  seasonalStudent?: SeasonalStudentModel | undefined;
  isHidden?: boolean | undefined;

  constructor(data?: IStudentPoolItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.poolStudent = Data["poolStudent"] ? PoolStudentView.fromJS(Data["poolStudent"]) : <any>undefined;
      this.pool = Data["pool"] ? StudentPoolSummary.fromJS(Data["pool"]) : <any>undefined;
      if (Array.isArray(Data["placement"])) {
        this.placement = [] as any;
        for (let item of Data["placement"])
          this.placement!.push(PlacementDetailModel.fromJS(item));
      }
      this.seasonalStudent = Data["seasonalStudent"] ? SeasonalStudentModel.fromJS(Data["seasonalStudent"]) : <any>undefined;
      this.isHidden = Data["isHidden"];
    }
  }

  static fromJS(data: any): StudentPoolItem {
    data = typeof data === 'object' ? data : {};
    let result = new StudentPoolItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["poolStudent"] = this.poolStudent ? this.poolStudent.toJSON() : <any>undefined;
    data["pool"] = this.pool ? this.pool.toJSON() : <any>undefined;
    if (Array.isArray(this.placement)) {
      data["placement"] = [];
      for (let item of this.placement)
        data["placement"].push(item.toJSON());
    }
    data["seasonalStudent"] = this.seasonalStudent ? this.seasonalStudent.toJSON() : <any>undefined;
    data["isHidden"] = this.isHidden;
    return data;
  }
}

export interface IStudentPoolItem {
  poolStudent?: PoolStudentView | undefined;
  pool?: StudentPoolSummary | undefined;
  placement?: PlacementDetailModel[] | undefined;
  seasonalStudent?: SeasonalStudentModel | undefined;
  isHidden?: boolean | undefined;
}