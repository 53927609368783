import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  USER,
  USER_SEARCH,
  userLoadError,
  userLoadSuccess,
  userSaveError,
  userSaveSuccess, userSearchError,
  userSearchSuccess,
} from './Actions';
import {ICommonSavePayload} from '../Common.actions';
import {IUserEditModel, UserEditModel} from '../../types/requests/UserEditModel';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {UserDetailModel} from '../../types/models/UserDetailModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {UserSearchParams} from '../../types/requests/UserSearchParams';
import {ElasticUserResultPaged} from '../../types/views/ElasticUserResultPaged';


function* LoadUser(id: string) {
  try {
    const userResult = yield authApi.get(`${apiConfig.coreUserPath}/${id}`);
    const user = UserDetailModel.fromJS(userResult.data);
    yield put(userLoadSuccess(user));
  } catch (error) {
    yield put(userLoadError(error));
  }
}

function* SaveUser({ id, submission }: ICommonSavePayload<UserEditModel>) {
  try {
    const userPath = `${apiConfig.coreUserPath}${id ? `/${id}` : ''}`;
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: userPath,
      data: submission
    });
    const user = UserDetailModel.fromJS(res.data);
    yield put(userSaveSuccess(user));
  } catch (error) {
    yield put(userSaveError(error));
  }
}

function* SearchUsers({ page, searchParams }: ISearchRequest<UserSearchParams>) {
  console.log('SAGA - teamSaga - SearchUsers');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreUserPath}/search?page=${page}`,
      data: searchParams
    });
    const usersResult: ElasticUserResultPaged = ElasticUserResultPaged.fromJS(res.data);
    yield put(userSearchSuccess(usersResult));
  } catch (error) {
    yield put(userSearchError(error));
  }
}

function* DisableUser(id: string) {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: `${apiConfig.coreUserPath}/${id}`,
    });
    const user = UserDetailModel.fromJS(res.data);
    yield put(userSaveSuccess(user));
  } catch (error) {
    yield put(userLoadError(error));
  }
}

function* EnableUser(id: string) {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreUserPath}/enable/${id}`,
    });
    const user = UserDetailModel.fromJS(res.data);
    yield put(userSaveSuccess(user));
  } catch (error) {
    yield put(userLoadError(error));
  }
}

export function* watchUserLoad() {
  yield takeLatest(USER.LOAD, ({ payload }: PayloadAction<string>) => LoadUser(payload));
}

export function* watchUserSave() {
  yield takeLatest(USER.SAVE, ({payload}: PayloadAction<ICommonSavePayload<UserEditModel>>) => SaveUser(payload) )
}

export function* watchUserSearchLoad() {
  yield takeLatest(USER_SEARCH.LOAD, ({ payload }: PayloadAction<ISearchRequest<UserSearchParams>>) => SearchUsers(payload));
}

export function* watchUserDisable() {
  yield takeLatest(USER.DISABLE, ({ payload }: PayloadAction<string>) => DisableUser(payload) )
}

export function* watchUserEnable() {
  yield takeLatest(USER.ENABLE, ({ payload }: PayloadAction<string>) => EnableUser(payload) )
}

export default function* teamsSaga() {
  yield all ([
    call(watchUserLoad),
    call(watchUserSave),
    call(watchUserSearchLoad),
    call(watchUserDisable),
    call(watchUserEnable),
  ])
}
