import {ICommonLoadActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IEngagementInfoSearchParamsModel} from '../../types/requests/EngagementInfoSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {IEngagementPersonModel} from '../../types/views/EngagementPersonModel';
import {PersonSearchResultModel} from '../../types/views/PersonSearchResultModel';


export interface IEngagementSearchActionTypes extends ICommonLoadActionType {
  REFRESH: string;
  CHANGE_VIEW: string;
}

export const ENGAGEMENT_SEARCH: IEngagementSearchActionTypes = {
  ERROR: 'engagementSearch/error',
  LOAD: 'engagementSearch/load',
  LOADED: 'engagementSearch/loaded',
  REFRESH: 'engagementSearch/refresh',
  CHANGE_VIEW: 'engagementSearch/changeView',
};

export const BULK_ADVISOR_OPERATION_SEARCH: IEngagementSearchActionTypes = {
  ERROR: 'bulkUpdateAdvisorSearch/error',
  LOAD: 'bulkUpdateAdvisorSearch/load',
  LOADED: 'bulkUpdateAdvisorSearch/loaded',
  REFRESH: 'bulkUpdateAdvisorSearch/refresh',
  CHANGE_VIEW: 'bulkUpdateAdvisorSearch/changeView',
};

export const engagementsSearch = createAction<ISearchRequest<IEngagementInfoSearchParamsModel>>(ENGAGEMENT_SEARCH.LOAD);
export const engagementsSearchView = createAction<string>(ENGAGEMENT_SEARCH.CHANGE_VIEW);
export const engagementsSearchRefresh = createAction<ISearchRequest<IEngagementInfoSearchParamsModel>>(ENGAGEMENT_SEARCH.REFRESH);
export const engagementsSearchError = createAction<string>(ENGAGEMENT_SEARCH.ERROR);
export const engagementsSearchSuccess = createAction<IPagedResult<PersonSearchResultModel>>(ENGAGEMENT_SEARCH.LOADED);

export const bulkUpdateAdvisorSearch = createAction<ISearchRequest<IEngagementInfoSearchParamsModel>>(BULK_ADVISOR_OPERATION_SEARCH.LOAD);
export const bulkUpdateAdvisorView = createAction<string>(BULK_ADVISOR_OPERATION_SEARCH.CHANGE_VIEW);
export const bulkUpdateAdvisorRefresh = createAction<ISearchRequest<IEngagementInfoSearchParamsModel>>(BULK_ADVISOR_OPERATION_SEARCH.REFRESH);
export const bulkUpdateAdvisorSearchError = createAction<string>(BULK_ADVISOR_OPERATION_SEARCH.ERROR);
export const bulkUpdateAdvisorSearchSuccess = createAction<IPagedResult<PersonSearchResultModel>>(BULK_ADVISOR_OPERATION_SEARCH.LOADED);