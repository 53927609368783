import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  ALUMNI,
  alumniSaveSuccess,
  alumniSaveError, alumniLoadSuccess, alumniLoadError
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {EngagementPersonModel} from '../../types/views/EngagementPersonModel';
import {ICommonSavePayload} from '../Common.actions';
import {AlumniEditModel} from '../../types/requests/AlumniEditModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {AlumniSearchParamsModel} from '../../types/requests/AlumniSearchParamsModel';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {ALUMNI_SEARCH, alumniSearchError, alumniSearchSuccess} from './Actions';

function* LoadAlumni(id: string) {
  console.log('SAGA - alumniSaga - LoadAlumni');
  try {
    const res = yield authApi.get(`${apiConfig.coreAlumniPath}/${id}`);
    const alumni = EngagementPersonModel.fromJS(res.data);
    yield put(alumniLoadSuccess(alumni));
  } catch (error) {
    yield put(alumniLoadError(error));
  }
}

function* SaveAlumni({ id, submission }: ICommonSavePayload<AlumniEditModel>) {
  console.log('SAGA - alumniSaga - SaveAlumni');
  const alumniPath = id ? `${apiConfig.coreAlumniPath}/${id}` : apiConfig.coreAlumniPath;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: alumniPath,
      data: submission
    });
    const alumni = EngagementPersonModel.fromJS(res.data);
    yield put(alumniSaveSuccess(alumni));
  } catch (error) {
    yield put(alumniSaveError(error));
  }
}

function* SearchAlumnis({ page, sort, searchParams }: ISearchRequest<AlumniSearchParamsModel>) {
  console.log('SAGA - alumniSaga - SearchAlumnis');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreAlumniPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(alumniSearchSuccess(results));
  } catch (error) {
    yield put(alumniSearchError(error));
  }
}

export function* watchAlumniLoad() {
  yield takeLatest(ALUMNI.LOAD, ({ payload }: PayloadAction<string>) => LoadAlumni(payload));
}

export function* watchAlumniRefresh() {
  yield takeLatest(ALUMNI.REFRESH, ({ payload }: PayloadAction<string>) => LoadAlumni(payload));
}

export function* watchAlumniSave() {
  yield takeLatest(ALUMNI.SAVE, ({ payload }: PayloadAction<ICommonSavePayload<AlumniEditModel>>) => SaveAlumni(payload));
}

export function* watchAlumniSearch() {
  yield takeLatest(ALUMNI_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<AlumniSearchParamsModel>>) => SearchAlumnis(payload))
}

export function* watchAlumniSearchRefresh() {
  yield takeLatest(ALUMNI_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<AlumniSearchParamsModel>>) => SearchAlumnis(payload))
}


export default function* alumniSaga() {
  yield all ([
    call(watchAlumniRefresh),
    call(watchAlumniLoad),
    call(watchAlumniSave),
    call(watchAlumniSearch),
    call(watchAlumniSearchRefresh),
  ])
}
