import {HSPPlacementModel} from './HSPPlacementModel';
import {HSPPlacementStepView} from './HSPPlacementStepView';
import {HSPPlacementTravelDates} from './HSPPlacementTravelDates';
import {AddressViewModel} from './AddressViewModel';

export class HSPPlacementDetailModel implements IHSPPlacementDetailModel {
  id?: string | undefined;
  companyId?: string | undefined;
  personId?: string | undefined;
  hspPoolId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  managerPersonIds?: string[] | undefined;
  locationInformation?: string | undefined;
  locationAddress?: AddressViewModel | undefined;
  isComplete?: boolean | undefined;
  dateComplete?: Date | undefined;
  completePersonId?: string | undefined;
  completeComment?: string | undefined;
  isCancelled?: boolean | undefined;
  isCancelledDuring?: boolean | undefined;
  dateCancelled?: Date | undefined;
  cancelledPersonId?: string | undefined;
  cancelledComment?: string | undefined;
  isTravelRequired?: boolean | undefined;
  isFlightsRequired?: boolean | undefined;
  departureLocation?: string | undefined;
  arrivalLocation?: string | undefined;
  accommodationRequired?: string | undefined;
  travelDates?: HSPPlacementTravelDates[] | undefined;
  travelNotes?: string | undefined;
  intendsToReturn?: boolean | undefined;
  isTravelAlert?: boolean | undefined;
  program?: string | undefined;
  placementType?: string | undefined;
  placementSetting?: HSPPlacementModel | undefined;
  placementSettingId?: string | undefined;
  placementSettingHistory?: HSPPlacementStepView[] | undefined;
  placementPercentComplete?: number | undefined;
  placementStepsComplete?: number | undefined;
  formattedTitle?: string | undefined;

  constructor(data?: IHSPPlacementDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyId = Data["companyId"];
      this.personId = Data["personId"];
      this.hspPoolId = Data["hspPoolId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      this.startDate = Data["startDate"] ? new Date(Data["startDate"].toString()) : <any>undefined;
      this.endDate = Data["endDate"] ? new Date(Data["endDate"].toString()) : <any>undefined;
      this.title = Data["title"];
      this.summary = Data["summary"];
      // this.managerPersonIds = Data["managerPersonIds"];
      if (Array.isArray(Data["managerPersonIds"])) {
        this.managerPersonIds = [] as any;
        for (let item of Data["managerPersonIds"])
          this.managerPersonIds!.push(item);
      }
      this.locationInformation = Data["locationInformation"];
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.isComplete = Data["isComplete"];
      this.dateComplete = Data["dateComplete"] ? new Date(Data["dateComplete"].toString()) : <any>undefined;
      this.completePersonId = Data["completePersonId"];
      this.completeComment = Data["completeComment"];
      this.isCancelled = Data["isCancelled"];
      this.isCancelledDuring = Data["isCancelledDuring"];
      this.dateCancelled = Data["dateCancelled"] ? new Date(Data["dateCancelled"].toString()) : <any>undefined;
      this.cancelledPersonId = Data["cancelledPersonId"];
      this.cancelledComment = Data["cancelledComment"];
      this.isTravelRequired = Data["isTravelRequired"];
      this.isFlightsRequired = Data["isFlightsRequired"];
      this.departureLocation = Data["departureLocation"];
      this.arrivalLocation = Data["arrivalLocation"];
      this.accommodationRequired = Data["accommodationRequired"];
      if (Array.isArray(Data["travelDates"])) {
        this.travelDates = [] as any;
        for (let item of Data["travelDates"])
          this.travelDates!.push(HSPPlacementTravelDates.fromJS(item));
      }
      this.travelNotes = Data["travelNotes"];
      this.intendsToReturn = Data["intendsToReturn"];
      this.isTravelAlert = Data["isTravelAlert"];
      this.program = Data["program"];
      this.placementType = Data["placementType"];
      this.placementSetting = Data["placementSetting"] ? HSPPlacementModel.fromJS(Data["placementSetting"]) : <any>undefined;
      this.placementSettingId = Data["placementSettingId"];
      if (Array.isArray(Data["placementSettingHistory"])) {
        this.placementSettingHistory = [] as any;
        for (let item of Data["placementSettingHistory"])
          this.placementSettingHistory!.push(HSPPlacementStepView.fromJS(item));
      }
      this.placementPercentComplete = Data["placementPercentComplete"];
      this.placementStepsComplete = Data["placementStepsComplete"];
      this.formattedTitle = Data["formattedTitle"];
    }
  }

  static fromJS(data: any): HSPPlacementDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPPlacementDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyId"] = this.companyId;
    data["personId"] = this.personId;
    data["hspPoolId"] = this.hspPoolId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    data["startDate"] = this.startDate ? this.startDate.toISOString() : <any>undefined;
    data["endDate"] = this.endDate ? this.endDate.toISOString() : <any>undefined;
    data["title"] = this.title;
    data["summary"] = this.summary;
    if (Array.isArray(this.managerPersonIds)) {
      data["managerPersonIds"] = [];
      for (let item of this.managerPersonIds)
        data["managerPersonIds"].push(item);
    }
    data["locationInformation"] = this.locationInformation;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["isComplete"] = this.isComplete;
    data["dateComplete"] = this.dateComplete ? this.dateComplete.toISOString() : <any>undefined;
    data["completePersonId"] = this.completePersonId;
    data["completeComment"] = this.completeComment;
    data["isCancelled"] = this.isCancelled;
    data["isCancelledDuring"] = this.isCancelledDuring;
    data["dateCancelled"] = this.dateCancelled ? this.dateCancelled.toISOString() : <any>undefined;
    data["cancelledPersonId"] = this.cancelledPersonId;
    data["cancelledComment"] = this.cancelledComment;
    data["isTravelRequired"] = this.isTravelRequired;
    data["isFlightsRequired"] = this.isFlightsRequired;
    data["departureLocation"] = this.departureLocation;
    data["arrivalLocation"] = this.arrivalLocation;
    data["accommodationRequired"] = this.accommodationRequired;
    if (Array.isArray(this.travelDates)) {
      data["travelDates"] = [];
      for (let item of this.travelDates)
        data["travelDates"].push(item.toJSON());
    }
    data["travelNotes"] = this.travelNotes;
    data["intendsToReturn"] = this.intendsToReturn;
    data["isTravelAlert"] = this.isTravelAlert;
    data["program"] = this.program;
    data["placementType"] = this.placementType;
    data["placementSetting"] = this.placementSetting ? this.placementSetting.toJSON() : <any>undefined;
    data["placementSettingId"] = this.placementSettingId;
    if (Array.isArray(this.placementSettingHistory)) {
      data["placementSettingHistory"] = [];
      for (let item of this.placementSettingHistory)
        data["placementSettingHistory"].push(item.toJSON());
    }
    data["placementPercentComplete"] = this.placementPercentComplete;
    data["placementStepsComplete"] = this.placementStepsComplete;
    data["formattedTitle"] = this.formattedTitle;
    return data;
  }
}

export interface IHSPPlacementDetailModel {
  id?: string | undefined;
  companyId?: string | undefined;
  personId?: string | undefined;
  hspPoolId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  managerPersonIds?: string[] | undefined;
  locationInformation?: string | undefined;
  locationAddress?: AddressViewModel | undefined;
  isComplete?: boolean | undefined;
  dateComplete?: Date | undefined;
  completePersonId?: string | undefined;
  completeComment?: string | undefined;
  isCancelled?: boolean | undefined;
  isCancelledDuring?: boolean | undefined;
  dateCancelled?: Date | undefined;
  cancelledPersonId?: string | undefined;
  cancelledComment?: string | undefined;
  isTravelRequired?: boolean | undefined;
  isFlightsRequired?: boolean | undefined;
  departureLocation?: string | undefined;
  arrivalLocation?: string | undefined;
  accommodationRequired?: string | undefined;
  travelDates?: HSPPlacementTravelDates[] | undefined;
  travelNotes?: string | undefined;
  intendsToReturn?: boolean | undefined;
  isTravelAlert?: boolean | undefined;
  program?: string | undefined;
  placementType?: string | undefined;
  placementSetting?: HSPPlacementModel | undefined;
  placementSettingId?: string | undefined;
  placementSettingHistory?: HSPPlacementStepView[] | undefined;
  placementPercentComplete?: number | undefined;
  placementStepsComplete?: number | undefined;
  formattedTitle?: string | undefined;
}