import {AdvisorUserModel} from '../models/AdvisorUserModel';
import {HSPPoolModel} from '../models/HSPPoolModel';
import {HSPPoolContactPersonModel} from '../models/HSPPoolContactPersonModel';
import {HSPPoolStudentView} from '../models/HSPPoolStudentView';
import {AddressEditModel} from '../requests/AddressEditModel';

export class HSPPoolSummary implements IHSPPoolSummary {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  poolStatus?: string | undefined;
  dateClosed?: string | undefined;
  closedByUserId?: string | undefined;
  title?: string | undefined;
  quickNotes?: string | undefined;
  locationAddress?: AddressEditModel | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  countryCode?: string | undefined;
  startingPositions?: number | undefined;
  calculatedRemainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  description?: string | undefined;
  degreePoolIds?: string[] | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByPersonId?: string | undefined;
  poolSetting?: HSPPoolModel | undefined;
  poolSettingId?: string | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  contactPeople?: HSPPoolContactPersonModel[] | undefined;
  poolStudents?: HSPPoolStudentView[] | undefined;
  maxProgressPercent?: number | undefined;
  maxProgressSteps?: number | undefined;
  primaryAdvisorPersonId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  billingStatus?: string | undefined;
  poolType?: string | undefined;
  remoteFlexible?: boolean | undefined;
  fileIds?: string[] | undefined;

  constructor(data?: IHSPPoolSummary) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyId = Data["companyId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.poolStatus = Data["poolStatus"];
      this.dateClosed = Data["dateClosed"];
      this.closedByUserId = Data["closedByUserId"];
      this.title = Data["title"];
      this.quickNotes = Data["quickNotes"];
      this.locationAddress = Data["locationAddress"] ? AddressEditModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.suburb = Data["suburb"];
      this.state = Data["state"];
      this.countryCode = Data["countryCode"];
      this.startingPositions = Data["startingPositions"];
      this.calculatedRemainingPositions = Data["calculatedRemainingPositions"];
      this.shortlistedStudentsCount = Data["shortlistedStudentsCount"];
      this.studentsMatched = Data["studentsMatched"];
      this.description = Data["description"];
      if (Array.isArray(Data["degreePoolIds"])) {
        this.degreePoolIds = [] as any;
        for (let item of Data["degreePoolIds"])
          this.degreePoolIds!.push(item);
      }
      this.isDeleted = Data["isDeleted"];
      this.dateDeleted = Data["dateDeleted"] ? new Date(Data["dateDeleted"].toString()) : <any>undefined;
      this.deletedByPersonId = Data["deletedByPersonId"];
      this.poolSetting = Data["poolSetting"] ? HSPPoolModel.fromJS(Data["poolSetting"]) : <any>undefined;
      this.poolSettingId = Data["poolSettingId"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(AdvisorUserModel.fromJS(item));
      }
      if (Array.isArray(Data["contactPeople"])) {
        this.contactPeople = [] as any;
        for (let item of Data["contactPeople"])
          this.contactPeople!.push(HSPPoolContactPersonModel.fromJS(item));
      }
      if (Array.isArray(Data["poolStudents"])) {
        this.poolStudents = [] as any;
        for (let item of Data["poolStudents"])
          this.poolStudents!.push(HSPPoolStudentView.fromJS(item));
      }
      this.maxProgressPercent = Data["maxProgressPercent"];
      this.maxProgressSteps = Data["maxProgressSteps"];
      this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      this.billingStatus = Data["billingStatus"];
      this.poolType = Data["poolType"];
      this.remoteFlexible = Data["remoteFlexible"];
      if (Array.isArray(Data["fileIds"])) {
        this.fileIds = [] as any;
        for (let item of Data["fileIds"])
          this.fileIds!.push(item);
      }
    }
  }

  static fromJS(data: any): HSPPoolSummary {
    data = typeof data === 'object' ? data : {};
    let result = new HSPPoolSummary();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyId"] = this.companyId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["poolStatus"] = this.poolStatus;
    data["dateClosed"] = this.dateClosed;
    data["closedByUserId"] = this.closedByUserId;
    data["title"] = this.title;
    data["quickNotes"] = this.quickNotes;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["suburb"] = this.suburb;
    data["state"] = this.state;
    data["countryCode"] = this.countryCode;
    data["startingPositions"] = this.startingPositions;
    data["calculatedRemainingPositions"] = this.calculatedRemainingPositions;
    data["shortlistedStudentsCount"] = this.shortlistedStudentsCount;
    data["studentsMatched"] = this.studentsMatched;
    data["description"] = this.description;
    if (Array.isArray(this.degreePoolIds)) {
      data["degreePoolIds"] = [];
      for (let item of this.degreePoolIds)
        data["degreePoolIds"].push(item);
    }
    data["isDeleted"] = this.isDeleted;
    data["dateDeleted"] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
    data["deletedByPersonId"] = this.deletedByPersonId;
    data["poolSetting"] = this.poolSetting ? this.poolSetting.toJSON() : <any>undefined;
    data["poolSettingId"] = this.poolSettingId;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item.toJSON());
    }
    if (Array.isArray(this.contactPeople)) {
      data["contactPeople"] = [];
      for (let item of this.contactPeople)
        data["contactPeople"].push(item.toJSON());
    }
    if (Array.isArray(this.poolStudents)) {
      data["poolStudents"] = [];
      for (let item of this.poolStudents)
        data["poolStudents"].push(item.toJSON());
    }
    data["maxProgressPercent"] = this.maxProgressPercent;
    data["maxProgressSteps"] = this.maxProgressSteps;
    data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    data["billingStatus"] = this.billingStatus;
    data["poolType"] = this.poolType;
    data["remoteFlexible"] = this.remoteFlexible;
    if (Array.isArray(this.fileIds)) {
      data["fileIds"] = [];
      for (let item of this.fileIds)
        data["fileIds"].push(item);
    }
    return data;
  }
}

export interface IHSPPoolSummary {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  poolStatus?: string | undefined;
  dateClosed?: string | undefined;
  closedByUserId?: string | undefined;
  title?: string | undefined;
  quickNotes?: string | undefined;
  locationAddress?: AddressEditModel | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  countryCode?: string | undefined;
  startingPositions?: number | undefined;
  calculatedRemainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  description?: string | undefined;
  degreePoolIds?: string[] | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByPersonId?: string | undefined;
  poolSetting?: HSPPoolModel | undefined;
  poolSettingId?: string | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  contactPeople?: HSPPoolContactPersonModel[] | undefined;
  poolStudents?: HSPPoolStudentView[] | undefined;
  maxProgressPercent?: number | undefined;
  maxProgressSteps?: number | undefined;
  primaryAdvisorPersonId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  billingStatus?: string | undefined;
  poolType?: string | undefined;
  remoteFlexible?: boolean | undefined;
  fileIds?: string[] | undefined;
}