import {
  ICommonLoadActionType,
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonSearchActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {OpportunityDetailModel} from '../../types/models/OpportunityDetailModel';
import {PersonModel} from '../../types/models/PersonModel';
import {OpportunityEditDetailModel} from '../../types/requests/OpportunityEditDetailModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {CompanyOpportunitySeasonModel} from '../../types/models/CompanyOpportunitySeasonModel';
import {OpportunitySearchParamsModel} from '../../types/requests/OpportunitySearchParamsModel';
import {OpportunitySearchPaged} from '../../types/models/OpportunitySearchPaged';
import {OpportunityBySeasonRequest} from '../../types/requests/OpportunityBySeasonRequest';
import {OpportunityStudentListView} from '../../types/views/OpportunityStudentListView';
import {OpportunitySummaryModel} from '../../types/models/OpportunitySummaryModel';
import {PoolSearchParamsModel} from '../../types/requests/PoolSearchParamsModel';
import {PoolDetailModel} from '../../types/models/PoolDetailModel';

import {HSPOpportunityDetailModel} from '../../types/models/HSPOpportunityDetailModel';
import {HSPOpportunityEditDetailModel} from '../../types/requests/HSPOpportunityEditDetailModel';
import {HSPCompanyOpportunitySeasonModel} from '../../types/models/HSPCompanyOpportunitySeasonModel';
import {HSPOpportunitySearchParamsModel} from '../../types/requests/HSPOpportunitySearchParamsModel';
import {HSPOpportunitySearchPaged} from '../../types/models/HSPOpportunitySearchPaged';
import {HSPOpportunityBySeasonRequest} from '../../types/requests/HSPOpportunityBySeasonRequest';
import {HSPOpportunityStudentListView} from '../../types/views/HSPOpportunityStudentListView';
import {HSPOpportunitySummaryModel} from '../../types/models/HSPOpportunitySummaryModel';
import {HSPPoolSearchParamsModel} from '../../types/requests/HSPPoolSearchParamsModel';
import {HSPPoolDetailModel} from '../../types/models/HSPPoolDetailModel';

export interface IOpportunityActionTypes extends ICommonSaveActionType {
  REFRESH: string;
  LOAD_BY_SEASON: string;
  REFRESH_BY_SEASON: string;
}

export interface IOpportunityCompanySeasonsType extends ICommonLoadActionType {
  REFRESH: string;
}

export interface IOpportunityContactSeasonsType extends ICommonLoadActionType {
  REFRESH: string;
}

export interface ICurrentOpportunityTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const OPPORTUNITY: IOpportunityActionTypes = {
  LOAD: 'opportunity/load',
  LOAD_BY_SEASON: 'opportunity/loadBySeason',
  LOADED: 'opportunity/loadSuccess',
  LOAD_ERROR: 'opportunity/loadError',
  REFRESH: 'opportunity/refresh',
  REFRESH_BY_SEASON: 'opportunity/refreshBySeason',
  SAVE: 'opportunity/save',
  SAVED: 'opportunity/saveSuccess',
  SAVE_ERROR: 'opportunity/saveError',
};

export const OPPORTUNITY_STUDENT_PROFILES: ICommonLoadActionType = {
  ERROR: 'opportunityStudentProfiles/error',
  LOAD: 'opportunityStudentProfiles/load',
  LOADED: 'opportunityStudentProfiles/loadSuccess'
};

export const OPPORTUNITY_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'opportunitySearch/view',
  ERROR: 'opportunitySearch/error',
  LOAD: 'opportunitySearch/search',
  LOADED: 'opportunitySearch/searchSuccess',
  REFRESH: 'opportunitySearch/refresh'
};

export const OPPORTUNITY_LOCALITY_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'opportunityLocalitySearch/view',
  ERROR: 'opportunityLocalitySearch/error',
  LOAD: 'opportunityLocalitySearch/search',
  LOADED: 'opportunityLocalitySearch/searchSuccess',
  REFRESH: 'opportunityLocalitySearch/refresh'
};

export const COMPANY_OPPORTUNITY_SEARCH: ICommonSearchActionType = {
  CHANGE_VIEW: 'opportunityCompanySearch/view',
  ERROR: 'opportunityCompanySearch/error',
  LOAD: 'opportunityCompanySearch/search',
  LOADED: 'opportunityCompanySearch/searchSuccess',
  REFRESH: 'opportunityCompanySearch/refresh'
};

export const COMPANY_OPPORTUNITY_SEASONS: IOpportunityCompanySeasonsType = {
  ERROR: 'opportunityCompanySeasonsSearch/error',
  LOAD: 'opportunityCompanySeasonsSearch/search',
  LOADED: 'opportunityCompanySeasonsSearch/searchSuccess',
  REFRESH: 'opportunityCompanySeasonsSearch/refresh'
};

export const STUDENT_OPPORTUNITY_SEASONS: IOpportunityCompanySeasonsType = {
  ERROR: 'opportunityStudentSeasonsSearch/error',
  LOAD: 'opportunityStudentSeasonsSearch/search',
  LOADED: 'opportunityStudentSeasonsSearch/searchSuccess',
  REFRESH: 'opportunityStudentSeasonsSearch/refresh'
};

export const CONTACT_OPPORTUNITY_SEASONS: IOpportunityContactSeasonsType = {
  ERROR: 'opportunityContactSeasonsSearch/error',
  LOAD: 'opportunityContactSeasonsSearch/search',
  LOADED: 'opportunityContactSeasonsSearch/searchSuccess',
  REFRESH: 'opportunityContactSeasonsSearch/refresh'
};

export const CURRENT_OPPORTUNITY: ICurrentOpportunityTypes = {
  ERROR: 'currentCompanyOpportunities/error',
  LOAD: 'currentCompanyOpportunities/search',
  LOADED: 'currentCompanyOpportunities/searchSuccess',
  REFRESH: 'currentCompanyOpportunities/refresh'
}

export const opportunityLoadBySeason = createAction<OpportunityBySeasonRequest>(OPPORTUNITY.LOAD_BY_SEASON);
export const opportunityLoad = createAction<string>(OPPORTUNITY.LOAD);
export const opportunityRefreshBySeason = createAction<OpportunityBySeasonRequest>(OPPORTUNITY.REFRESH_BY_SEASON);
export const opportunityRefresh = createAction<string>(OPPORTUNITY.REFRESH);
export const opportunityLoadSuccess = createAction<OpportunityStudentListView | undefined>(OPPORTUNITY.LOADED);
export const opportunityLoadError = createAction<string>(OPPORTUNITY.LOAD_ERROR);

export const opportunityStudentProfilesLoad = createAction<string[]>(OPPORTUNITY_STUDENT_PROFILES.LOAD);
export const opportunityStudentProfilesLoadSuccess = createAction<PersonModel[]>(OPPORTUNITY_STUDENT_PROFILES.LOADED);
export const opportunityStudentProfilesLoadError = createAction<string>(OPPORTUNITY_STUDENT_PROFILES.ERROR);

export const opportunitySave = createAction<ICommonSavePayload<OpportunityEditDetailModel>>(OPPORTUNITY.SAVE);
export const opportunitySaveSuccess = createAction<OpportunityDetailModel>(OPPORTUNITY.SAVED);
export const opportunitySaveError = createAction<string>(OPPORTUNITY.SAVE_ERROR);

export const opportunitySearchLoad = createAction<ISearchRequest<OpportunitySearchParamsModel>>(OPPORTUNITY_SEARCH.LOAD);
export const opportunitySearchRefresh = createAction<ISearchRequest<OpportunitySearchParamsModel>>(OPPORTUNITY_SEARCH.REFRESH);
export const opportunitySearchLoadSuccess = createAction<OpportunitySearchPaged>(OPPORTUNITY_SEARCH.LOADED);
export const opportunitySearchLoadError = createAction<string>(OPPORTUNITY_SEARCH.ERROR);

export const opportunityCompanySearchLoad = createAction<ISearchRequest<OpportunitySearchParamsModel>>(COMPANY_OPPORTUNITY_SEARCH.LOAD);
export const opportunityCompanySearchRefresh = createAction<ISearchRequest<OpportunitySearchParamsModel>>(COMPANY_OPPORTUNITY_SEARCH.REFRESH);
export const opportunityCompanySearchLoadSuccess = createAction<OpportunityDetailModel[]>(COMPANY_OPPORTUNITY_SEARCH.LOADED);
export const opportunityCompanySearchLoadError = createAction<string>(COMPANY_OPPORTUNITY_SEARCH.ERROR);

export const opportunityCompanySeasonsLoad = createAction<string>(COMPANY_OPPORTUNITY_SEASONS.LOAD);
export const opportunityCompanySeasonsRefresh = createAction<string>(COMPANY_OPPORTUNITY_SEASONS.REFRESH);
export const opportunityCompanySeasonsLoadSuccess = createAction<CompanyOpportunitySeasonModel[]>(COMPANY_OPPORTUNITY_SEASONS.LOADED);
export const opportunityCompanySeasonsLoadError = createAction<string>(COMPANY_OPPORTUNITY_SEASONS.ERROR);

export const opportunityStudentSeasonsLoad = createAction<string>(STUDENT_OPPORTUNITY_SEASONS.LOAD);
export const opportunityStudentSeasonsRefresh = createAction<string>(STUDENT_OPPORTUNITY_SEASONS.REFRESH);
export const opportunityStudentSeasonsLoadSuccess = createAction<OpportunitySummaryModel[]>(STUDENT_OPPORTUNITY_SEASONS.LOADED);
export const opportunityStudentSeasonsLoadError = createAction<string>(STUDENT_OPPORTUNITY_SEASONS.ERROR);

export const opportunityContactSeasonsLoad = createAction<string>(CONTACT_OPPORTUNITY_SEASONS.LOAD);
export const opportunityContactSeasonsRefresh = createAction<string>(CONTACT_OPPORTUNITY_SEASONS.REFRESH);
export const opportunityContactSeasonsLoadSuccess = createAction<OpportunitySummaryModel[]>(CONTACT_OPPORTUNITY_SEASONS.LOADED);
export const opportunityContactSeasonsLoadError = createAction<string>(CONTACT_OPPORTUNITY_SEASONS.ERROR);

export const opportunityLocalitySearchLoad = createAction<ISearchRequest<OpportunitySearchParamsModel>>(OPPORTUNITY_LOCALITY_SEARCH.LOAD);
export const opportunityLocalitySearchRefresh = createAction<ISearchRequest<OpportunitySearchParamsModel>>(OPPORTUNITY_LOCALITY_SEARCH.REFRESH);
export const opportunityLocalitySearchLoadSuccess = createAction<PoolDetailModel[]>(OPPORTUNITY_LOCALITY_SEARCH.LOADED);
export const opportunityLocalitySearchLoadError = createAction<string>(OPPORTUNITY_LOCALITY_SEARCH.ERROR);

export const currentOpportunityLoad = createAction<OpportunityBySeasonRequest>(CURRENT_OPPORTUNITY.LOAD);
export const currentOpportunityRefresh = createAction<OpportunityBySeasonRequest>(CURRENT_OPPORTUNITY.REFRESH);
export const currentOpportunityLoadSuccess = createAction<OpportunityStudentListView | undefined>(CURRENT_OPPORTUNITY.LOADED);
export const currentOpportunityLoadError = createAction<string>(CURRENT_OPPORTUNITY.ERROR);