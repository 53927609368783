import {ClientOnboardingModel} from './ClientOnboardingModel';
import {ClientDetailModelClientStatus} from './Enums';
import {ClientOnboardingHistoryModel} from './ClientOnboardingHistoryModel';
import {ClientPartnerTagModel} from './ClientPartnerTagModel';


export class ClientDetailModel implements IClientDetailModel {
  onboardingClientSettingId?: string | undefined;
  clientOnboardingSetting?: ClientOnboardingModel | undefined;
  clientOnboardingHistory?: ClientOnboardingHistoryModel[] | undefined;
  clientStatus?: ClientDetailModelClientStatus | undefined;
  clientTags?: ClientPartnerTagModel[] | undefined;
  isActive?: boolean | undefined;
  expectedInternsFrom?: number | undefined;
  expectedInternsTo?: number | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  clientSummary?: string | undefined;
  companyType?: string | undefined;
  contractYearExpires?: number | undefined;
  sponsorshipFee?: number | undefined;
  additionalRequirements?: string | undefined;
  isHighSchoolProgram?: boolean | undefined;
  isUniversityProgram?: boolean | undefined;

  constructor(data?: IClientDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.onboardingClientSettingId = Data["onboardingClientSettingId"];
      this.clientOnboardingSetting = Data["clientOnboardingSetting"] ? ClientOnboardingModel.fromJS(Data["clientOnboardingSetting"]) : <any>undefined;
      if (Array.isArray(Data["clientOnboardingHistory"])) {
        this.clientOnboardingHistory = [] as any;
        for (let item of Data["clientOnboardingHistory"])
          this.clientOnboardingHistory!.push(ClientOnboardingHistoryModel.fromJS(item));
      }
      this.clientStatus = Data["clientStatus"];
      if (Array.isArray(Data["clientTags"])) {
        this.clientTags = [] as any;
        for (let item of Data["clientTags"])
          this.clientTags!.push(ClientPartnerTagModel.fromJS(item));
      }
      this.isActive = Data["isActive"];
      this.expectedInternsFrom = Data["expectedInternsFrom"];
      this.expectedInternsTo = Data["expectedInternsTo"];
      this.onboardingStepsComplete = Data["onboardingStepsComplete"];
      this.onboardingPercent = Data["onboardingPercent"];
      this.clientSummary = Data["clientSummary"];
      this.companyType = Data["companyType"];
      this.contractYearExpires = Data["contractYearExpires"];
      this.sponsorshipFee = Data["sponsorshipFee"];
      this.additionalRequirements = Data["additionalRequirements"];
      this.isHighSchoolProgram = Data["isHighSchoolProgram"];
      this.isUniversityProgram = Data["isUniversityProgram"];
    }
  }

  static fromJS(data: any): ClientDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new ClientDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["onboardingClientSettingId"] = this.onboardingClientSettingId;
    data["clientOnboardingSetting"] = this.clientOnboardingSetting ? this.clientOnboardingSetting.toJSON() : <any>undefined;
    if (Array.isArray(this.clientOnboardingHistory)) {
      data["clientOnboardingHistory"] = [];
      for (let item of this.clientOnboardingHistory)
        data["clientOnboardingHistory"].push(item.toJSON());
    }
    data["clientStatus"] = this.clientStatus;
    if (Array.isArray(this.clientTags)) {
      data["clientTags"] = [];
      for (let item of this.clientTags)
        data["clientTags"].push(item.toJSON());
    }
    data["isActive"] = this.isActive;
    data["expectedInternsFrom"] = this.expectedInternsFrom;
    data["expectedInternsTo"] = this.expectedInternsTo;
    data["onboardingStepsComplete"] = this.onboardingStepsComplete;
    data["onboardingPercent"] = this.onboardingPercent;
    data["clientSummary"] = this.clientSummary;
    data["companyType"] = this.companyType;
    data["contractYearExpires"] = this.contractYearExpires;
    data["sponsorshipFee"] = this.sponsorshipFee;
    data["additionalRequirements"] = this.additionalRequirements;
    data["isHighSchoolProgram"] = this.isHighSchoolProgram;
    data["isUniversityProgram"] = this.isUniversityProgram;
    return data;
  }
}

export interface IClientDetailModel {
  onboardingClientSettingId?: string | undefined;
  clientOnboardingSetting?: ClientOnboardingModel | undefined;
  clientOnboardingHistory?: ClientOnboardingHistoryModel[] | undefined;
  clientStatus?: ClientDetailModelClientStatus | undefined;
  clientTags?: ClientPartnerTagModel[] | undefined;
  isActive?: boolean | undefined;
  expectedInternsFrom?: number | undefined;
  expectedInternsTo?: number | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  clientSummary?: string | undefined;
  companyType?: string | undefined;
  contractYearExpires?: number | undefined;
  sponsorshipFee?: number | undefined;
  additionalRequirements?: string | undefined;
  isHighSchoolProgram?: boolean | undefined;
  isUniversityProgram?: boolean | undefined;
}