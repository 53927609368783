import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {inboxPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const sectionBreadcrumbs = [new BreadcrumbItemProps("Inbox", inboxPaths.InboxView)];

const inboxRoutes = [
  new AppRouteProps('View', inboxPaths.InboxView, React.lazy(() => import('./InboxView')), sectionBreadcrumbs),
  new AppRouteProps('Conversation', inboxPaths.InboxThreadView, React.lazy(() => import('./InboxView')), sectionBreadcrumbs),
]

const inboxPathsConfig: AppRouteGroupProps[] = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, inboxRoutes),
];

export default inboxPathsConfig;
