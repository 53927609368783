import React, {useContext} from 'react';
import {renderRoutes} from 'react-router-config';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {useLocation} from 'react-router-dom';
import {AppSuspense} from '../../index';
import routes from '../../../modules';
import Scrollbar from '../Scrollbar';
import AppContext from '../../utility/AppContext';
import Box from '@mui/material/Box';
import {RouteTransition} from '../../../shared/constants/AppEnums';
import AppErrorBoundary from '../AppErrorBoundary';
import AppContextPropsType, {AppTheme} from '../../../types/AppContextPropsType';
import makeStyles from '@mui/styles/makeStyles';

interface TransitionWrapperProps {
  children: any;
}

const useStyle = makeStyles((theme: AppTheme) => ({
  contentScroll: {
    [theme.breakpoints.up('sm')]: {
      marginRight: 64,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 80,
    },
  }
}));

const TransitionWrapper: React.FC<TransitionWrapperProps> = ({children}) => {
  const {rtAnim} = useContext<AppContextPropsType>(AppContext);
  const location = useLocation();
  if (rtAnim === RouteTransition.NONE) {
    return <>{children}</>;
  }
  return (
    <TransitionGroup appear enter exit>
      <CSSTransition
        key={location.key}
        timeout={{enter: 300, exit: 300}}
        classNames={rtAnim}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

const ContentView = () => {
  const classes = useStyle();

  return (
    <Scrollbar className={classes.contentScroll}>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        className='main-content-view'>
        <AppSuspense>
          <AppErrorBoundary>
            <TransitionWrapper>{renderRoutes(routes)}</TransitionWrapper>
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
    </Scrollbar>
  );
};

export default ContentView;
