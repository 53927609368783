import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {companyPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const formPage = React.lazy(() => import('./CompanyEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Company", companyPaths.CompanySearch)];

const opportunnitiesBreadcrumbs = [new BreadcrumbItemProps("Company", companyPaths.OpportunitiesSearch)];

const companyRoutes = [
  new AppRouteProps("Add", companyPaths.CompanyCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit", companyPaths.CompanyEdit, formPage, sectionBreadcrumbs),
  new AppRouteProps("Search", companyPaths.CompanySearch, React.lazy(() => import('./CompanySearch')), sectionBreadcrumbs),
  new AppRouteProps("Details", companyPaths.CompanyProfile, React.lazy(() => import('./CompanyDetails')), sectionBreadcrumbs),
  new AppRouteProps("Opportunities", companyPaths.OpportunitiesSearch, React.lazy(() => import('./OpportunitiesSearch')), opportunnitiesBreadcrumbs),
]

const companyRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, companyRoutes)
];

export default companyRoutesConfig;
