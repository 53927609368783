import {HISTORY} from './Actions';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {HistoryRetrievedModel} from '../../types/models/HistoryRetrievedModel';

export interface IHistoryState {
  historyLoading: boolean | false;
  historyLoadingError: string | undefined;
  historySaving: boolean | false;
  historySavingError: string | undefined;
  historyDetail: HistoryRetrievedModel[];
}

const initialState: IHistoryState = {
  historyLoading: false,
  historyLoadingError: undefined,
  historySaving: false,
  historySavingError: undefined,
  historyDetail: []
}

const historyReducer = createReducer(initialState, {
  [HISTORY.LOAD]: (state) => {
    state.historyLoading = true;
    state.historyLoadingError = undefined;
  },
  [HISTORY.LOADED]: (state, action: PayloadAction<HistoryRetrievedModel[]>) => {
    state.historyLoading = false;
    state.historyLoadingError = undefined;
    state.historyDetail = action.payload;
  },
  [HISTORY.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.historyLoading = false;
    state.historyLoadingError = action.payload;
  },
  [HISTORY.SAVE]: (state) => {
    state.historySaving = true;
    state.historySavingError = undefined;
  },
  [HISTORY.SAVED]: (state, action: PayloadAction<HistoryRetrievedModel[]>) => {
    state.historySaving = false;
    state.historySavingError = undefined;
    state.historyDetail = action.payload;
  },
  [HISTORY.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.historySaving = true;
    state.historySavingError = action.payload;
  },
});

export default historyReducer;