import {TaskFieldModel} from './TaskFieldModel';

export class InternshipExperienceFieldsModel implements IInternshipExperienceFieldsModel {
  version?: number | undefined;
  fields?: TaskFieldModel[] | undefined;

  constructor(data?: IInternshipExperienceFieldsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.version = Data["version"];
      if (Array.isArray(Data["fields"])) {
        this.fields = [] as any;
        for (let item of Data["fields"])
          this.fields!.push(TaskFieldModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): InternshipExperienceFieldsModel {
    data = typeof data === 'object' ? data : {};
    let result = new InternshipExperienceFieldsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["version"] = this.version;
    if (Array.isArray(this.fields)) {
      data["fields"] = [];
      for (let item of this.fields)
        data["fields"].push(item.toJSON());
    }
    return data;
  }
}

export interface IInternshipExperienceFieldsModel {
  version?: number | undefined;
  fields?: TaskFieldModel[] | undefined;
}