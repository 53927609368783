export class UserTeamData implements IUserTeamData {
  personId?: string | undefined;
  teamId?: string | undefined;
  subTeamId?: string | undefined;
  dateCreated?: string | undefined;
  dateUpdated?: string | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  description?: string | undefined;

  constructor(data?: IUserTeamData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.teamId = Data["teamId"];
      this.subTeamId = Data["subTeamId"];
      this.dateCreated = Data["dateCreated"];
      this.dateUpdated = Data["dateUpdated"];
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.description = Data["description"];
    }
  }

  static fromJS(data: any): UserTeamData {
    data = typeof data === 'object' ? data : {};
    let result = new UserTeamData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["teamId"] = this.teamId;
    data["subTeamId"] = this.subTeamId;
    data["dateCreated"] = this.dateCreated;
    data["dateUpdated"] = this.dateUpdated;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["description"] = this.description;
    return data;
  }
}

export interface IUserTeamData {
  personId?: string | undefined;
  teamId?: string | undefined;
  subTeamId?: string | undefined;
  dateCreated?: string | undefined;
  dateUpdated?: string | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  description?: string | undefined;
}