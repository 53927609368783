import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {breakSettingsPath} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const formPage = React.lazy(() => import('./BreakSettingsEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Email templates", breakSettingsPath.BreakSettingsManage)];

const breakSettingsRoutes = [
  new AppRouteProps("Manage", breakSettingsPath.BreakSettingsManage, React.lazy(() => import('./BreakSettingsResult')), sectionBreadcrumbs),
  new AppRouteProps("Create", breakSettingsPath.BreakSettingsCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit", breakSettingsPath.BreakSettingsEdit, formPage, sectionBreadcrumbs),
]

const breakSettingsRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADMINISTRATOR, breakSettingsRoutes)
];

export default breakSettingsRoutesConfig;
