import {all, call, put, takeLatest} from 'redux-saga/effects';
import { OpportunityDetailModel } from 'types/models/OpportunityDetailModel';
import {
  COMPANY_OPPORTUNITY_SEARCH,
  COMPANY_OPPORTUNITY_SEASONS,
  OPPORTUNITY,
  OPPORTUNITY_SEARCH,
  OPPORTUNITY_STUDENT_PROFILES,
  STUDENT_OPPORTUNITY_SEASONS,
  opportunityCompanySearchLoadError,
  opportunityCompanySearchLoadSuccess,
  opportunityCompanySeasonsLoadError,
  opportunityCompanySeasonsLoadSuccess,
  opportunityLoadError,
  opportunityLoadSuccess,
  opportunitySaveError,
  opportunitySaveSuccess,
  opportunitySearchLoadError,
  opportunitySearchLoadSuccess,
  opportunityStudentProfilesLoadError,
  opportunityStudentProfilesLoadSuccess,
  opportunityStudentSeasonsLoadSuccess,
  opportunityStudentSeasonsLoadError,
  CONTACT_OPPORTUNITY_SEASONS,
  opportunityContactSeasonsLoadSuccess,
  opportunityContactSeasonsLoadError,
  opportunityLocalitySearchLoadSuccess,
  opportunityLocalitySearchLoadError,
  OPPORTUNITY_LOCALITY_SEARCH,
  currentOpportunityLoadSuccess,
  currentOpportunityLoadError,
  CURRENT_OPPORTUNITY,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PersonModel} from '../../types/models/PersonModel';
import {ICommonSavePayload} from '../Common.actions';
import {OpportunityEditDetailModel} from '../../types/requests/OpportunityEditDetailModel';
import { OpportunitySearchParamsModel } from 'types/requests/OpportunitySearchParamsModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {SeasonModel} from '../../types/models/SeasonModel';
import {OpportunitySearchPaged} from '../../types/models/OpportunitySearchPaged';
import {PayloadAction} from '@reduxjs/toolkit';
import {OpportunitySummaryModel} from '../../types/models/OpportunitySummaryModel';
import {OpportunityBySeasonRequest} from '../../types/requests/OpportunityBySeasonRequest';
import {OpportunityStudentListView} from '../../types/views/OpportunityStudentListView';
import {OpportunitiesSearchPaged} from '../../types/views/OpportunitiesSearchPaged';
import {PoolSearchParamsModel} from '../../types/requests/PoolSearchParamsModel';
import {PoolSearchPaged} from '../../types/views/PoolSearchPaged';
import {LocalOpportunitySearchItem} from '../../types/views/LocalOpportunitySearchItem';

function* OpportunityLoad(opportunityId: string) {
  console.log('SAGA - opportunitySaga - OpportunityLoad');
  try {
    const res = yield authApi.get(`${apiConfig.coreOpportunitiesPath}/${opportunityId}`);
    const opportunity: OpportunityStudentListView = OpportunityStudentListView.fromJS(res.data);
    yield put(opportunityLoadSuccess(opportunity));
  } catch (err) {
    yield put(opportunityLoadError(err));
  }
}

function* OpportunityLoadBySeason(request: OpportunityBySeasonRequest) {
  console.log('SAGA - opportunitySaga - OpportunityLoadBySeason');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreOpportunitiesPath}/by-season`,
      data: request
    });
    const opportunity: OpportunityStudentListView | undefined = res.data && res.data['opportunity'] ? OpportunityStudentListView.fromJS(res.data) : undefined;
    yield put(opportunityLoadSuccess(opportunity));
  } catch (err) {
    yield put(opportunityLoadError(err));
  }
}

function* CurrentOpportunityLoad(request: OpportunityBySeasonRequest) {
  console.log('SAGA - opportunitySaga - CurrentOpportunityLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreOpportunitiesPath}/by-season`,
      data: request
    });
    const opportunity: OpportunityStudentListView | undefined = res.data && res.data['opportunity'] ? OpportunityStudentListView.fromJS(res.data) : undefined;
    yield put(currentOpportunityLoadSuccess(opportunity));
  } catch (err) {
    yield put(currentOpportunityLoadError(err));
  }
}

function* OpportunityStudentProfilesLoad(studentIds: string[]) {
  // Student request here, but have it in opportunities to ensure redux is isolated
  // can apply elsewhere for other use cases, but this is for opportunities on expectation
  // we will need to do more with what we send to contacts
  console.log('SAGA - opportunitySaga - OpportunityStudentProfilesLoad');
  try {
    const submission = { idList: studentIds };
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreStudentPath}/list`,
      data: submission
    });
    const result: PersonModel[] = res.data.map((x: any) => PersonModel.fromJS(x));
    yield put(opportunityStudentProfilesLoadSuccess(result));
  } catch (err) {
    yield put(opportunityStudentProfilesLoadError(err));
  }
}

function* OpportunitySave({submission, id}: ICommonSavePayload<OpportunityEditDetailModel>) {
  console.log('SAGA - opportunitySaga - OpportunitySave');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: id ? `${apiConfig.coreOpportunitiesPath}/${id}` : apiConfig.coreOpportunitiesPath,
      data: submission
    });
    const opportunity: OpportunityDetailModel = OpportunityDetailModel.fromJS(res.data);
    yield put(opportunitySaveSuccess(opportunity));
  } catch (err) {
    yield put(opportunitySaveError(err));
  }
}

export function* OpportunitiesSearch({ searchParams, page, sort}: ISearchRequest<OpportunitySearchParamsModel>) {
  console.log('SAGA - opportunitySaga - OpportunitiesSearch');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url:`${apiConfig.coreOpportunitiesPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const result: OpportunitiesSearchPaged = OpportunitiesSearchPaged.fromJS(res.data);
    yield put(opportunitySearchLoadSuccess(result));
  } catch (err) {
    yield put(opportunitySearchLoadError(err));
  }
}

function* OpportunitiesLocalitySearch({ searchParams, page, sort}: ISearchRequest<OpportunitySearchParamsModel>) {
  console.log('SAGA - poolSaga - OpportunitiesLocalitySearch');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url:`${apiConfig.coreOpportunitiesPath}/search/local?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const result: LocalOpportunitySearchItem[] = res.data.map((x: any) => LocalOpportunitySearchItem.fromJS(x));
    yield put(opportunityLocalitySearchLoadSuccess(result??[]));
  } catch (err) {
    yield put(opportunityLocalitySearchLoadError(err));
  }
}

function* CompanyOpportunitySearch({ searchParams, page, sort}: ISearchRequest<OpportunitySearchParamsModel>) {
  console.log('SAGA - opportunitySaga - CompanyOpportunitySearch');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url:`${apiConfig.coreOpportunitiesPath}/search?page=${page}&sort=${sort}&page_size=100`,
      data: searchParams
    });
    const result: OpportunitySearchPaged = OpportunitySearchPaged.fromJS(res.data);
    yield put(opportunityCompanySearchLoadSuccess(result?.result??[]));
  } catch (err) {
    yield put(opportunityCompanySearchLoadError(err));
  }
}

function* StudentOpportunitySeasonsLoad(studentId: string) {
  console.log('SAGA - opportunitySaga - StudentOpportunitySeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreStudentPath}/${studentId}/opportunity-seasons`,
    });
    const result: OpportunityDetailModel[] = res.data.map((x: any) => OpportunityDetailModel.fromJS(x));
    yield put(opportunityStudentSeasonsLoadSuccess(result));
  } catch (err) {
    yield put(opportunityStudentSeasonsLoadError(err));
  }
}

function* CompanyOpportunitySeasonsLoad(companyId: string) {
  console.log('SAGA - opportunitySaga - CompanyOpportunitySeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreCompaniesPath}/${companyId}/opportunity-seasons`,
    });
    const result: SeasonModel[] = res.data.map((x: any) => OpportunitySummaryModel.fromJS(x));
    yield put(opportunityCompanySeasonsLoadSuccess(result));
  } catch (err) {
    yield put(opportunityCompanySeasonsLoadError(err));
  }
}

function* ContactOpportunitySeasonsLoad(contactId: string) {
  console.log('SAGA - opportunitySaga - ContactOpportunitySeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreContactPath}/${contactId}/opportunity-seasons`,
    });
    const result: SeasonModel[] = res.data.map((x: any) => OpportunitySummaryModel.fromJS(x));
    yield put(opportunityContactSeasonsLoadSuccess(result));
  } catch (err) {
    yield put(opportunityContactSeasonsLoadError(err));
  }
}

export function* watchOpportunityLoad() {
  yield takeLatest(OPPORTUNITY.LOAD, ({payload}: PayloadAction<string>) => OpportunityLoad(payload))
}

export function* watchOpportunityLoadBySeason() {
  yield takeLatest(OPPORTUNITY.LOAD_BY_SEASON, ({payload}: PayloadAction<OpportunityBySeasonRequest>) => OpportunityLoadBySeason(payload))
}

export function* watchOpportunityRefresh() {
  yield takeLatest(OPPORTUNITY.REFRESH, ({payload}: PayloadAction<string>) => OpportunityLoad(payload))
}

export function* watchOpportunityRefreshBySeason() {
  yield takeLatest(OPPORTUNITY.REFRESH_BY_SEASON, ({payload}: PayloadAction<OpportunityBySeasonRequest>) => OpportunityLoadBySeason(payload))
}

export function* watchOpportunityStudentProfilesLoad() {
  yield takeLatest(OPPORTUNITY_STUDENT_PROFILES.LOAD, ({payload}: PayloadAction<string[]>) => OpportunityStudentProfilesLoad(payload))
}

export function* watchOpportunitySave() {
  yield takeLatest(OPPORTUNITY.SAVE, ({payload}: PayloadAction<ICommonSavePayload<OpportunityEditDetailModel>>) => OpportunitySave(payload))
}

export function* watchOpportunitySearchLoad() {
  yield takeLatest(OPPORTUNITY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => OpportunitiesSearch(payload))
}

export function* watchOpportunitySearchRefresh() {
  yield takeLatest(OPPORTUNITY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => OpportunitiesSearch(payload))
}

export function* watchOpportunityLocalitySearchLoad() {
  yield takeLatest(OPPORTUNITY_LOCALITY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => OpportunitiesLocalitySearch(payload))
}

export function* watchOpportunityLocalitySearchRefresh() {
  yield takeLatest(OPPORTUNITY_LOCALITY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => OpportunitiesLocalitySearch(payload))
}

export function* watchOpportunityCompanySearchLoad() {
  yield takeLatest(COMPANY_OPPORTUNITY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => CompanyOpportunitySearch(payload))
}

export function* watchOpportunityCompanySearchRefresh() {
  yield takeLatest(COMPANY_OPPORTUNITY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<OpportunitySearchParamsModel>>) => CompanyOpportunitySearch(payload))
}

export function* watchOpportunityStudentSeasonsLoad() {
  yield takeLatest(STUDENT_OPPORTUNITY_SEASONS.LOAD, ({payload}: PayloadAction<string>) => StudentOpportunitySeasonsLoad(payload))
}

export function* watchOpportunityCompanySeasonsLoad() {
  yield takeLatest(COMPANY_OPPORTUNITY_SEASONS.LOAD, ({payload}: PayloadAction<string>) => CompanyOpportunitySeasonsLoad(payload))
}

export function* watchOpportunityCompanySeasonsRefresh() {
  yield takeLatest(COMPANY_OPPORTUNITY_SEASONS.REFRESH, ({payload}: PayloadAction<string>) => CompanyOpportunitySeasonsLoad(payload))
}

export function* watchOpportunityContactSeasonsLoad() {
  yield takeLatest(CONTACT_OPPORTUNITY_SEASONS.LOAD, ({payload}: PayloadAction<string>) => ContactOpportunitySeasonsLoad(payload))
}

export function* watchOpportunityContactSeasonsRefresh() {
  yield takeLatest(CONTACT_OPPORTUNITY_SEASONS.REFRESH, ({payload}: PayloadAction<string>) => ContactOpportunitySeasonsLoad(payload))
}

export function* watchCurrentOpportunityLoad() {
  yield takeLatest(CURRENT_OPPORTUNITY.LOAD, ({payload}: PayloadAction<OpportunityBySeasonRequest>) => CurrentOpportunityLoad(payload))
}


export default function* opportunitySaga() {
  yield all ([
    call(watchOpportunityLoadBySeason),
    call(watchOpportunityLoad),
    call(watchOpportunityStudentProfilesLoad),
    call(watchOpportunityRefresh),
    call(watchOpportunityRefreshBySeason),
    call(watchOpportunitySave),
    call(watchOpportunitySearchLoad),
    call(watchOpportunitySearchRefresh),
    call(watchOpportunityLocalitySearchLoad),
    call(watchOpportunityLocalitySearchRefresh),
    call(watchOpportunityCompanySearchLoad),
    call(watchOpportunityCompanySearchRefresh),
    call(watchOpportunityStudentSeasonsLoad),
    call(watchOpportunityCompanySeasonsLoad),
    call(watchOpportunityCompanySeasonsRefresh),
    call(watchOpportunityContactSeasonsLoad),
    call(watchOpportunityContactSeasonsRefresh),
    call(watchCurrentOpportunityLoad),
  ])
}
