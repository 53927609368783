export interface IClientOnboardingHistoryModel {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  dateEvent?: Date | undefined;
  eventId?: string | undefined;
  stepId?: string | undefined;
  stepTitle?: string | undefined;
  /** The person who did the event. Not always a user */
  eventPersonId?: string | undefined;
  /** Any additional notes */
  additionalInformation?: string | undefined;
  /** Files uploaded to the file store service */
  fileIds?: string[] | undefined;
  skipped?: boolean | undefined;
}

export class ClientPartnerTagModel implements IClientPartnerTagModel {
  tag?: string | undefined;
  dateAdded?: string | undefined;

  constructor(data?: IClientPartnerTagModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.tag = Data["tag"];
      this.dateAdded = Data["dateAdded"];
    }
  }

  static fromJS(data: any): ClientPartnerTagModel {
    data = typeof data === 'object' ? data : {};
    let result = new ClientPartnerTagModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["tag"] = this.tag;
    data["dateAdded"] = this.dateAdded;
    return data;
  }
}

export interface IClientPartnerTagModel {
  tag?: string | undefined;
  dateAdded?: string | undefined;
}