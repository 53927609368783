import {SubTeamDetailModel} from './SubTeamDetailModel';

export class TeamDetailModel implements ITeamDetailModel {
  id?: string | undefined;
  createdByUserId?: string | undefined;
  /** Descriptive title */
  title?: string | undefined;
  /** A short name for this team (eg NSW) */
  shortTitle?: string | undefined;
  isDisabled?: boolean | undefined;
  dateDisabled?: Date | undefined;
  disabledByUserId?: string | undefined;
  subTeams?: SubTeamDetailModel[] | undefined;

  constructor(data?: ITeamDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.createdByUserId = Data["createdByUserId"];
      this.title = Data["title"];
      this.shortTitle = Data["shortTitle"];
      this.isDisabled = Data["isDisabled"];
      this.dateDisabled = Data["dateDisabled"] ? new Date(Data["dateDisabled"].toString()) : <any>undefined;
      this.disabledByUserId = Data["disabledByUserId"];
      if (Array.isArray(Data["subTeams"])) {
        this.subTeams = [] as any;
        for (let item of Data["subTeams"])
          this.subTeams!.push(SubTeamDetailModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): TeamDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new TeamDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createdByUserId"] = this.createdByUserId;
    data["title"] = this.title;
    data["shortTitle"] = this.shortTitle;
    data["isDisabled"] = this.isDisabled;
    data["dateDisabled"] = this.dateDisabled ? this.dateDisabled.toISOString() : <any>undefined;
    data["disabledByUserId"] = this.disabledByUserId;
    if (Array.isArray(this.subTeams)) {
      data["subTeams"] = [];
      for (let item of this.subTeams)
        data["subTeams"].push(item.toJSON());
    }
    return data;
  }
}

export interface ITeamDetailModel {
  id?: string | undefined;
  createdByUserId?: string | undefined;
  /** Descriptive title */
  title?: string | undefined;
  /** A short name for this team (eg NSW) */
  shortTitle?: string | undefined;
  isDisabled?: boolean | undefined;
  dateDisabled?: Date | undefined;
  disabledByUserId?: string | undefined;
  subTeams?: SubTeamDetailModel[] | undefined;
}