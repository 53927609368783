export class RecipientCompany implements IRecipientCompany {
  companyId?: string | undefined;
  companyName?: string | undefined;

  constructor(data?: IRecipientCompany) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.companyId = Data["companyId"];
      this.companyName = Data["companyName"];
    }
  }

  static fromJS(data: any): RecipientCompany {
    data = typeof data === 'object' ? data : {};
    let result = new RecipientCompany();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["companyId"] = this.companyId;
    data["companyName"] = this.companyName;
    return data;
  }
}

export interface IRecipientCompany {
  companyId?: string | undefined;
  companyName?: string | undefined;
}