import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {emailTemplatePaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const formPage = React.lazy(() => import('./EmailTemplateEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Email templates", emailTemplatePaths.EmailTemplateSettings)];

const emailTemplateRoutes = [
  new AppRouteProps("Manage", emailTemplatePaths.EmailTemplateSettings, React.lazy(() => import('./EmailTemplateResults')), sectionBreadcrumbs),
  new AppRouteProps("Create", emailTemplatePaths.EmailTemplateCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit", emailTemplatePaths.EmailTemplateEdit, formPage, sectionBreadcrumbs),
]

const emailTemplateRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, emailTemplateRoutes)
];

export default emailTemplateRoutesConfig;
