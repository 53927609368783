import {OpportunityDetailModel} from '../models/OpportunityDetailModel';
import {StudentPoolItem} from './StudentPoolItem';
import {OpportunityStudentView} from '../models/OpportunityStudentView';

export class StudentOpportunityListView implements IStudentOpportunityListView {
  opportunity?: OpportunityDetailModel | undefined;
  opportunityStudent?: OpportunityStudentView | undefined;
  poolStudents?: StudentPoolItem[] | undefined;

  constructor(data?: IStudentOpportunityListView) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.opportunity = Data["opportunity"] ? OpportunityDetailModel.fromJS(Data["opportunity"]) : <any>undefined;
      this.opportunityStudent = Data["opportunityStudent"] ? OpportunityStudentView.fromJS(Data["opportunityStudent"]) : <any>undefined;
      if (Array.isArray(Data["poolStudents"])) {
        this.poolStudents = [] as any;
        for (let item of Data["poolStudents"])
          this.poolStudents!.push(StudentPoolItem.fromJS(item));
      }
    }
  }

  static fromJS(data: any): StudentOpportunityListView {
    data = typeof data === 'object' ? data : {};
    let result = new StudentOpportunityListView();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["opportunity"] = this.opportunity ? this.opportunity.toJSON() : <any>undefined;
    data["opportunityStudent"] = this.opportunityStudent ? this.opportunityStudent.toJSON() : <any>undefined;
    if (Array.isArray(this.poolStudents)) {
      data["poolStudents"] = [];
      for (let item of this.poolStudents)
        data["poolStudents"].push(item.toJSON());
    }
    return data;
  }
}

export interface IStudentOpportunityListView {
  opportunity?: OpportunityDetailModel | undefined;
  opportunityStudent?: OpportunityStudentView | undefined;
  poolStudents?: StudentPoolItem[] | undefined;
}