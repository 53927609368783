export class JobErrorModel implements IJobErrorModel {
  jobId?: string | undefined;
  dateCreated?: Date | undefined;
  failedEntityId?: string | undefined;
  entityType?: string | undefined;
  errorMessage?: string | undefined;
  exceptionType?: string | undefined;

  constructor(data?: IJobErrorModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.jobId = Data["jobId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.failedEntityId = Data["failedEntityId"];
      this.entityType = Data["entityType"];
      this.errorMessage = Data["errorMessage"];
      this.exceptionType = Data["exceptionType"];
    }
  }

  static fromJS(data: any): JobErrorModel {
    data = typeof data === 'object' ? data : {};
    let result = new JobErrorModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["jobId"] = this.jobId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["failedEntityId"] = this.failedEntityId;
    data["entityType"] = this.entityType;
    data["errorMessage"] = this.errorMessage;
    data["exceptionType"] = this.exceptionType;
    return data;
  }
}

export interface IJobErrorModel {
  jobId?: string | undefined;
  dateCreated?: Date | undefined;
  failedEntityId?: string | undefined;
  entityType?: string | undefined;
  errorMessage?: string | undefined;
  exceptionType?: string | undefined;
}