import {IContactState} from './Reducer';
import {createSelector} from '@reduxjs/toolkit';

export const selectContactsState = (state: { contacts: IContactState }) => state.contacts;

export const selectContactSearchParams = createSelector(
  selectContactsState,
  (subState) => subState.contactSearchParams
);
export const selectContactSearchView = createSelector(
  selectContactsState,
  (subState) => subState.contactSearchView
);
export const selectContactSearchSort = createSelector(
  selectContactsState,
  (subState) => subState.contactSearchSort
);
export const selectContactResultLoading = createSelector(
  selectContactsState,
  (subState) => subState.contactResultLoading
);
export const selectContactResultError = createSelector(
  selectContactsState,
  (subState) => subState.contactResultError
);
export const selectContactResult = createSelector(
  selectContactsState,
  (subState) => subState.contactResult
);

export const selectContactLoading = createSelector(
  selectContactsState,
  (subState) => subState.contactLoading
);
export const selectContactError = createSelector(
  selectContactsState,
  (subState) => subState.contactError
);
export const selectContactSaving = createSelector(
  selectContactsState,
  (subState) => subState.contactSaving
);
export const selectContactSaveError = createSelector(
  selectContactsState,
  (subState) => subState.contactSaveError
);
export const selectContactDetail = createSelector(
  selectContactsState,
  (subState) => subState.contactDetail
);
export const selectEditContactDetail = createSelector(
  selectContactsState,
  (subState) => subState.contactEditDetail
);