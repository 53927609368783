import {HSPPoolStudentView} from '../models/HSPPoolStudentView';
import {HSPPoolSummary} from './HSPPoolSummary';
import {HSPPlacementDetailModel} from '../models/HSPPlacementDetailModel';
import {HSPSeasonalStudentModel} from '../models/HSPSeasonalStudentModel';

export class HSPPoolItem implements IHSPPoolItem {
  poolStudent?: HSPPoolStudentView | undefined;
  pool?: HSPPoolSummary | undefined;
  placement?: HSPPlacementDetailModel[] | undefined;
  seasonalStudent?: HSPSeasonalStudentModel | undefined;
  isHidden?: boolean | undefined;

  constructor(data?: IHSPPoolItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.poolStudent = Data["poolStudent"] ? HSPPoolStudentView.fromJS(Data["poolStudent"]) : <any>undefined;
      this.pool = Data["pool"] ? HSPPoolSummary.fromJS(Data["pool"]) : <any>undefined;
      if (Array.isArray(Data["placement"])) {
        this.placement = [] as any;
        for (let item of Data["placement"])
          this.placement!.push(HSPPlacementDetailModel.fromJS(item));
      }
      this.seasonalStudent = Data["seasonalStudent"] ? HSPSeasonalStudentModel.fromJS(Data["seasonalStudent"]) : <any>undefined;
      this.isHidden = Data["isHidden"];
    }
  }

  static fromJS(data: any): HSPPoolItem {
    data = typeof data === 'object' ? data : {};
    let result = new HSPPoolItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["poolStudent"] = this.poolStudent ? this.poolStudent.toJSON() : <any>undefined;
    data["pool"] = this.pool ? this.pool.toJSON() : <any>undefined;
    if (Array.isArray(this.placement)) {
      data["placement"] = [];
      for (let item of this.placement)
        data["placement"].push(item.toJSON());
    }
    data["seasonalStudent"] = this.seasonalStudent ? this.seasonalStudent.toJSON() : <any>undefined;
    data["isHidden"] = this.isHidden;
    return data;
  }
}

export interface IHSPPoolItem {
  poolStudent?: HSPPoolStudentView | undefined;
  pool?: HSPPoolSummary | undefined;
  placement?: HSPPlacementDetailModel[] | undefined;
  seasonalStudent?: HSPSeasonalStudentModel | undefined;
  isHidden?: boolean | undefined;
}