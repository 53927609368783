import {formatDate} from '../../@template/helpers/datetimes';

const defaultStudentStatusFilter = ['Potential', 'Pipeline', 'Intern'];

export const defaultInternshipSearchParams = () => {
  return InternshipSearchParams.fromJS({
    searchText: '',
    statusFlags: defaultStudentStatusFilter,
    advisorUsers: [],
    alumniStatusFlags: [],
    includeAlumni: false,
    primaryOnly: true,
    advisorSelected: false,
    excludePrimarySelected: false,
    noPrimaryAppointed: false,
    noAdvisorAppointed: false,
    byTeam: false,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    companyIds: [],
    studentTags: [],
    degreePoolId: [],
    majorSearchText: '',
    university: [],
    campus: [],
    state: [],
    countryCode: 'AU',
    contactSearchText: '',
    includeInactive: false,
    icsStatus: [],
    atRiskOnly: false,
    isUniversityPlacement: false,
    isPreUniversityPlacement: false,
    wasWorkShadow: false,

    program: undefined,
    managerPersonIds: [],
    notArchived: true,
    includeArchived: false,
    onlyArchived: false,
    includeCancelled: false,
    includeCompleted: false,
    placementType: [],
    isTravelRequired: false,
    travelledTo: '',
  });
}


// Generated
export class InternshipSearchParams implements IInternshipSearchParams {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  alumniStatusFlags?: string[] | undefined;
  includeAlumni?: boolean | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  yearStartFrom?: number | undefined;
  seasonStartFrom?: number | undefined;
  yearStartTo?: number | undefined;
  seasonStartTo?: number | undefined;
  yearEndFrom?: number | undefined;
  seasonEndFrom?: number | undefined;
  yearEndTo?: number | undefined;
  seasonEndTo?: number | undefined;
  studentTags?: string[] | undefined;
  degreePoolId?: string[] | undefined;
  majorSearchText?: string | undefined;
  companyIds?: string[] | undefined;
  university?: string[] | undefined;
  campus?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  atRiskOnly?: boolean | undefined;
  icsStatus?: string[] | undefined;
  placedFromYear?: number | undefined;
  placedFromSeason?: number | undefined;
  placedToYear?: number | undefined;
  placedToSeason?: number | undefined;
  travelFromYear?: number | undefined;
  travelFromSemester?: number | undefined;
  travelToYear?: number | undefined;
  travelToSemester?: number | undefined;
  isUniversityPlacement?: boolean | undefined;
  isPreUniversityPlacement?: boolean | undefined;
  wasWorkShadow?: boolean | undefined;
  program?: string | undefined;
  managerPersonIds?: string[] | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  createdFrom?: Date | undefined;
  createdTo?: Date | undefined;
  progressFrom?: number | undefined;
  progressTo?: number | undefined;
  includeCancelled?: boolean | undefined;
  includeCompleted?: boolean | undefined;
  seasonFromSeason?: number | undefined;
  seasonFromYear?: number | undefined;
  seasonToYear?: number | undefined;
  seasonToSeason?: number | undefined;
  placementType?: string[] | undefined;
  isTravelRequired?: boolean | undefined;
  travelledTo?: string | undefined;

  constructor(data?: IInternshipSearchParams) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      if (Array.isArray(Data["statusFlags"])) {
        this.statusFlags = [] as any;
        for (let item of Data["statusFlags"])
          this.statusFlags!.push(item);
      }
      if (Array.isArray(Data["alumniStatusFlags"])) {
        this.alumniStatusFlags = [] as any;
        for (let item of Data["alumniStatusFlags"])
          this.alumniStatusFlags!.push(item);
      }
      this.includeAlumni = Data["includeAlumni"];
      this.joinedFrom = Data["joinedFrom"] ? new Date(Data["joinedFrom"].toString()) : <any>undefined;
      this.joinedTo = Data["joinedTo"] ? new Date(Data["joinedTo"].toString()) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data["primaryOnly"];
      this.advisorSelected = Data["advisorSelected"];
      this.excludePrimarySelected = Data["excludePrimarySelected"];
      this.noPrimaryAppointed = Data["noPrimaryAppointed"];
      this.noAdvisorAppointed = Data["noAdvisorAppointed"];
      this.byTeam = Data["byTeam"];
      this.advisorTeamId = Data["advisorTeamId"];
      this.advisorSubTeamId = Data["advisorSubTeamId"];
      this.byAdvisorTeam = Data["byAdvisorTeam"];
      this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
      this.yearStartFrom = Data["yearStartFrom"];
      this.seasonStartFrom = Data["seasonStartFrom"];
      this.yearStartTo = Data["yearStartTo"];
      this.seasonStartTo = Data["seasonStartTo"];
      this.yearEndFrom = Data["yearEndFrom"];
      this.seasonEndFrom = Data["seasonEndFrom"];
      this.yearEndTo = Data["yearEndTo"];
      this.seasonEndTo = Data["seasonEndTo"];
      if (Array.isArray(Data["studentTags"])) {
        this.studentTags = [] as any;
        for (let item of Data["studentTags"])
          this.studentTags!.push(item);
      }
      if (Array.isArray(Data["degreePoolId"])) {
        this.degreePoolId = [] as any;
        for (let item of Data["degreePoolId"])
          this.degreePoolId!.push(item);
      }
      this.majorSearchText = Data["majorSearchText"];
      if (Array.isArray(Data["companyIds"])) {
        this.companyIds = [] as any;
        for (let item of Data["companyIds"])
          this.companyIds!.push(item);
      }
      if (Array.isArray(Data["university"])) {
        this.university = [] as any;
        for (let item of Data["university"])
          this.university!.push(item);
      }
      if (Array.isArray(Data["campus"])) {
        this.campus = [] as any;
        for (let item of Data["campus"])
          this.campus!.push(item);
      }
      if (Array.isArray(Data["state"])) {
        this.state = [] as any;
        for (let item of Data["state"])
          this.state!.push(item);
      }
      this.countryCode = Data["countryCode"];
      this.contactSearchText = Data["contactSearchText"];
      this.atRiskOnly = Data["atRiskOnly"];
      if (Array.isArray(Data["icsStatus"])) {
        this.icsStatus = [] as any;
        for (let item of Data["icsStatus"])
          this.icsStatus!.push(item);
      }
      this.placedFromYear = Data["placedFromYear"];
      this.placedFromSeason = Data["placedFromSeason"];
      this.placedToYear = Data["placedToYear"];
      this.placedToSeason = Data["placedToSeason"];
      this.isTravelRequired = Data["isTravelRequired"];
      this.travelFromYear = Data["travelFromYear"];
      this.travelFromSemester = Data["travelFromSemester"];
      this.travelToYear = Data["travelToYear"];
      this.travelToSemester = Data["travelToSemester"];
      this.isUniversityPlacement = Data["isUniversityPlacement"];
      this.isPreUniversityPlacement = Data["isPreUniversityPlacement"];
      this.wasWorkShadow = Data["wasWorkShadow"];
      this.program = Data["program"];
      this.managerPersonIds = Data["managerPersonIds"];
      this.notArchived = Data["notArchived"];
      this.includeArchived = Data["includeArchived"];
      this.onlyArchived = Data["onlyArchived"];
      this.createdFrom = Data["createdFrom"] ? new Date(Data["createdFrom"].toString()) : <any>undefined;
      this.createdTo = Data["createdTo"] ? new Date(Data["createdTo"].toString()) : <any>undefined;
      this.progressFrom = Data["progressFrom"];
      this.progressTo = Data["progressTo"];
      this.includeCancelled = Data["includeCancelled"];
      this.includeCompleted = Data["includeCompleted"];
      this.seasonFromSeason = Data["seasonFromSeason"];
      this.seasonFromYear = Data["seasonFromYear"];
      this.seasonToYear = Data["seasonToYear"];
      this.seasonToSeason = Data["seasonToSeason"];
      if (Array.isArray(Data["placementType"])) {
        this.placementType = [] as any;
        for (let item of Data["placementType"])
          this.placementType!.push(item);
      }
      this.travelledTo = Data["travelledTo"];
    }
  }

  static fromJS(data: any): InternshipSearchParams {
    data = typeof data === 'object' ? data : {};
    let result = new InternshipSearchParams();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    if (Array.isArray(this.statusFlags)) {
      data["statusFlags"] = [];
      for (let item of this.statusFlags)
        data["statusFlags"].push(item);
    }
    if (Array.isArray(this.alumniStatusFlags)) {
      data["alumniStatusFlags"] = [];
      for (let item of this.alumniStatusFlags)
        data["alumniStatusFlags"].push(item);
    }
    data["includeAlumni"] = this.includeAlumni;
    data["joinedFrom"] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
    data["joinedTo"] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryOnly"] = this.primaryOnly;
    data["advisorSelected"] = this.advisorSelected;
    data["excludePrimarySelected"] = this.excludePrimarySelected;
    data["noPrimaryAppointed"] = this.noPrimaryAppointed;
    data["noAdvisorAppointed"] = this.noAdvisorAppointed;
    data["byTeam"] = this.byTeam;
    data["advisorTeamId"] = this.advisorTeamId;
    data["advisorSubTeamId"] = this.advisorSubTeamId;
    data["byAdvisorTeam"] = this.byAdvisorTeam;
    data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
    data["yearStartFrom"] = this.yearStartFrom;
    data["seasonStartFrom"] = this.seasonStartFrom;
    data["yearStartTo"] = this.yearStartTo;
    data["seasonStartTo"] = this.seasonStartTo;
    data["yearEndFrom"] = this.yearEndFrom;
    data["seasonEndFrom"] = this.seasonEndFrom;
    data["yearEndTo"] = this.yearEndTo;
    data["seasonEndTo"] = this.seasonEndTo;
    if (Array.isArray(this.studentTags)) {
      data["studentTags"] = [];
      for (let item of this.studentTags)
        data["studentTags"].push(item);
    }
    if (Array.isArray(this.degreePoolId)) {
      data["degreePoolId"] = [];
      for (let item of this.degreePoolId)
        data["degreePoolId"].push(item);
    }
    data["majorSearchText"] = this.majorSearchText;
    if (Array.isArray(this.companyIds)) {
      data["companyIds"] = [];
      for (let item of this.companyIds)
        data["companyIds"].push(item);
    }
    if (Array.isArray(this.university)) {
      data["university"] = [];
      for (let item of this.university)
        data["university"].push(item);
    }
    if (Array.isArray(this.campus)) {
      data["campus"] = [];
      for (let item of this.campus)
        data["campus"].push(item);
    }
    if (Array.isArray(this.state)) {
      data["state"] = [];
      for (let item of this.state)
        data["state"].push(item);
    }
    data["countryCode"] = this.countryCode;
    data["contactSearchText"] = this.contactSearchText;
    data["atRiskOnly"] = this.atRiskOnly;
    if (Array.isArray(this.icsStatus)) {
      data["icsStatus"] = [];
      for (let item of this.icsStatus)
        data["icsStatus"].push(item);
    }
    data["placedFromYear"] = this.placedFromYear;
    data["placedFromSeason"] = this.placedFromSeason;
    data["placedToYear"] = this.placedToYear;
    data["placedToSeason"] = this.placedToSeason;
    data["isTravelRequired"] = this.isTravelRequired;
    data["travelFromYear"] = this.travelFromYear;
    data["travelFromSemester"] = this.travelFromSemester;
    data["travelToYear"] = this.travelToYear;
    data["travelToSemester"] = this.travelToSemester;
    data["isUniversityPlacement"] = this.isUniversityPlacement;
    data["isPreUniversityPlacement"] = this.isPreUniversityPlacement;
    data["wasWorkShadow"] = this.wasWorkShadow;
    data["program"] = this.program;
    data["managerPersonIds"] = this.managerPersonIds;
    data["notArchived"] = this.notArchived;
    data["includeArchived"] = this.includeArchived;
    data["onlyArchived"] = this.onlyArchived;
    data["createdFrom"] = this.createdFrom ? formatDate(this.createdFrom) : <any>undefined;
    data["createdTo"] = this.createdTo ? formatDate(this.createdTo) : <any>undefined;
    data["progressFrom"] = this.progressFrom;
    data["progressTo"] = this.progressTo;
    data["includeCancelled"] = this.includeCancelled;
    data["includeCompleted"] = this.includeCompleted;
    data["seasonFromSeason"] = this.seasonFromSeason;
    data["seasonFromYear"] = this.seasonFromYear;
    data["seasonToYear"] = this.seasonToYear;
    data["seasonToSeason"] = this.seasonToSeason;
    if (Array.isArray(this.placementType)) {
      data["placementType"] = [];
      for (let item of this.placementType)
        data["placementType"].push(item);
    }
    data["travelledTo"] = this.travelledTo;
    return data;
  }
}

export interface IInternshipSearchParams {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  alumniStatusFlags?: string[] | undefined;
  includeAlumni?: boolean | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  yearStartFrom?: number | undefined;
  seasonStartFrom?: number | undefined;
  yearStartTo?: number | undefined;
  seasonStartTo?: number | undefined;
  yearEndFrom?: number | undefined;
  seasonEndFrom?: number | undefined;
  yearEndTo?: number | undefined;
  seasonEndTo?: number | undefined;
  studentTags?: string[] | undefined;
  degreePoolId?: string[] | undefined;
  majorSearchText?: string | undefined;
  companyIds?: string[] | undefined;
  university?: string[] | undefined;
  campus?: string[] | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  atRiskOnly?: boolean | undefined;
  icsStatus?: string[] | undefined;
  placedFromYear?: number | undefined;
  placedFromSeason?: number | undefined;
  placedToYear?: number | undefined;
  placedToSeason?: number | undefined;
  travelFromYear?: number | undefined;
  travelFromSemester?: number | undefined;
  travelToYear?: number | undefined;
  travelToSemester?: number | undefined;
  isUniversityPlacement?: boolean | undefined;
  isPreUniversityPlacement?: boolean | undefined;
  wasWorkShadow?: boolean | undefined;
  program?: string | undefined;
  managerPersonIds?: string[] | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  createdFrom?: Date | undefined;
  createdTo?: Date | undefined;
  progressFrom?: number | undefined;
  progressTo?: number | undefined;
  includeCancelled?: boolean | undefined;
  includeCompleted?: boolean | undefined;
  seasonFromSeason?: number | undefined;
  seasonFromYear?: number | undefined;
  seasonToYear?: number | undefined;
  seasonToSeason?: number | undefined;
  placementType?: string[] | undefined;
  isTravelRequired?: boolean | undefined;
  travelledTo?: string | undefined;
}
