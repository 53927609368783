import {UniversityModel} from '../../types/models/UniversityModel';
import {ReactNode} from 'react';

export const isEmptyOrWhitespace = (value: string | undefined | null) => {
  return (!value || value.length == 0 || value.match(/^ *$/) !== null);
};

export const toSoftBrokenString = (value: string | undefined | null, breakChars = 10) => {
  if (!value) {
    return '';
  } else {
    const hyphen = String.fromCharCode(0x00AD);
    const unbroken = new RegExp('([^\\s-]{' + breakChars + '})', 'g');
    return value.replace(unbroken, '$&'+hyphen);
  }
};

export const splitCase = (value: string | undefined) => {
  if (!value) {
    return '';
  }else {
    return value.replace(/([A-Z][a-z])/g, ' $1');
  }
};

export const truncate = (source: string, n: number, useWordBoundary: boolean, placeholder: string | undefined = ' ...') => {
  if (!source){ return ''; }
  if (source.length <= n) { return source; }
  let subString = source.substr(0, n-1);
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString) + placeholder;
};

export const capitalise = (value: string) => {
  return value[0].toUpperCase() + value.substring(1);
};

export const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/ig, ($1 : string) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const universityAbbreviation = (studentUni: string, allUnis: UniversityModel[]) => {
  if (!studentUni || !allUnis){
    return null;
  }
  const university = allUnis.find(uni => {
    return uni.title === studentUni;
  });
  if (university) {
    return university.abbreviation;
  }
  if (studentUni.length > 5) {
    return truncate(studentUni, 5, false);
  } else {
    return studentUni;
  }
};