import React, {useContext} from 'react';
import Avatar from '@mui/material/Avatar';
import {useDispatch} from 'react-redux';
import {authLogOut} from '../../../redux/auth/Actions';
import {useAuthUser} from '../../../@template/utility/AppHooks';
import AppContext from '../../../@template/utility/AppContext';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import {orange} from '@mui/material/colors';
import {Fonts, ThemeMode} from '../../constants/AppEnums';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {CurrentUserModel} from '../../../types/views/CurrentUserModel';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    ctUserInfo: {
      backgroundColor: 'rgba(0,0,0,.08)',
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    userInfo: {
      width: 'calc(100% - 16px)',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: Fonts.MEDIUM,
      color: '#313541',
    },
    designation: {
      marginTop: -2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: 'pointer',
    },
  };
});

const UserInfo: React.FC<{}> = () => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const dispatch = useDispatch();
  const user: CurrentUserModel | undefined = useAuthUser();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (user && user.name) {
      return user.name.charAt(0).toUpperCase();
    }
    if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const onLogOutClick = () => {
    localStorage.removeItem('INITIAL_URL');
    dispatch(authLogOut());
  }

  const classes = useStyles({themeMode});

  return (
    <Box
      px={{xs: 4}}
      className={clsx(classes.ctUserInfo, 'ct-user-info')}>
      <Box display='flex' alignItems='center'>
        {user && user.picture ? (
          <Avatar className={classes.profilePic} src={user.picture} />
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
        )}
        <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Box mb={0} className={clsx(classes.userName)}>
              {user?.name}
            </Box>
            {/* Leaving this here for now in case we move the logout button back here */}
            {/*<Box*/}
            {/*  ml={3}*/}
            {/*  className={classes.pointer}*/}
            {/*  color={themeMode === 'light' ? '#313541' : 'white'}>*/}
            {/*  <ExpandMoreIcon onClick={handleClick} />*/}
            {/*  <Menu*/}
            {/*    id='simple-menu'*/}
            {/*    anchorEl={anchorEl}*/}
            {/*    keepMounted*/}
            {/*    open={Boolean(anchorEl)}*/}
            {/*    onClose={handleClose}>*/}
            {/*    <MenuItem onClick={() => onLogOutClick()}>*/}
            {/*      Logout*/}
            {/*    </MenuItem>*/}
            {/*  </Menu>*/}
            {/*</Box>*/}
          </Box>
          <Box className={classes.designation}>{user && user.isAdmin ? 'Administrator' : user?.isManager ? 'Manager': 'Advisor'}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfo;
