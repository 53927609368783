import React, {useContext, useMemo} from 'react';
import {Icon, ListItem, ListItemText} from '@mui/material';
import clsx from 'clsx';
import {NavItemProps} from '../../../../modules/routesConfig';
import AppContext from '../../../utility/AppContext';
import useStyles from './VerticalHeader.style';
import {useAuthUser} from '../../../utility/AppHooks';
import {checkPermission} from '../../../utility/Utils';
import Box from '@mui/material/Box';
import {Badge} from '../../../index';

interface VerticalHeaderProps {
  item: NavItemProps;
  level: number;
}

const VerticalHeader: React.FC<VerticalHeaderProps> = ({item, level}) => {
  const {themeMode} = useContext(AppContext);
  const classes = useStyles({level, themeMode});
  const user = useAuthUser();

  const hasPermission = useMemo(() => checkPermission(item.auth, user?.isManager, user?.isAdmin), [
    item.auth,
    user,
  ]);
  if (!hasPermission) {
    return null;
  }
  return (
    <ListItem
      className={clsx(classes.navItem, 'nav-item')}
    >
      {item.icon && (
        <Box component='span' mr={6}>
          <Icon
            className={clsx(classes.listIcon, 'nav-item-icon')}
            color='action'>
            {item.icon}
          </Icon>
        </Box>
      )}
      <ListItemText
        primary={item.label}
        classes={{primary: clsx('nav-item-text', classes.listItemText), secondary: clsx('nav-item-text', classes.listItemText)}}
      />
      {item.count && (
        <Box mr={1}>
          <Badge count={item.count} color={item.color} />
        </Box>
      )}
    </ListItem>
  );
};

export default React.memo(VerticalHeader);
