export default {
  siteUrl: process.env.REACT_APP_SITE_URL,
};
export const employerUrl = process.env.REACT_APP_EMPLOYER_URL;
const apiConfig = {
  coreUrl: process.env.REACT_APP_CORE_URL,
  messagingUrl: process.env.REACT_APP_MESSAGING_URL,
  filesUrl: process.env.REACT_APP_FILES_URL,
  imagesUrl: process.env.REACT_APP_FILES_IMAGES_URL,
  uppyUrl: process.env.REACT_APP_UPPY_URL,
  messagingEventsPath: '/events/items',
  messagingInboxPath: '/inbox',
  messagingAggregatesPath: '/aggregates',
  coreAuthPath: '/auth',
  coreBrandsPath: '/brands',
  coreSettingsPath: '/settings',
  coreUserPath: '/users',
  coreContactPath: '/contacts',
  corePeoplePath: '/people',
  coreTeamsPath: '/teams',
  coreStudentPath: '/students',
  coreCompaniesPath: '/companies',
  corePoolsPath: '/pools',
  coreHSPPoolsPath: '/hsp-pools',
  coreOpportunitiesPath: '/opportunities',
  coreHSPOpportunitiesPath: '/hsp-opportunities',
  corePlacementsPath: '/placements',
  coreHSPPlacementsPath: '/hsp-placements',
  coreEngagementPath: '/engagement',
  coreReportingPath: '/reporting',
  coreVolunteerPath: '/volunteers',
  coreWorkShadowPath: '/workshadow',
  coreAlumniPath: '/alumni',
  coreJobsPath: '/jobs',
  coreMailingPath: '/mailing',
  coreMessagingPath: '/messaging',
  filesPath: '/files',
  imagesPath: '/images',
  tasksPath: '/tasks',
  threadMetadataPath: '/threadmetadata',
  utilityPath: '/utility',
};
const imageConfig = {
  imageSize: {
    imagesLarge: '/large'
  },
};
const googleConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_CONFIG_API_KEY
};
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const siteConfig = {
  siteName: 'Career Trackers',
  footerText: 'CareerTrackers © 2020',
  dateFormatLong: 'do MMM, yyyy',
  dateFormatShort: 'MMM do yyyy',
  dateTimeFormatLong: 'do MMM, yyyy, h:mm:ss a',
  dateFileFormat: 'yyyy_MM_dd_kkmmss',
  tokenExpiryMinutes: 45,
  debugFooter: process.env.REACT_APP_DEBUG_FOOTER
};
const uppyConfig = {
  target: '#uppyHolder',
  endpoint: apiConfig.filesUrl + apiConfig.filesPath,
  DashboardInline: true,
  Webcam: true,
  GoogleDrive: true,
  Dropbox: false,
  Instagram: true,
  autoProceed: true,
  restrictions: {
    maxFileSize: 20000000,
    maxNumberOfFiles: 5,
    minNumberOfFiles: 1,
    allowedFileTypes: ['image/*', 'video/*', 'text/*', '.doc', '.dot', '.pdf', '.rtf', '.docx', '.xls', '.xlt', '.xlm', '.xlsx', '.ppt', '.pptx', '.pptm', '.pub', '.zip', '.rar', '.PDF', '.PPT', '.PPTX', '.RAR', '.XLS', '.XLSX', '.DOC', '.DOCX', '.XLT', '.RTF', '.heic']
  },
};
const language = 'english';

const contextType = {
  INTERNAL: {
    STUDENT: 'InternalStudent',
    CONTACT: 'InternalContact',
    COMPANY: 'InternalCompany',
    POOL: 'InternalPool',
    PLACEMENT: 'InternalInternship',
    VOLUNTEER: 'InternalVolunteer',
    MAILING: 'InternalMailing',
  },
  EXTERNAL: {
    STUDENT: 'ExternalStudent',
    CONTACT: 'ExternalContact',
    COMPANY: 'ExternalCompany',
    POOL: 'ExternalPool',
    PLACEMENT: 'ExternalInternship',
  }
};

const actionConfig = {
  CATEGORIES: {
    STUDENT: 'student',
    CONTACT: 'contact',
    ADVISOR: 'advisor',
    POOL: 'pool',
    VOLUNTEER: 'volunteer',
    COMPANY: 'company',
    PLACEMENT: 'internship',
  }
};

const studentSources = [
  'AIME',
  'Clontarf',
  'Community Partner',
  'Company Referral',
  'CSIRO',
  'Dandjoo Darbalung',
  'Go Foundation',
  'High School Camp',
  'ICS Website',
  'NASCA',
  'NRLS2W',
  'Other',
  'Recruitment Event',
  'Salesforce',
  'Social Media',
  'Student Ambassador',
  'Student App',
  'Student Portal',
  'Student Referral',
  'University Referral',
  'Website Registration',
  'Word of Mouth',
  'Work Shadow Program'
];

const workShadowSources = [
  'Community Partner',
  'Company Referral',
  'CSIRO',
  'High School Camp',
  'ICS Website',
  'Other',
  'Recruitment Event',
  'Salesforce',
  'Social Media',
  'Student Ambassador',
  'Student App',
  'Student Portal',
  'Student Referral',
  'University Referral',
  'Website Registration',
  'Word of Mouth',
];

const registrationSources = [
  'AIME',
  'Cape York Leaders Program',
  'CareerTrackers Advisor',
  'CareerTrackers intern or Alumni',
  'Clontarf',
  'Community Partner',
  'CSIRO',
  'Dandjoo Darbalung',
  'Family member / Friend',
  'Go Foundation',
  'High School (including Teachers)',
  'Internet / Google search',
  'NASCA',
  'NRLS2W',
  'Other',
  'Salesforce',
  'Social Media',
  'University (including uni staff)'
];

const customAusStateConfig = {
  // NOTE: ordering here matters for reporting table order
  National: 'National', // needed for reporting
  nsw: 'NSW/ACT',
  act: 'NSW/ACT',
  qld: 'QLD/NT',
  nt: 'QLD/NT',
  vic: 'VIC/TAS',
  tas: 'VIC/TAS',
  sa: 'SA',
  wa: 'WA'
};

const reportingLabels = new Map([
  [ 'billable', 'Billable' ],
  [ 'ten_by_ten', '10x10' ],
  [ 'non_billable', 'Non-Billable' ],
  [ 'total', 'Total' ]
]);
// This should sync up with the core api config
const seasonConfig = {
  SEASON_2_START_MONTH: 12,
  SEASON_2_START_DATE: 1,
  SEASON_1_START_MONTH: 6,
  SEASON_1_START_DATE: 1,
  SEASON_2_END_MONTH: 2,
  SEASON_2_END_DATE: 15,
  SEASON_1_END_MONTH: 7,
  SEASON_1_END_DATE: 15,
};

// Variables subsituted at email time. This config should be the same as employer portal, student portal and advisor platform
const emailVariablesConfig = [
  {
    variable: '{{to_name}}',
    placeholder: 'Recipient'
  },
  {
    variable: '{{manager_first_name}}',
    placeholder: 'Recipients'
  },
  {
    variable: '{{from_name}}',
    placeholder: 'Sender'
  },
  {
    variable: '{{link}}',
    placeholder: '[link here](https://www.careertrackers.org.au/)'
  }
]

const emailVariableSubstitutionActionsConfig = [
  'OpportunityStudentsSent',
  'StudentOpportunityShared',
  'CompanyStudentsSent',
  'StudentSendLearningContract',
  'StudentSendPreUniversityLearningContract',
  'InternshipStudentSendLearningContract',
  'InternshipStudentSendPreUniversityLearningContract',
]


export {
  uppyConfig,
  imageConfig,
  googleConfig,
  siteConfig,
  apiConfig,
  language,
  firebaseConfig,
  contextType,
  actionConfig,
  studentSources,
  customAusStateConfig,
  workShadowSources,
  reportingLabels,
  seasonConfig,
  emailVariablesConfig,
  emailVariableSubstitutionActionsConfig,
  registrationSources
};
