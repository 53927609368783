import { OpportunityPoolDetailModel } from 'types/views/OpportunityPoolDetailModel';
import {AdvisorUserModel} from './AdvisorUserModel';
import {OpportunityContactPersonModel} from './OpportunityContactPersonModel';
import {OpportunityStudentView} from './OpportunityStudentView';

export class OpportunityDetailModel implements IOpportunityDetailModel {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  startingPositions?: number | undefined;
  remainingPositions?: number | undefined;
  calculatedRemainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  tenByTenPositions?: number | undefined;
  remainingTenByTenPositions?: number | undefined;
  billablePositions?: number | undefined;
  remainingBillablePositions?: number | undefined;
  nonBillablePositions?: number | undefined;
  remainingNonBillablePositions?: number | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  contactPeople?: OpportunityContactPersonModel[] | undefined;
  opportunityStudents?: OpportunityStudentView[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  pools?: OpportunityPoolDetailModel[] | undefined;

  constructor(data?: IOpportunityDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyId = Data["companyId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.startingPositions = Data["startingPositions"];
      this.remainingPositions = Data["remainingPositions"];
      this.calculatedRemainingPositions = Data["calculatedRemainingPositions"];
      this.shortlistedStudentsCount = Data["shortlistedStudentsCount"];
      this.studentsMatched = Data["studentsMatched"];
      this.tenByTenPositions = Data["tenByTenPositions"];
      this.remainingTenByTenPositions = Data["remainingTenByTenPositions"];
      this.billablePositions = Data["billablePositions"];
      this.remainingBillablePositions = Data["remainingBillablePositions"];
      this.nonBillablePositions = Data["nonBillablePositions"];
      this.remainingNonBillablePositions = Data["remainingNonBillablePositions"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(AdvisorUserModel.fromJS(item));
      }
      if (Array.isArray(Data["contactPeople"])) {
        this.contactPeople = [] as any;
        for (let item of Data["contactPeople"])
          this.contactPeople!.push(OpportunityContactPersonModel.fromJS(item));
      }
      if (Array.isArray(Data["opportunityStudents"])) {
        this.opportunityStudents = [] as any;
        for (let item of Data["opportunityStudents"])
          this.opportunityStudents!.push(OpportunityStudentView.fromJS(item));
      }
      this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      if (Array.isArray(Data["pools"])) {
        this.pools = [] as any;
        for (let item of Data["pools"])
          this.pools!.push(OpportunityPoolDetailModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): OpportunityDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new OpportunityDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyId"] = this.companyId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["startingPositions"] = this.startingPositions;
    data["remainingPositions"] = this.remainingPositions;
    data["calculatedRemainingPositions"] = this.calculatedRemainingPositions;
    data["shortlistedStudentsCount"] = this.shortlistedStudentsCount;
    data["studentsMatched"] = this.studentsMatched;
    data["tenByTenPositions"] = this.tenByTenPositions;
    data["remainingTenByTenPositions"] = this.remainingTenByTenPositions;
    data["billablePositions"] = this.billablePositions;
    data["remainingBillablePositions"] = this.remainingBillablePositions;
    data["nonBillablePositions"] = this.nonBillablePositions;
    data["remainingNonBillablePositions"] = this.remainingNonBillablePositions;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item.toJSON());
    }
    if (Array.isArray(this.contactPeople)) {
      data["contactPeople"] = [];
      for (let item of this.contactPeople)
        data["contactPeople"].push(item.toJSON());
    }
    if (Array.isArray(this.opportunityStudents)) {
      data["opportunityStudents"] = [];
      for (let item of this.opportunityStudents)
        data["opportunityStudents"].push(item.toJSON());
    }
    data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    if (Array.isArray(this.pools)) {
      data["pools"] = [];
      for (let item of this.pools)
        data["pools"].push(item.toJSON());
    }
    return data;
  }
}

export interface IOpportunityDetailModel {
  id?: string | undefined;
  companyId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  startingPositions?: number | undefined;
  remainingPositions?: number | undefined;
  calculatedRemainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  tenByTenPositions?: number | undefined;
  remainingTenByTenPositions?: number | undefined;
  billablePositions?: number | undefined;
  remainingBillablePositions?: number | undefined;
  nonBillablePositions?: number | undefined;
  remainingNonBillablePositions?: number | undefined;
  advisorUsers?: AdvisorUserModel[] | undefined;
  contactPeople?: OpportunityContactPersonModel[] | undefined;
  opportunityStudents?: OpportunityStudentView[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  pools?: OpportunityPoolDetailModel[] | undefined;
}
