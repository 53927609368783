import React from 'react';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {personCacheAdd, personCacheRemove, personCacheUpdate} from '../../../redux/peopleCache/Actions';
import Box from '@mui/material/Box';
import {AppState} from '../../../redux/store';
import {PersonListItem} from '../../../types/views/PersonListItem';
import {generatePath} from 'react-router';
import {studentPaths} from '../../students/paths';
import Link from '@mui/material/Link';
import {contactPaths} from '../../contacts/paths';
import {volunteerPaths} from '../../volunteers/paths';
import AddContactDialogWithIcon from "../../companies/Common/AddContactDialogWithIcon";
import {ContactEditDetailModel} from "../../../types/requests/ContactEditDetailModel";
import {ContactPersonEditModel} from "../../../types/requests/ContactPersonEditModel";


interface IPersonLinkProps {
  personId?: string,
  className?: string | undefined,
  linkProfile?: boolean | undefined,
  forceContactLink?: boolean | undefined,
  onClick?(): void | undefined,
  companyId?: string | undefined;
}

const getCacheItem = (id: string) => (state: AppState) => {
  return state.peopleCache.personCache.find((x: PersonListItem) => x.id === id);
}

const PersonLink = ({ personId, linkProfile, className, onClick, forceContactLink, companyId }: IPersonLinkProps) => {

  const dispatch = useAppDispatch();
  const [ avatarPersonId ] = useState(personId);

  const listItem = useSelector(getCacheItem(personId??''));

  useEffect(() => {
    if (!listItem && avatarPersonId) {
      dispatch(personCacheAdd([avatarPersonId]));
    }
  }, [ avatarPersonId ]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const getUrl = () => {
    let url = '#';
    if (listItem) {
      if ((listItem.isStudent || listItem.isWorkShadow || listItem.isAlumni) && !forceContactLink) {
        url = generatePath(studentPaths.StudentProfile, { id: listItem.id });
      } else if (listItem.isClient) {
        url =  generatePath(contactPaths.ContactProfile, { id: listItem.id });
      } else if (listItem.isVolunteer) {
        url =  generatePath(volunteerPaths.VolunteerProfile, { id: listItem.id });
      }
    }
    return url;
  }

  // NOTE: this may need to be expanded to include all promotes
  const renderQuickPromote = () => {
    if (listItem && companyId && !listItem.isClient
      && !listItem.isAlumni && !listItem.isVolunteer
      && !listItem.isUser && !listItem.isStudent && !listItem.isWorkShadow) {
      const payload = ContactPersonEditModel.fromJS({
        firstName: listItem.firstName,
        lastName: listItem.lastName,
        email: listItem.email,
        contactDetail: ContactEditDetailModel.fromJS({companyId: companyId})
      })
      return <AddContactDialogWithIcon companyId={companyId} payload={payload} personId={listItem.id} onFinishedSave={delayedUpdatePersonListItem} />
    }
    return <></>;
  }

  const updateCacheItem = () => {
    if (personId) {
      dispatch(personCacheRemove(personId));
      setTimeout(() => dispatch(personCacheUpdate([personId])), 1000);
    }
  }

  const delayedUpdatePersonListItem = () => {
    setTimeout(updateCacheItem, 1000);
  }

  if (onClick && listItem) {
    return <Box component="span" className={className}>
      {renderQuickPromote()}<Link component="button" onClick={handleClick}>{listItem.firstName} {listItem.lastName}</Link>
    </Box>;
  }
  if (listItem && linkProfile && getUrl() !== '#') {
    return (
      <Box component="span" className={className}>
        {renderQuickPromote()}<NavLink to={getUrl()} >{listItem.firstName} {listItem.lastName}</NavLink>
      </Box>
    );
  } else if (listItem) {
    return <Box component="span" className={className}>
      {renderQuickPromote()}{listItem.firstName} {listItem.lastName}
    </Box>;
  }
  return <Box component="span" className={className}>
    Loading person
  </Box>
};

export default PersonLink;