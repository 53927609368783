import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './shared/styles/index.css';
import App from './App';
import '@template/services';

ReactDOM.render(<App />, document.getElementById('root'));
