export class OpportunitySummaryModel implements IOpportunitySummaryModel {
  id?: string | undefined;
  companyId?: string | undefined;
  startingPositions?: number | undefined;
  remainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  tenByTenPositions?: number | undefined;
  remainingTenByTenPositions?: number | undefined;
  billablePositions?: number | undefined;
  remainingBillablePositions?: number | undefined;
  nonBillablePositions?: number | undefined;
  remainingNonBillablePositions?: number | undefined;

  constructor(data?: IOpportunitySummaryModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyId = Data["companyId"];
      this.startingPositions = Data["startingPositions"];
      this.remainingPositions = Data["remainingPositions"];
      this.shortlistedStudentsCount = Data["shortlistedStudentsCount"];
      this.studentsMatched = Data["studentsMatched"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonIndex = Data["seasonIndex"];
      this.tenByTenPositions = Data["tenByTenPositions"];
      this.remainingTenByTenPositions = Data["remainingTenByTenPositions"];
      this.billablePositions = Data["billablePositions"];
      this.remainingBillablePositions = Data["remainingBillablePositions"];
      this.nonBillablePositions = Data["nonBillablePositions"];
      this.remainingNonBillablePositions = Data["remainingNonBillablePositions"];
    }
  }

  static fromJS(data: any): OpportunitySummaryModel {
    data = typeof data === 'object' ? data : {};
    let result = new OpportunitySummaryModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyId"] = this.companyId;
    data["startingPositions"] = this.startingPositions;
    data["remainingPositions"] = this.remainingPositions;
    data["shortlistedStudentsCount"] = this.shortlistedStudentsCount;
    data["studentsMatched"] = this.studentsMatched;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonIndex"] = this.seasonIndex;
    data["tenByTenPositions"] = this.tenByTenPositions;
    data["remainingTenByTenPositions"] = this.remainingTenByTenPositions;
    data["billablePositions"] = this.billablePositions;
    data["remainingBillablePositions"] = this.remainingBillablePositions;
    data["nonBillablePositions"] = this.nonBillablePositions;
    data["remainingNonBillablePositions"] = this.remainingNonBillablePositions;
    return data;
  }
}

export interface IOpportunitySummaryModel {
  id?: string | undefined;
  companyId?: string | undefined;
  startingPositions?: number | undefined;
  remainingPositions?: number | undefined;
  shortlistedStudentsCount?: number | undefined;
  studentsMatched?: number | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonIndex?: number | undefined;
  tenByTenPositions?: number | undefined;
  remainingTenByTenPositions?: number | undefined;
  billablePositions?: number | undefined;
  remainingBillablePositions?: number | undefined;
  nonBillablePositions?: number | undefined;
  remainingNonBillablePositions?: number | undefined;
}