import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {JOB_PROGRESS} from './Actions';
import {COMPANY_CACHE} from '../companyCache/Actions';

export interface IJobProgressState {
  jobId: string | undefined;
  jobError: string | undefined;
  jobErrorList: string[];
  jobType: 'FILE' | 'PROGRESS' | undefined;
}

const initialState: IJobProgressState = {
  jobError: undefined,
  jobErrorList: [],
  jobId: undefined,
  jobType: undefined
};

const jobProgressReducer = createReducer(initialState, {
  [JOB_PROGRESS.SAVE]: (state, action: PayloadAction<string>) => {
    state.jobId = action.payload;
    state.jobError = undefined;
    state.jobType = 'PROGRESS';
    state.jobErrorList = [];
  },
  [JOB_PROGRESS.SAVE_FILE]: (state, action: PayloadAction<string>) => {
    state.jobId = action.payload;
    state.jobError = undefined;
    state.jobType = 'FILE';
    state.jobErrorList = [];
  },
  [JOB_PROGRESS.ERROR]: (state, action: PayloadAction<string>) => {
    state.jobErrorList = [];
    state.jobError = action.payload;
  },
  [JOB_PROGRESS.SUCCESS]: (state, action: PayloadAction<string[]>) => {
    state.jobErrorList = action.payload;
    state.jobError = undefined;
  },
  [JOB_PROGRESS.CLEAR]: (state) => {
    state.jobId = undefined;
    state.jobError = undefined;
    state.jobType = undefined;
    state.jobErrorList = [];
  },
});

export default jobProgressReducer;