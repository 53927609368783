import {useFormContext, Controller, FieldValues, get } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import React from 'react';
import {useValidationContext, isFieldRequired, getFieldMaxLength} from '../../validation/ValidationContext';
import {isFieldInObject} from '../../helpers/json';
import {changeFieldOverride} from '../../utility/FormLayout/layoutConfig';

export interface IFormTextFieldProps extends FieldValues {
  showRemainingMaxLength?: boolean;
}

const FormTextField = (props: IFormTextFieldProps) => {
  const { schema, changeRequest } = useValidationContext();
  const { control, setValue, formState: { errors } } = useFormContext();
  const { name, label, required = false, multiline = false, rows = 1, fullWidth = true, disabled = false, onBlur, type = undefined,  showRemainingMaxLength = multiline } = props;
  const isRequired = required ? true : schema ? isFieldRequired(schema, name, required) : false;
  const maxLength: number | false = showRemainingMaxLength && schema ? getFieldMaxLength(schema, name) : false;

  let isError = false;
  let errorMessage = '';

  if (errors) {
    errorMessage = get(errors, name)?.message;
    if (errorMessage)
      isError = true;
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, onHookBlur: () => void) => {
    onHookBlur();
    if (e?.target?.value) {
      setValue(name, e.target.value.trim());
    }
    if (onBlur) onBlur(e);
  }

  const styleForChangeRequest = () => {
    if (changeRequest && isFieldInObject(changeRequest, name)) {
      return changeFieldOverride;
    }
    return undefined;
  }

  const remainingLengthLabel = (label: string | undefined, value: string | undefined) => {
    if(maxLength){
      if(value){
        return `${label} (${maxLength - value.length})`;
      }
      return `${label} (${maxLength})`;
    }
    return label;
  }


  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onBlur, ...rest} }) => {
        return <TextField
          fullWidth={fullWidth}
          variant='outlined'
          type={type}
          sx={styleForChangeRequest()}
          label={showRemainingMaxLength && maxLength ? remainingLengthLabel(label, value) : label}
          multiline={multiline}
          minRows={rows ? rows : 1}
          required={isRequired}
          error={isError}
          helperText={errorMessage}
          value={!!value ? value : ''}
          disabled={disabled}
          onBlur={(e) => handleBlur(e, onBlur)}
          {...rest}
        />
      }}
    />
  );
}

export default FormTextField;
