import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, {SyntheticEvent} from 'react';

interface IDialogCloseButtonProps {
  onClick?(e: SyntheticEvent): void;
}

export const DialogCloseButton = ({onClick}: IDialogCloseButtonProps) => {
  const handleClose = (e: SyntheticEvent) => {
    if (onClick) onClick(e);
  }

  return (
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
      >
      <CloseIcon />
    </IconButton>
  );
}

export default DialogCloseButton;