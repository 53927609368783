export class CareerQuestionnaireConfigModel implements ICareerQuestionnaireConfigModel {
  section?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  content?: string | undefined;
  lastUpdated?: string | undefined;
  isCustomField?: boolean | undefined;

  constructor(data?: ICareerQuestionnaireConfigModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.section = Data["section"];
      this.title = Data["title"];
      this.description = Data["description"];
      this.content = Data["content"];
      this.lastUpdated = Data["lastUpdated"];
      this.isCustomField = Data["isCustomField"];
    }
  }

  static fromJS(data: any): CareerQuestionnaireConfigModel {
    data = typeof data === 'object' ? data : {};
    let result = new CareerQuestionnaireConfigModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["section"] = this.section;
    data["title"] = this.title;
    data["description"] = this.description;
    data["content"] = this.content;
    data["lastUpdated"] = this.lastUpdated;
    data["isCustomField"] = this.isCustomField;
    return data;
  }
}

export interface ICareerQuestionnaireConfigModel {
  section?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  content?: string | undefined;
  lastUpdated?: string | undefined;
  isCustomField?: boolean | undefined;
}