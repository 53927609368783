

export const defaultRecipientSearchParamsModel = () => {
  return RecipientSearchParamsModel.fromJS( {
    searchText: '',
    email: '',
    isContact: false,
    isStudent: false,
    isVolunteer: false,
    isWorkShadow: false,
    isAlumni: false,
    createdById: [],
  });
}


export class RecipientSearchParamsModel implements IRecipientSearchParamsModel {
  searchText?: string | undefined;
  email?: string | undefined;
  isContact?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  createdById?: string[] | undefined;

  constructor(data?: IRecipientSearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      this.email = Data["email"];
      this.isContact = Data["isContact"];
      this.isStudent = Data["isStudent"];
      this.isVolunteer = Data["isVolunteer"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.isAlumni = Data["isAlumni"];
      if (Array.isArray(Data["createdById"])) {
        this.createdById = [] as any;
        for (let item of Data["createdById"])
          this.createdById!.push(item);
      }
    }
  }

  static fromJS(data: any): RecipientSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new RecipientSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    data["email"] = this.email;
    data["isContact"] = this.isContact;
    data["isStudent"] = this.isStudent;
    data["isVolunteer"] = this.isVolunteer;
    data["isWorkShadow"] = this.isWorkShadow;
    data["isAlumni"] = this.isAlumni;
    if (Array.isArray(this.createdById)) {
      data["createdById"] = [];
      for (let item of this.createdById)
        data["createdById"].push(item);
    }
    return data;
  }
}

export interface IRecipientSearchParamsModel {
  searchText?: string | undefined;
  email?: string | undefined;
  isContact?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  createdById?: string[] | undefined;
}