import {ICommonLoadActionType, ICommonSaveActionType, ICommonSavePayload} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {StudentOnboardingModel} from '../../types/models/StudentOnboardingModel';
import {StudentPlacementModel} from '../../types/models/StudentPlacementModel';
import {StudentGraduatingModel} from '../../types/models/StudentGraduatingModel';
import {ClientOnboardingModel} from '../../types/models/ClientOnboardingModel';
import {EducationOnboardingModel} from '../../types/models/EducationOnboardingModel';
import {WorkShadowOnboardingModel} from '../../types/models/WorkShadowOnboardingModel';
import {PoolWorkShadowModel} from '../../types/models/PoolWorkShadowModel';
import {PlacementWorkShadowModel} from '../../types/models/PlacementWorkShadowModel';
import {AlumniEarlyProfessionalModel} from '../../types/models/AlumniEarlyProfessionalModel';
import {HighSchoolProgramParticipantModel} from '../../types/models/HighSchoolProgramParticipantModel';


interface ICommonTimelineSettingsActionType extends ICommonSaveActionType {
  DISABLE: string;
}

export const STUDENT_ON_BOARDING_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'studentOnBoardingSettings/load',
  LOAD_ERROR: 'studentOnBoardingSettings/loadError',
  LOADED: 'studentOnBoardingSettings/loadSuccess',
  SAVE: 'studentOnBoardingSettings/save',
  SAVED: 'studentOnBoardingSettings/saveSuccess',
  SAVE_ERROR: 'studentOnBoardingSettings/saveError',
  DISABLE: 'studentOnBoardingSettings/disable',
};

export const STUDENT_ON_BOARDING_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'studentOnBoardingSettings/list/loadError',
  LOAD: 'studentOnBoardingSettings/list/load',
  LOADED: 'studentOnBoardingSettings/list/loadSuccess'
};

export const STUDENT_PLACEMENT_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'placementSettings/load',
  LOAD_ERROR: 'placementSettings/loadError',
  LOADED: 'placementSettings/loadSuccess',
  SAVE: 'placementSettings/save',
  SAVED: 'placementSettings/saveSuccess',
  SAVE_ERROR: 'placementSettings/saveError',
  DISABLE: 'placementSettings/disable',
};

export const STUDENT_PLACEMENT_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'placementSettings/list/loadError',
  LOAD: 'placementSettings/list/load',
  LOADED: 'placementSettings/list/loadSuccess'
};

export const GRADUATING_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'graduatingSettings/load',
  LOAD_ERROR: 'graduatingSettings/loadError',
  LOADED: 'graduatingSettings/loadSuccess',
  SAVE: 'graduatingSettings/save',
  SAVED: 'graduatingSettings/saveSuccess',
  SAVE_ERROR: 'graduatingSettings/saveError',
  DISABLE: 'graduatingSettings/disable',
};

export const GRADUATING_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'graduatingSettings/list/loadError',
  LOAD: 'graduatingSettings/list/load',
  LOADED: 'graduatingSettings/list/loadSuccess'
};

export const CLIENT_ON_BOARDING_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'clientOnBoardingSettings/load',
  LOAD_ERROR: 'clientOnBoardingSettings/loadError',
  LOADED: 'clientOnBoardingSettings/loadSuccess',
  SAVE: 'clientOnBoardingSettings/save',
  SAVED: 'clientOnBoardingSettings/saveSuccess',
  SAVE_ERROR: 'clientOnBoardingSettings/saveError',
  DISABLE: 'clientOnBoardingSettings/disable',
};

export const CLIENT_ON_BOARDING_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'clientOnBoardingSettings/list/loadError',
  LOAD: 'clientOnBoardingSettings/list/load',
  LOADED: 'clientOnBoardingSettings/list/loadSuccess'
};

export const EDUCATION_ON_BOARDING_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'educationOnBoardingSettings/load',
  LOAD_ERROR: 'educationOnBoardingSettings/loadError',
  LOADED: 'educationOnBoardingSettings/loadSuccess',
  SAVE: 'educationOnBoardingSettings/save',
  SAVED: 'educationOnBoardingSettings/saveSuccess',
  SAVE_ERROR: 'educationOnBoardingSettings/saveError',
  DISABLE: 'educationOnBoardingSettings/disable',
};

export const EDUCATION_ON_BOARDING_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'educationOnBoardingSettings/list/loadError',
  LOAD: 'educationOnBoardingSettings/list/load',
  LOADED: 'educationOnBoardingSettings/list/loadSuccess'
};

export const WORK_SHADOW_ON_BOARDING_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'workShadowOnBoardingSettings/load',
  LOAD_ERROR: 'workShadowOnBoardingSettings/loadError',
  LOADED: 'workShadowOnBoardingSettings/loadSuccess',
  SAVE: 'workShadowOnBoardingSettings/save',
  SAVED: 'workShadowOnBoardingSettings/saveSuccess',
  SAVE_ERROR: 'workShadowOnBoardingSettings/saveError',
  DISABLE: 'workShadowOnBoardingSettings/disable',
};

export const WORK_SHADOW_ON_BOARDING_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'workShadowOnBoardingSettings/list/loadError',
  LOAD: 'workShadowOnBoardingSettings/list/load',
  LOADED: 'workShadowOnBoardingSettings/list/loadSuccess'
};

export const WORK_SHADOW_POOL_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'workShadowPoolSettings/load',
  LOAD_ERROR: 'workShadowPoolSettings/loadError',
  LOADED: 'workShadowPoolSettings/loadSuccess',
  SAVE: 'workShadowPoolSettings/save',
  SAVED: 'workShadowPoolSettings/saveSuccess',
  SAVE_ERROR: 'workShadowPoolSettings/saveError',
  DISABLE: 'workShadowPoolSettings/disable',
};

export const WORK_SHADOW_POOL_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'workShadowPoolSettings/list/loadError',
  LOAD: 'workShadowPoolSettings/list/load',
  LOADED: 'workShadowPoolSettings/list/loadSuccess'
};

export const WORK_SHADOW_PLACEMENT_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'workShadowPlacementSettings/load',
  LOAD_ERROR: 'workShadowPlacementSettings/loadError',
  LOADED: 'workShadowPlacementSettings/loadSuccess',
  SAVE: 'workShadowPlacementSettings/save',
  SAVED: 'workShadowPlacementSettings/saveSuccess',
  SAVE_ERROR: 'workShadowPlacementSettings/saveError',
  DISABLE: 'workShadowPlacementSettings/disable',
};

export const WORK_SHADOW_PLACEMENT_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'workShadowPlacementSettings/list/loadError',
  LOAD: 'workShadowPlacementSettings/list/load',
  LOADED: 'workShadowPlacementSettings/list/loadSuccess'
};


export const ALUMNI_EARLY_PROFESSIONAL_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'alumniEarlyProfessionalSettings/load',
  LOAD_ERROR: 'alumniEarlyProfessionalSettings/loadError',
  LOADED: 'alumniEarlyProfessionalSettings/loadSuccess',
  SAVE: 'alumniEarlyProfessionalSettings/save',
  SAVED: 'alumniEarlyProfessionalSettings/saveSuccess',
  SAVE_ERROR: 'alumniEarlyProfessionalSettings/saveError',
  DISABLE: 'alumniEarlyProfessionalSettings/disable',
};

export const ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'alumniEarlyProfessionalSettings/list/loadError',
  LOAD: 'alumniEarlyProfessionalSettings/list/load',
  LOADED: 'alumniEarlyProfessionalSettings/list/loadSuccess'
};

export const HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS: ICommonTimelineSettingsActionType = {
  LOAD: 'highSchoolProgramParticipantSettings/load',
  LOAD_ERROR: 'highSchoolProgramParticipantSettings/loadError',
  LOADED: 'highSchoolProgramParticipantSettings/loadSuccess',
  SAVE: 'highSchoolProgramParticipantSettings/save',
  SAVED: 'highSchoolProgramParticipantSettings/saveSuccess',
  SAVE_ERROR: 'highSchoolProgramParticipantSettings/saveError',
  DISABLE: 'highSchoolProgramParticipantSettings/disable',
};

export const HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST: ICommonLoadActionType = {
  ERROR: 'highSchoolProgramParticipantSettings/list/loadError',
  LOAD: 'highSchoolProgramParticipantSettings/list/load',
  LOADED: 'highSchoolProgramParticipantSettings/list/loadSuccess'
};

export const studentOnBoardingSettingsLoad = createAction<string>(STUDENT_ON_BOARDING_SETTINGS.LOAD);
export const studentOnBoardingSettingsLoadSuccess = createAction<StudentOnboardingModel>(STUDENT_ON_BOARDING_SETTINGS.LOADED);
export const studentOnBoardingSettingsLoadError = createAction<string>(STUDENT_ON_BOARDING_SETTINGS.LOAD_ERROR);
export const studentOnBoardingSettingsSave = createAction<ICommonSavePayload<StudentOnboardingModel>>(STUDENT_ON_BOARDING_SETTINGS.SAVE);
export const studentOnBoardingSettingsDisable = createAction<string>(STUDENT_ON_BOARDING_SETTINGS.DISABLE);
export const studentOnBoardingSettingsSaveSuccess = createAction<StudentOnboardingModel>(STUDENT_ON_BOARDING_SETTINGS.SAVED);
export const studentOnBoardingSettingsSaveError = createAction<string>(STUDENT_ON_BOARDING_SETTINGS.SAVE_ERROR);

export const studentOnBoardingSettingsList = createAction(STUDENT_ON_BOARDING_SETTINGS_LIST.LOAD);
export const studentOnBoardingSettingsListSuccess = createAction<StudentOnboardingModel[]>(STUDENT_ON_BOARDING_SETTINGS_LIST.LOADED);
export const studentOnBoardingSettingsListError = createAction<string>(STUDENT_ON_BOARDING_SETTINGS_LIST.ERROR);

export const studentPlacementSettingsLoad = createAction<string>(STUDENT_PLACEMENT_SETTINGS.LOAD);
export const studentPlacementSettingsLoadSuccess = createAction<StudentPlacementModel>(STUDENT_PLACEMENT_SETTINGS.LOADED);
export const studentPlacementSettingsLoadError = createAction<string>(STUDENT_PLACEMENT_SETTINGS.LOAD_ERROR);
export const studentPlacementSettingsSave = createAction<ICommonSavePayload<StudentPlacementModel>>(STUDENT_PLACEMENT_SETTINGS.SAVE);
export const studentPlacementSettingsDisable = createAction<string>(STUDENT_PLACEMENT_SETTINGS.DISABLE);
export const studentPlacementSettingsSaveSuccess = createAction<StudentPlacementModel>(STUDENT_PLACEMENT_SETTINGS.SAVED);
export const studentPlacementSettingsSaveError = createAction<string>(STUDENT_PLACEMENT_SETTINGS.SAVE_ERROR);

export const studentPlacementSettingsList = createAction(STUDENT_PLACEMENT_SETTINGS_LIST.LOAD);
export const studentPlacementSettingsListSuccess = createAction<StudentPlacementModel[]>(STUDENT_PLACEMENT_SETTINGS_LIST.LOADED);
export const studentPlacementSettingsListError = createAction<string>(STUDENT_PLACEMENT_SETTINGS_LIST.ERROR);

export const graduatingSettingsLoad = createAction<string>(GRADUATING_SETTINGS.LOAD);
export const graduatingSettingsLoadSuccess = createAction<StudentGraduatingModel>(GRADUATING_SETTINGS.LOADED);
export const graduatingSettingsLoadError = createAction<string>(GRADUATING_SETTINGS.LOAD_ERROR);
export const graduatingSettingsSave = createAction<ICommonSavePayload<StudentGraduatingModel>>(GRADUATING_SETTINGS.SAVE);
export const graduatingSettingsDisable = createAction<string>(GRADUATING_SETTINGS.DISABLE);
export const graduatingSettingsSaveSuccess = createAction<StudentGraduatingModel>(GRADUATING_SETTINGS.SAVED);
export const graduatingSettingsSaveError = createAction<string>(GRADUATING_SETTINGS.SAVE_ERROR);

export const graduatingSettingsList = createAction(GRADUATING_SETTINGS_LIST.LOAD);
export const graduatingSettingsListSuccess = createAction<StudentGraduatingModel[]>(GRADUATING_SETTINGS_LIST.LOADED);
export const graduatingSettingsListError = createAction<string>(GRADUATING_SETTINGS_LIST.ERROR);

export const clientOnBoardingSettingsLoad = createAction<string>(CLIENT_ON_BOARDING_SETTINGS.LOAD);
export const clientOnBoardingSettingsLoadSuccess = createAction<ClientOnboardingModel>(CLIENT_ON_BOARDING_SETTINGS.LOADED);
export const clientOnBoardingSettingsLoadError = createAction<string>(CLIENT_ON_BOARDING_SETTINGS.LOAD_ERROR);
export const clientOnBoardingSettingsSave = createAction<ICommonSavePayload<ClientOnboardingModel>>(CLIENT_ON_BOARDING_SETTINGS.SAVE);
export const clientOnBoardingSettingsDisable = createAction<string>(CLIENT_ON_BOARDING_SETTINGS.DISABLE);
export const clientOnBoardingSettingsSaveSuccess = createAction<ClientOnboardingModel>(CLIENT_ON_BOARDING_SETTINGS.SAVED);
export const clientOnBoardingSettingsSaveError = createAction<string>(CLIENT_ON_BOARDING_SETTINGS.SAVE_ERROR);

export const clientOnBoardingSettingsList = createAction(CLIENT_ON_BOARDING_SETTINGS_LIST.LOAD);
export const clientOnBoardingSettingsListSuccess = createAction<ClientOnboardingModel[]>(CLIENT_ON_BOARDING_SETTINGS_LIST.LOADED);
export const clientOnBoardingSettingsListError = createAction<string>(CLIENT_ON_BOARDING_SETTINGS_LIST.ERROR);

export const educationOnBoardingSettingsLoad = createAction<string>(EDUCATION_ON_BOARDING_SETTINGS.LOAD);
export const educationOnBoardingSettingsLoadSuccess = createAction<EducationOnboardingModel>(EDUCATION_ON_BOARDING_SETTINGS.LOADED);
export const educationOnBoardingSettingsLoadError = createAction<string>(EDUCATION_ON_BOARDING_SETTINGS.LOAD_ERROR);
export const educationOnBoardingSettingsSave = createAction<ICommonSavePayload<EducationOnboardingModel>>(EDUCATION_ON_BOARDING_SETTINGS.SAVE);
export const educationOnBoardingSettingsDisable = createAction<string>(EDUCATION_ON_BOARDING_SETTINGS.DISABLE);
export const educationOnBoardingSettingsSaveSuccess = createAction<EducationOnboardingModel>(EDUCATION_ON_BOARDING_SETTINGS.SAVED);
export const educationOnBoardingSettingsSaveError = createAction<string>(EDUCATION_ON_BOARDING_SETTINGS.SAVE_ERROR);

export const educationOnBoardingSettingsList = createAction(EDUCATION_ON_BOARDING_SETTINGS_LIST.LOAD);
export const educationOnBoardingSettingsListSuccess = createAction<EducationOnboardingModel[]>(EDUCATION_ON_BOARDING_SETTINGS_LIST.LOADED);
export const educationOnBoardingSettingsListError = createAction<string>(EDUCATION_ON_BOARDING_SETTINGS_LIST.ERROR);


export const workShadowOnBoardingSettingsLoad = createAction<string>(WORK_SHADOW_ON_BOARDING_SETTINGS.LOAD);
export const workShadowOnBoardingSettingsLoadSuccess = createAction<WorkShadowOnboardingModel>(WORK_SHADOW_ON_BOARDING_SETTINGS.LOADED);
export const workShadowOnBoardingSettingsLoadError = createAction<string>(WORK_SHADOW_ON_BOARDING_SETTINGS.LOAD_ERROR);
export const workShadowOnBoardingSettingsSave = createAction<ICommonSavePayload<WorkShadowOnboardingModel>>(WORK_SHADOW_ON_BOARDING_SETTINGS.SAVE);
export const workShadowOnBoardingSettingsDisable = createAction<string>(WORK_SHADOW_ON_BOARDING_SETTINGS.DISABLE);
export const workShadowOnBoardingSettingsSaveSuccess = createAction<WorkShadowOnboardingModel>(WORK_SHADOW_ON_BOARDING_SETTINGS.SAVED);
export const workShadowOnBoardingSettingsSaveError = createAction<string>(WORK_SHADOW_ON_BOARDING_SETTINGS.SAVE_ERROR);

export const workShadowOnBoardingSettingsList = createAction(WORK_SHADOW_ON_BOARDING_SETTINGS_LIST.LOAD);
export const workShadowOnBoardingSettingsListSuccess = createAction<WorkShadowOnboardingModel[]>(WORK_SHADOW_ON_BOARDING_SETTINGS_LIST.LOADED);
export const workShadowOnBoardingSettingsListError = createAction<string>(WORK_SHADOW_ON_BOARDING_SETTINGS_LIST.ERROR);

export const workShadowPoolSettingsLoad = createAction<string>(WORK_SHADOW_POOL_SETTINGS.LOAD);
export const workShadowPoolSettingsLoadSuccess = createAction<PoolWorkShadowModel>(WORK_SHADOW_POOL_SETTINGS.LOADED);
export const workShadowPoolSettingsLoadError = createAction<string>(WORK_SHADOW_POOL_SETTINGS.LOAD_ERROR);
export const workShadowPoolSettingsSave = createAction<ICommonSavePayload<PoolWorkShadowModel>>(WORK_SHADOW_POOL_SETTINGS.SAVE);
export const workShadowPoolSettingsDisable = createAction<string>(WORK_SHADOW_POOL_SETTINGS.DISABLE);
export const workShadowPoolSettingsSaveSuccess = createAction<PoolWorkShadowModel>(WORK_SHADOW_POOL_SETTINGS.SAVED);
export const workShadowPoolSettingsSaveError = createAction<string>(WORK_SHADOW_POOL_SETTINGS.SAVE_ERROR);

export const workShadowPoolSettingsList = createAction(WORK_SHADOW_POOL_SETTINGS_LIST.LOAD);
export const workShadowPoolSettingsListSuccess = createAction<PoolWorkShadowModel[]>(WORK_SHADOW_POOL_SETTINGS_LIST.LOADED);
export const workShadowPoolSettingsListError = createAction<string>(WORK_SHADOW_POOL_SETTINGS_LIST.ERROR);

export const workShadowPlacementSettingsLoad = createAction<string>(WORK_SHADOW_PLACEMENT_SETTINGS.LOAD);
export const workShadowPlacementSettingsLoadSuccess = createAction<PlacementWorkShadowModel>(WORK_SHADOW_PLACEMENT_SETTINGS.LOADED);
export const workShadowPlacementSettingsLoadError = createAction<string>(WORK_SHADOW_PLACEMENT_SETTINGS.LOAD_ERROR);
export const workShadowPlacementSettingsSave = createAction<ICommonSavePayload<PlacementWorkShadowModel>>(WORK_SHADOW_PLACEMENT_SETTINGS.SAVE);
export const workShadowPlacementSettingsDisable = createAction<string>(WORK_SHADOW_PLACEMENT_SETTINGS.DISABLE);
export const workShadowPlacementSettingsSaveSuccess = createAction<PlacementWorkShadowModel>(WORK_SHADOW_PLACEMENT_SETTINGS.SAVED);
export const workShadowPlacementSettingsSaveError = createAction<string>(WORK_SHADOW_PLACEMENT_SETTINGS.SAVE_ERROR);

export const workShadowPlacementSettingsList = createAction(WORK_SHADOW_PLACEMENT_SETTINGS_LIST.LOAD);
export const workShadowPlacementSettingsListSuccess = createAction<PlacementWorkShadowModel[]>(WORK_SHADOW_PLACEMENT_SETTINGS_LIST.LOADED);
export const workShadowPlacementSettingsListError = createAction<string>(WORK_SHADOW_PLACEMENT_SETTINGS_LIST.ERROR);

export const alumniEarlyProfessionalSettingsLoad = createAction<string>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.LOAD);
export const alumniEarlyProfessionalSettingsLoadSuccess = createAction<AlumniEarlyProfessionalModel>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.LOADED);
export const alumniEarlyProfessionalSettingsLoadError = createAction<string>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.LOAD_ERROR);
export const alumniEarlyProfessionalSettingsSave = createAction<ICommonSavePayload<AlumniEarlyProfessionalModel>>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.SAVE);
export const alumniEarlyProfessionalSettingsDisable = createAction<string>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.DISABLE);
export const alumniEarlyProfessionalSettingsSaveSuccess = createAction<AlumniEarlyProfessionalModel>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.SAVED);
export const alumniEarlyProfessionalSettingsSaveError = createAction<string>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS.SAVE_ERROR);

export const alumniEarlyProfessionalSettingsList = createAction(ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST.LOAD);
export const alumniEarlyProfessionalSettingsListSuccess = createAction<AlumniEarlyProfessionalModel[]>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST.LOADED);
export const alumniEarlyProfessionalSettingsListError = createAction<string>(ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST.ERROR);

export const highSchoolProgramParticipantSettingsLoad = createAction<string>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.LOAD);
export const highSchoolProgramParticipantSettingsLoadSuccess = createAction<HighSchoolProgramParticipantModel>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.LOADED);
export const highSchoolProgramParticipantSettingsLoadError = createAction<string>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.LOAD_ERROR);
export const highSchoolProgramParticipantSettingsSave = createAction<ICommonSavePayload<HighSchoolProgramParticipantModel>>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.SAVE);
export const highSchoolProgramParticipantSettingsDisable = createAction<string>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.DISABLE);
export const highSchoolProgramParticipantSettingsSaveSuccess = createAction<HighSchoolProgramParticipantModel>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.SAVED);
export const highSchoolProgramParticipantSettingsSaveError = createAction<string>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.SAVE_ERROR);

export const highSchoolProgramParticipantSettingsList = createAction(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST.LOAD);
export const highSchoolProgramParticipantSettingsListSuccess = createAction<HighSchoolProgramParticipantModel[]>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST.LOADED);
export const highSchoolProgramParticipantSettingsListError = createAction<string>(HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST.ERROR);