import {
  ICommonLoadActionType,
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonSearchActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IContactSearchParamsModel} from '../../types/requests/ContactSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {IContactPersonModel} from '../../types/views/ContactPersonModel';
import {PersonModel} from '../../types/models/PersonModel';
import {ContactPersonEditModel} from '../../types/requests/ContactPersonEditModel';
import {PersonSearchResultModel} from '../../types/views/PersonSearchResultModel';

export interface IContactActionTypes extends ICommonSaveActionType {
  REFRESH: string;
}

export const CONTACT: IContactActionTypes = {
  LOAD: 'contact/load',
  LOAD_ERROR: 'contact/loadError',
  LOADED: 'contact/loadSuccess',
  REFRESH: 'contact/refresh',
  SAVE: 'contact/save',
  SAVED: 'contact/saveSuccess',
  SAVE_ERROR: 'contact/saveError',
};

export const CONTACT_SEARCH: ICommonSearchActionType = {
  ERROR: 'contactSearch/error',
  LOAD: 'contactSearch/load',
  LOADED: 'contactSearch/loaded',
  REFRESH: 'contactSearch/refresh',
  CHANGE_VIEW: 'contactSearch/changeView',
};

export const contactLoad = createAction<string>(CONTACT.LOAD);
export const contactRefresh = createAction<string>(CONTACT.REFRESH);
export const contactLoadSuccess = createAction<PersonModel>(CONTACT.LOADED);
export const contactLoadError = createAction<string>(CONTACT.LOAD_ERROR);

export const contactSave = createAction<ICommonSavePayload<ContactPersonEditModel>>(CONTACT.SAVE);
export const contactSaveSuccess = createAction<PersonModel>(CONTACT.SAVED);
export const contactSaveError = createAction<string>(CONTACT.SAVE_ERROR);

export const contactsSearch = createAction<ISearchRequest<IContactSearchParamsModel>>(CONTACT_SEARCH.LOAD);
export const contactsSearchView = createAction<string>(CONTACT_SEARCH.CHANGE_VIEW);
export const contactsSearchRefresh = createAction<ISearchRequest<IContactSearchParamsModel>>(CONTACT_SEARCH.REFRESH);
export const contactsSearchError = createAction<string>(CONTACT_SEARCH.ERROR);
export const contactsSearchSuccess = createAction<IPagedResult<PersonSearchResultModel>>(CONTACT_SEARCH.LOADED);