import {IPagedResult} from '../common/PagedResult';
import {IInternshipStudentSearchResult, InternshipStudentSearchResult} from '../views/InternshipStudentSearchResult';
import {Paging} from '../common/Paging';





export class StudentInternshipSearchPaged implements IPagedResult<InternshipStudentSearchResult> {
    paging?: Paging | undefined;
    result?: InternshipStudentSearchResult[] | undefined;

    constructor(data?: IPagedResult<IInternshipStudentSearchResult>) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.paging = Data["paging"] ? Paging.fromJS(Data["paging"]) : <any>undefined;
            if (Array.isArray(Data["result"])) {
                this.result = [] as any;
                for (let item of Data["result"])
                    this.result!.push(InternshipStudentSearchResult.fromJS(item));
            }
        }
    }

    static fromJS(data: any): StudentInternshipSearchPaged {
        data = typeof data === 'object' ? data : {};
        let result = new StudentInternshipSearchPaged();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["paging"] = this.paging ? this.paging.toJSON() : <any>undefined;
        if (Array.isArray(this.result)) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IStudentInternshipSearchPaged {
    paging?: Paging | undefined;
    result?: InternshipStudentSearchResult[] | undefined;
}
