import React from 'react';
import PreUniversityIcon from '@mui/icons-material/AccessibilityNew';
import UniversityIcon from '@mui/icons-material/Book';
import ContactIcon from '@mui/icons-material/ContactPage';
import StudentIcon from '@mui/icons-material/Person';
import HSPIcon from '@mui/icons-material/EscalatorWarning';
import AlumniIcon from '@mui/icons-material/School';
import WorkShadowBuddyIcon from '@mui/icons-material/SupervisorAccount';
import AdvisorIcon from '@mui/icons-material/AssignmentInd';
import VolunteerIcon from '@mui/icons-material/VolunteerActivism';
import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import {studentPaths} from '../../students/paths';
import {volunteerPaths} from '../../volunteers/paths';
import {contactPaths} from '../../contacts/paths';
import {workShadowPaths} from '../../workShadow/paths';
import {generatePath} from 'react-router';
import {NavLink, useHistory} from 'react-router-dom';
import {companyPaths} from '../../companies/paths';
import {peoplePaths} from '../paths';
import WorkShadowIcon from '@mui/icons-material/People';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  chip: {
    // backgroundColor: '#fde4d8',
    // color: '#cb7f5c',
    borderRadius: '6px',
    fontWeight: Fonts.MEDIUM,
    marginRight: 2,
    marginBottom: 2,
    height: 'auto',
    // '& .MuiSvgIcon-root': {
    //   color: '#cb7f5c',
    // },
    '& .MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
  contactColour: {
    backgroundColor: '#f2c7ca',
    color: '#A67B7E',
    '& .MuiSvgIcon-root': {
      color: '#A67B7E',
    },
  },
  studentColour: {
    backgroundColor: '#c3e7d3',
    color: '#779B87',
    '& .MuiSvgIcon-root': {
      color: '#779B87',
    },
  },
  volunteerColour: {
    backgroundColor: '#cfddeb',
    color: '#83919F',
    '& .MuiSvgIcon-root': {
      color: '#83919F',
    },
  },
  workShadowColour: {
    backgroundColor: '#f0d3c6',
    color: '#A4877A',
    '& .MuiSvgIcon-root': {
      color: '#A4877A',
    },
  },

  alumniColour: {
    backgroundColor: '#FFF8C6',
    color: '#B4AF8E',
    '& .MuiSvgIcon-root': {
      color: '#B4AF8E',
    },
  },
  advisorColour: {
    backgroundColor: '#fde4d8',
    color: '#cb7f5c',
    '& .MuiSvgIcon-root': {
      color: '#cb7f5c',
    },
  },
  titleLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.text.primary
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
  },
}));

interface IProgramTagProps {
  isStudent?: boolean | undefined,
  isClient?: boolean | undefined,
  isVolunteer?: boolean | undefined,
  isWorkShadow?: boolean | undefined,
  isAdvisor?: boolean | undefined,
  fontSize?: number | undefined,
  isBuddyWorkShadow?: boolean | undefined,
  isAlumni?: boolean | undefined;
  isHSP?: boolean | undefined,
  id?: string | undefined,
  onClick?(): void | undefined,
}

export const ProgramTags = ({isStudent = false, isClient = false, isVolunteer = false, isWorkShadow = false, isAdvisor = false
                              , isBuddyWorkShadow = false, isAlumni = false, isHSP = false, id ,fontSize = 12, onClick = undefined}: IProgramTagProps) => {

  const handleItemAction = () => {
    if (onClick) onClick();
  }

  const getLink = (type: string | undefined) => {
    if(type == 'student' && id)
    {
      return generatePath(studentPaths.StudentProfile, { id: id });
    }
    if(type == 'client' && id)
    {
      return generatePath(contactPaths.ContactProfile, { id: id });
    }
    if(type == 'volunteer')
    {
      return generatePath(volunteerPaths.VolunteerProfile, { id: id });
    }
    return '';
  }
  const history = useHistory();

  const handleOnTagClick = (type: string) => {
    history.push(getLink(type));
    handleItemAction();
  }

  const classes = useStyles();
  return (

    <Box className={classes.root} sx={{ fontSize: fontSize }}>
      {isClient && <Chip className={clsx(classes.chip, classes.contactColour)} sx={{ fontSize: fontSize }} onClick={() => handleOnTagClick('client')}  icon={<ContactIcon sx={{ fontSize: fontSize+2 }} />} label="Contact" />}
      {isStudent && <Chip className={clsx(classes.chip, classes.studentColour)} sx={{ fontSize: fontSize }} onClick={() => handleOnTagClick('student')} icon={<StudentIcon sx={{ fontSize: fontSize+2 }} />} label="Student" />}
      {isVolunteer && <Chip className={clsx(classes.chip, classes.volunteerColour)} sx={{ fontSize: fontSize }} onClick={() => handleOnTagClick('volunteer')}  icon={<VolunteerIcon sx={{ fontSize: fontSize+2 }} />} label="Volunteer" />}
      {isWorkShadow && <Chip className={clsx(classes.chip, classes.workShadowColour)} sx={{ fontSize: fontSize }} onClick={() => handleOnTagClick('student')}  icon={<WorkShadowIcon sx={{ fontSize: fontSize+2 }} />} label="WorkShadow" />}
      {isHSP && <Chip className={clsx(classes.chip, classes.workShadowColour)} sx={{ fontSize: fontSize }} onClick={() => handleOnTagClick('student')}  icon={<HSPIcon sx={{ fontSize: fontSize+2 }} />} label="HSP" />}
      {isAlumni && <Chip className={clsx(classes.chip, classes.alumniColour)} sx={{ fontSize: fontSize }} onClick={() => handleOnTagClick('student')}  icon={<AlumniIcon sx={{ fontSize: fontSize+2 }} />} label="Alumni" />}
      {isAdvisor && <Chip className={clsx(classes.chip, classes.advisorColour)} sx={{ fontSize: fontSize }} icon={<AdvisorIcon sx={{ fontSize: fontSize+2 }} />} label="Advisor" />}
      {isBuddyWorkShadow && <Chip className={clsx(classes.chip, classes.workShadowColour)} sx={{ fontSize: fontSize }} onClick={() => handleOnTagClick('student')}  icon={<WorkShadowBuddyIcon sx={{ fontSize: fontSize+2 }} />} label="BuddyWorkShadow" />}
    </Box>

  );
};

export default ProgramTags;
