import {defaultContactSearchParamsModel, ContactSearchParamsModel} from '../../types/requests/ContactSearchParamsModel';
import {ContactPersonModel} from '../../types/views/ContactPersonModel';
import {ContactEditDetailModel} from '../../types/requests/ContactEditDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {CONTACT, CONTACT_SEARCH} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {SearchView} from '../../types/common/SearchView';
import {PersonModel} from '../../types/models/PersonModel';

export interface IContactState {
  contactSearchParams: ContactSearchParamsModel;
  contactSearchView: string | undefined;
  contactSearchSort: string | undefined;
  contactResultLoading: boolean;
  contactResultError: string | undefined;
  contactResult: PersonSearchResultPaged | undefined;
  contactLoading: boolean;
  contactRefreshing: boolean;
  contactSaving: boolean;
  contactSaveError: string | undefined;
  contactError: string | undefined;
  contactEditDetail: ContactEditDetailModel | undefined;
  contactDetail: PersonModel | undefined;
}

const initialState: IContactState = {
  contactSearchParams: defaultContactSearchParamsModel(),
  contactSearchView: SearchView.Default,
  contactSearchSort: 'Relevance',
  contactResultLoading: false,
  contactResultError: undefined,
  contactResult: undefined,
  contactLoading: false,
  contactRefreshing: false,
  contactSaving: false,
  contactSaveError: undefined,
  contactError: undefined,
  contactEditDetail: undefined,
  contactDetail: undefined,
};

const contactsReducer = createReducer(initialState, {
  [CONTACT_SEARCH.CHANGE_VIEW]: (state, action: PayloadAction<string>) => {
    state.contactSearchView = action.payload;
  },
  [CONTACT_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<ContactSearchParamsModel>>) => {
    state.contactSearchParams = action.payload.searchParams;
    state.contactResultError = undefined;
    state.contactResultLoading = true;
  },
  [CONTACT_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<ContactSearchParamsModel>>) => {
    state.contactSearchParams = action.payload.searchParams;
    state.contactResultError = undefined;
  },
  [CONTACT_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.contactResultError = action.payload;
    state.contactResultLoading = false;
  },
  [CONTACT_SEARCH.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.contactResult = action.payload;
    state.contactResultLoading = false;
    state.contactResultError = undefined;
  },
  [CONTACT.LOAD]: (state) => {
    state.contactLoading = true;
    state.contactRefreshing = false;
    state.contactError = undefined;
    state.contactSaveError = undefined;
    state.contactDetail = undefined;
  },
  [CONTACT.REFRESH]: (state) => {
    state.contactLoading = false;
    state.contactRefreshing = true;
    state.contactError = undefined;
    state.contactSaveError = undefined;
  },
  [CONTACT.LOADED]: (state, action: PayloadAction<PersonModel>) => {
    state.contactLoading = false;
    state.contactRefreshing = false;
    state.contactError = undefined;
    state.contactSaveError = undefined;
    state.contactDetail = action.payload;
  },
  [CONTACT.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.contactLoading = false;
    state.contactRefreshing = false;
    state.contactError = action.payload;
    state.contactSaveError = undefined;
  },
  [CONTACT.SAVE]: (state) => {
    state.contactSaving = true;
    state.contactError = undefined;
    state.contactSaveError = undefined;
  },
  [CONTACT.SAVED]: (state, action: PayloadAction<ContactPersonModel>) => {
    state.contactSaving = false;
    state.contactError = undefined;
    state.contactSaveError = undefined;
    state.contactDetail = action.payload;
  },
  [CONTACT.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.contactSaving = false;
    state.contactError = undefined;
    state.contactSaveError = action.payload;
  },
});

export default contactsReducer;