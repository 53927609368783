export const defaultForHSPPlacementStepModel = () => {
  return HSPPlacementStepModel.fromJS({
    title: '',
    shortTitle: '',
    isGeneratePlacement: false,
    isAttachmentRequired: false,
    isVisible: true,
    completedByTasks: [],
    generatesTasks: [],
    finaliseTasks:  [],
    generatesAchievements: [],
  });
}

export class HSPPlacementStepModel implements IHSPPlacementStepModel {
  /** identity of step */
  stepId?: string | undefined;
  /** This determines if the placement popup follows hitting this status */
  isGeneratePlacement?: boolean | undefined;
  /** Will the user be forced to supply a file */
  isAttachmentRequired?: boolean | undefined;
  /** Title of the step */
  title!: string;
  /** Short name of this step */
  shortTitle!: string;
  /** Student App will see this step */
  isVisible?: boolean | undefined;
  /** Tip for student completion */
  tip?: string | undefined;
  completedByTasks?: string[] | undefined;
  generatesTasks?: string[] | undefined;
  finaliseTasks?: string[] | undefined;
  generatesAchievements?: string[] | undefined;

  constructor(data?: IHSPPlacementStepModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.stepId = Data["stepId"];
      this.isGeneratePlacement = Data["isGeneratePlacement"];
      this.isAttachmentRequired = Data["isAttachmentRequired"];
      this.title = Data["title"];
      this.shortTitle = Data["shortTitle"];
      this.isVisible = Data["isVisible"];
      this.tip = Data["tip"];
      if (Array.isArray(Data["completedByTasks"])) {
        this.completedByTasks = [] as any;
        for (let item of Data["completedByTasks"])
          this.completedByTasks!.push(item);
      }
      if (Array.isArray(Data["generatesTasks"])) {
        this.generatesTasks = [] as any;
        for (let item of Data["generatesTasks"])
          this.generatesTasks!.push(item);
      }
      if (Array.isArray(Data["finaliseTasks"])) {
        this.finaliseTasks = [] as any;
        for (let item of Data["finaliseTasks"])
          this.finaliseTasks!.push(item);
      }
      if (Array.isArray(Data["generatesAchievements"])) {
        this.generatesAchievements = [] as any;
        for (let item of Data["generatesAchievements"])
          this.generatesAchievements!.push(item);
      }
    }
  }

  static fromJS(data: any): HSPPlacementStepModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPPlacementStepModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["stepId"] = this.stepId;
    data["isGeneratePlacement"] = this.isGeneratePlacement;
    data["isAttachmentRequired"] = this.isAttachmentRequired;
    data["title"] = this.title;
    data["shortTitle"] = this.shortTitle;
    data["isVisible"] = this.isVisible;
    data["tip"] = this.tip;
    if (Array.isArray(this.completedByTasks)) {
      data["completedByTasks"] = [];
      for (let item of this.completedByTasks)
        data["completedByTasks"].push(item);
    }
    if (Array.isArray(this.generatesTasks)) {
      data["generatesTasks"] = [];
      for (let item of this.generatesTasks)
        data["generatesTasks"].push(item);
    }
    if (Array.isArray(this.finaliseTasks)) {
      data["finaliseTasks"] = [];
      for (let item of this.finaliseTasks)
        data["finaliseTasks"].push(item);
    }
    if (Array.isArray(this.generatesAchievements)) {
      data["generatesAchievements"] = [];
      for (let item of this.generatesAchievements)
        data["generatesAchievements"].push(item);
    }
    return data;
  }
}

export interface IHSPPlacementStepModel {
  /** identity of step */
  stepId?: string | undefined;
  /** This determines if the placement popup follows hitting this status */
  isGeneratePlacement?: boolean | undefined;
  /** Will the user be forced to supply a file */
  isAttachmentRequired?: boolean | undefined;
  /** Title of the step */
  title: string;
  /** Short name of this step */
  shortTitle: string;
  /** Student App will see this step */
  isVisible?: boolean | undefined;
  /** Tip for student completion */
  tip?: string | undefined;
  completedByTasks?: string[] | undefined;
  generatesTasks?: string[] | undefined;
  finaliseTasks?: string[] | undefined;
  generatesAchievements?: string[] | undefined;
}