export class ElasticUserDetailModel implements IElasticUserDetailModel {
  userHandle?: string | undefined;
  signature?: string | undefined;
  currentTeamId?: string | undefined;
  currentSubTeamId?: string | undefined;
  team?: string | undefined;
  subTeam?: string | undefined;
  isSystemAdmin?: boolean | undefined;
  isDisabled?: boolean | undefined;
  isMailingListApprover?: boolean | undefined;

  constructor(data?: IElasticUserDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.userHandle = Data["userHandle"];
      this.signature = Data["signature"];
      this.currentTeamId = Data["currentTeamId"];
      this.currentSubTeamId = Data["currentSubTeamId"];
      this.team = Data["team"];
      this.subTeam = Data["subTeam"];
      this.isSystemAdmin = Data["isSystemAdmin"];
      this.isDisabled = Data["isDisabled"];
      this.isMailingListApprover = Data["isMailingListApprover"];
    }
  }

  static fromJS(data: any): ElasticUserDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new ElasticUserDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["userHandle"] = this.userHandle;
    data["signature"] = this.signature;
    data["currentTeamId"] = this.currentTeamId;
    data["currentSubTeamId"] = this.currentSubTeamId;
    data["team"] = this.team;
    data["subTeam"] = this.subTeam;
    data["isSystemAdmin"] = this.isSystemAdmin;
    data["isDisabled"] = this.isDisabled;
    data["isMailingListApprover"] = this.isMailingListApprover;
    return data;
  }
}

export interface IElasticUserDetailModel {
  userHandle?: string | undefined;
  signature?: string | undefined;
  currentTeamId?: string | undefined;
  currentSubTeamId?: string | undefined;
  team?: string | undefined;
  subTeam?: string | undefined;
  isSystemAdmin?: boolean | undefined;
  isDisabled?: boolean | undefined;
  isMailingListApprover?: boolean | undefined;
}