import {HSPOpportunityDetailModel} from '../models/HSPOpportunityDetailModel';
import {HSPPoolItem} from './HSPPoolItem';
import {HSPOpportunityStudentView} from '../models/HSPOpportunityStudentView';

export class HSPStudentOpportunityListView implements IHSPStudentOpportunityListView {
  hspOpportunity?: HSPOpportunityDetailModel | undefined;
  hspOpportunityStudent?: HSPOpportunityStudentView | undefined;
  poolStudents?: HSPPoolItem[] | undefined;

  constructor(data?: IHSPStudentOpportunityListView) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.hspOpportunity = Data["hspOpportunity"] ? HSPOpportunityDetailModel.fromJS(Data["hspOpportunity"]) : <any>undefined;
      this.hspOpportunityStudent = Data["hspOpportunityStudent"] ? HSPOpportunityStudentView.fromJS(Data["hspOpportunityStudent"]) : <any>undefined;
      if (Array.isArray(Data["poolStudents"])) {
        this.poolStudents = [] as any;
        for (let item of Data["poolStudents"])
          this.poolStudents!.push(HSPPoolItem.fromJS(item));
      }
    }
  }

  static fromJS(data: any): HSPStudentOpportunityListView {
    data = typeof data === 'object' ? data : {};
    let result = new HSPStudentOpportunityListView();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["hspOpportunity"] = this.hspOpportunity ? this.hspOpportunity.toJSON() : <any>undefined;
    data["hspOpportunityStudent"] = this.hspOpportunityStudent ? this.hspOpportunityStudent.toJSON() : <any>undefined;
    if (Array.isArray(this.poolStudents)) {
      data["poolStudents"] = [];
      for (let item of this.poolStudents)
        data["poolStudents"].push(item.toJSON());
    }
    return data;
  }
}

export interface IHSPStudentOpportunityListView {
  hspOpportunity?: HSPOpportunityDetailModel | undefined;
  hspOpportunityStudent?: HSPOpportunityStudentView | undefined;
  poolStudents?: HSPPoolItem[] | undefined;
}