export class SubCategoryListModel implements ISubCategoryListModel {
  ownerId?: string | undefined;
  subCategories?: string[] | undefined;

  constructor(data?: ISubCategoryListModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.ownerId = Data["ownerId"];
      if (Array.isArray(Data["subCategories"])) {
        this.subCategories = [] as any;
        for (let item of Data["subCategories"])
          this.subCategories!.push(item);
      }
    }
  }

  static fromJS(data: any): SubCategoryListModel {
    data = typeof data === 'object' ? data : {};
    let result = new SubCategoryListModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ownerId"] = this.ownerId;
    if (Array.isArray(this.subCategories)) {
      data["subCategories"] = [];
      for (let item of this.subCategories)
        data["subCategories"].push(item);
    }
    return data;
  }
}

export interface ISubCategoryListModel {
  ownerId?: string | undefined;
  subCategories?: string[] | undefined;
}