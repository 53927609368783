import {ThreadDetailModel} from '../models/ThreadDetailModel';
import {PersonSummary} from './PersonSummary';

export class InboxSummary implements IInboxSummary {
  threadList?: ThreadDetailModel[] | undefined;
  summaries?: PersonSummary[] | undefined;

  constructor(data?: IInboxSummary) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["threadList"])) {
        this.threadList = [] as any;
        for (let item of Data["threadList"])
          this.threadList!.push(ThreadDetailModel.fromJS(item));
      }
      if (Array.isArray(Data["summaries"])) {
        this.summaries = [] as any;
        for (let item of Data["summaries"])
          this.summaries!.push(PersonSummary.fromJS(item));
      }
    }
  }

  static fromJS(data: any): InboxSummary {
    data = typeof data === 'object' ? data : {};
    let result = new InboxSummary();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.threadList)) {
      data["threadList"] = [];
      for (let item of this.threadList)
        data["threadList"].push(item.toJSON());
    }
    if (Array.isArray(this.summaries)) {
      data["summaries"] = [];
      for (let item of this.summaries)
        data["summaries"].push(item.toJSON());
    }
    return data;
  }
}

export interface IInboxSummary {
  threadList?: ThreadDetailModel[] | undefined;
  summaries?: PersonSummary[] | undefined;
}