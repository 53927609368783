import * as yup from 'yup';

export const nullableString = yup.string().nullable();

const initialiseYup = () => {
  yup.setLocale({
    mixed: {
      required: 'Required',
      default: (type: any) => `Must be a valid ${type}`,
      notType: ({ type }: any) => `Must be a valid ${type}`,
      oneOf: ({ values }: any) => `Must be one of ${values}`,
    },
    string: {
      max: ({max}: any) => `Maximum ${max} characters`,
      min: ({min}: any) => `Minimum ${min} characters`,
      email: (params: any) => 'Must be a valid email address',
      url: (params: any) => 'Must be a valid url',
    },
    number: {
      max: ({max}: any) => `Must be less than or equal to ${max}`,
      min: ({min}: any) => `Must be greater than or equal to  ${min}`,
    },
  });
}


export default initialiseYup;