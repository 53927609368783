import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {volunteerPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const formPage = React.lazy(() => import('./VolunteerEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Volunteer", volunteerPaths.VolunteerSearch)];

const volunteerRoutes = [
  new AppRouteProps("Add", volunteerPaths.VolunteerCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit", volunteerPaths.VolunteerEdit, formPage, sectionBreadcrumbs),
  new AppRouteProps("Promote", volunteerPaths.VolunteerPromote, React.lazy(() => import('./VolunteerPromote')), sectionBreadcrumbs),
  new AppRouteProps("Search", volunteerPaths.VolunteerSearch, React.lazy(() => import('./VolunteerSearch')), sectionBreadcrumbs),
  new AppRouteProps("Details", volunteerPaths.VolunteerProfile, React.lazy(() => import('./VolunteerDetails')), sectionBreadcrumbs),
]

const volunteerRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, volunteerRoutes)
];

export default volunteerRoutesConfig;
