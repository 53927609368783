export class MailingListMessageModel implements IMailingListMessageModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  emailMessage?: string | undefined;
  emailTemplateId?: string | undefined;
  sendStatus?: string | undefined;
  mailingListId?: string | undefined;

  constructor(data?: IMailingListMessageModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.emailMessage = Data["emailMessage"];
      this.emailTemplateId = Data["emailTemplateId"];
      this.sendStatus = Data["sendStatus"];
      this.mailingListId = Data["mailingListId"];
    }
  }

  static fromJS(data: any): MailingListMessageModel {
    data = typeof data === 'object' ? data : {};
    let result = new MailingListMessageModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["emailMessage"] = this.emailMessage;
    data["emailTemplateId"] = this.emailTemplateId;
    data["sendStatus"] = this.sendStatus;
    data["mailingListId"] = this.mailingListId;
    return data;
  }
}

export interface IMailingListMessageModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  emailMessage?: string | undefined;
  emailTemplateId?: string | undefined;
  sendStatus?: string | undefined;
  mailingListId?: string | undefined;
}