import {MessageScheduleRequestModel} from './MessageScheduleRequestModel';

export class MessageDraftModel implements IMessageDraftModel {
  personId?: string | undefined;
  threadId?: string | undefined;
  dateCreated?: string | undefined;
  dateUpdated?: string | undefined;
  message?: string | undefined;
  fileIds?: string[] | undefined;
  selectedTemplateId?: string | undefined;
  eventSchedule?: MessageScheduleRequestModel | undefined;

  constructor(data?: IMessageDraftModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.threadId = Data["threadId"];
      this.dateCreated = Data["dateCreated"];
      this.dateUpdated = Data["dateUpdated"];
      this.message = Data["message"];
      if (Array.isArray(Data["fileIds"])) {
        this.fileIds = [] as any;
        for (let item of Data["fileIds"])
          this.fileIds!.push(item);
      }
      this.selectedTemplateId = Data["selectedTemplateId"];
      this.eventSchedule = Data["eventSchedule"] ? MessageScheduleRequestModel.fromJS(Data["eventSchedule"]) : <any>undefined;
    }
  }

  static fromJS(data: any): MessageDraftModel {
    data = typeof data === 'object' ? data : {};
    let result = new MessageDraftModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["threadId"] = this.threadId;
    data["dateCreated"] = this.dateCreated;
    data["dateUpdated"] = this.dateUpdated;
    data["message"] = this.message;
    if (Array.isArray(this.fileIds)) {
      data["fileIds"] = [];
      for (let item of this.fileIds)
        data["fileIds"].push(item);
    }
    data["selectedTemplateId"] = this.selectedTemplateId;
    data["eventSchedule"] = this.eventSchedule ? this.eventSchedule.toJSON() : <any>undefined;
    return data;
  }
}

export interface IMessageDraftModel {
  personId?: string | undefined;
  threadId?: string | undefined;
  dateCreated?: string | undefined;
  dateUpdated?: string | undefined;
  message?: string | undefined;
  fileIds?: string[] | undefined;
  selectedTemplateId?: string | undefined;
  eventSchedule?: MessageScheduleRequestModel | undefined;
}
