import {ICommonLoadActionType, ICommonSaveActionType, ICommonSavePayload} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {PersonModel} from '../../types/models/PersonModel';
import {WorkShadowPersonEditModel} from '../../types/requests/WorkShadowEditModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IWorkShadowSearchParamsModel} from '../../types/requests/WorkShadowSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {PersonSearchResultModel} from '../../types/views/PersonSearchResultModel';
import {RollbackWorkShadowPersonEditModel} from '../../types/requests/RollbackWorkShadowPersonEditModel';
import {IHSPPlacementDetailModel} from '../../types/models/HSPPlacementDetailModel';
import {IStudentListActionTypes, MY_STUDENTS, STUDENT_OPPORTUNITIES, STUDENT_STUDENT_POOLS} from '../students/Actions';
import {IHSPPoolItem} from '../../types/views/HSPPoolItem';
import {IStudentOpportunityListView} from '../../types/views/StudentOpportunityListView';
import {IStudentSearchParamsModel} from '../../types/requests/StudentSearchParamsModel';

export interface IWorkShadowActionTypes extends ICommonSaveActionType {
  REFRESH: string;
  ROLLBACK: string;
}

export interface IWorkShadowSearchActionTypes extends ICommonLoadActionType {
  REFRESH: string;
  CHANGE_VIEW: string;
}


export const WORKSHADOW: IWorkShadowActionTypes = {
  LOAD: 'workShadow/load',
  LOAD_ERROR: 'workShadow/loadError',
  LOADED: 'workShadow/loadSuccess',
  REFRESH: 'workShadow/refresh',
  SAVE: 'workShadow/save',
  ROLLBACK:'workShadow/rollback',
  SAVED: 'workShadow/saveSuccess',
  SAVE_ERROR: 'workShadow/saveError',
};

export const WORKSHADOW_SEARCH: IWorkShadowSearchActionTypes = {
  ERROR: 'workShadowSearch/error',
  LOAD: 'workShadowSearch/load',
  LOADED: 'workShadowSearch/loaded',
  REFRESH: 'workShadowSearch/refresh',
  CHANGE_VIEW: 'workShadowSearch/changeView',
};

export const HSP_STUDENT_POOLS: IWorkShadowSearchActionTypes = {
  ERROR: 'hsp/hspStudentPools/error',
  LOAD: 'hsp/hspStudentPools/load',
  LOADED: 'hsp/hspStudentPools/loaded',
  REFRESH: 'hsp/hspStudentPools/refresh',
  CHANGE_VIEW: 'hspStudentPools/changeView',
};

export const HSP_OPPORTUNITIES: IWorkShadowSearchActionTypes = {
  ERROR: 'hsp/hspStudentOpportunities/error',
  LOAD: 'hsp/hspStudentOpportunities/load',
  LOADED: 'hsp/hspStudentOpportunities/loaded',
  REFRESH: 'hsp/hspStudentOpportunities/refresh',
  CHANGE_VIEW: 'hspStudentOpportunities/changeView',
};

export const HSP_PLACEMENTS: IWorkShadowSearchActionTypes = {
  ERROR: 'hspPlacements/placements/error',
  LOAD: 'hspPlacements/placements/load',
  LOADED: 'hspPlacements/placements/loaded',
  REFRESH: 'hspPlacements/placements/refresh',
  CHANGE_VIEW: 'hspPlacements/changeView',
};

export const MY_HSP_STUDENTS: ICommonLoadActionType = {
  LOAD: 'myHSPStudents/load',
  ERROR: 'myHSPStudents/error',
  LOADED: 'myHSPStudents/success',
}


export const workShadowLoad = createAction<string>(WORKSHADOW.LOAD);
export const workShadowRefresh = createAction<string>(WORKSHADOW.REFRESH);
export const workShadowLoadSuccess = createAction<PersonModel>(WORKSHADOW.LOADED);
export const workShadowLoadError = createAction<string>(WORKSHADOW.LOAD_ERROR);

export const workShadowSave = createAction<ICommonSavePayload<WorkShadowPersonEditModel>>(WORKSHADOW.SAVE);
export const workShadowSaveSuccess = createAction<PersonModel>(WORKSHADOW.SAVED);
export const workShadowSaveError = createAction<string>(WORKSHADOW.SAVE_ERROR);

export const workShadowRollback = createAction<ICommonSavePayload<RollbackWorkShadowPersonEditModel>>(WORKSHADOW.ROLLBACK);

export const workShadowsSearch = createAction<ISearchRequest<IWorkShadowSearchParamsModel>>(WORKSHADOW_SEARCH.LOAD);
export const workShadowsSearchView = createAction<string>(WORKSHADOW_SEARCH.CHANGE_VIEW);
export const workShadowsSearchRefresh = createAction<ISearchRequest<IWorkShadowSearchParamsModel>>(WORKSHADOW_SEARCH.REFRESH);
export const workShadowsSearchError = createAction<string>(WORKSHADOW_SEARCH.ERROR);
export const workShadowsSearchSuccess = createAction<IPagedResult<PersonSearchResultModel>>(WORKSHADOW_SEARCH.LOADED);

export const hspPlacementsLoad = createAction<string>(HSP_PLACEMENTS.LOAD);
export const hspPlacementsRefresh = createAction<string>(HSP_PLACEMENTS.REFRESH);
export const hspPlacementsLoadSuccess = createAction<IHSPPlacementDetailModel[]>(HSP_PLACEMENTS.LOADED);
export const hspPlacementsLoadError = createAction<string>(HSP_PLACEMENTS.ERROR);

export const hspPoolsLoad = createAction<string>(HSP_STUDENT_POOLS.LOAD);
export const hspPoolsRefresh = createAction<string>(HSP_STUDENT_POOLS.REFRESH);
export const hspPoolsLoadSuccess = createAction<IHSPPoolItem[]>(HSP_STUDENT_POOLS.LOADED);
export const hspPoolsLoadError = createAction<string>(HSP_STUDENT_POOLS.ERROR);

export const hspOpportunitiesLoad = createAction<string>(HSP_OPPORTUNITIES.LOAD);
export const hspOpportunitiesRefresh = createAction<string>(HSP_OPPORTUNITIES.REFRESH);
export const hspOpportunitiesLoadSuccess = createAction<IStudentOpportunityListView[]>(HSP_OPPORTUNITIES.LOADED);
export const hspOpportunitiesLoadError = createAction<string>(HSP_OPPORTUNITIES.ERROR);

export const myHSPStudentsRequest = createAction<ISearchRequest<IWorkShadowSearchParamsModel>>(MY_HSP_STUDENTS.LOAD);
export const myHSPStudentsError = createAction<string>(MY_HSP_STUDENTS.ERROR);
export const myHSPStudentsSuccess = createAction<IPagedResult<PersonSearchResultModel>>(MY_HSP_STUDENTS.LOADED);