export class PersonSummary implements IPersonSummary {
  eventContext?: string | undefined;
  totalEvents?: string | undefined;
  incompleteEvents?: string | undefined;
  unreadEvents?: string | undefined;

  constructor(data?: IPersonSummary) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.eventContext = Data["eventContext"];
      this.totalEvents = Data["totalEvents"];
      this.incompleteEvents = Data["incompleteEvents"];
      this.unreadEvents = Data["unreadEvents"];
    }
  }

  static fromJS(data: any): PersonSummary {
    data = typeof data === 'object' ? data : {};
    let result = new PersonSummary();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["eventContext"] = this.eventContext;
    data["totalEvents"] = this.totalEvents;
    data["incompleteEvents"] = this.incompleteEvents;
    data["unreadEvents"] = this.unreadEvents;
    return data;
  }
}

export interface IPersonSummary {
  eventContext?: string | undefined;
  totalEvents?: string | undefined;
  incompleteEvents?: string | undefined;
  unreadEvents?: string | undefined;
}