import {PhoneModelPhoneType} from './Enums';

export class PhoneModel implements IPhoneModel {
  /** Phone type */
  phoneType?: PhoneModelPhoneType | undefined;
  /** The phone number */
  number?: string | undefined;

  constructor(data?: IPhoneModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.phoneType = Data["phoneType"];
      this.number = Data["number"];
    }
  }

  static fromJS(data: any): PhoneModel {
    data = typeof data === 'object' ? data : {};
    let result = new PhoneModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["phoneType"] = this.phoneType;
    data["number"] = this.number;
    return data;
  }
}

export interface IPhoneModel {
  /** Phone type */
  phoneType?: PhoneModelPhoneType | undefined;
  /** The phone number */
  number?: string | undefined;
}