import {createSelector} from 'reselect';
import {IHistoryState} from './Reducer';

export const selectHistoryState = (state: { history: IHistoryState }) => state.history;


export const  selectHistoryLoading = createSelector(
  selectHistoryState,
  (subState) => subState.historyLoading
);

export const  selectHistorySaving = createSelector(
  selectHistoryState,
  (subState) => subState.historySaving
);

export const  selectHistoryLoadingError = createSelector(
  selectHistoryState,
  (subState) => subState.historyLoadingError
);

export const  selectHistorySavingError = createSelector(
  selectHistoryState,
  (subState) => subState.historySavingError
);

export const  selectHistoryDetail = createSelector(
  selectHistoryState,
  (subState) => subState.historyDetail
);
