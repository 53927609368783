export class HSPSeasonalStudentModel implements IHSPSeasonalStudentModel {
  personId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  interviewAvailability?: string | undefined;
  internshipDates?: string | undefined;

  constructor(data?: IHSPSeasonalStudentModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.interviewAvailability = Data["interviewAvailability"];
      this.internshipDates = Data["internshipDates"];
    }
  }

  static fromJS(data: any): HSPSeasonalStudentModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPSeasonalStudentModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["interviewAvailability"] = this.interviewAvailability;
    data["internshipDates"] = this.internshipDates;
    return data;
  }
}

export interface IHSPSeasonalStudentModel {
  personId?: string | undefined;
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  interviewAvailability?: string | undefined;
  internshipDates?: string | undefined;
}