import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {COMPANY_CACHE, COMPANY_LOGO_CACHE} from './Actions';
import {CompanyListItem} from '../../types/views/CompanyListItem';
import {ImageMetadataModel} from '../../types/models/ImageMetadataModel';

export interface ICompaniesCacheState {
  companyCacheUpdating: boolean | false;
  companyCacheError: string | undefined;
  companyPendingIdList: string[];
  companyCache: CompanyListItem[];
  companyLogoPendingIdList: string[];
  companyLogoCache: ImageMetadataModel[];
}

const initialState: ICompaniesCacheState = {
  companyCacheUpdating: false,
  companyCacheError: undefined,
  companyPendingIdList: [],
  companyLogoPendingIdList: [],
  companyCache: [],
  companyLogoCache: []
}

const companiesCacheReducer = createReducer(initialState, {
  [COMPANY_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.companyPendingIdList = [...new Set([...state.companyPendingIdList, ...action.payload])];
    state.companyCacheError = undefined;
  },
  [COMPANY_CACHE.UPDATE]: (state) => {
    state.companyPendingIdList = [];
    state.companyCacheUpdating = true;
    state.companyCacheError = undefined;
  },
  [COMPANY_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<CompanyListItem[]>) => {
    let currentCache = state.companyCache;
    action.payload.map(item => {
      if (!currentCache.find(x => x.id === item.id)) {
        currentCache.push(item);
      }
    });
    state.companyCache = currentCache;
    state.companyCacheUpdating = false;
    state.companyCacheError = undefined;
  },
  [COMPANY_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.companyCache = state.companyCache.filter(x => x.id !== action.payload);
    state.companyCacheUpdating = false;
    state.companyCacheError = undefined;
  },
  [COMPANY_LOGO_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.companyLogoPendingIdList = [...new Set(...state.companyLogoPendingIdList, ...action.payload)];
    state.companyCacheError = undefined;
  },
  [COMPANY_LOGO_CACHE.UPDATE]: (state) => {
    state.companyLogoPendingIdList = [];
    state.companyCacheError = undefined;
  },
  [COMPANY_LOGO_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<ImageMetadataModel[]>) => {
    let currentLogoCache = state.companyLogoCache;
    action.payload.forEach(item => {
      if (!currentLogoCache.find(x => x.imageId === item.imageId)) {
        currentLogoCache.push(item);
      }
    });
    state.companyLogoCache = currentLogoCache;
    state.companyCacheError = undefined;
  },
  [COMPANY_LOGO_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.companyLogoCache = state.companyCache.filter(x => x.id !== action.payload);
    state.companyCacheError = undefined;
  },
});

export default companiesCacheReducer;
