export class OpportunityPoolStudentViewModel implements IOpportunityPoolStudentViewModel {
  personId?: string | undefined;
  dateCreated?: string | undefined;
  poolPercentComplete?: number | undefined;
  poolStepsComplete?: number | undefined;
  poolStudentStatus?: string | undefined;

  constructor(data?: IOpportunityPoolStudentViewModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.dateCreated = Data["dateCreated"];
      this.poolPercentComplete = Data["poolPercentComplete"];
      this.poolStepsComplete = Data["poolStepsComplete"];
      this.poolStudentStatus = Data["poolStudentStatus"];
    }
  }

  static fromJS(data: any): OpportunityPoolStudentViewModel {
    data = typeof data === 'object' ? data : {};
    let result = new OpportunityPoolStudentViewModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["dateCreated"] = this.dateCreated;
    data["poolPercentComplete"] = this.poolPercentComplete;
    data["poolStepsComplete"] = this.poolStepsComplete;
    data["poolStudentStatus"] = this.poolStudentStatus;
    return data;
  }
}

export interface IOpportunityPoolStudentViewModel {
  personId?: string | undefined;
  dateCreated?: string | undefined;
  poolPercentComplete?: number | undefined;
  poolStepsComplete?: number | undefined;
  poolStudentStatus?: string | undefined;
}