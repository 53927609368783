import {HSPOpportunityDetailModel} from '../models/HSPOpportunityDetailModel';

export class HSPOpportunitiesDetailModel implements IHSPOpportunitiesDetailModel {
  companyId?: string | undefined;
  opportunities?: HSPOpportunityDetailModel[] | undefined;
  opportunityCount?: number | undefined;

  constructor(data?: IHSPOpportunitiesDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.companyId = Data["companyId"];
      if (Array.isArray(Data["opportunities"])) {
        this.opportunities = [] as any;
        for (let item of Data["opportunities"])
          this.opportunities!.push(HSPOpportunityDetailModel.fromJS(item));
      }
      this.opportunityCount = Data["opportunityCount"];
    }
  }

  static fromJS(data: any): HSPOpportunitiesDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPOpportunitiesDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["companyId"] = this.companyId;
    if (Array.isArray(this.opportunities)) {
      data["opportunities"] = [];
      for (let item of this.opportunities)
        data["opportunities"].push(item.toJSON());
    }
    data["opportunityCount"] = this.opportunityCount;
    return data;
  }
}

export interface IHSPOpportunitiesDetailModel {
  companyId?: string | undefined;
  opportunities?: HSPOpportunityDetailModel[] | undefined;
  opportunityCount?: number | undefined;
}