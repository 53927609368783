import {IPagedResult} from '../../types/common/PagedResult';
import {ElasticUserPersonModel} from '../../types/views/ElasticUserPersonModel';
import {UserDetailModel} from '../../types/models/UserDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {TEAM, USER, USER_SEARCH} from './Actions';
import {ElasticUserResultPaged} from '../../types/views/ElasticUserResultPaged';
import {TeamDetailModel} from '../../types/models/TeamDetailModel';


export interface ITeamState {
  usersResultLoading: boolean,
  usersResultError: string | undefined;
  usersResult: IPagedResult<ElasticUserPersonModel> | undefined,
  userLoading: boolean,
  userSaving: boolean,
  userSaveError: string | undefined,
  userError: string | undefined,
  userDetail: UserDetailModel | undefined,

  teamSaving: boolean,
  teamSaveError: string | undefined,
}

const initialState: ITeamState = {
  usersResultLoading: false,
  usersResultError: undefined,
  usersResult: undefined,

  userLoading: false,
  userSaving: false,
  userSaveError: undefined,
  userError: undefined,
  userDetail: undefined,

  teamSaving: false,
  teamSaveError: undefined,
}

const teamReducer = createReducer(initialState, {
  [USER.LOAD]: (state) => {
    state.userError = undefined;
    state.userLoading = true;
    state.userDetail = undefined;
  },
  [USER.LOADED]: (state, action: PayloadAction<UserDetailModel>) => {
    state.userError = undefined;
    state.userLoading = false;
    state.userDetail = action.payload;
  },
  [USER.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.userError = action.payload;
    state.userLoading = false;
  },
  [USER.SAVE]: (state) => {
    state.userSaving = true;
    state.userError = undefined;
    state.userSaveError = undefined;
  },
  [USER.SAVED]: (state, action: PayloadAction<UserDetailModel>) => {
    state.userSaving = false;
    state.userError = undefined;
    state.userSaveError = undefined;
    state.userDetail = action.payload;
  },
  [USER.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.userSaving = false;
    state.userError = undefined;
    state.userSaveError = action.payload;
  },
  [USER_SEARCH.LOAD]: (state) => {
    state.usersResultError = undefined;
    state.usersResultLoading = true;
  },
  [USER_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.usersResultError = action.payload;
    state.usersResultLoading = false;
  },
  [USER_SEARCH.LOADED]: (state, action: PayloadAction<ElasticUserResultPaged>) => {
    state.usersResult = action.payload;
    state.usersResultLoading = false;
    state.usersResultError = undefined;
  },

  [TEAM.SAVE]: (state) => {
    state.teamSaving = true;
    state.teamSaveError = undefined;
  },
  [TEAM.SAVED]: (state, action: PayloadAction<TeamDetailModel>) => {
    state.teamSaving = false;
    state.teamSaveError = undefined;
  },
  [TEAM.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.teamSaving = false;
    state.teamSaveError = action.payload;
  },

});

export default teamReducer;
