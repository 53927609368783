import {RecipientPerson} from './RecipientPerson';
import {RecipientCompany} from './RecipientCompany';

export class ActivityGroupModel implements IActivityGroupModel {
  id?: string | undefined;
  /** This entity belongs to a user and is a way of grouping user inboxes */
  personId?: string | undefined;
  /** The owner id of this conversation */
  ownerId?: string | undefined;
  dtLastActivity?: Date | undefined;
  recipientGroup?: string | undefined;
  companyId?: string | undefined;
  /** Row key & id is a composite key which ensures correct storage in cassandra */
  lastEventId?: string | undefined;
  /** Row key& id is a composite key which ensures correct storage in cassandra */
  lastEventRowKey?: string | undefined;
  /** If the conversation is fully read */
  isRead?: boolean | undefined;
  isMessagesRead?: boolean | undefined;
  /** number of pending tasks */
  incomplete?: number | undefined;
  hasFiles?: boolean | undefined;
  lastThreadId?: string | undefined;
  /** People in this conversation */
  recipientPeopleMetadata?: RecipientPerson[] | undefined;
  /** Companies in this conversation */
  recipientCompaniesMetadata?: RecipientCompany[] | undefined;
  lastContent?: string | undefined;
  lastSubject?: string | undefined;
  isArchived?: boolean | undefined;
  dtArchived?: Date | undefined;
  unread?: number | undefined;
  unreadMessages?: number | undefined;

  constructor(data?: IActivityGroupModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.personId = Data["personId"];
      this.ownerId = Data["ownerId"];
      this.dtLastActivity = Data["dtLastActivity"] ? new Date(Data["dtLastActivity"].toString()) : <any>undefined;
      this.recipientGroup = Data["recipientGroup"];
      this.companyId = Data["companyId"];
      this.lastEventId = Data["lastEventId"];
      this.lastEventRowKey = Data["lastEventRowKey"];
      this.isRead = Data["isRead"];
      this.isMessagesRead = Data["isMessagesRead"];
      this.incomplete = Data["incomplete"];
      this.hasFiles = Data["hasFiles"];
      this.lastThreadId = Data["lastThreadId"];
      if (Array.isArray(Data["recipientPeopleMetadata"])) {
        this.recipientPeopleMetadata = [] as any;
        for (let item of Data["recipientPeopleMetadata"])
          this.recipientPeopleMetadata!.push(RecipientPerson.fromJS(item));
      }
      if (Array.isArray(Data["recipientCompaniesMetadata"])) {
        this.recipientCompaniesMetadata = [] as any;
        for (let item of Data["recipientCompaniesMetadata"])
          this.recipientCompaniesMetadata!.push(RecipientCompany.fromJS(item));
      }
      this.lastContent = Data["lastContent"];
      this.lastSubject = Data["lastSubject"];
      this.isArchived = Data["isArchived"];
      this.dtArchived = Data["dtArchived"] ? new Date(Data["dtArchived"].toString()) : <any>undefined;
      this.unread = Data["unread"];
      this.unreadMessages = Data["unreadMessages"];
    }
  }

  static fromJS(data: any): ActivityGroupModel {
    data = typeof data === 'object' ? data : {};
    let result = new ActivityGroupModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["personId"] = this.personId;
    data["ownerId"] = this.ownerId;
    data["dtLastActivity"] = this.dtLastActivity ? this.dtLastActivity.toISOString() : <any>undefined;
    data["recipientGroup"] = this.recipientGroup;
    data["companyId"] = this.companyId;
    data["lastEventId"] = this.lastEventId;
    data["lastEventRowKey"] = this.lastEventRowKey;
    data["isRead"] = this.isRead;
    data["isMessagesRead"] = this.isMessagesRead;
    data["incomplete"] = this.incomplete;
    data["hasFiles"] = this.hasFiles;
    data["lastThreadId"] = this.lastThreadId;
    if (Array.isArray(this.recipientPeopleMetadata)) {
      data["recipientPeopleMetadata"] = [];
      for (let item of this.recipientPeopleMetadata)
        data["recipientPeopleMetadata"].push(item.toJSON());
    }
    if (Array.isArray(this.recipientCompaniesMetadata)) {
      data["recipientCompaniesMetadata"] = [];
      for (let item of this.recipientCompaniesMetadata)
        data["recipientCompaniesMetadata"].push(item.toJSON());
    }
    data["lastContent"] = this.lastContent;
    data["lastSubject"] = this.lastSubject;
    data["isArchived"] = this.isArchived;
    data["dtArchived"] = this.dtArchived ? this.dtArchived.toISOString() : <any>undefined;
    data["unread"] = this.unread;
    data["unreadMessages"] = this.unreadMessages;
    return data;
  }
}

export interface IActivityGroupModel {
  id?: string | undefined;
  /** This entity belongs to a user and is a way of grouping user inboxes */
  personId?: string | undefined;
  /** The owner id of this conversation */
  ownerId?: string | undefined;
  dtLastActivity?: Date | undefined;
  recipientGroup?: string | undefined;
  companyId?: string | undefined;
  /** Row key & id is a composite key which ensures correct storage in cassandra */
  lastEventId?: string | undefined;
  /** Row key& id is a composite key which ensures correct storage in cassandra */
  lastEventRowKey?: string | undefined;
  /** If the conversation is fully read */
  isRead?: boolean | undefined;
  isMessagesRead?: boolean | undefined;
  /** number of pending tasks */
  incomplete?: number | undefined;
  hasFiles?: boolean | undefined;
  lastThreadId?: string | undefined;
  /** People in this conversation */
  recipientPeopleMetadata?: RecipientPerson[] | undefined;
  /** Companies in this conversation */
  recipientCompaniesMetadata?: RecipientCompany[] | undefined;
  lastContent?: string | undefined;
  lastSubject?: string | undefined;
  isArchived?: boolean | undefined;
  dtArchived?: Date | undefined;
  unread?: number | undefined;
  unreadMessages?: number | undefined;
}