import {PhoneModel} from '../models/PhoneModel';

export class PersonListItem implements IPersonListItem {
  /** Id */
  id?: string | undefined;
  /** First name of user */
  firstName?: string | undefined;
  /** Last name */
  lastName?: string | undefined;
  fullName?: string | undefined;
  email?: string | undefined;
  /** Phone numbers */
  phoneNumbers?: PhoneModel[] | undefined;
  /** if this person is connected */
  isUser?: boolean | undefined;
  /** System handle for user - used in messaging */
  userHandle?: string | undefined;
  /** Id of team this user belongs to */
  currentTeamId?: string | undefined;
  /** The user photo from their account (facebook or google) */
  picture?: string | undefined;
  isStudent?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  /** Person is a contact */
  isClient?: boolean | undefined;
  companyId?: string | undefined;
  /** Person is a volunteer */
  isVolunteer?: boolean | undefined;

  constructor(data?: IPersonListItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.firstName = Data["firstName"];
      this.lastName = Data["lastName"];
      this.fullName = Data["fullName"];
      this.email = Data["email"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.isUser = Data["isUser"];
      this.userHandle = Data["userHandle"];
      this.currentTeamId = Data["currentTeamId"];
      this.picture = Data["picture"];
      this.isStudent = Data["isStudent"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.isAlumni = Data["isAlumni"];
      this.isClient = Data["isClient"];
      this.companyId = Data["companyId"];
      this.isVolunteer = Data["isVolunteer"];
    }
  }

  static fromJS(data: any): PersonListItem {
    data = typeof data === 'object' ? data : {};
    let result = new PersonListItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["firstName"] = this.firstName;
    data["lastName"] = this.lastName;
    data["fullName"] = this.fullName;
    data["email"] = this.email;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item.toJSON());
    }
    data["isUser"] = this.isUser;
    data["userHandle"] = this.userHandle;
    data["currentTeamId"] = this.currentTeamId;
    data["picture"] = this.picture;
    data["isStudent"] = this.isStudent;
    data["isWorkShadow"] = this.isWorkShadow;
    data["isAlumni"] = this.isAlumni;
    data["isClient"] = this.isClient;
    data["companyId"] = this.companyId;
    data["isVolunteer"] = this.isVolunteer;
    return data;
  }
}

export interface IPersonListItem {
  /** Id */
  id?: string | undefined;
  /** First name of user */
  firstName?: string | undefined;
  /** Last name */
  lastName?: string | undefined;
  fullName?: string | undefined;
  email?: string | undefined;
  /** Phone numbers */
  phoneNumbers?: PhoneModel[] | undefined;
  /** if this person is connected */
  isUser?: boolean | undefined;
  /** System handle for user - used in messaging */
  userHandle?: string | undefined;
  /** Id of team this user belongs to */
  currentTeamId?: string | undefined;
  /** The user photo from their account (facebook or google) */
  picture?: string | undefined;
  isStudent?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  /** Person is a contact */
  isClient?: boolean | undefined;
  companyId?: string | undefined;
  /** Person is a volunteer */
  isVolunteer?: boolean | undefined;
}