import {EventReferenceField} from './EventReferenceField';
import {EventItemViewFiles} from './EventItemViewFiles';
import {EventItemTrackingDetail} from './EventItemTrackingDetail';
import {toLocalTime} from '../../@template/helpers/datetimes';

/*
 * WARNING!
 * Messaging models need to have their TZ data adjusted.
 * If replacing this model, you need to apply the toLocaltime function for any date parsing
 */
export class EventItemViewModel implements IEventItemViewModel {
  /** Identity of the owner of this side of the conversation */
  ownerId?: string | undefined;
  id?: string | undefined;
  activityRowKey?: string | undefined;
  activityId?: string | undefined;
  /** The enum value for the event type */
  eventContext!: string;
  /** Thread id */
  threadId?: string | undefined;
  dtCreated?: Date | undefined;
  eventDate?: Date | undefined;
  /** Category for this event. Usually the same as the type */
  eventCategory?: string | undefined;
  /** Category reference id */
  eventCategoryRef?: string | undefined;
  /** Sub category type */
  eventSubCategory?: string | undefined;
  /** Sub category reference field */
  eventSubCategoryRef?: string | undefined;
  actionReference?: string | undefined;
  /** Any non-functional references like onboarding steps or other info */
  refFields?: EventReferenceField[] | undefined;
  /** Any metadata for related objects that should be searched */
  refContent?: string[] | undefined;
  /** ActionItemMessage content */
  message?: string | undefined;
  isPending?: boolean | undefined;
  /** Flag reserved for system generated events */
  isSystem?: boolean | undefined;
  isComplete?: boolean | undefined;
  isRead?: boolean | undefined;
  dtCompleted?: Date | undefined;
  dtDue?: Date | undefined;
  priority?: number | undefined;
  completedPersonId?: string | undefined;
  completedStatus?: string | undefined;
  completedByActivityId?: string | undefined;
  /** Person who created the event */
  authorPersonId?: string | undefined;
  /** Company id of author if there is one */
  authorCompanyId?: string | undefined;
  /** Set to true if the author was an advisor */
  authorInternal?: boolean | undefined;
  /** Team id of the person who triggered the event */
  authorTeamId?: string | undefined;
  /** Set to true if action schedule is attached. */
  hasSchedule?: boolean | undefined;
  actionScheduleId?: string | undefined;
  /** Person id of the people who should receive the message */
  ownerRecipients?: string[] | undefined;
  /** The files attached to this message */
  files?: EventItemViewFiles[] | undefined;
  outgoingTrackItem?: EventItemTrackingDetail | undefined;
  incomingTrackItem?: EventItemTrackingDetail | undefined;

  constructor(data?: IEventItemViewModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.ownerId = Data["ownerId"];
      this.id = Data["id"];
      this.activityRowKey = Data["activityRowKey"];
      this.activityId = Data["activityId"];
      this.eventContext = Data["eventContext"];
      this.threadId = Data["threadId"];
      this.dtCreated = Data["dtCreated"] ? toLocalTime(Data["dtCreated"].toString()) : <any>undefined;
      this.eventDate = Data["eventDate"] ? toLocalTime(Data["eventDate"].toString()) : <any>undefined;
      this.eventCategory = Data["eventCategory"];
      this.eventCategoryRef = Data["eventCategoryRef"];
      this.eventSubCategory = Data["eventSubCategory"];
      this.eventSubCategoryRef = Data["eventSubCategoryRef"];
      this.actionReference = Data["actionReference"];
      if (Array.isArray(Data["refFields"])) {
        this.refFields = [] as any;
        for (let item of Data["refFields"])
          this.refFields!.push(EventReferenceField.fromJS(item));
      }
      if (Array.isArray(Data["refContent"])) {
        this.refContent = [] as any;
        for (let item of Data["refContent"])
          this.refContent!.push(item);
      }
      this.message = Data["message"];
      this.isPending = Data["isPending"];
      this.isSystem = Data["isSystem"];
      this.isComplete = Data["isComplete"];
      this.isRead = Data["isRead"];
      this.dtCompleted = Data["dtCompleted"] ? toLocalTime(Data["dtCompleted"].toString()) : <any>undefined;
      this.dtDue = Data["dtDue"] ? toLocalTime(Data["dtDue"].toString()) : <any>undefined;
      this.priority = Data["priority"];
      this.completedPersonId = Data["completedPersonId"];
      this.completedStatus = Data["completedStatus"];
      this.completedByActivityId = Data["completedByActivityId"];
      this.authorPersonId = Data["authorPersonId"];
      this.authorCompanyId = Data["authorCompanyId"];
      this.authorInternal = Data["authorInternal"];
      this.authorTeamId = Data["authorTeamId"];
      this.hasSchedule = Data["hasSchedule"];
      this.actionScheduleId = Data["actionScheduleId"];
      if (Array.isArray(Data["ownerRecipients"])) {
        this.ownerRecipients = [] as any;
        for (let item of Data["ownerRecipients"])
          this.ownerRecipients!.push(item);
      }
      if (Array.isArray(Data["files"])) {
        this.files = [] as any;
        for (let item of Data["files"])
          this.files!.push(EventItemViewFiles.fromJS(item));
      }
      this.outgoingTrackItem = Data["outgoingTrackItem"] ? EventItemTrackingDetail.fromJS(Data["outgoingTrackItem"]) : <any>undefined;
      this.incomingTrackItem = Data["incomingTrackItem"] ? EventItemTrackingDetail.fromJS(Data["incomingTrackItem"]) : <any>undefined;
    }
  }

  static fromJS(data: any): EventItemViewModel {
    data = typeof data === 'object' ? data : {};
    let result = new EventItemViewModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ownerId"] = this.ownerId;
    data["id"] = this.id;
    data["activityRowKey"] = this.activityRowKey;
    data["activityId"] = this.activityId;
    data["eventContext"] = this.eventContext;
    data["threadId"] = this.threadId;
    data["dtCreated"] = this.dtCreated ? this.dtCreated.toISOString() : <any>undefined;
    data["eventDate"] = this.eventDate ? this.eventDate.toISOString() : <any>undefined;
    data["eventCategory"] = this.eventCategory;
    data["eventCategoryRef"] = this.eventCategoryRef;
    data["eventSubCategory"] = this.eventSubCategory;
    data["eventSubCategoryRef"] = this.eventSubCategoryRef;
    data["actionReference"] = this.actionReference;
    if (Array.isArray(this.refFields)) {
      data["refFields"] = [];
      for (let item of this.refFields)
        data["refFields"].push(item.toJSON());
    }
    if (Array.isArray(this.refContent)) {
      data["refContent"] = [];
      for (let item of this.refContent)
        data["refContent"].push(item);
    }
    data["message"] = this.message;
    data["isPending"] = this.isPending;
    data["isSystem"] = this.isSystem;
    data["isComplete"] = this.isComplete;
    data["isRead"] = this.isRead;
    data["dtCompleted"] = this.dtCompleted ? this.dtCompleted.toISOString() : <any>undefined;
    data["dtDue"] = this.dtDue ? this.dtDue.toISOString() : <any>undefined;
    data["priority"] = this.priority;
    data["completedPersonId"] = this.completedPersonId;
    data["completedStatus"] = this.completedStatus;
    data["completedByActivityId"] = this.completedByActivityId;
    data["authorPersonId"] = this.authorPersonId;
    data["authorCompanyId"] = this.authorCompanyId;
    data["authorInternal"] = this.authorInternal;
    data["authorTeamId"] = this.authorTeamId;
    data["hasSchedule"] = this.hasSchedule;
    data["actionScheduleId"] = this.actionScheduleId;
    if (Array.isArray(this.ownerRecipients)) {
      data["ownerRecipients"] = [];
      for (let item of this.ownerRecipients)
        data["ownerRecipients"].push(item);
    }
    if (Array.isArray(this.files)) {
      data["files"] = [];
      for (let item of this.files)
        data["files"].push(item.toJSON());
    }
    data["outgoingTrackItem"] = this.outgoingTrackItem ? this.outgoingTrackItem.toJSON() : <any>undefined;
    data["incomingTrackItem"] = this.incomingTrackItem ? this.incomingTrackItem.toJSON() : <any>undefined;
    return data;
  }
}

export interface IEventItemViewModel {
  /** Identity of the owner of this side of the conversation */
  ownerId?: string | undefined;
  id?: string | undefined;
  activityRowKey?: string | undefined;
  activityId?: string | undefined;
  /** The enum value for the event type */
  eventContext: string;
  /** Thread id */
  threadId?: string | undefined;
  dtCreated?: Date | undefined;
  eventDate?: Date | undefined;
  /** Category for this event. Usually the same as the type */
  eventCategory?: string | undefined;
  /** Category reference id */
  eventCategoryRef?: string | undefined;
  /** Sub category type */
  eventSubCategory?: string | undefined;
  /** Sub category reference field */
  eventSubCategoryRef?: string | undefined;
  actionReference?: string | undefined;
  /** Any non-functional references like onboarding steps or other info */
  refFields?: EventReferenceField[] | undefined;
  /** Any metadata for related objects that should be searched */
  refContent?: string[] | undefined;
  /** ActionItemMessage content */
  message?: string | undefined;
  isPending?: boolean | undefined;
  /** Flag reserved for system generated events */
  isSystem?: boolean | undefined;
  isComplete?: boolean | undefined;
  isRead?: boolean | undefined;
  dtCompleted?: Date | undefined;
  dtDue?: Date | undefined;
  priority?: number | undefined;
  completedPersonId?: string | undefined;
  completedStatus?: string | undefined;
  completedByActivityId?: string | undefined;
  /** Person who created the event */
  authorPersonId?: string | undefined;
  /** Company id of author if there is one */
  authorCompanyId?: string | undefined;
  /** Set to true if the author was an advisor */
  authorInternal?: boolean | undefined;
  /** Team id of the person who triggered the event */
  authorTeamId?: string | undefined;
  /** Set to true if action schedule is attached. */
  hasSchedule?: boolean | undefined;
  actionScheduleId?: string | undefined;
  /** Person id of the people who should receive the message */
  ownerRecipients?: string[] | undefined;
  /** The files attached to this message */
  files?: EventItemViewFiles[] | undefined;
  outgoingTrackItem?: EventItemTrackingDetail | undefined;
  incomingTrackItem?: EventItemTrackingDetail | undefined;
}
