import makeStyles from '@mui/styles/makeStyles';
import {Fonts, ThemeMode} from '../../../../shared/constants/AppEnums';
import {AppTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: AppTheme) => {
  return {
    navItem: {
      height: 40,
      marginTop: 2,
      marginBottom: 2,
      textDecoration: 'none !important',
      width: 'calc(100% - 16px)',
      borderRadius: '0 20px 20px 0',
      paddingLeft: (props: {level: number; themeMode: ThemeMode}) => 24 + 40 * props.level,
      paddingRight: 12,
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
      '& .nav-item-icon': {
        color: theme.palette.text.primary,
      },
      '& .nav-item-text': {
        color: theme.palette.text.primary,
        fontWeight: Fonts.BOLD,
      },
    },
    listIcon: {
      fontSize: 18,
      [theme.breakpoints.up('xl')]: {
        fontSize: 22,
      },
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: Fonts.BOLD,
    },
  };
});
export default useStyles;
