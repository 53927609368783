import {AdvisorUserModel} from './AdvisorUserModel';
import {formatDate} from '../../@template/helpers/datetimes';

export class EngagementInfoDetailModel implements IEngagementInfoDetailModel {
    isActive?: boolean | undefined;
    deactivatedReason?: string | undefined;
    deactivatedNotes?: string | undefined;
    inactivatedReason?: string | undefined;
    inactivatedNotes?: string | undefined;
    isAppEnabled?: boolean | undefined;
    /** People who are following this student */
    advisorUsers?: AdvisorUserModel[] | undefined;
    languageGroup?: string | undefined;
    heritage?: string | undefined;
    dateOfBirth?: Date | undefined;
    studentSource?: string | undefined;
    registrationSource?: string | undefined;

    constructor(data?: IEngagementInfoDetailModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.isActive = Data["isActive"];
            this.deactivatedReason = Data["deactivatedReason"];
            this.deactivatedNotes = Data["deactivatedNotes"];
            this.inactivatedReason = Data["inactivatedReason"];
            this.inactivatedNotes = Data["inactivatedNotes"];
            this.isAppEnabled = Data["isAppEnabled"];
            if (Array.isArray(Data["advisorUsers"])) {
                this.advisorUsers = [] as any;
                for (let item of Data["advisorUsers"])
                    this.advisorUsers!.push(AdvisorUserModel.fromJS(item));
            }
            this.languageGroup = Data["languageGroup"];
            this.heritage = Data["heritage"];
            this.dateOfBirth = Data["dateOfBirth"] ? new Date(Data["dateOfBirth"].toString()) : <any>undefined;
            this.studentSource = Data["studentSource"];
            this.registrationSource = Data["registrationSource"];
        }
    }

    static fromJS(data: any): EngagementInfoDetailModel {
        data = typeof data === 'object' ? data : {};
        let result = new EngagementInfoDetailModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["isActive"] = this.isActive;
        data["deactivatedReason"] = this.deactivatedReason;
        data["deactivatedNotes"] = this.deactivatedNotes;
        data["inactivatedReason"] = this.inactivatedReason;
        data["inactivatedNotes"] = this.inactivatedNotes;
        data["isAppEnabled"] = this.isAppEnabled;
        if (Array.isArray(this.advisorUsers)) {
            data["advisorUsers"] = [];
            for (let item of this.advisorUsers)
                data["advisorUsers"].push(item.toJSON());
        }
        data["languageGroup"] = this.languageGroup;
        data["heritage"] = this.heritage;
        data["dateOfBirth"] = this.dateOfBirth ? formatDate(this.dateOfBirth) : <any>undefined;
        data["studentSource"] = this.studentSource;
        data["registrationSource"] = this.registrationSource;
        return data;
    }
}

export interface IEngagementInfoDetailModel {
    isActive?: boolean | undefined;
    deactivatedReason?: string | undefined;
    deactivatedNotes?: string | undefined;
    inactivatedReason?: string | undefined;
    inactivatedNotes?: string | undefined;
    isAppEnabled?: boolean | undefined;
    /** People who are following this student */
    advisorUsers?: AdvisorUserModel[] | undefined;
    languageGroup?: string | undefined;
    heritage?: string | undefined;
    dateOfBirth?: Date | undefined;
    studentSource?: string | undefined;
    registrationSource?: string | undefined;
}