export class StudentCareerQuestionnaireModel implements IStudentCareerQuestionnaireModel {
  documentHeader?: string | undefined;
  categoryInterests?: string | undefined;
  categoryPersonality?: string | undefined;
  personalityCompassionate?: number | undefined;
  personalityPerfectionist?: number | undefined;
  personalityImagine?: number | undefined;
  personalityExplanations?: number | undefined;
  personalityExcitement?: number | undefined;
  personalityMoments?: number | undefined;
  personalityInstructions?: number | undefined;
  personalitySensible?: number | undefined;
  categorySkillsAbilities?: string | undefined;
  categoryAttitudesValues?: string | undefined;
  categoryPersonalCircumstances?: string | undefined;

  constructor(data?: IStudentCareerQuestionnaireModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.documentHeader = Data["documentHeader"];
      this.categoryInterests = Data["categoryInterests"];
      this.categoryPersonality = Data["categoryPersonality"];
      this.personalityCompassionate = Data["personalityCompassionate"];
      this.personalityPerfectionist = Data["personalityPerfectionist"];
      this.personalityImagine = Data["personalityImagine"];
      this.personalityExplanations = Data["personalityExplanations"];
      this.personalityExcitement = Data["personalityExcitement"];
      this.personalityMoments = Data["personalityMoments"];
      this.personalityInstructions = Data["personalityInstructions"];
      this.personalitySensible = Data["personalitySensible"];
      this.categorySkillsAbilities = Data["categorySkillsAbilities"];
      this.categoryAttitudesValues = Data["categoryAttitudesValues"];
      this.categoryPersonalCircumstances = Data["categoryPersonalCircumstances"];
    }
  }

  static fromJS(data: any): StudentCareerQuestionnaireModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentCareerQuestionnaireModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["documentHeader"] = this.documentHeader;
    data["categoryInterests"] = this.categoryInterests;
    data["categoryPersonality"] = this.categoryPersonality;
    data["personalityCompassionate"] = this.personalityCompassionate;
    data["personalityPerfectionist"] = this.personalityPerfectionist;
    data["personalityImagine"] = this.personalityImagine;
    data["personalityExplanations"] = this.personalityExplanations;
    data["personalityExcitement"] = this.personalityExcitement;
    data["personalityMoments"] = this.personalityMoments;
    data["personalityInstructions"] = this.personalityInstructions;
    data["personalitySensible"] = this.personalitySensible;
    data["categorySkillsAbilities"] = this.categorySkillsAbilities;
    data["categoryAttitudesValues"] = this.categoryAttitudesValues;
    data["categoryPersonalCircumstances"] = this.categoryPersonalCircumstances;
    return data;
  }
}

export interface IStudentCareerQuestionnaireModel {
  documentHeader?: string | undefined;
  categoryInterests?: string | undefined;
  categoryPersonality?: string | undefined;
  personalityCompassionate?: number | undefined;
  personalityPerfectionist?: number | undefined;
  personalityImagine?: number | undefined;
  personalityExplanations?: number | undefined;
  personalityExcitement?: number | undefined;
  personalityMoments?: number | undefined;
  personalityInstructions?: number | undefined;
  personalitySensible?: number | undefined;
  categorySkillsAbilities?: string | undefined;
  categoryAttitudesValues?: string | undefined;
  categoryPersonalCircumstances?: string | undefined;
}