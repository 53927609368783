import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {teamPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';


const userFormPage = React.lazy(() => import('./UserEdit'));
const teamFormPage = React.lazy(() => import('./TeamActions/TeamEditDialog'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("Team management", teamPaths.UserSearch)];

const userRoutes = [
  new AppRouteProps('Edit advisor', teamPaths.UserEdit, userFormPage, []),
  new AppRouteProps('Add advisor', teamPaths.UserCreate, userFormPage, []),
  new AppRouteProps('Manage advisors', teamPaths.UserSearch, React.lazy(() => import('./UserSearch')), []),
  new AppRouteProps('Manage teams', teamPaths.TeamManage, React.lazy(() => import('./TeamManage')), []),
]

const teamPathsConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, userRoutes)
];

export default teamPathsConfig;
