export class PlacementTravelDates implements IPlacementTravelDates {
  id?: string | undefined;
  createdByUserId?: string | undefined;
  seasonYear?: number | undefined;
  season?: number | undefined;
  checkinDate?: Date | undefined;
  checkoutDate?: Date | undefined;
  note?: string | undefined;

  constructor(data?: IPlacementTravelDates) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.createdByUserId = Data["createdByUserId"];
      this.seasonYear = Data["seasonYear"];
      this.season = Data["season"];
      this.checkinDate = Data["checkinDate"] ? new Date(Data["checkinDate"].toString()) : <any>undefined;
      this.checkoutDate = Data["checkoutDate"] ? new Date(Data["checkoutDate"].toString()) : <any>undefined;
      this.note = Data["note"];
    }
  }

  static fromJS(data: any): PlacementTravelDates {
    data = typeof data === 'object' ? data : {};
    let result = new PlacementTravelDates();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createdByUserId"] = this.createdByUserId;
    data["seasonYear"] = this.seasonYear;
    data["season"] = this.season;
    data["checkinDate"] = this.checkinDate ? this.checkinDate.toISOString() : <any>undefined;
    data["checkoutDate"] = this.checkoutDate ? this.checkoutDate.toISOString() : <any>undefined;
    data["note"] = this.note;
    return data;
  }
}

export interface IPlacementTravelDates {
  id?: string | undefined;
  createdByUserId?: string | undefined;
  seasonYear?: number | undefined;
  season?: number | undefined;
  checkinDate?: Date | undefined;
  checkoutDate?: Date | undefined;
  note?: string | undefined;
}