import {IPagedResult} from '../../types/common/PagedResult';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {SearchRequest} from '../../types/common/SearchRequest';
import {
  defaultCompanyInternshipScorecardParamsModel,
  defaultEngagementScorecardParamsModel, defaultInternshipScorecardParamsModel,
  defaultRecruitmentScorecardParamsModel,
  ScorecardParamsModel,
} from '../../types/requests/ScorecardParamsModel';

import {
  COMPANY_INTERNSHIP_SCORECARD,
  ENGAGEMENT_SCORECARD,
  INTERNSHIP_SCORECARD,
  RECRUITMENT_SCORECARD, SCORECARD_PRESET,
  HSP_ENGAGEMENT_SCORECARD
} from './Actions';
import {ScorecardPresetModel} from '../../types/models/ScorecardPresetModel';
import {PersonSearchResultModel} from '../../types/views/PersonSearchResultModel';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {STUDENT} from '../students/Actions';


export interface IScorecardState {
  scorecardPresetSaving: boolean,
  scorecardPresetLoading: boolean,
  scorecardPresetError: string | undefined;
  scorecardPresetResults: ScorecardPresetModel[] | undefined;
  scorecardPresetSaveError: string | undefined;
  scorecardPresetRefreshing: boolean,
  recruitmentScorecardSearchParams: ScorecardParamsModel | undefined,
  recruitmentScorecardLoading: boolean,
  recruitmentScorecardError: string | undefined;
  recruitmentScorecardResults: IPagedResult<PersonSearchResultModel> | undefined;
  engagementScorecardSearchParams: ScorecardParamsModel | undefined,
  engagementScorecardLoading: boolean,
  engagementScorecardError: string | undefined;
  engagementScorecardResults: IPagedResult<PersonSearchResultModel> | undefined;
  hspEngagementScorecardSearchParams: ScorecardParamsModel | undefined,
  hspEngagementScorecardLoading: boolean,
  hspEngagementScorecardError: string | undefined;
  hspEngagementScorecardResults: IPagedResult<PersonSearchResultModel> | undefined;
  internshipScorecardSearchParams: ScorecardParamsModel | undefined,
  internshipScorecardLoading: boolean,
  internshipScorecardError: string | undefined;
  internshipScorecardResults: IPagedResult<PersonSearchResultModel> | undefined;
  companyInternshipScorecardSearchParams: ScorecardParamsModel | undefined,
  companyInternshipScorecardLoading: boolean,
  companyInternshipScorecardError: string | undefined;
  companyInternshipScorecardResults: IPagedResult<PersonSearchResultModel> | undefined;
}

const initialState: IScorecardState = {
  scorecardPresetSaving: false,
  scorecardPresetLoading: false,
  scorecardPresetError: undefined,
  scorecardPresetResults: undefined,
  scorecardPresetSaveError: undefined,
  scorecardPresetRefreshing: false,
  recruitmentScorecardSearchParams: undefined,
  recruitmentScorecardLoading: false,
  recruitmentScorecardError: undefined,
  recruitmentScorecardResults: undefined,
  engagementScorecardSearchParams: undefined,
  engagementScorecardLoading: false,
  engagementScorecardError: undefined,
  engagementScorecardResults: undefined,
  hspEngagementScorecardSearchParams: undefined,
  hspEngagementScorecardLoading: false,
  hspEngagementScorecardError: undefined,
  hspEngagementScorecardResults: undefined,
  internshipScorecardSearchParams: undefined,
  internshipScorecardLoading: false,
  internshipScorecardError: undefined,
  internshipScorecardResults: undefined,
  companyInternshipScorecardSearchParams: undefined,
  companyInternshipScorecardLoading: false,
  companyInternshipScorecardError: undefined,
  companyInternshipScorecardResults: undefined,
};

const scorecardReducer = createReducer(initialState, {
  [RECRUITMENT_SCORECARD.LOAD]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.recruitmentScorecardSearchParams = action.payload.searchParams;
    state.recruitmentScorecardError = undefined;
    state.recruitmentScorecardLoading = true;
  },
  [RECRUITMENT_SCORECARD.REFRESH]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.recruitmentScorecardSearchParams = action.payload.searchParams;
    state.recruitmentScorecardError = undefined;
  },
  [RECRUITMENT_SCORECARD.ERROR]: (state, action: PayloadAction<string>) => {
    state.recruitmentScorecardError = action.payload;
    state.recruitmentScorecardLoading = false;
  },
  [RECRUITMENT_SCORECARD.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.recruitmentScorecardResults = action.payload;
    state.recruitmentScorecardLoading = false;
    state.recruitmentScorecardError = undefined;
  },

  [ENGAGEMENT_SCORECARD.LOAD]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.engagementScorecardSearchParams = action.payload.searchParams;
    state.engagementScorecardError = undefined;
    state.engagementScorecardLoading = true;
  },
  [ENGAGEMENT_SCORECARD.REFRESH]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.engagementScorecardSearchParams = action.payload.searchParams;
    state.engagementScorecardError = undefined;
  },
  [ENGAGEMENT_SCORECARD.ERROR]: (state, action: PayloadAction<string>) => {
    state.engagementScorecardError = action.payload;
    state.engagementScorecardLoading = false;
  },
  [ENGAGEMENT_SCORECARD.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.engagementScorecardResults = action.payload;
    state.engagementScorecardLoading = false;
    state.engagementScorecardError = undefined;
  },

  [HSP_ENGAGEMENT_SCORECARD.LOAD]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.hspEngagementScorecardSearchParams = action.payload.searchParams;
    state.hspEngagementScorecardError = undefined;
    state.hspEngagementScorecardLoading = true;
  },
  [HSP_ENGAGEMENT_SCORECARD.REFRESH]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.hspEngagementScorecardSearchParams = action.payload.searchParams;
    state.hspEngagementScorecardError = undefined;
  },
  [HSP_ENGAGEMENT_SCORECARD.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspEngagementScorecardError = action.payload;
    state.hspEngagementScorecardLoading = false;
  },
  [HSP_ENGAGEMENT_SCORECARD.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.hspEngagementScorecardResults = action.payload;
    state.hspEngagementScorecardLoading = false;
    state.hspEngagementScorecardError = undefined;
  },

  [INTERNSHIP_SCORECARD.LOAD]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.internshipScorecardSearchParams = action.payload.searchParams;
    state.internshipScorecardError = undefined;
    state.internshipScorecardLoading = true;
  },
  [INTERNSHIP_SCORECARD.REFRESH]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.internshipScorecardSearchParams = action.payload.searchParams;
    state.internshipScorecardError = undefined;
  },
  [INTERNSHIP_SCORECARD.ERROR]: (state, action: PayloadAction<string>) => {
    state.internshipScorecardError = action.payload;
    state.internshipScorecardLoading = false;
  },
  [INTERNSHIP_SCORECARD.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.internshipScorecardResults = action.payload;
    state.internshipScorecardLoading = false;
    state.internshipScorecardError = undefined;
  },

  [COMPANY_INTERNSHIP_SCORECARD.LOAD]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.companyInternshipScorecardSearchParams = action.payload.searchParams;
    state.companyInternshipScorecardError = undefined;
    state.companyInternshipScorecardLoading = true;
  },
  [COMPANY_INTERNSHIP_SCORECARD.REFRESH]: (state, action: PayloadAction<SearchRequest<ScorecardParamsModel>>) => {
    state.companyInternshipScorecardSearchParams = action.payload.searchParams;
    state.companyInternshipScorecardError = undefined;
  },
  [COMPANY_INTERNSHIP_SCORECARD.ERROR]: (state, action: PayloadAction<string>) => {
    state.companyInternshipScorecardError = action.payload;
    state.companyInternshipScorecardLoading = false;
  },
  [COMPANY_INTERNSHIP_SCORECARD.LOADED]: (state, action: PayloadAction<PersonSearchResultPaged>) => {
    state.companyInternshipScorecardResults = action.payload;
    state.companyInternshipScorecardLoading = false;
    state.companyInternshipScorecardError = undefined;
  },

  [SCORECARD_PRESET.LOAD]: (state) => {
    state.scorecardPresetLoading = true;
    state.scorecardPresetRefreshing = false;
    state.scorecardPresetError = undefined;
    state.scorecardPresetSaveError = undefined;
    state.scorecardPresetResults = undefined;
  },
  [SCORECARD_PRESET.LOADED]: (state, action: PayloadAction<ScorecardPresetModel[]>) => {
    state.scorecardPresetLoading = false;
    state.scorecardPresetRefreshing = false;
    state.scorecardPresetError = undefined;
    state.scorecardPresetSaveError = undefined;
    state.scorecardPresetResults = action.payload;
  },
  [SCORECARD_PRESET.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.scorecardPresetLoading = false;
    state.scorecardPresetRefreshing = false;
    state.scorecardPresetError = action.payload;
    state.scorecardPresetSaveError = undefined;
  },
  [SCORECARD_PRESET.SAVE]: (state) => {
    state.scorecardPresetSaving = true;
    state.scorecardPresetError = undefined;
    state.scorecardPresetSaveError = undefined;
  },
  [SCORECARD_PRESET.SAVED]: (state) => {
    state.scorecardPresetSaving = false;
    state.scorecardPresetError = undefined;
    state.scorecardPresetSaveError = undefined;
  },
  [SCORECARD_PRESET.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.scorecardPresetSaving = false;
    state.scorecardPresetError = undefined;
    state.scorecardPresetSaveError = action.payload;
  },
  [SCORECARD_PRESET.REFRESH]: (state) => {
    state.scorecardPresetLoading = false;
    state.scorecardPresetRefreshing = true;
    state.scorecardPresetError = undefined;
    state.scorecardPresetSaveError = undefined;
  },
  [SCORECARD_PRESET.DELETE]: (state) => {
    state.scorecardPresetLoading = true;
    state.scorecardPresetError = undefined;
    state.scorecardPresetSaveError = undefined;
  },
});

export default scorecardReducer;
