export class TaskModel implements ITaskModel {
  actionName?: string | undefined;
  actionType?: string | undefined;
  title?: string | undefined;
  internalCompletion?: boolean | undefined;
  isOnboarding?: boolean | undefined;
  isGraduating?: boolean | undefined;
  isPool?: boolean | undefined;
  isPlacement?: boolean | undefined;
  canAutoCreate?: boolean | undefined;
  canAutoComplete?: boolean | undefined;
  canAutoFinalise?: boolean | undefined;
  requirePlacement?: boolean | undefined;
  description?: string | undefined;
  defaultMessage?: string | undefined;
  isManual?: boolean | undefined;
  isSeasonal?: boolean | undefined;
  canEnabledEvent?: boolean | undefined;
  isEventFlexible?: boolean | undefined;
  dueDateRequired?: boolean | undefined;
  infoRequired?: boolean | undefined;
  attachmentRequired?: boolean | undefined;
  adminOnly?: boolean | undefined;
  isLinked?: boolean | undefined;
  linkedThreadType?: string | undefined;
  defaultFileTags?: string[] | undefined;

  constructor(data?: ITaskModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.actionName = Data["actionName"];
      this.actionType = Data["actionType"];
      this.title = Data["title"];
      this.internalCompletion = Data["internalCompletion"];
      this.isOnboarding = Data["isOnboarding"];
      this.isGraduating = Data["isGraduating"];
      this.isPool = Data["isPool"];
      this.isPlacement = Data["isPlacement"];
      this.canAutoCreate = Data["canAutoCreate"];
      this.canAutoComplete = Data["canAutoComplete"];
      this.canAutoFinalise = Data["canAutoFinalise"];
      this.requirePlacement = Data["requirePlacement"];
      this.description = Data["description"];
      this.defaultMessage = Data["defaultMessage"];
      this.isManual = Data["isManual"];
      this.isSeasonal = Data["isSeasonal"];
      this.canEnabledEvent = Data["canEnabledEvent"];
      this.isEventFlexible = Data["isEventFlexible"];
      this.dueDateRequired = Data["dueDateRequired"];
      this.infoRequired = Data["infoRequired"];
      this.attachmentRequired = Data["attachmentRequired"];
      this.adminOnly = Data["adminOnly"];
      this.isLinked = Data["isLinked"];
      this.linkedThreadType = Data["linkedThreadType"];
      if (Array.isArray(Data["defaultFileTags"])) {
        this.defaultFileTags = [] as any;
        for (let item of Data["defaultFileTags"])
          this.defaultFileTags!.push(item);
      }
    }
  }

  static fromJS(data: any): TaskModel {
    data = typeof data === 'object' ? data : {};
    let result = new TaskModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["actionName"] = this.actionName;
    data["actionType"] = this.actionType;
    data["title"] = this.title;
    data["internalCompletion"] = this.internalCompletion;
    data["isOnboarding"] = this.isOnboarding;
    data["isGraduating"] = this.isGraduating;
    data["isPool"] = this.isPool;
    data["isPlacement"] = this.isPlacement;
    data["canAutoCreate"] = this.canAutoCreate;
    data["canAutoComplete"] = this.canAutoComplete;
    data["canAutoFinalise"] = this.canAutoFinalise;
    data["requirePlacement"] = this.requirePlacement;
    data["description"] = this.description;
    data["defaultMessage"] = this.defaultMessage;
    data["isManual"] = this.isManual;
    data["isSeasonal"] = this.isSeasonal;
    data["canEnabledEvent"] = this.canEnabledEvent;
    data["isEventFlexible"] = this.isEventFlexible;
    data["dueDateRequired"] = this.dueDateRequired;
    data["infoRequired"] = this.infoRequired;
    data["attachmentRequired"] = this.attachmentRequired;
    data["adminOnly"] = this.adminOnly;
    data["isLinked"] = this.isLinked;
    data["linkedThreadType"] = this.linkedThreadType;
    if (Array.isArray(this.defaultFileTags)) {
      data["defaultFileTags"] = [];
      for (let item of this.defaultFileTags)
        data["defaultFileTags"].push(item);
    }
    return data;
  }
}

export interface ITaskModel {
  actionName?: string | undefined;
  actionType?: string | undefined;
  title?: string | undefined;
  internalCompletion?: boolean | undefined;
  isOnboarding?: boolean | undefined;
  isGraduating?: boolean | undefined;
  isPool?: boolean | undefined;
  isPlacement?: boolean | undefined;
  canAutoCreate?: boolean | undefined;
  canAutoComplete?: boolean | undefined;
  canAutoFinalise?: boolean | undefined;
  requirePlacement?: boolean | undefined;
  description?: string | undefined;
  defaultMessage?: string | undefined;
  isManual?: boolean | undefined;
  isSeasonal?: boolean | undefined;
  canEnabledEvent?: boolean | undefined;
  isEventFlexible?: boolean | undefined;
  dueDateRequired?: boolean | undefined;
  infoRequired?: boolean | undefined;
  attachmentRequired?: boolean | undefined;
  adminOnly?: boolean | undefined;
  isLinked?: boolean | undefined;
  linkedThreadType?: string | undefined;
  defaultFileTags?: string[] | undefined;
}