import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {workShadowPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const formPage = React.lazy(() => import('./WorkShadowEdit'));

const sectionBreadcrumbs = [new BreadcrumbItemProps("High School Program Students", workShadowPaths.WorkShadowSearch)];


const workShadowRoutes = [
  new AppRouteProps("Add student", workShadowPaths.WorkShadowCreate, formPage, sectionBreadcrumbs),
  new AppRouteProps("Edit student", workShadowPaths.WorkShadowEdit, formPage, sectionBreadcrumbs),
  new AppRouteProps("Promote", workShadowPaths.WorkShadowPromote, React.lazy(() => import('./WorkShadowPromote')), sectionBreadcrumbs),
  new AppRouteProps("Rollback", workShadowPaths.WorkShadowRollback, React.lazy(() => import('./WorkShadowRollback')), sectionBreadcrumbs),
  new AppRouteProps("Search", workShadowPaths.WorkShadowSearch, React.lazy(() => import('./WorkShadowSearch')), sectionBreadcrumbs),
]

const workShadowRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, workShadowRoutes)
];

export default workShadowRoutesConfig;
