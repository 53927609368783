import React from 'react';
import {authPaths} from './paths';
import {AppRouteGroupProps, AppRouteProps} from '../../types/AppRoutesPropType';
import {PermissionLevel} from '../../types/common/Permissions';

const authRoutes = [
  new AppRouteProps("Sign in", authPaths.SignIn, React.lazy(() => import('./SignIn')), [], PermissionLevel.NONE),
  new AppRouteProps("Error 404", authPaths.Error404, React.lazy(() => import('../errorPages/Error404/index')), [], PermissionLevel.NONE),
]

const authRouteConfig = [new AppRouteGroupProps(PermissionLevel.NONE, authRoutes)];
export default authRouteConfig;
