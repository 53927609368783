import {ICommonLoadActionType, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IPagedResult} from '../../types/common/PagedResult';
import {FileSearchParams} from '../../types/requests/FileSearchParams';
import {FileMetaModel} from '../../types/models/FileMetaModel';


export interface IFilesActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const FILES: IFilesActionTypes = {
  ERROR: 'files/error',
  LOAD: 'files/search',
  LOADED: 'files/searchSuccess',
  REFRESH: 'files/searchRefresh'
};

export const filesSearch = createAction<ISearchRequest<FileSearchParams>>(FILES.LOAD);
export const filesSearchRefresh = createAction<ISearchRequest<FileSearchParams>>(FILES.REFRESH);
export const filesSearchSuccess = createAction<IPagedResult<FileMetaModel>>(FILES.LOADED);
export const filesSearchError = createAction<string>(FILES.ERROR);
