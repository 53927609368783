import React, {useContext, useState} from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppRightInbox from './AppRightInbox';
import {ContentView} from '../../../index';
import Box from '@mui/material/Box';
import useStyles from './index.style';
import AppFixedFooter from './AppFixedFooter';
import AppContext from '../../../utility/AppContext';
import clsx from 'clsx';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import useRecursiveTimeout from '../../../utility/AppPolling';
import {groupsNotificationRefresh, notificationsLoadRefresh} from '../../../../redux/messaging/Actions';
import {useSelector} from 'react-redux';
import {
  selectGroupsNotificationError,
  selectGroupsNotificationLoading, selectGroupsNotificationRefreshing,
  selectNotificationsSummaryError,
  selectNotificationsSummaryLoading, selectNotificationsSummaryRefreshing,
} from '../../../../redux/messaging/Selectors';
import {useAppDispatch} from '../../../utility/AppHooks';
import {contactChangeRequestRefresh, studentChangeRequestRefresh} from '../../../../redux/changeRequest/Actions';

interface MiniSidebarToggleProps {}

const MiniSidebarToggle: React.FC<MiniSidebarToggleProps> = () => {
  const timeoutInterval = 10000;
  const dispatch = useAppDispatch();

  const [refreshCount, setRefreshCount] = useState(0);

  const {footer, themeStyle, layoutType, footerType} = useContext<
    AppContextPropsType
  >(AppContext);
  const classes = useStyles({footer, themeStyle});

  const notificationSummaryLoading = useSelector(selectNotificationsSummaryLoading);
  const notificationsSummaryError = useSelector(selectNotificationsSummaryError);
  const notificationsSummaryRefreshing = useSelector(selectNotificationsSummaryRefreshing);
  const groupsLoading = useSelector(selectGroupsNotificationLoading);
  const groupsError = useSelector(selectGroupsNotificationError);
  const groupsRefreshing = useSelector(selectGroupsNotificationRefreshing);

  const refreshNotifications = () => {
    // Get the latest from date
    if (!notificationSummaryLoading && !notificationsSummaryRefreshing && !notificationsSummaryError) {
      dispatch(notificationsLoadRefresh());
    }
  };

  const refreshGroups = () => {
    if (!groupsLoading && !groupsRefreshing && !groupsError) {
      dispatch(groupsNotificationRefresh());
    }
  }

  const refreshUpdates = () => {
    if(refreshCount >= 10){
      dispatch(studentChangeRequestRefresh());
      dispatch(contactChangeRequestRefresh());
      setRefreshCount(0);
    }
    else {
      setRefreshCount(refreshCount+1);
    }
  }

  useRecursiveTimeout(() =>
    new Promise(r => {
      refreshNotifications();
      refreshGroups();
      refreshUpdates();
      r();
    }), timeoutInterval);

  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppSidebar />

      <Box className={clsx(classes.mainContent, 'main-content')}>
        <Box className={classes.mainContainer}>
          <AppHeader />
          <ContentView />
          <AppFixedFooter />
        </Box>
      </Box>
      <AppRightInbox position='right' />
    </Box>
  );
};

export default MiniSidebarToggle;
