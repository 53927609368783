import React from 'react';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {AppState} from '../../../redux/store';
import {generatePath} from 'react-router';
import Link from '@mui/material/Link';
import {CompanyListItem} from '../../../types/views/CompanyListItem';
import {companyCacheAdd} from '../../../redux/companyCache/Actions';
import {companyPaths} from '../paths';
import { NavLink } from 'react-router-dom';


interface ICompanyLinkProps {
  companyId?: string | undefined,
  className?: string | undefined,
  linkProfile?: boolean | undefined,
  onClick?(): void | undefined,
}

const getCacheItem = (id: string | undefined) => (state: AppState) => {
  if (id)
    return state.companiesCache.companyCache.find((x: CompanyListItem) => x.id === id);
  return undefined;
}

const CompanyLink = ({ companyId, linkProfile, className, onClick }: ICompanyLinkProps) => {
  const dispatch = useAppDispatch();
  const [ avatarCompanyId ] = useState(companyId);

  const listItem = useSelector(getCacheItem(avatarCompanyId));

  useEffect(() => {
    if (!listItem && avatarCompanyId) {
      dispatch(companyCacheAdd([avatarCompanyId]));
    }
  }, [ avatarCompanyId ]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  if (onClick && listItem) {
    return <Box component="span" className={className}>
      <Link component="button" onClick={handleClick}>{listItem.companyName}</Link>
    </Box>;
  }
  if (listItem && linkProfile) {
    return (
      <Box component="span" className={className}>
        <NavLink to={generatePath(companyPaths.CompanyProfile, { id: listItem.id })} >{listItem.companyName}</NavLink>
      </Box>
    );
  }
  return <Box component="span" className={className}>
    {listItem?.companyName}
  </Box>;
};

export default CompanyLink;