import {PhoneModel} from './PhoneModel';
import {UserTeamData} from './UserTeamData';

export class UserDetailModel implements IUserDetailModel {
    personId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    userHandle?: string | undefined;
    signature?: string | undefined;
    picture?: string | undefined;
    email?: string | undefined;
    currentTeamId?: string | undefined;
    currentSubTeamId?: string | undefined;
    /** Phone numbers for this student */
    phoneNumbers?: PhoneModel[] | undefined;
    jobTitle?: string | undefined;
    isDisabled?: boolean | undefined;
    isSystemAdmin?: boolean | undefined;
    /** User team data */
    userTeams?: UserTeamData[] | undefined;
    isMailingListApprover?: boolean | undefined;

    constructor(data?: IUserDetailModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.personId = Data["personId"];
            this.firstName = Data["firstName"];
            this.lastName = Data["lastName"];
            this.userHandle = Data["userHandle"];
            this.signature = Data["signature"];
            this.picture = Data["picture"];
            this.email = Data["email"];
            this.currentTeamId = Data["currentTeamId"];
            this.currentSubTeamId = Data["currentSubTeamId"];
            if (Array.isArray(Data["phoneNumbers"])) {
                this.phoneNumbers = [] as any;
                for (let item of Data["phoneNumbers"])
                    this.phoneNumbers!.push(PhoneModel.fromJS(item));
            }
            this.jobTitle = Data["jobTitle"];
            this.isDisabled = Data["isDisabled"];
            this.isSystemAdmin = Data["isSystemAdmin"];
            if (Array.isArray(Data["userTeams"])) {
                this.userTeams = [] as any;
                for (let item of Data["userTeams"])
                    this.userTeams!.push(UserTeamData.fromJS(item));
            }
            this.isMailingListApprover = Data["isMailingListApprover"];
        }
    }

    static fromJS(data: any): UserDetailModel {
        data = typeof data === 'object' ? data : {};
        let result = new UserDetailModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["personId"] = this.personId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["userHandle"] = this.userHandle;
        data["signature"] = this.signature;
        data["picture"] = this.picture;
        data["email"] = this.email;
        data["currentTeamId"] = this.currentTeamId;
        data["currentSubTeamId"] = this.currentSubTeamId;
        if (Array.isArray(this.phoneNumbers)) {
            data["phoneNumbers"] = [];
            for (let item of this.phoneNumbers)
                data["phoneNumbers"].push(item.toJSON());
        }
        data["jobTitle"] = this.jobTitle;
        data["isDisabled"] = this.isDisabled;
        data["isSystemAdmin"] = this.isSystemAdmin;
        if (Array.isArray(this.userTeams)) {
            data["userTeams"] = [];
            for (let item of this.userTeams)
                data["userTeams"].push(item.toJSON());
        }
        data["isMailingListApprover"] = this.isMailingListApprover;
        return data;
    }
}

export interface IUserDetailModel {
    personId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    userHandle?: string | undefined;
    signature?: string | undefined;
    picture?: string | undefined;
    email?: string | undefined;
    currentTeamId?: string | undefined;
    currentSubTeamId?: string | undefined;
    /** Phone numbers for this student */
    phoneNumbers?: PhoneModel[] | undefined;
    jobTitle?: string | undefined;
    isDisabled?: boolean | undefined;
    isSystemAdmin?: boolean | undefined;
    /** User team data */
    userTeams?: UserTeamData[] | undefined;
    isMailingListApprover?: boolean | undefined;
}