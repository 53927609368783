import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {settingsPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const studentSectionBreadcrumbs = [new BreadcrumbItemProps("Student Settings", settingsPaths.StudentSettings)];
const employerSectionBreadcrumbs = [new BreadcrumbItemProps("Employer Settings", settingsPaths.EmployerSettings)];
const workShadowSectionBreadcrumbs = [new BreadcrumbItemProps("Work Shadow Settings", settingsPaths.WorkShadowSettings)];
const alumniSectionBreadcrumbs = [new BreadcrumbItemProps("Alumni Settings", settingsPaths.AlumniSettings)];
const educationSectionBreadcrumbs = [new BreadcrumbItemProps("Education Settings", settingsPaths.EducationSettings)];

const studentOnboardingSettingsFormPage = React.lazy(() => import('./StudentOnboardingSettingsEdit'));
// const studentPoolSettingsFormPage = React.lazy(() => import('./StudentPoolSettingsEdit'));
const studentPlacementSettingsFormPage = React.lazy(() => import('./StudentPlacementSettingsEdit'));
const studentGraduatingSettingsFormPage = React.lazy(() => import('./StudentGraduatingSettingsEdit'));
const employerOnboardingSettingsFormPage = React.lazy(() => import('./EmployerOnboardingSettingsEdit'));
const workShadowOnboardingSettingsFormPage = React.lazy(() => import('./WorkShadowOnboardingSettingsEdit'));
const workShadowPoolSettingsFormPage = React.lazy(() => import('./WorkShadowPoolSettingsEdit'));
const workShadowPlacementSettingsFormPage = React.lazy(() => import('./WorkShadowPlacementSettingsEdit'));
const highSchoolProgramParticipantSettingsFormPage = React.lazy(() => import('./HighSchoolProgramParticipantSettingsEdit'));
const alumniEarlyProfSettingsFormPage = React.lazy(() => import('./AlumniEarlyProfessionalSettingsEdit'))
const educationOnboardingSettingsFormPage = React.lazy(() => import('./EducationOnboardingSettingsEdit'));

const settingsRoutes = [
  new AppRouteProps("Manage", settingsPaths.StudentSettings, React.lazy(() => import('./StudentSettings')), studentSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.StudentOnboardingSettingsCreate, studentOnboardingSettingsFormPage, studentSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.StudentOnboardingSettingsEdit, studentOnboardingSettingsFormPage, []),
  // new AppRouteProps("Create", settingsPaths.StudentPoolSettingsCreate, studentPoolSettingsFormPage, studentSectionBreadcrumbs),
  // new AppRouteProps("Edit", settingsPaths.StudentPoolSettingsEdit, studentPoolSettingsFormPage, studentSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.StudentPlacementSettingsCreate, studentPlacementSettingsFormPage, studentSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.StudentPlacementSettingsEdit, studentPlacementSettingsFormPage, studentSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.StudentGraduatingSettingsCreate, studentGraduatingSettingsFormPage, studentSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.StudentGraduatingSettingsEdit, studentGraduatingSettingsFormPage, studentSectionBreadcrumbs),
  new AppRouteProps("Manage", settingsPaths.EmployerSettings, React.lazy(() => import('./EmployerSettings')), employerSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.EmployerOnboardingSettingsCreate, employerOnboardingSettingsFormPage, employerSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.EmployerOnboardingSettingsEdit, employerOnboardingSettingsFormPage, employerSectionBreadcrumbs),
  new AppRouteProps("Manage", settingsPaths.WorkShadowSettings, React.lazy(() => import('./WorkShadowSettings')), workShadowSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.WorkShadowOnboardingSettingsCreate, workShadowOnboardingSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.WorkShadowOnboardingSettingsEdit, workShadowOnboardingSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.WorkShadowPoolSettingsCreate, workShadowPoolSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.WorkShadowPoolSettingsEdit, workShadowPoolSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.WorkShadowPlacementSettingsCreate, workShadowPlacementSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.WorkShadowPlacementSettingsEdit, workShadowPlacementSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Tags", settingsPaths.TagDefinitionSettings, React.lazy(() => import('./TagDefinitionSettings')), []),
  new AppRouteProps("Manage", settingsPaths.AlumniSettings, React.lazy(() => import('./AlumniSettings')), alumniSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.AlumniEarlyProfSettingsCreate, alumniEarlyProfSettingsFormPage, alumniSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.AlumniEarlyProfSettingsEdit, alumniEarlyProfSettingsFormPage, alumniSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.HighSchoolProgramParticipantSettingsCreate, highSchoolProgramParticipantSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.HighSchoolProgramParticipantSettingsEdit, highSchoolProgramParticipantSettingsFormPage, workShadowSectionBreadcrumbs),
  new AppRouteProps("Manage", settingsPaths.EducationSettings, React.lazy(() => import('./EducationSettings')), educationSectionBreadcrumbs),
  new AppRouteProps("Create", settingsPaths.EducationOnboardingSettingsCreate, educationOnboardingSettingsFormPage, educationSectionBreadcrumbs),
  new AppRouteProps("Edit", settingsPaths.EducationOnboardingSettingsEdit, educationOnboardingSettingsFormPage, educationSectionBreadcrumbs),
];

const settingsRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, settingsRoutes)
];

export default settingsRoutesConfig;
