import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';

import {
  RE_INIT,
  reInitError, reInitSuccess,
} from './Actions';

function* ReInit() {
  console.log('SAGA - reindexSaga - ReInit');
  try {
    const res = yield authApi.get(`${apiConfig.utilityPath}/elastic-init`);
    yield put(reInitSuccess(res));
  } catch (error) {
    yield put(reInitError(error));
  }
}

export function* watchReInit() {
  yield takeLatest(RE_INIT.LOAD, () => ReInit());
}

export default function* devOperations() {
  yield all ([
    call(watchReInit),
  ])
}
