export class Paging implements IPaging {
  /** Total results */
  totalCount?: number | undefined;
  /** Current page */
  page?: number | undefined;
  /** Results per page */
  pageSize?: number | undefined;

  constructor(data?: IPaging) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.totalCount = Data["totalCount"];
      this.page = Data["page"];
      this.pageSize = Data["pageSize"];
    }
  }

  static fromJS(data: any): Paging {
    data = typeof data === 'object' ? data : {};
    let result = new Paging();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["totalCount"] = this.totalCount;
    data["page"] = this.page;
    data["pageSize"] = this.pageSize;
    return data;
  }
}

export interface IPaging {
  /** Total results */
  totalCount?: number | undefined;
  /** Current page */
  page?: number | undefined;
  /** Results per page */
  pageSize?: number | undefined;
}