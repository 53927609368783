export class BrandModel implements IBrandModel {
  id?: string | undefined;
  companyName?: string | undefined;
  groupName?: string | undefined;
  legalName?: string | undefined;
  website?: string | undefined;
  linkedinUrl?: string | undefined;
  shortSummary?: string | undefined;
  logoImageId?: string | undefined;

  constructor(data?: IBrandModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyName = Data["companyName"];
      this.groupName = Data["groupName"];
      this.legalName = Data["legalName"];
      this.website = Data["website"];
      this.linkedinUrl = Data["linkedinUrl"];
      this.shortSummary = Data["shortSummary"];
      this.logoImageId = Data["logoImageId"];
    }
  }

  static fromJS(data: any): BrandModel {
    data = typeof data === 'object' ? data : {};
    let result = new BrandModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyName"] = this.companyName;
    data["groupName"] = this.groupName;
    data["legalName"] = this.legalName;
    data["website"] = this.website;
    data["linkedinUrl"] = this.linkedinUrl;
    data["shortSummary"] = this.shortSummary;
    data["logoImageId"] = this.logoImageId;
    return data;
  }
}

export interface IBrandModel {
  id?: string | undefined;
  companyName?: string | undefined;
  groupName?: string | undefined;
  legalName?: string | undefined;
  website?: string | undefined;
  linkedinUrl?: string | undefined;
  shortSummary?: string | undefined;
  logoImageId?: string | undefined;
}
