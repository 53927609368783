import React, {useEffect, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from '@mui/material';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {IActionDialogPropsWithPayload} from '../../../../types/common/ActionDialogProps';
import DialogCloseButton from '../../../../@template/core/DialogCloseButton';
import {LoadingButton} from '@mui/lab';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {ValidationProvider} from '../../../../@template/validation/ValidationContext';
import FormTextField from '../../../../@template/forms/FormTextField';
import {ContactPersonEditModel, } from '../../../../types/requests/ContactPersonEditModel';
import {defaultForContactEditDetailModel} from '../../../../types/requests/ContactEditDetailModel';
import GridContainer from '../../../../@template/core/GridContainer';
import FormCompanyAutoComplete from '../../FormCompanyAutoComplete';
import FormPhoneNumbers from '../../../../@template/forms/FormPhoneNumbers';
import FormRegion from '../../../../@template/forms/FormRegion';

export interface IAddContactDialogProps extends IActionDialogPropsWithPayload<ContactPersonEditModel, ContactPersonEditModel> {
  companyId?: string | undefined;
}

const addContactSchema = yup.object().shape({
  firstName: yup.string().max(20).required(),
  lastName:  yup.string().max(20).nullable(),
  email: yup.string().email().max(150).required(),
  phoneNumbers: yup.array().of(
    yup.object({
      phoneType: yup.string().required(),
      number: yup.string().required()
    })
  ),
  contactDetail: yup.object({
    companyId: yup.string(),
    jobTitle: yup.string().nullable().max(150),
    locationAddress: yup.object({
      line1: yup.string().max(100).nullable(),
      line2: yup.string().max(100).nullable(),
      state: yup.string().nullable(),
      suburb: yup.string().nullable(),
      countryCode: yup.string().required(),
    }),
  }),
});

const AddContactDialog = ({ companyId, payload, isOpen, isSubmitting, onSubmit, onClose}: IAddContactDialogProps) => {
  const ensureNestedEntities = (source: ContactPersonEditModel, companyId: string | undefined) => {
    // Safes place to do any manual object changes
    source.contactDetail = defaultForContactEditDetailModel();
    if(companyId){
      source.contactDetail.companyId = companyId;
    }
    return source;
  }

  const setCompanyId = (source: ContactPersonEditModel, companyId: string | undefined) => {
    // Safes place to do any manual object changes
    source.contactDetail = defaultForContactEditDetailModel();
    if(companyId){
      source.contactDetail.companyId = companyId;
    }
    return source;
  }

  const methods = useForm<ContactPersonEditModel>({
    resolver: yupResolver(addContactSchema),
    mode: 'onBlur',
    defaultValues: payload ? setCompanyId(payload, companyId) : ensureNestedEntities(ContactPersonEditModel.fromJS({}), companyId)
  });

  const { handleSubmit, reset, watch, setValue, formState: { errors } } = methods;

  const handleClose = (e: any, reason?: string | undefined) => {
    if (reason !== 'backdropClick' && onClose) onClose();
  };

  const handleFormSubmit = (formData: any) => {
    let submission = ContactPersonEditModel.fromJS(formData);
    onSubmit(submission);
  };

  useEffect(() => {
    setValue('contactDetail.companyId', companyId);
  }, [companyId]);

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle>
        Creating New Contact
        <DialogCloseButton onClick={handleClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidationProvider schema={addContactSchema}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <GridContainer spacing={1}>
                <Grid item xs={12}>
                  <FormCompanyAutoComplete
                    name="contactDetail.companyId"
                    label="Company"
                  />
                  <FormTextField name="firstName" label="Preferred name" />
                  <FormTextField name="lastName" label="Last Name" />
                  <FormTextField name="email" label="Email" />
                  <FormTextField name="contactDetail.jobTitle" label="Job Title" />
                  <FormRegion prefixName="contactDetail.locationAddress" labelPrefix="Location " />
                  <FormPhoneNumbers name="phoneNumbers" label="Phone numbers" />
                </Grid>
              </GridContainer>
            </form>
          </FormProvider>
        </ValidationProvider>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          type="submit"
          loading={isSubmitting}
          onClick={handleSubmit(handleFormSubmit)}
        >Save</LoadingButton>
        <Button onClick={handleClose} variant="contained" color="secondary" size="medium">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddContactDialog;
