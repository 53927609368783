import {PoolWorkShadowStepModel} from './PoolWorkShadowStepModel';

export class PoolWorkShadowModel implements IPoolWorkShadowModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title!: string;
  /** Description of the template */
  description?: string | undefined;
  /** Optional company for specific pool processes */
  companyId?: string | undefined;
  /** Steps for this template */
  poolSteps!: PoolWorkShadowStepModel[];
  /** Default template */
  isDefault?: boolean | undefined;
  /** Disabled */
  isDisabled?: boolean | undefined;

  constructor(data?: IPoolWorkShadowModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.poolSteps = [];
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.createdByUserId = Data["createdByUserId"];
      this.title = Data["title"];
      this.description = Data["description"];
      this.companyId = Data["companyId"];
      if (Array.isArray(Data["poolSteps"])) {
        this.poolSteps = [] as any;
        for (let item of Data["poolSteps"])
          this.poolSteps!.push(PoolWorkShadowStepModel.fromJS(item));
      }
      this.isDefault = Data["isDefault"];
      this.isDisabled = Data["isDisabled"];
    }
  }

  static fromJS(data: any): PoolWorkShadowModel {
    data = typeof data === 'object' ? data : {};
    let result = new PoolWorkShadowModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createdByUserId"] = this.createdByUserId;
    data["title"] = this.title;
    data["description"] = this.description;
    data["companyId"] = this.companyId;
    if (Array.isArray(this.poolSteps)) {
      data["poolSteps"] = [];
      for (let item of this.poolSteps)
        data["poolSteps"].push(item.toJSON());
    }
    data["isDefault"] = this.isDefault;
    data["isDisabled"] = this.isDisabled;
    return data;
  }
}

export interface IPoolWorkShadowModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title: string;
  /** Description of the template */
  description?: string | undefined;
  /** Optional company for specific pool processes */
  companyId?: string | undefined;
  /** Steps for this template */
  poolSteps: PoolWorkShadowStepModel[];
  /** Default template */
  isDefault?: boolean | undefined;
  /** Disabled */
  isDisabled?: boolean | undefined;
}