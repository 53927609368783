import {AddressSearch} from './AddressSearch';
import {TimelineStepIndexModel} from './TimelineStepIndexModel';
import {HSPPlacementSearchModel} from './HSPPlacementSearchModel';
import {HSPSeasonalActionModel} from '../models/HSPSeasonalActionModel';
import {SeasonalActionModel} from '../models/SeasonalActionModel';
import {WorkShadowOnboardingHistoryModel} from '../models/WorkShadowOnboardingHistoryModel';
import {HSPOpportunitySearchModel} from './HSPOpportunitySearchModel';
import {HSPPoolSearchModel} from './HSPPoolSearchModel';

export class WorkShadowSearchModel implements IWorkShadowSearchModel {
  workShadowTags?: string[] | undefined;
  locationAddress?: AddressSearch | undefined;
  quickNotes?: string | undefined;
  onboardingWorkShadowSettingId?: string | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  onboardingSteps?: TimelineStepIndexModel[] | undefined;
  onboardingStepIds?: string[] | undefined;
  isCompleted?: boolean | undefined;
  workShadowStatus?: string | undefined;
  dateCompleted?: Date | undefined;
  degreePoolId?: string[] | undefined;
  degreePoolFirst?: string | undefined;
  currentCompanyId?: string | undefined;
  currentCompanyName?: string | undefined;
  currentPipelineCompanyId?: string | undefined;
  currentPipelineCompanyName?: string | undefined;
  placementHistory?: HSPPlacementSearchModel[] | undefined;
  allPlacements?: HSPPlacementSearchModel[] | undefined;
  topPlacement?: HSPPlacementSearchModel | undefined;
  currentPools?: HSPPoolSearchModel[] | undefined;
  previousPools?: HSPPoolSearchModel[] | undefined;
  topPool?: HSPPoolSearchModel | undefined;
  topOpportunityStudent?: HSPOpportunitySearchModel | undefined;
  opportunityStudents?: HSPOpportunitySearchModel[] | undefined;
  seasonalActions?: HSPSeasonalActionModel[] | undefined;
  highSchool?: string | undefined;
  workShadowOnboardingHistory?: WorkShadowOnboardingHistoryModel[] | undefined;


  constructor(data?: IWorkShadowSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["workShadowTags"])) {
        this.workShadowTags = [] as any;
        for (let item of Data["workShadowTags"])
          this.workShadowTags!.push(item);
      }
      this.locationAddress = Data["locationAddress"] ? AddressSearch.fromJS(Data["locationAddress"]) : <any>undefined;
      this.quickNotes = Data["quickNotes"];
      this.onboardingWorkShadowSettingId = Data["onboardingWorkShadowSettingId"];
      this.onboardingStepsComplete = Data["onboardingStepsComplete"];
      this.onboardingPercent = Data["onboardingPercent"];
      if (Array.isArray(Data["onboardingSteps"])) {
        this.onboardingSteps = [] as any;
        for (let item of Data["onboardingSteps"])
          this.onboardingSteps!.push(TimelineStepIndexModel.fromJS(item));
      }
      if (Array.isArray(Data["onboardingStepIds"])) {
        this.onboardingStepIds = [] as any;
        for (let item of Data["onboardingStepIds"])
          this.onboardingStepIds!.push(item);
      }
      this.isCompleted = Data["isCompleted"];
      this.workShadowStatus = Data["workShadowStatus"];
      this.dateCompleted = Data["dateCompleted"] ? new Date(Data["dateCompleted"].toString()) : <any>undefined;
      if (Array.isArray(Data["degreePoolId"])) {
        this.degreePoolId = [] as any;
        for (let item of Data["degreePoolId"])
          this.degreePoolId!.push(item);
      }
      this.degreePoolFirst = Data["degreePoolFirst"];
      this.currentCompanyId = Data["currentCompanyId"];
      this.currentCompanyName = Data["currentCompanyName"];
      this.currentPipelineCompanyId = Data["currentPipelineCompanyId"];
      this.currentPipelineCompanyName = Data["currentPipelineCompanyName"];
      if (Array.isArray(Data["placementHistory"])) {
        this.placementHistory = [] as any;
        for (let item of Data["placementHistory"])
          this.placementHistory!.push(HSPPlacementSearchModel.fromJS(item));
      }
      if (Array.isArray(Data["allPlacements"])) {
        this.allPlacements = [] as any;
        for (let item of Data["allPlacements"])
          this.allPlacements!.push(HSPPlacementSearchModel.fromJS(item));
      }
      this.topPlacement = Data["topPlacement"] ? HSPPlacementSearchModel.fromJS(Data["topPlacement"]) : <any>undefined;
      if (Array.isArray(Data["currentPools"])) {
        this.currentPools = [] as any;
        for (let item of Data["currentPools"])
          this.currentPools!.push(HSPPoolSearchModel.fromJS(item));
      }
      if (Array.isArray(Data["previousPools"])) {
        this.previousPools = [] as any;
        for (let item of Data["previousPools"])
          this.previousPools!.push(HSPPoolSearchModel.fromJS(item));
      }
      this.topPool = Data["topPool"] ? HSPPoolSearchModel.fromJS(Data["topPool"]) : <any>undefined;
      this.topOpportunityStudent = Data["topOpportunityStudent"] ? HSPOpportunitySearchModel.fromJS(Data["topOpportunityStudent"]) : <any>undefined;
      if (Array.isArray(Data["opportunityStudents"])) {
        this.opportunityStudents = [] as any;
        for (let item of Data["opportunityStudents"])
          this.opportunityStudents!.push(HSPOpportunitySearchModel.fromJS(item));
      }
      if (Array.isArray(Data["seasonalActions"])) {
        this.seasonalActions = [] as any;
        for (let item of Data["seasonalActions"])
          this.seasonalActions!.push(HSPSeasonalActionModel.fromJS(item));
      }
      this.highSchool = Data["highSchool"];
      if (Array.isArray(Data["workShadowOnboardingHistory"])) {
        this.workShadowOnboardingHistory = [] as any;
        for (let item of Data["workShadowOnboardingHistory"])
          this.workShadowOnboardingHistory!.push(WorkShadowOnboardingHistoryModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): WorkShadowSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new WorkShadowSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.workShadowTags)) {
      data["workShadowTags"] = [];
      for (let item of this.workShadowTags)
        data["workShadowTags"].push(item);
    }
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["quickNotes"] = this.quickNotes;
    data["onboardingWorkShadowSettingId"] = this.onboardingWorkShadowSettingId;
    data["onboardingStepsComplete"] = this.onboardingStepsComplete;
    data["onboardingPercent"] = this.onboardingPercent;
    if (Array.isArray(this.onboardingSteps)) {
      data["onboardingSteps"] = [];
      for (let item of this.onboardingSteps)
        data["onboardingSteps"].push(item.toJSON());
    }
    if (Array.isArray(this.onboardingStepIds)) {
      data["onboardingStepIds"] = [];
      for (let item of this.onboardingStepIds)
        data["onboardingStepIds"].push(item);
    }
    data["isCompleted"] = this.isCompleted;
    data["workShadowStatus"] = this.workShadowStatus;
    data["dateCompleted"] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
    if (Array.isArray(this.degreePoolId)) {
      data["degreePoolId"] = [];
      for (let item of this.degreePoolId)
        data["degreePoolId"].push(item);
    }
    data["degreePoolFirst"] = this.degreePoolFirst;
    data["currentCompanyId"] = this.currentCompanyId;
    data["currentCompanyName"] = this.currentCompanyName;
    data["currentPipelineCompanyId"] = this.currentPipelineCompanyId;
    data["currentPipelineCompanyName"] = this.currentPipelineCompanyName;
    if (Array.isArray(this.placementHistory)) {
      data["placementHistory"] = [];
      for (let item of this.placementHistory)
        data["placementHistory"].push(item.toJSON());
    }
    if (Array.isArray(this.allPlacements)) {
      data["allPlacements"] = [];
      for (let item of this.allPlacements)
        data["allPlacements"].push(item.toJSON());
    }
    data["topPlacement"] = this.topPlacement ? this.topPlacement.toJSON() : <any>undefined;
    if (Array.isArray(this.currentPools)) {
      data["currentPools"] = [];
      for (let item of this.currentPools)
        data["currentPools"].push(item.toJSON());
    }
    if (Array.isArray(this.previousPools)) {
      data["previousPools"] = [];
      for (let item of this.previousPools)
        data["previousPools"].push(item.toJSON());
    }
    data["topPool"] = this.topPool ? this.topPool.toJSON() : <any>undefined;
    data["topOpportunityStudent"] = this.topOpportunityStudent ? this.topOpportunityStudent.toJSON() : <any>undefined;
    if (Array.isArray(this.opportunityStudents)) {
      data["opportunityStudents"] = [];
      for (let item of this.opportunityStudents)
        data["opportunityStudents"].push(item.toJSON());
    }
    if (Array.isArray(this.seasonalActions)) {
      data["seasonalActions"] = [];
      for (let item of this.seasonalActions)
        data["seasonalActions"].push(item.toJSON());
    }
    data["highSchool"] = this.highSchool;
    if (Array.isArray(this.workShadowOnboardingHistory)) {
      data["workShadowOnboardingHistory"] = [];
      for (let item of this.workShadowOnboardingHistory)
        data["workShadowOnboardingHistory"].push(item.toJSON());
    }
    return data;
  }
}

export interface IWorkShadowSearchModel {
  workShadowTags?: string[] | undefined;
  locationAddress?: AddressSearch | undefined;
  quickNotes?: string | undefined;
  onboardingWorkShadowSettingId?: string | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  onboardingSteps?: TimelineStepIndexModel[] | undefined;
  onboardingStepIds?: string[] | undefined;
  isCompleted?: boolean | undefined;
  workShadowStatus?: string | undefined;
  dateCompleted?: Date | undefined;
  degreePoolId?: string[] | undefined;
  degreePoolFirst?: string | undefined;
  currentCompanyId?: string | undefined;
  currentCompanyName?: string | undefined;
  currentPipelineCompanyId?: string | undefined;
  currentPipelineCompanyName?: string | undefined;
  placementHistory?: HSPPlacementSearchModel[] | undefined;
  allPlacements?: HSPPlacementSearchModel[] | undefined;
  topPlacement?: HSPPlacementSearchModel | undefined;
  currentPools?: HSPPoolSearchModel[] | undefined;
  previousPools?: HSPPoolSearchModel[] | undefined;
  topPool?: HSPPoolSearchModel | undefined;
  topOpportunityStudent?: HSPOpportunitySearchModel | undefined;
  opportunityStudents?: HSPOpportunitySearchModel[] | undefined;
  seasonalActions?: HSPSeasonalActionModel[] | undefined;
  highSchool?: string | undefined;
  workShadowOnboardingHistory?: WorkShadowOnboardingHistoryModel[] | undefined;
}