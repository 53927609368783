import {useFormContext, Controller, FieldValues, get} from 'react-hook-form';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {MenuItem, TextField} from '@mui/material';
import {isFieldRequired, useValidationContext} from '../../validation/ValidationContext';
import Typography from '@mui/material/Typography';
import {isFieldInObject} from '../../helpers/json';
import {changeFieldOverride} from '../../utility/FormLayout/layoutConfig';


const useStyles = makeStyles(() => ({
  selectBox: {
    marginLeft: 0,
    cursor: 'pointer',
  },
  selectOption: {
    cursor: 'pointer',
    padding: 8,
    paddingRight: 16
 },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
}));

const FormSelect = <IFormSelectOptions extends FieldValues>(props: IFormSelectOptions) => {
  const { schema, changeRequest } = useValidationContext();
  const { control, setValue, formState: { errors }, watch } = useFormContext();
  const { name, label, data = [], labelField = 'title', valueField = 'id', required = false, fullWidth = true, disabled = false, loading = false, onBlur } = props;
  const isRequired = required ? true : schema ? isFieldRequired(schema, name, required) : false;

  const watchSelectedValue = watch(name);

  const [selectedOption, setSelectedOption] = useState(data ? data.find((x: any) => x[valueField] === watchSelectedValue) : null);

  const classes = useStyles();

  let isError = false;
  let errorMessage = '';

  if (errors) {
    errorMessage = get(errors, name)?.message;
    if (errorMessage)
      isError = true;
  }

  const styleForChangeRequest = () => {
    if (changeRequest && isFieldInObject(changeRequest, name)) {
      return changeFieldOverride;
    }
    return undefined;
  }

  useEffect(() => {
    // Loading option here allows for datasource loaded from api
    if (!watchSelectedValue) {
      setSelectedOption(undefined);
    } else {
      setSelectedOption(data?.find((x: any) => x[valueField] === watchSelectedValue));
    }
  }, [watchSelectedValue, loading]);

  const handleChanged = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const selectedVal = e.target.value;
      setValue(name, selectedVal);
    },
    [setValue, name]
  );

  const handleBlur = (onHookBlur: () => void) => {
    onHookBlur();
    if (onBlur) onBlur();
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ...rest } }) => (
        <TextField
          {...rest}
          select
          fullWidth={fullWidth}
          variant='outlined'
          label={label}
          sx={styleForChangeRequest()}
          required={isRequired}
          error={isError}
          helperText={errorMessage}
          className={classes.selectBox}
          onChange={handleChanged}
          value={value ?? ''}
          disabled={disabled}
          onBlur={() => handleBlur(onBlur)}
        >
          {data?.map((item: any) =>
            <MenuItem
              key={`${name}-${item[valueField]}`}
              value={item[valueField]}
              className={classes.selectOption}>
              <Typography variant="inherit" className={classes.textTruncate} noWrap>
              {item[labelField]}
              </Typography>
            </MenuItem>
          )}
        </TextField>
      )}
      {...props}
    />
  );
}

export default FormSelect;
