import {SubIndustryModel} from './SubIndustryModel';

export class IndustryModel implements IIndustryModel {
  id?: number | undefined;
  name?: string | undefined;
  subIndustries?: SubIndustryModel[] | undefined;

  constructor(data?: IIndustryModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.name = Data["name"];
      if (Array.isArray(Data["subIndustries"])) {
        this.subIndustries = [] as any;
        for (let item of Data["subIndustries"])
          this.subIndustries!.push(SubIndustryModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): IndustryModel {
    data = typeof data === 'object' ? data : {};
    let result = new IndustryModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["name"] = this.name;
    if (Array.isArray(this.subIndustries)) {
      data["subIndustries"] = [];
      for (let item of this.subIndustries)
        data["subIndustries"].push(item.toJSON());
    }
    return data;
  }
}

export interface IIndustryModel {
  id?: number | undefined;
  name?: string | undefined;
  subIndustries?: SubIndustryModel[] | undefined;
}
