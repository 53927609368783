import { AlumniEarlyProfessionalStepModel } from "./AlumniEarlyProfessionalStepModel";

export class AlumniEarlyProfessionalModel implements IAlumniEarlyProfessionalModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title!: string;
  /** Description of the template */
  description?: string | undefined;
  /** If we want this to be the default for new students */
  isDefault?: boolean | undefined;
  /** Steps for this template */
  earlyProfessionalSteps!: AlumniEarlyProfessionalStepModel[];
  /** Disabled */
  isDisabled?: boolean | undefined;

  constructor(data?: IAlumniEarlyProfessionalModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.earlyProfessionalSteps = [];
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.createdByUserId = Data["createdByUserId"];
      this.title = Data["title"];
      this.description = Data["description"];
      this.isDefault = Data["isDefault"];
      if (Array.isArray(Data["earlyProfessionalSteps"])) {
        this.earlyProfessionalSteps = [] as any;
        for (let item of Data["earlyProfessionalSteps"])
          this.earlyProfessionalSteps!.push(AlumniEarlyProfessionalStepModel.fromJS(item));
      }
      this.isDisabled = Data["isDisabled"];
    }
  }

  static fromJS(data: any): AlumniEarlyProfessionalModel {
    data = typeof data === 'object' ? data : {};
    let result = new AlumniEarlyProfessionalModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createdByUserId"] = this.createdByUserId;
    data["title"] = this.title;
    data["description"] = this.description;
    data["isDefault"] = this.isDefault;
    if (Array.isArray(this.earlyProfessionalSteps)) {
      data["earlyProfessionalSteps"] = [];
      for (let item of this.earlyProfessionalSteps)
        data["earlyProfessionalSteps"].push(item.toJSON());
    }
    data["isDisabled"] = this.isDisabled;
    return data;
  }
}

export interface IAlumniEarlyProfessionalModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title: string;
  /** Description of the template */
  description?: string | undefined;
  /** If we want this to be the default for new students */
  isDefault?: boolean | undefined;
  /** Steps for this template */
  earlyProfessionalSteps: AlumniEarlyProfessionalStepModel[];
  /** Disabled */
  isDisabled?: boolean | undefined;
}