export class CompanyListItem implements ICompanyListItem {
  /** Id */
  id?: string | undefined;
  /** First name of user */
  companyName?: string | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  /** The company logo */
  logoImageId?: string | undefined;
  /** Company website */
  website?: string | undefined;
  isClient?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isActive?: boolean | undefined;

  constructor(data?: ICompanyListItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.companyName = Data["companyName"];
      this.suburb = Data["suburb"];
      this.state = Data["state"];
      this.logoImageId = Data["logoImageId"];
      this.website = Data["website"];
      this.isClient = Data["isClient"];
      this.isDeleted = Data["isDeleted"];
      this.isActive = Data["isActive"];
    }
  }

  static fromJS(data: any): CompanyListItem {
    data = typeof data === 'object' ? data : {};
    let result = new CompanyListItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["companyName"] = this.companyName;
    data["suburb"] = this.suburb;
    data["state"] = this.state;
    data["logoImageId"] = this.logoImageId;
    data["website"] = this.website;
    data["isClient"] = this.isClient;
    data["isDeleted"] = this.isDeleted;
    data["isActive"] = this.isActive;
    return data;
  }
}

export interface ICompanyListItem {
  /** Id */
  id?: string | undefined;
  /** First name of user */
  companyName?: string | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  /** The company logo */
  logoImageId?: string | undefined;
  /** Company website */
  website?: string | undefined;
  isClient?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isActive?: boolean | undefined;
}