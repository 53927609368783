import React, {useContext, useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';
import {toggleChatCollapsed} from '../../../../redux/ui/Actions';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Scrollbar from '../../Scrollbar';
import AppContext from '../../../utility/AppContext';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import {Avatar, BadgeProps, ListItem, ListItemText} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import SmsIcon from '@mui/icons-material/Sms';
import {Fonts} from '../../../../shared/constants/AppEnums';
import {selectChatCollapsed} from '../../../../redux/ui/Selectors';
import {
  selectGroupNotificationList,
} from '../../../../redux/messaging/Selectors';
import {ActivityGroupModel} from '../../../../types/models/ActivityGroupModel';
import {
  groupsNotificationLoad,
} from '../../../../redux/messaging/Actions';
import PersonAvatar from '../../../../modules/people/PersonAvatar';
import {hasItems} from '../../../helpers/arrays';
import CompanyAvatar from '../../../../modules/companies/CompanyAvatar';
import {styled, Theme} from '@mui/material/styles';
import {useAuthUser} from '../../../utility/AppHooks';
import makeStyles from '@mui/styles/makeStyles';
import useStyles from './AppRightInbox.style';
import PersonLink from '../../../../modules/people/PersonLink';
import {truncate} from '../../../helpers/strings';
import {AppNavLink} from '../../../index';
import {inboxPaths} from '../../../../modules/messaging/paths';
import {generatePath} from 'react-router';
import CompanyLink from '../../../../modules/companies/CompanyLink';
import {IGroupMetadata} from '../../../../types/views/GroupMetadata';

interface AppSidebarProps {
  variant?: string;
  position?: 'left' | 'bottom' | 'right' | 'top';
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 10,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const useGroupItemStyles = makeStyles((theme: Theme) => ({
  link: {
    fontSize: 14,
  },
  groupItem: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.primary,
    '&.MuiListItem-root': {
      paddingTop: '2px',
      paddingBottom: '5px',
      paddingLeft: '18px',
      paddingRight: '12px',
    },
    '& .MuiListItemText-root': {
      flex: 1,
      display: 'block'
    },
    '& .largeText': {
      display: 'none',
      width: 'calc(100%-60px)',
    },
    '& .smallText': {
      display: 'block',
      width: 'calc(100%-60px)',
    },
    '&:hover': {
      '& .largeText': {
        display: 'block',
      },
      '& .smallText': {
        display: 'none',
      },
    },
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const AppRightInbox: React.FC<AppSidebarProps> = ({
                                                 position = 'left',
                                                 variant,
                                               }) => {
  const dispatch = useDispatch();
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const chatCollapsed = useSelector(selectChatCollapsed);
  const groups = useSelector(selectGroupNotificationList);

  const currentUser = useAuthUser();

  const [init, setInit] = useState(false);
  const [openGroupId, setOpenGroupId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!init) {
      dispatch(groupsNotificationLoad());
      setInit(true);
    }
  }, [init]);

  const handleToggleDrawer = () => {
    dispatch(toggleChatCollapsed());
  };

  const handleOpenGroup = (itemId: string | undefined) => {
    setOpenGroupId(itemId);
  };

  const classes = useStyles({themeMode});
  const groupItemClasses = useGroupItemStyles();
  let sidebarClasses = classes.sidebarStandard;

  const groupMetadataFromGroup = (groupItem: ActivityGroupModel): IGroupMetadata => {
    let recipientIds: string[] = [];
    let internalIds: string[] = [];
    let recipientCompanyId: string | undefined = undefined;
    let companyThread = false;
    // If this is a conversation with a student or contact
    if (hasItems(groupItem.recipientPeopleMetadata)) {
      recipientIds = groupItem.recipientPeopleMetadata?.filter(x => !x.isInternal).map(x => x.personId) ?? [];
      internalIds = groupItem.recipientPeopleMetadata?.filter(x => x.isInternal).map(x => x.personId) ?? [];
    }
    if (groupItem.recipientCompaniesMetadata && hasItems(groupItem.recipientCompaniesMetadata)){
      companyThread = true;
      recipientCompanyId = groupItem.recipientCompaniesMetadata[0].companyId;
    }
    if (!recipientIds || recipientIds.length === 0) {
      // We have no external recipients, just show the other advisers.
      if (groupItem.recipientPeopleMetadata && hasItems(groupItem.recipientPeopleMetadata)) {
        recipientIds = groupItem.recipientPeopleMetadata.filter(x => x.personId !== currentUser?.personId).map(x => x.personId);
      }
    }
    return {
      recipientIds, recipientCompanyId, internalIds, companyThread
    };
  }

  const avatarForRecipientList = (groupItem: ActivityGroupModel) => {
    const groupMetadata = groupMetadataFromGroup(groupItem);
    if (!groupMetadata.recipientIds && !groupMetadata.recipientCompanyId) {
      return <StyledBadge badgeContent={groupItem.unreadMessages} color="secondary"><Avatar sx={{ fontSize: 36 }} className={classes.avatar} /></StyledBadge>;
    }
    if (groupMetadata.recipientIds && hasItems(groupMetadata.recipientIds)) return <StyledBadge badgeContent={groupItem.unreadMessages} color="secondary"><PersonAvatar size={36} personId={groupMetadata.recipientIds[0]} /></StyledBadge>;
    return <StyledBadge badgeContent={groupItem.unreadMessages} color="secondary"><CompanyAvatar size={36} companyId={groupMetadata.recipientCompanyId??''} /></StyledBadge>;
  }

  const groupTitle = (groupItem: ActivityGroupModel) => {
    const groupMetadata = groupMetadataFromGroup(groupItem);
    if (groupMetadata.recipientIds && hasItems(groupMetadata.recipientIds)) {
      return <Box component="h5" className={groupItemClasses.textTruncate}>
        {groupMetadata.recipientIds?.map((personId: string, idx: number) => <React.Fragment key={`${personId}-${idx}`}>{idx > 0 ? ', ' : ''}<PersonLink
          personId={personId} /></React.Fragment>)}
      </Box>;
    }
    if (groupMetadata.recipientCompanyId) {
      return <Box component="h5" className={groupItemClasses.textTruncate}>
        <CompanyLink companyId={groupMetadata.recipientCompanyId} />
      </Box>;
    }
    return <Box component="h5" className={groupItemClasses.textTruncate}>Context not found</Box>;
  }

  const groupListItem = (groupItem: ActivityGroupModel, prefix: string) => {
    return <ListItem
      button
      key={`ri-inb-${prefix}-${groupItem.id}`}
      component={AppNavLink}
      to={ groupItem.lastThreadId ? generatePath(inboxPaths.InboxThreadView, { id: groupItem.lastThreadId }) : generatePath(inboxPaths.InboxView)}
      activeClassName='expanded'
      onClick={() => { if (chatCollapsed) handleToggleDrawer() }}
      className={groupItemClasses.groupItem}
    >
      <Box sx={{ fontSize: 36, width: '50px', alignItems: 'center', marginTop: '5px', marginBottom: 'auto' }}>{avatarForRecipientList(groupItem)}</Box>
      {chatCollapsed && <ListItemText sx={{ marginLeft: '5px'}}>
        {groupTitle(groupItem)}
        <Box className={clsx(groupItemClasses.textTruncate, 'smallText')}>{groupItem.lastContent??'Other activity'}</Box>
        <Box className="largeText">{truncate(groupItem.lastContent??'Other activity', 200, true)}</Box>
      </ListItemText>}
    </ListItem>
  }

  const unreadGroups = groups?.filter(x => !x.isMessagesRead);

  return <>
    <Drawer
      sx={{ display: { xs: 'block', sm: 'none' } }}
      anchor={position}
      open={chatCollapsed}
      onClose={ev => handleToggleDrawer()}
      classes={{
        root: clsx(variant),
        paper: clsx(variant),
      }}
      style={{position: 'absolute'}}>
      <Box height='100%'>
        <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
          <Box className={clsx(classes.sidebarHeader)}>
            <IconButton
              className={clsx(classes.notiBtn, 'notiBtn')}
              aria-label='show 4 new mails'
              color='inherit'
              onClick={() => dispatch(toggleChatCollapsed())}
              >
              <Badge
                className={classes.badgeStyle}
                badgeContent={unreadGroups?.length??0}
                color='secondary'>
                <SmsIcon className={clsx(classes.chatIcon, 'smsIcon')} />
              </Badge>
            </IconButton>
            <Box
              ml={4}
              fontSize={16}
              fontWeight={Fonts.REGULAR}>
              Latest messages
            </Box>
          </Box>
          <Scrollbar
            scrollToTop={false}
            className={classes.drawerScrollAppSidebar}>
            <>{groups?.map((group: ActivityGroupModel) => groupListItem(group, 'col'))}</>
          </Scrollbar>
        </Box>
      </Box>
    </Drawer>
    <Box
      sx={{ display: { sm: 'block', xs: 'none' } }}
      height='100%'
      className={clsx(
        classes.container,
        'app-sidebar',
        chatCollapsed ? '' : 'mini-sidebar-collapsed',
      )}>
      <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
        <IconButton
          className={clsx(classes.notiBtn, 'notiBtn')}
          aria-label='show 4 new mails'
          color='inherit'
          onClick={() => dispatch(toggleChatCollapsed())}
          >
          <StyledBadge
            badgeContent={unreadGroups?.length??0}
            color='secondary'>
            <SmsIcon className={clsx(classes.chatIcon, 'smsIcon')} />
          </StyledBadge>
          <Box
            sx={{ display: { xs: 'block', sm: 'none' } }}
            ml={4}
            fontSize={16}
            fontWeight={Fonts.REGULAR}
            component='span'>
            Messages
          </Box>
        </IconButton>
        <Scrollbar scrollToTop={false} className={classes.scrollAppSidebar}>
          <>{groups?.map((group: ActivityGroupModel) => groupListItem(group, 'drw'))}</>
        </Scrollbar>
      </Box>
    </Box>
  </>;
};

export default AppRightInbox;
