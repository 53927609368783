import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {adminPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const sectionBreadcrumbs = [new BreadcrumbItemProps("Administration")];

const adminRoutes = [
  new AppRouteProps("Re-initialise search indexes", adminPaths.DevReindexPage, React.lazy(() => import('./DevReindex')), sectionBreadcrumbs),
  new AppRouteProps("Manage subscribed emails", adminPaths.ManageUnsubscribe, React.lazy(() => import('./ManageUnsubscribe')), sectionBreadcrumbs),
  new AppRouteProps("Bulk advisor update", adminPaths.BulkAdvisorUpdatePage, React.lazy(() => import('./BulkAdvisorUpdate')), sectionBreadcrumbs),
]

const adminRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, adminRoutes)
];
export default adminRoutesConfig;
