import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {UI} from './Actions';

export interface IUIState {
  navCollapsed: boolean;
  chatCollapsed: boolean;
  initialPath: string | undefined;
  error: string | undefined;
  loading: boolean;
  isAppDrawerOpen: boolean;
  updatingContent: boolean;
  message: string | undefined;
}

const initialState: IUIState = {
  navCollapsed: false,
  chatCollapsed: false,
  initialPath: '/',
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
};

const uiReducer = createReducer(initialState, {
  [UI.ROUTE_CHANGE]: (state) => {
    state.navCollapsed = false;
  },
  [UI.TOGGLE_NAV_COLLAPSED]: (state) => {
    state.navCollapsed = !state.navCollapsed;
  },
  [UI.TOGGLE_CHAT_COLLAPSED]: (state) => {
    state.chatCollapsed = !state.chatCollapsed;
  },
  [UI.SET_INITIAL_PATH]: (state, action: PayloadAction<string>) => {
    state.initialPath = action.payload;
  },
  [UI.SHOW_MESSAGE]: (state, action: PayloadAction<string>) => {
    state.message = action.payload;
  },
  [UI.HIDE_MESSAGE]: (state) =>  {
    state.message = undefined;
    state.error = undefined;
  },
  [UI.TOGGLE_APP_DRAWER]: (state) => {
    state.isAppDrawerOpen = !state.isAppDrawerOpen;
  },
});

export default uiReducer;
