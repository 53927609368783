import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {mailingListPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';


const sectionBreadcrumbs = [new BreadcrumbItemProps("Mailing List", mailingListPaths.MailingListSearch)];

const mailingListRoutes = [
  new AppRouteProps("Search", mailingListPaths.MailingListSearch, React.lazy(() => import('./MailingListSearch')), sectionBreadcrumbs),
  new AppRouteProps("Profile", mailingListPaths.MailingListProfile, React.lazy(() => import('./MailingListDetails')), sectionBreadcrumbs),
]

const mailingListRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, mailingListRoutes)
];

export default mailingListRoutesConfig;
