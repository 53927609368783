import {formatDate} from '../../@template/helpers/datetimes';

export const defaultMailingListMessageSearchParamsModel = () => {
  return MailingListMessageSearchParamsModel.fromJS({
    searchText: '',
    createdByUserId: [],
    dateCreatedTo: '',
    dateCreatedFrom: '',
    sendStatus: null,
    isArchived: false,
  });
};


export class MailingListMessageSearchParamsModel implements IMailingListMessageSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  emailTemplateId?: string | undefined;
  dateCreatedTo?: Date | undefined;
  dateCreatedFrom?: Date | undefined;
  createdByUserId?: string[] | undefined;
  sendStatus?: string | undefined;
  tags?: string[] | undefined;

  constructor(data?: IMailingListMessageSearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      this.emailTemplateId = Data["emailTemplateId"];
      this.dateCreatedTo = Data["dateCreatedTo"] ? new Date(Data["dateCreatedTo"].toString()) : <any>undefined;
      this.dateCreatedFrom = Data["dateCreatedFrom"] ? new Date(Data["dateCreatedFrom"].toString()) : <any>undefined;
      if (Array.isArray(Data["createdByUserId"])) {
        this.createdByUserId = [] as any;
        for (let item of Data["createdByUserId"])
          this.createdByUserId!.push(item);
      }
      this.sendStatus = Data["sendStatus"];
      if (Array.isArray(Data["tags"])) {
        this.tags = [] as any;
        for (let item of Data["tags"])
          this.tags!.push(item);
      }
    }
  }

  static fromJS(data: any): MailingListMessageSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new MailingListMessageSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    data["emailTemplateId"] = this.emailTemplateId;
    data["dateCreatedTo"] = this.dateCreatedTo ? formatDate(this.dateCreatedTo) : <any>undefined;
    data["dateCreatedFrom"] = this.dateCreatedFrom ? formatDate(this.dateCreatedFrom) : <any>undefined;
    if (Array.isArray(this.createdByUserId)) {
      data["createdByUserId"] = [];
      for (let item of this.createdByUserId)
        data["createdByUserId"].push(item);
    }
    data["sendStatus"] = this.sendStatus;
    if (Array.isArray(this.tags)) {
      data["tags"] = [];
      for (let item of this.tags)
        data["tags"].push(item);
    }
    return data;
  }
}

export interface IMailingListMessageSearchParamsModel {
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  emailTemplateId?: string | undefined;
  dateCreatedTo?: Date | undefined;
  dateCreatedFrom?: Date | undefined;
  createdByUserId?: string[] | undefined;
  sendStatus?: string | undefined;
  tags?: string[] | undefined;
}