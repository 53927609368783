import {AddressViewModel} from './AddressViewModel';

export class PlacementSearchResultModel implements IPlacementSearchResultModel {
    id?: string | undefined;
    dateCreated?: Date | undefined;
    companyId?: string | undefined;
    companyName?: string | undefined;
    title?: string | undefined;
    seasonNumber?: number | undefined;
    seasonYear?: number | undefined;
    isTravelRequired?: boolean | undefined;
    seasonIndex?: number | undefined;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    isComplete?: boolean | undefined;
    isCancelled?: boolean | undefined;
    placementSettingId?: string | undefined;
    placementStepsComplete?: number | undefined;
    placementPercentComplete?: number | undefined;
    placementStudentSteps?: string[] | undefined;
    placementStudentStepIds?: string[] | undefined;
    locationAddress?: AddressViewModel | undefined;
    managerPersonIds?: string[] | undefined;
    isFlightsRequired?: boolean | undefined;
    departureLocation?: string | undefined;
    arrivalLocation?: string | undefined;
    accommodationRequired?: boolean | undefined;

    constructor(data?: IPlacementSearchResultModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.id = Data["id"];
            this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
            this.companyId = Data["companyId"];
            this.companyName = Data["companyName"];
            this.title = Data["title"];
            this.seasonNumber = Data["seasonNumber"];
            this.seasonYear = Data["seasonYear"];
            this.isTravelRequired = Data["isTravelRequired"];
            this.seasonIndex = Data["seasonIndex"];
            this.startDate = Data["startDate"] ? new Date(Data["startDate"].toString()) : <any>undefined;
            this.endDate = Data["endDate"] ? new Date(Data["endDate"].toString()) : <any>undefined;
            this.isComplete = Data["isComplete"];
            this.isCancelled = Data["isCancelled"];
            this.placementSettingId = Data["placementSettingId"];
            this.placementStepsComplete = Data["placementStepsComplete"];
            this.placementPercentComplete = Data["placementPercentComplete"];
            if (Array.isArray(Data["placementStudentSteps"])) {
                this.placementStudentSteps = [] as any;
                for (let item of Data["placementStudentSteps"])
                    this.placementStudentSteps!.push(item);
            }
            if (Array.isArray(Data["placementStudentStepIds"])) {
                this.placementStudentStepIds = [] as any;
                for (let item of Data["placementStudentStepIds"])
                    this.placementStudentStepIds!.push(item);
            }
            this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
            if (Array.isArray(Data["managerPersonIds"])) {
                this.managerPersonIds = [] as any;
                for (let item of Data["managerPersonIds"])
                    this.managerPersonIds!.push(item);
            }
            this.isFlightsRequired = Data["isFlightsRequired"];
            this.departureLocation = Data["departureLocation"];
            this.arrivalLocation = Data["arrivalLocation"];
            this.accommodationRequired = Data["accommodationRequired"];
        }
    }

    static fromJS(data: any): PlacementSearchResultModel {
        data = typeof data === 'object' ? data : {};
        let result = new PlacementSearchResultModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data["companyId"] = this.companyId;
        data["companyName"] = this.companyName;
        data["title"] = this.title;
        data["seasonNumber"] = this.seasonNumber;
        data["seasonYear"] = this.seasonYear;
        data["isTravelRequired"] = this.isTravelRequired;
        data["seasonIndex"] = this.seasonIndex;
        data["startDate"] = this.startDate ? this.startDate.toISOString() : <any>undefined;
        data["endDate"] = this.endDate ? this.endDate.toISOString() : <any>undefined;
        data["isComplete"] = this.isComplete;
        data["isCancelled"] = this.isCancelled;
        data["placementSettingId"] = this.placementSettingId;
        data["placementStepsComplete"] = this.placementStepsComplete;
        data["placementPercentComplete"] = this.placementPercentComplete;
        if (Array.isArray(this.placementStudentSteps)) {
            data["placementStudentSteps"] = [];
            for (let item of this.placementStudentSteps)
                data["placementStudentSteps"].push(item);
        }
        if (Array.isArray(this.placementStudentStepIds)) {
            data["placementStudentStepIds"] = [];
            for (let item of this.placementStudentStepIds)
                data["placementStudentStepIds"].push(item);
        }
        data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
        if (Array.isArray(this.managerPersonIds)) {
            data["managerPersonIds"] = [];
            for (let item of this.managerPersonIds)
                data["placementStudentSteps"].push(item);
        }
        data["isFlightsRequired"] = this.isFlightsRequired;
        data["departureLocation"] = this.departureLocation;
        data["arrivalLocation"] = this.arrivalLocation;
        data["accommodationRequired"] = this.accommodationRequired;
        return data; 
    }
}

export interface IPlacementSearchResultModel {
    id?: string | undefined;
    dateCreated?: Date | undefined;
    companyId?: string | undefined;
    companyName?: string | undefined;
    title?: string | undefined;
    seasonNumber?: number | undefined;
    seasonYear?: number | undefined;
    isTravelRequired?: boolean | undefined;
    seasonIndex?: number | undefined;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    isComplete?: boolean | undefined;
    isCancelled?: boolean | undefined;
    placementSettingId?: string | undefined;
    placementStepsComplete?: number | undefined;
    placementPercentComplete?: number | undefined;
    placementStudentSteps?: string[] | undefined;
    placementStudentStepIds?: string[] | undefined;
    locationAddress?: AddressViewModel | undefined;
    managerPersonIds?: string[] | undefined;
    isFlightsRequired?: boolean | undefined;
    departureLocation?: string | undefined;
    arrivalLocation?: string | undefined;
    accommodationRequired?: boolean | undefined;
}