import {all, call, put, takeLatest} from 'redux-saga/effects';
import {apiConfig} from '../../config';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {
  PLACEMENT,
  PLACEMENT_LIST,
  placementLoadError,
  placementLoadSuccess,
  placementListSuccess, placementListError, PLACEMENT_EVALUATIONS,
} from './Actions';
import {PlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {PayloadAction} from '@reduxjs/toolkit';
import {PlacementListByModel} from '../../types/requests/PlacementListByModel';

function* ListPlacements(submission: PlacementListByModel)  {
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.corePlacementsPath}/list`,
      data: submission
    });

    const results: PlacementDetailModel[] = res.data.map((x: any) => PlacementDetailModel.fromJS(x));
    yield put(placementListSuccess(results));
  } catch (error) {
    yield put(placementListError(error));
  }
}

function* ListPlacementEvaluations(placementId: string)  {
  console.log('SAGA - placementSaga - listPlacements');

  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.corePlacementsPath}/${placementId}/intern-evaluations`,
    });

    const results: PlacementDetailModel[] = res.data.map((x: any) => PlacementDetailModel.fromJS(x));
    yield put(placementListSuccess(results));
  } catch (error) {
    yield put(placementListError(error));
  }
}

export function* LoadPlacement(id: string) {
  console.log('SAGA - placementSaga - LoadPlacement');
  try {
    const res = yield authApi.get(`${apiConfig.corePlacementsPath}/${id}`);
    const placement: PlacementDetailModel = PlacementDetailModel.fromJS(res.data);
    yield put(placementLoadSuccess(placement));
  } catch (err) {
    yield put(placementLoadError(err));
  }
}


export function* watchPlacementListLoad() {
  yield takeLatest(PLACEMENT_LIST.LOAD, ({payload}: PayloadAction<PlacementListByModel>) => ListPlacements(payload))
}

export function* watchPlacementListRefresh() {
  yield takeLatest(PLACEMENT_LIST.REFRESH, ({payload}: PayloadAction<PlacementListByModel>) => ListPlacements(payload))
}

export function* watchPlacementEvaluationsListLoad() {
  yield takeLatest(PLACEMENT_EVALUATIONS.LOAD, ({payload}: PayloadAction<string>) => ListPlacementEvaluations(payload))
}

export function* watchPlacementLoad() {
  yield takeLatest(PLACEMENT.LOAD, ({payload}: PayloadAction<string>) => LoadPlacement(payload))
}


export default function* placementSaga() {
  yield all ([
    call(watchPlacementListLoad),
    call(watchPlacementListRefresh),
    call(watchPlacementLoad),
  ])
}

