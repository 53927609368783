import {formatDate} from '../../@template/helpers/datetimes';
import { AlumniStatusEnum, StudentTypes} from '../models/Enums';

const defaultStudentStatusFilter = ['Potential', 'Pipeline', 'Intern'];
const defaultWorkShadowStatusFilter = ['Potential', 'Pipeline', 'Confirmed'];
const defaultAlumniStatusFilter = [AlumniStatusEnum.Early];
export const defaultEngagementInfoSearchParamsModel = () => {
  return EngagementInfoSearchParamsModel.fromJS({
    studentTypes: [StudentTypes.WorkShadow, StudentTypes.University, StudentTypes.Alumni],
    searchText: '',
    studentStatusFlags: defaultStudentStatusFilter,
    alumniStatusFlags: defaultAlumniStatusFilter,
    workShadowStatusFlags: defaultWorkShadowStatusFilter,
    advisorUsers: [],
    primaryOnly: true,
    advisorSelected: false,
    excludeAdvisorSelected: false,
    noPrimaryAppointed: false,
    noAdvisorAppointed: false,
    byTeam: false,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    state: [],
    countryCode: 'AU',
    contactSearchText: '',
    includeInactive: false,
    usingBoth: false,
    usingEither: false,
    usingApp: false,
    usingPortal: false,
    signedNotPermission: false,
    permissionNotSigned: false,
    notSignedNotPermission: false,
    notArchived: true,
    includeArchived: false,
    onlyArchived: false,
    isNoContact: false,
    companyIds: [],
  })
}

export class EngagementInfoSearchParamsModel implements IEngagementInfoSearchParamsModel {
  studentTypes?: string[] | undefined;
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  isNoContact?: boolean | undefined;
  usingBoth?: boolean | undefined;
  usingEither?: boolean | undefined;
  usingApp?: boolean | undefined;
  usingPortal?: boolean | undefined;
  signedNotPermission?: boolean | undefined;
  permissionNotSigned?: boolean | undefined;
  notSignedNotPermission?: boolean | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  alumniTags?: string[] | undefined;
  alumniStatusFlags?: string[] | undefined;
  studentTags?: string[] | undefined;
  studentStatusFlags?: string[] | undefined;
  workShadowTags?: string[] | undefined;
  workShadowStatusFlags?: string[] | undefined;
  companyIds?: string[] | undefined;

  constructor(data?: IEngagementInfoSearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["studentTypes"])) {
        this.studentTypes = [] as any;
        for (let item of Data["studentTypes"])
          this.studentTypes!.push(item);
      }
      this.searchText = Data["searchText"];
      this.joinedFrom = Data["joinedFrom"] ? new Date(Data["joinedFrom"].toString()) : <any>undefined;
      this.joinedTo = Data["joinedTo"] ? new Date(Data["joinedTo"].toString()) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data["primaryOnly"];
      this.advisorSelected = Data["advisorSelected"];
      this.excludePrimarySelected = Data["excludePrimarySelected"];
      this.noPrimaryAppointed = Data["noPrimaryAppointed"];
      this.noAdvisorAppointed = Data["noAdvisorAppointed"];
      this.byTeam = Data["byTeam"];
      this.advisorTeamId = Data["advisorTeamId"];
      this.advisorSubTeamId = Data["advisorSubTeamId"];
      this.byAdvisorTeam = Data["byAdvisorTeam"];
      this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
      if (Array.isArray(Data["state"])) {
        this.state = [] as any;
        for (let item of Data["state"])
          this.state!.push(item);
      }
      this.countryCode = Data["countryCode"];
      this.contactSearchText = Data["contactSearchText"];
      this.isNoContact = Data["isNoContact"];
      this.usingBoth = Data["usingBoth"];
      this.usingEither = Data["usingEither"];
      this.usingApp = Data["usingApp"];
      this.usingPortal = Data["usingPortal"];
      this.signedNotPermission = Data["signedNotPermission"];
      this.permissionNotSigned = Data["permissionNotSigned"];
      this.notSignedNotPermission = Data["notSignedNotPermission"];
      this.notArchived = Data["notArchived"];
      this.includeArchived = Data["includeArchived"];
      this.onlyArchived = Data["onlyArchived"];
      if (Array.isArray(Data["alumniTags"])) {
        this.alumniTags = [] as any;
        for (let item of Data["alumniTags"])
          this.alumniTags!.push(item);
      }
      if (Array.isArray(Data["alumniStatusFlags"])) {
        this.alumniStatusFlags = [] as any;
        for (let item of Data["alumniStatusFlags"])
          this.alumniStatusFlags!.push(item);
      }
      if (Array.isArray(Data["studentTags"])) {
        this.studentTags = [] as any;
        for (let item of Data["studentTags"])
          this.studentTags!.push(item);
      }
      if (Array.isArray(Data["studentStatusFlags"])) {
        this.studentStatusFlags = [] as any;
        for (let item of Data["studentStatusFlags"])
          this.studentStatusFlags!.push(item);
      }
      if (Array.isArray(Data["workShadowTags"])) {
        this.workShadowTags = [] as any;
        for (let item of Data["workShadowTags"])
          this.workShadowTags!.push(item);
      }
      if (Array.isArray(Data["workShadowStatusFlags"])) {
        this.workShadowStatusFlags = [] as any;
        for (let item of Data["workShadowStatusFlags"])
          this.workShadowStatusFlags!.push(item);
      }
      if (Array.isArray(Data["companyIds"])) {
        this.companyIds = [] as any;
        for (let item of Data["companyIds"])
          this.companyIds!.push(item);
      }
    }
  }

  static fromJS(data: any): EngagementInfoSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new EngagementInfoSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.studentTypes)) {
      data["studentTypes"] = [];
      for (let item of this.studentTypes)
        data["studentTypes"].push(item);
    }
    data["searchText"] = this.searchText;
    data["joinedFrom"] = this.joinedFrom ? formatDate(this.joinedFrom) : <any>undefined;
    data["joinedTo"] = this.joinedTo ? formatDate(this.joinedTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryOnly"] = this.primaryOnly;
    data["advisorSelected"] = this.advisorSelected;
    data["excludePrimarySelected"] = this.excludePrimarySelected;
    data["noPrimaryAppointed"] = this.noPrimaryAppointed;
    data["noAdvisorAppointed"] = this.noAdvisorAppointed;
    data["byTeam"] = this.byTeam;
    data["advisorTeamId"] = this.advisorTeamId;
    data["advisorSubTeamId"] = this.advisorSubTeamId;
    data["byAdvisorTeam"] = this.byAdvisorTeam;
    data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
    if (Array.isArray(this.state)) {
      data["state"] = [];
      for (let item of this.state)
        data["state"].push(item);
    }
    data["countryCode"] = this.countryCode;
    data["contactSearchText"] = this.contactSearchText;
    data["isNoContact"] = this.isNoContact;
    data["usingBoth"] = this.usingBoth;
    data["usingEither"] = this.usingEither;
    data["usingApp"] = this.usingApp;
    data["usingPortal"] = this.usingPortal;
    data["signedNotPermission"] = this.signedNotPermission;
    data["permissionNotSigned"] = this.permissionNotSigned;
    data["notSignedNotPermission"] = this.notSignedNotPermission;
    data["notArchived"] = this.notArchived;
    data["includeArchived"] = this.includeArchived;
    data["onlyArchived"] = this.onlyArchived;
    if (Array.isArray(this.alumniTags)) {
      data["alumniTags"] = [];
      for (let item of this.alumniTags)
        data["alumniTags"].push(item);
    }
    if (Array.isArray(this.alumniStatusFlags)) {
      data["alumniStatusFlags"] = [];
      for (let item of this.alumniStatusFlags)
        data["alumniStatusFlags"].push(item);
    }
    if (Array.isArray(this.studentTags)) {
      data["studentTags"] = [];
      for (let item of this.studentTags)
        data["studentTags"].push(item);
    }
    if (Array.isArray(this.studentStatusFlags)) {
      data["studentStatusFlags"] = [];
      for (let item of this.studentStatusFlags)
        data["studentStatusFlags"].push(item);
    }
    if (Array.isArray(this.workShadowTags)) {
      data["workShadowTags"] = [];
      for (let item of this.workShadowTags)
        data["workShadowTags"].push(item);
    }
    if (Array.isArray(this.workShadowStatusFlags)) {
      data["workShadowStatusFlags"] = [];
      for (let item of this.workShadowStatusFlags)
        data["workShadowStatusFlags"].push(item);
    }
      if (Array.isArray(this.companyIds)) {
        data["companyIds"] = [];
        for (let item of this.companyIds)
          data["companyIds"].push(item);
      }
    return data;
  }
}

export interface IEngagementInfoSearchParamsModel {
  studentTypes?: string[] | undefined;
  /** Searches name, quick notes, profile */
  searchText?: string | undefined;
  joinedFrom?: Date | undefined;
  joinedTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  state?: string[] | undefined;
  countryCode?: string | undefined;
  contactSearchText?: string | undefined;
  isNoContact?: boolean | undefined;
  usingBoth?: boolean | undefined;
  usingEither?: boolean | undefined;
  usingApp?: boolean | undefined;
  usingPortal?: boolean | undefined;
  signedNotPermission?: boolean | undefined;
  permissionNotSigned?: boolean | undefined;
  notSignedNotPermission?: boolean | undefined;
  notArchived?: boolean | undefined;
  includeArchived?: boolean | undefined;
  onlyArchived?: boolean | undefined;
  alumniTags?: string[] | undefined;
  alumniStatusFlags?: string[] | undefined;
  studentTags?: string[] | undefined;
  studentStatusFlags?: string[] | undefined;
  workShadowTags?: string[] | undefined;
  workShadowStatusFlags?: string[] | undefined;
  companyIds?: string[] | undefined;
}