import {STUDENT_CHANGE_REQUEST, CONTACT_CHANGE_REQUEST} from './Actions';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {StudentUpdatesSearchPaged} from '../../types/views/StudentUpdatesSearchPaged';
import {ContactUpdatesSearchPaged} from '../../types/views/ContactUpdatesSearchPaged';

export interface IChangeRequestState {
  studentChangeRequestLoading: boolean | false;
  studentChangeRequestError: string | undefined;
  studentChangeRequestDetail: StudentUpdatesSearchPaged | undefined;
  contactChangeRequestLoading: boolean | false;
  contactChangeRequestError: string | undefined;
  contactChangeRequestDetail: ContactUpdatesSearchPaged | undefined;
}

const initialState: IChangeRequestState = {
  studentChangeRequestLoading: false,
  studentChangeRequestError: undefined,
  studentChangeRequestDetail: undefined,
  contactChangeRequestLoading: false,
  contactChangeRequestError: undefined,
  contactChangeRequestDetail: undefined,
}

const changeRequestReducer = createReducer(initialState, {
  [STUDENT_CHANGE_REQUEST.LOAD]: (state) => {
    state.studentChangeRequestLoading = true;
    state.studentChangeRequestError = undefined;
  },
  [STUDENT_CHANGE_REQUEST.REFRESH]: (state) => {
    state.studentChangeRequestError = undefined;
  },
  [STUDENT_CHANGE_REQUEST.LOADED]: (state, action: PayloadAction<StudentUpdatesSearchPaged>) => {
    state.studentChangeRequestLoading = false;
    state.studentChangeRequestError = undefined;
    state.studentChangeRequestDetail = action.payload;
  },
  [STUDENT_CHANGE_REQUEST.ERROR]: (state, action: PayloadAction<string>) => {
    state.studentChangeRequestLoading = false;
    state.studentChangeRequestError = action.payload;
  },
  [CONTACT_CHANGE_REQUEST.LOAD]: (state) => {
    state.contactChangeRequestLoading = true;
    state.contactChangeRequestError = undefined;
  },
  [CONTACT_CHANGE_REQUEST.REFRESH]: (state) => {
    state.contactChangeRequestLoading = true;
  },
  [CONTACT_CHANGE_REQUEST.LOADED]: (state, action: PayloadAction<ContactUpdatesSearchPaged>) => {
    state.contactChangeRequestLoading = false;
    state.contactChangeRequestError = undefined;
    state.contactChangeRequestDetail = action.payload;
  },
  [CONTACT_CHANGE_REQUEST.ERROR]: (state, action: PayloadAction<string>) => {
    state.contactChangeRequestLoading = false;
    state.contactChangeRequestError = action.payload;
  },
});

export default changeRequestReducer;