import React, {HTMLAttributes, SyntheticEvent, useCallback, useEffect, useState} from 'react';
import {MessageView} from '../../../@template';
import {useFormContext, Controller, FieldValues, get} from 'react-hook-form';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import {useSelector} from 'react-redux';
import {hasItems} from '../../../@template/helpers/arrays';
import {Autocomplete, CircularProgress, MenuItem, TextField} from '@mui/material';
import {debounce} from 'lodash';
import {CompanySuggestModel} from '../../../types/views/CompanySuggestModel';
import authApi from '../../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../../config';
import {isFieldRequired, useValidationContext} from '../../../@template/validation/ValidationContext';
import {selectAllCompaniesCache} from '../../../redux/companyCache/Selectors';
import {companyCacheAdd} from '../../../redux/companyCache/Actions';
import {CompanySuggestRequest} from '../../../types/requests/CompanySuggestRequest';

interface IFormCompanyAutoCompleteProps extends FieldValues {
  isClient?: boolean;
  isPartner?: boolean;
}

const FormCompanyAutoComplete = (props: IFormCompanyAutoCompleteProps) => {
  const { schema, changeRequest } = useValidationContext();
  const { control, watch, setValue, formState: { errors } } = useFormContext();
  const { name, label, data = [], labelField = 'title', valueField = 'id', defaultSelectedItem = undefined,
    isClient = false, isPartner = false, required = false, fullWidth = true, readOnly = false } = props;

  const isRequired = required ? true : schema ? isFieldRequired(schema, name, required) : false;

  const dispatch = useAppDispatch();
  const watchCompanyId = watch(name);
  let isError = false;
  let errorMessage = '';

  if (errors) {
    errorMessage = get(errors, name)?.message;
    if (errorMessage)
      isError = true;
  }

  const [suggestError, setSuggestError] = useState(undefined);
  const [suggesting, setSuggesting] = useState(false);

  const [companyId, setCompanyId] = useState(watchCompanyId);
  const [suggestions, setSuggestions] = useState<CompanySuggestModel[]>([]);
  const [selectedOption, setSelectedOption] = useState<any | undefined>(undefined)

  const companyItems = useSelector(selectAllCompaniesCache);

  const suggestCompanies = (searchText: string) => {
    // Only search if a value supplied for searching (prevents loading state on selections)
    if (searchText) {
      setSuggesting(true);
      const submission = CompanySuggestRequest.fromJS({
        'isClient': isClient,
        'isPartner': isPartner
      })
      const suggestParams = new URLSearchParams({
        code: 'utf-8',
        query: searchText,
      });
      authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.coreCompaniesPath}/suggest`,
        params: suggestParams,
        data: submission
      }).then(res => {
        let results: CompanySuggestModel[] = [];
        if (res && res.data && hasItems(res.data)) {
          results = res.data.map((x: any) => CompanySuggestModel.fromJS(x));
        }
        setSuggesting(false);
        setSuggestions(results);
      }).catch((err) => {
        setSuggestError(err);
      });
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (watchCompanyId && !companyItems?.find(c => c.id === watchCompanyId)) {
      dispatch(companyCacheAdd([watchCompanyId]));
    }
  }, [watchCompanyId]);

  useEffect(() => {
    // Necessary to deal with default values loading detail from cache
    if (watchCompanyId) {
      let optionItem = selectedOption;
      if (!optionItem || optionItem.companyName === 'Loading') optionItem = companyItems?.find(c => c.id === watchCompanyId);
      if (!optionItem) {
        optionItem = {
          id: watchCompanyId,
          companyName: 'Loading'
        };
      }
      setSelectedOption(optionItem);
      setValue(name, optionItem.id);
    }
  }, [watchCompanyId, companyItems]);

  const isOptionEqualToValue = (option: any, value: any) => {
    return option && value
      && option.hasOwnProperty(valueField)
      && value.hasOwnProperty(valueField)
      && option[valueField] === value[valueField];
  }

  const handleSearchTextChanged = useCallback(
    debounce((searchText: string) => {
      suggestCompanies(searchText);
    }, 400), [watchCompanyId]);

  const handleChanged = (e: SyntheticEvent<Element, Event>, selectedVal: any | undefined) => {
    if (!selectedVal) {
      setCompanyId(undefined);
      setSelectedOption(undefined);
      setValue(name, undefined);
    }
    else {
      setCompanyId(selectedVal.id);
      setSelectedOption(selectedVal);
      setValue(name, selectedVal.id);
    }
  };

  const getData = () => {
    // Ensure source data contains the original selection
    if (selectedOption) {
      let newResults = suggestions.filter((x: any) => x.id !== selectedOption?.id);
      return [selectedOption, ...newResults];
    } else {
      return suggestions;
    }
  }

  const showError = () => {
    return <MessageView variant="error" message="An error occurred searching companies." />;
  };
  return (
    <>
      {suggestError && showError()}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ...rest } }) => (
          <Autocomplete
            {...rest}
            onChange={handleChanged}
            options={getData()}
            autoComplete
            disabled={readOnly}
            filterOptions={(options, state) => options}
            getOptionLabel={(option: any) => option.companyName?? ''}
            renderOption={(props: HTMLAttributes<HTMLElement>, option: any) => (<MenuItem {...props} key={`comp-auto-${option.id}`}>{option.companyName}</MenuItem>)}
            isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
            onInputChange={(event, newInputValue) => {
              handleSearchTextChanged(newInputValue);
            }}
            value={selectedOption??null}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                fullWidth={fullWidth}
                label={label}
                required={isRequired}
                error={isError}
                helperText={errorMessage}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {suggesting ? <CircularProgress color="inherit" size={20} /> : undefined}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </>
  )
}

export default FormCompanyAutoComplete;