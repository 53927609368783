import makeStyles from '@mui/styles/makeStyles';
import {AppTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: AppTheme) => {
  return {
    container: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: '100vh',
      position: 'relative',
      top: 0,
      right: 0,
      zIndex: 1109,
      width: '10rem',
      transition: 'all 0.5s ease',

      '& .MuiAvatar-root': {
        margin: '5px auto',
      },

      [theme.breakpoints.up('sm')]: {
        position: 'fixed',
        '&.mini-sidebar-collapsed': {
          width: '4rem',

          '& + .main-content': {
            marginLeft: '4rem',
            width: 'calc(100vw - 4rem)',
            flex: 'auto',

            '& .app-bar': {
              width: 'calc(100vw - 4rem)',
            },
          },

          '& .ct-user-info': {
            paddingLeft: '12px !important',
            paddingRight: '12px !important',
          },

          '& .nav-item-icon': {
            display: 'block',
          },

          '& .nav-item-text, & .nav-item-icon-arrow': {
            opacity: 0,
            visibility: 'hidden',
          },

          '& .nav-item-header, & .user-info, & .collapse-children': {
            display: 'none',
          },

          '& .nav-item': {
            width: 48,
            paddingLeft: 14,
            marginLeft: 8,
            borderRadius: '50%',
          },
        },
      },

      [theme.breakpoints.up('lg')]: {
        width: '21rem',
        '&.mini-sidebar-collapsed': {
          width: '5rem',

          '& + .main-content': {
            marginLeft: '5rem',
            width: 'calc(100vw - 5rem)',

            '& .app-bar': {
              width: 'calc(100vw - 5rem)',
            },
          },

          '& .ct-user-info': {
            paddingLeft: '12px !important',
            paddingRight: '12px !important',
          },

          '& .nav-item': {
            height: 48,
            paddingLeft: 12,
            marginLeft: 12,
          },
        },
      },
    },
    sidebarBg: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 3px 2px rgba(0,0,0,0.08)',
    },
    sidebarHeader: {
      display: 'flex',
      flexDirection: 'row',
    },
    scrollAppSidebar: {
      paddingTop: 0,
      paddingBottom: 20,
      height: 'calc(100vh - 58px) !important',

      [theme.breakpoints.up('lg')]: {
        height: 'calc(100vh - 65px) !important',
      },
    },
    drawerScrollAppSidebar: {
      paddingTop: 0,
      paddingBottom: 20,
      height: 'calc(100vh - 58px) !important',

      [theme.breakpoints.up('lg')]: {
        height: 'calc(100vh - 65px) !important',
      },
    },
    sidebarStandard: {
      height: '100%',
      width: '100%',
      color: 'white',
      overflow: 'hidden',
    },
    notiBtn: {
      justifyContent: 'flex-start',
      width: '100%',
      height: 56,
      fontSize: 16,
      borderRadius: 0,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      color: theme.palette.grey[800],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.up('xs')]: {
        height: 70,
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        width: 'auto',
        borderLeft: 'solid 1px',
        borderColor: theme.palette.grey[200],
        color: theme.palette.grey[400],
        '&:hover, &:focus': {
          color: theme.palette.text.primary,
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
    },
    badgeStyle: {
      marginRight: 8,
    },
    chatIcon: {
      fontSize: 22,
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('md')]: {
        fontSize: 26,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 30,
      },
    },
    avatar: {
      width: 28,
      height: 28,
      [theme.breakpoints.up('md')]: {
        width: 32,
        height: 32,
      },
      [theme.breakpoints.up('lg')]: {
        width: 36,
        height: 36,
      },
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  };
});
export default useStyles;
