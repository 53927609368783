import {ICommonLoadActionType, ICommonSaveActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {IActivityGroupSearchModel} from '../../types/requests/ActivityGroupSearchModel';
import {IActivityGroupModel} from '../../types/models/ActivityGroupModel';
import {IThreadDetailModel, ThreadDetailModel} from '../../types/models/ThreadDetailModel';
import {IInboxSummary} from '../../types/views/InboxSummary';
import {ThreadMetadataModel} from '../../types/views/ThreadMetadataModel';
import {IThreadMetadataRequest} from '../../types/requests/ThreadMetadataRequest';
import {IDraftIdentifier, IDraftSaveRequest, IMessageSaveRequest} from '../../types/requests/MessageSaveRequest';
import {IMessageDraftModel} from '../../types/requests/MessageDraftModel';
import {IActivityEventSearchModel} from '../../types/requests/ActivityEventSearchModel';
import {IEventItemViewModel} from '../../types/models/EventItemViewModel';
import {ThreadTaskList} from '../../types/models/ThreadTaskList';

export interface IGroupActionTypes extends ICommonLoadActionType {
  REFRESH: string;
  THREADS_LOAD: string;
  THREADS_LOADED: string;
  THREADS_ERROR: string;
}

export interface IRefreshActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export interface IFeedActionTypes extends ICommonLoadActionType {
  REFRESH: string;
  HISTORY_LOAD: string;
  HISTORY_LOADED: string;
  HISTORY_LOAD_ALL: string;
}

export interface IThreadActionTypes extends ICommonLoadActionType {
  READ: string;
  METADATA_LOAD: string;
  METADATA_LOADED: string;
  METADATA_ERROR: string;
}

export interface IMessageActionTypes {
  SAVE: string;
  ERROR: string;
  SAVED: string;
}

export interface IDraftActionTypes extends ICommonSaveActionType {
  DELETE: string;
  COMPLETE: string;
}

export interface IGroupNotificationActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const GROUPS: IGroupActionTypes = {
  LOAD: 'messaging/groups/load',
  ERROR: 'messaging/groups/loadError',
  LOADED: 'messaging/groups/loadSuccess',
  REFRESH: 'messaging/groups/refresh',
  THREADS_LOAD: 'messaging/groupsThreads/load',
  THREADS_ERROR: 'messaging/groupsThreads/error',
  THREADS_LOADED: 'messaging/groupsThreads/loadSuccess',
};

export const GROUP_NOTIFICATIONS: IGroupNotificationActionTypes = {
  LOAD: 'messaging/groupNotifications/load',
  ERROR: 'messaging/groupNotifications/loadError',
  LOADED: 'messaging/groupNotifications/loadSuccess',
  REFRESH: 'messaging/groupNotifications/refresh',
};

export const NOTIFICATIONS: IRefreshActionTypes = {
  LOAD: 'messaging/notifications/load',
  ERROR: 'messaging/notifications/loadError',
  LOADED: 'messaging/notifications/loadSuccess',
  REFRESH: 'messaging/notifications/refresh',
};

export const THREAD: IThreadActionTypes = {
  ERROR: 'messaging/thread/error',
  LOAD: 'messaging/thread/load',
  LOADED: 'messaging/thread/loadSuccess',
  READ: 'messaging/thread/markRead',
  METADATA_ERROR: 'messaging/threadMetadata/Error',
  METADATA_LOAD: 'messaging/threadMetadata/load',
  METADATA_LOADED: 'messaging/threadMetadata/loadSuccess',
};

export const MESSAGE: IMessageActionTypes = {
  ERROR: 'messaging/message/error',
  SAVE: 'messaging/message/save',
  SAVED: 'messaging/message/saveSuccess',
};

export const MESSAGE_FEED: IFeedActionTypes = {
  LOAD: 'messaging/feed/load',
  REFRESH: 'messaging/feed/refresh',
  LOADED: 'messaging/feed/loadSuccess',
  ERROR: 'messaging/feed/error',
  HISTORY_LOAD: 'messaging/feed/historyLoad',
  HISTORY_LOADED: 'messaging/feed/historyLoadSuccess',
  HISTORY_LOAD_ALL: 'messaging/feed/historyLoadAll',
};

export const DRAFT: IDraftActionTypes = {
  COMPLETE: 'messaging/draft/complete',
  DELETE: 'messaging/draft/delete',
  LOAD: 'messaging/draft/load',
  LOADED: 'messaging/draft/loaded',
  LOAD_ERROR: 'messaging/draft/loadError',
  SAVE: 'messaging/draft/save',
  SAVED: 'messaging/draft/saveSuccess',
  SAVE_ERROR: 'messaging/draft/saveError'
};

export const DRAFT_LIST: IRefreshActionTypes = {
  ERROR: 'messaging/drafts/error',
  LOAD: 'messaging/drafts/load',
  LOADED: 'messaging/drafts/loadSuccess',
  REFRESH: 'messaging/drafts/refresh',
};

export const TASK_THREAD_LIST: IRefreshActionTypes = {
  ERROR: 'messaging/taskThreadList/error',
  LOAD: 'messaging/taskThreadList/load',
  LOADED: 'messaging/taskThreadList/loadSuccess',
  REFRESH: 'messaging/taskThreadList/refresh',
};

export const groupsNotificationLoad = createAction(GROUP_NOTIFICATIONS.LOAD);
export const groupsNotificationLoadError = createAction<string>(GROUP_NOTIFICATIONS.ERROR);
export const groupsNotificationLoadSuccess = createAction<IActivityGroupModel[] | undefined>(GROUP_NOTIFICATIONS.LOADED);
export const groupsNotificationRefresh= createAction(GROUP_NOTIFICATIONS.REFRESH);

export const groupsLoad = createAction<IActivityGroupSearchModel>(GROUPS.LOAD);
export const groupsLoadError = createAction<string>(GROUPS.ERROR);
export const groupsLoadSuccess = createAction<IActivityGroupModel[] | undefined>(GROUPS.LOADED);
export const groupsLoadRefresh= createAction<IActivityGroupSearchModel>(GROUPS.REFRESH);
export const groupsThreadsLoad = createAction<string>(GROUPS.THREADS_LOAD);
export const groupsThreadsLoadError = createAction<string>(GROUPS.THREADS_ERROR);
export const groupsThreadsLoadSuccess = createAction<IThreadDetailModel[] | undefined>(GROUPS.THREADS_LOADED);

export const notificationsLoad = createAction(NOTIFICATIONS.LOAD);
export const notificationsLoadRefresh = createAction(NOTIFICATIONS.REFRESH);
export const notificationsLoadError = createAction<string>(NOTIFICATIONS.ERROR);
export const notificationsLoadSuccess = createAction<IInboxSummary>(NOTIFICATIONS.LOADED);

export const threadLoad = createAction<string>(THREAD.LOAD);
export const threadMarkRead = createAction<string>(THREAD.READ);
export const threadLoadError = createAction<string>(THREAD.ERROR);
export const threadLoadSuccess = createAction<ThreadDetailModel>(THREAD.LOADED);
export const threadMetadataLoad = createAction<IThreadMetadataRequest>(THREAD.METADATA_LOAD);
export const threadMetadataLoadSuccess = createAction<ThreadMetadataModel | undefined>(THREAD.METADATA_LOADED);
export const threadMetadataLoadError = createAction<string>(THREAD.METADATA_ERROR);

export const messageError = createAction<string>(MESSAGE.ERROR);
export const messageSave = createAction<IMessageSaveRequest>(MESSAGE.SAVE);
export const messageSaveSuccess = createAction(MESSAGE.SAVED);

export const messageFeedLoad = createAction<IActivityEventSearchModel>(MESSAGE_FEED.LOAD);
export const messageFeedRefresh = createAction<IActivityEventSearchModel>(MESSAGE_FEED.REFRESH);
export const messageFeedLoadSuccess = createAction<IEventItemViewModel[] | undefined>(MESSAGE_FEED.LOADED);
export const messageFeedHistoryLoad = createAction<IActivityEventSearchModel>(MESSAGE_FEED.HISTORY_LOAD);
export const messageFeedHistoryLoadSuccess = createAction<IEventItemViewModel[] | undefined>(MESSAGE_FEED.HISTORY_LOADED);
export const messageFeedError = createAction<IMessageSaveRequest>(MESSAGE_FEED.ERROR);
export const messageFeedHistoryLoadAll = createAction<IActivityEventSearchModel>(MESSAGE_FEED.HISTORY_LOAD_ALL);


export const messageDraftLoad = createAction<IDraftIdentifier>(DRAFT.LOAD);
export const messageDraftLoadSuccess = createAction<IMessageDraftModel | undefined>(DRAFT.LOADED);
export const messageDraftLoadError = createAction<string>(DRAFT.LOAD_ERROR);
export const messageDraftSave = createAction<IDraftSaveRequest>(DRAFT.SAVE);
export const messageDraftSaveError = createAction<IDraftSaveRequest>(DRAFT.SAVE_ERROR);
export const messageDraftSaveSuccess = createAction(DRAFT.SAVED);
export const messageDraftDelete = createAction<IDraftIdentifier>(DRAFT.DELETE);
export const messageDraftComplete = createAction<IDraftIdentifier>(DRAFT.COMPLETE);

export const messageDraftListLoad = createAction(DRAFT_LIST.LOAD);
export const messageDraftListRefresh = createAction(DRAFT_LIST.REFRESH);
export const messageDraftListLoadError = createAction<string>(DRAFT_LIST.ERROR);
export const messageDraftListLoadSuccess = createAction<string[] | undefined>(DRAFT_LIST.LOADED);

export const listTaskThreads = createAction(TASK_THREAD_LIST.LOAD);
export const refreshTaskThreads = createAction(TASK_THREAD_LIST.REFRESH);
export const taskThreadsLoaded = createAction<ThreadTaskList[]>(TASK_THREAD_LIST.LOADED);
export const taskThreadsError = createAction<string>(TASK_THREAD_LIST.ERROR);