import {PhoneModel} from '../models/PhoneModel';
import { StudentSearchModel } from './StudentSearchModel';
import { WorkShadowSearchModel } from './WorkShadowSearchModel';
import { AlumniSearchModel } from './AlumniSearchModel';
import { EngagementInfoSearchModel } from './EngagementInfoSearchModel';
import {ContactSearchModel} from './ContactSearchModel';
import {VolunteerSearchModel} from './VolunteerSearchModel';

export class PersonSearchResultModel implements IPersonSearchResultModel {
  dateIndexed?: Date | undefined;
  id?: string | undefined;
  personNumber?: number | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  phoneNumbers?: PhoneModel[] | undefined;
  facebookUrl?: string | undefined;
  linkedinUrl?: string | undefined;
  twitterUrl?: string | undefined;
  instagramUrl?: string | undefined;
  isAdvisor?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isBuddyWorkShadow?: boolean | undefined;
  isStudentAppUser?: boolean | undefined;
  isHidden?: boolean | undefined;
  dateStudentAppCreated?: Date | undefined;
  dateStudentAppUpdated?: Date | undefined;
  isStudentPortalUser?: boolean | undefined;
  dateStudentPortalCreated?: Date | undefined;
  dateStudentPortalUpdated?: Date | undefined;
  isEmployerPortalUser?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isNoContact?: boolean | undefined;
  dateIsNoContactSet?: Date | undefined;
  personRoles?: string | undefined;
  jobTitle?: string | undefined;
  picture?: string | undefined;
  imageFileId?: string | undefined;
  studentDetail?: StudentSearchModel | undefined;
  workShadowDetail?: WorkShadowSearchModel | undefined;
  alumniDetail?: AlumniSearchModel | undefined;
  engagementInfoDetail?: EngagementInfoSearchModel | undefined;
  contactDetail?: ContactSearchModel | undefined;
  volunteerDetail?: VolunteerSearchModel | undefined;

  constructor(data?: IPersonSearchResultModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.dateIndexed = Data["dateIndexed"] ? new Date(Data["dateIndexed"].toString()) : <any>undefined;
      this.id = Data["id"];
      this.personNumber = Data["personNumber"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.firstName = Data["firstName"];
      this.lastName = Data["lastName"];
      this.email = Data["email"];
      this.altEmail = Data["altEmail"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.facebookUrl = Data["facebookUrl"];
      this.linkedinUrl = Data["linkedinUrl"];
      this.twitterUrl = Data["twitterUrl"];
      this.instagramUrl = Data["instagramUrl"];
      this.isAdvisor = Data["isAdvisor"];
      this.isClient = Data["isClient"];
      this.isStudent = Data["isStudent"];
      this.isVolunteer = Data["isVolunteer"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.isAlumni = Data["isAlumni"];
      this.isBuddyWorkShadow = Data["isBuddyWorkShadow"];
      this.isStudentAppUser = Data["isStudentAppUser"];
      this.isHidden = Data["isHidden"];
      this.dateStudentAppCreated = Data["dateStudentAppCreated"] ? new Date(Data["dateStudentAppCreated"].toString()) : <any>undefined;
      this.dateStudentAppUpdated = Data["dateStudentAppUpdated"] ? new Date(Data["dateStudentAppUpdated"].toString()) : <any>undefined;
      this.isStudentPortalUser = Data["isStudentPortalUser"];
      this.dateStudentPortalCreated = Data["dateStudentPortalCreated"] ? new Date(Data["dateStudentPortalCreated"].toString()) : <any>undefined;
      this.dateStudentPortalUpdated = Data["dateStudentPortalUpdated"] ? new Date(Data["dateStudentPortalUpdated"].toString()) : <any>undefined;
      this.isEmployerPortalUser = Data["isEmployerPortalUser"];
      this.isDeleted = Data["isDeleted"];
      this.isNoContact = Data["isNoContact"];
      this.dateIsNoContactSet = Data["dateIsNoContactSet"] ? new Date(Data["dateIsNoContactSet"].toString()) : <any>undefined;
      this.personRoles = Data["personRoles"];
      this.jobTitle = Data["jobTitle"];
      this.picture = Data["picture"];
      this.imageFileId = Data["imageFileId"];
      this.studentDetail = Data["studentDetail"] ? StudentSearchModel.fromJS(Data["studentDetail"]) : <any>undefined;
      this.workShadowDetail = Data["workShadowDetail"] ? WorkShadowSearchModel.fromJS(Data["workShadowDetail"]) : <any>undefined;
      this.alumniDetail = Data["alumniDetail"] ? AlumniSearchModel.fromJS(Data["alumniDetail"]) : <any>undefined;
      this.engagementInfoDetail = Data["engagementInfoDetail"] ? EngagementInfoSearchModel.fromJS(Data["engagementInfoDetail"]) : <any>undefined;
      this.contactDetail = Data["contactDetail"] ? ContactSearchModel.fromJS(Data["contactDetail"]) : <any>undefined;
      this.volunteerDetail = Data["volunteerDetail"] ? VolunteerSearchModel.fromJS(Data["volunteerDetail"]) : <any>undefined;
    }
  }

  static fromJS(data: any): PersonSearchResultModel {
    data = typeof data === 'object' ? data : {};
    let result = new PersonSearchResultModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["dateIndexed"] = this.dateIndexed ? this.dateIndexed.toISOString() : <any>undefined;
    data["id"] = this.id;
    data["personNumber"] = this.personNumber;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["firstName"] = this.firstName;
    data["lastName"] = this.lastName;
    data["email"] = this.email;
    data["altEmail"] = this.altEmail;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item.toJSON());
    }
    data["facebookUrl"] = this.facebookUrl;
    data["linkedinUrl"] = this.linkedinUrl;
    data["twitterUrl"] = this.twitterUrl;
    data["instagramUrl"] = this.instagramUrl;
    data["isAdvisor"] = this.isAdvisor;
    data["isClient"] = this.isClient;
    data["isStudent"] = this.isStudent;
    data["isVolunteer"] = this.isVolunteer;
    data["isWorkShadow"] = this.isWorkShadow;
    data["isAlumni"] = this.isAlumni;
    data["isBuddyWorkShadow"] = this.isBuddyWorkShadow;
    data["isStudentAppUser"] = this.isStudentAppUser;
    data["isHidden"] = this.isHidden;
    data["dateStudentAppCreated"] = this.dateStudentAppCreated ? this.dateStudentAppCreated.toISOString() : <any>undefined;
    data["dateStudentAppUpdated"] = this.dateStudentAppUpdated ? this.dateStudentAppUpdated.toISOString() : <any>undefined;
    data["isStudentPortalUser"] = this.isStudentPortalUser;
    data["dateStudentPortalCreated"] = this.dateStudentPortalCreated ? this.dateStudentPortalCreated.toISOString() : <any>undefined;
    data["dateStudentPortalUpdated"] = this.dateStudentPortalUpdated ? this.dateStudentPortalUpdated.toISOString() : <any>undefined;
    data["isEmployerPortalUser"] = this.isEmployerPortalUser;
    data["isDeleted"] = this.isDeleted;
    data["isNoContact"] = this.isNoContact;
    data["dateIsNoContactSet"] = this.dateIsNoContactSet ? this.dateIsNoContactSet.toISOString() : <any>undefined;
    data["personRoles"] = this.personRoles;
    data["jobTitle"] = this.jobTitle;
    data["picture"] = this.picture;
    data["imageFileId"] = this.imageFileId;
    data["studentDetail"] = this.studentDetail ? this.studentDetail.toJSON() : <any>undefined;
    data["workShadowDetail"] = this.workShadowDetail ? this.workShadowDetail.toJSON() : <any>undefined;
    data["alumniDetail"] = this.alumniDetail ? this.alumniDetail.toJSON() : <any>undefined;
    data["engagementInfoDetail"] = this.engagementInfoDetail ? this.engagementInfoDetail.toJSON() : <any>undefined;
    data["contactDetail"] = this.contactDetail ? this.contactDetail.toJSON() : <any>undefined;
    data["volunteerDetail"] = this.volunteerDetail ? this.volunteerDetail.toJSON() : <any>undefined;
    return data;
  }
}

export interface IPersonSearchResultModel {
  dateIndexed?: Date | undefined;
  id?: string | undefined;
  personNumber?: number | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  phoneNumbers?: PhoneModel[] | undefined;
  facebookUrl?: string | undefined;
  linkedinUrl?: string | undefined;
  twitterUrl?: string | undefined;
  instagramUrl?: string | undefined;
  isAdvisor?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isBuddyWorkShadow?: boolean | undefined;
  isStudentAppUser?: boolean | undefined;
  isHidden?: boolean | undefined;
  dateStudentAppCreated?: Date | undefined;
  dateStudentAppUpdated?: Date | undefined;
  isStudentPortalUser?: boolean | undefined;
  dateStudentPortalCreated?: Date | undefined;
  dateStudentPortalUpdated?: Date | undefined;
  isEmployerPortalUser?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isNoContact?: boolean | undefined;
  dateIsNoContactSet?: Date | undefined;
  personRoles?: string | undefined;
  jobTitle?: string | undefined;
  picture?: string | undefined;
  imageFileId?: string | undefined;
  studentDetail?: StudentSearchModel | undefined;
  workShadowDetail?: WorkShadowSearchModel | undefined;
  alumniDetail?: AlumniSearchModel | undefined;
  engagementInfoDetail?: EngagementInfoSearchModel | undefined;
  contactDetail?: ContactSearchModel | undefined;
  volunteerDetail?: VolunteerSearchModel | undefined;
}