import {splitCase} from './strings';

export function hasItems<T>(elem: any[] | Array<T> | Map<any, T> | Set<T> | Int32Array | Uint32Array | undefined | null) {
  return Array.isArray(elem) && !!elem.length;
}

export function arrayToValueObjectArray(elem: any[] | Array<any> | Int32Array | Uint32Array | undefined | null) {
  if (!Array.isArray(elem)) return [];
  return elem.map((x: any) => { return { value: x}});
}

export function enumToValueObjectArray(enumType: any) {
  if (!enumType) return [];
  return Object.values(enumType).map((x: any) => { return { value: x}});
}

export function enumToNameValueObjectArray(enumType: any) {
  if (!enumType) return [];
  return Object.values(enumType).map((x: any) => { return { value: x, name: splitCase(x) }});
}

export function enumToNameValueObjectArrayNoSplitCase(enumType: any) {
  if (!enumType) return [];
  return Object.values(enumType).map((x: any) => { return { value: x, name: x }});
}

export function arrayToNameValueObjectArray(elem: any[]) {
  if (!Array.isArray(elem)) return [];
  return elem.map((x: any) => { return { value: x, name: splitCase(x) }});
}

