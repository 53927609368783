import {ActivityGroupSearchModel,} from '../../types/requests/ActivityGroupSearchModel';
import {ActivityGroupModel} from '../../types/models/ActivityGroupModel';
import {ThreadDetailModel} from '../../types/models/ThreadDetailModel';
import {ActivityEventSearchModel} from '../../types/requests/ActivityEventSearchModel';
import {EventItemViewModel} from '../../types/models/EventItemViewModel';
import {ThreadMetadataModel} from '../../types/views/ThreadMetadataModel';
import {MessageDraftModel} from '../../types/requests/MessageDraftModel';
import {createDraftSafeSelector, createReducer, createSelector, PayloadAction} from '@reduxjs/toolkit';
import {
  DRAFT, DRAFT_LIST, GROUP_NOTIFICATIONS,
  GROUPS,
  MESSAGE, MESSAGE_FEED, NOTIFICATIONS, TASK_THREAD_LIST, THREAD,
} from './Actions';
import {InboxSummary} from '../../types/views/InboxSummary';
import {hasItems} from '../../@template/helpers/arrays';
import {ThreadTaskList} from '../../types/models/ThreadTaskList';
import {IDraftIdentifier} from '../../types/requests/MessageSaveRequest';

export interface IMessagingState {
  groupsNotificationList: ActivityGroupModel[] | undefined;
  groupsNotificationError: string | undefined;
  groupsNotificationLoading: boolean;
  groupsNotificationRefreshing: boolean;
  groupsSearch: ActivityGroupSearchModel | undefined;
  groupsList: ActivityGroupModel[] | undefined;
  groupsError: string | undefined;
  groupsLoading: boolean;
  groupsRefreshing: boolean;
  groupsThreadsList: ThreadDetailModel[] | undefined;
  groupsThreadsError: string | undefined;
  groupsThreadsLoading: boolean;
  taskThreadListRefreshing: boolean;
  notificationsSummary: InboxSummary | undefined;
  notificationsError: string | undefined;
  notificationsLoading: boolean;
  notificationsRefreshing: boolean;
  messageSearch: ActivityEventSearchModel | undefined;
  messageList: EventItemViewModel[] | undefined;
  messageListStart: Date | undefined;
  messageListIsFullHistory: boolean;
  messageListError: string | undefined;
  messageListLoading: boolean;
  messageListRefreshing: boolean;
  messageListHistoryLoading: boolean;
  messageSaving: boolean;
  messageError: string | undefined;
  threadMetadata: ThreadMetadataModel | undefined;
  threadMetadataError: string | undefined;
  threadMetadataLoading: boolean;
  thread: ThreadDetailModel | undefined;
  threadError: string | undefined;
  threadLoading: boolean;
  messageDraft: MessageDraftModel | undefined;
  messageDraftError: string | undefined;
  messageDraftLoading: boolean;
  messageDraftSaveError: string | undefined;
  messageDraftSaving: boolean;
  messageDraftIds: IDraftIdentifier[] | undefined;
  messageDraftIdsError: string | undefined;
  messageDraftIdsLoading: boolean;
  taskThreadsList: ThreadTaskList[] | undefined;
  taskThreadsLoading: boolean;
  taskThreadsRefreshing: boolean;
  taskThreadsError: string | undefined;
}

const initialState: IMessagingState = {
  groupsNotificationList: undefined,
  groupsNotificationError: undefined,
  groupsNotificationLoading: false,
  groupsNotificationRefreshing: false,
  groupsSearch: undefined,
  groupsList: undefined,
  groupsError: undefined,
  groupsLoading: false,
  groupsRefreshing: false,
  groupsThreadsList: undefined,
  groupsThreadsError: undefined,
  groupsThreadsLoading: false,
  taskThreadListRefreshing: false,
  notificationsSummary: undefined,
  notificationsError: undefined,
  notificationsLoading: false,
  notificationsRefreshing: false,
  messageSearch: undefined,
  messageList: undefined,
  messageListStart: undefined,
  messageListIsFullHistory: false,
  messageListError: undefined,
  messageListLoading: false,
  messageListRefreshing: false,
  messageListHistoryLoading: false,
  messageSaving: false,
  messageError: undefined,
  threadMetadata: undefined,
  threadMetadataLoading: false,
  threadMetadataError: undefined,
  thread: undefined,
  threadError: undefined,
  threadLoading: false,
  messageDraft: undefined,
  messageDraftError: undefined,
  messageDraftLoading: false,
  messageDraftSaveError: undefined,
  messageDraftSaving: false,
  messageDraftIds: [],
  messageDraftIdsError: undefined,
  messageDraftIdsLoading: false,
  taskThreadsList: [],
  taskThreadsLoading: false,
  taskThreadsRefreshing: false,
  taskThreadsError: undefined,
};

const selectMessaging = (state: IMessagingState) => state
const selectMessageList = createDraftSafeSelector(
  selectMessaging,
  (state) => state.messageList??[]
)
const messagingReducer = createReducer(initialState, {
  [GROUPS.LOAD]: (state, action: PayloadAction<ActivityGroupSearchModel>) => {
    state.groupsSearch = action.payload;
    state.groupsList = [];
    state.groupsRefreshing = false;
    state.groupsLoading = true;
    state.groupsError = undefined;
  },
  [GROUPS.REFRESH]: (state, action: PayloadAction<ActivityGroupSearchModel>) => {
    state.groupsSearch = action.payload;
    state.groupsRefreshing = true;
    state.groupsLoading = false;
    state.groupsError = undefined;
  },
  [GROUPS.ERROR]: (state, action: PayloadAction<string>) => {
    state.groupsRefreshing = false;
    state.groupsLoading = false;
    state.groupsError = action.payload;
  },
  [GROUPS.LOADED]: (state, action: PayloadAction<ActivityGroupModel[]>) => {
    state.groupsList = action.payload;
    state.groupsRefreshing = false;
    state.groupsLoading = false;
    state.groupsError = undefined;
  },
  [GROUPS.THREADS_LOAD]: (state) => {
    state.groupsThreadsList = [];
    state.groupsThreadsError = undefined;
    state.groupsThreadsLoading = true;
  },
  [GROUPS.THREADS_LOADED]: (state, action: PayloadAction<ThreadDetailModel[]>) => {
    state.groupsThreadsList = action.payload;
    state.groupsThreadsError = undefined;
    state.groupsThreadsLoading = false;
  },
  [GROUPS.THREADS_ERROR]: (state, action: PayloadAction<string>) => {
    state.groupsThreadsError = action.payload;
    state.groupsThreadsLoading = false;
  },
  [GROUP_NOTIFICATIONS.LOAD]: (state) => {
    state.groupsNotificationList = [];
    state.groupsNotificationRefreshing = false;
    state.groupsNotificationLoading = true;
    state.groupsNotificationError = undefined;
  },
  [GROUP_NOTIFICATIONS.REFRESH]: (state) => {
    state.groupsNotificationRefreshing = true;
    state.groupsNotificationLoading = false;
    state.groupsNotificationError = undefined;
  },
  [GROUP_NOTIFICATIONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.groupsNotificationRefreshing = false;
    state.groupsNotificationLoading = false;
    state.groupsNotificationError = action.payload;
  },
  [GROUP_NOTIFICATIONS.LOADED]: (state, action: PayloadAction<ActivityGroupModel[]>) => {
    state.groupsNotificationList = action.payload;
    state.groupsNotificationRefreshing = false;
    state.groupsNotificationLoading = false;
    state.groupsNotificationError = undefined;
  },
  [NOTIFICATIONS.LOAD]: (state) => {
    state.notificationsSummary = undefined;
    state.notificationsError = undefined;
    state.notificationsLoading = true;
    state.notificationsRefreshing = false;
  },
  [NOTIFICATIONS.REFRESH]: (state) => {
    state.notificationsError = undefined;
    state.notificationsLoading = false;
    state.notificationsRefreshing = true;
  },
  [NOTIFICATIONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.notificationsError = action.payload;
    state.notificationsLoading = false;
    state.notificationsRefreshing = false;
  },
  [NOTIFICATIONS.LOADED]: (state, action: PayloadAction<InboxSummary>) => {
    state.notificationsSummary = action.payload;
    state.notificationsError = undefined;
    state.notificationsLoading = false;
    state.notificationsRefreshing = false;
  },
  [THREAD.LOAD]: (state) => {
    state.thread = undefined;
    state.threadError = undefined;
    state.threadLoading = false;
    state.threadMetadata = undefined;
    state.threadMetadataError = undefined;
    state.threadMetadataLoading = false;
  },
  [THREAD.ERROR]: (state, action: PayloadAction<string>) => {
    state.threadError = action.payload;
    state.threadLoading = false;
  },
  [THREAD.LOADED]: (state, action: PayloadAction<ThreadDetailModel>) => {
    state.thread = action.payload;
    state.threadError = undefined;
    state.threadLoading = false;
  },
  [THREAD.METADATA_LOAD]: (state) => {
    state.threadMetadata = undefined;
    state.threadMetadataError = undefined;
    state.threadMetadataLoading = true;
  },
  [THREAD.METADATA_ERROR]: (state, action: PayloadAction<string>) => {
    state.threadMetadataError = action.payload;
    state.threadMetadataLoading = false;
  },
  [THREAD.METADATA_LOADED]: (state, action: PayloadAction<ThreadMetadataModel | undefined>) => {
    state.threadMetadata = action.payload;
    state.threadMetadataError = undefined;
    state.threadMetadataLoading = false;
  },
  [MESSAGE.SAVE]: (state) => {
    state.messageSaving = true;
    state.messageError = undefined;
  },
  [MESSAGE.SAVED]: (state) => {
    state.messageSaving = false;
    state.messageError = undefined;
  },
  [MESSAGE.ERROR]: (state, action: PayloadAction<string>) => {
    state.messageSaving = false;
    state.messageError = action.payload;
  },
  [MESSAGE_FEED.LOAD]: (state, action: PayloadAction<ActivityEventSearchModel>) => {
    state.messageSearch = action.payload;
    state.messageList = undefined;
    state.messageListStart = undefined;
    state.messageListIsFullHistory = false;
    state.messageListError = undefined;
    state.messageListLoading = true;
    state.messageListRefreshing = false;
    state.messageListHistoryLoading = false;
  },
  [MESSAGE_FEED.REFRESH]: (state, action: PayloadAction<ActivityEventSearchModel>) => {
    state.messageSearch = action.payload;
    state.messageListError = undefined;
    state.messageListRefreshing = true;
  },
  [MESSAGE_FEED.LOADED]: (state, action: PayloadAction<EventItemViewModel[]>) => {
    state.messageListError = undefined;
    state.messageListRefreshing = false;
    state.messageListLoading = false;
    if (action.payload && hasItems(action.payload)) {
      const feedOldest = action.payload[action.payload.length - 1].dtCreated;
      let currentList = selectMessageList(state)??[];
      let newList: Map<string, EventItemViewModel> = new Map(currentList.map((x) => { return [x.id??'', x]}));
      action.payload.forEach(item => newList.set(item.id??'', item));
      // This approach ensures all new values for existing messages overwrites old values
      state.messageList = [...newList.values()];
      state.messageListStart = feedOldest;
    }
  },
  [MESSAGE_FEED.HISTORY_LOAD]: (state, action: PayloadAction<ActivityEventSearchModel>) => {
    state.messageSearch = action.payload;
    state.messageListError = undefined;
    state.messageListHistoryLoading = true;
  },
  [MESSAGE_FEED.HISTORY_LOADED]: (state, action: PayloadAction<EventItemViewModel[]>) => {
    if (action.payload && hasItems(action.payload)) {
      if (action.payload && hasItems(action.payload)) {
        const historyOldest = action.payload[action.payload.length - 1].dtCreated;
        let currentHistoryList = selectMessageList(state)??[];
        let newHistoryList: Map<string, EventItemViewModel> = new Map(currentHistoryList.map((x) => { return [x.id??'', x]}));
        action.payload.forEach(item => {newHistoryList.set(item.id??'', item)});
        // This approach ensures all new values for existing messages overwrites old values
        state.messageList = [...newHistoryList.values()];
        state.messageListStart = historyOldest;
      }
    } else {
      state.messageListIsFullHistory = true;
    }
    state.messageListRefreshing = false;
    state.messageListLoading = false;
    state.messageListError = undefined;
    state.messageListHistoryLoading = false;
  },
  [MESSAGE_FEED.HISTORY_LOAD_ALL]: (state, action: PayloadAction<ActivityEventSearchModel>) => {
    state.messageSearch = action.payload;
    state.messageListError = undefined;
    state.messageListHistoryLoading = true;
  },
  [MESSAGE_FEED.ERROR]: (state, action: PayloadAction<string>) => {
    state.messageListError = action.payload;
    state.messageListRefreshing = false;
    state.messageListLoading = false;
  },
  [DRAFT.LOAD]: (state) => {
    state.messageDraft = undefined;
    state.messageDraftError = undefined;
    state.messageDraftLoading = true;
  },
  [DRAFT.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.messageDraftError = action.payload;
    state.messageDraftLoading = false;
  },
  [DRAFT.LOADED]: (state, action: PayloadAction<MessageDraftModel>) => {
    state.messageDraft = action.payload;
    state.messageDraftError = undefined;
    state.messageDraftLoading = false;
  },
  [DRAFT.SAVE]: (state) => {
    state.messageDraftSaveError = undefined;
    state.messageDraftSaving = true;
  },
  [DRAFT.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.messageDraftSaveError = action.payload;
    state.messageDraftSaving = false;
  },
  [DRAFT.SAVED]: (state, action: PayloadAction<MessageDraftModel>) => {
    state.messageDraft = action.payload;
    state.messageDraftSaveError = undefined;
    state.messageDraftSaving = false;
  },
  [DRAFT_LIST.LOAD]: (state) => {
    state.messageDraftIds = [];
    state.messageDraftError = undefined;
    state.messageDraftLoading = true;
  },
  [DRAFT_LIST.REFRESH]: (state) => {
    state.messageDraftError = undefined;
  },
  [DRAFT_LIST.LOADED]: (state, action: PayloadAction<IDraftIdentifier[]>) => {
    state.messageDraftIds = action.payload;
    state.messageDraftError = undefined;
    state.messageDraftLoading = false;
  },
  [DRAFT_LIST.LOAD]: (state, action: PayloadAction<string>) => {
    state.messageDraftError = action.payload;
    state.messageDraftLoading = false;
  },
  [TASK_THREAD_LIST.LOAD]: (state) => {
    state.taskThreadsList = [];
    state.taskThreadsLoading = true;
    state.taskThreadsRefreshing = false;
    state.taskThreadsError = undefined;
  },
  [TASK_THREAD_LIST.REFRESH]: (state) => {
    state.taskThreadsLoading = false;
    state.taskThreadsRefreshing = true;
    state.taskThreadsError = undefined;
  },
  [TASK_THREAD_LIST.LOADED]: (state, action: PayloadAction<ThreadTaskList[]>) => {
    state.taskThreadsList = action.payload;
    state.taskThreadsLoading = false;
    state.taskThreadsRefreshing = false;
    state.taskThreadsError = undefined;
  },
  [TASK_THREAD_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.taskThreadsList = [];
    state.taskThreadsLoading = false;
    state.taskThreadsRefreshing = false;
    state.taskThreadsError = action.payload;
  }
});

export default messagingReducer;
