
export const defaultForActivityGroupSearchModel = () => {
  return ActivityGroupSearchModel.fromJS({
    searchText: '',
    pageSize: 50,
    ownerId: 'internal',
    incompleteOnly: false,
    includeArchived: false,
  });
}


export class ActivityGroupSearchModel implements IActivityGroupSearchModel {
  searchText?: string | undefined;
  pageSize?: number | undefined;
  ownerId?: string | undefined;
  incompleteOnly?: boolean | undefined;
  includeArchived?: boolean | undefined;
  fromDate?: Date | undefined;

  constructor(data?: IActivityGroupSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      this.pageSize = Data["pageSize"];
      this.ownerId = Data["ownerId"];
      this.incompleteOnly = Data["incompleteOnly"];
      this.includeArchived = Data["includeArchived"];
      this.fromDate = Data["fromDate"] ? new Date(Data["fromDate"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): ActivityGroupSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new ActivityGroupSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    data["pageSize"] = this.pageSize;
    data["ownerId"] = this.ownerId;
    data["incompleteOnly"] = this.incompleteOnly;
    data["includeArchived"] = this.includeArchived;
    data["fromDate"] = this.fromDate ? this.fromDate.toISOString() : <any>undefined;
    return data;
  }
}

export interface IActivityGroupSearchModel {
  searchText?: string | undefined;
  pageSize?: number | undefined;
  ownerId?: string | undefined;
  incompleteOnly?: boolean | undefined;
  includeArchived?: boolean | undefined;
  fromDate?: Date | undefined;
}