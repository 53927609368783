import {
  ICommonLoadActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {PoolDetailModel} from '../../types/models/PoolDetailModel';
import {CompanyPoolSeasonModel} from '../../types/models/CompanyPoolSeasonModel';

export interface IPoolCompanySeasonsType extends ICommonLoadActionType {
  REFRESH: string;
}

export const COMPANY_POOL_SEASONS: IPoolCompanySeasonsType = {
  ERROR: 'poolCompanySeasonsSearch/error',
  LOAD: 'poolCompanySeasonsSearch/search',
  LOADED: 'poolCompanySeasonsSearch/searchSuccess',
  REFRESH: 'poolCompanySeasonsSearch/refresh'
};

export const COMPANY_POOL_LIST = {
  LOAD: 'poolCompanyList/load',
  LOADED: 'poolCompanyList/loadSuccess',
  LOAD_ERROR: 'poolCompanyList/loadError',
  REFRESH: 'poolCompanyList/refresh',
};

export const poolCompanySeasonsLoad = createAction<string>(COMPANY_POOL_SEASONS.LOAD);
export const poolCompanySeasonsRefresh = createAction<string>(COMPANY_POOL_SEASONS.REFRESH);
export const poolCompanySeasonsLoadSuccess = createAction<CompanyPoolSeasonModel[]>(COMPANY_POOL_SEASONS.LOADED);
export const poolCompanySeasonsLoadError = createAction<string>(COMPANY_POOL_SEASONS.ERROR);

export const poolCompanyListLoad = createAction<string[]>(COMPANY_POOL_LIST.LOAD);
export const poolCompanyListRefresh = createAction<string[]>(COMPANY_POOL_LIST.REFRESH);
export const poolCompanyListLoadSuccess = createAction<PoolDetailModel[]>(COMPANY_POOL_LIST.LOADED);
export const poolCompanyListLoadError = createAction<string>(COMPANY_POOL_LIST.LOAD_ERROR);
