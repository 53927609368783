import {PoolStudentViewPoolStudentStatus} from './Enums';
import {PoolStudentStepView} from './PoolStudentStepView';
import {PlacementDetailModel} from './PlacementDetailModel';
import {PoolStudentContactPersonView} from './PoolStudentContactPersonView';
import {EmployerExperienceModel, HonourAndAwardsModel} from '../requests/StudentProfileEditModel';

export class PoolStudentView implements IPoolStudentView {
  poolId?: string | undefined;
  personId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  additionalInfo?: string | undefined;
  placement?: PlacementDetailModel[] | undefined;
  sentByUserId?: string | undefined;
  poolStudentStatus?: string | undefined;
  dateFirstSent?: Date | undefined;
  dateLastSent?: Date | undefined;
  poolStudentContactPeople?: PoolStudentContactPersonView[] | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  publicProfile?: string | undefined;

  constructor(data?: IPoolStudentView) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.poolId = Data["poolId"];
      this.personId = Data["personId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.additionalInfo = Data["additionalInfo"];
      if (Array.isArray(Data["placement"])) {
        this.placement = [] as any;
        for (let item of Data["placement"])
          this.placement!.push(PlacementDetailModel.fromJS(item));
      }
      this.sentByUserId = Data["sentByUserId"];
      this.poolStudentStatus = Data["poolStudentStatus"];
      this.dateFirstSent = Data["dateFirstSent"] ? new Date(Data["dateFirstSent"].toString()) : <any>undefined;
      this.dateLastSent = Data["dateLastSent"] ? new Date(Data["dateLastSent"].toString()) : <any>undefined;
      if (Array.isArray(Data["poolStudentContactPeople"])) {
        this.poolStudentContactPeople = [] as any;
        for (let item of Data["poolStudentContactPeople"])
          this.poolStudentContactPeople!.push(PoolStudentContactPersonView.fromJS(item));
      }
      if (Array.isArray(Data["employerExperiences"])) {
        this.employerExperiences = [] as any;
        for (let item of Data["employerExperiences"])
          this.employerExperiences!.push(EmployerExperienceModel.fromJS(item));
      }
      if (Array.isArray(Data["employerHonourAwards"])) {
        this.employerHonourAwards = [] as any;
        for (let item of Data["employerHonourAwards"])
          this.employerHonourAwards!.push(HonourAndAwardsModel.fromJS(item));
      }
      this.publicProfile = Data["publicProfile"];
    }
  }

  static fromJS(data: any): PoolStudentView {
    data = typeof data === 'object' ? data : {};
    let result = new PoolStudentView();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["poolId"] = this.poolId;
    data["personId"] = this.personId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["additionalInfo"] = this.additionalInfo;
    if (Array.isArray(this.placement)) {
      data["placement"] = [];
      for (let item of this.placement)
        data["placement"].push(item.toJSON());
    }
    data["sentByUserId"] = this.sentByUserId;
    data["poolStudentStatus"] = this.poolStudentStatus;
    data["dateFirstSent"] = this.dateFirstSent ? this.dateFirstSent.toISOString() : <any>undefined;
    data["dateLastSent"] = this.dateLastSent ? this.dateLastSent.toISOString() : <any>undefined;
    if (Array.isArray(this.poolStudentContactPeople)) {
      data["poolStudentContactPeople"] = [];
      for (let item of this.poolStudentContactPeople)
        data["poolStudentContactPeople"].push(item.toJSON());
    }
    if (Array.isArray(this.employerExperiences)) {
      data["employerExperiences"] = [];
      for (let item of this.employerExperiences)
        data["employerExperiences"].push(item.toJSON());
    }
    if (Array.isArray(this.employerHonourAwards)) {
      data["employerHonourAwards"] = [];
      for (let item of this.employerHonourAwards)
        data["employerHonourAwards"].push(item.toJSON());
    }
    data["publicProfile"] = this.publicProfile;
    return data;
  }
}

export interface IPoolStudentView {
  poolId?: string | undefined;
  personId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  additionalInfo?: string | undefined;
  placement?: PlacementDetailModel[] | undefined;
  sentByUserId?: string | undefined;
  poolStudentStatus?: string | undefined;
  dateFirstSent?: Date | undefined;
  dateLastSent?: Date | undefined;
  poolStudentContactPeople?: PoolStudentContactPersonView[] | undefined;
  employerExperiences?: EmployerExperienceModel[] | undefined;
  employerHonourAwards?: HonourAndAwardsModel[] | undefined;
  publicProfile?: string | undefined;
}