export class GenderPronounsConfigModel implements IGenderPronounsConfigModel {
  identifier?: string | undefined;
  subjective?: string | undefined;
  objective?: string | undefined;
  possessive?: string | undefined;
  reflexive?: string | undefined;

  constructor(data?: IGenderPronounsConfigModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.identifier = Data["identifier"];
      this.subjective = Data["subjective"];
      this.objective = Data["objective"];
      this.possessive = Data["possessive"];
      this.reflexive = Data["reflexive"];
    }
  }

  static fromJS(data: any): GenderPronounsConfigModel {
    data = typeof data === 'object' ? data : {};
    let result = new GenderPronounsConfigModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["identifier"] = this.identifier;
    data["subjective"] = this.subjective;
    data["objective"] = this.objective;
    data["possessive"] = this.possessive;
    data["reflexive"] = this.reflexive;
    return data;
  }
}

export interface IGenderPronounsConfigModel {
  identifier?: string | undefined;
  subjective?: string | undefined;
  objective?: string | undefined;
  possessive?: string | undefined;
  reflexive?: string | undefined;
}