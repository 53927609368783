import {ICommonCacheActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {PoolSuggestModel} from '../../types/views/PoolSuggestModel';

export const POOL_CACHE: ICommonCacheActionType = {
  ADD: 'poolCache/add',
  UPDATE: 'poolCache/updated',
  MERGE_ITEMS: 'poolCache/merge',
  ERROR: 'poolCache/error',
  REMOVE: 'poolCache/remove'
};

export const poolCacheAdd = createAction<string[]>(POOL_CACHE.ADD);
export const poolCacheUpdate = createAction<string[]>(POOL_CACHE.UPDATE);
export const poolCacheMerge = createAction<PoolSuggestModel[]>(POOL_CACHE.MERGE_ITEMS);
export const poolCacheError = createAction<string>(POOL_CACHE.ERROR);
export const poolCacheRemove = createAction<string>(POOL_CACHE.REMOVE);