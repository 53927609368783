export interface WorkShadowPaths {
  WorkShadowEdit: string,
  WorkShadowPromote: string,
  WorkShadowRollback: string,
  WorkShadowCreate: string,
  WorkShadowSearch: string,
}

export const workShadowPaths: WorkShadowPaths = {
  WorkShadowEdit: '/advisor/hsp/edit/:id',
  WorkShadowPromote: '/advisor/hsp/promote/:id',
  WorkShadowCreate: '/advisor/hsp/add',
  WorkShadowSearch: '/advisor/hsp/search',
  WorkShadowRollback: '/advisor/hsp/rollback/:id'
};