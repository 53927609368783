import {alpha} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material';
import {Fonts, ThemeStyleRadius} from '../../../../shared/constants/AppEnums';
import {grey} from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) => ({
  appToolbar: {
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 56,
    [theme.breakpoints.up('xs')]: {
      minHeight: 70,
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    width: 30,
    height: 30,
  },
  breadcrumbItem: {
    color: grey[600],
    fontSize: '0.8rem',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
      color: grey[600],
      fontWeight: Fonts.BOLD,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      }
    },
  },
  breadcrumbIcon: {
    width: 10,
    height: 10,
    margin: '0px 4px 0px 5px',
    paddingTop: '2px',
    color: grey[600]
  },
  breadcrumbHomeIcon: {
    width: 18,
    height: 18,
    color: grey[600],
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: ThemeStyleRadius.MODERN,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    minHeight: 56,
    display: 'none',
    marginRight: 60,
    [theme.breakpoints.up('xs')]: {
      minHeight: 70,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexDirection:'column',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  appBar: {
    width: '100%',
    boxShadow: '0px 0px 0px 0px',
    transition: 'all 0.5s ease',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 19rem)',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100vw - 21rem)',
    },
  },
  menuItemRoot: {
    padding: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  logoRoot: {
    verticalAlign: 'middle',
    display: 'inline-block',
    height: 30,
  },
  menuIconRoot: {
    width: '2.5rem',
    height: '2.5rem',
  },

}));
export default useStyles;
