import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  COMPANY_INTERNSHIP_SCORECARD, companyInternshipScorecardSearchError, companyInternshipScorecardSearchSuccess,
  ENGAGEMENT_SCORECARD, engagementScorecardSearchError, engagementScorecardSearchSuccess,
  INTERNSHIP_SCORECARD, internshipScorecardSearchError, internshipScorecardSearchSuccess,
  RECRUITMENT_SCORECARD,
  HSP_ENGAGEMENT_SCORECARD, hspEngagementScorecardSearchError, hspEngagementScorecardSearchSuccess,
  recruitmentScorecardSearchError,
  recruitmentScorecardSearchSuccess,
  SCORECARD_PRESET, scorecardPresetLoadError, scorecardPresetLoadSuccess, scorecardPresetRefresh,
  scorecardPresetSaveError,
  scorecardPresetSaveSuccess,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {ScorecardParamsModel} from '../../types/requests/ScorecardParamsModel';
import {ISaveScorecardPresetsModel, SaveScorecardPresetsModel} from '../../types/requests/SaveScorecardPresetsModel';
import {ScorecardPresetModel} from '../../types/models/ScorecardPresetModel';
import {IRetrieveScorecardPresetsModel} from '../../types/requests/RetrieveScorecardPresetsModel';


function* LoadScorecardPresets(presetType: IRetrieveScorecardPresetsModel) {
  console.log('SAGA - scorecardSaga - LoadScorecardPresets');
  const presetPath = `${apiConfig.coreReportingPath}/scorecard/presets`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: presetPath,
      data: presetType
    });
    const presets: ScorecardPresetModel[] = res.data.map((x: any) => ScorecardPresetModel.fromJS(x));
    yield put(scorecardPresetLoadSuccess(presets));
  } catch (error) {
    yield put(scorecardPresetLoadError(error));
  }
}

function* SaveScorecardPreset(submission: ISaveScorecardPresetsModel) {
  console.log('SAGA - scorecardSaga - SaveScorecardPreset');
  const presetPath = `${apiConfig.coreReportingPath}/scorecard/presets`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: presetPath,
      data: submission
    });
    yield put(scorecardPresetSaveSuccess());
    yield put(scorecardPresetRefresh());
  } catch (error) {
    yield put(scorecardPresetSaveError(error));
  }
}

function* DeleteScorecardPreset(submission: ISaveScorecardPresetsModel) {
  console.log('SAGA - scorecardSaga - DeleteScorecardPreset');
  const presetPath = `${apiConfig.coreReportingPath}/scorecard/presets`;
  try {
    yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'DELETE',
      url: presetPath,
      data: submission
    });
    yield put(scorecardPresetRefresh());
  } catch (error) {

  }
}

function* SearchRecruitmentScorecard({ page, sort, searchParams }: ISearchRequest<ScorecardParamsModel>) {
  console.log('SAGA - scorecardSaga - SearchRecruitmentScorecard');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreReportingPath}/scorecard/recruitment?page=${page}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(recruitmentScorecardSearchSuccess(results));
  } catch (error) {
    yield put(recruitmentScorecardSearchError(error));
  }
}

function* SearchEngagementScorecard({ page, sort, searchParams }: ISearchRequest<ScorecardParamsModel>) {
  console.log('SAGA - scorecardSaga - SearchEngagementScorecard');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreReportingPath}/scorecard/engagement?page=${page}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(engagementScorecardSearchSuccess(results));
  } catch (error) {
    yield put(engagementScorecardSearchError(error));
  }
}

function* SearchHSPEngagementScorecard({ page, sort, searchParams }: ISearchRequest<ScorecardParamsModel>) {
  console.log('SAGA - scorecardSaga - SearchHSPEngagementScorecard');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreReportingPath}/scorecard/hsp-engagement?page=${page}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(hspEngagementScorecardSearchSuccess(results));
  } catch (error) {
    yield put(hspEngagementScorecardSearchError(error));
  }
}

function* SearchInternshipScorecard({ page, sort, searchParams }: ISearchRequest<ScorecardParamsModel>) {
  console.log('SAGA - scorecardSaga - SearchInternshipScorecard');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreReportingPath}/scorecard/internship?page=${page}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(internshipScorecardSearchSuccess(results));
  } catch (error) {
    yield put(internshipScorecardSearchError(error));
  }
}

function* SearchCompanyInternshipScorecard({ page, sort, searchParams }: ISearchRequest<ScorecardParamsModel>) {
  console.log('SAGA - scorecardSaga - SearchCompanyInternshipScorecard');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreReportingPath}/scorecard/company-internship?page=${page}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(companyInternshipScorecardSearchSuccess(results));
  } catch (error) {
    yield put(companyInternshipScorecardSearchError(error));
  }
}

export function* watchScorecardPresetSave() {
  yield takeLatest(SCORECARD_PRESET.SAVE, ({ payload }: PayloadAction<ISaveScorecardPresetsModel>) => SaveScorecardPreset(payload));
}

export function* watchScorecardPresetLoad() {
  yield takeLatest(SCORECARD_PRESET.LOAD, ({ payload }: PayloadAction<IRetrieveScorecardPresetsModel>) => LoadScorecardPresets(payload));
}

export function* watchScorecardPresetRefresh() {
  yield takeLatest(SCORECARD_PRESET.REFRESH, ({ payload }: PayloadAction<IRetrieveScorecardPresetsModel>) => LoadScorecardPresets(payload));
}

export function* watchScorecardPresetDelete() {
  yield takeLatest(SCORECARD_PRESET.DELETE, ({ payload }: PayloadAction<SaveScorecardPresetsModel>) => DeleteScorecardPreset(payload));
}

export function* watchRecruitmentScorecardSearch() {
  yield takeLatest(RECRUITMENT_SCORECARD.LOAD, ({payload}: PayloadAction<ISearchRequest<ScorecardParamsModel>>) => SearchRecruitmentScorecard(payload))
}

export function* watchEngagementScorecardSearch() {
  yield takeLatest(ENGAGEMENT_SCORECARD.LOAD, ({payload}: PayloadAction<ISearchRequest<ScorecardParamsModel>>) => SearchEngagementScorecard(payload))
}

export function* watchHSPEngagementScorecardSearch() {
  yield takeLatest(HSP_ENGAGEMENT_SCORECARD.LOAD, ({payload}: PayloadAction<ISearchRequest<ScorecardParamsModel>>) => SearchHSPEngagementScorecard(payload))
}

export function* watchInternshipScorecardSearch() {
  yield takeLatest(INTERNSHIP_SCORECARD.LOAD, ({payload}: PayloadAction<ISearchRequest<ScorecardParamsModel>>) => SearchInternshipScorecard(payload))
}

export function* watchCompanyInternshipScorecardSearch() {
  yield takeLatest(COMPANY_INTERNSHIP_SCORECARD.LOAD, ({payload}: PayloadAction<ISearchRequest<ScorecardParamsModel>>) => SearchCompanyInternshipScorecard(payload))
}

export default function* scorecardSaga() {
  yield all ([
    call(watchScorecardPresetSave),
    call(watchScorecardPresetLoad),
    call(watchScorecardPresetDelete),
    call(watchRecruitmentScorecardSearch),
    call(watchEngagementScorecardSearch),
    call(watchHSPEngagementScorecardSearch),
    call(watchInternshipScorecardSearch),
    call(watchCompanyInternshipScorecardSearch),
  ])
}
