export class AddressSearch implements IAddressSearch {
  /** Address 1 */
  line1?: string | undefined;
  /** Address 2 */
  line2?: string | undefined;
  /** City/Suburb */
  suburb?: string | undefined;
  /** Postcode/Zip */
  postcode?: string | undefined;
  /** State/County */
  state?: string | undefined;
  countryCode?: string | undefined;
  locationDescription?: string | undefined;

  constructor(data?: IAddressSearch) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.line1 = Data["line1"];
      this.line2 = Data["line2"];
      this.suburb = Data["suburb"];
      this.postcode = Data["postcode"];
      this.state = Data["state"];
      this.countryCode = Data["countryCode"];
      this.locationDescription = Data["locationDescription"];
    }
  }

  static fromJS(data: any): AddressSearch {
    data = typeof data === 'object' ? data : {};
    let result = new AddressSearch();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["line1"] = this.line1;
    data["line2"] = this.line2;
    data["suburb"] = this.suburb;
    data["postcode"] = this.postcode;
    data["state"] = this.state;
    data["countryCode"] = this.countryCode;
    data["locationDescription"] = this.locationDescription;
    return data;
  }
}

export interface IAddressSearch {
  /** Address 1 */
  line1?: string | undefined;
  /** Address 2 */
  line2?: string | undefined;
  /** City/Suburb */
  suburb?: string | undefined;
  /** Postcode/Zip */
  postcode?: string | undefined;
  /** State/County */
  state?: string | undefined;
  countryCode?: string | undefined;
  locationDescription?: string | undefined;
}