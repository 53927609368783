import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import IconButton from '@mui/material/IconButton';
import AddContactDialog from "../CompanyActions/AddContactDialog";
import {contactSave} from "../../../redux/contacts/Actions";
import {useAppDispatch} from "../../../@template/utility/AppHooks";
import {ContactPersonEditModel} from "../../../types/requests/ContactPersonEditModel";
import {selectContactSaveError, selectContactSaving} from "../../../redux/contacts/Selectors";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {MessageView} from "../../../@template";
import {personCacheUpdate} from "../../../redux/peopleCache/Actions";

interface IAddContactDialogWithIcon {
  companyId: string;
  personId?: string | undefined;
  payload: ContactPersonEditModel;
  onFinishedSave?(): void | undefined;
}

const AddContactDialogWithIcon = ({ companyId, payload, personId, onFinishedSave }: IAddContactDialogWithIcon) => {
  const dispatch = useAppDispatch();
  const contactSaving = useSelector(selectContactSaving);
  const contactSaveError = useSelector(selectContactSaveError);

  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [actionError, setActionError] = useState<string | undefined>(undefined);
  const [actionSuccess, setActionSuccess] = useState<string | undefined>(undefined);

  const saving = useSelector(selectContactSaving);

  const handleAddContactSubmit = (submission: ContactPersonEditModel) => {
    setIsSaving(true);
    dispatch(contactSave({
      submission: submission,
      id: personId
    }));
  }

  const clearMessages = () => {
    setActionError(undefined);
    setActionSuccess(undefined);
  }

  const updatePersonListItem = () => {
    if (personId) dispatch(personCacheUpdate([personId]));
  }

  useEffect(() => {
    if (!contactSaving && isSaving) {
      if (!contactSaveError) setActionSuccess("Contact successfully added to company.");
      if (contactSaveError) setActionError("Saving new contact failed. Check email does not already exist or contact support if problem persists.");
      // if (personId) dispatch(personCacheUpdate([personId]));
      setIsSaving(false);
      setOpen(false);
      if (personId) dispatch(personCacheUpdate([personId]));
      if (!contactSaveError && onFinishedSave) onFinishedSave();
    }
  }, [contactSaving, contactSaveError, isSaving]);

  const showError = () => {
    return <MessageView title="Company action error" message={actionError??''} variant="error" onClose={clearMessages} />;
  }

  const showSuccess = () => {
    return <MessageView title="Company action successful" message={actionSuccess??''} variant="success" onClose={clearMessages} />;
  }

  return <React.Fragment>
    {actionError && showError()}
    {actionSuccess && showSuccess()}
    <AddContactDialog
      companyId={companyId}
      isOpen={open}
      isSubmitting={saving}
      onClose={() => setOpen(false)}
      onSubmit={handleAddContactSubmit}
      payload={payload}
    />
    <IconButton
      size="small"
      color="inherit"
      onClick={() => setOpen(true)}
    >
      <AddCircleOutlineIcon fontSize="small" />
    </IconButton>
  </React.Fragment>;
}

export default AddContactDialogWithIcon;