export class StudentAchievementModel implements IStudentAchievementModel {
  studentAchievementId?: string | undefined;
  studentId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  dateAchieved?: string | undefined;
  createdByPersonId?: string | undefined;
  updatedByPersonId?: string | undefined;
  seasonSemester?: number | undefined;
  seasonYear?: number | undefined;
  achievement?: string | undefined;
  achievedCount?: number | undefined;
  notes?: string | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByPersonId?: string | undefined;

  constructor(data?: IStudentAchievementModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.studentAchievementId = Data["studentAchievementId"];
      this.studentId = Data["studentId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.dateAchieved = Data["dateAchieved"];
      this.createdByPersonId = Data["createdByPersonId"];
      this.updatedByPersonId = Data["updatedByPersonId"];
      this.seasonSemester = Data["seasonSemester"];
      this.seasonYear = Data["seasonYear"];
      this.achievement = Data["achievement"];
      this.achievedCount = Data["achievedCount"];
      this.notes = Data["notes"];
      this.isDeleted = Data["isDeleted"];
      this.dateDeleted = Data["dateDeleted"] ? new Date(Data["dateDeleted"].toString()) : <any>undefined;
      this.deletedByPersonId = Data["deletedByPersonId"];
    }
  }

  static fromJS(data: any): StudentAchievementModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentAchievementModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["studentAchievementId"] = this.studentAchievementId;
    data["studentId"] = this.studentId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["dateAchieved"] = this.dateAchieved;
    data["createdByPersonId"] = this.createdByPersonId;
    data["updatedByPersonId"] = this.updatedByPersonId;
    data["seasonSemester"] = this.seasonSemester;
    data["seasonYear"] = this.seasonYear;
    data["achievement"] = this.achievement;
    data["achievedCount"] = this.achievedCount;
    data["notes"] = this.notes;
    data["isDeleted"] = this.isDeleted;
    data["dateDeleted"] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
    data["deletedByPersonId"] = this.deletedByPersonId;
    return data;
  }
}

export interface IStudentAchievementModel {
  studentAchievementId?: string | undefined;
  studentId?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  dateAchieved?: string | undefined;
  createdByPersonId?: string | undefined;
  updatedByPersonId?: string | undefined;
  seasonSemester?: number | undefined;
  seasonYear?: number | undefined;
  achievement?: string | undefined;
  achievedCount?: number | undefined;
  notes?: string | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByPersonId?: string | undefined;
}
