import {AppState} from '../store';
import {hasItems} from '../../@template/helpers/arrays';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IPlacementsCacheState} from './Reducer';
import {HSP_PLACEMENT_CACHE, hspPlacementCacheError, hspPlacementCacheMerge, hspPlacementCacheUpdate} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {HSPPlacementListItem} from '../../types/views/HSPPlacementListItem';
import {HSPPlacementIdList} from '../../types/requests/HSPPlacementIdList';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const placementsCacheState = (state: AppState) => state.placementsCache;

export function* HSPLoadPlacementCacheItems(idList: string[]) {
  console.log('SAGA - placementCacheSaga - LoadPlacementCacheItems', idList);
  try {
    if (hasItems(idList)) {
      let submissionData = new HSPPlacementIdList();
      submissionData.idList = idList;
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.corePlacementsPath}/list`,
        data: submissionData
      });
      const placements: HSPPlacementListItem[] = res.data.map((x: any) => HSPPlacementListItem.fromJS(x));
      yield put(hspPlacementCacheMerge(placements));
    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(hspPlacementCacheError(err));
  }
}

export function* HSPAddPlacementCacheItems() {
  console.log('SAGA - placementCacheSaga - AddPlacementCacheItems');
  yield call(delay, 200);
  const currentPlacementCache: IPlacementsCacheState = yield select(placementsCacheState);
  const currentPendingList = currentPlacementCache.hspPlacementPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    console.log('SAGA - peopleCacheSaga - AddPlacementCacheItems Load', currentPendingList);
    yield put(hspPlacementCacheUpdate([...currentPendingList]));
  }
}

export function* watchHSPLoadPlacementCacheItems(){
  yield takeEvery(HSP_PLACEMENT_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => HSPLoadPlacementCacheItems(payload));
}

export function* watchHSPAddPlacementCacheItems(){
  yield takeEvery(HSP_PLACEMENT_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => HSPAddPlacementCacheItems());
}

export default function* hspPlacementCacheSaga() {
  yield all([
    call(watchHSPLoadPlacementCacheItems),
    call(watchHSPAddPlacementCacheItems),
  ])
}
