import {all, call, put, takeLatest} from 'redux-saga/effects';
import { HSPOpportunityDetailModel } from 'types/models/HSPOpportunityDetailModel';
import {
  HSP_COMPANY_OPPORTUNITY_SEARCH,
  HSP_COMPANY_OPPORTUNITY_SEASONS,
  HSP_OPPORTUNITY,
  HSP_OPPORTUNITY_SEARCH,
  HSP_OPPORTUNITY_STUDENT_PROFILES,
  HSP_OPPORTUNITY_SEASONS,
  hspOpportunityCompanySearchLoadError,
  hspOpportunityCompanySearchLoadSuccess,
  hspOpportunityCompanySeasonsLoadError,
  hspOpportunityCompanySeasonsLoadSuccess,
  hspOpportunityLoadError,
  hspOpportunityLoadSuccess,
  hspOpportunitySaveError,
  hspOpportunitySaveSuccess,
  hspOpportunitySearchLoadError,
  hspOpportunitySearchLoadSuccess,
  hspOpportunityStudentProfilesLoadError,
  hspOpportunityStudentProfilesLoadSuccess,
  hspOpportunityStudentSeasonsLoadSuccess,
  hspOpportunityStudentSeasonsLoadError,
  HSP_CONTACT_OPPORTUNITY_SEASONS,
  hspOpportunityContactSeasonsLoadSuccess,
  hspOpportunityContactSeasonsLoadError,
  hspOpportunityLocalitySearchLoadSuccess,
  hspOpportunityLocalitySearchLoadError,
  HSP_OPPORTUNITY_LOCALITY_SEARCH,
  hspCurrentOpportunityLoadSuccess,
  hspCurrentOpportunityLoadError,
  HSP_CURRENT_OPPORTUNITY,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PersonModel} from '../../types/models/PersonModel';
import {ICommonSavePayload} from '../Common.actions';
import { HSPOpportunityEditDetailModel} from '../../types/requests/HSPOpportunityEditDetailModel';
import { HSPOpportunitySearchParamsModel } from 'types/requests/HSPOpportunitySearchParamsModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {SeasonModel} from '../../types/models/SeasonModel';
import {HSPOpportunitySearchPaged} from '../../types/models/HSPOpportunitySearchPaged';
import {PayloadAction} from '@reduxjs/toolkit';
import {HSPOpportunitySummaryModel} from '../../types/models/HSPOpportunitySummaryModel';
import {HSPOpportunityBySeasonRequest} from '../../types/requests/HSPOpportunityBySeasonRequest';
import {HSPOpportunityStudentListView} from '../../types/views/HSPOpportunityStudentListView';
import {HSPOpportunitiesSearchPaged} from '../../types/views/HSPOpportunitiesSearchPaged';
import {PoolSearchParamsModel} from '../../types/requests/PoolSearchParamsModel';
import {PoolSearchPaged} from '../../types/views/PoolSearchPaged';
import {HSPLocalOpportunitySearchItem} from '../../types/views/HSPLocalOpportunitySearchItem';

function* HSPOpportunityLoad(opportunityId: string) {
  console.log('SAGA - hspOpportunitySaga - OpportunityLoad');
  console.log('HSPOpportunityLoad!!!!');
  try {
    const res = yield authApi.get(`${apiConfig.coreOpportunitiesPath}/${opportunityId}`);
    const opportunity: HSPOpportunityStudentListView = HSPOpportunityStudentListView.fromJS(res.data);
    yield put(hspOpportunityLoadSuccess(opportunity));
  } catch (err) {
    yield put(hspOpportunityLoadError(err));
  }
}

function* HSPOpportunityLoadBySeason(request: HSPOpportunityBySeasonRequest) {
  console.log('SAGA - hspOpportunitySaga - OpportunityLoadBySeason');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreHSPOpportunitiesPath}/by-season`,
      data: request
    });
    const opportunity: HSPOpportunityStudentListView | undefined = res.data && res.data['hspOpportunity'] ? HSPOpportunityStudentListView.fromJS(res.data) : undefined;
    yield put(hspOpportunityLoadSuccess(opportunity));
  } catch (err) {
    yield put(hspOpportunityLoadError(err));
  }
}

function* HSPCurrentOpportunityLoad(request: HSPOpportunityBySeasonRequest) {
  console.log('SAGA - hspOpportunitySaga - CurrentOpportunityLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreHSPOpportunitiesPath}/by-season`,
      data: request
    });
    const opportunity: HSPOpportunityStudentListView | undefined = res.data && res.data['hspOpportunity'] ? HSPOpportunityStudentListView.fromJS(res.data) : undefined;
    yield put(hspCurrentOpportunityLoadSuccess(opportunity));
  } catch (err) {
    yield put(hspCurrentOpportunityLoadError(err));
  }
}

function* HSPOpportunityStudentProfilesLoad(studentIds: string[]) {
  // Student request here, but have it in opportunities to ensure redux is isolated
  // can apply elsewhere for other use cases, but this is for opportunities on expectation
  // we will need to do more with what we send to contacts
  console.log('SAGA - hspOpportunitySaga - OpportunityStudentProfilesLoad');
  try {
    const submission = { idList: studentIds };
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreWorkShadowPath}/list`,
      data: submission
    });
    const result: PersonModel[] = res.data.map((x: any) => PersonModel.fromJS(x));
    yield put(hspOpportunityStudentProfilesLoadSuccess(result));
  } catch (err) {
    yield put(hspOpportunityStudentProfilesLoadError(err));
  }
}

function* HSPOpportunitySave({submission, id}: ICommonSavePayload<HSPOpportunityEditDetailModel>) {
  console.log('SAGA - hspOpportunitySaga - OpportunitySave');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: id ? `${apiConfig.coreHSPOpportunitiesPath}/${id}` : apiConfig.coreOpportunitiesPath,
      data: submission
    });
    const opportunity: HSPOpportunityDetailModel = HSPOpportunityDetailModel.fromJS(res.data);
    yield put(hspOpportunitySaveSuccess(opportunity));
  } catch (err) {
    yield put(hspOpportunitySaveError(err));
  }
}

export function* HSPOpportunitiesSearch({ searchParams, page, sort}: ISearchRequest<HSPOpportunitySearchParamsModel>) {
  console.log('SAGA - hspOpportunitySaga - OpportunitiesSearch');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url:`${apiConfig.coreHSPOpportunitiesPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const result: HSPOpportunitiesSearchPaged = HSPOpportunitiesSearchPaged.fromJS(res.data);
    yield put(hspOpportunitySearchLoadSuccess(result));
  } catch (err) {
    yield put(hspOpportunitySearchLoadError(err));
  }
}

function* HSPOpportunitiesLocalitySearch({ searchParams, page, sort}: ISearchRequest<HSPOpportunitySearchParamsModel>) {
  console.log('SAGA - hspPoolSaga - OpportunitiesLocalitySearch');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url:`${apiConfig.coreHSPOpportunitiesPath}/search/local?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const result: HSPLocalOpportunitySearchItem[] = res.data.map((x: any) => HSPLocalOpportunitySearchItem.fromJS(x));
    yield put(hspOpportunityLocalitySearchLoadSuccess(result??[]));
  } catch (err) {
    yield put(hspOpportunityLocalitySearchLoadError(err));
  }
}

function* HSPCompanyOpportunitySearch({ searchParams, page, sort}: ISearchRequest<HSPOpportunitySearchParamsModel>) {
  console.log('SAGA - hspOpportunitySaga - CompanyOpportunitySearch');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url:`${apiConfig.coreHSPOpportunitiesPath}/search?page=${page}&sort=${sort}&page_size=100`,
      data: searchParams
    });
    const result: HSPOpportunitySearchPaged = HSPOpportunitySearchPaged.fromJS(res.data);
    yield put(hspOpportunityCompanySearchLoadSuccess(result?.result??[]));
  } catch (err) {
    yield put(hspOpportunityCompanySearchLoadError(err));
  }
}

function* HSPOpportunitySeasonsLoad(studentId: string) {
  console.log('SAGA - hspOpportunitySaga - HSPOpportunitySeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreWorkShadowPath}/${studentId}/opportunity-seasons`,
    });
    const result: HSPOpportunityDetailModel[] = res.data.map((x: any) => HSPOpportunityDetailModel.fromJS(x));
    yield put(hspOpportunityStudentSeasonsLoadSuccess(result));
  } catch (err) {
    yield put(hspOpportunityStudentSeasonsLoadError(err));
  }
}

function* HSPCompanyOpportunitySeasonsLoad(companyId: string) {
  console.log('SAGA - hspOpportunitySaga - CompanyOpportunitySeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreCompaniesPath}/${companyId}/hsp-opportunity-seasons`,
    });
    const result: SeasonModel[] = res.data.map((x: any) => HSPOpportunitySummaryModel.fromJS(x));
    yield put(hspOpportunityCompanySeasonsLoadSuccess(result));
  } catch (err) {
    yield put(hspOpportunityCompanySeasonsLoadError(err));
  }
}

function* HSPContactOpportunitySeasonsLoad(contactId: string) {
  console.log('SAGA - hspOpportunitySaga - ContactOpportunitySeasonsLoad');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url:`${apiConfig.coreContactPath}/${contactId}/opportunity-seasons`,
    });
    const result: SeasonModel[] = res.data.map((x: any) => HSPOpportunitySummaryModel.fromJS(x));
    yield put(hspOpportunityContactSeasonsLoadSuccess(result));
  } catch (err) {
    yield put(hspOpportunityContactSeasonsLoadError(err));
  }
}

export function* watchHSPOpportunityLoad() {
  yield takeLatest(HSP_OPPORTUNITY.LOAD, ({payload}: PayloadAction<string>) => HSPOpportunityLoad(payload))
}

export function* watchHSPOpportunityLoadBySeason() {
  yield takeLatest(HSP_OPPORTUNITY.LOAD_BY_SEASON, ({payload}: PayloadAction<HSPOpportunityBySeasonRequest>) => HSPOpportunityLoadBySeason(payload))
}

export function* watchHSPOpportunityRefresh() {
  yield takeLatest(HSP_OPPORTUNITY.REFRESH, ({payload}: PayloadAction<string>) => HSPOpportunityLoad(payload))
}

export function* watchHSPOpportunityRefreshBySeason() {
  yield takeLatest(HSP_OPPORTUNITY.REFRESH_BY_SEASON, ({payload}: PayloadAction<HSPOpportunityBySeasonRequest>) => HSPOpportunityLoadBySeason(payload))
}

export function* watchHSPOpportunityStudentProfilesLoad() {
  yield takeLatest(HSP_OPPORTUNITY_STUDENT_PROFILES.LOAD, ({payload}: PayloadAction<string[]>) => HSPOpportunityStudentProfilesLoad(payload))
}

export function* watchHSPOpportunitySave() {
  yield takeLatest(HSP_OPPORTUNITY.SAVE, ({payload}: PayloadAction<ICommonSavePayload<HSPOpportunityEditDetailModel>>) => HSPOpportunitySave(payload))
}

export function* watcHSPOpportunitySearchLoad() {
  yield takeLatest(HSP_OPPORTUNITY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => HSPOpportunitiesSearch(payload))
}

export function* watchHSPOpportunitySearchRefresh() {
  yield takeLatest(HSP_OPPORTUNITY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => HSPOpportunitiesSearch(payload))
}

export function* watchHSPOpportunityLocalitySearchLoad() {
  yield takeLatest(HSP_OPPORTUNITY_LOCALITY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => HSPOpportunitiesLocalitySearch(payload))
}

export function* watchHSPOpportunityLocalitySearchRefresh() {
  yield takeLatest(HSP_OPPORTUNITY_LOCALITY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => HSPOpportunitiesLocalitySearch(payload))
}

export function* watchHSPOpportunityCompanySearchLoad() {
  yield takeLatest(HSP_COMPANY_OPPORTUNITY_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => HSPCompanyOpportunitySearch(payload))
}

export function* watchHSPOpportunityCompanySearchRefresh() {
  yield takeLatest(HSP_COMPANY_OPPORTUNITY_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => HSPCompanyOpportunitySearch(payload))
}

export function* watchHSPOpportunityStudentSeasonsLoad() {
  yield takeLatest(HSP_OPPORTUNITY_SEASONS.LOAD, ({payload}: PayloadAction<string>) => HSPOpportunitySeasonsLoad(payload))
}

export function* watchHSPOpportunityCompanySeasonsLoad() {
  yield takeLatest(HSP_COMPANY_OPPORTUNITY_SEASONS.LOAD, ({payload}: PayloadAction<string>) => HSPCompanyOpportunitySeasonsLoad(payload))
}

export function* watchHSPOpportunityCompanySeasonsRefresh() {
  yield takeLatest(HSP_COMPANY_OPPORTUNITY_SEASONS.REFRESH, ({payload}: PayloadAction<string>) => HSPCompanyOpportunitySeasonsLoad(payload))
}

export function* watchHSPOpportunityContactSeasonsLoad() {
  yield takeLatest(HSP_CONTACT_OPPORTUNITY_SEASONS.LOAD, ({payload}: PayloadAction<string>) => HSPContactOpportunitySeasonsLoad(payload))
}

export function* watchHSPOpportunityContactSeasonsRefresh() {
  yield takeLatest(HSP_CONTACT_OPPORTUNITY_SEASONS.REFRESH, ({payload}: PayloadAction<string>) => HSPContactOpportunitySeasonsLoad(payload))
}

export function* watchHSPCurrentOpportunityLoad() {
  yield takeLatest(HSP_CURRENT_OPPORTUNITY.LOAD, ({payload}: PayloadAction<HSPOpportunityBySeasonRequest>) => HSPCurrentOpportunityLoad(payload))
}


export default function* hspOpportunitySaga() {
  yield all ([
    call(watchHSPOpportunityLoadBySeason),
    call(watchHSPOpportunityLoad),
    call(watchHSPOpportunityStudentProfilesLoad),
    call(watchHSPOpportunityRefresh),
    call(watchHSPOpportunityRefreshBySeason),
    call(watchHSPOpportunitySave),
    call(watcHSPOpportunitySearchLoad),
    call(watchHSPOpportunitySearchRefresh),
    call(watchHSPOpportunityLocalitySearchLoad),
    call(watchHSPOpportunityLocalitySearchRefresh),
    call(watchHSPOpportunityCompanySearchLoad),
    call(watchHSPOpportunityCompanySearchRefresh),
    call(watchHSPOpportunityStudentSeasonsLoad),
    call(watchHSPOpportunityCompanySeasonsLoad),
    call(watchHSPOpportunityCompanySeasonsRefresh),
    call(watchHSPOpportunityContactSeasonsLoad),
    call(watchHSPOpportunityContactSeasonsRefresh),
    call(watchHSPCurrentOpportunityLoad),
  ])
}
