import {AppState} from '../store';
import {hasItems} from '../../@template/helpers/arrays';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {HSPPoolSuggestModel} from '../../types/views/HSPPoolSuggestModel';
import {HSP_POOL_CACHE, hspPoolCacheError, hspPoolCacheMerge, hspPoolCacheUpdate} from './Actions';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {IHSPPoolsCacheState} from './Reducer';
import {PayloadAction} from '@reduxjs/toolkit';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const poolCacheState = (state: AppState) => state.hspPoolsCache;

export function* HSPLoadPoolCacheItems(idList: string[]) {
  console.log('SAGA - hspPoolCacheSaga - LoadHSPPoolCacheItems', idList);
  try {
    if (hasItems(idList)) {
      const res = yield authApi.request({
        baseURL: apiConfig.coreUrl,
        method: 'POST',
        url: `${apiConfig.coreHSPPoolsPath}/list`,
        data: { id_list: idList }
      });
      const people: HSPPoolSuggestModel[] = res.data.map((x: any) => HSPPoolSuggestModel.fromJS(x));
      yield put(hspPoolCacheMerge(people));
    }
  } catch (err) {
    console.log('Cache load error', err);
    yield put(hspPoolCacheError(err));
  }
}

export function* HSPAddPoolCacheItems() {
  console.log('SAGA - hspPoolCacheSaga - AddPoolCacheItems TRY LOADLoad');
  yield call(delay, 200);
  const currentPoolCache: IHSPPoolsCacheState = yield select(poolCacheState);
  const currentPendingList = currentPoolCache.hspPoolPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    console.log('SAGA - hspPoolCacheSaga - AddPoolCacheItems Load', currentPendingList);
    yield put(hspPoolCacheUpdate([...currentPendingList]));
  }
}

export function* watchHSPLoadPoolCacheItems(){
  yield takeEvery(HSP_POOL_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => HSPLoadPoolCacheItems(payload));
}

export function* watchHSPAddPoolCacheItems(){
  yield takeEvery(HSP_POOL_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => HSPAddPoolCacheItems());
}

export default function* hspPoolCacheSaga() {
  yield all([
    call(watchHSPLoadPoolCacheItems),
    call(watchHSPAddPoolCacheItems),
  ])
}