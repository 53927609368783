export class EmailTemplateModel implements IEmailTemplateModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  title?: string | undefined;
  /** Provider template id */
  providerId?: string | undefined;
  description?: string | undefined;
  emailSubject?: string | undefined;
  prefilledContent?: string | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByUserId?: string | undefined;
  isEnabled?: boolean | undefined;
  dateDeactivated?: Date | undefined;
  deactivatedByUserId?: string | undefined;
  /** ActionItemMessage content */
  message?: string | undefined;
  fileIdList?: string[] | undefined;
  contextType?: string[] | undefined;

  constructor(data?: IEmailTemplateModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.dateUpdated = Data["dateUpdated"] ? new Date(Data["dateUpdated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.updatedByUserId = Data["updatedByUserId"];
      this.title = Data["title"];
      this.providerId = Data["providerId"];
      this.description = Data["description"];
      this.emailSubject = Data["emailSubject"];
      this.prefilledContent = Data["prefilledContent"];
      this.isDeleted = Data["isDeleted"];
      this.dateDeleted = Data["dateDeleted"] ? new Date(Data["dateDeleted"].toString()) : <any>undefined;
      this.deletedByUserId = Data["deletedByUserId"];
      this.isEnabled = Data["isEnabled"];
      this.dateDeactivated = Data["dateDeactivated"] ? new Date(Data["dateDeactivated"].toString()) : <any>undefined;
      this.deactivatedByUserId = Data["deactivatedByUserId"];
      this.message = Data["message"];
      if (Array.isArray(Data["fileIdList"])) {
        this.fileIdList = [] as any;
        for (let item of Data["fileIdList"])
          this.fileIdList!.push(item);
      }
      if (Array.isArray(Data["contextType"])) {
        this.contextType = [] as any;
        for (let item of Data["contextType"])
          this.contextType!.push(item);
      }
    }
  }

  static fromJS(data: any): EmailTemplateModel {
    data = typeof data === 'object' ? data : {};
    let result = new EmailTemplateModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["dateUpdated"] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["updatedByUserId"] = this.updatedByUserId;
    data["title"] = this.title;
    data["providerId"] = this.providerId;
    data["description"] = this.description;
    data["emailSubject"] = this.emailSubject;
    data["prefilledContent"] = this.prefilledContent;
    data["isDeleted"] = this.isDeleted;
    data["dateDeleted"] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
    data["deletedByUserId"] = this.deletedByUserId;
    data["isEnabled"] = this.isEnabled;
    data["dateDeactivated"] = this.dateDeactivated ? this.dateDeactivated.toISOString() : <any>undefined;
    data["deactivatedByUserId"] = this.deactivatedByUserId;
    data["message"] = this.message;
    if (Array.isArray(this.fileIdList)) {
      data["fileIdList"] = [];
      for (let item of this.fileIdList)
        data["fileIdList"].push(item);
    }
    if (Array.isArray(this.contextType)) {
      data["contextType"] = [];
      for (let item of this.contextType)
        data["contextType"].push(item);
    }
    return data;
  }
}

export interface IEmailTemplateModel {
  id?: string | undefined;
  dateCreated?: Date | undefined;
  dateUpdated?: Date | undefined;
  createdByUserId?: string | undefined;
  updatedByUserId?: string | undefined;
  title?: string | undefined;
  /** Provider template id */
  providerId?: string | undefined;
  description?: string | undefined;
  emailSubject?: string | undefined;
  prefilledContent?: string | undefined;
  isDeleted?: boolean | undefined;
  dateDeleted?: Date | undefined;
  deletedByUserId?: string | undefined;
  isEnabled?: boolean | undefined;
  dateDeactivated?: Date | undefined;
  deactivatedByUserId?: string | undefined;
  /** ActionItemMessage content */
  message?: string | undefined;
  fileIdList?: string[] | undefined;
  contextType?: string[] | undefined;
}