import { Paging } from "types/common/Paging";
import {OpportunityDetailModel} from './OpportunityDetailModel';

export class OpportunitySearchPaged implements IOpportunitySearchPaged {
  paging?: Paging | undefined;
  result?: OpportunityDetailModel[] | undefined;

  constructor(data?: IOpportunitySearchPaged) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.paging = Data["paging"] ? Paging.fromJS(Data["paging"]) : <any>undefined;
      if (Array.isArray(Data["result"])) {
        this.result = [] as any;
        for (let item of Data["result"])
          this.result!.push(OpportunityDetailModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): OpportunitySearchPaged {
    data = typeof data === 'object' ? data : {};
    let result = new OpportunitySearchPaged();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["paging"] = this.paging ? this.paging.toJSON() : <any>undefined;
    if (Array.isArray(this.result)) {
      data["result"] = [];
      for (let item of this.result)
        data["result"].push(item.toJSON());
    }
    return data;
  }
}

export interface IOpportunitySearchPaged {
  paging?: Paging | undefined;
  result?: OpportunityDetailModel[] | undefined;
}