import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  CONTACT, CONTACT_SEARCH,
  contactLoadError, contactLoadSuccess,
  contactSaveError, contactSaveSuccess,
  contactsSearchError, contactsSearchSuccess,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {ContactPersonEditModel} from '../../types/requests/ContactPersonEditModel';
import {ICommonSavePayload} from '../Common.actions';
import {ContactPersonModel} from '../../types/views/ContactPersonModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {ContactSearchParamsModel} from '../../types/requests/ContactSearchParamsModel';
import {ContactSearchPaged} from '../../types/views/ContactSearchPaged';
import {PersonModel} from '../../types/models/PersonModel';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';

function* LoadContact(id: string) {
  console.log('SAGA - contactSaga - LoadContact');
  try {
    const res = yield authApi.get(`${apiConfig.coreContactPath}/${id}`);
    const contact = PersonModel.fromJS(res.data);
    yield put(contactLoadSuccess(contact));
  } catch (error) {
    yield put(contactLoadError(error));
  }
}

function* SaveContact({ id, submission }: ICommonSavePayload<ContactPersonEditModel>) {
  console.log('SAGA - contactSaga - SaveContact');
  const contactPath = id ? `${apiConfig.coreContactPath}/${id}` : apiConfig.coreContactPath;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: contactPath,
      data: submission
    });
    const contact = ContactPersonModel.fromJS(res.data);
    yield put(contactSaveSuccess(contact));
  } catch (error) {
    yield put(contactSaveError(error));
  }
}

function* SearchContacts({ page, sort, searchParams }: ISearchRequest<ContactSearchParamsModel>) {
  console.log('SAGA - contactSaga - SearchContacts');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreContactPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(contactsSearchSuccess(results));
  } catch (error) {
    yield put(contactsSearchError(error));
  }
}

export function* watchContactLoad() {
  yield takeLatest(CONTACT.LOAD, ({ payload }: PayloadAction<string>) => LoadContact(payload));
}

export function* watchContactRefresh() {
  yield takeLatest(CONTACT.REFRESH, ({ payload }: PayloadAction<string>) => LoadContact(payload));
}

export function* watchContactSave() {
  yield takeLatest(CONTACT.SAVE, ({ payload }: PayloadAction<ICommonSavePayload<ContactPersonEditModel>>) => SaveContact(payload));
}

export function* watchContactSearch() {
  yield takeLatest(CONTACT_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<ContactSearchParamsModel>>) => SearchContacts(payload))
}

export function* watchContactSearchRefresh() {
  yield takeLatest(CONTACT_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<ContactSearchParamsModel>>) => SearchContacts(payload))
}

export default function* studentSaga() {
  yield all ([
    call(watchContactRefresh),
    call(watchContactLoad),
    call(watchContactSave),
    call(watchContactSearch),
    call(watchContactSearchRefresh),
  ])
}
