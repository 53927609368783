import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {makeStyles} from '@mui/styles';

const useStyle = makeStyles({
  btn: {
    marginBottom: 8,
  },
});

interface EmptyResultProps {
  title: string;
  description: string;
  actionTitle?: string;
  onAction?: (() => void);
}

const EmptyResult: React.FC<EmptyResultProps> = ({
                                                   title = 'Not found',
                                                   description = '',
                                                   actionTitle,
                                                   onAction,
                                                 }) => {
  const classes = useStyle();
  return (
    <Box
      display='flex'
      flexDirection='column'
      flex={1}
      justifyContent='center'
      width={1}
      height={1}
      alignItems='center'
      p={5}>
      <Box
        component='h2'
        mb={4}
        color='text.secondary'>
        {title}
      </Box>
      {description ? (
        <Box component='p' mb={5} color='text.secondary'>
          {description}
        </Box>
      ) : null}
      {actionTitle && onAction ? (
        <Button
          className={classes.btn}
          variant='contained'
          color='primary'
          onClick={onAction}>
          {actionTitle}
        </Button>
      ) : null}
    </Box>
  );
};

export default EmptyResult;
