export class JobProgressModel implements IJobProgressModel {
  jobId?: string | undefined;
  jobType?: string | undefined;
  jobDescription?: string | undefined;
  jobParameters?: any | undefined;
  jobExpiresMinutes?: number | undefined;
  dateJobExpires?: Date | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  jobStatus?: string | undefined;
  dateCompleted?: Date | undefined;
  batchTotal?: number | undefined;
  batchProcessed?: number | undefined;
  itemsTotal?: number | undefined;
  itemsProcessed?: number | undefined;
  itemsFailed?: number | undefined;
  itemsSuccess?: number | undefined;
  concurrentBatches?: number | undefined;

  constructor(data?: IJobProgressModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.jobId = Data["jobId"];
      this.jobType = Data["jobType"];
      this.jobDescription = Data["jobDescription"];
      this.jobParameters = Data["jobParameters"];
      this.jobExpiresMinutes = Data["jobExpiresMinutes"];
      this.dateJobExpires = Data["dateJobExpires"] ? new Date(Data["dateJobExpires"].toString()) : <any>undefined;
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.createdByUserId = Data["createdByUserId"];
      this.jobStatus = Data["jobStatus"];
      this.dateCompleted = Data["dateCompleted"] ? new Date(Data["dateCompleted"].toString()) : <any>undefined;
      this.batchTotal = Data["batchTotal"];
      this.batchProcessed = Data["batchProcessed"];
      this.itemsTotal = Data["itemsTotal"];
      this.itemsProcessed = Data["itemsProcessed"];
      this.itemsFailed = Data["itemsFailed"];
      this.itemsSuccess = Data["itemsSuccess"];
      this.concurrentBatches = Data["concurrentBatches"];
    }
  }

  static fromJS(data: any): JobProgressModel {
    data = typeof data === 'object' ? data : {};
    let result = new JobProgressModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["jobId"] = this.jobId;
    data["jobType"] = this.jobType;
    data["jobDescription"] = this.jobDescription;
    data["jobParameters"] = this.jobParameters;
    data["jobExpiresMinutes"] = this.jobExpiresMinutes;
    data["dateJobExpires"] = this.dateJobExpires ? this.dateJobExpires.toISOString() : <any>undefined;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["createdByUserId"] = this.createdByUserId;
    data["jobStatus"] = this.jobStatus;
    data["dateCompleted"] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
    data["batchTotal"] = this.batchTotal;
    data["batchProcessed"] = this.batchProcessed;
    data["itemsTotal"] = this.itemsTotal;
    data["itemsProcessed"] = this.itemsProcessed;
    data["itemsFailed"] = this.itemsFailed;
    data["itemsSuccess"] = this.itemsSuccess;
    data["concurrentBatches"] = this.concurrentBatches;
    return data;
  }
}

export interface IJobProgressModel {
  jobId?: string | undefined;
  jobType?: string | undefined;
  jobDescription?: string | undefined;
  jobParameters?: any | undefined;
  jobExpiresMinutes?: number | undefined;
  dateJobExpires?: Date | undefined;
  dateCreated?: Date | undefined;
  createdByUserId?: string | undefined;
  jobStatus?: string | undefined;
  dateCompleted?: Date | undefined;
  batchTotal?: number | undefined;
  batchProcessed?: number | undefined;
  itemsTotal?: number | undefined;
  itemsProcessed?: number | undefined;
  itemsFailed?: number | undefined;
  itemsSuccess?: number | undefined;
  concurrentBatches?: number | undefined;
}