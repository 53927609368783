export class CompanySuggestModel implements ICompanySuggestModel {
  brandId?: string | undefined;
  id?: string | undefined;
  companyId?: string | undefined;
  companyName?: string | undefined;
  groupName?: string | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  isEducation?: boolean | undefined;
  isClient?: boolean | undefined;
  isPartner?: boolean | undefined;

  constructor(data?: ICompanySuggestModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.brandId = Data["brandId"];
      this.id = Data["id"];
      this.companyId = Data["companyId"];
      this.companyName = Data["companyName"];
      this.groupName = Data["groupName"];
      this.suburb = Data["suburb"];
      this.state = Data["state"];
      this.isEducation = Data["isEducation"];
      this.isClient = Data["isClient"];
      this.isPartner = Data["isPartner"];
    }
  }

  static fromJS(data: any): CompanySuggestModel {
    data = typeof data === 'object' ? data : {};
    let result = new CompanySuggestModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["brandId"] = this.brandId;
    data["id"] = this.id;
    data["companyId"] = this.companyId;
    data["companyName"] = this.companyName;
    data["groupName"] = this.groupName;
    data["suburb"] = this.suburb;
    data["state"] = this.state;
    data["isEducation"] = this.isEducation;
    data["isClient"] = this.isClient;
    data["isPartner"] = this.isPartner;
    return data;
  }
}

export interface ICompanySuggestModel {
  brandId?: string | undefined;
  id?: string | undefined;
  companyId?: string | undefined;
  companyName?: string | undefined;
  groupName?: string | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  isEducation?: boolean | undefined;
  isClient?: boolean | undefined;
  isPartner?: boolean | undefined;
}