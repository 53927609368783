import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {peoplePaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';


const sectionBreadcrumbs = [new BreadcrumbItemProps("People", peoplePaths.PeopleSearch)];

const peopleRoutes = [
  new AppRouteProps("Search", peoplePaths.PeopleSearch, React.lazy(() => import('./PeopleSearch')), sectionBreadcrumbs),
]

const peopleRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, peopleRoutes)
];

export default peopleRoutesConfig;
