import {OpportunityDetailModel} from '../../types/models/OpportunityDetailModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {
  HSP_COMPANY_OPPORTUNITY_SEARCH,
  HSP_COMPANY_OPPORTUNITY_SEASONS, HSP_CONTACT_OPPORTUNITY_SEASONS, HSP_CURRENT_OPPORTUNITY,
  HSP_OPPORTUNITY, HSP_OPPORTUNITY_LOCALITY_SEARCH,
  HSP_OPPORTUNITY_SEARCH,
  HSP_OPPORTUNITY_STUDENT_PROFILES, HSP_OPPORTUNITY_SEASONS,
} from './Actions';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {PersonModel} from '../../types/models/PersonModel';
import {HSPOpportunitySearchPaged} from '../../types/models/HSPOpportunitySearchPaged';
import {HSPOpportunitySummaryModel} from '../../types/models/HSPOpportunitySummaryModel';
import {HSPOpportunityDetailModel} from '../../types/models/HSPOpportunityDetailModel';
import {HSPOpportunityStudentListView} from '../../types/views/HSPOpportunityStudentListView';
import {LocalOpportunitySearchItem} from '../../types/views/LocalOpportunitySearchItem';
import {defaultForHSPLocalOpportunitySearchParamsModel,
  HSPOpportunitySearchParamsModel} from '../../types/requests/HSPOpportunitySearchParamsModel';
import {HSPLocalOpportunitySearchItem} from '../../types/views/HSPLocalOpportunitySearchItem';


export interface IHSPOpportunityState {
  hspOpportunitySearchParams: HSPOpportunitySearchParamsModel | undefined;
  hspOpportunitySearchView: string | undefined;
  hspOpportunitySearchSort: string | undefined;
  hspOpportunityResultLoading: boolean;
  hspOpportunityResultError: string | undefined;
  hspOpportunityResult: HSPOpportunitySearchPaged | undefined;
  hspOpportunityCompanyResultLoading: boolean;
  hspOpportunityCompanyResultRefreshing: boolean;
  hspOpportunityCompanyResultError: string | undefined;
  hspOpportunityCompanyResult: HSPOpportunityDetailModel[] | undefined;
  hspOpportunitiesSelectLoading: boolean;
  hspOpportunitiesSelectError: string | undefined;
  hspOpportunitiesSelectResult: HSPOpportunityDetailModel[] | undefined;
  hspOpportunityCompanySeasons: HSPOpportunitySummaryModel[] | undefined;
  hspOpportunityCompanySeasonsLoading: boolean;
  hspOpportunityCompanySeasonsRefreshing: boolean;
  hspOpportunityCompanySeasonsError: string | undefined;
  hspOpportunityLoading: boolean;
  hspOpportunityRefreshing: boolean;
  hspOpportunitySaving: boolean;
  hspOpportunityError: string | undefined;
  hspOpportunityDetail: HSPOpportunityStudentListView | undefined;
  hspOpportunityStudentProfiles: PersonModel[] | undefined;
  hspOpportunityStudentProfilesLoading: boolean;
  hspOpportunityStudentProfilesError: string | undefined;
  hspCurrentOpportunityState: string| undefined;
  hspOpportunityStudentSeasons: HSPOpportunityDetailModel[] | undefined;
  hspOpportunityStudentSeasonsRefreshing: boolean;
  hspOpportunityStudentSeasonsLoading: boolean;
  hspOpportunityStudentSeasonsError: string | undefined;
  hspOpportunityContactSeasons: HSPOpportunitySummaryModel[] | undefined;
  hspOpportunityContactSeasonsLoading: boolean;
  hspOpportunityContactSeasonsRefreshing: boolean;
  hspOpportunityContactSeasonsError: string | undefined;
  hspOpportunityLocalSearchParams: HSPOpportunitySearchParamsModel;
  hspOpportunityLocalSearchView: string | undefined;
  hspOpportunityLocalSearchSort: string | undefined;
  hspOpportunityLocalResultLoading: boolean;
  hspOpportunityLocalResultError: string | undefined;
  hspOpportunityLocalResult: HSPLocalOpportunitySearchItem[] | undefined;
  hspCurrentOpportunityDetail: HSPOpportunityStudentListView | undefined;
  hspCurrentOpportunityLoading: boolean;
  hspCurrentOpportunityRefreshing: boolean;
  hspCurrentOpportunityError: string | undefined;
}

const initialState: IHSPOpportunityState = {
  hspOpportunitySearchParams: undefined,
  hspOpportunitySearchView: 'default',
  hspOpportunitySearchSort: 'Relevance',
  hspOpportunityResultLoading: false,
  hspOpportunityResultError: undefined,
  hspOpportunityResult: undefined,
  hspOpportunityCompanyResultLoading: false,
  hspOpportunityCompanyResultRefreshing: true,
  hspOpportunityCompanyResultError: undefined,
  hspOpportunityCompanyResult: undefined,
  hspOpportunitiesSelectLoading: false,
  hspOpportunitiesSelectError: undefined,
  hspOpportunitiesSelectResult: undefined,
  hspOpportunityCompanySeasons: undefined,
  hspOpportunityCompanySeasonsLoading: false,
  hspOpportunityCompanySeasonsRefreshing: false,
  hspOpportunityCompanySeasonsError: undefined,
  hspOpportunityLoading: false,
  hspOpportunityRefreshing: false,
  hspOpportunitySaving: false,
  hspOpportunityError: undefined,
  hspOpportunityDetail: undefined,
  hspOpportunityStudentProfiles: undefined,
  hspOpportunityStudentProfilesError: undefined,
  hspOpportunityStudentProfilesLoading: false,
  hspCurrentOpportunityState: undefined,
  hspOpportunityStudentSeasons: undefined,
  hspOpportunityStudentSeasonsRefreshing: false,
  hspOpportunityStudentSeasonsLoading: false,
  hspOpportunityStudentSeasonsError: undefined,
  hspOpportunityContactSeasons: undefined,
  hspOpportunityContactSeasonsLoading: false,
  hspOpportunityContactSeasonsRefreshing: false,
  hspOpportunityContactSeasonsError: undefined,
  hspOpportunityLocalSearchParams: defaultForHSPLocalOpportunitySearchParamsModel(),
  hspOpportunityLocalSearchView: 'default',
  hspOpportunityLocalSearchSort: 'Relevance',
  hspOpportunityLocalResultLoading: false,
  hspOpportunityLocalResultError: undefined,
  hspOpportunityLocalResult: undefined,
  hspCurrentOpportunityDetail: undefined,
  hspCurrentOpportunityLoading: false,
  hspCurrentOpportunityRefreshing: false,
  hspCurrentOpportunityError: undefined,
};

const hspOpportunitiesReducer = createReducer(initialState, {
  [HSP_OPPORTUNITY.LOAD]: (state) => {
    state.hspOpportunityLoading = true;
    state.hspOpportunityRefreshing = false;
    state.hspOpportunityError = undefined;
    state.hspOpportunityDetail = undefined;
  },
  [HSP_OPPORTUNITY.LOAD_BY_SEASON]: (state) => {
    state.hspOpportunityLoading = true;
    state.hspOpportunityRefreshing = false;
    state.hspOpportunityError = undefined;
    state.hspOpportunityDetail = undefined;
  },
  [HSP_OPPORTUNITY.REFRESH]: (state) => {
    state.hspOpportunityLoading = false;
    state.hspOpportunityRefreshing = true;
    state.hspOpportunityError = undefined;
  },
  [HSP_OPPORTUNITY.REFRESH_BY_SEASON]: (state) => {
    state.hspOpportunityLoading = false;
    state.hspOpportunityRefreshing = true;
    state.hspOpportunityError = undefined;
  },
  [HSP_OPPORTUNITY.LOADED]: (state, action: PayloadAction<HSPOpportunityStudentListView>) => {
    state.hspOpportunityLoading = false;
    state.hspOpportunityError = undefined;
    state.hspOpportunityDetail = action.payload;
  },
  [HSP_OPPORTUNITY.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityLoading = false;
    state.hspOpportunityError = action.payload;
  },
  [HSP_OPPORTUNITY_STUDENT_PROFILES.LOAD]: (state) => {
    state.hspOpportunityStudentProfilesLoading = true;
    state.hspOpportunityStudentProfilesError = undefined;
    state.hspOpportunityStudentProfiles = undefined;
  },
  [HSP_OPPORTUNITY_STUDENT_PROFILES.LOADED]: (state, action: PayloadAction<PersonModel[]>) => {
    state.hspOpportunityStudentProfilesLoading = false;
    state.hspOpportunityStudentProfilesError = undefined;
    state.hspOpportunityStudentProfiles = action.payload;
  },
  [HSP_OPPORTUNITY_STUDENT_PROFILES.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityStudentProfilesLoading = false;
    state.hspOpportunityStudentProfilesError = action.payload;
  },
  [HSP_OPPORTUNITY.SAVE]: (state) => {
    state.hspOpportunityError = undefined;
    state.hspOpportunitySaving = true;
  },
  [HSP_OPPORTUNITY.SAVED]: (state, action: PayloadAction<OpportunityDetailModel>) => {
    state.hspOpportunitySaving = false;
    state.hspOpportunityError = undefined;
    state.hspOpportunityDetail = action.payload;
  },
  [HSP_OPPORTUNITY.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunitySaving = false;
    state.hspOpportunityError = action.payload;
  },
  [HSP_OPPORTUNITY_SEARCH.LOAD]: (state, action: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => {
    state.hspOpportunitySearchParams = action.payload.searchParams;
    state.hspOpportunitySearchSort = action.payload.sort;
    state.hspOpportunityResultLoading = true;
    state.hspOpportunityResultError = undefined;
  },
  [HSP_OPPORTUNITY_SEARCH.LOADED]: (state, action: PayloadAction<HSPOpportunitySearchPaged>) => {
    state.hspOpportunityResultLoading = false;
    state.hspOpportunityResultError = undefined;
    state.hspOpportunityResult = action.payload;
  },
  [HSP_OPPORTUNITY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityResultLoading = false;
    state.hspOpportunityResultError = action.payload;
  },
  [HSP_COMPANY_OPPORTUNITY_SEARCH.LOAD]: (state) => {
    state.hspOpportunityCompanyResult = undefined;
    state.hspOpportunityCompanyResultLoading = true;
    state.hspOpportunityCompanyResultRefreshing = false;
    state.hspOpportunityCompanyResultError = undefined;
  },
  [HSP_COMPANY_OPPORTUNITY_SEARCH.REFRESH]: (state) => {
    state.hspOpportunityCompanyResultLoading = false;
    state.hspOpportunityCompanyResultRefreshing = true;
    state.hspOpportunityCompanyResultError = undefined;
  },
  [HSP_COMPANY_OPPORTUNITY_SEARCH.LOADED]: (state, action: PayloadAction<HSPOpportunityDetailModel[]>) => {
    state.hspOpportunityCompanyResult = action.payload;
    state.hspOpportunityCompanyResultLoading = false;
    state.hspOpportunityCompanyResultRefreshing = false;
    state.hspOpportunityCompanyResultError = undefined;
  },
  [HSP_COMPANY_OPPORTUNITY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityCompanyResultLoading = false;
    state.hspOpportunityCompanyResultRefreshing = false;
    state.hspOpportunityCompanyResultError = action.payload;
  },
  [HSP_COMPANY_OPPORTUNITY_SEASONS.LOAD]: (state) => {
    state.hspOpportunityCompanySeasons = undefined;
    state.hspOpportunityCompanySeasonsRefreshing = false;
    state.hspOpportunityCompanySeasonsLoading = true;
    state.hspOpportunityCompanySeasonsError = undefined;
  },
  [HSP_COMPANY_OPPORTUNITY_SEASONS.REFRESH]: (state) => {
    state.hspOpportunityCompanySeasons = undefined;
    state.hspOpportunityCompanySeasonsRefreshing = true;
    state.hspOpportunityCompanySeasonsLoading = false;
    state.hspOpportunityCompanySeasonsError = undefined;
  },
  [HSP_COMPANY_OPPORTUNITY_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityCompanySeasonsRefreshing = false;
    state.hspOpportunityCompanySeasonsLoading = false;
    state.hspOpportunityCompanySeasonsError = action.payload;
  },
  [HSP_COMPANY_OPPORTUNITY_SEASONS.LOADED]: (state, action: PayloadAction<HSPOpportunitySummaryModel[]>) => {
    state.hspOpportunityCompanySeasons = action.payload;
    state.hspOpportunityCompanySeasonsRefreshing = false;
    state.hspOpportunityCompanySeasonsLoading = false;
    state.hspOpportunityCompanySeasonsError = undefined;
  },
  [HSP_OPPORTUNITY_SEASONS.LOAD]: (state) => {
    state.hspOpportunityStudentSeasons = undefined;
    state.hspOpportunityStudentSeasonsRefreshing = false;
    state.hspOpportunityStudentSeasonsLoading = true;
    state.hspOpportunityStudentSeasonsError = undefined;
  },
  [HSP_OPPORTUNITY_SEASONS.REFRESH]: (state) => {
    state.hspOpportunityStudentSeasons = undefined;
    state.hspOpportunityStudentSeasonsRefreshing = true;
    state.hspOpportunityStudentSeasonsLoading = false;
    state.hspOpportunityStudentSeasonsError = undefined;
  },
  [HSP_OPPORTUNITY_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityStudentSeasonsRefreshing = false;
    state.hspOpportunityStudentSeasonsLoading = false;
    state.hspOpportunityStudentSeasonsError = action.payload;
  },
  [HSP_OPPORTUNITY_SEASONS.LOADED]: (state, action: PayloadAction<HSPOpportunityDetailModel[]>) => {
    state.hspOpportunityStudentSeasons = action.payload;
    state.hspOpportunityStudentSeasonsRefreshing = false;
    state.hspOpportunityStudentSeasonsLoading = false;
    state.hspOpportunityStudentSeasonsError = undefined;
  },

  [HSP_CONTACT_OPPORTUNITY_SEASONS.LOAD]: (state) => {
    state.hspOpportunityContactSeasons = undefined;
    state.hspOpportunityContactSeasonsRefreshing = false;
    state.hspOpportunityContactSeasonsLoading = true;
    state.hspOpportunityContactSeasonsError = undefined;
  },
  [HSP_CONTACT_OPPORTUNITY_SEASONS.REFRESH]: (state) => {
    state.hspOpportunityContactSeasons = undefined;
    state.hspOpportunityContactSeasonsRefreshing = true;
    state.hspOpportunityContactSeasonsLoading = false;
    state.hspOpportunityContactSeasonsError = undefined;
  },
  [HSP_CONTACT_OPPORTUNITY_SEASONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityContactSeasonsRefreshing = false;
    state.hspOpportunityContactSeasonsLoading = false;
    state.hspOpportunityContactSeasonsError = action.payload;
  },
  [HSP_CONTACT_OPPORTUNITY_SEASONS.LOADED]: (state, action: PayloadAction<HSPOpportunitySummaryModel[]>) => {
    state.hspOpportunityContactSeasons = action.payload;
    state.hspOpportunityContactSeasonsRefreshing = false;
    state.hspOpportunityContactSeasonsLoading = false;
    state.hspOpportunityContactSeasonsError = undefined;
  },
  [HSP_OPPORTUNITY_LOCALITY_SEARCH.LOAD]: (state, action: PayloadAction<ISearchRequest<HSPOpportunitySearchParamsModel>>) => {
    state.hspOpportunityLocalSearchParams = action.payload.searchParams;
    state.hspOpportunityLocalSearchSort = action.payload.sort;
    state.hspOpportunityLocalResultLoading = true;
    state.hspOpportunityLocalResultError = undefined;
  },
  [HSP_OPPORTUNITY_LOCALITY_SEARCH.LOADED]: (state, action: PayloadAction<LocalOpportunitySearchItem[]>) => {
    state.hspOpportunityLocalResultLoading = false;
    state.hspOpportunityLocalResultError = undefined;
    state.hspOpportunityLocalResult = action.payload;
  },
  [HSP_OPPORTUNITY_LOCALITY_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspOpportunityLocalResultLoading = true;
    state.hspOpportunityLocalResultError = action.payload;
  },
  [HSP_CURRENT_OPPORTUNITY.LOAD]: (state) => {
    state.hspCurrentOpportunityLoading = true;
    state.hspCurrentOpportunityRefreshing = false;
    state.hspCurrentOpportunityError = undefined;
    state.hspCurrentOpportunityDetail = undefined;
  },
  [HSP_CURRENT_OPPORTUNITY.REFRESH]: (state) => {
    state.hspCurrentOpportunityLoading = false;
    state.hspCurrentOpportunityRefreshing = true;
    state.hspCurrentOpportunityError = undefined;
  },
  [HSP_CURRENT_OPPORTUNITY.LOADED]: (state, action: PayloadAction<HSPOpportunityStudentListView>) => {
    state.hspCurrentOpportunityLoading = false;
    state.hspCurrentOpportunityError = undefined;
    state.hspCurrentOpportunityDetail = action.payload;
  },
  [HSP_CURRENT_OPPORTUNITY.ERROR]: (state, action: PayloadAction<string>) => {
    state.hspCurrentOpportunityLoading = false;
    state.hspCurrentOpportunityError = action.payload;
  },
});

export default hspOpportunitiesReducer;

