export class LearningContractConfigModel implements ILearningContractConfigModel {
  section?: string | undefined;
  title?: string | undefined;
  content?: string | undefined;
  preUniContent?: string | undefined;
  graduateContent?: string | undefined;
  lastUpdated?: string | undefined;
  isCustomField?: boolean | undefined;

  constructor(data?: ILearningContractConfigModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.section = Data["section"];
      this.title = Data["title"];
      this.content = Data["content"];
      this.preUniContent = Data["preUniContent"];
      this.graduateContent = Data["graduateContent"];
      this.lastUpdated = Data["lastUpdated"];
      this.isCustomField = Data["isCustomField"];
    }
  }

  static fromJS(data: any): LearningContractConfigModel {
    data = typeof data === 'object' ? data : {};
    let result = new LearningContractConfigModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["section"] = this.section;
    data["title"] = this.title;
    data["content"] = this.content;
    data["preUniContent"] = this.preUniContent;
    data["graduateContent"] = this.graduateContent;
    data["lastUpdated"] = this.lastUpdated;
    data["isCustomField"] = this.isCustomField;
    return data;
  }
}

export interface ILearningContractConfigModel {
  section?: string | undefined;
  title?: string | undefined;
  content?: string | undefined;
  preUniContent?: string | undefined;
  graduateContent?: string | undefined;
  lastUpdated?: string | undefined;
  isCustomField?: boolean | undefined;
}