import {createSelector} from 'reselect';
import {IGlobalSearchState} from './Reducer';

export const selectGlobalSearchState = (state: { globalSearch: IGlobalSearchState }) => state.globalSearch;

export const  selectGlobalSearchValue = createSelector(
  selectGlobalSearchState,
  (subState) => subState.globalSearchValue
);

export const  selectGlobalSearchResult = createSelector(
  selectGlobalSearchState,
  (subState) => subState.globalSearchResult
);

export const  selectGlobalSearchLoading = createSelector(
  selectGlobalSearchState,
  (subState) => subState.globalSearchLoading
);

export const  selectGlobalSearchError = createSelector(
  selectGlobalSearchState,
  (subState) => subState.globalSearchError
);
