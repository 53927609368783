export class AchievementModel implements IAchievementModel {
  achievement?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  icon?: string | undefined;
  colour?: string | undefined;
  isSemesterly?: boolean | undefined;
  isYearly?: boolean | undefined;
  hasCount?: boolean | undefined;
  isSpecial?: boolean | undefined;
  showBeforeAttained?: boolean | undefined;

  constructor(data?: IAchievementModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.achievement = Data["achievement"];
      this.name = Data["name"];
      this.description = Data["description"];
      this.icon = Data["icon"];
      this.colour = Data["colour"];
      this.isSemesterly = Data["isSemesterly"];
      this.isYearly = Data["isYearly"];
      this.hasCount = Data["hasCount"];
      this.isSpecial = Data["isSpecial"];
      this.showBeforeAttained = Data["showBeforeAttained"];
    }
  }

  static fromJS(data: any): AchievementModel {
    data = typeof data === 'object' ? data : {};
    let result = new AchievementModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["achievement"] = this.achievement;
    data["name"] = this.name;
    data["description"] = this.description;
    data["icon"] = this.icon;
    data["colour"] = this.colour;
    data["isSemesterly"] = this.isSemesterly;
    data["isYearly"] = this.isYearly;
    data["hasCount"] = this.hasCount;
    data["isSpecial"] = this.isSpecial;
    data["showBeforeAttained"] = this.showBeforeAttained;
    return data;
  }
}

export interface IAchievementModel {
  achievement?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  icon?: string | undefined;
  colour?: string | undefined;
  isSemesterly?: boolean | undefined;
  isYearly?: boolean | undefined;
  hasCount?: boolean | undefined;
  isSpecial?: boolean | undefined;
  showBeforeAttained?: boolean | undefined;
}