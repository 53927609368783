import {ICommonCacheActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {MailingListListItem} from '../../types/views/MailingListListItem';

export const MAILING_LIST_CACHE: ICommonCacheActionType = {
  ADD: 'mailingListCache/add',
  UPDATE: 'mailingListCache/updated',
  MERGE_ITEMS: 'mailingListCache/merge',
  ERROR: 'mailingListCache/error',
  REMOVE: 'mailingListCache/remove'
};

export const mailingListCacheAdd = createAction<string[]>(MAILING_LIST_CACHE.ADD);
export const mailingListCacheUpdate = createAction<string[]>(MAILING_LIST_CACHE.UPDATE);
export const mailingListCacheMerge = createAction<MailingListListItem[]>(MAILING_LIST_CACHE.MERGE_ITEMS);
export const mailingListCacheError = createAction<string>(MAILING_LIST_CACHE.ERROR);
export const mailingListCacheRemove = createAction<string>(MAILING_LIST_CACHE.REMOVE);