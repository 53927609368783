import {toDate, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {addMinutes, isDate, subMinutes} from 'date-fns';

export const formatDate = (d: Date) => {
  return d.getFullYear() + '-' +
    (d.getMonth() < 9 ? ('0' + (d.getMonth()+1)) : (d.getMonth()+1)) + '-' +
    (d.getDate() < 10 ? ('0' + d.getDate()) : d.getDate());
}

export const toLocalTime = (d: string | undefined) => {
  if (!d) return undefined;
  return zonedTimeToUtc(d, 'UTC');
}

export function toUTC(dt: Date | undefined) {
  if (dt && isDate(dt)) {
    const offset = dt.getTimezoneOffset();

    return Math.sign(offset) !== -1 ? addMinutes(dt, offset) : subMinutes(dt, Math.abs(offset));
  }
  return undefined;
}

export function fromUTC(dt: Date | undefined) {
  if (dt && isDate(dt)) {
    const offset = dt.getTimezoneOffset();

    return Math.sign(offset) !== -1 ? subMinutes(dt, offset) : addMinutes(dt, Math.abs(offset));
  }
  return undefined;
}


export function cleanDate(dt: Date | undefined) {
  if (dt && isDate(dt)) {
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  }
  return undefined;
}

export const toLiteralUTCDate = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const monthLabels = [
  { value: 0, label: 'Jan' },
  { value: 1, label: 'Feb' },
  { value: 2, label: 'Mar' },
  { value: 3, label: 'Apr' },
  { value: 4, label: 'May' },
  { value: 5, label: 'Jun' },
  { value: 6, label: 'Jul' },
  { value: 7, label: 'Aug' },
  { value: 8, label: 'Sept' },
  { value: 9, label: 'Oct' },
  { value: 10, label: 'Nov' },
  { value: 11, label: 'Dec' },
];

export const browserDate = (dateString: string | undefined) => {
  if (dateString) {
    let newDate = dateString.replace(/-/g, '/');
    let n = newDate.indexOf('.');
    newDate = newDate.substring(0, n != -1 ? n : newDate.length);
    return newDate;
  }
  return '';
};
