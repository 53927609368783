import React from 'react';
import {AppRouteGroupProps, AppRouteProps, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {reportingPaths} from './paths';
import {PermissionLevel} from '../../types/common/Permissions';

const sectionBreadcrumbs = [new BreadcrumbItemProps("Reporting")];

const reportingRoutes = [
  new AppRouteProps("Student Activity", reportingPaths.StudentActivity, React.lazy(() => import('./StudentActivity')), sectionBreadcrumbs),
  new AppRouteProps("Work Shadow Activity", reportingPaths.WorkShadowActivity, React.lazy(() => import('./WorkShadowActivity')), sectionBreadcrumbs),
  new AppRouteProps("Student Activity(Hidden)", reportingPaths.HiddenStudentActivity, React.lazy(() => import('./HiddenStudentActivity')), sectionBreadcrumbs),
]

const reportingRoutesConfig = [
  new AppRouteGroupProps(PermissionLevel.ADVISOR, reportingRoutes)
];

export default reportingRoutesConfig;
