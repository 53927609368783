import {
  ICommonLoadActionType,
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonSearchActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IVolunteerSearchParamsModel} from '../../types/requests/VolunteerSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {IVolunteerPersonModel} from '../../types/views/VolunteerPersonModel';
import {PersonModel} from '../../types/models/PersonModel';
import {VolunteerPersonEditModel} from '../../types/requests/VolunteerPersonEditModel';
import {PersonSearchResultModel} from '../../types/views/PersonSearchResultModel';

export interface IVolunteerActionTypes extends ICommonSaveActionType {
  REFRESH: string;
}

export const VOLUNTEER: IVolunteerActionTypes = {
  LOAD: 'volunteer/load',
  LOAD_ERROR: 'volunteer/loadError',
  LOADED: 'volunteer/loadSuccess',
  REFRESH: 'volunteer/refresh',
  SAVE: 'volunteer/save',
  SAVED: 'volunteer/saveSuccess',
  SAVE_ERROR: 'volunteer/saveError',
};

export const VOLUNTEER_SEARCH: ICommonSearchActionType = {
  ERROR: 'volunteerSearch/error',
  LOAD: 'volunteerSearch/load',
  LOADED: 'volunteerSearch/loaded',
  REFRESH: 'volunteerSearch/refresh',
  CHANGE_VIEW: 'volunteerSearch/changeView',
};

export const volunteerLoad = createAction<string>(VOLUNTEER.LOAD);
export const volunteerRefresh = createAction<string>(VOLUNTEER.REFRESH);
export const volunteerLoadSuccess = createAction<PersonModel>(VOLUNTEER.LOADED);
export const volunteerLoadError = createAction<string>(VOLUNTEER.LOAD_ERROR);

export const volunteerSave = createAction<ICommonSavePayload<VolunteerPersonEditModel>>(VOLUNTEER.SAVE);
export const volunteerSaveSuccess = createAction<PersonModel>(VOLUNTEER.SAVED);
export const volunteerSaveError = createAction<string>(VOLUNTEER.SAVE_ERROR);

export const volunteersSearch = createAction<ISearchRequest<IVolunteerSearchParamsModel>>(VOLUNTEER_SEARCH.LOAD);
export const volunteersSearchView = createAction<string>(VOLUNTEER_SEARCH.CHANGE_VIEW);
export const volunteersSearchRefresh = createAction<ISearchRequest<IVolunteerSearchParamsModel>>(VOLUNTEER_SEARCH.REFRESH);
export const volunteersSearchError = createAction<string>(VOLUNTEER_SEARCH.ERROR);
export const volunteersSearchSuccess = createAction<IPagedResult<PersonSearchResultModel>>(VOLUNTEER_SEARCH.LOADED);