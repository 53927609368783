import {FileMetaModelContentType} from './Enums';

export interface IFileUploadMetaModel extends IFileMetaModel {
  uppyId?: string | undefined;
}


export class FileMetaModel implements IFileMetaModel {
  /** Universal file id */
  fileId?: string | undefined;
  /** File size */
  fileSize?: number | undefined;
  /** The id of the person who uploaded or sent this file */
  createdByPersonId?: string | undefined;
  /** Mime type of the file */
  mimeType?: string | undefined;
  /** The presentation filename */
  originalFilename?: string | undefined;
  /** File extension */
  fileType?: string | undefined;
  /** What the file is for */
  contentType?: FileMetaModelContentType | undefined;
  /** Short content extracted from the text in the file */
  abstract?: string | undefined;
  /** Date file was uploaded */
  dateCreated?: Date | undefined;
  /** True if the file has not yet been added to the related entity */
  isTemporary?: boolean | undefined;
  /** Can be set to prevent file being discoverable */
  isPrivate?: boolean | undefined;
  /** Any tags added to the file */
  tags?: string[] | undefined;
  /** Company this file relates to */
  companyId?: string | undefined;
  /** Company pool */
  poolId?: string | undefined;
  /** Library file category */
  categoryId?: string | undefined;
  /** File uploaded to a student or contact */
  personId?: string | undefined;
  /** Related thread */
  threadId?: string | undefined;
  /** Activity event related */
  eventId?: string | undefined;
  /** If this file belongs to a user (outside of CRM) */
  userId?: string | undefined;

  constructor(data?: IFileMetaModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.fileId = Data["fileId"];
      this.fileSize = Data["fileSize"];
      this.createdByPersonId = Data["createdByPersonId"];
      this.mimeType = Data["mimeType"];
      this.originalFilename = Data["originalFilename"];
      this.fileType = Data["fileType"];
      this.contentType = Data["contentType"];
      this.abstract = Data["abstract"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.isTemporary = Data["isTemporary"];
      this.isPrivate = Data["isPrivate"];
      if (Array.isArray(Data["tags"])) {
        this.tags = [] as any;
        for (let item of Data["tags"])
          this.tags!.push(item);
      }
      this.companyId = Data["companyId"];
      this.poolId = Data["poolId"];
      this.categoryId = Data["categoryId"];
      this.personId = Data["personId"];
      this.threadId = Data["threadId"];
      this.eventId = Data["eventId"];
      this.userId = Data["userId"];
    }
  }

  static fromJS(data: any): FileMetaModel {
    data = typeof data === 'object' ? data : {};
    let result = new FileMetaModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["fileId"] = this.fileId;
    data["fileSize"] = this.fileSize;
    data["createdByPersonId"] = this.createdByPersonId;
    data["mimeType"] = this.mimeType;
    data["originalFilename"] = this.originalFilename;
    data["fileType"] = this.fileType;
    data["contentType"] = this.contentType;
    data["abstract"] = this.abstract;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["isTemporary"] = this.isTemporary;
    data["isPrivate"] = this.isPrivate;
    if (Array.isArray(this.tags)) {
      data["tags"] = [];
      for (let item of this.tags)
        data["tags"].push(item);
    }
    data["companyId"] = this.companyId;
    data["poolId"] = this.poolId;
    data["categoryId"] = this.categoryId;
    data["personId"] = this.personId;
    data["threadId"] = this.threadId;
    data["eventId"] = this.eventId;
    data["userId"] = this.userId;
    return data;
  }
}

export interface IFileMetaModel {
  /** Universal file id */
  fileId?: string | undefined;
  /** File size */
  fileSize?: number | undefined;
  /** The id of the person who uploaded or sent this file */
  createdByPersonId?: string | undefined;
  /** Mime type of the file */
  mimeType?: string | undefined;
  /** The presentation filename */
  originalFilename?: string | undefined;
  /** File extension */
  fileType?: string | undefined;
  /** What the file is for */
  contentType?: FileMetaModelContentType | undefined;
  /** Short content extracted from the text in the file */
  abstract?: string | undefined;
  /** Date file was uploaded */
  dateCreated?: Date | undefined;
  /** True if the file has not yet been added to the related entity */
  isTemporary?: boolean | undefined;
  /** Can be set to prevent file being discoverable */
  isPrivate?: boolean | undefined;
  /** Any tags added to the file */
  tags?: string[] | undefined;
  /** Company this file relates to */
  companyId?: string | undefined;
  /** Company pool */
  poolId?: string | undefined;
  /** Library file category */
  categoryId?: string | undefined;
  /** File uploaded to a student or contact */
  personId?: string | undefined;
  /** Related thread */
  threadId?: string | undefined;
  /** Activity event related */
  eventId?: string | undefined;
  /** If this file belongs to a user (outside of CRM) */
  userId?: string | undefined;
}
