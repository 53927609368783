export class HSPPoolContactPersonModel implements IHSPPoolContactPersonModel {
  dateCreated?: Date | undefined;
  isPrimary?: boolean | undefined;
  contactPersonId?: string | undefined;

  constructor(data?: IHSPPoolContactPersonModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.isPrimary = Data["isPrimary"];
      this.contactPersonId = Data["contactPersonId"];
    }
  }

  static fromJS(data: any): HSPPoolContactPersonModel {
    data = typeof data === 'object' ? data : {};
    let result = new HSPPoolContactPersonModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["isPrimary"] = this.isPrimary;
    data["contactPersonId"] = this.contactPersonId;
    return data;
  }
}

export interface IHSPPoolContactPersonModel {
  dateCreated?: Date | undefined;
  isPrimary?: boolean | undefined;
  contactPersonId?: string | undefined;
}