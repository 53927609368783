import {AddressEditModel} from '../requests/AddressEditModel';
import {PlacementSearchResultModel} from '../models/PlacementSearchResultModel';
import {TimelineStepIndexModel} from './TimelineStepIndexModel';


export class InternshipStudentSearchResult implements IInternshipStudentSearchResult {
    firstName?: string | undefined;
    lastName?: string | undefined;
    id?: string | undefined;
    email?: string | undefined;
    onboardingStudentSettingId?: string | undefined;
    onboardingStepsComplete?: number | undefined;
    onboardingPercent?: number | undefined;
    onboardingSteps?: TimelineStepIndexModel[] | undefined;
    onboardingStepIds?: string | undefined;
    graduatingStudentSettingId?: string | undefined;
    graduatingStepsComplete?: number | undefined;
    graduatingPercent?: number | undefined;
    graduatingStepIds?: string[] | undefined;
    graduatingSteps?: TimelineStepIndexModel[] | undefined;
    isActive?: boolean | undefined;
    deactivatedReason?: string | undefined;
    deactivatedNotes?: string | undefined;
    inactivatedReason?: string | undefined;
    inactivatedNotes?: string | undefined;
    isCompleted?: boolean | undefined;
    studentStatus?: string | undefined;
    studentTags?: string[] | undefined;
    dateCompleted?: Date | undefined;
    locationAddress?: AddressEditModel | undefined;
    quickNotes?: string | undefined;
    publicSummary?: string | undefined;
    publicProfile?: string | undefined;
    dateOfBirth?: Date | undefined;
    university?: string | undefined;
    campus?: string | undefined;
    semesterStart?: number | undefined;
    semesterEnd?: number | undefined;
    semesterNumberStart?: number | undefined;
    semesterYearStart?: number | undefined;
    semesterYearEnd?: number | undefined;
    semesterNumberEnd?: number | undefined;
    degreeEndIndex?: number | undefined;
    degree?: string | undefined;
    degreePoolId?: string | undefined;
    degreePoolFirst?: string | undefined;
    degreeMajor?: string | undefined;
    advisorUsers?: string | undefined;
    primaryAdvisorPersonId?: string | undefined;
    isAtRisk?: boolean | undefined;
    atRiskByPersonId?: string | undefined;
    atRiskContext?: string | undefined;
    atRiskReasons?: string | undefined;
    atRiskSemester?: number | undefined;
    atRiskYear?: number | undefined;
    atRiskNotes?: string | undefined;
    dateAtRiskSet?: Date | undefined;
    icsStatus?: string | undefined;
    icsId?: string | undefined;
    icsStart?: Date | undefined;
    icsEnd?: Date | undefined;
    icsNotes?: string | undefined;
    dateIcsSet?: Date | undefined;
    isGraduating?: boolean | undefined;
    studentSource?: string | undefined;
    isPreUniversity?: boolean | undefined;
    datePreUniversity?: Date | undefined;
    isUniversityProgram?: boolean | undefined;
    dateUniversityProgram?: Date | undefined;
    allPlacements?: PlacementSearchResultModel[] | undefined;

    constructor(data?: IInternshipStudentSearchResult) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(Data?: any) {
        if (Data) {
            this.firstName = Data["firstName"];
            this.lastName = Data["lastName"];
            this.id = Data["id"];
            this.email = Data["email"];
            this.onboardingStudentSettingId = Data["onboardingStudentSettingId"];
            this.onboardingStepsComplete = Data["onboardingStepsComplete"];
            this.onboardingPercent = Data["onboardingPercent"];
            if (Array.isArray(Data["onboardingSteps"])) {
                this.onboardingSteps = [] as any;
                for (let item of Data["onboardingSteps"])
                    this.onboardingSteps!.push(TimelineStepIndexModel.fromJS(item));
            }
            this.onboardingStepIds = Data["onboardingStepIds"];
            this.graduatingStudentSettingId = Data["graduatingStudentSettingId"];
            this.graduatingStepsComplete = Data["graduatingStepsComplete"];
            this.graduatingPercent = Data["graduatingPercent"];
            if (Array.isArray(Data["graduatingStepIds"])) {
                this.graduatingStepIds = [] as any;
                for (let item of Data["graduatingStepIds"])
                    this.graduatingStepIds!.push(item);
            }
            if (Array.isArray(Data["graduatingSteps"])) {
                this.graduatingSteps = [] as any;
                for (let item of Data["graduatingSteps"])
                    this.graduatingSteps!.push(TimelineStepIndexModel.fromJS(item));
            }
            this.isActive = Data["isActive"];
            this.deactivatedReason = Data["deactivatedReason"];
            this.deactivatedNotes = Data["deactivatedNotes"];
            this.inactivatedReason = Data["inactivatedReason"];
            this.inactivatedNotes = Data["inactivatedNotes"];
            this.isCompleted = Data["isCompleted"];
            this.studentStatus = Data["studentStatus"];
            if (Array.isArray(Data["studentTags"])) {
                this.studentTags = [] as any;
                for (let item of Data["studentTags"])
                    this.studentTags!.push(item);
            }
            this.dateCompleted = Data["dateCompleted"] ? new Date(Data["dateCompleted"].toString()) : <any>undefined;
            this.locationAddress = Data["locationAddress"] ? AddressEditModel.fromJS(Data["locationAddress"]) : <any>undefined;
            this.quickNotes = Data["quickNotes"];
            this.publicSummary = Data["publicSummary"];
            this.publicProfile = Data["publicProfile"];
            this.dateOfBirth = Data["dateOfBirth"] ? new Date(Data["dateOfBirth"].toString()) : <any>undefined;
            this.university = Data["university"];
            this.campus = Data["campus"];
            this.semesterStart = Data["semesterStart"];
            this.semesterEnd = Data["semesterEnd"];
            this.semesterNumberStart = Data["semesterNumberStart"];
            this.semesterYearStart = Data["semesterYearStart"];
            this.semesterYearEnd = Data["semesterYearEnd"];
            this.semesterNumberEnd = Data["semesterNumberEnd"];
            this.degreeEndIndex = Data["degreeEndIndex"];
            this.degree = Data["degree"];
            this.degreePoolId = Data["degreePoolId"];
            this.degreePoolFirst = Data["degreePoolFirst"];
            this.degreeMajor = Data["degreeMajor"];
            this.advisorUsers = Data["advisorUsers"];
            this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
            this.isAtRisk = Data["isAtRisk"];
            this.atRiskByPersonId = Data["atRiskByPersonId"];
            this.atRiskContext = Data["atRiskContext"];
            this.atRiskReasons = Data["atRiskReasons"];
            this.atRiskSemester = Data["atRiskSemester"];
            this.atRiskYear = Data["atRiskYear"];
            this.atRiskNotes = Data["atRiskNotes"];
            this.dateAtRiskSet = Data["dateAtRiskSet"] ? new Date(Data["dateAtRiskSet"].toString()) : <any>undefined;
            this.icsStatus = Data["icsStatus"];
            this.icsId = Data["icsId"];
            this.icsStart = Data["icsStart"] ? new Date(Data["icsStart"].toString()) : <any>undefined;
            this.icsEnd = Data["icsEnd"] ? new Date(Data["icsEnd"].toString()) : <any>undefined;
            this.icsNotes = Data["icsNotes"];
            this.dateIcsSet = Data["dateIcsSet"] ? new Date(Data["dateIcsSet"].toString()) : <any>undefined;
            this.isGraduating = Data["isGraduating"];
            this.studentSource = Data["studentSource"];
            this.isPreUniversity = Data["isPreUniversity"];
            this.datePreUniversity = Data["datePreUniversity"] ? new Date(Data["datePreUniversity"].toString()) : <any>undefined;
            this.isUniversityProgram = Data["isUniversityProgram"];
            this.dateUniversityProgram = Data["dateUniversityProgram"] ? new Date(Data["dateUniversityProgram"].toString()) : <any>undefined;
            if (Array.isArray(Data["allPlacements"])) {
                this.allPlacements = [] as any;
                for (let item of Data["allPlacements"])
                    this.allPlacements!.push(PlacementSearchResultModel.fromJS(item));
            }
        }
    }

    static fromJS(data: any): InternshipStudentSearchResult {
        data = typeof data === 'object' ? data : {};
        let result = new InternshipStudentSearchResult();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["id"] = this.id;
        data["email"] = this.email;
        data["onboardingStudentSettingId"] = this.onboardingStudentSettingId;
        data["onboardingStepsComplete"] = this.onboardingStepsComplete;
        data["onboardingPercent"] = this.onboardingPercent;
        if (Array.isArray(this.onboardingSteps)) {
            data["onboardingSteps"] = [];
            for (let item of this.onboardingSteps)
                data["onboardingSteps"].push(item.toJSON());
        }
        data["onboardingStepIds"] = this.onboardingStepIds;
        data["graduatingStudentSettingId"] = this.graduatingStudentSettingId;
        data["graduatingStepsComplete"] = this.graduatingStepsComplete;
        data["graduatingPercent"] = this.graduatingPercent;
        if (Array.isArray(this.graduatingStepIds)) {
            data["graduatingStepIds"] = [];
            for (let item of this.graduatingStepIds)
                data["graduatingStepIds"].push(item);
        }
        if (Array.isArray(this.graduatingSteps)) {
            data["graduatingSteps"] = [];
            for (let item of this.graduatingSteps)
                data["graduatingSteps"].push(item.toJSON());
        }
        data["isActive"] = this.isActive;
        data["deactivatedReason"] = this.deactivatedReason;
        data["deactivatedNotes"] = this.deactivatedNotes;
        data["inactivatedReason"] = this.inactivatedReason;
        data["inactivatedNotes"] = this.inactivatedNotes;
        data["isCompleted"] = this.isCompleted;
        data["studentStatus"] = this.studentStatus;
        if (Array.isArray(this.studentTags)) {
            data["studentTags"] = [];
            for (let item of this.studentTags)
                data["studentTags"].push(item);
        }
        data["dateCompleted"] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
        data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
        data["quickNotes"] = this.quickNotes;
        data["publicSummary"] = this.publicSummary;
        data["publicProfile"] = this.publicProfile;
        data["dateOfBirth"] = this.dateOfBirth ? this.dateOfBirth.toISOString() : <any>undefined;
        data["university"] = this.university;
        data["campus"] = this.campus;
        data["semesterStart"] = this.semesterStart;
        data["semesterEnd"] = this.semesterEnd;
        data["semesterNumberStart"] = this.semesterNumberStart;
        data["semesterYearStart"] = this.semesterYearStart;
        data["semesterYearEnd"] = this.semesterYearEnd;
        data["semesterNumberEnd"] = this.semesterNumberEnd;
        data["degreeEndIndex"] = this.degreeEndIndex;
        data["degree"] = this.degree;
        data["degreePoolId"] = this.degreePoolId;
        data["degreePoolFirst"] = this.degreePoolFirst;
        data["degreeMajor"] = this.degreeMajor;
        data["advisorUsers"] = this.advisorUsers;
        data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
        data["isAtRisk"] = this.isAtRisk;
        data["atRiskByPersonId"] = this.atRiskByPersonId;
        data["atRiskContext"] = this.atRiskContext;
        data["atRiskReasons"] = this.atRiskReasons;
        data["atRiskSemester"] = this.atRiskSemester;
        data["atRiskYear"] = this.atRiskYear;
        data["atRiskNotes"] = this.atRiskNotes;
        data["dateAtRiskSet"] = this.dateAtRiskSet ? this.dateAtRiskSet.toISOString() : <any>undefined;
        data["icsStatus"] = this.icsStatus;
        data["icsId"] = this.icsId;
        data["icsStart"] = this.icsStart ? this.icsStart.toISOString() : <any>undefined;
        data["icsEnd"] = this.icsEnd ? this.icsEnd.toISOString() : <any>undefined;
        data["icsNotes"] = this.icsNotes;
        data["dateIcsSet"] = this.dateIcsSet ? this.dateIcsSet.toISOString() : <any>undefined;
        data["isGraduating"] = this.isGraduating;
        data["studentSource"] = this.studentSource;
        data["isPreUniversity"] = this.isPreUniversity;
        data["datePreUniversity"] = this.datePreUniversity ? this.datePreUniversity.toISOString() : <any>undefined;
        data["isUniversityProgram"] = this.isUniversityProgram;
        data["dateUniversityProgram"] = this.dateUniversityProgram ? this.dateUniversityProgram.toISOString() : <any>undefined;
        if (Array.isArray(this.allPlacements)) {
            data["allPlacements"] = [];
            for (let item of this.allPlacements)
                data["allPlacements"].push(item.toJSON());
        }
        return data;
    }
}

export interface IInternshipStudentSearchResult {
    firstName?: string | undefined;
    lastName?: string | undefined;
    id?: string | undefined;
    email?: string | undefined;
    onboardingStudentSettingId?: string | undefined;
    onboardingStepsComplete?: number | undefined;
    onboardingPercent?: number | undefined;
    onboardingSteps?: TimelineStepIndexModel[] | undefined;
    onboardingStepIds?: string | undefined;
    graduatingStudentSettingId?: string | undefined;
    graduatingStepsComplete?: number | undefined;
    graduatingPercent?: number | undefined;
    graduatingStepIds?: string[] | undefined;
    graduatingSteps?: TimelineStepIndexModel[] | undefined;
    isActive?: boolean | undefined;
    deactivatedReason?: string | undefined;
    deactivatedNotes?: string | undefined;
    inactivatedReason?: string | undefined;
    inactivatedNotes?: string | undefined;
    isCompleted?: boolean | undefined;
    studentStatus?: string | undefined;
    studentTags?: string[] | undefined;
    dateCompleted?: Date | undefined;
    locationAddress?: AddressEditModel | undefined;
    quickNotes?: string | undefined;
    publicSummary?: string | undefined;
    publicProfile?: string | undefined;
    dateOfBirth?: Date | undefined;
    university?: string | undefined;
    campus?: string | undefined;
    semesterStart?: number | undefined;
    semesterEnd?: number | undefined;
    semesterNumberStart?: number | undefined;
    semesterYearStart?: number | undefined;
    semesterYearEnd?: number | undefined;
    semesterNumberEnd?: number | undefined;
    degreeEndIndex?: number | undefined;
    degree?: string | undefined;
    degreePoolId?: string | undefined;
    degreePoolFirst?: string | undefined;
    degreeMajor?: string | undefined;
    advisorUsers?: string | undefined;
    primaryAdvisorPersonId?: string | undefined;
    isAtRisk?: boolean | undefined;
    atRiskByPersonId?: string | undefined;
    atRiskContext?: string | undefined;
    atRiskReasons?: string | undefined;
    atRiskSemester?: number | undefined;
    atRiskYear?: number | undefined;
    atRiskNotes?: string | undefined;
    dateAtRiskSet?: Date | undefined;
    icsStatus?: string | undefined;
    icsId?: string | undefined;
    icsStart?: Date | undefined;
    icsEnd?: Date | undefined;
    icsNotes?: string | undefined;
    dateIcsSet?: Date | undefined;
    isGraduating?: boolean | undefined;
    studentSource?: string | undefined;
    isPreUniversity?: boolean | undefined;
    datePreUniversity?: Date | undefined;
    isUniversityProgram?: boolean | undefined;
    dateUniversityProgram?: Date | undefined;
    allPlacements?: PlacementSearchResultModel[] | undefined;
}