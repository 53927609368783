import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface MailingListPaths {
  MailingListSearch: string,
  MailingListEdit: string,
  MailingListProfile: string,
}

export const mailingListPaths: MailingListPaths = {
  MailingListSearch: '/advisor/mailingList/search',
  MailingListEdit: '/advisor/mailingList/edit/:id',
  MailingListProfile: '/advisor/mailingList/profile/:id',
};

export interface IMailingListProfileTabs {
  activity: RouteTabParameter;
  description: RouteTabParameter;
  recipients: RouteTabParameter;
  messages: RouteTabParameter;
}

export const mailingListProfileTabs: IMailingListProfileTabs = {
  activity: new RouteTabParameter(0, 'Activity', 'activity'),
  description: new RouteTabParameter(0, 'Description', 'description'),
  recipients: new RouteTabParameter(1, 'Recipients', 'recipients'),
  messages: new RouteTabParameter(2, 'Messages', 'messages'),
}

const profileTabs = [
  mailingListProfileTabs.activity,
  mailingListProfileTabs.description,
  mailingListProfileTabs.recipients,
  mailingListProfileTabs.messages
];

export const getMailingListProfileTab = (query: string | undefined | null) => profileTabs.find(x => x.query === query);