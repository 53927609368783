import {PhoneModel} from '../models/PhoneModel';
import {StudentScheduleModel} from '../models/StudentScheduleModel';
import {StudentDetailModel} from '../models/StudentDetailModel';
import {WorkShadowDetailModel} from '../models/WorkShadowDetailModel';
import {AlumniDetailModel} from '../models/AlumniDetailModel';
import {BuddyWorkShadowDetailModel} from '../models/BuddyWorkShadowDetailModel';
import { EngagementInfoDetailModel } from '../models/EngagementInfoDetailModel';

export class EngagementPersonModel implements IEngagementPersonModel {
  id?: string | undefined;
  createdByUserId?: string | undefined;
  dateCreated?: Date | undefined;
  personNumber?: number | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  formattedName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  facebookUrl?: string | undefined;
  linkedinUrl?: string | undefined;
  twitterUrl?: string | undefined;
  instagramUrl?: string | undefined;
  gender?: string | undefined;
  genderPronouns?: string | undefined;
  /** Phone numbers for this student */
  phoneNumbers?: PhoneModel[] | undefined;
  isNoContact?: boolean | undefined;
  dateIsNoContactSet?: Date | undefined;
  isAdvisor?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isBuddyWorkShadow?: boolean | undefined;
  isStudentAppUser?: boolean | undefined;
  dateStudentAppCreated?: Date | undefined;
  dateStudentAppUpdated?: Date | undefined;
  isStudentPortalUser?: boolean | undefined;
  dateStudentPortalCreated?: Date | undefined;
  dateStudentPortalUpdated?: Date | undefined;
  isEmployerPortalUser?: boolean | undefined;
  dateEmployerPortalCreated?: Date | undefined;
  dateEmployerPortalUpdated?: Date | undefined;
  hasSchedule?: boolean | undefined;
  scheduleDetail?: StudentScheduleModel | undefined;
  studentDetail?: StudentDetailModel | undefined;
  workShadowDetail?: WorkShadowDetailModel | undefined;
  alumniDetail?: AlumniDetailModel | undefined;
  engagementInfoDetail?: EngagementInfoDetailModel | undefined;
  buddyWorkShadowDetail?: BuddyWorkShadowDetailModel | undefined;
  picture?: string | undefined;
  isHidden?: boolean | undefined;

  constructor(data?: IEngagementPersonModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.createdByUserId = Data["createdByUserId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.personNumber = Data["personNumber"];
      this.firstName = Data["firstName"];
      this.middleName = Data["middleName"];
      this.lastName = Data["lastName"];
      this.birthName = Data["birthName"];
      this.formattedName = Data["formattedName"];
      this.email = Data["email"];
      this.altEmail = Data["altEmail"];
      this.facebookUrl = Data["facebookUrl"];
      this.linkedinUrl = Data["linkedinUrl"];
      this.twitterUrl = Data["twitterUrl"];
      this.instagramUrl = Data["instagramUrl"];
      this.gender = Data["gender"];
      this.genderPronouns = Data["genderPronouns"];
      if (Array.isArray(Data["phoneNumbers"])) {
        this.phoneNumbers = [] as any;
        for (let item of Data["phoneNumbers"])
          this.phoneNumbers!.push(PhoneModel.fromJS(item));
      }
      this.isNoContact = Data["isNoContact"];
      this.dateIsNoContactSet = Data["dateIsNoContactSet"] ? new Date(Data["dateIsNoContactSet"].toString()) : <any>undefined;
      this.isAdvisor = Data["isAdvisor"];
      this.isClient = Data["isClient"];
      this.isStudent = Data["isStudent"];
      this.isVolunteer = Data["isVolunteer"];
      this.isWorkShadow = Data["isWorkShadow"];
      this.isAlumni = Data["isAlumni"];
      this.isBuddyWorkShadow = Data["isBuddyWorkShadow"];
      this.isStudentAppUser = Data["isStudentAppUser"];
      this.dateStudentAppCreated = Data["dateStudentAppCreated"] ? new Date(Data["dateStudentAppCreated"].toString()) : <any>undefined;
      this.dateStudentAppUpdated = Data["dateStudentAppUpdated"] ? new Date(Data["dateStudentAppUpdated"].toString()) : <any>undefined;
      this.isStudentPortalUser = Data["isStudentPortalUser"];
      this.dateStudentPortalCreated = Data["dateStudentPortalCreated"] ? new Date(Data["dateStudentPortalCreated"].toString()) : <any>undefined;
      this.dateStudentPortalUpdated = Data["dateStudentPortalUpdated"] ? new Date(Data["dateStudentPortalUpdated"].toString()) : <any>undefined;
      this.isEmployerPortalUser = Data["isEmployerPortalUser"];
      this.dateEmployerPortalCreated = Data["dateEmployerPortalCreated"] ? new Date(Data["dateEmployerPortalCreated"].toString()) : <any>undefined;
      this.dateEmployerPortalUpdated = Data["dateEmployerPortalUpdated"] ? new Date(Data["dateEmployerPortalUpdated"].toString()) : <any>undefined;
      this.hasSchedule = Data["hasSchedule"];
      this.scheduleDetail = Data["scheduleDetail"] ? StudentScheduleModel.fromJS(Data["scheduleDetail"]) : <any>undefined;
      this.studentDetail = Data["studentDetail"] ? StudentDetailModel.fromJS(Data["studentDetail"]) : <any>undefined;
      this.workShadowDetail = Data["workShadowDetail"] ? WorkShadowDetailModel.fromJS(Data["workShadowDetail"]) : <any>undefined;
      this.alumniDetail = Data["alumniDetail"] ? AlumniDetailModel.fromJS(Data["alumniDetail"]) : <any>undefined;
      this.engagementInfoDetail = Data["engagementInfoDetail"] ? EngagementInfoDetailModel.fromJS(Data["engagementInfoDetail"]) : <any>undefined;
      this.buddyWorkShadowDetail = Data["buddyWorkShadowDetail"] ? BuddyWorkShadowDetailModel.fromJS(Data["buddyWorkShadowDetail"]) : <any>undefined;
      this.picture = Data["picture"];
      this.isHidden = Data["isHidden"];
    }
  }

  static fromJS(data: any): EngagementPersonModel {
    data = typeof data === 'object' ? data : {};
    let result = new EngagementPersonModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createdByUserId"] = this.createdByUserId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["personNumber"] = this.personNumber;
    data["firstName"] = this.firstName;
    data["middleName"] = this.middleName;
    data["lastName"] = this.lastName;
    data["birthName"] = this.birthName;
    data["formattedName"] = this.formattedName;
    data["email"] = this.email;
    data["altEmail"] = this.altEmail;
    data["facebookUrl"] = this.facebookUrl;
    data["linkedinUrl"] = this.linkedinUrl;
    data["twitterUrl"] = this.twitterUrl;
    data["instagramUrl"] = this.instagramUrl;
    data["gender"] = this.gender;
    data["genderPronouns"] = this.genderPronouns;
    if (Array.isArray(this.phoneNumbers)) {
      data["phoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["phoneNumbers"].push(item.toJSON());
    }
    data["isNoContact"] = this.isNoContact;
    data["dateIsNoContactSet"] = this.dateIsNoContactSet ? this.dateIsNoContactSet.toISOString() : <any>undefined;
    data["isAdvisor"] = this.isAdvisor;
    data["isClient"] = this.isClient;
    data["isStudent"] = this.isStudent;
    data["isVolunteer"] = this.isVolunteer;
    data["isWorkShadow"] = this.isWorkShadow;
    data["isAlumni"] = this.isAlumni;
    data["isBuddyWorkShadow"] = this.isBuddyWorkShadow;
    data["isStudentAppUser"] = this.isStudentAppUser;
    data["dateStudentAppCreated"] = this.dateStudentAppCreated ? this.dateStudentAppCreated.toISOString() : <any>undefined;
    data["dateStudentAppUpdated"] = this.dateStudentAppUpdated ? this.dateStudentAppUpdated.toISOString() : <any>undefined;
    data["isStudentPortalUser"] = this.isStudentPortalUser;
    data["dateStudentPortalCreated"] = this.dateStudentPortalCreated ? this.dateStudentPortalCreated.toISOString() : <any>undefined;
    data["dateStudentPortalUpdated"] = this.dateStudentPortalUpdated ? this.dateStudentPortalUpdated.toISOString() : <any>undefined;
    data["isEmployerPortalUser"] = this.isEmployerPortalUser;
    data["dateEmployerPortalCreated"] = this.dateEmployerPortalCreated ? this.dateEmployerPortalCreated.toISOString() : <any>undefined;
    data["dateEmployerPortalUpdated"] = this.dateEmployerPortalUpdated ? this.dateEmployerPortalUpdated.toISOString() : <any>undefined;
    data["hasSchedule"] = this.hasSchedule;
    data["scheduleDetail"] = this.scheduleDetail ? this.scheduleDetail.toJSON() : <any>undefined;
    data["studentDetail"] = this.studentDetail ? this.studentDetail.toJSON() : <any>undefined;
    data["workShadowDetail"] = this.workShadowDetail ? this.workShadowDetail.toJSON() : <any>undefined;
    data["alumniDetail"] = this.alumniDetail ? this.alumniDetail.toJSON() : <any>undefined;
    data["engagementInfoDetail"] = this.engagementInfoDetail ? this.engagementInfoDetail.toJSON() : <any>undefined;
    data["buddyWorkShadowDetail"] = this.buddyWorkShadowDetail ? this.buddyWorkShadowDetail.toJSON() : <any>undefined;
    data["picture"] = this.picture;
    data["isHidden"] = this.isHidden;
    return data;
  }
}

export interface IEngagementPersonModel {
  id?: string | undefined;
  createdByUserId?: string | undefined;
  dateCreated?: Date | undefined;
  personNumber?: number | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  lastName?: string | undefined;
  birthName?: string | undefined;
  formattedName?: string | undefined;
  email?: string | undefined;
  altEmail?: string | undefined;
  facebookUrl?: string | undefined;
  linkedinUrl?: string | undefined;
  twitterUrl?: string | undefined;
  instagramUrl?: string | undefined;
  gender?: string | undefined;
  genderPronouns?: string | undefined;
  /** Phone numbers for this student */
  phoneNumbers?: PhoneModel[] | undefined;
  isNoContact?: boolean | undefined;
  dateIsNoContactSet?: Date | undefined;
  isAdvisor?: boolean | undefined;
  isClient?: boolean | undefined;
  isStudent?: boolean | undefined;
  isVolunteer?: boolean | undefined;
  isWorkShadow?: boolean | undefined;
  isAlumni?: boolean | undefined;
  isBuddyWorkShadow?: boolean | undefined;
  isStudentAppUser?: boolean | undefined;
  dateStudentAppCreated?: Date | undefined;
  dateStudentAppUpdated?: Date | undefined;
  isStudentPortalUser?: boolean | undefined;
  dateStudentPortalCreated?: Date | undefined;
  dateStudentPortalUpdated?: Date | undefined;
  isEmployerPortalUser?: boolean | undefined;
  dateEmployerPortalCreated?: Date | undefined;
  dateEmployerPortalUpdated?: Date | undefined;
  hasSchedule?: boolean | undefined;
  scheduleDetail?: StudentScheduleModel | undefined;
  studentDetail?: StudentDetailModel | undefined;
  workShadowDetail?: WorkShadowDetailModel | undefined;
  alumniDetail?: AlumniDetailModel | undefined;
  engagementInfoDetail?: EngagementInfoDetailModel | undefined;
  buddyWorkShadowDetail?: BuddyWorkShadowDetailModel | undefined;
  picture?: string | undefined;
  isHidden?: boolean | undefined;
}