import {WorkShadowOnboardingStepModel} from './WorkShadowOnboardingStepModel';

export class WorkShadowOnboardingModel implements IWorkShadowOnboardingModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title!: string;
  /** Description of the template */
  description?: string | undefined;
  /** If we want this to be the default for new workShadows */
  isDefault?: boolean | undefined;
  /** Steps for this template */
  onboardingSteps!: WorkShadowOnboardingStepModel[];
  /** Disabled */
  isDisabled?: boolean | undefined;

  constructor(data?: IWorkShadowOnboardingModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.onboardingSteps = [];
    }
  }

  init(Data?: any) {
    if (Data) {
      this.id = Data["id"];
      this.createdByUserId = Data["createdByUserId"];
      this.title = Data["title"];
      this.description = Data["description"];
      this.isDefault = Data["isDefault"];
      if (Array.isArray(Data["onboardingSteps"])) {
        this.onboardingSteps = [] as any;
        for (let item of Data["onboardingSteps"])
          this.onboardingSteps!.push(WorkShadowOnboardingStepModel.fromJS(item));
      }
      this.isDisabled = Data["isDisabled"];
    }
  }

  static fromJS(data: any): WorkShadowOnboardingModel {
    data = typeof data === 'object' ? data : {};
    let result = new WorkShadowOnboardingModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["createdByUserId"] = this.createdByUserId;
    data["title"] = this.title;
    data["description"] = this.description;
    data["isDefault"] = this.isDefault;
    if (Array.isArray(this.onboardingSteps)) {
      data["onboardingSteps"] = [];
      for (let item of this.onboardingSteps)
        data["onboardingSteps"].push(item.toJSON());
    }
    data["isDisabled"] = this.isDisabled;
    return data;
  }
}

export interface IWorkShadowOnboardingModel {
  /** Key for this template */
  id?: string | undefined;
  /** Created by */
  createdByUserId?: string | undefined;
  /** Title of the template */
  title: string;
  /** Description of the template */
  description?: string | undefined;
  /** If we want this to be the default for new workShadows */
  isDefault?: boolean | undefined;
  /** Steps for this template */
  onboardingSteps: WorkShadowOnboardingStepModel[];
  /** Disabled */
  isDisabled?: boolean | undefined;
}
