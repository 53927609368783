export class SeasonModel implements ISeasonModel {
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonName?: string | undefined;
  seasonDescription?: string | undefined;

  constructor(data?: ISeasonModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.seasonNumber = Data["seasonNumber"];
      this.seasonYear = Data["seasonYear"];
      this.seasonName = Data["seasonName"];
      this.seasonDescription = Data["seasonDescription"];
    }
  }

  static fromJS(data: any): SeasonModel {
    data = typeof data === 'object' ? data : {};
    let result = new SeasonModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["seasonNumber"] = this.seasonNumber;
    data["seasonYear"] = this.seasonYear;
    data["seasonName"] = this.seasonName;
    data["seasonDescription"] = this.seasonDescription;
    return data;
  }
}

export interface ISeasonModel {
  seasonNumber?: number | undefined;
  seasonYear?: number | undefined;
  seasonName?: string | undefined;
  seasonDescription?: string | undefined;
}