export interface ScorecardPaths {
  RecruitmentScorecard: string,
  EngagementScorecard: string,
  InternshipScorecard: string,
  CompanyInternshipScorecard: string,
  HSPEngagementScorecard: string,
}

export const scorecardPaths: ScorecardPaths = {
  RecruitmentScorecard: '/reporting/recruitment',
  EngagementScorecard: '/reporting/engagement',
  InternshipScorecard: '/reporting/internship',
  CompanyInternshipScorecard: '/reporting/companyInternship',
  HSPEngagementScorecard: '/reporting/hsp-engagement',
};
