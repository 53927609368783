import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import AppContext from './AppContext';
import {useAppDispatch, useAuthState} from './AppHooks';
import {Loader} from '../index';
import AppContextPropsType from '../../types/AppContextPropsType';
import {authPaths} from '../../modules/auth/paths';
import {dashboardPaths} from '../../modules/dashboard/paths';
import {selectAuth, selectCurrentUser} from '../../redux/auth/Selectors';
import {checkPermission} from './Utils';
import {
  selectCountriesLoading,
  selectSessionLoading,
} from '../../redux/session/Selectors';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({children}) => {
  const {pathname} = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    routes,
  } = useContext<AppContextPropsType>(AppContext);

  const [initialPath, setInitialPath] = useState(localStorage.getItem('INITIAL_URL'));
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  const { error } = useSelector(selectAuth);
  const currentUser = useSelector(selectCurrentUser);

  const sessionLoading = useSelector(selectSessionLoading);
  const countriesLoading = useSelector(selectCountriesLoading);
  const {loggingIn} = useSelector(selectAuth);


  const [isAuthenticated, isAuthLoading, isInitialising] = useAuthState(currentRoute);

  useEffect(() => {
    console.log('Navigating to', pathname);
    function setInitPath() {
      if (
        [
          authPaths.SignIn,
          authPaths.ForgotPassword,
          authPaths.Error404,
          dashboardPaths.LandingPage
        ].indexOf(pathname) === -1
      ) {
        setInitialPath(pathname);
      }
    }
    setInitPath();
  }, [initialPath, pathname]);

  useEffect(() => {
    let isPermitted = currentUser && checkPermission(currentRoute.auth, currentUser ? currentUser.isManager : false, currentUser ? currentUser.isAdmin : false);
    // console.log(isAuthenticated, 'isAuthenticated', isPermitted, 'isPermitted', isInitialising, 'isInitialising', isAuthLoading, 'isAuthLoading', 'Render route effect');
    if (!isAuthLoading && !isInitialising)
    {
      if (!isAuthenticated) {
        console.log('Redirecting to signin');
        history.push(authPaths.SignIn); // allowed route
      } else if (currentUser && !isPermitted) {
        history.push(authPaths.Error404); // Not found
      }
      else if (currentUser && isPermitted) {
        if (
          pathname === '/' ||
          pathname === authPaths.SignIn
        ) {
          if (
            initialPath &&
            dashboardPaths.LandingPage !== initialPath &&
            initialPath !== '/'
          ) {
            console.log('Redirecting to initial path');
            history.push(initialPath);
          } else {
            console.log('Redirecting to landing page path');
            history.push(dashboardPaths.LandingPage);
          }
        }
      }
    }
  }, [currentUser, initialPath, pathname, history, error, isAuthLoading, isAuthenticated, isInitialising, sessionLoading, countriesLoading]);
  return (isAuthLoading || isInitialising || loggingIn || ((!currentUser || !isAuthenticated) && !!currentRoute.auth)) ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
