import {createAction} from '@reduxjs/toolkit';
import {ICommonLoadActionType, ICommonSaveActionType, ICommonSavePayload} from '../Common.actions';
import {PersonModel} from '../../types/models/PersonModel';
import {StudentEditModel} from '../../types/requests/StudentEditModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IStudentSearchParamsModel} from '../../types/requests/StudentSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {STUDENT, STUDENT_SEARCH} from '../students/Actions';
import {BreakSettingsModel, IBreakSettingsModel} from '../../types/requests/BreakSettingsModel';

// TODO: Break settings, file tags and email templates
export interface SettingsActionTypes {
  STUDENT_TAG_SAVE: string;
  STUDENT_TAG_DELETE: string;
  CLIENT_TAG_SAVE: string;
  CLIENT_TAG_DELETE: string;
  PARTNER_TAG_SAVE: string;
  PARTNER_TAG_DELETE: string;
  CONTACT_TAG_SAVE: string;
  CONTACT_TAG_DELETE: string;
  VOLUNTEER_TAG_SAVE: string;
  VOLUNTEER_TAG_DELETE: string;
  WORK_SHADOW_TAG_SAVE: string;
  WORK_SHADOW_TAG_DELETE: string;
}

export interface IBreakSettingActionTypes extends ICommonSaveActionType {
  REFRESH: string;
  DELETE: string;
}

export interface BreakSettingsListActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}


export const SETTINGS: SettingsActionTypes = {
  STUDENT_TAG_SAVE: 'settings/studentTagSave',
  STUDENT_TAG_DELETE: 'settings/studentTagDelete',
  CLIENT_TAG_SAVE: 'settings/clientTagSave',
  CLIENT_TAG_DELETE: 'settings/clientTagDelete',
  PARTNER_TAG_SAVE: 'settings/partnerTagSave',
  PARTNER_TAG_DELETE: 'settings/partnerTagDelete',
  CONTACT_TAG_SAVE: 'settings/contactTagSave',
  CONTACT_TAG_DELETE: 'settings/contactTagDelete',
  VOLUNTEER_TAG_SAVE: 'settings/volunteerTagSave',
  VOLUNTEER_TAG_DELETE: 'settings/volunteerTagDelete',
  WORK_SHADOW_TAG_SAVE: 'settings/workShadowTagSave',
  WORK_SHADOW_TAG_DELETE: 'settings/workShadowTagDelete'
}

export const BREAK_SETTINGS: IBreakSettingActionTypes = {
  LOAD: 'breakSettings/load',
  LOAD_ERROR: 'breakSettings/loadError',
  LOADED: 'breakSettings/loadSuccess',
  REFRESH: 'breakSettings/refresh',
  SAVE: 'breakSettings/save',
  SAVED: 'breakSettings/saveSuccess',
  SAVE_ERROR: 'breakSettings/saveError',
  DELETE: 'breakSettings/delete',
}

export const BREAK_SETTINGS_LIST: BreakSettingsListActionTypes = {
  ERROR: 'breakSettingsList/error',
  LOAD: 'breakSettingsList/load',
  LOADED: 'breakSettingsList/loaded',
  REFRESH: 'breakSettingsList/refresh',
}

export const settingsStudentTagSave = createAction<string>(SETTINGS.STUDENT_TAG_SAVE);
export const settingsStudentTagDelete = createAction<string>(SETTINGS.STUDENT_TAG_DELETE);
export const settingsClientTagSave = createAction<string>(SETTINGS.CLIENT_TAG_SAVE);
export const settingsClientTagDelete = createAction<string>(SETTINGS.CLIENT_TAG_DELETE);
export const settingsPartnerTagSave = createAction<string>(SETTINGS.PARTNER_TAG_SAVE);
export const settingsPartnerTagDelete = createAction<string>(SETTINGS.PARTNER_TAG_DELETE);
export const settingsContactTagSave = createAction<string>(SETTINGS.CONTACT_TAG_SAVE);
export const settingsContactTagDelete = createAction<string>(SETTINGS.CONTACT_TAG_DELETE);
export const settingsVolunteerTagSave = createAction<string>(SETTINGS.VOLUNTEER_TAG_SAVE);
export const settingsVolunteerTagDelete = createAction<string>(SETTINGS.VOLUNTEER_TAG_DELETE);
export const settingsWorkShadowTagSave = createAction<string>(SETTINGS.WORK_SHADOW_TAG_SAVE);
export const settingsWorkShadowTagDelete = createAction<string>(SETTINGS.WORK_SHADOW_TAG_DELETE);

export const breakSettingsLoad = createAction<string>(BREAK_SETTINGS.LOAD);
export const breakSettingsRefresh = createAction<string>(BREAK_SETTINGS.REFRESH);
export const breakSettingsLoadSuccess = createAction<BreakSettingsModel>(BREAK_SETTINGS.LOADED);
export const breakSettingsLoadError = createAction<string>(BREAK_SETTINGS.LOAD_ERROR);

export const breakSettingsSave = createAction<ICommonSavePayload<BreakSettingsModel>>(BREAK_SETTINGS.SAVE);
export const breakSettingsSaveSuccess = createAction<BreakSettingsModel>(BREAK_SETTINGS.SAVED);
export const breakSettingsSaveError = createAction<string>(BREAK_SETTINGS.SAVE_ERROR);
export const breakSettingsDelete = createAction<string>(BREAK_SETTINGS.DELETE);

export const breakSettingsListSearch = createAction(BREAK_SETTINGS_LIST.LOAD);
export const breakSettingsListSearchRefresh = createAction(BREAK_SETTINGS_LIST.REFRESH);
export const breakSettingsListSearchError = createAction<string>(BREAK_SETTINGS_LIST.ERROR);
export const breakSettingsListSearchSuccess = createAction<IBreakSettingsModel[]>(BREAK_SETTINGS_LIST.LOADED);
