import React from 'react';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import Person from '@mui/icons-material/Person';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {personCacheAdd} from '../../../redux/peopleCache/Actions';
import {selectPersonColor} from '../../../@template/helpers/profileColour';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import defaultConfig from '../../../@template/utility/ContextProvider/defaultConfig';
import Avatar from '@mui/material/Avatar';
import {Tooltip} from '@mui/material';
import {AppState} from '../../../redux/store';
import {PersonListItem} from '../../../types/views/PersonListItem';


interface IPersonIconProps {
  personId: string,
  size?: number | undefined,
  overrideColor?: string | undefined,
  onClick?(): void | undefined,
}

const getCacheItem = (id: string) => (state: AppState) => {
  return state.peopleCache.personCache.find((x: PersonListItem) => x.id === id);
}

const PersonAvatar = ({ personId, onClick, size = 20, overrideColor }: IPersonIconProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [ avatarPersonId ] = useState(personId);

  const listItem = useSelector(getCacheItem(avatarPersonId));

  useEffect(() => {
    if (!listItem && avatarPersonId) {
      dispatch(personCacheAdd([avatarPersonId]));
    }
  }, [ avatarPersonId ]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const color = defaultConfig.theme.palette.secondary;

  const avatarRender = (firstName: string, lastName: string, initials:string, picture: string | undefined) => {
    const backgroundColorCode = !!overrideColor ? overrideColor : selectPersonColor(initials);
    const backgroundColor = alpha(backgroundColorCode??'#efefef', 0.1);
    const fontColor = overrideColor ? overrideColor : selectPersonColor(initials);
    if(picture) {
      return (<Avatar alt={`${firstName} ${lastName}`} src={listItem.picture} sx={{ width: size, height: size}} onClick={(e) => handleClick(e)} />)
    } else {
      return (
        <Avatar alt={`${firstName} ${lastName}`} sx={{ bgcolor: backgroundColor, color: fontColor, width: size, height: size }} onClick={(e) => handleClick(e)}>
          {initials}
        </Avatar>
      )
    }
  }

  if (listItem) {
    let { firstName, lastName, picture } = listItem;
    if (!firstName) {
      firstName = '?';
    }
    if (!lastName){
      lastName = '?';
    }
    const initials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

    return (
      <Tooltip placement="bottom" title={`${firstName} ${lastName}`}>
        {avatarRender(firstName, lastName, initials, picture)}
      </Tooltip>
    );
  }
  return <Avatar onClick={(e) => handleClick(e)} sx={{ bgcolor: '#dfdfdf', width: size, height: size }}><Person /></Avatar>;
};

export default PersonAvatar;