export class TimelineStepIndexModel implements ITimelineStepIndexModel {
  stepId?: string | undefined;
  dateCreated?: Date | undefined;
  stepTitle?: string | undefined;
  eventPersonId?: string | undefined;
  skipped?: boolean | undefined;

  constructor(data?: ITimelineStepIndexModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.stepId = Data["stepId"];
      this.dateCreated = Data["dateCreated"] ? new Date(Data["dateCreated"].toString()) : <any>undefined;
      this.stepTitle = Data["stepTitle"];
      this.eventPersonId = Data["eventPersonId"];
      this.skipped = Data["skipped"];
    }
  }

  static fromJS(data: any): TimelineStepIndexModel {
    data = typeof data === 'object' ? data : {};
    let result = new TimelineStepIndexModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["stepId"] = this.stepId;
    data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data["stepTitle"] = this.stepTitle;
    data["eventPersonId"] = this.eventPersonId;
    data["skipped"] = this.skipped;
    return data;
  }
}

export interface ITimelineStepIndexModel {
  stepId?: string | undefined;
  dateCreated?: Date | undefined;
  stepTitle?: string | undefined;
  eventPersonId?: string | undefined;
  skipped?: boolean | undefined;
}
