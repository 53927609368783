import {
  ICommonLoadActionType,
  ICommonSaveActionType,
  ICommonSavePayload,
  ICommonSearchActionType,
} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IStudentSearchParamsModel} from '../../types/requests/StudentSearchParamsModel';
import {IPagedResult} from '../../types/common/PagedResult';
import {PersonModel} from '../../types/models/PersonModel';
import {IStudentPoolItem} from '../../types/views/StudentPoolItem';
import {IStudentAchievementModel} from '../../types/models/StudentAchievementModel';
import {StudentEditModel} from '../../types/requests/StudentEditModel';
import {IPlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {IInternshipSearchParams} from '../../types/requests/InternshipSearchParams';
import {IInternshipStudentSearchResult,} from '../../types/views/InternshipStudentSearchResult';
import {EventItemViewModel} from '../../types/models/EventItemViewModel';
import {StudentProfileEditModel} from '../../types/requests/StudentProfileEditModel';
import {IStudentOpportunityListView} from '../../types/views/StudentOpportunityListView';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {PersonSearchResultModel} from '../../types/views/PersonSearchResultModel';
import {RollbackStudentEditModel} from '../../types/requests/RollbackStudentEditModel';


export interface IStudentActionTypes extends ICommonSaveActionType {
  REFRESH: string;
  ROLLBACK: string;
}

export interface IEmployerProfileActionTypes extends ICommonSaveActionType {
  REFRESH: string;
  STUDENT_VIEW_LOAD: string;
  STUDENT_VIEW_LOADED: string;
  STUDENT_VIEW_ERROR: string;
}

export interface IStudentListActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const STUDENT: IStudentActionTypes = {
  LOAD: 'student/load',
  LOAD_ERROR: 'session/loadError',
  LOADED: 'session/loadSuccess',
  REFRESH: 'student/refresh',
  SAVE: 'student/save',
  ROLLBACK: 'student/rollback',
  SAVED: 'student/saveSuccess',
  SAVE_ERROR: 'student/saveError',
};

export const STUDENT_SEARCH: ICommonSearchActionType = {
  ERROR: 'studentSearch/error',
  LOAD: 'studentSearch/load',
  LOADED: 'studentSearch/loaded',
  REFRESH: 'studentSearch/refresh',
  CHANGE_VIEW: 'studentSearch/changeView',
};

export const STUDENT_STUDENT_POOLS: IStudentListActionTypes = {
  ERROR: 'student/studentPools/error',
  LOAD: 'student/studentPools/load',
  LOADED: 'student/studentPools/loaded',
  REFRESH: 'student/studentPools/refresh'
};

export const STUDENT_OPPORTUNITIES: IStudentListActionTypes = {
  ERROR: 'student/studentOpportunities/error',
  LOAD: 'student/studentOpportunities/load',
  LOADED: 'student/studentOpportunities/loaded',
  REFRESH: 'student/studentOpportunities/refresh'
};

export const STUDENT_PLACEMENTS: IStudentListActionTypes = {
  ERROR: 'student/placements/error',
  LOAD: 'student/placements/load',
  LOADED: 'student/placements/loaded',
  REFRESH: 'student/placements/refresh'
};

export const STUDENT_ACHIEVEMENTS: ICommonLoadActionType = {
  ERROR: 'student/achievements/error',
  LOAD: 'student/achievements/load',
  LOADED: 'student/achievements/loadSuccess'
};

export const STUDENT_LEARNING_CONTRACTS: IStudentListActionTypes = {
  LOAD: 'learningContracts/load',
  LOADED: 'learningContracts/loadSuccess',
  ERROR: 'learningContracts/error',
  REFRESH: 'learningContracts/refresh',
}

export const PLACEMENT_SEARCH: ICommonSearchActionType = {
  ERROR: 'placementSearch/error',
  LOAD: 'placementSearch/load',
  LOADED: 'placementSearch/loaded',
  REFRESH: 'placementSearch/refresh',
  CHANGE_VIEW: 'placementSearch/changeView',
}

export const EMPLOYER_PUBLIC_PROFILE: IEmployerProfileActionTypes = {
  LOAD: 'employerProfile/load',
  LOAD_ERROR: 'employerProfile/loadError',
  LOADED: 'employerProfile/loadSuccess',
  REFRESH: 'employerProfile/refresh',
  SAVE: 'employerProfile/save',
  SAVED: 'employerProfile/saveSuccess',
  SAVE_ERROR: 'employerProfile/saveError',
  STUDENT_VIEW_LOAD: 'employerProfile/loadViewStudent',
  STUDENT_VIEW_LOADED: 'employerProfile/loadViewStudentSuccess',
  STUDENT_VIEW_ERROR: 'employerProfile/loadViewStudentError',
}

export const MY_STUDENTS: ICommonLoadActionType = {
  LOAD: 'myStudents/load',
  ERROR: 'myStudents/error',
  LOADED: 'myStudents/success',
}

export const studentLoad = createAction<string>(STUDENT.LOAD);
export const studentRefresh = createAction<string>(STUDENT.REFRESH);
export const studentLoadSuccess = createAction<PersonModel>(STUDENT.LOADED);
export const studentLoadError = createAction<string>(STUDENT.LOAD_ERROR);

export const studentSave = createAction<ICommonSavePayload<StudentEditModel>>(STUDENT.SAVE);
export const studentRollback = createAction<ICommonSavePayload<RollbackStudentEditModel>>(STUDENT.ROLLBACK);
export const studentSaveSuccess = createAction<PersonModel>(STUDENT.SAVED);
export const studentSaveError = createAction<string>(STUDENT.SAVE_ERROR);

export const studentsSearch = createAction<ISearchRequest<IStudentSearchParamsModel>>(STUDENT_SEARCH.LOAD);
export const studentsSearchView = createAction<string>(STUDENT_SEARCH.CHANGE_VIEW);
export const studentsSearchRefresh = createAction<ISearchRequest<IStudentSearchParamsModel>>(STUDENT_SEARCH.REFRESH);
export const studentsSearchError = createAction<string>(STUDENT_SEARCH.ERROR);
export const studentsSearchSuccess = createAction<IPagedResult<PersonSearchResultModel>>(STUDENT_SEARCH.LOADED);

export const studentPoolsLoad = createAction<string>(STUDENT_STUDENT_POOLS.LOAD);
export const studentPoolsRefresh = createAction<string>(STUDENT_STUDENT_POOLS.REFRESH);
export const studentPoolsLoadSuccess = createAction<IStudentPoolItem[]>(STUDENT_STUDENT_POOLS.LOADED);
export const studentPoolsLoadError = createAction<string>(STUDENT_STUDENT_POOLS.ERROR);

export const studentOpportunitiesLoad = createAction<string>(STUDENT_OPPORTUNITIES.LOAD);
export const studentOpportunitiesRefresh = createAction<string>(STUDENT_OPPORTUNITIES.REFRESH);
export const studentOpportunitiesLoadSuccess = createAction<IStudentOpportunityListView[]>(STUDENT_OPPORTUNITIES.LOADED);
export const studentOpportunitiesLoadError = createAction<string>(STUDENT_OPPORTUNITIES.ERROR);

export const studentPlacementsLoad = createAction<string>(STUDENT_PLACEMENTS.LOAD);
export const studentPlacementsRefresh = createAction<string>(STUDENT_PLACEMENTS.REFRESH);
export const studentPlacementsLoadSuccess = createAction<IPlacementDetailModel[]>(STUDENT_PLACEMENTS.LOADED);
export const studentPlacementsLoadError = createAction<string>(STUDENT_PLACEMENTS.ERROR);

export const studentLearningContractsList = createAction<string>(STUDENT_LEARNING_CONTRACTS.LOAD);
export const studentLearningContractsRefresh = createAction<string>(STUDENT_LEARNING_CONTRACTS.REFRESH);
export const studentLearningContractsListSuccess = createAction<EventItemViewModel[]>(STUDENT_LEARNING_CONTRACTS.LOADED);
export const studentLearningContractsListError = createAction<string>(STUDENT_LEARNING_CONTRACTS.ERROR);

export const studentAchievementsLoad = createAction<string>(STUDENT_ACHIEVEMENTS.LOAD);
export const studentAchievementsLoadSuccess = createAction<IStudentAchievementModel[]>(STUDENT_ACHIEVEMENTS.LOADED);
export const studentAchievementsLoadError = createAction<string>(STUDENT_ACHIEVEMENTS.ERROR);

export const placementSearch = createAction<ISearchRequest<IInternshipSearchParams>>(PLACEMENT_SEARCH.LOAD);
export const placementSearchView = createAction<string>(PLACEMENT_SEARCH.CHANGE_VIEW);
export const placementSearchRefresh = createAction<ISearchRequest<IInternshipSearchParams>>(PLACEMENT_SEARCH.REFRESH);
export const placementSearchError = createAction<string>(PLACEMENT_SEARCH.ERROR);
export const placementSearchSuccess = createAction<IPagedResult<IInternshipStudentSearchResult>>(PLACEMENT_SEARCH.LOADED);

export const employerProfileLoad = createAction<string>(EMPLOYER_PUBLIC_PROFILE.LOAD);
export const employerProfileRefresh = createAction<string>(EMPLOYER_PUBLIC_PROFILE.REFRESH);
export const employerProfileLoadSuccess = createAction<PersonModel>(EMPLOYER_PUBLIC_PROFILE.LOADED);
export const employerProfileLoadError = createAction<string>(EMPLOYER_PUBLIC_PROFILE.LOAD_ERROR);

export const employerProfileSave = createAction<ICommonSavePayload<StudentProfileEditModel>>(EMPLOYER_PUBLIC_PROFILE.SAVE);
export const employerProfileSaveSuccess = createAction<PersonModel>(EMPLOYER_PUBLIC_PROFILE.SAVED);
export const employerProfileSaveError = createAction<string>(EMPLOYER_PUBLIC_PROFILE.SAVE_ERROR);

export const employerProfileViewStudentLoad = createAction<string>(EMPLOYER_PUBLIC_PROFILE.STUDENT_VIEW_LOAD);
export const employerProfileViewStudentLoadSuccess = createAction<PersonModel>(EMPLOYER_PUBLIC_PROFILE.STUDENT_VIEW_LOADED);
export const employerProfileViewStudentLoadError = createAction<string>(EMPLOYER_PUBLIC_PROFILE.STUDENT_VIEW_ERROR);

export const myStudentsRequest = createAction<ISearchRequest<IStudentSearchParamsModel>>(MY_STUDENTS.LOAD);
export const myStudentsError = createAction<string>(MY_STUDENTS.ERROR);
export const myStudentsSuccess = createAction<IPagedResult<PersonSearchResultModel>>(MY_STUDENTS.LOADED);
