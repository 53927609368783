import {toLocalTime} from '../../@template/helpers/datetimes';

export class EventItemViewFiles implements IEventItemViewFiles {
  fileId!: string;
  dtCreated?: Date | undefined;
  filename?: string | undefined;
  fromPersonId?: string | undefined;

  constructor(data?: IEventItemViewFiles) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.fileId = Data["fileId"];
      this.dtCreated = Data["dtCreated"] ? toLocalTime(Data["dtCreated"].toString()) : <any>undefined;
      this.filename = Data["filename"];
      this.fromPersonId = Data["fromPersonId"];
    }
  }

  static fromJS(data: any): EventItemViewFiles {
    data = typeof data === 'object' ? data : {};
    let result = new EventItemViewFiles();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["fileId"] = this.fileId;
    data["dtCreated"] = this.dtCreated ? this.dtCreated.toISOString() : <any>undefined;
    data["filename"] = this.filename;
    data["fromPersonId"] = this.fromPersonId;
    return data;
  }
}

export interface IEventItemViewFiles {
  fileId: string;
  dtCreated?: Date | undefined;
  filename?: string | undefined;
  fromPersonId?: string | undefined;
}