import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  WORKSHADOW, WORKSHADOW_SEARCH, HSP_PLACEMENTS, HSP_OPPORTUNITIES, HSP_STUDENT_POOLS,
  workShadowSaveSuccess,
  workShadowSaveError, workShadowLoadSuccess, workShadowLoadError, workShadowsSearchSuccess, workShadowsSearchError,
  hspPlacementsLoadError, hspPlacementsLoadSuccess,
  hspPoolsLoadSuccess,
  hspPoolsLoadError,
  hspOpportunitiesLoadSuccess,
  hspOpportunitiesLoadError, myHSPStudentsSuccess, myHSPStudentsError, MY_HSP_STUDENTS,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {PayloadAction} from '@reduxjs/toolkit';
import {PersonModel} from '../../types/models/PersonModel';
import {ICommonSavePayload} from '../Common.actions';
import {WorkShadowPersonEditModel} from '../../types/requests/WorkShadowEditModel';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {WorkShadowSearchParamsModel} from '../../types/requests/WorkShadowSearchParamsModel';
import {EngagementInfoSearchPaged} from '../../types/views/EngagementInfoSearchPaged';
import {PersonSearchResultPaged} from '../../types/views/PersonSearchResultPaged';
import {RollbackWorkShadowPersonEditModel} from '../../types/requests/RollbackWorkShadowPersonEditModel';
import {HSPPlacementDetailModel} from '../../types/models/HSPPlacementDetailModel';
import {HSPPoolItem} from '../../types/views/HSPPoolItem';
import {
  MY_STUDENTS,
  myStudentsError,
  myStudentsSuccess,
  studentOpportunitiesLoadError,
  studentOpportunitiesLoadSuccess,
  studentPoolsLoadError,
  studentPoolsLoadSuccess,
} from '../students/Actions';
import {
  HSPStudentOpportunityListView,
} from '../../types/views/HSPStudentOpportunityListView';
import {StudentSearchParamsModel} from '../../types/requests/StudentSearchParamsModel';


function* LoadWorkShadow(id: string) {
  console.log('SAGA - workShadowSaga - LoadWorkShadow');
  try {
    const res = yield authApi.get(`${apiConfig.coreWorkShadowPath}/${id}`);
    const workShadow = PersonModel.fromJS(res.data);
    yield put(workShadowLoadSuccess(workShadow));
  } catch (error) {
    yield put(workShadowLoadError(error));
  }
}

function* SaveWorkShadow({ id, submission }: ICommonSavePayload<WorkShadowPersonEditModel>) {
  console.log('SAGA - workShadowSaga - SaveWorkShadow');
  const workShadowPath = id ? `${apiConfig.coreWorkShadowPath}/${id}` : apiConfig.coreWorkShadowPath;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: id ? 'PUT' : 'POST',
      url: workShadowPath,
      data: submission
    });
    const workShadow = PersonModel.fromJS(res.data);
    yield put(workShadowSaveSuccess(workShadow));
  } catch (error) {
    yield put(workShadowSaveError(error));
  }
}

function* RollbackWorkShadow({ id, submission }: ICommonSavePayload<RollbackWorkShadowPersonEditModel>) {
  console.log('SAGA - workShadowSaga - RollbackWorkShadow');
  const workShadowPath = `${apiConfig.coreWorkShadowPath}/${id}/rollback`;
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: workShadowPath,
      data: submission
    });
    const workShadow = PersonModel.fromJS(res.data);
    yield put(workShadowSaveSuccess(workShadow));
  } catch (error) {
    yield put(workShadowSaveError(error));
  }
}

function* SearchWorkShadows({ page, sort, searchParams }: ISearchRequest<WorkShadowSearchParamsModel>) {
  console.log('SAGA - workShadowSaga - SearchWorkShadow');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreWorkShadowPath}/search?page=${page}&sort=${sort}`,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(workShadowsSearchSuccess(results));
  } catch (error) {
    yield put(workShadowsSearchError(error));
  }
}

function* LoadHSPPlacements(id: string) {
  console.log('SAGA - workShadowSaga - LoadHSPPlacements');
  try {
    const res = yield authApi.get(`${apiConfig.coreUrl}${apiConfig.coreWorkShadowPath}/${id}/placements`);
    const placements = res.data.map((x: any) => HSPPlacementDetailModel.fromJS(x));
    yield put(hspPlacementsLoadSuccess(placements));
  } catch (error) {
    yield put(hspPlacementsLoadError(error));
  }
}

function* LoadHSPPools(id: string) {
  console.log('SAGA - workShadowSaga - LoadHSPStudentPools');
  try {
    const res = yield authApi.get(`${apiConfig.coreUrl}${apiConfig.coreWorkShadowPath}/${id}/pools`);
    const pools = res.data.map((x: any) => HSPPoolItem.fromJS(x));
    yield put(hspPoolsLoadSuccess(pools));
  } catch (error) {
    yield put(hspPoolsLoadError(error));
  }
}

function* LoadHSPOpportunities(personId: string) {
  console.log('SAGA - workShadowSaga - LoadHSPOpportunities');
  try {
    const url = `${apiConfig.coreWorkShadowPath}/${personId}/opportunities`;
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: url,
    });
    const opportunities = res.data.map((x: any) => HSPStudentOpportunityListView.fromJS(x));

    yield put(hspOpportunitiesLoadSuccess(opportunities));
  } catch (error) {
    yield put(hspOpportunitiesLoadError(error));
  }
}

function* MyHSPStudents({ searchParams, page, pageSize }: ISearchRequest<WorkShadowSearchParamsModel>) {
  try {
    const searchPath = `${apiConfig.coreWorkShadowPath}/search?page=${page}&page_size=${pageSize ? pageSize:50}`;
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: searchPath,
      data: searchParams
    });
    const results: PersonSearchResultPaged = PersonSearchResultPaged.fromJS(res.data);
    yield put(myHSPStudentsSuccess(results));
  } catch (error) {
    yield put(myHSPStudentsError(error));
  }
}


export function* watchWorkShadowLoad() {
  yield takeLatest(WORKSHADOW.LOAD, ({ payload }: PayloadAction<string>) => LoadWorkShadow(payload));
}

export function* watchWorkShadowRefresh() {
  yield takeLatest(WORKSHADOW.REFRESH, ({ payload }: PayloadAction<string>) => LoadWorkShadow(payload));
}

export function* watchWorkShadowSave() {
  yield takeLatest(WORKSHADOW.SAVE, ({ payload }: PayloadAction<ICommonSavePayload<WorkShadowPersonEditModel>>) => SaveWorkShadow(payload));
}

export function* watchWorkShadowRollback() {
  yield takeLatest(WORKSHADOW.ROLLBACK, ({ payload }: PayloadAction<ICommonSavePayload<RollbackWorkShadowPersonEditModel>>) => RollbackWorkShadow(payload));
}

export function* watchWorkShadowSearch() {
  yield takeLatest(WORKSHADOW_SEARCH.LOAD, ({payload}: PayloadAction<ISearchRequest<WorkShadowSearchParamsModel>>) => SearchWorkShadows(payload))
}

export function* watchWorkShadowSearchRefresh() {
  yield takeLatest(WORKSHADOW_SEARCH.REFRESH, ({payload}: PayloadAction<ISearchRequest<WorkShadowSearchParamsModel>>) => SearchWorkShadows(payload))
}

export function* watchHSPLoadPlacements() {
  yield takeLatest(HSP_PLACEMENTS.LOAD, ({ payload }: PayloadAction<string>) => LoadHSPPlacements(payload));
}
export function* watchHSPRefreshPlacements() {
  yield takeLatest(HSP_PLACEMENTS.REFRESH, ({ payload }: PayloadAction<string>) => LoadHSPPlacements(payload));
}

export function* watchHSPLoadPools() {
  yield takeLatest(HSP_STUDENT_POOLS.LOAD, ({ payload }: PayloadAction<string>) => LoadHSPPools(payload));
}
export function* watchHSPRefreshPools() {
  yield takeLatest(HSP_STUDENT_POOLS.REFRESH, ({ payload }: PayloadAction<string>) => LoadHSPPools(payload));
}

export function* watchHSPLoadOpportunities() {
  yield takeLatest(HSP_OPPORTUNITIES.LOAD, ({ payload }: PayloadAction<string>) => LoadHSPOpportunities(payload));
}
export function* watchHSPRefreshOpportunities() {
  yield takeLatest(HSP_OPPORTUNITIES.REFRESH, ({ payload }: PayloadAction<string>) => LoadHSPOpportunities(payload));
}

export function* watchMyHSPStudents(){
  yield takeLatest(MY_HSP_STUDENTS.LOAD, ({ payload }: PayloadAction<ISearchRequest<WorkShadowSearchParamsModel>>) => MyHSPStudents(payload))
}

export default function* workShadowSaga() {
  yield all ([
    call(watchWorkShadowRefresh),
    call(watchWorkShadowLoad),
    call(watchWorkShadowSave),
    call(watchWorkShadowRollback),
    call(watchWorkShadowSearch),
    call(watchWorkShadowSearchRefresh),
    call(watchHSPLoadPlacements),
    call(watchHSPRefreshPlacements),
    call(watchHSPLoadPools),
    call(watchHSPRefreshPools),
    call(watchHSPLoadOpportunities),
    call(watchHSPRefreshOpportunities),
    call(watchMyHSPStudents),
  ])
}
