import {createSelector} from 'reselect';
import {IChangeRequestState} from './Reducer';

export const selectChangeRequestState = (state: { changeRequests: IChangeRequestState }) => state.changeRequests;

export const selectStudentChangeRequest = createSelector(
  selectChangeRequestState,
  (subState) => subState.studentChangeRequestDetail
);

export const  selectStudentChangeRequestLoading = createSelector(
  selectChangeRequestState,
  (subState) => subState.studentChangeRequestLoading
);

export const  selectStudentChangeRequestError = createSelector(
  selectChangeRequestState,
  (subState) => subState.studentChangeRequestError
);

export const selectContactChangeRequest = createSelector(
  selectChangeRequestState,
  (subState) => subState.contactChangeRequestDetail
);

export const  selectContactChangeRequestLoading = createSelector(
  selectChangeRequestState,
  (subState) => subState.contactChangeRequestLoading
);

export const  selectContactChangeRequestError = createSelector(
  selectChangeRequestState,
  (subState) => subState.contactChangeRequestError
);