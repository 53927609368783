import {ICommonLoadActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {GlobalIndexModel} from '../../types/views/GlobalIndexModel';


export interface IGlobalSearchActionTypes extends ICommonLoadActionType {}

export const GLOBAL_SEARCH: IGlobalSearchActionTypes = {
  LOAD: 'globalSearch/load',
  LOADED: 'globalSearch/loaded',
  ERROR: 'globalSearch/error',
}

export const globalSearchLoad = createAction<string>(GLOBAL_SEARCH.LOAD);
export const globalSearchLoadSuccess = createAction<GlobalIndexModel[]>(GLOBAL_SEARCH.LOADED);
export const globalSearchLoadError = createAction<string>(GLOBAL_SEARCH.ERROR);