import React from 'react';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';

import {responsiveFontSizes} from '@mui/material';
import defaultConfig from '../../utility/ContextProvider/defaultConfig';
import {useSelector} from 'react-redux';
import {selectJobId} from '../../../redux/jobProgress/Selectors';
import ProgressLoaderDialog from '../../../modules/progressLoader';

const AppThemeProvider: React.FC<React.ReactNode> = (props) => {
  let customTheme = createTheme(defaultConfig.theme);
  const responsiveTheme = responsiveFontSizes(customTheme);

  const jobId = useSelector(selectJobId);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={responsiveTheme}>
        {jobId && <ProgressLoaderDialog />}
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default React.memo(AppThemeProvider);
