import {ICompaniesCacheState} from './Reducer';
import {createSelector} from '@reduxjs/toolkit';

export const selectCompaniesCache = (state: { companiesCache: ICompaniesCacheState }) => state.companiesCache;

export const selectAllCompaniesCache = createSelector(
  selectCompaniesCache,
  (subState) => subState.companyCache
);

export const selectCompanyProp = (_: any, companyId: string) => companyId;
export const selectCompanyCacheItem = createSelector(
  selectAllCompaniesCache,
  selectCompanyProp,
  (companies, companyId) => {
    if (companyId) {
      return companies.find(x => x.id === companyId);
    }
    return null;
  }
);

export const selectCompanyIdsProp = (_: any, companyIds: string[]) => companyIds;
export const selectCompanyCacheItems = createSelector(
  selectAllCompaniesCache,
  selectCompanyIdsProp,
  (companies, companyIds) => {
    if (companyIds) {
      return companies.filter(x => companyIds.find(id => id === x.id));
    }
    return null;
  }
);

export const selectAllLogos = createSelector(
  selectCompaniesCache,
  (subState) => subState.companyLogoCache
);

