import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {PLACEMENT_CACHE} from './Actions';
import {PlacementDetailModel} from '../../types/models/PlacementDetailModel';
import {PlacementListItem} from '../../types/views/PlacementListItem';
import {PlacementIdList} from '../../types/requests/PlacementIdList';

export interface IPlacementsCacheState {
  placementCacheUpdating: boolean | false;
  placementCacheError: string | undefined;
  placementPendingIdList: string[];
  placementCache: PlacementListItem[];
}

const initialState: IPlacementsCacheState = {
  placementCacheUpdating: false,
  placementCacheError: undefined,
  placementPendingIdList: [],
  placementCache: [],
}

const placementsCacheReducer = createReducer(initialState, {
  [PLACEMENT_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.placementPendingIdList = [...new Set([...state.placementPendingIdList, ...action.payload])];
    state.placementCacheError = undefined;
  },
  [PLACEMENT_CACHE.UPDATE]: (state) => {
    state.placementPendingIdList = [];
    state.placementCacheUpdating = true;
    state.placementCacheError = undefined;
  },
  [PLACEMENT_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<PlacementListItem[]>) => {
    let currentPlacementsCache = state.placementCache;
    action.payload.map(item => {
      if (!currentPlacementsCache.find(x => x.id === item.id)) {
        currentPlacementsCache.push(item);
      }
    });
    state.placementCache = currentPlacementsCache;
    state.placementCacheUpdating = false;
    state.placementCacheError = undefined;
  },
  [PLACEMENT_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.placementCache = state.placementCache.filter(x => x.id !== action.payload);
    state.placementCacheUpdating = false;
    state.placementCacheError = undefined;
  },
});

export default placementsCacheReducer;
