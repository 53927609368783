import {AddressViewModel} from '../models/AddressViewModel';

export class VolunteerSearchModel implements IVolunteerSearchModel {
  volunteerTags?: string[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;
  companyName?: string | undefined;
  companyRole?: string | undefined;
  yearsOfExperience?: string | undefined;
  aboutMe?: string | undefined;
  volunteerType?: string[] | undefined;
  isActive?: boolean | undefined;

  constructor(data?: IVolunteerSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      if (Array.isArray(Data["volunteerTags"])) {
        this.volunteerTags = [] as any;
        for (let item of Data["volunteerTags"])
          this.volunteerTags!.push(item);
      }
      this.locationAddress = Data["locationAddress"] ? AddressViewModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.quickNotes = Data["quickNotes"];
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryAdvisorPersonId = Data["primaryAdvisorPersonId"];
      this.primaryAdvisorFullName = Data["primaryAdvisorFullName"];
      this.companyName = Data["companyName"];
      this.companyRole = Data["companyRole"];
      this.yearsOfExperience = Data["yearsOfExperience"];
      this.aboutMe = Data["aboutMe"];
      if (Array.isArray(Data["volunteerType"])) {
        this.volunteerType = [] as any;
        for (let item of Data["volunteerType"])
          this.volunteerType!.push(item);
      }
      this.isActive = Data["isActive"];
    }
  }

  static fromJS(data: any): VolunteerSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new VolunteerSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.volunteerTags)) {
      data["volunteerTags"] = [];
      for (let item of this.volunteerTags)
        data["volunteerTags"].push(item);
    }
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["quickNotes"] = this.quickNotes;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryAdvisorPersonId"] = this.primaryAdvisorPersonId;
    data["primaryAdvisorFullName"] = this.primaryAdvisorFullName;
    data["companyName"] = this.companyName;
    data["companyRole"] = this.companyRole;
    data["yearsOfExperience"] = this.yearsOfExperience;
    data["aboutMe"] = this.aboutMe;
    if (Array.isArray(this.volunteerType)) {
      data["volunteerType"] = [];
      for (let item of this.volunteerType)
        data["volunteerType"].push(item);
    }
    data["isActive"] = this.isActive;
    return data;
  }
}

export interface IVolunteerSearchModel {
  volunteerTags?: string[] | undefined;
  locationAddress?: AddressViewModel | undefined;
  quickNotes?: string | undefined;
  advisorUsers?: string[] | undefined;
  primaryAdvisorPersonId?: string | undefined;
  primaryAdvisorFullName?: string | undefined;
  companyName?: string | undefined;
  companyRole?: string | undefined;
  yearsOfExperience?: string | undefined;
  aboutMe?: string | undefined;
  volunteerType?: string[] | undefined;
  isActive?: boolean | undefined;
}