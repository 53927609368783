
export const defaultForMessageScheduleRequestModel = () => {
  return MessageScheduleRequestModel.fromJS({
    actionSchedule: [new Date(), new Date()],
    eventTitle: '',
    eventSummary: '',
    acceptGuestChanges: false
  });
}


export class MessageScheduleRequestModel implements IMessageScheduleRequestModel {
  actionScheduleId?: string | undefined;
  actionSchedule?: Date[] | undefined;
  eventTitle?: string | undefined;
  eventSummary?: string | undefined;
  acceptGuestChanges?: boolean | undefined;

  constructor(data?: IMessageScheduleRequestModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.actionScheduleId = Data["actionScheduleId"];
      if (Array.isArray(Data["actionSchedule"])) {
        this.actionSchedule = [] as any;
        for (let item of Data["actionSchedule"])
          this.actionSchedule!.push(new Date(item));
      }
      this.eventTitle = Data["eventTitle"];
      this.eventSummary = Data["eventSummary"];
      this.acceptGuestChanges = Data["acceptGuestChanges"];
    }
  }

  static fromJS(data: any): MessageScheduleRequestModel {
    data = typeof data === 'object' ? data : {};
    let result = new MessageScheduleRequestModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["actionScheduleId"] = this.actionScheduleId;
    if (Array.isArray(this.actionSchedule)) {
      data["actionSchedule"] = [];
      for (let item of this.actionSchedule)
        data["actionSchedule"].push(item.toISOString());
    }
    data["eventTitle"] = this.eventTitle;
    data["eventSummary"] = this.eventSummary;
    data["acceptGuestChanges"] = this.acceptGuestChanges;
    return data;
  }
}

export interface IMessageScheduleRequestModel {
  actionScheduleId?: string | undefined;
  actionSchedule?: Date[] | undefined;
  eventTitle?: string | undefined;
  eventSummary?: string | undefined;
  acceptGuestChanges?: boolean | undefined;
}
