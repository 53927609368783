import {StudentOnboardingModel} from '../../types/models/StudentOnboardingModel';
import {StudentPoolModel} from '../../types/models/StudentPoolModel';
import {StudentPlacementModel} from '../../types/models/StudentPlacementModel';
import {StudentGraduatingModel} from '../../types/models/StudentGraduatingModel';
import {ClientOnboardingModel} from '../../types/models/ClientOnboardingModel';
import {EducationOnboardingModel} from '../../types/models/EducationOnboardingModel';
import {WorkShadowOnboardingModel} from '../../types/models/WorkShadowOnboardingModel';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {STUDENT_SEARCH} from '../students/Actions';
import {
  CLIENT_ON_BOARDING_SETTINGS,
  CLIENT_ON_BOARDING_SETTINGS_LIST,
  EDUCATION_ON_BOARDING_SETTINGS,
  EDUCATION_ON_BOARDING_SETTINGS_LIST,
  GRADUATING_SETTINGS,
  GRADUATING_SETTINGS_LIST,
  STUDENT_ON_BOARDING_SETTINGS,
  STUDENT_ON_BOARDING_SETTINGS_LIST,
  STUDENT_PLACEMENT_SETTINGS,
  STUDENT_PLACEMENT_SETTINGS_LIST,
  // STUDENT_POOL_SETTINGS,
  // STUDENT_POOL_SETTINGS_LIST,
  WORK_SHADOW_ON_BOARDING_SETTINGS,
  WORK_SHADOW_ON_BOARDING_SETTINGS_LIST,
  WORK_SHADOW_PLACEMENT_SETTINGS,
  WORK_SHADOW_PLACEMENT_SETTINGS_LIST,
  WORK_SHADOW_POOL_SETTINGS,
  WORK_SHADOW_POOL_SETTINGS_LIST,
  ALUMNI_EARLY_PROFESSIONAL_SETTINGS,
  ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST,
  HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS,
  HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST
} from './Actions';
import {PoolWorkShadowModel} from '../../types/models/PoolWorkShadowModel';
import {PlacementWorkShadowModel} from '../../types/models/PlacementWorkShadowModel';
import {AlumniEarlyProfessionalModel} from '../../types/models/AlumniEarlyProfessionalModel';
import {HighSchoolProgramParticipantModel} from '../../types/models/HighSchoolProgramParticipantModel';

export interface ITimelinesState {
  studentOnBoardingResultLoading: boolean;
  studentOnBoardingResultError: string | undefined;
  studentOnBoardingResult: StudentOnboardingModel[] | undefined;
  studentOnBoardingSettings: StudentOnboardingModel | undefined;
  studentOnBoardingSettingsLoading: boolean;
  studentOnBoardingSettingsSaving: boolean;
  studentOnBoardingSettingsDisabling: boolean;
  studentOnBoardingSettingsError: string | undefined;
  studentPlacementResultLoading: boolean;
  studentPlacementResultError: string | undefined;
  studentPlacementResult: StudentPlacementModel[] | undefined;
  studentPlacementSettingsLoading: boolean;
  studentPlacementSettingsSaving: boolean;
  studentPlacementSettingsDisabling: boolean;
  studentPlacementSettingsError: string | undefined;
  studentPlacementSettings: StudentPlacementModel | undefined;
  graduatingStudentResultLoading: boolean;
  graduatingStudentResultError: string | undefined;
  graduatingStudentResult: StudentGraduatingModel[] | undefined;
  graduatingStudentSettings: StudentGraduatingModel | undefined;
  graduatingStudentSettingsLoading: boolean;
  graduatingStudentSettingsSaving: boolean;
  graduatingStudentSettingsDisabling: boolean;
  graduatingStudentSettingsError: string | undefined;
  clientOnBoardingResultLoading: boolean;
  clientOnBoardingResultError: string | undefined;
  clientOnBoardingResult: ClientOnboardingModel[] | undefined;
  clientOnBoardingSettingsLoading: boolean;
  clientOnBoardingSettingsSaving: boolean;
  clientOnBoardingSettingsDisabling: boolean;
  clientOnBoardingSettingsError: string | undefined;
  clientOnBoardingSettings: ClientOnboardingModel | undefined;
  educationOnBoardingResultLoading: boolean;
  educationOnBoardingResultError: string | undefined;
  educationOnBoardingResult: EducationOnboardingModel[] | undefined;
  educationOnBoardingSettingsLoading: boolean;
  educationOnBoardingSettingsSaving: boolean;
  educationOnBoardingSettingsDisabling: boolean;
  educationOnBoardingSettingsError: string | undefined;
  educationOnBoardingSettings: EducationOnboardingModel | undefined;
  workShadowOnBoardingResultLoading: boolean;
  workShadowOnBoardingResultError: string | undefined;
  workShadowOnBoardingResult: WorkShadowOnboardingModel[] | undefined;
  workShadowOnBoardingSettings: WorkShadowOnboardingModel | undefined;
  workShadowOnBoardingSettingsLoading: boolean;
  workShadowOnBoardingSettingsSaving: boolean;
  workShadowOnBoardingSettingsDisabling: boolean;
  workShadowOnBoardingSettingsError: string | undefined;
  workShadowPoolResultLoading: boolean;
  workShadowPoolResultError: string | undefined;
  workShadowPoolResult: PoolWorkShadowModel[] | undefined;
  workShadowPoolSettingsLoading: boolean;
  workShadowPoolSettingsSaving: boolean;
  workShadowPoolSettingsDisabling: boolean;
  workShadowPoolSettingsError: string | undefined;
  workShadowPoolSettings: PoolWorkShadowModel | undefined;
  workShadowPlacementResultLoading: boolean;
  workShadowPlacementResultError: string | undefined;
  workShadowPlacementResult: PlacementWorkShadowModel[] | undefined;
  workShadowPlacementSettingsLoading: boolean;
  workShadowPlacementSettingsSaving: boolean;
  workShadowPlacementSettingsDisabling: boolean;
  workShadowPlacementSettingsError: string | undefined;
  workShadowPlacementSettings: PlacementWorkShadowModel | undefined;
  alumniEarlyProfessionalResultLoading: boolean;
  alumniEarlyProfessionalResultError: string | undefined;
  alumniEarlyProfessionalResult: AlumniEarlyProfessionalModel[] | undefined;
  alumniEarlyProfessionalSettings: AlumniEarlyProfessionalModel | undefined;
  alumniEarlyProfessionalSettingsLoading: boolean;
  alumniEarlyProfessionalSettingsSaving: boolean;
  alumniEarlyProfessionalSettingsDisabling: boolean;
  alumniEarlyProfessionalSettingsError: string | undefined;
  highSchoolProgramParticipantResultLoading: boolean;
  highSchoolProgramParticipantResultError: string | undefined;
  highSchoolProgramParticipantResult: HighSchoolProgramParticipantModel[] | undefined;
  highSchoolProgramParticipantSettings: HighSchoolProgramParticipantModel | undefined;
  highSchoolProgramParticipantSettingsLoading: boolean;
  highSchoolProgramParticipantSettingsSaving: boolean;
  highSchoolProgramParticipantSettingsDisabling: boolean;
  highSchoolProgramParticipantSettingsError: string | undefined;
}

const initialState: ITimelinesState = {
  studentOnBoardingResultLoading: false,
  studentOnBoardingResultError: undefined,
  studentOnBoardingResult: [],
  studentOnBoardingSettings: undefined,
  studentOnBoardingSettingsLoading: false,
  studentOnBoardingSettingsSaving: false,
  studentOnBoardingSettingsDisabling: false,
  studentOnBoardingSettingsError: undefined,
  studentPlacementResultLoading: false,
  studentPlacementResultError: undefined,
  studentPlacementResult: [],
  studentPlacementSettingsLoading: false,
  studentPlacementSettingsSaving: false,
  studentPlacementSettingsDisabling: false,
  studentPlacementSettingsError: undefined,
  studentPlacementSettings: undefined,
  graduatingStudentResultLoading: false,
  graduatingStudentResultError: undefined,
  graduatingStudentResult: [],
  graduatingStudentSettings: undefined,
  graduatingStudentSettingsLoading: false,
  graduatingStudentSettingsSaving: false,
  graduatingStudentSettingsDisabling: false,
  graduatingStudentSettingsError: undefined,
  clientOnBoardingResultLoading: false,
  clientOnBoardingResultError: undefined,
  clientOnBoardingResult: [],
  clientOnBoardingSettingsLoading: false,
  clientOnBoardingSettingsSaving: false,
  clientOnBoardingSettingsDisabling: false,
  clientOnBoardingSettingsError: undefined,
  clientOnBoardingSettings: undefined,
  educationOnBoardingResultLoading: false,
  educationOnBoardingResultError: undefined,
  educationOnBoardingResult: [],
  educationOnBoardingSettingsLoading: false,
  educationOnBoardingSettingsSaving: false,
  educationOnBoardingSettingsDisabling: false,
  educationOnBoardingSettingsError: undefined,
  educationOnBoardingSettings: undefined,
  workShadowOnBoardingResultLoading: false,
  workShadowOnBoardingResultError: undefined,
  workShadowOnBoardingResult: [],
  workShadowOnBoardingSettings: undefined,
  workShadowOnBoardingSettingsLoading: false,
  workShadowOnBoardingSettingsSaving: false,
  workShadowOnBoardingSettingsDisabling: false,
  workShadowOnBoardingSettingsError: undefined,
  workShadowPoolResultLoading: false,
  workShadowPoolResultError: undefined,
  workShadowPoolResult: [],
  workShadowPoolSettingsLoading: false,
  workShadowPoolSettingsSaving: false,
  workShadowPoolSettingsDisabling: false,
  workShadowPoolSettingsError: undefined,
  workShadowPoolSettings: undefined,
  workShadowPlacementResultLoading: false,
  workShadowPlacementResultError: undefined,
  workShadowPlacementResult: [],
  workShadowPlacementSettingsLoading: false,
  workShadowPlacementSettingsSaving: false,
  workShadowPlacementSettingsDisabling: false,
  workShadowPlacementSettingsError: undefined,

  workShadowPlacementSettings: undefined,
  alumniEarlyProfessionalResultLoading: false,
  alumniEarlyProfessionalResultError: undefined,
  alumniEarlyProfessionalResult: [],
  alumniEarlyProfessionalSettings: undefined,
  alumniEarlyProfessionalSettingsLoading: false,
  alumniEarlyProfessionalSettingsSaving: false,
  alumniEarlyProfessionalSettingsDisabling: false,
  alumniEarlyProfessionalSettingsError: undefined,

  highSchoolProgramParticipantResultLoading: false,
  highSchoolProgramParticipantResultError: undefined,
  highSchoolProgramParticipantResult: [],
  highSchoolProgramParticipantSettings: undefined,
  highSchoolProgramParticipantSettingsLoading: false,
  highSchoolProgramParticipantSettingsSaving: false,
  highSchoolProgramParticipantSettingsDisabling: false,
  highSchoolProgramParticipantSettingsError: undefined,
}

const timelinesReducer = createReducer(initialState, {
  [STUDENT_ON_BOARDING_SETTINGS.LOAD]: (state) => {
    state.studentOnBoardingSettings = undefined;
    state.studentOnBoardingSettingsError = undefined;
    state.studentOnBoardingSettingsLoading = true;
  },
  [STUDENT_ON_BOARDING_SETTINGS.LOADED]: (state, action: PayloadAction<StudentOnboardingModel>) => {
    state.studentOnBoardingSettings = action.payload;
    state.studentOnBoardingSettingsError = undefined;
    state.studentOnBoardingSettingsLoading = false;
  },
  [STUDENT_ON_BOARDING_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.studentOnBoardingSettingsError = action.payload;
    state.studentOnBoardingSettingsLoading = false;
  },
  [STUDENT_ON_BOARDING_SETTINGS.SAVE]: (state) => {
    state.studentOnBoardingSettingsError = undefined;
    state.studentOnBoardingSettingsSaving = true;
  },
  [STUDENT_ON_BOARDING_SETTINGS.DISABLE]: (state) => {
    state.studentOnBoardingSettingsError = undefined;
    state.studentOnBoardingSettingsDisabling = true;
  },
  [STUDENT_ON_BOARDING_SETTINGS.SAVED]: (state, action: PayloadAction<StudentOnboardingModel>) => {
    state.studentOnBoardingSettings = action.payload;
    state.studentOnBoardingSettingsError = undefined;
    state.studentOnBoardingSettingsLoading = false;
    state.studentOnBoardingSettingsSaving = false;
    state.studentOnBoardingSettingsDisabling = false;
  },
  [STUDENT_ON_BOARDING_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.studentOnBoardingSettingsError = action.payload;
    state.studentOnBoardingSettingsLoading = false;
    state.studentOnBoardingSettingsSaving = false;
    state.studentOnBoardingSettingsDisabling = false;
  },
  [STUDENT_ON_BOARDING_SETTINGS_LIST.LOAD]: (state) => {
    state.studentOnBoardingResultError = undefined;
    state.studentOnBoardingResultLoading = true;
  },
  [STUDENT_ON_BOARDING_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<StudentOnboardingModel[]>) => {
    state.studentOnBoardingResult = action.payload;
    state.studentOnBoardingResultError = undefined;
    state.studentOnBoardingResultLoading = false;
  },
  [STUDENT_ON_BOARDING_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.studentOnBoardingResultError = action.payload;
    state.studentOnBoardingResultLoading = false;
  },
  [STUDENT_PLACEMENT_SETTINGS.LOAD]: (state) => {
    state.studentPlacementSettings = undefined;
    state.studentPlacementSettingsError = undefined;
    state.studentPlacementSettingsLoading = true;
  },
  [STUDENT_PLACEMENT_SETTINGS.LOADED]: (state, action: PayloadAction<StudentPlacementModel>) => {
    state.studentPlacementSettings = action.payload;
    state.studentPlacementSettingsError = undefined;
    state.studentPlacementSettingsLoading = false;
  },
  [STUDENT_PLACEMENT_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.studentPlacementSettingsError = action.payload;
    state.studentPlacementSettingsLoading = false;
  },
  [STUDENT_PLACEMENT_SETTINGS.SAVE]: (state) => {
    state.studentPlacementSettingsError = undefined;
    state.studentPlacementSettingsSaving = true;
  },
  [STUDENT_PLACEMENT_SETTINGS.DISABLE]: (state) => {
    state.studentPlacementSettingsError = undefined;
    state.studentPlacementSettingsDisabling = true;
  },
  [STUDENT_PLACEMENT_SETTINGS.SAVED]: (state, action: PayloadAction<StudentPlacementModel>) => {
    state.studentPlacementSettings = action.payload;
    state.studentPlacementSettingsError = undefined;
    state.studentPlacementSettingsLoading = false;
    state.studentPlacementSettingsSaving = false;
    state.studentPlacementSettingsDisabling = false;
  },
  [STUDENT_PLACEMENT_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.studentPlacementSettingsError = action.payload;
    state.studentPlacementSettingsLoading = false;
    state.studentPlacementSettingsSaving = false;
    state.studentPlacementSettingsDisabling = false;
  },
  [STUDENT_PLACEMENT_SETTINGS_LIST.LOAD]: (state) => {
    state.studentPlacementResultError = undefined;
    state.studentPlacementResultLoading = true;
  },
  [STUDENT_PLACEMENT_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<StudentPlacementModel[]>) => {
    state.studentPlacementResult = action.payload;
    state.studentPlacementResultError = undefined;
    state.studentPlacementResultLoading = false;
  },
  [STUDENT_PLACEMENT_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.studentPlacementResultError = action.payload;
    state.studentPlacementResultLoading = false;
  },
  [GRADUATING_SETTINGS.LOAD]: (state) => {
    state.graduatingStudentSettings = undefined;
    state.graduatingStudentSettingsError = undefined;
    state.graduatingStudentSettingsLoading = true;
  },
  [GRADUATING_SETTINGS.LOADED]: (state, action: PayloadAction<StudentGraduatingModel>) => {
    state.graduatingStudentSettings = action.payload;
    state.graduatingStudentSettingsError = undefined;
    state.graduatingStudentSettingsLoading = false;
  },
  [GRADUATING_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.graduatingStudentSettingsError = action.payload;
    state.graduatingStudentSettingsLoading = false;
  },
  [GRADUATING_SETTINGS.SAVE]: (state) => {
    state.graduatingStudentSettingsError = undefined;
    state.graduatingStudentSettingsSaving = true;
  },
  [GRADUATING_SETTINGS.DISABLE]: (state) => {
    state.graduatingStudentSettingsError = undefined;
    state.graduatingStudentSettingsDisabling = true;
  },
  [GRADUATING_SETTINGS.SAVED]: (state, action: PayloadAction<StudentGraduatingModel>) => {
    state.graduatingStudentSettings = action.payload;
    state.graduatingStudentSettingsError = undefined;
    state.graduatingStudentSettingsLoading = false;
    state.graduatingStudentSettingsSaving = false;
    state.graduatingStudentSettingsDisabling = false;
  },
  [GRADUATING_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.graduatingStudentSettingsError = action.payload;
    state.graduatingStudentSettingsLoading = false;
    state.graduatingStudentSettingsSaving = false;
    state.graduatingStudentSettingsDisabling = false;
  },
  [GRADUATING_SETTINGS_LIST.LOAD]: (state) => {
    state.graduatingStudentResultError = undefined;
    state.graduatingStudentResultLoading = true;
  },
  [GRADUATING_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<StudentGraduatingModel[]>) => {
    state.graduatingStudentResult = action.payload;
    state.graduatingStudentResultError = undefined;
    state.graduatingStudentResultLoading = false;
  },
  [GRADUATING_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.graduatingStudentResultError = action.payload;
    state.graduatingStudentResultLoading = false;
  },
  [CLIENT_ON_BOARDING_SETTINGS.LOAD]: (state) => {
    state.clientOnBoardingSettings = undefined;
    state.clientOnBoardingSettingsError = undefined;
    state.clientOnBoardingSettingsLoading = true;
  },
  [CLIENT_ON_BOARDING_SETTINGS.LOADED]: (state, action: PayloadAction<ClientOnboardingModel>) => {
    state.clientOnBoardingSettings = action.payload;
    state.clientOnBoardingSettingsError = undefined;
    state.clientOnBoardingSettingsLoading = false;
  },
  [CLIENT_ON_BOARDING_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.clientOnBoardingSettingsError = action.payload;
    state.clientOnBoardingSettingsLoading = false;
  },
  [CLIENT_ON_BOARDING_SETTINGS.SAVE]: (state) => {
    state.clientOnBoardingSettingsError = undefined;
    state.clientOnBoardingSettingsSaving = true;
  },
  [CLIENT_ON_BOARDING_SETTINGS.DISABLE]: (state) => {
    state.clientOnBoardingSettingsError = undefined;
    state.clientOnBoardingSettingsDisabling = true;
  },
  [CLIENT_ON_BOARDING_SETTINGS.SAVED]: (state, action: PayloadAction<ClientOnboardingModel>) => {
    state.clientOnBoardingSettings = action.payload;
    state.clientOnBoardingSettingsError = undefined;
    state.clientOnBoardingSettingsLoading = false;
    state.clientOnBoardingSettingsSaving = false;
    state.clientOnBoardingSettingsDisabling = false;
  },
  [CLIENT_ON_BOARDING_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.clientOnBoardingSettingsError = action.payload;
    state.clientOnBoardingSettingsLoading = false;
    state.clientOnBoardingSettingsSaving = false;
    state.clientOnBoardingSettingsDisabling = false;
  },
  [CLIENT_ON_BOARDING_SETTINGS_LIST.LOAD]: (state) => {
    state.clientOnBoardingResultError = undefined;
    state.clientOnBoardingResultLoading = true;
  },
  [CLIENT_ON_BOARDING_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<ClientOnboardingModel[]>) => {
    state.clientOnBoardingResult = action.payload;
    state.clientOnBoardingResultError = undefined;
    state.clientOnBoardingResultLoading = false;
  },
  [CLIENT_ON_BOARDING_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.clientOnBoardingResultError = action.payload;
    state.clientOnBoardingResultLoading = false;
  },
  [EDUCATION_ON_BOARDING_SETTINGS.LOAD]: (state) => {
    state.educationOnBoardingSettings = undefined;
    state.educationOnBoardingSettingsError = undefined;
    state.educationOnBoardingSettingsLoading = true;
  },
  [EDUCATION_ON_BOARDING_SETTINGS.LOADED]: (state, action: PayloadAction<EducationOnboardingModel>) => {
    state.educationOnBoardingSettings = action.payload;
    state.educationOnBoardingSettingsError = undefined;
    state.educationOnBoardingSettingsLoading = false;
  },
  [EDUCATION_ON_BOARDING_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.educationOnBoardingSettingsError = action.payload;
    state.educationOnBoardingSettingsLoading = false;
  },
  [EDUCATION_ON_BOARDING_SETTINGS.SAVE]: (state) => {
    state.educationOnBoardingSettingsError = undefined;
    state.educationOnBoardingSettingsSaving = true;
  },
  [EDUCATION_ON_BOARDING_SETTINGS.DISABLE]: (state) => {
    state.educationOnBoardingSettingsError = undefined;
    state.educationOnBoardingSettingsDisabling = true;
  },
  [EDUCATION_ON_BOARDING_SETTINGS.SAVED]: (state, action: PayloadAction<EducationOnboardingModel>) => {
    state.educationOnBoardingSettings = action.payload;
    state.educationOnBoardingSettingsError = undefined;
    state.educationOnBoardingSettingsLoading = false;
    state.educationOnBoardingSettingsSaving = false;
    state.educationOnBoardingSettingsDisabling = false;
  },
  [EDUCATION_ON_BOARDING_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.educationOnBoardingSettingsError = action.payload;
    state.educationOnBoardingSettingsLoading = false;
    state.educationOnBoardingSettingsSaving = false;
    state.educationOnBoardingSettingsDisabling = false;
  },
  [EDUCATION_ON_BOARDING_SETTINGS_LIST.LOAD]: (state) => {
    state.educationOnBoardingResultError = undefined;
    state.educationOnBoardingResultLoading = true;
  },
  [EDUCATION_ON_BOARDING_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<EducationOnboardingModel[]>) => {
    state.educationOnBoardingResult = action.payload;
    state.educationOnBoardingResultError = undefined;
    state.educationOnBoardingResultLoading = false;
  },
  [EDUCATION_ON_BOARDING_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.educationOnBoardingResultError = action.payload;
    state.educationOnBoardingResultLoading = false;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS.LOAD]: (state) => {
    state.workShadowOnBoardingSettings = undefined;
    state.workShadowOnBoardingSettingsError = undefined;
    state.workShadowOnBoardingSettingsLoading = true;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS.LOADED]: (state, action: PayloadAction<WorkShadowOnboardingModel>) => {
    state.workShadowOnBoardingSettings = action.payload;
    state.workShadowOnBoardingSettingsError = undefined;
    state.workShadowOnBoardingSettingsLoading = false;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowOnBoardingSettingsError = action.payload;
    state.workShadowOnBoardingSettingsLoading = false;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS.SAVE]: (state) => {
    state.workShadowOnBoardingSettingsError = undefined;
    state.workShadowOnBoardingSettingsSaving = true;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS.DISABLE]: (state) => {
    state.workShadowOnBoardingSettingsError = undefined;
    state.workShadowOnBoardingSettingsDisabling = true;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS.SAVED]: (state, action: PayloadAction<WorkShadowOnboardingModel>) => {
    state.workShadowOnBoardingSettings = action.payload;
    state.workShadowOnBoardingSettingsError = undefined;
    state.workShadowOnBoardingSettingsLoading = false;
    state.workShadowOnBoardingSettingsSaving = false;
    state.workShadowOnBoardingSettingsDisabling = false;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowOnBoardingSettingsError = action.payload;
    state.workShadowOnBoardingSettingsLoading = false;
    state.workShadowOnBoardingSettingsSaving = false;
    state.workShadowOnBoardingSettingsDisabling = false;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS_LIST.LOAD]: (state) => {
    state.workShadowOnBoardingResultError = undefined;
    state.workShadowOnBoardingResultLoading = true;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<WorkShadowOnboardingModel[]>) => {
    state.workShadowOnBoardingResult = action.payload;
    state.workShadowOnBoardingResultError = undefined;
    state.workShadowOnBoardingResultLoading = false;
  },
  [WORK_SHADOW_ON_BOARDING_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowOnBoardingResultError = action.payload;
    state.workShadowOnBoardingResultLoading = false;
  },
  [WORK_SHADOW_POOL_SETTINGS.LOAD]: (state) => {
    state.workShadowPoolSettings = undefined;
    state.workShadowPoolSettingsError = undefined;
    state.workShadowPoolSettingsLoading = true;
  },
  [WORK_SHADOW_POOL_SETTINGS.LOADED]: (state, action: PayloadAction<PoolWorkShadowModel>) => {
    state.workShadowPoolSettings = action.payload;
    state.workShadowPoolSettingsError = undefined;
    state.workShadowPoolSettingsLoading = false;
  },
  [WORK_SHADOW_POOL_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowPoolSettingsError = action.payload;
    state.workShadowPoolSettingsLoading = false;
  },
  [WORK_SHADOW_POOL_SETTINGS.SAVE]: (state) => {
    state.workShadowPoolSettingsError = undefined;
    state.workShadowPoolSettingsSaving = true;
  },
  [WORK_SHADOW_POOL_SETTINGS.DISABLE]: (state) => {
    state.workShadowPoolSettingsError = undefined;
    state.workShadowPoolSettingsDisabling = true;
  },
  [WORK_SHADOW_POOL_SETTINGS.SAVED]: (state, action: PayloadAction<PoolWorkShadowModel>) => {
    state.workShadowPoolSettings = action.payload;
    state.workShadowPoolSettingsError = undefined;
    state.workShadowPoolSettingsLoading = false;
    state.workShadowPoolSettingsSaving = false;
    state.workShadowPoolSettingsDisabling = false;
  },
  [WORK_SHADOW_POOL_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowPoolSettingsError = action.payload;
    state.workShadowPoolSettingsLoading = false;
    state.workShadowPoolSettingsSaving = false;
    state.workShadowPoolSettingsDisabling = false;
  },
  [WORK_SHADOW_POOL_SETTINGS_LIST.LOAD]: (state) => {
    state.workShadowPoolResultError = undefined;
    state.workShadowPoolResultLoading = true;
  },
  [WORK_SHADOW_POOL_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<PoolWorkShadowModel[]>) => {
    state.workShadowPoolResult = action.payload;
    state.workShadowPoolResultError = undefined;
    state.workShadowPoolResultLoading = false;
  },
  [WORK_SHADOW_POOL_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowPoolResultError = action.payload;
    state.workShadowPoolResultLoading = false;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS.LOAD]: (state) => {
    state.workShadowPlacementSettings = undefined;
    state.workShadowPlacementSettingsError = undefined;
    state.workShadowPlacementSettingsLoading = true;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS.LOADED]: (state, action: PayloadAction<PlacementWorkShadowModel>) => {
    state.workShadowPlacementSettings = action.payload;
    state.workShadowPlacementSettingsError = undefined;
    state.workShadowPlacementSettingsLoading = false;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowPlacementSettingsError = action.payload;
    state.workShadowPlacementSettingsLoading = false;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS.SAVE]: (state) => {
    state.workShadowPlacementSettingsError = undefined;
    state.workShadowPlacementSettingsSaving = true;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS.DISABLE]: (state) => {
    state.workShadowPlacementSettingsError = undefined;
    state.workShadowPlacementSettingsDisabling = true;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS.SAVED]: (state, action: PayloadAction<PlacementWorkShadowModel>) => {
    state.workShadowPlacementSettings = action.payload;
    state.workShadowPlacementSettingsError = undefined;
    state.workShadowPlacementSettingsLoading = false;
    state.workShadowPlacementSettingsSaving = false;
    state.workShadowPlacementSettingsDisabling = false;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowPlacementSettingsError = action.payload;
    state.workShadowPlacementSettingsLoading = false;
    state.workShadowPlacementSettingsSaving = false;
    state.workShadowPlacementSettingsDisabling = false;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS_LIST.LOAD]: (state) => {
    state.workShadowPlacementResultError = undefined;
    state.workShadowPlacementResultLoading = true;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<PlacementWorkShadowModel[]>) => {
    state.workShadowPlacementResult = action.payload;
    state.workShadowPlacementResultError = undefined;
    state.workShadowPlacementResultLoading = false;
  },
  [WORK_SHADOW_PLACEMENT_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.workShadowPlacementResultError = action.payload;
    state.workShadowPlacementResultLoading = false;
  },

  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS.LOAD]: (state) => {
    state.alumniEarlyProfessionalSettings = undefined;
    state.alumniEarlyProfessionalSettingsError = undefined;
    state.alumniEarlyProfessionalSettingsLoading = true;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS.LOADED]: (state, action: PayloadAction<AlumniEarlyProfessionalModel>) => {
    state.alumniEarlyProfessionalSettings = action.payload;
    state.alumniEarlyProfessionalSettingsError = undefined;
    state.alumniEarlyProfessionalSettingsLoading = false;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.alumniEarlyProfessionalSettingsError = action.payload;
    state.alumniEarlyProfessionalSettingsLoading = false;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS.SAVE]: (state) => {
    state.alumniEarlyProfessionalSettingsError = undefined;
    state.alumniEarlyProfessionalSettingsSaving = true;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS.DISABLE]: (state) => {
    state.alumniEarlyProfessionalSettingsError = undefined;
    state.alumniEarlyProfessionalSettingsDisabling = true;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS.SAVED]: (state, action: PayloadAction<AlumniEarlyProfessionalModel>) => {
    state.alumniEarlyProfessionalSettings = action.payload;
    state.alumniEarlyProfessionalSettingsError = undefined;
    state.alumniEarlyProfessionalSettingsLoading = false;
    state.alumniEarlyProfessionalSettingsSaving = false;
    state.alumniEarlyProfessionalSettingsDisabling = false;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.alumniEarlyProfessionalSettingsError = action.payload;
    state.alumniEarlyProfessionalSettingsLoading = false;
    state.alumniEarlyProfessionalSettingsSaving = false;
    state.alumniEarlyProfessionalSettingsDisabling = false;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST.LOAD]: (state) => {
    state.alumniEarlyProfessionalResultError = undefined;
    state.alumniEarlyProfessionalResultLoading = true;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<AlumniEarlyProfessionalModel[]>) => {
    state.alumniEarlyProfessionalResult = action.payload;
    state.alumniEarlyProfessionalResultError = undefined;
    state.alumniEarlyProfessionalResultLoading = false;
  },
  [ALUMNI_EARLY_PROFESSIONAL_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.alumniEarlyProfessionalResultError = action.payload;
    state.alumniEarlyProfessionalResultLoading = false;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.LOAD]: (state) => {
    state.highSchoolProgramParticipantSettings = undefined;
    state.highSchoolProgramParticipantSettingsError = undefined;
    state.highSchoolProgramParticipantSettingsLoading = true;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.LOADED]: (state, action: PayloadAction<HighSchoolProgramParticipantModel>) => {
    state.highSchoolProgramParticipantSettings = action.payload;
    state.highSchoolProgramParticipantSettingsError = undefined;
    state.highSchoolProgramParticipantSettingsLoading = false;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
    state.highSchoolProgramParticipantSettingsError = action.payload;
    state.highSchoolProgramParticipantSettingsLoading = false;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.SAVE]: (state) => {
    state.highSchoolProgramParticipantSettingsError = undefined;
    state.highSchoolProgramParticipantSettingsSaving = true;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.DISABLE]: (state) => {
    state.highSchoolProgramParticipantSettingsError = undefined;
    state.highSchoolProgramParticipantSettingsDisabling = true;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.SAVED]: (state, action: PayloadAction<HighSchoolProgramParticipantModel>) => {
    state.highSchoolProgramParticipantSettings = action.payload;
    state.highSchoolProgramParticipantSettingsError = undefined;
    state.highSchoolProgramParticipantSettingsLoading = false;
    state.highSchoolProgramParticipantSettingsSaving = false;
    state.highSchoolProgramParticipantSettingsDisabling = false;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.highSchoolProgramParticipantSettingsError = action.payload;
    state.highSchoolProgramParticipantSettingsLoading = false;
    state.highSchoolProgramParticipantSettingsSaving = false;
    state.highSchoolProgramParticipantSettingsDisabling = false;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST.LOAD]: (state) => {
    state.highSchoolProgramParticipantResultError = undefined;
    state.highSchoolProgramParticipantResultLoading = true;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST.LOADED]: (state, action: PayloadAction<HighSchoolProgramParticipantModel[]>) => {
    state.highSchoolProgramParticipantResult = action.payload;
    state.highSchoolProgramParticipantResultError = undefined;
    state.highSchoolProgramParticipantResultLoading = false;
  },
  [HIGH_SCHOOL_PROGRAM_PARTICIPANT_SETTINGS_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.highSchoolProgramParticipantResultError = action.payload;
    state.highSchoolProgramParticipantResultLoading = false;
  },
});

export default timelinesReducer;

