import {RecipientPerson} from './RecipientPerson';
import {RecipientCompany} from './RecipientCompany';
import {toLocalTime} from '../../@template/helpers/datetimes';

export class ThreadDetailModel implements IThreadDetailModel {
  /** Person who this thread is for (threads are created per person on delivery */
  personId?: string | undefined;
  /** Thread owner identity (internal, p_(personId) or c_(companyId) */
  ownerId?: string | undefined;
  /** Unique identifier */
  id?: string | undefined;
  /** Event context */
  eventContext?: string | undefined;
  /** Thread grouping for user (conversation) */
  groupId?: string | undefined;
  /** Company Id */
  companyId?: string | undefined;
  dtCreated?: Date | undefined;
  dtUpdated?: Date | undefined;
  dtRead?: Date | undefined;
  dtLastEmailed?: Date | undefined;
  dtLastActivity?: Date | undefined;
  /** Indicates if anything in this thread is incomplete */
  isComplete?: boolean | undefined;
  /** Indicates if there is anything in this thread that is unread */
  isRead?: boolean | undefined;
  isMessagesRead?: boolean | undefined;
  /** Number of events unseen by owner */
  unreadCount?: number | undefined;
  unreadMessageCount?: number | undefined;
  /** Number of events requiring actions */
  incompleteCount?: number | undefined;
  isArchived?: boolean | undefined;
  totalEvents?: number | undefined;
  totalMessages?: number | undefined;
  lastMessage?: string | undefined;
  lastSubject?: string | undefined;
  dtMinDue?: Date | undefined;
  /** People in this conversation */
  recipientPeopleMetadata?: RecipientPerson[] | undefined;
  /** Companies in this conversation */
  recipientCompaniesMetadata?: RecipientCompany[] | undefined;
  recipientsDescription?: string | undefined;
  sinceLastActivity?: number | undefined;

  constructor(data?: IThreadDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.ownerId = Data["ownerId"];
      this.id = Data["id"];
      this.eventContext = Data["eventContext"];
      this.groupId = Data["groupId"];
      this.companyId = Data["companyId"];
      this.dtCreated = Data["dtCreated"] ? toLocalTime(Data["dtCreated"].toString()) : <any>undefined;
      this.dtUpdated = Data["dtUpdated"] ? toLocalTime(Data["dtUpdated"].toString()) : <any>undefined;
      this.dtRead = Data["dtRead"] ? toLocalTime(Data["dtRead"].toString()) : <any>undefined;
      this.dtLastEmailed = Data["dtLastEmailed"] ? toLocalTime(Data["dtLastEmailed"].toString()) : <any>undefined;
      this.dtLastActivity = Data["dtLastActivity"] ? toLocalTime(Data["dtLastActivity"].toString()) : <any>undefined;
      this.isComplete = Data["isComplete"];
      this.isRead = Data["isRead"];
      this.unreadCount = Data["unreadCount"];
      this.incompleteCount = Data["incompleteCount"];
      this.isArchived = Data["isArchived"];
      this.totalEvents = Data["totalEvents"];
      this.totalMessages = Data["totalMessages"];
      this.lastMessage = Data["lastMessage"];
      this.lastSubject = Data["lastSubject"];
      this.dtMinDue = Data["dtMinDue"] ? toLocalTime(Data["dtMinDue"].toString()) : <any>undefined;
      if (Array.isArray(Data["recipientPeopleMetadata"])) {
        this.recipientPeopleMetadata = [] as any;
        for (let item of Data["recipientPeopleMetadata"])
          this.recipientPeopleMetadata!.push(RecipientPerson.fromJS(item));
      }
      if (Array.isArray(Data["recipientCompaniesMetadata"])) {
        this.recipientCompaniesMetadata = [] as any;
        for (let item of Data["recipientCompaniesMetadata"])
          this.recipientCompaniesMetadata!.push(RecipientCompany.fromJS(item));
      }
      this.recipientsDescription = Data["recipientsDescription"];
      this.sinceLastActivity = Data["sinceLastActivity"];
    }
  }

  static fromJS(data: any): ThreadDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new ThreadDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["ownerId"] = this.ownerId;
    data["id"] = this.id;
    data["eventContext"] = this.eventContext;
    data["groupId"] = this.groupId;
    data["companyId"] = this.companyId;
    data["dtCreated"] = this.dtCreated ? this.dtCreated.toISOString() : <any>undefined;
    data["dtUpdated"] = this.dtUpdated ? this.dtUpdated.toISOString() : <any>undefined;
    data["dtRead"] = this.dtRead ? this.dtRead.toISOString() : <any>undefined;
    data["dtLastEmailed"] = this.dtLastEmailed ? this.dtLastEmailed.toISOString() : <any>undefined;
    data["dtLastActivity"] = this.dtLastActivity ? this.dtLastActivity.toISOString() : <any>undefined;
    data["isComplete"] = this.isComplete;
    data["isRead"] = this.isRead;
    data["isMessagesRead"] = this.isMessagesRead;
    data["unreadCount"] = this.unreadCount;
    data["unreadMessageCount"] = this.unreadMessageCount;
    data["incompleteCount"] = this.incompleteCount;
    data["isArchived"] = this.isArchived;
    data["totalEvents"] = this.totalEvents;
    data["totalMessages"] = this.totalMessages;
    data["lastMessage"] = this.lastMessage;
    data["lastSubject"] = this.lastSubject;
    data["dtMinDue"] = this.dtMinDue ? this.dtMinDue.toISOString() : <any>undefined;
    if (Array.isArray(this.recipientPeopleMetadata)) {
      data["recipientPeopleMetadata"] = [];
      for (let item of this.recipientPeopleMetadata)
        data["recipientPeopleMetadata"].push(item.toJSON());
    }
    if (Array.isArray(this.recipientCompaniesMetadata)) {
      data["recipientCompaniesMetadata"] = [];
      for (let item of this.recipientCompaniesMetadata)
        data["recipientCompaniesMetadata"].push(item.toJSON());
    }
    data["recipientsDescription"] = this.recipientsDescription;
    data["sinceLastActivity"] = this.sinceLastActivity;
    return data;
  }
}

export interface IThreadDetailModel {
  /** Person who this thread is for (threads are created per person on delivery */
  personId?: string | undefined;
  /** Thread owner identity (internal, p_(personId) or c_(companyId) */
  ownerId?: string | undefined;
  /** Unique identifier */
  id?: string | undefined;
  /** Event context */
  eventContext?: string | undefined;
  /** Thread grouping for user (conversation) */
  groupId?: string | undefined;
  /** Company Id */
  companyId?: string | undefined;
  dtCreated?: Date | undefined;
  dtUpdated?: Date | undefined;
  dtRead?: Date | undefined;
  dtLastEmailed?: Date | undefined;
  dtLastActivity?: Date | undefined;
  /** Indicates if anything in this thread is incomplete */
  isComplete?: boolean | undefined;
  /** Indicates if there is anything in this thread that is unread */
  isRead?: boolean | undefined;
  isMessagesRead?: boolean | undefined;
  /** Number of events unseen by owner */
  unreadCount?: number | undefined;
  unreadMessageCount?: number | undefined;
  /** Number of events requiring actions */
  incompleteCount?: number | undefined;
  isArchived?: boolean | undefined;
  totalEvents?: number | undefined;
  totalMessages?: number | undefined;
  lastMessage?: string | undefined;
  lastSubject?: string | undefined;
  dtMinDue?: Date | undefined;
  /** People in this conversation */
  recipientPeopleMetadata?: RecipientPerson[] | undefined;
  /** Companies in this conversation */
  recipientCompaniesMetadata?: RecipientCompany[] | undefined;
  recipientsDescription?: string | undefined;
  sinceLastActivity?: number | undefined;
}