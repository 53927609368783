
export interface AuthPaths {
  SignIn: string,
  Error404: string,
  ForgotPassword: string,
}

export const authPaths: AuthPaths = {
  Error404: '/error-pages/error-404',
  SignIn: '/signin',
  ForgotPassword: '/forgot-password'
}