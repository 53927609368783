import {formatDate} from '../../@template/helpers/datetimes';
import {SeasonModel} from '../models/SeasonModel';

export const defaultForPoolSearchParamsModel = () => {
  return PoolSearchParamsModel.fromJS({
    searchText: '',
    advisorUsers: [],
    primaryOnly: true,
    advisorSelected: false,
    excludePrimarySelected: false,
    noPrimaryAppointed: false,
    noAdvisorAppointed: false,
    byTeam: false,
    byAdvisorTeam: false,
    byPrimaryAdvisorTeam: true,
    companyIds: [],
    contactPersonIds: [],
    degreePoolIds: [],
    state: [],
    countryCode: 'AU',
    statusFlags: ['Potential', 'Active'],
    capacityFilter: 'Available',
    notArchived: true,
    includeArchived: false,
    onlyArchived: false,
    seasonFromYear: undefined,
    seasonFromSeason: undefined,
  });
}

export const defaultForLocalPoolSearchParamsModel = () => {
  return PoolSearchParamsModel.fromJS({
    distance: 20,
    geoPoint: '-33.8688, 151.2093',
    statusFlags: ['Potential', 'Active'],
    companyIds: [],
    degreePoolIds: []
  });
}


export class PoolSearchParamsModel implements IPoolSearchParamsModel {
  /** Searches name, description */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  createdFrom?: Date | undefined;
  createdTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  companyIds?: string[] | undefined;
  contactPersonIds?: string[] | undefined;
  degreePoolIds?: string[] | undefined;
  studentPersonId?: string | undefined;
  progressFrom?: number | undefined;
  progressTo?: number | undefined;
  remoteFlexible?: boolean | undefined;
  countryCode?: string | undefined;
  state?: string[] | undefined;
  seasonFromSeason?: number | undefined;
  seasonFromYear?: number | undefined;
  seasonToYear?: number | undefined;
  seasonToSeason?: number | undefined;
  billingStatus?: string | undefined;
  isNoSeason?: boolean | undefined;
  studentStatusFlags?: string[] | undefined;
  capacityFilter?: string | undefined;
  distance?: number | undefined;
  geoPoint?: string | undefined;

  constructor(data?: IPoolSearchParamsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.searchText = Data["searchText"];
      if (Array.isArray(Data["statusFlags"])) {
        this.statusFlags = [] as any;
        for (let item of Data["statusFlags"])
          this.statusFlags!.push(item);
      }
      this.createdFrom = Data["createdFrom"] ? new Date(Data["createdFrom"].toString()) : <any>undefined;
      this.createdTo = Data["createdTo"] ? new Date(Data["createdTo"].toString()) : <any>undefined;
      if (Array.isArray(Data["advisorUsers"])) {
        this.advisorUsers = [] as any;
        for (let item of Data["advisorUsers"])
          this.advisorUsers!.push(item);
      }
      this.primaryOnly = Data["primaryOnly"];
      this.advisorSelected = Data["advisorSelected"];
      this.excludePrimarySelected = Data["excludePrimarySelected"];
      this.noPrimaryAppointed = Data["noPrimaryAppointed"];
      this.noAdvisorAppointed = Data["noAdvisorAppointed"];
      this.byTeam = Data["byTeam"];
      this.advisorTeamId = Data["advisorTeamId"];
      this.advisorSubTeamId = Data["advisorSubTeamId"];
      this.byAdvisorTeam = Data["byAdvisorTeam"];
      this.byPrimaryAdvisorTeam = Data["byPrimaryAdvisorTeam"];
      if (Array.isArray(Data["companyIds"])) {
        this.companyIds = [] as any;
        for (let item of Data["companyIds"])
          this.companyIds!.push(item);
      }
      if (Array.isArray(Data["contactPersonIds"])) {
        this.contactPersonIds = [] as any;
        for (let item of Data["contactPersonIds"])
          this.contactPersonIds!.push(item);
      }
      if (Array.isArray(Data["degreePoolIds"])) {
        this.degreePoolIds = [] as any;
        for (let item of Data["degreePoolIds"])
          this.degreePoolIds!.push(item);
      }
      this.studentPersonId = Data["studentPersonId"];
      this.progressFrom = Data["progressFrom"];
      this.progressTo = Data["progressTo"];
      this.remoteFlexible = Data["remoteFlexible"];
      this.countryCode = Data["countryCode"];
      if (Array.isArray(Data["state"])) {
        this.state = [] as any;
        for (let item of Data["state"])
          this.state!.push(item);
      }
      this.seasonFromSeason = Data["seasonFromSeason"];
      this.seasonFromYear = Data["seasonFromYear"];
      this.seasonToYear = Data["seasonToYear"];
      this.seasonToSeason = Data["seasonToSeason"];
      this.billingStatus = Data["billingStatus"];
      this.isNoSeason = Data["isNoSeason"];
      if (Array.isArray(Data["studentStatusFlags"])) {
        this.studentStatusFlags = [] as any;
        for (let item of Data["studentStatusFlags"])
          this.studentStatusFlags!.push(item);
      }
      this.capacityFilter = Data["capacityFilter"];
      this.distance = Data["distance"];
      this.geoPoint = Data["geoPoint"];
    }
  }

  static fromJS(data: any): PoolSearchParamsModel {
    data = typeof data === 'object' ? data : {};
    let result = new PoolSearchParamsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["searchText"] = this.searchText;
    if (Array.isArray(this.statusFlags)) {
      data["statusFlags"] = [];
      for (let item of this.statusFlags)
        data["statusFlags"].push(item);
    }
    data["createdFrom"] = this.createdFrom ? formatDate(this.createdFrom) : <any>undefined;
    data["createdTo"] = this.createdTo ? formatDate(this.createdTo) : <any>undefined;
    if (Array.isArray(this.advisorUsers)) {
      data["advisorUsers"] = [];
      for (let item of this.advisorUsers)
        data["advisorUsers"].push(item);
    }
    data["primaryOnly"] = this.primaryOnly;
    data["advisorSelected"] = this.advisorSelected;
    data["excludePrimarySelected"] = this.excludePrimarySelected;
    data["noPrimaryAppointed"] = this.noPrimaryAppointed;
    data["noAdvisorAppointed"] = this.noAdvisorAppointed;
    data["byTeam"] = this.byTeam;
    data["advisorTeamId"] = this.advisorTeamId;
    data["advisorSubTeamId"] = this.advisorSubTeamId;
    data["byAdvisorTeam"] = this.byAdvisorTeam;
    data["byPrimaryAdvisorTeam"] = this.byPrimaryAdvisorTeam;
    if (Array.isArray(this.companyIds)) {
      data["companyIds"] = [];
      for (let item of this.companyIds)
        data["companyIds"].push(item);
    }
    if (Array.isArray(this.contactPersonIds)) {
      data["contactPersonIds"] = [];
      for (let item of this.contactPersonIds)
        data["contactPersonIds"].push(item);
    }
    if (Array.isArray(this.degreePoolIds)) {
      data["degreePoolIds"] = [];
      for (let item of this.degreePoolIds)
        data["degreePoolIds"].push(item);
    }
    data["studentPersonId"] = this.studentPersonId;
    data["progressFrom"] = this.progressFrom;
    data["progressTo"] = this.progressTo;
    data["remoteFlexible"] = this.remoteFlexible;
    data["countryCode"] = this.countryCode;
    if (Array.isArray(this.state)) {
      data["state"] = [];
      for (let item of this.state)
        data["state"].push(item);
    }
    data["seasonFromSeason"] = this.seasonFromSeason;
    data["seasonFromYear"] = this.seasonFromYear;
    data["seasonToYear"] = this.seasonToYear;
    data["seasonToSeason"] = this.seasonToSeason;
    data["billingStatus"] = this.billingStatus;
    data["isNoSeason"] = this.isNoSeason;
    if (Array.isArray(this.studentStatusFlags)) {
      data["studentStatusFlags"] = [];
      for (let item of this.studentStatusFlags)
        data["studentStatusFlags"].push(item);
    }

    data["capacityFilter"] = this.capacityFilter;
    data["distance"] = this.distance;
    data["geoPoint"] = this.geoPoint;
    return data;
  }
}

export interface IPoolSearchParamsModel {
  /** Searches name, description */
  searchText?: string | undefined;
  statusFlags?: string[] | undefined;
  createdFrom?: Date | undefined;
  createdTo?: Date | undefined;
  advisorUsers?: string[] | undefined;
  primaryOnly?: boolean | undefined;
  advisorSelected?: boolean | undefined;
  excludePrimarySelected?: boolean | undefined;
  noPrimaryAppointed?: boolean | undefined;
  noAdvisorAppointed?: boolean | undefined;
  byTeam?: boolean | undefined;
  advisorTeamId?: string | undefined;
  advisorSubTeamId?: string | undefined;
  byAdvisorTeam?: boolean | undefined;
  byPrimaryAdvisorTeam?: boolean | undefined;
  companyIds?: string[] | undefined;
  contactPersonIds?: string[] | undefined;
  degreePoolIds?: string[] | undefined;
  studentPersonId?: string | undefined;
  progressFrom?: number | undefined;
  progressTo?: number | undefined;
  remoteFlexible?: boolean | undefined;
  countryCode?: string | undefined;
  state?: string[] | undefined;
  seasonFromSeason?: number | undefined;
  seasonFromYear?: number | undefined;
  seasonToYear?: number | undefined;
  seasonToSeason?: number | undefined;
  billingStatus?: string | undefined;
  isNoSeason?: boolean | undefined;
  studentStatusFlags?: string[] | undefined;
  capacityFilter?: string | undefined;
  distance?: number | undefined;
  geoPoint?: string | undefined;
}
