import {SeasonModel} from './SeasonModel';

export class ScorecardDateSettingsModel implements IScorecardDateSettingsModel {
  currentInternshipDefaultSeason?: SeasonModel | undefined;
  currentCompanyInternshipDefaultSeason?: SeasonModel | undefined;
  currentEngagementDefaultSeason?: SeasonModel | undefined;
  currentRecruitmentDefaultSeason?: SeasonModel | undefined;

  constructor(data?: IScorecardDateSettingsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.currentInternshipDefaultSeason = Data["currentInternshipDefaultSeason"] ? SeasonModel.fromJS(Data["currentInternshipDefaultSeason"]) : <any>undefined;
      this.currentCompanyInternshipDefaultSeason = Data["currentCompanyInternshipDefaultSeason"] ? SeasonModel.fromJS(Data["currentCompanyInternshipDefaultSeason"]) : <any>undefined;
      this.currentEngagementDefaultSeason = Data["currentEngagementDefaultSeason"] ? SeasonModel.fromJS(Data["currentEngagementDefaultSeason"]) : <any>undefined;
      this.currentRecruitmentDefaultSeason = Data["currentRecruitmentDefaultSeason"] ? SeasonModel.fromJS(Data["currentRecruitmentDefaultSeason"]) : <any>undefined;
    }
  }

  static fromJS(data: any): ScorecardDateSettingsModel {
    data = typeof data === 'object' ? data : {};
    let result = new ScorecardDateSettingsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["currentInternshipDefaultSeason"] = this.currentInternshipDefaultSeason ? this.currentInternshipDefaultSeason.toJSON() : <any>undefined;
    data["currentCompanyInternshipDefaultSeason"] = this.currentCompanyInternshipDefaultSeason ? this.currentCompanyInternshipDefaultSeason.toJSON() : <any>undefined;
    data["currentEngagementDefaultSeason"] = this.currentEngagementDefaultSeason ? this.currentEngagementDefaultSeason.toJSON() : <any>undefined;
    data["currentRecruitmentDefaultSeason"] = this.currentRecruitmentDefaultSeason ? this.currentRecruitmentDefaultSeason.toJSON() : <any>undefined;
    return data;
  }
}

export interface IScorecardDateSettingsModel {
  currentInternshipDefaultSeason?: SeasonModel | undefined;
  currentCompanyInternshipDefaultSeason?: SeasonModel | undefined;
  currentEngagementDefaultSeason?: SeasonModel | undefined;
  currentRecruitmentDefaultSeason?: SeasonModel | undefined;
}