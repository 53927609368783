import {all, call, put, takeLatest} from 'redux-saga/effects';
import {apiConfig} from '../../config';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {
  HSP_PLACEMENT,
  HSP_PLACEMENT_LIST,
  hspPlacementLoadError,
  hspPlacementLoadSuccess,
  hspPlacementListSuccess, hspPlacementListError, HSP_PLACEMENT_EVALUATIONS,
} from './Actions';
import {HSPPlacementDetailModel} from '../../types/models/HSPPlacementDetailModel';
import {PayloadAction} from '@reduxjs/toolkit';
import {HSPPlacementListByModel} from '../../types/requests/HSPPlacementListByModel';

function* HSPListPlacements(submission: HSPPlacementListByModel)  {
  console.log('SAGA - hspPlacementSaga - hspListPlacements');
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'PUT',
      url: `${apiConfig.coreHSPPlacementsPath}/list`,
      data: submission
    });

    const results: HSPPlacementDetailModel[] = res.data.map((x: any) => HSPPlacementDetailModel.fromJS(x));
    yield put(hspPlacementListSuccess(results));
  } catch (error) {
    yield put(hspPlacementListError(error));
  }
}

function* HSPListPlacementEvaluations(placementId: string)  {
  console.log('SAGA - hspPlacementSaga - listPlacements');

  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreHSPPlacementsPath}/${placementId}/intern-evaluations`,
    });

    const results: HSPPlacementDetailModel[] = res.data.map((x: any) => HSPPlacementDetailModel.fromJS(x));
    yield put(hspPlacementListSuccess(results));
  } catch (error) {
    yield put(hspPlacementListError(error));
  }
}

export function* HSPLoadPlacement(id: string) {
  console.log('SAGA - hspPlacementSaga - LoadPlacement');
  try {
    const res = yield authApi.get(`${apiConfig.coreHSPPlacementsPath}/${id}`);
    const placement: HSPPlacementDetailModel = HSPPlacementDetailModel.fromJS(res.data);
    yield put(hspPlacementLoadSuccess(placement));
  } catch (err) {
    yield put(hspPlacementLoadError(err));
  }
}


export function* watchHSPPlacementListLoad() {
  yield takeLatest(HSP_PLACEMENT_LIST.LOAD, ({payload}: PayloadAction<HSPPlacementListByModel>) => HSPListPlacements(payload))
}

export function* watchHSPPlacementListRefresh() {
  yield takeLatest(HSP_PLACEMENT_LIST.REFRESH, ({payload}: PayloadAction<HSPPlacementListByModel>) => HSPListPlacements(payload))
}

export function* watchHSPPlacementEvaluationsListLoad() {
  yield takeLatest(HSP_PLACEMENT_EVALUATIONS.LOAD, ({payload}: PayloadAction<string>) => HSPListPlacementEvaluations(payload))
}

export function* watchHSPPlacementLoad() {
  yield takeLatest(HSP_PLACEMENT.LOAD, ({payload}: PayloadAction<string>) => HSPLoadPlacement(payload))
}


export default function* hspPlacementSaga() {
  yield all ([
    call(watchHSPPlacementListLoad),
    call(watchHSPPlacementListRefresh),
    call(watchHSPPlacementEvaluationsListLoad),
    call(watchHSPPlacementLoad),
  ])
}

